import { QueryExpr } from '@apis/Resources';
import styled from '@emotion/styled';
import { Text, Title, useMantineTheme } from '@mantine/core';
import { useElementSize } from '@mantine/hooks';
import { MeasuredText } from '@root/Design/Primitives';
import { useDi } from '@root/Services/DI';
import { FormatService, NamedFormats } from '@root/Services/FormatService';
import { useState } from 'react';
import { StandardChartProps } from './Models';

export function KpiChart<T extends Record<string, number>>(props: KpiChartProps<T>) {
    props = normalizeObsoleteProps(props);
    const data = transformKpiData(props.data as unknown as Record<string, number>[], props.values as string[]);
    const { labels, format } = props.settings ?? { labels: [], format: [] };

    const fmtSvc = useDi(FormatService);
    const kpiData = data.map(({ value }, i) => ({
        label: labels?.[i] ?? '',
        formattedValue: fmtSvc.getFormatter(format[i], 'number')!.format(value),
    }));

    return (
        <KpiContainer items={kpiData.length}>
            {kpiData.map((props, i) => (
                <KpiItem key={i} {...props} />
            ))}
        </KpiContainer>
    );
}

function normalizeObsoleteProps<T>(props: KpiChartProps<T> | ObsoleteKpiChartProps<T>): KpiChartProps<T> {
    if (props?.settings && 'valueFilters' in props.settings) {
        const { labels, format } = props.settings;
        return {
            settings: {
                labels,
                format: format.map((f) => (typeof f === 'object' && 'decimals' in f ? 'number' : (f as NamedFormats))),
            },
            data: props.data,
            groups: props.groups,
            values: props.values?.map((_, i) => `kpi${i}`) ?? [],
        } as KpiChartProps<T>;
    }
    return props as KpiChartProps<T>;
}

function KpiItem({ formattedValue, label }: { formattedValue: string; label: string }) {
    const theme = useMantineTheme();
    const { ref: containerRef, width: containerWidth } = useElementSize();
    const [textWidth, setTextWidth] = useState(0);
    const scale = textWidth > containerWidth ? containerWidth / textWidth : 1;

    const textStyle = {
        lineHeight: `calc(${theme.lineHeight} * ${scale})`,
        transform: `scale(${scale})`,
        transformOrigin: 'left',
    };

    return (
        <KpiBox ref={containerRef}>
            <Title>
                <MeasuredText onWidth={setTextWidth} validationKey={formattedValue} text={formattedValue} />
            </Title>
            <Title sx={{ flex: 1, ...textStyle }}>{formattedValue}</Title>
            <Text sx={{ flex: 0 }}>{label}</Text>
        </KpiBox>
    );
}

export interface KpiChartSettings {
    labels: string[];
    format: Array<NamedFormats | undefined>;
}

interface KpiChartProps<T extends Record<string, any>> extends StandardChartProps<number, T> {
    settings?: KpiChartSettings;
}

interface ObsoleteKpiChartProps<T extends Record<string, any>> extends StandardChartProps<number, T> {
    settings?: {
        valueFilters: QueryExpr[][];
        labels: string[];
        format: ('percent' | 'money' | 'money-whole' | { decimals: number } | undefined)[];
    };
}

function transformKpiData(data: Record<string, number>[], aliases: string[]) {
    const singleRecord = data[0] ?? {};
    return aliases.map((s) => ({
        value: singleRecord[s],
    }));
}

const KpiBox = styled.div`
    text-align: center;
    display: flex;
    flex-direction: column;
    div {
        text-align: center;
        white-space: nowrap;
    }
`;
const KpiContainer = styled.div<{ items: number }>`
    height: 100%;
    display: grid;
    grid-template-columns: repeat(${(props) => props.items}, minmax(0, 1fr));
    align-items: center;
    justify-content: space-evenly;
`;
