import { QueryExpr, QueryOperation } from '@apis/Resources';
import { IQueryExpr, Query, QueryParameters, QuerySelectExpr, QuerySortExpr } from '@apis/Resources/model';
import { addDays, addMonths, addWeeks, addYears } from 'date-fns';
import { injectable } from 'tsyringe';
import { FormatService } from '../FormatService';
import { exprBuilder, IFluentOperators, TFluentOps } from '../QueryExpr';

export interface IValueProvider {
    field: string;
    type: string;
    getValue(item: any): any;
}

interface ArrayDataSourceState {
    filters: IQueryExpr[];
    sort: QuerySortExpr[];
}

const resolvingState = Symbol('resolving');
const unresolvedState = Symbol('unresolved');
export class ArrayDataSource {
    private paramValueProviders = new Map<string, IValueProvider>();
    private dataValueProviders = new Map<string, IValueProvider>();
    public constructor(public items: any[], valueProviders: IValueProvider[] = []) {
        for (const valueProvider of valueProviders) {
            this.dataValueProviders.set(valueProvider.field, valueProvider);
        }
    }
    protected filteredItems: any[] = [];
    public async getPage(start: number, end: number, state: ArrayDataSourceState, parent?: any) {
        if (parent) {
            throw new Error('Grid is misconfigured, an array-based datasource cannot provide children. ');
        }
        this.applyState(state);
        return {
            items: this.filteredItems,
            total: this.filteredItems.length,
        };
    }
    public filter(filter: IQueryExpr[]) {
        return this.applyFilter(this.items, filter);
    }
    public applyState(state: ArrayDataSourceState) {
        let filteredItems = this.items.slice();
        this.applySort(filteredItems, state.sort);
        filteredItems = this.applyFilter(filteredItems, state.filters);
        return (this.filteredItems = filteredItems);
    }

    public setValueProviders(valueProviders: IValueProvider[]) {
        this.dataValueProviders.clear();
        for (const valueProvider of valueProviders) {
            this.dataValueProviders.set(valueProvider.field, valueProvider);
        }
    }

    public getValueProviders() {
        return Array.from(this.dataValueProviders.values());
    }

    /**
     * Warning: not fully supported. Populates the datasource with parameters that can be referenced in queries/filters/sorts/etc.
     */
    public setParameters(parameters?: { [key: string]: QueryExpr | IQueryExpr }) {
        this.paramValueProviders.clear();
        if (parameters) {
            for (const [name, value] of Object.entries(parameters)) {
                this.paramValueProviders.set(name, this.createParameterValueProvider(name, value));
            }
        }
    }

    /**
     * Warning: not fully supported. Evaluates the expr and returns the result.
     */
    public evaluateExpr<TResult>(expr: QueryExpr | TFluentOps<TResult>): TResult[] {
        const results = this.project({ value: expr }) as Array<{ value: unknown }>;
        return results.map(({ value }) => value as TResult);
    }

    /**
     * Warning: not fully supported. Transforms each item in this datasource using the passed projection.
     * May return mixed cardinality property values, some V[], some V.
     */
    public project<T>(select: { [K: string]: QueryExpr | IQueryExpr }): Partial<T>[];
    public project<TProjection extends { [K: string]: TFluentOps<any> }>(select: TProjection) {
        type TResult = Partial<{ [K in keyof TProjection]: TProjection[K] extends TFluentOps<infer V> ? V : TProjection[K] }>[];
        const selectExprs = Object.entries(select).map(([key, value]) => ({ Alias: key, Expr: this.normalizeExpr(value) }));
        return this.projectExprs<TResult>(selectExprs);
    }
    public projectExprs<T>(selectExprs: QuerySelectExpr[]): Partial<T>[] {
        const results = this.internalProject(selectExprs, true);
        return results as unknown as Partial<T>[];
    }

    private createParameterValueProvider(name: string, value: QueryExpr | IQueryExpr) {
        let resolvedValue: typeof resolvingState | typeof unresolvedState | any = unresolvedState;
        const valueExpr = value as QueryExpr;
        const datasource = this;
        return {
            field: name,
            get type() {
                return datasource.getType(valueExpr);
            },
            getValue: () => {
                if (resolvedValue === unresolvedState) {
                    resolvedValue = resolvingState;
                    resolvedValue = datasource.evaluateExpr(valueExpr);
                } else if (resolvedValue === resolvingState) {
                    throw new Error(`Circular reference detected when evaluating parameter '${name}'. `);
                }
                return resolvedValue;
            },
        };
    }

    private normalizeExpr(expr: TFluentOps<unknown> | QueryExpr): QueryExpr {
        return 'resolve' in expr ? (expr as { resolve(): QueryExpr }).resolve() : (expr as QueryExpr);
    }

    private internalProject(exprs: QuerySelectExpr[], dirtySingularize: boolean): Array<Record<string, unknown>> {
        const evaluators: Array<[string, (item: unknown) => unknown]> = exprs.map((expr) => [
            expr.Alias ?? 'none',
            this.createEvaluator((expr.Expr as QueryExpr) ?? { Value: undefined }),
        ]);
        return this.items.map((item) => this.projectItem(item, evaluators, dirtySingularize));
    }

    private projectItem(item: any, exprs: Array<[string, (item: unknown) => unknown]>, dirtySingularize: boolean): Record<string, unknown> {
        return exprs.reduce((result, [alias, evaluator]) => {
            const value = evaluator(item);
            result[alias] = !dirtySingularize || !(value instanceof Array) ? value : value.length > 1 ? value : value[0];
            return result;
        }, {} as Record<string, unknown>);
    }

    private applyFilter(items: any[], filters: IQueryExpr[]) {
        const filterHandler = this.createFilterHandler(filters);
        return items.filter(filterHandler);
    }

    private createFilterHandler(filters: IQueryExpr[]): (item: any) => boolean {
        return this.createFilter({ Operation: 'and', Operands: filters });
    }

    private createOperationEvaluator(expr: QueryOperation): (item: any) => any {
        switch (expr.Operation.toLowerCase()) {
            case 'currentdate':
                return this.createCurrentDate();
            case 'adddate':
                return this.createAddDate(expr.Operands as QueryExpr[]);
            case 'snapdate':
                return this.createSnapDate(expr.Operands as QueryExpr[]);
            case 'multiply':
            case 'divide':
            case 'add':
            case 'substract':
                return this.createArithmetic(expr);
            default:
                return this.createFilter(expr);
        }
    }

    private createFilter(expr: QueryOperation): (item: any) => boolean {
        switch (expr.Operation.toLowerCase()) {
            case 'and':
                return this.createAnd(expr.Operands as QueryExpr[]);
            case 'or':
                return this.createOr(expr.Operands as QueryExpr[]);
            case 'not':
                return this.createNot(expr.Operands[0] as QueryExpr);
            case 'isnull':
                return this.createNullCheck(expr.Operands[0] as QueryExpr, true);
            case 'isnotnull':
                return this.createNullCheck(expr.Operands[0] as QueryExpr, false);
            case 'between':
                return this.createBetween(expr.Operands as QueryExpr[]);
            default:
                return this.createComparitor(expr);
        }
    }

    private createEvaluator(expr: QueryExpr): (item: any) => any {
        if ('Field' in expr) {
            const valueProvider = this.getValueProvider(expr.Field) ?? { getValue: (item: any) => item[expr.Field] };
            const accessor = (item: any) => valueProvider?.getValue(item);
            return accessor;
        } else if ('Value' in expr) {
            return () => expr.Value;
        } else {
            return this.createOperationEvaluator(expr);
        }
    }

    private *valueEnumerator(value: any, converter: (value: any) => any) {
        if (value instanceof Array) {
            for (const item of value) {
                yield converter(item);
            }
        } else {
            yield converter(value);
        }
    }

    private compareEach(left: any[], right: any[], comparer: (left: any, right: any) => boolean) {
        for (const leftItem of left) {
            for (const rightItem of right) {
                if (comparer(leftItem, rightItem)) {
                    return true;
                }
            }
        }
        return false;
    }

    private createComparitor(expr: QueryOperation) {
        const type = this.getBestType(expr.Operands as QueryExpr[]);
        const converter = this.getConverter(type);
        const leftEval = this.createEvaluator(expr.Operands[0] as QueryExpr);
        const rightEval = this.createEvaluator(expr.Operands[1] as QueryExpr);
        const left = (item: any) => converter(leftEval(item));
        const right = (item: any) => converter(rightEval(item));

        switch (expr.Operation.toLowerCase()) {
            case 'eq':
            case 'ne':
                const comparer =
                    type === 'string'
                        ? (left: string, right: string) => left.localeCompare(right, undefined, { sensitivity: 'base' }) === 0
                        : type === 'date'
                        ? (left: Date, right: Date) => left.getTime() === right.getTime()
                        : (left: any, right: any) => left === right;
                const negate = expr.Operation.toLowerCase() === 'ne';
                return (item: any) => {
                    const left = [...this.valueEnumerator(leftEval(item), converter)];
                    const right = [...this.valueEnumerator(rightEval(item), converter)];
                    const result = this.compareEach(left, right, comparer);
                    return negate ? !result : result;
                };
            case 'gt':
                return (item: any) => left(item) > right(item);
            case 'gte':
                return (item: any) => left(item) >= right(item);
            case 'lt':
                return (item: any) => left(item) < right(item);
            case 'lte':
                return (item: any) => left(item) <= right(item);
            case 'startswith':
                return (item: any) => (left(item) as string).toLocaleLowerCase().startsWith(right(item).toLocaleLowerCase());
            case 'endswith':
                return (item: any) => (left(item) as string).toLocaleLowerCase().endsWith(right(item).toLocaleLowerCase());
            case 'contains':
                return (item: any) => (left(item) as string).toLocaleLowerCase().indexOf(right(item).toLocaleLowerCase()) >= 0;
            case 'notcontains':
                return (item: any) => (left(item) as string).toLocaleLowerCase().indexOf(right(item).toLocaleLowerCase()) === -1;
            default:
                return () => true;
        }
    }

    private createNullCheck(expr: QueryExpr, not: boolean) {
        const valueEval = this.createEvaluator(expr as QueryExpr);
        const matches = new Set([null, undefined, '']);
        return (item: any) => matches.has(valueEval(item)) === not;
    }

    private getBestType(exprs: QueryExpr[]) {
        return exprs.map((x) => this.getType(x)).find((t) => t !== 'unknown');
    }

    private getType(expr: QueryExpr) {
        if ('Field' in expr) {
            const valueProvider = this.getValueProvider(expr.Field);
            return valueProvider?.type || 'unknown';
        } else if ('Value' in expr) {
            const value = expr.Value;
            if (value instanceof Date) {
                return 'date';
            } else if (typeof value === 'number') {
                return 'number';
            } else if (typeof value === 'boolean') {
                return 'boolean';
            } else if (typeof value === 'string') {
                return 'string';
            } else {
                return 'unknown';
            }
        } else {
            return this.getOperationType(expr);
        }
    }

    private getOperationType(expr: QueryOperation) {
        switch (expr.Operation.toLowerCase()) {
            case 'currentdate':
            case 'adddate':
                return 'date';
            case 'multiply':
            case 'divide':
            case 'add':
            case 'substract':
                return 'number';
            default:
                return 'boolean';
        }
    }

    private createAnd(exprs: QueryExpr[]) {
        const filters = exprs.map((x) => this.createFilter(x as QueryOperation));
        return (item: any) => filters.every((f) => f(item));
    }

    private createOr(exprs: QueryExpr[]) {
        const filters = exprs.map((x) => this.createFilter(x as QueryOperation));
        return (item: any) => filters.some((f) => f(item));
    }

    private createNot(expr: QueryExpr) {
        const filter = this.createFilter(expr as QueryOperation);
        return (item: any) => !filter(item);
    }

    private createBetween(exprs: QueryExpr[]) {
        const left = exprs.length > 0 ? exprs[0] : null;
        const right = exprs.length > 1 ? exprs[1] : null;
        const range = !right || !('Value' in right) || !Array.isArray(right.Value) || right.Value.length !== 2 ? null : right.Value;
        if (!left || !range) {
            return () => false;
        } else {
            return this.createAnd([
                { Operation: 'gte', Operands: [left, { Value: range[0] }] },
                { Operation: 'lte', Operands: [left, { Value: range[1] }] },
            ]);
        }
    }

    private createCurrentDate() {
        return () => new Date();
    }

    private createArithmetic(expr: QueryOperation) {
        const leftEval = this.createEvaluator(expr.Operands[0] as QueryExpr);
        const rightEval = this.createEvaluator(expr.Operands[1] as QueryExpr);
        const left = (item: any) => this.numberConverter(leftEval(item));
        const right = (item: any) => this.numberConverter(rightEval(item));
        switch (expr.Operation.toLowerCase()) {
            case 'add':
                return (item: any) => left(item) + right(item);
            case 'substract':
                return (item: any) => left(item) - right(item);
            case 'multiply':
                return (item: any) => left(item) * right(item);
            case 'divide':
                return (item: any) => left(item) / right(item);
            default:
                return () => 0;
        }
    }

    private createAddDate(exprs: QueryExpr[]) {
        const [subject, amount, unit] = exprs;
        if (!subject) {
            return () => null;
        } else if (!amount || !unit) {
            return () => subject;
        } else {
            const subjectEval = this.createEvaluator(subject);
            const amountEval = this.createEvaluator(amount);
            const unitEval = this.createEvaluator(unit);
            return (item: any) => {
                const subjDate = this.dateConverter(subjectEval(item));
                const amountNum = this.numberConverter(amountEval(item));
                const unitStr = this.stringConverter(unitEval(item));
                if (!subjDate || isNaN(amountNum) || amountNum === 0 || !unitStr) {
                    return subjDate;
                }

                switch (unitStr) {
                    case 'day':
                        return addDays(subjDate, amountNum);
                    case 'week':
                        return addWeeks(subjDate, amountNum);
                    case 'month':
                        return addMonths(subjDate, amountNum);
                    case 'year':
                        return addYears(subjDate, amountNum);
                    default:
                        return subjDate;
                }
            };
        }
    }

    private createSnapDate(exprs: QueryExpr[]) {
        const [subjectExpr, unitExpr, modeExpr] = exprs;
        if (!subjectExpr || !unitExpr) {
            return () => null;
        } else {
            const subjectEval = this.createEvaluator(subjectExpr);
            const unitEval = this.createEvaluator(unitExpr);
            const modeEval = !modeExpr ? () => 'start' : this.createEvaluator(modeExpr);
            return (item: any) => {
                const subj = this.dateConverter(subjectEval(item));
                const unit = this.stringConverter(unitEval(item));
                const mode = this.stringConverter(modeEval(item)) ?? 'start';
                if (!subj || !unit) {
                    return subj;
                }
                return FormatService.instance.tryOrFallback((f) => f.snapDate(subj, unit as 'day', mode as 'start'), subj);
            };
        }
    }

    private applySort(items: any[], sort: QuerySortExpr[]) {
        const sortHandler = this.createCombinedSortHandler(sort);
        return sortHandler(items);
    }

    private createCombinedSortHandler(sort: QuerySortExpr[]) {
        const sortHandlers = sort.reduce((result, item) => {
            const sorter = this.createSortHandler(item);
            if (sorter) {
                result.push(sorter);
            }
            return result;
        }, [] as ((a: any, b: any) => number)[]);
        const sorter = (a: any, b: any) => {
            for (const handler of sortHandlers) {
                const result = handler(a, b);
                if (result !== 0) {
                    return result;
                }
            }
            return 0;
        };
        return (items: any[]) => (sortHandlers.length ? items.sort(sorter) : items);
    }

    private getValueProvider(field: string) {
        return this.paramValueProviders.get(field) ?? this.dataValueProviders.get(field);
    }

    private createSortHandler(sort: QuerySortExpr) {
        const field = sort.Expr?.Field;
        const valueProvider = !field
            ? null
            : this.getValueProvider(field) ?? ({ field, type: sort.Expr?.Type, getValue: (item: any) => item[field] } as IValueProvider);
        const sorter = valueProvider ? this.createSort(valueProvider) : null;
        const modifier = sorter ? this.createSortModifier(sorter, sort.Direction ?? 'Asc') : null;

        return modifier;
    }

    private createSortModifier(sorter: (a: any, b: any) => number, direction: 'Asc' | 'Desc') {
        return direction === 'Asc' ? sorter : (a: any, b: any) => sorter(a, b) * -1;
    }

    private createSort(valueProvider: IValueProvider): (a: any, b: any) => number {
        const converter = this.getConverter(valueProvider.type);
        const comparer = this.getComparer(valueProvider);
        return (a: any, b: any) => comparer(converter(valueProvider.getValue(a)), converter(valueProvider.getValue(b)));
    }

    private getConverter(type?: string) {
        switch (type) {
            case 'date':
                return this.dateConverter;
            case 'number':
                return this.numberConverter;
            case 'string':
                return this.stringConverter;
            default:
                return (value: any) => value;
        }
    }

    private getComparer(valueProvider: IValueProvider) {
        switch (valueProvider.type) {
            case 'number':
            case 'boolean':
            case 'date':
                return this.numberComparer;
            default:
                return this.stringComparer;
        }
    }

    private dateConverter = (date?: Date | string) =>
        !date ? new Date(0, 0, 0) : date instanceof Date ? date : typeof date === 'number' ? new Date(date) : new Date(Date.parse(date));
    private numberConverter = (value?: number) => (typeof value === 'number' ? value : !value ? 0 : parseFloat(value));
    private stringConverter = (value?: string) => (typeof value === 'string' ? value : value === null || value === undefined ? '' : value + '');
    private numberComparer = (a?: number, b?: number) => (a ?? 0) - (b ?? 0);
    private stringComparer = (a?: string, b?: string) => (a ?? '').localeCompare(b ?? '', [], { sensitivity: 'base' });
}

@injectable()
export class ExprEvaluator {
    public evalate<TResult>(
        expr: QueryExpr | TFluentOps<TResult>,
        options: Parameters<ExprEvaluator['createDatasource']>[0] = {}
    ): TResult | undefined {
        const datasource = this.createDatasource(options);
        const results = datasource.evaluateExpr<TResult>(expr);

        return results[0];
    }

    public project<T>(select: { [K: string]: QueryExpr | IQueryExpr }, options: Parameters<ExprEvaluator['createDatasource']>[0] = {}): Partial<T> {
        const datasource = this.createDatasource(options);
        return datasource.project<T>(select)?.[0];
    }

    private createDatasource(options: { fieldData?: unknown; parameters?: QueryParameters; valueProviders?: IValueProvider[] } = {}) {
        const { fieldData = {}, parameters = {}, valueProviders = [] } = options;
        const datasource = new ArrayDataSource([fieldData ?? {}]);
        datasource.setParameters(parameters ?? {});
        datasource.setValueProviders(valueProviders);

        return datasource;
    }
}
