import { postNotificationGenerateScheduleOccurrences } from '@apis/Notification';
import {
    DailyRecurrenceRule,
    IOccurrenceRuleType,
    MonthlyRecurrenceRule,
    OnDemandOccurrenceRule,
    OneTimeOccurrenceRule,
    ScheduleDefinition,
    WeeklyRecurrenceRule,
} from '@apis/Notification/model';
import { Button, Group, Skeleton, Tooltip } from '@mantine/core';
import { useAsync } from '@react-hookz/web';
import { InputSpec } from '@root/Components/Settings/InputSpec';
import { InputSpecInput, SettingsInput, useInputBinding, useSpecBuilder } from '@root/Components/Settings/SettingsInputs';
import {
    SettingsInputRow,
    SettingsLabel,
    SettingsSectionItem,
    SettingsSectionItemHeader,
    SettingsSectionItemHeaderLabel,
    SettingsSectionItemBody,
    SettingsSectionBodyDivider,
    SettingsInfoRow,
    SettingsInfoRowText,
} from '@root/Design/Settings';
import { EventEmitter, useEvent } from '@root/Services/EventEmitter';
import { FormatService, useFmtSvc } from '@root/Services/FormatService';
import { addDays, addMinutes, differenceInDays, format, setDay } from 'date-fns';
import { useCallback, useEffect, useMemo } from 'react';
import { Report } from 'tabler-icons-react';

export type RecurrenceRule =
    | ({ Type: 'Daily' } & DailyRecurrenceRule)
    | ({ Type: 'Weekly' } & WeeklyRecurrenceRule)
    | ({ Type: 'Monthly' } & MonthlyRecurrenceRule);
export type OccurrenceRule = RecurrenceRule | ({ Type: 'OneTime' } & OneTimeOccurrenceRule) | ({ Type: 'OnDemand' } & OnDemandOccurrenceRule);
export type RecurrenceRuleType = RecurrenceRule['Type'];
export type OccurrenceRuleType = OccurrenceRule['Type'];

interface IScheduleSettingsProps {
    definition: ScheduleDefinition;
    typeConstraint?: OccurrenceRuleType;
    onChange: (definition: ScheduleDefinition) => void;
    occurrencesNoun?: string;
}
export function ScheduleSettingsSection(props: IScheduleSettingsProps) {
    return (
        <SettingsSectionItem>
            <SettingsSectionItemHeader>
                <SettingsSectionItemHeaderLabel>Schedule Settings</SettingsSectionItemHeaderLabel>
                <SettingsSectionItemBody>
                    <ScheduleSettings {...props} />
                </SettingsSectionItemBody>
            </SettingsSectionItemHeader>
        </SettingsSectionItem>
    );
}

export function ScheduleSettings(props: IScheduleSettingsProps) {
    const { occurrencesNoun = 'occurrences', definition, onChange, typeConstraint } = props;
    const { occurrenceDates, status, loadDates } = useScheduleOccurrences(definition);
    const onDefChange = useCallback(() => {
        loadDates();
        onChange(definition);
    }, [loadDates, JSON.stringify(definition)]);

    return (
        <>
            <ScheduleSettingsInputs definition={definition} typeConstraint={typeConstraint} onChange={onDefChange} />
            <SettingsSectionBodyDivider />
            <SettingsInfoRow>
                <SettingsLabel>Schedule description:</SettingsLabel>
                <SettingsInfoRowText indent>{describeSchedule(definition).description}</SettingsInfoRowText>
            </SettingsInfoRow>
            <SettingsInfoRow>
                <SettingsLabel>Upcoming {occurrencesNoun}:</SettingsLabel>
                <ScheduleDatePreview dates={occurrenceDates} loading={status === 'loading'} />
            </SettingsInfoRow>
        </>
    );
}

function ScheduleDatePreview(props: { loading: boolean; dates?: string[] }) {
    const { dates: rawDates, loading } = props;
    const fmtSvc = useFmtSvc();
    const dates = rawDates?.map((d) => fmtSvc.toLocalDate(d)) ?? Array.from({ length: 3 });
    return (
        <>
            {!loading && !dates.length ? (
                <SettingsInfoRowText indent>None</SettingsInfoRowText>
            ) : (
                dates.map((d, i) => (
                    <Skeleton height={26} visible={loading}>
                        <Group noWrap spacing={4}>
                            <SettingsInfoRowText indent>{i + 1}.</SettingsInfoRowText>
                            <SettingsInfoRowText>{`${fmtSvc.toShortDate(d, true)} ${fmtSvc.formatTime(d, false)}`}</SettingsInfoRowText>
                        </Group>
                    </Skeleton>
                ))
            )}
        </>
    );
}

function useScheduleOccurrences(def: ScheduleDefinition) {
    const fmtSvc = useFmtSvc();
    const [{ status, result: occurrenceDates }, { execute }] = useAsync((def: ScheduleDefinition, count: number) =>
        postNotificationGenerateScheduleOccurrences(def, { maxOccurrences: count, fromDate: fmtSvc.formatAsLocal(new Date()) })
    );
    const loadDates = useCallback((count: number = 3) => execute(def, count), [execute]);
    useEffect(() => {
        loadDates();
    }, [loadDates]);

    return { status, occurrenceDates, loadDates };
}

export function ScheduleSettingsInputs({ definition, onChange, typeConstraint: typeConstraint }: IScheduleSettingsProps) {
    const formInvalidated = useMemo(() => EventEmitter.empty(), []);
    useEvent(formInvalidated);

    const handleChange = useCallback(() => {
        onChange(definition);
        formInvalidated.emit();
    }, [definition, onChange]);

    const occurrence = useMemo(() => {
        if (definition.OccurrenceRules?.length === 0) {
            definition.OccurrenceRules = [
                { Type: 'Daily', Increment: 1, StartAt: JSON.stringify(new Date()), TimeOffsetsInMinutes: [8 * 60] },
            ] as OccurrenceRule[];
        }
        return definition.OccurrenceRules![0] as OccurrenceRule;
    }, [definition]);

    const type = typeConstraint ?? occurrence.Type;
    const mode = !type || type === 'OnDemand' ? 'disabled' : type === 'OneTime' ? 'one-time' : ('recurring' as const);
    return (
        <>
            {typeConstraint ? null : <ScheduleTypePicker rule={occurrence} onChanged={handleChange} />}
            {mode === 'disabled' ? null : mode === 'one-time' ? (
                <OneTimeOccurrenceSettings rule={occurrence as OneTimeOccurrenceRule} onChange={handleChange} />
            ) : (
                <RecurrenceSettings minimumOptions={!!typeConstraint} rule={occurrence as RecurrenceRule} onChange={handleChange} />
            )}
        </>
    );
}

function ScheduleTypePicker({ rule, onChanged }: { rule: OccurrenceRule; onChanged: () => void }) {
    const handleChange = useCallback(
        (value: string | undefined) => {
            if (value === 'recurring') {
                rule.Type = 'Weekly';
                if (!rule.TimeOffsetsInMinutes?.length) {
                    rule.TimeOffsetsInMinutes = [8 * 60];
                }
                if (!rule.StartAt) {
                    rule.StartAt = JSON.stringify(new Date());
                }
                if (rule.EndAt) {
                    const endAtDt = new Date(rule.EndAt);
                    if (endAtDt < new Date()) {
                        rule.EndAt = undefined;
                    }
                }
            } else {
                rule.Type = (value as IOccurrenceRuleType) ?? 'OnDemand';
            }
            onChanged();
        },
        [rule]
    );
    const type = rule.Type;
    const scheduleType = type === 'Weekly' || type === 'Monthly' || type === 'Daily' ? ('recurring' as const) : type ?? 'OnDemand';
    const scheduleTypeOptions = useMemo(
        () =>
            [
                { value: 'OnDemand', label: 'Disable' },
                { value: 'recurring', label: 'Recurring' },
                { value: 'OneTime', label: 'One-time' },
            ] as Array<{ value: typeof scheduleType; label: string }>,
        []
    );

    return (
        <SettingsInput
            type="string"
            value={scheduleType}
            onChange={handleChange}
            options={scheduleTypeOptions}
            label="Schedule Type"
            presentationOptions={{ align: 'center' }}
        />
    );
}

function OneTimeOccurrenceSettings({ rule, onChange }: { rule: OneTimeOccurrenceRule; onChange: () => void }) {
    const date = useSpecBuilder(rule, (b) =>
        b
            .type('date')
            .options({ label: 'Date' })
            .map((d) => d.Date as unknown as Date)
    );

    const time = useInputBinding(rule, (o) => o.TimeOffsetsInMinutes[0]);

    useEffect(() => onChange(), [JSON.stringify([time.value, date.value])]);
    return (
        <>
            <SettingsInput spec={date} onChanged={onChange} />
            <SettingsInputRow>
                <SettingsLabel>Time</SettingsLabel>
                <TimeSelector {...time} />
            </SettingsInputRow>
        </>
    );
}

const typeMaxIncrement = new Map<IOccurrenceRuleType, number>([
    ['Daily', 999],
    ['Weekly', 150],
    ['Monthly', 30],
]);
interface IRecurrenceSettingsProps {
    rule: RecurrenceRule;
    onChange: () => void;
    minimumOptions?: boolean;
    allowMultipleWeekdays?: boolean;
}
function RecurrenceSettings({ allowMultipleWeekdays, rule, onChange, minimumOptions }: IRecurrenceSettingsProps) {
    const increment = useInputBinding(rule, (o) => o.Increment);
    const type = useInputBinding(rule, (o) => o.Type);
    const max = typeMaxIncrement.get(type.value);

    const time = useInputBinding(rule, (o) => o.TimeOffsetsInMinutes[0]);
    const startAt = useSpecBuilder(rule, (b) =>
        b
            .type('date')
            .options({ label: 'Start' })
            .map((d) => d.StartAt as unknown as Date)
    );
    const endAt = useSpecBuilder(rule, (b) =>
        b
            .type('date')
            .options({ label: 'End' })
            .presentation({ optional: true })
            .map((d) => d.EndAt as unknown as Date)
    );
    const dayOfMonth = useInputBinding<MonthlyRecurrenceRule, number>(rule, (o) => o.DaysOfMonth[0], 0);
    const weekdays = useInputBinding<WeeklyRecurrenceRule, number[]>(rule, (o) => o.DaysOfWeek, [1]);

    const settings = [time, increment, type, startAt, endAt, dayOfMonth, weekdays].map((s) => s.value);
    useEffect(() => onChange(), settings);

    return (
        <>
            {minimumOptions ? null : (
                <>
                    <SettingsInput spec={startAt} onChanged={onChange} />
                    <SettingsInputRow>
                        <SettingsLabel>Repeat Every</SettingsLabel>
                        <Group noWrap spacing={6}>
                            <RecurrenceIncrement {...increment} max={max ?? 1} />
                            <RecurrenceTypeSelector {...type} increment={increment.value} />
                        </Group>
                    </SettingsInputRow>
                </>
            )}
            {type.value === 'Weekly' ? (
                <SettingsInputRow>
                    <SettingsLabel>Weekday{allowMultipleWeekdays ? 's' : ''}</SettingsLabel>
                    <Group noWrap spacing={6}>
                        <WeekdaySelector {...weekdays} multipleDays={allowMultipleWeekdays} />
                        <TimeSelector {...time} />
                    </Group>
                </SettingsInputRow>
            ) : type.value === 'Monthly' ? (
                <SettingsInputRow>
                    <SettingsLabel>Day of month</SettingsLabel>
                    <Group noWrap spacing={6}>
                        <DayOfMonthSelector {...dayOfMonth} />
                        <TimeSelector {...time} />
                    </Group>
                </SettingsInputRow>
            ) : type.value === 'Daily' ? (
                <SettingsInputRow>
                    <SettingsLabel>Time of day</SettingsLabel>
                    <TimeSelector {...time} />
                </SettingsInputRow>
            ) : null}
            {minimumOptions ? null : <SettingsInput spec={endAt} onChanged={onChange} />}
        </>
    );
}

function RecurrenceIncrement({ value, onChange, max }: { value: number; onChange: (value: number) => void; max: number }) {
    const spec = useMemo(() => {
        return {
            onChange,
            value,
            type: 'number',
            defaultValue: 1,
            min: 1,
            max,
            presentationOptions: { align: 'center' },
        } as InputSpec;
    }, [value, onChange, max]);

    return <InputSpecInput spec={spec} />;
}

function RecurrenceTypeSelector({
    value,
    onChange,
    increment,
}: {
    value: NonNullable<RecurrenceRule['Type']>;
    onChange: (value: NonNullable<RecurrenceRule['Type']>) => void;
    increment?: number;
}) {
    const spec = useMemo(() => {
        const suffix = (increment ?? 0) > 1 ? 's' : '';
        return {
            onChange: onChange,
            value: value.toString(),
            type: 'string',
            options: [
                { value: 'Daily', label: 'Day' + suffix },
                { value: 'Weekly', label: 'Week' + suffix },
                { value: 'Monthly', label: 'Month' + suffix },
            ] as { value: NonNullable<RecurrenceRule['Type']>; label: string }[],
            defaultValue: (8 * 60).toString(),
            presentationOptions: { align: 'center', width: 95 },
        } as InputSpec;
    }, [value, onChange, increment]);

    return <InputSpecInput spec={spec} />;
}

function DayOfMonthSelector({ value, onChange }: { value: number; onChange: (value: number) => void }) {
    const spec = useMemo(() => {
        return {
            onChange: (day: string) => onChange(parseInt(day)),
            value: value.toString(),
            type: 'string',
            options: [
                ...Array.from({ length: 31 }, (_, i) => i).map((i) => ({
                    value: i.toString(),
                    label: getDayOfMonthName(i),
                })),
                { value: '-1', label: 'End of month' },
            ],
            presentationOptions: { align: 'center' },
        } as InputSpec;
    }, [value, onChange]);

    return <InputSpecInput spec={spec} />;
}

function TimeSelector({ value, onChange }: { value: number; onChange: (value: number) => void }) {
    const fmtSvc = useFmtSvc();
    const spec = useMemo(() => {
        const options = Array.from({ length: 24 }, (_, i) => i).map((h) => {
            const minutes = h * 60;
            const dt = addMinutes(baseDt, minutes);
            return {
                value: minutes.toString(),
                label: fmtSvc.formatTime(dt, false),
            };
        });
        return {
            onChange: (min: string) => onChange(parseInt(min)),
            value: value.toString(),
            type: 'string',
            options,
            defaultValue: (8 * 60).toString(),
            presentationOptions: { align: 'center', width: 95 },
        } as InputSpec;
    }, [value, onChange]);

    return <InputSpecInput spec={spec} />;
}

function WeekdaySelector({ value, onChange, multipleDays }: { value: number[]; onChange: (value: number[]) => void; multipleDays?: boolean }) {
    const options = useMemo(() => {
        return [0, 1, 2, 3, 4, 5, 6].map((d) => {
            const onClick = () => {
                if (multipleDays) {
                    if (value.includes(d)) {
                        onChange(value.filter((v) => v !== d));
                    } else {
                        onChange([...value, d]);
                    }
                } else {
                    onChange([d]);
                }
            };
            const label = getDayOfWeekName(d);
            const selected = value.includes(d);
            const tooltip = multipleDays ? `${label}: ${selected ? 'Included' : 'Excluded'}` : label;
            const abbr = getDayOfWeekAbbr(d);
            return { value: d, tooltip, abbr, label, onClick, selected };
        });
    }, [value, onChange, multipleDays]);

    return (
        <Group noWrap spacing={3}>
            {options.map(({ value, abbr, tooltip, onClick, selected }) => (
                <Tooltip position="top" label={tooltip} key={value}>
                    <Button px={0} compact sx={{ width: 26, height: 30 }} variant={selected ? 'filled' : 'outline'} onClick={onClick}>
                        {abbr}
                    </Button>
                </Tooltip>
            ))}
        </Group>
    );
}

const baseDt = new Date(2020, 0, 1);
export function describeSchedule(schedule: ScheduleDefinition, fmtSvc?: FormatService) {
    fmtSvc = fmtSvc ?? FormatService.instance;
    const occurrences = schedule.OccurrenceRules ?? [];
    const descriptions = occurrences.map((o) => describeOccurrence(o as OccurrenceRule, fmtSvc!));
    const brief = fmtSvc.userFriendlyJoin(
        descriptions.map((d) => d.brief),
        'and'
    );
    const description = fmtSvc.userFriendlyJoin(
        descriptions.map((d) => d.description),
        'and'
    );

    return {
        icon: Report,
        brief,
        description,
        type: 'Report',
    };
}
function describeOccurrence(occurrence: OccurrenceRule, fmtSvc: FormatService) {
    const { Type } = occurrence;
    switch (Type) {
        case 'OnDemand':
            return { brief: 'Disabled', description: 'Never' };
        case 'OneTime':
            return describeOneTimeOccurrence(occurrence, fmtSvc);
        default:
            return describeRecurrence(occurrence, fmtSvc);
    }
}

function describeOneTimeOccurrence(occurrence: OneTimeOccurrenceRule, fmtSvc: FormatService) {
    const dt = fmtSvc.toLocalDate(occurrence.Date);
    const date = fmtSvc.formatDatetime(addMinutes(dt, occurrence.TimeOffsetsInMinutes?.[0] ?? 0));

    return {
        brief: `Scheduled for ${fmtSvc.toShortDate(dt)}`,
        description: `One time on ${date}`,
    };
}

function describeRecurrence(occurrence: RecurrenceRule, fmtSvc: FormatService) {
    const { Type, EndAt, StartAt, TimeOffsetsInMinutes } = occurrence;

    const startAtDt = !StartAt ? null : fmtSvc.toLocalDate(StartAt);
    const daysFromStart = !startAtDt ? 0 : differenceInDays(new Date(), startAtDt);
    const startAtLbl = !startAtDt ? '' : Math.abs(daysFromStart) <= 1 ? 'Starting Today' : `Starting ${fmtSvc.toShortDate(startAtDt, true)}`;

    const endAtDt = !EndAt ? null : fmtSvc.toLocalDate(EndAt);
    const endAtLbl = !endAtDt ? '' : `Until ${fmtSvc.toShortDate(endAtDt, true)}`;

    const timeOffsets = TimeOffsetsInMinutes ?? [];
    const timeLbls = timeOffsets.map((t) => fmtSvc.formatTime(addMinutes(baseDt, t), false));
    const times = fmtSvc.userFriendlyJoin(timeLbls, 'and');
    const timesPrefix = timeOffsets.length ? 'At' : '';

    const recurPrefix = 'Every';
    const recurBaseName = Type === 'Monthly' ? 'Month' : Type === 'Weekly' ? 'Week' : Type === 'Daily' ? 'Day' : '';

    const increment = occurrence.Increment ?? 1;
    const incrementLbl = increment === 1 ? '' : fmtSvc.formatInt0Dec(increment);

    const recurPl = increment <= 1 ? '' : 's';
    const recurName = recurBaseName + recurPl;
    const recurLbl = [recurPrefix, incrementLbl, recurName].filter((p) => !!p).join(' ');

    const daysOfWeek = Type !== 'Weekly' || !occurrence.DaysOfWeek?.length ? [] : occurrence.DaysOfWeek.slice().sort((a, b) => a - b);
    const daysOfWeekNames = fmtSvc.userFriendlyJoin(daysOfWeek.map(getDayOfWeekName), 'and');
    const daysOfWeekPrefix = daysOfWeek.length ? 'On' : '';
    const daysOfWeekLbl = daysOfWeek.length ? [daysOfWeekPrefix, daysOfWeekNames].join(' ') : '';

    const daysOfMonth = Type !== 'Monthly' || !occurrence.DaysOfMonth?.length ? [] : occurrence.DaysOfMonth.slice().sort((a, b) => a - b);
    const daysOfMonthNames = fmtSvc.userFriendlyJoin(daysOfMonth.map(getDayOfMonthName), 'and');
    const daysOfMonthPrefix = daysOfMonth.length ? 'On the' : '';
    const daysOfMonthLbl = daysOfMonth.length ? [daysOfMonthPrefix, daysOfMonthNames].join(' ') : '';

    const parts = [recurLbl, daysOfWeekLbl, daysOfMonthLbl, timesPrefix, times, startAtLbl, endAtLbl].filter((p) => !!p);
    const fullLbl = parts.join(' ');

    const brief = [recurLbl, daysOfWeekLbl, daysOfMonthLbl, endAtLbl].filter((p) => !!p).join(' ');

    return {
        brief,
        description: fullLbl,
    };
}

function getDayOfWeekName(day: number) {
    return format(setDay(baseDt, day, { weekStartsOn: 0 }), 'cccc');
}
function getDayOfWeekAbbr(day: number) {
    return format(setDay(baseDt, day, { weekStartsOn: 0 }), 'ccccc');
}
function getDayOfMonthName(day: number) {
    return day === -1 ? 'End of month' : format(day === 0 ? baseDt : addDays(baseDt, day), 'do');
}
