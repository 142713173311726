import { Drawer } from '@mantine/core';
import { useDiMemo } from '@root/Services/DI';
import { useEvent, useToggle } from '@root/Services/EventEmitter';
import { INotificationViewerOpenRequest, NotificationViewerService } from '@root/Services/Notification/NotificationViewerService';
import { BasicRouteLoader } from '@root/Services/Router/BasicRouteLoader';
import { useCallback, useEffect, useState } from 'react';
import { useRouteBoundPortal } from '../Router/RouteBoundPortal';
import { NotificationDefinitionSettingsLoader } from './Settings/NotificationSettingsLoader';
import { InAppNotificationViewer } from './Viewer/InAppNotificationViewer';

function InternalNotificationViewerOpener() {
    const viewerSvc = useDiMemo(NotificationViewerService);
    const [opened, { close, open }] = useToggle(false);
    const [request, setRequest] = useState<INotificationViewerOpenRequest<any>>();
    const { mode, notificationId, typePrefId } = request ?? {};
    const target = useRouteBoundPortal();

    useEffect(() => {
        const { dispose } = viewerSvc.openRequested.listen((request) => {
            setRequest(request);
            open();
        });
        return dispose;
    }, []);

    const handleClose = useCallback(
        (changed?: boolean) => {
            request?.onClose?.(!!changed);
            setRequest(undefined);
            close();
        },
        [request?.onClose, close]
    );

    const handleModeChange = useCallback((mode: 'view' | 'edit') => setRequest((r) => (!r ? undefined : { ...r, mode })), [setRequest]);
    const handleShowSettings = useCallback(() => handleModeChange('edit'), [handleModeChange]);

    const settingsSize = 500;
    const viewerSize = 800;
    const totalSize = settingsSize + viewerSize;

    return (
        <Drawer
            opened={opened}
            size={totalSize}
            onClose={handleClose}
            target={target}
            withCloseButton={false}
            position="right"
            sx={{ flexWrap: 'nowrap' }}
        >
            {!opened ? null : mode === 'edit' ? (
                <NotificationDefinitionSettingsLoader
                    settingsWidth={settingsSize}
                    typePrefId={typePrefId}
                    onFinish={handleClose}
                    scope={request?.scope ?? {}}
                />
            ) : !notificationId ? null : (
                <InAppNotificationViewer notificationId={notificationId} onClose={handleClose} onShowSettings={handleShowSettings} />
            )}
        </Drawer>
    );
}

export function NotificationViewerProvider() {
    const routeLoader = useDiMemo(BasicRouteLoader);
    useEvent(routeLoader.routeMeta);

    return <InternalNotificationViewerOpener key={routeLoader.getTopRouteMeta().currentPathWithoutParams} />;
}

export function useNotificationViewer() {
    const { open, create } = useDiMemo(NotificationViewerService);
    return { open, create };
}
