import {
    formatDistance,
    fromUnixTime,
    addMilliseconds,
    parse,
    format,
    parseJSON,
    differenceInCalendarDays,
    startOfDay,
    endOfDay,
    endOfWeek,
    startOfWeek,
    startOfMonth,
    endOfMonth,
    endOfHour,
    startOfHour,
} from 'date-fns';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import { singleton } from 'tsyringe';

class NamedFormatterService {
    public readonly formatters: ReadonlyArray<NamedFormatter>;
    private readonly formatterLookup: Map<NamedFormats, NamedFormatter>;

    private constructor(formatters: NamedFormatter[]) {
        this.formatters = formatters;
        this.formatterLookup = formatters.reduce((res, item) => res.set(item.id, item), new Map<NamedFormats, NamedFormatter>());
    }

    public getByName(name: string): INamedFormatter | undefined {
        return this.formatterLookup.get(name as NamedFormats);
    }
    public get(id: NamedFormats): INamedFormatter | undefined {
        return this.formatterLookup.get(id);
    }
    public format<T>(id: NamedFormats, value: T, emptyValue?: string, fallbackValue?: T): string {
        const formatter = this.get(id);
        return formatter ? formatter.format(value, emptyValue, fallbackValue) : '';
    }

    public static create(fmtSvc: FormatService) {
        const formatterMeta: Record<NamedFormats, NamedFormatterMeta> = {
            /* User-friendly formatters */
            percent: { formatter: fmtSvc.formatPercent, ufName: 'Percent', valueType: 'number', defaultArgs: [10000] },
            bytes: { formatter: fmtSvc.formatBytes, ufName: 'Bytes', valueType: 'number', defaultArgs: [2] },
            int: { formatter: fmtSvc.formatInt0Dec, ufName: 'Whole Number', valueType: 'number' },
            float: { formatter: fmtSvc.formatPreciseDecimal, ufName: 'Precise Number', valueType: 'number' },
            money: { formatter: fmtSvc.formatMoney, ufName: 'Currency', valueType: 'number' },
            'money-whole': { formatter: fmtSvc.formatMoneyNoDecimals, ufName: 'Currency (whole)', valueType: 'number' },
            'money-with-two-decimals': {
                formatter: fmtSvc.formatMoneyDecimals,
                ufName: 'Currency (2-decimal)',
                valueType: 'number',
                defaultArgs: [2],
            },
            'money-with-four-decimals': {
                formatter: fmtSvc.formatMoneyDecimals,
                ufName: 'Currency (4-decimal)',
                valueType: 'number',
                defaultArgs: [4],
            },
            'money-with-seven-decimals': {
                formatter: fmtSvc.formatMoneyDecimals,
                ufName: 'Currency (7-decimal)',
                valueType: 'number',
                defaultArgs: [7],
            },
            'number-with-two-decimals': { formatter: fmtSvc.formatDecimal, ufName: 'Number (2-decimal)', valueType: 'number', defaultArgs: [2] },
            'number-with-four-decimals': { formatter: fmtSvc.formatDecimal, ufName: 'Number (4-decimal)', valueType: 'number', defaultArgs: [4] },
            'number-with-six-decimals': { formatter: fmtSvc.formatDecimal, ufName: 'Number (6-decimal)', valueType: 'number', defaultArgs: [6] },
            'date-default': { formatter: fmtSvc.formatDate, ufName: 'Date', valueType: 'date' },
            'short-date': { formatter: fmtSvc.toShortDate, ufName: 'Date (long)', valueType: 'date' },
            'short-date-with-dow': { formatter: fmtSvc.toShortDate, ufName: 'Date (with weekday)', valueType: 'date', defaultArgs: [true] },
            'short-month': { formatter: fmtSvc.formatShortMonthYear, ufName: 'Date (month & year)', valueType: 'date' },

            /* Internal formatters */
            string: { formatter: (value: any) => value?.toString() ?? '', valueType: 'string' },
            number: { formatter: (value: any) => value?.toString() ?? '', valueType: 'number' },
            'currency-2dec': { formatter: fmtSvc.formatMoneyDecimals, valueType: 'number', defaultArgs: [2] },
            'currency-4dec': { formatter: fmtSvc.formatMoneyDecimals, valueType: 'number', defaultArgs: [4] },
            'number-2dec': { formatter: fmtSvc.formatDecimal, valueType: 'number', defaultArgs: [2] },
            boolean: { formatter: (value: any) => (value === true ? 'Yes' : value === false ? 'No' : value), valueType: 'boolean' },
            date: { formatter: fmtSvc.formatDateWithTodaysTime, valueType: 'date' },
            'date-time-nosec': { formatter: fmtSvc.formatDatetimeNoSecs, valueType: 'date' },
        };

        const formatters = (Object.keys(formatterMeta) as NamedFormats[])
            .map((id) => ({ id, meta: formatterMeta[id] }))
            .map(({ id, meta }) => new NamedFormatter(id, { ...meta, formatter: meta.formatter.bind(fmtSvc) }, null, ''));

        return new NamedFormatterService(formatters);
    }
}

@singleton()
export class FormatService {
    //#region Named formatters
    private readonly namedFormatterSvc: NamedFormatterService;
    public format<T>(name: NamedFormats, value: T, emptyValue?: string, fallbackValue?: T): string {
        return this.getFormatter(name)?.format(value, emptyValue, fallbackValue) ?? emptyValue ?? '';
    }
    public getFormatter(name: undefined | string, fallback: NamedFormats): INamedFormatter;
    public getFormatter(name: NamedFormats): INamedFormatter;
    public getFormatter(name: string): INamedFormatter | undefined;
    public getFormatter(name: string, fallback?: NamedFormats): INamedFormatter | undefined {
        return this.namedFormatterSvc.getByName(name) ?? (!fallback ? undefined : this.namedFormatterSvc.get(fallback)!);
    }
    public getNamedFormatters(inputType?: string, userFriendlyOnly: boolean = true): NamedFormatter[] {
        return this.namedFormatterSvc.formatters.filter(
            (f) => (inputType === undefined || f.meta.valueType === inputType) && (!userFriendlyOnly || !!f.meta.ufName)
        );
    }
    //#endregion

    //#region Localized formatters
    private dateFormatter = new Intl.DateTimeFormat(undefined, {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
    });
    private monthFormatter = new Intl.DateTimeFormat(undefined, {
        month: 'short',
        year: 'numeric',
    });
    private timeFormatter = new Intl.DateTimeFormat(undefined, {
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
    });
    private hourMinuteFormatter = new Intl.DateTimeFormat(undefined, {
        hour: 'numeric',
        minute: 'numeric',
    });
    private hourFormatter = new Intl.DateTimeFormat(undefined, {
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
    });
    private datetimeFormatter = new Intl.DateTimeFormat(undefined, {
        hour: 'numeric',
        minute: 'numeric',
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
    });
    private datetimenosecFormatter = new Intl.DateTimeFormat(undefined, {
        hour: 'numeric',
        minute: 'numeric',
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
    });
    private dateShortFormatter = new Intl.DateTimeFormat(undefined, {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
    });
    private moneyFormatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });
    private dateSpelledOutFormatter = new Intl.DateTimeFormat(undefined, {
        year: 'numeric',
        month: 'long',
        day: '2-digit',
    });

    private moneyFormatterNoDecimals = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
    });
    private moneyFormatters = (() => {
        const moneyFormatters: Record<number, { formatter: Intl.NumberFormat; min: number }> = {};
        return {
            get(decimals: number) {
                return (moneyFormatters[decimals] ??= {
                    formatter: new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'USD',
                        maximumFractionDigits: decimals,
                        minimumFractionDigits: decimals,
                    }),
                    min: 10 ** -decimals,
                });
            },
            format(value: number, decimals: number) {
                const { formatter, min } = this.get(decimals);
                return Math.abs(value) > min
                    ? formatter.format(value)
                    : value > 0
                    ? `< ${formatter.format(min)}`
                    : value < 0
                    ? `> ${formatter.format(-min)}`
                    : formatter.format(0);
            },
        };
    })();
    private moneyFormatter4Decimals = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: 4,
        minimumFractionDigits: 4,
    });

    private preciseNumberFormatter = new Intl.NumberFormat(undefined, { maximumFractionDigits: 7 });
    private numberFormatter = new Intl.NumberFormat(undefined, {});
    private intFormatter = new Intl.NumberFormat(undefined, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
    });
    private decimalFormatter1Dec = new Intl.NumberFormat(undefined, {
        minimumFractionDigits: 1,
        maximumFractionDigits: 1,
    });
    private decimalFormatter2Dec = new Intl.NumberFormat(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });
    private decimalFormatter3Dec = new Intl.NumberFormat(undefined, {
        minimumFractionDigits: 3,
        maximumFractionDigits: 3,
    });
    private decimalFormatter4Dec = new Intl.NumberFormat(undefined, {
        minimumFractionDigits: 4,
        maximumFractionDigits: 4,
    });
    private decimalFormatter6Dec = new Intl.NumberFormat(undefined, {
        minimumFractionDigits: 6,
        maximumFractionDigits: 6,
    });
    //#endregion

    //#region Constructor
    private static _instance: FormatService;
    public static get instance() {
        return this._instance ?? (this._instance = new FormatService());
    }

    public constructor() {
        for (const key in this) {
            const member = this[key];
            if (typeof member === 'function') {
                this[key] = member.bind(this);
            }
        }
        this.namedFormatterSvc = NamedFormatterService.create(this);
        Object.freeze(this);
    }
    //#endregion

    //#region Currency
    /** (locale-safe) default currency formatter */
    public formatMoney(price: number): string {
        return this.moneyFormatter.format(price);
    }

    /**
     * (not locale-safe) format money with two decimals truncated (e.g., $1.43), including non-zero indicator (e.g., < $0.01)
     * @param price
     */
    public formatMoneyNonZeroTwoDecimals(price: number): string {
        if (price > 0 && price < 0.01) return '< $0.01';
        if (price < 0 && price > -0.01) return '> -$0.01';
        else return this.formatMoney(price);
    }

    /**
     * (not locale-safe) Format money with 0, 2 decimals according to a the price or additionally, a range of other sample values.
     * Sample output: '$1.93', '> -$0.01', '$12,553', '$5.00', '$10', '< $0.01', '$0', '$0.0017'
     * @param price
     */
    public formatMoneySignificantDecimals(price: number, ...range: number[]): string {
        if (price === 0) return this.formatMoneyNoDecimals(price);

        if (price > 0 && price < 0.01) return '< $0.01';
        if (price < 0 && price > -0.01) return '> -$0.01';

        const max = Math.max(...[price, ...range].map((n) => Math.abs(n)));
        if (max > 10) return this.formatMoneyNoDecimals(price);

        return this.formatMoney(price);
    }
    /** (locale-safe) Format money with the passed number of decimals, including non-zero indicator (e.g., < $0.01) */
    public formatMoneyDecimals(price: number, decimals: number): string {
        return this.moneyFormatters.format(price, decimals);
    }

    /**
     * (locale-safe) Format money with decimals truncated, e.g, $1.7 -> $1, $1.01 -> $1
     * @param price
     * @returns
     */
    public formatMoneyTruncateDecimals(price: number): string {
        return typeof price === 'number' ? this.moneyFormatterNoDecimals.format(Math.trunc(price)) : '—';
    }

    /**
     * (locale-safe) Format money rounded to nearest dollar, $0. Display m-dash if passed value is non-numeric
     * @param price
     * @returns
     */
    public formatMoneyNoDecimals(price: number): string {
        return typeof price === 'number' ? this.moneyFormatterNoDecimals.format(price) : '—';
    }

    /**
     * (locale-safe) Format money with 4 decimals, $0.0000. Display m-dash if passed value is non-numeric
     * @param price
     * @returns
     */
    public formatMoney4Decimals(price: number): string {
        return typeof price === 'number' ? this.moneyFormatter4Decimals.format(price) : '—';
    }
    //#endregion

    //#region Percents
    /**
     * (locale-safe) Multiply by 100, round, and add commas, e.g.,
     * 1.7 -> 170%
     * 12 -> 1,200%
     * 0.01 -> 1%
     */
    public formatPercent(value: number, max?: number): string {
        max ??= Infinity;
        const absValue = Math.abs(value);
        return value === 0
            ? '0%'
            : value < 0 && absValue < 0.01
            ? '> -1%'
            : absValue < 0.01
            ? '> 0%'
            : absValue * 100 > max && value < 0
            ? '< ' + this.formatInt0Dec(max) + '%'
            : value > max
            ? '> ' + this.formatInt0Dec(max) + '%'
            : this.formatInt0Dec(Math.round(value * 100)) + '%';
    }
    /**
     * (locale-safe) Normalize to 0-1 based by dividing by 100, then format as percent
     * 170 -> 170%
     * 1200 -> 1,200%
     * 1 -> 1%
     * 0.2 -> > 0%
     * -0.2 -> > -1%
     */
    public formatWholeNumberPercent(value: number, max?: number): string {
        return this.formatPercent(value / 100, max);
    }

    /** (not locale-safe) Display 99% if less that 100 and more than 99, otherwise display rounded percent */
    public formatPercentRoundDown(price: number): string {
        if (price < 1 && price > 0.99) {
            return '99%';
        }
        return Math.round(price * 100) + '%';
    }
    //#endregion

    //#region Dates
    //#region Date Converters
    /** Adjust local date to UTC timezone */
    public toUtc(date: Date) {
        return date.getTimezoneOffset() === 0 ? date : zonedTimeToUtc(date, this.getTzName());
    }
    /** Adjust UTC date to local timezone */
    public normalizeToLocal(date: Date) {
        return date.getTimezoneOffset() === 0 ? utcToZonedTime(date, this.getTzName()) : date;
    }

    /**
     * Convert a json date without time to a local date
     * e.g. 2021-01-01 -> 2021-01-01T00:00:00
     * e.g. 1703980800000 -> 2023-12-31T00:00:00
     * @param jsonDate
     * @returns
     */
    public parseDateNoTime(jsonDate: string | number | Date) {
        return new Date(this.formatAsLocal(jsonDate));
    }

    /**
     * Convert from server date, number, or string to timezone-agnostic date
     * Returns e.g. 2021-01-01T00:00:00Z -> 2021-01-01T00:00:00 (no Z or offset at the end)
     * @param jsonDate
     * @returns
     */
    public formatAsLocal(jsonDate: string | number | Date) {
        if (jsonDate instanceof Date) {
            jsonDate = jsonDate.toISOString();
        }
        if (typeof jsonDate === 'number') {
            jsonDate = new Date(jsonDate).toISOString();
        }
        jsonDate = jsonDate.length === 7 ? `${jsonDate}-01` : jsonDate;
        return jsonDate.replace('Z', '') + (jsonDate.includes('T') ? '' : 'T00:00:00');
    }

    /**
     * Given a json date from the server, convert to local date string
     * @param date
     * @returns
     */
    public toLocalDate(date: number | string | undefined | null | Date) {
        date = date ?? '';
        if (typeof date === 'number') {
            date = addMilliseconds(0, date);
        }
        return date instanceof Date
            ? this.normalizeToLocal(date)
            : date.match(/z$/gi)
            ? new Date(date)
            : date.match(/^\d{4}-\d{2}-\d{2}$/)
            ? new Date(date + 'T00:00')
            : parseJSON(date);
    }
    /** Convert a date to yyyyMMdd format */
    public to8DigitDate(date: Date) {
        return format(date, 'yyyyMMdd');
    }
    /** Parse a date string and convert to yyyyMMdd format */
    public from8DigitDate(date: string) {
        return parse(date, 'yyyyMMdd', 0);
    }

    /**
     * Format date as yyyy-MM-dd, e.g. 2021-01-01
     * @param date
     * @returns
     */
    public toJsonShortDate(date: Date) {
        return format(date, 'yyyy-MM-dd');
    }
    /**
     * Format date as UTC yyyy-MM-dd, e.g. 2021-01-01 or 2021-01-01T00:00:00Z
     * @param date
     * @returns
     */
    public toUtcJsonShortDate(date: Date, includeTime: boolean = false) {
        const result = JSON.stringify(date).substring(1, 11);
        return !includeTime ? result : result + 'T00:00:00Z';
    }
    /**
     * Parse date from yyyy-MM, e.g. 2021-01
     * @param date
     * @returns
     */
    public fromMonthYear(date: string) {
        return parse(date, 'yyyy-MM', 0);
    }

    /**
     * "Round" a date to start or end of a given unit, e.g., (2025-05-23T12:34, 'day', 'end') -> 2025-05-23T23:59:59
     */
    public snapDate(date: Date, unit: 'hour' | 'day' | 'week' | 'month', mode: 'start' | 'end' = 'start') {
        switch (unit) {
            case 'hour':
                return mode === 'start' ? startOfHour(date) : endOfHour(date);
            case 'day':
                return mode === 'start' ? startOfDay(date) : endOfDay(date);
            case 'week':
                return mode === 'start' ? startOfWeek(date) : endOfWeek(date);
            case 'month':
                return mode === 'start' ? startOfMonth(date) : endOfMonth(date);
            default:
                throw new Error(`Unsupported unit: ${unit}`);
        }
    }
    //#endregion

    //#region Date Formatters
    /**
     * (locale-safe) Format date locale sensitive, include time if date is today, e.g. 11/21/2001, 1:00 PM
     * @param dateValue
     * @returns
     */
    public formatDateWithTodaysTime(dateValue: string | Date) {
        const date = typeof dateValue === 'string' ? this.toLocalDate(dateValue) : dateValue;
        return !date ? '' : differenceInCalendarDays(new Date(), date) > 1 ? this.formatDate(date) : this.formatDatetime(date);
    }

    /**
     * (locale-safe) Given a json date from the server, convert to local date
     * @param date
     * @returns
     */
    public toLocal(date: number | string | undefined | null) {
        const dateZ = this.toLocalDate(date);
        return this.formatDatetime(dateZ);
    }
    /**
     * (locale-safe) Given a json date from the server, convert to local date without secs
     * @param date
     * @returns
     */
    public toLocalNoSeconds(date: number | string | undefined | null) {
        const dateZ = this.toLocalDate(date);
        return this.formatDatetimeNoSecs(dateZ);
    }
    /** (locale-safe) Given a json date from the server, format as date-time */
    public toLocalLongFormat(date: number | string | undefined | null) {
        const dateZ = this.toLocalDate(date);
        return this.toMonthLongForm(dateZ);
    }
    /**
     * Format date as yyyy-MM, e.g. 2021-01
     * @param date
     * @returns
     */
    public formatYearMonth(date: Date): any {
        return format(date, 'yyyy-MM');
    }
    /**
     * (locale-safe) Format date as LLL do, e.g. Jan 1st
     * @param date
     * @returns
     */
    public formatMonthDay(date: Date): any {
        return format(date, 'LLL do');
    }
    /**
     * (locale-safe) Format date as LLLL yyyy, e.g. January 2021
     * @param date
     * @returns
     */
    public formatLongMonthYear(date: Date) {
        return format(date, 'LLLL yyyy');
    }
    /**
     * (locale-safe) Format date as LLL yyyy, e.g. Jan 2021
     * @param date
     * @returns
     */
    public formatShortMonthYear(date: Date) {
        return format(date, 'LLL yyyy');
    }
    /**
     * Format date as EEE do, e.g. Mon 1st
     * @param date
     * @returns
     */
    public formatLongDay(date: Date) {
        return format(date, 'EEE do');
    }
    /**
     * (locale-safe) Format short date, e.g. Jan 1, 2021
     * Optionally include day of week, e.g. Mon Jan 1, 2021
     * @param date
     * @param includeDow
     * @returns
     */
    public toShortDate(date: Date, includeDow: boolean = false) {
        return isNaN(date as any)
            ? ''
            : includeDow
            ? `${format(date, 'EEE')} ` + this.dateShortFormatter.format(date)
            : this.dateShortFormatter.format(date);
    }
    /**
     * (locale-safe) Format date as M/d do, e.g. 6/1
     * @param date
     * @returns
     */
    public formatShortDate(date: Date) {
        return format(date, 'M/d');
    }
    /**
     * (locale-safe) output locale-default format, 10/1/2021
     * @param date
     * @returns
     */
    public formatDate(date: Date) {
        return isNaN(date as any) ? '' : this.dateFormatter.format(date);
    }
    /** (locale-safe) !Not a week format, output local-default date format */
    public formatWeek(date: Date) {
        return isNaN(date as any) ? '' : this.dateFormatter.format(date);
    }
    /** (locale-safe) short month format, e.g. Jan 2021 */
    public formatMonth(date: Date) {
        return isNaN(date as any) ? '' : this.monthFormatter.format(date);
    }
    /** (locale-safe) Output locale-default date format with time, but no seconds */
    public formatDatetime(date: Date) {
        return isNaN(date as any) ? '' : this.datetimeFormatter.format(date);
    }
    /** (locale-safe) Output locale-default time format, e.g., 5:23:45 PM */
    public formatTime(date: Date, includeSeconds = true) {
        return isNaN(date as any) ? '' : includeSeconds ? this.timeFormatter.format(date) : this.hourMinuteFormatter.format(date);
    }
    /** (locale-safe) Output locale-default hour format, e.g., 5 PM */
    public formatHour(date: Date) {
        return isNaN(date as any) ? '' : this.hourFormatter.format(date);
    }

    /** (locale-safe) Output locale-default date format with time, but no seconds */
    public formatDatetimeNoSecs(date: Date) {
        return isNaN(date as any) ? '' : this.datetimenosecFormatter.format(date);
    }

    /** (locale-safe) Output locale-default date format as long date  */
    public toMonthLongForm(date: Date) {
        return isNaN(date as any) ? '' : this.dateSpelledOutFormatter.format(date);
    }

    /**
     * (locale-safe) User-friendly time since a date, e.g., 5 minutes ago
     * @param date
     * @returns
     */
    public timeAgo(date: Date) {
        return this.timeElapsed(date) + ' ago';
    }

    /**
     * (locale-safe) User-friendly duration between two dates, e.g., 5 minutes
     * @param date
     * @returns
     */
    public timeElapsed(from: Date, to: Date = new Date()) {
        if (from.getTimezoneOffset() === 0) {
            to = this.toUtc(to);
        }
        return formatDistance(from, to);
    }
    //#endregion

    public getTzName() {
        return Intl.DateTimeFormat().resolvedOptions().timeZone;
    }
    public getTzOffsetHours() {
        return new Date().getTimezoneOffset() / 60;
    }
    //#endregion

    //#region Numbers
    /** (locale-safe) !Not an integer format, format number with locale-default number of decimals */
    public formatInt(value: number) {
        return this.numberFormatter.format(value);
    }

    /** (locale-safe) Format as integer */
    public formatInt0Dec(value: number) {
        return this.intFormatter.format(value);
    }
    /** (locale-safe) Format with 2 decimals */
    public formatDecimal2(value: number) {
        return this.decimalFormatter2Dec.format(value);
    }
    /** (locale-safe) Format with up to 7 decimals */
    public formatPreciseDecimal(value: number) {
        return this.preciseNumberFormatter.format(value);
    }
    /** (locale-safe) Format with passed number of decimal places */
    public formatDecimal(value: number, decimals: 1 | 2 | 3 | 4 | 6) {
        return decimals === 1
            ? this.decimalFormatter1Dec.format(value)
            : decimals === 2
            ? this.decimalFormatter2Dec.format(value)
            : decimals === 3
            ? this.decimalFormatter3Dec.format(value)
            : decimals === 4
            ? this.decimalFormatter4Dec.format(value)
            : decimals === 6
            ? this.decimalFormatter6Dec.format(value)
            : '';
    }
    public formatBytes(bytes: number, decimals: number | null = 2) {
        if (!+bytes) return '0 Bytes';

        const k = 1024;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

        const i = Math.max(0, Math.floor(Math.log(bytes) / Math.log(k)));

        const total = bytes / Math.pow(k, i);
        const dm = decimals !== null ? decimals : bytes < k ? 0 : total < 10 ? 1 : 0;

        return `${total.toFixed(dm)} ${sizes[i]}`;
    }
    //#endregion

    //#region Text
    public userFriendlyCamelCase(text: string) {
        return text
            .replace(/([a-z])([A-Z])/g, '$1 $2')
            .replace(/([A-Z])([A-Z][a-z])/g, '$1 $2')
            .replace(/^[a-z]/, (v) => v.toLocaleUpperCase());
    }

    public titleCase(text: string) {
        return text
            .split(' ')
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
            .join(' ');
    }

    public awsIdToName(id: string) {
        return id.replace(/\d\.([a-z]+-[a-z]+-\d\.)?/, '');
    }

    public awsAccountId(id: string) {
        return id.indexOf('-') >= 0 ? id : id.replace(/(\d{4})(\d{4})(\d{4})/, '$1-$2-$3');
    }

    public adjustCspName(platform: string) {
        return platform === 'Aws' ? 'AWS' : platform;
    }

    /**
     * Join a list with commas and/or a conjunction (and/or), e.g.,
     *
     * ['one', 'two', 'three'] => 'one, two, and three'
     * ['one', 'two'] => 'one and two'
     * ['one'] => 'one'
     */
    public userFriendlyJoin(items: Array<any>, conjunction: string = 'and'): string {
        const len = items.length;
        return items.reduce((result, item, idx) => {
            const delimiter = idx === 0 ? '' : idx !== len - 1 ? ', ' : len > 2 ? ', and ' : ' and ';
            return [result, item].join(delimiter);
        }, '');
    }
    //#endregion

    /** Call the passed formatter function, display the default value on error */
    public tryOrFallback<T>(handler: (f: FormatService) => T, fallbackValue: T) {
        try {
            return handler(this);
        } catch {
            return fallbackValue;
        }
    }
}

// #region Named Formatters
export type NamedFormats =
    | 'bytes'
    | 'float'
    | 'int'
    | 'money'
    | 'money-whole'
    | 'money-with-two-decimals'
    | 'money-with-four-decimals'
    | 'money-with-seven-decimals'
    | 'number-with-two-decimals'
    | 'number-with-four-decimals'
    | 'number-with-six-decimals'
    | 'percent'
    | 'date-default'
    | 'short-date'
    | 'short-date-with-dow'
    | 'short-month'
    /* Internal format names */
    | 'string'
    | 'number'
    | 'boolean'
    | 'currency-2dec'
    | 'currency-4dec'
    | 'number-2dec'
    | 'date'
    | 'date-time-nosec';

type NamedFormatterMeta = {
    formatter: (...args: any[]) => string;
    ufName?: string;
    defaultArgs?: any[];
    valueType: 'number' | 'date' | 'string' | 'boolean';
};

export interface INamedFormatter {
    readonly id: NamedFormats;
    readonly meta: NamedFormatterMeta;
    readonly ufName: string | undefined;
    format<T>(value: T, emptyValue?: string, fallbackValue?: T): string;
}
class NamedFormatter implements INamedFormatter {
    private readonly converter: (value: unknown) => unknown;
    private readonly formatter: (value: unknown) => string;
    public get ufName() {
        return this.meta.ufName;
    }

    public constructor(
        public readonly id: NamedFormats,
        public readonly meta: NamedFormatterMeta,
        private readonly conversionFallback?: unknown,
        private readonly formattedFallback: string = ''
    ) {
        this.converter =
            meta.valueType === 'date' ? NamedFormatter.convertToDate : meta.valueType === 'number' ? NamedFormatter.convertToNumber : (x: any) => x;
        const args = meta.defaultArgs ?? [];
        this.formatter = (value: any) => meta.formatter(value, ...args);
    }

    public format = <T>(value: T, emptyValue?: string, fallbackValue?: T): string => {
        const converted = this.convert<T>(value, fallbackValue);
        if (converted === null) {
            return emptyValue ?? this.formattedFallback ?? '';
        }

        try {
            return this.formatter(converted);
        } catch {
            return emptyValue ?? this.formattedFallback ?? '';
        }
    };

    // #region Conversion
    private convert = <T>(value: T, fallbackValue?: T): null | T => {
        const coalesced = this.coalesce(value, fallbackValue);
        if (coalesced === null) return null;
        try {
            return this.converter(coalesced) as T;
        } catch {
            return this.conversionFallback as T | null;
        }
    };

    private coalesce = <T>(value: T, fallbackValue?: T): null | T => {
        return value !== null && value !== undefined
            ? value
            : fallbackValue !== undefined
            ? fallbackValue
            : this.conversionFallback !== undefined
            ? (this.conversionFallback as T)
            : null;
    };

    private static convertToNumber = (value: any) => {
        const result = typeof value === 'string' ? parseFloat(value) : typeof value === 'number' ? value : parseFloat(value);
        return isNaN(result) ? null : result;
    };
    /**
     * Detect whether value is a date with a utc-midnight time.
     * If detected, convert to local date, ignoring the time.
     * If not detected, use date as is.
     */
    private static convertToDate = (value: any) => {
        const ts = value instanceof Date ? value.getTime() : typeof value === 'string' ? Date.parse(value) : typeof value === 'number' ? value : null;
        const isUtcMidnight = ts !== null && ts % 86400000 === 0;

        if (isUtcMidnight) {
            const localized = new Date(ts).toISOString().replace('Z', '');
            return new Date(localized);
        } else {
            return value instanceof Date
                ? value
                : typeof value === 'string'
                ? new Date(Date.parse(value))
                : typeof value === 'number'
                ? new Date(value)
                : null;
        }
    };
    // #endregion
}

// #endregion

export function useFmtSvc() {
    return FormatService.instance;
}
export function useNamedFormatter(formatName: string): undefined | INamedFormatter;
export function useNamedFormatter(formatName: NamedFormats): INamedFormatter;
export function useNamedFormatter(formatName: NamedFormats | string): undefined | INamedFormatter {
    return FormatService.instance.getFormatter(formatName);
}
