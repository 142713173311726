import { QuerySelectExpr } from '@apis/Customers/model';
import {
    NotificationComponentConfig,
    ScheduleDefinition,
    UserDefinedNotificationConfig,
    UserDefinedNotificationThreshold,
    UserDefinedNotificationTrigger,
} from '@apis/Notification/model';
import { QueryConstant, QueryExpr, QueryOperation } from '@apis/Resources';
import { UserConfiguredBarChartSettings } from '@root/Components/Charts/BarChart';
import { ChartTypes } from '@root/Components/Charts/Common';
import { ChartConfig } from '@root/Components/DashboardLayout/Charts/ChartRenderer';
import { createDatasourceSchemaContext } from '@root/Components/Filter/DatasourceContext';
import { FilterSettings } from '@root/Components/Settings/FilterSettings';
import { getInputSpec, ICustomInputSpec, InputSpecBuilder } from '@root/Components/Settings/InputSpec';
import { FormElement, FormSectionItem } from '@root/Components/Settings/SettingsForms';
import { FormatService } from '@root/Services/FormatService';
import { QueryDatasource, QueryDatasourceMetadata } from '@root/Services/Query/QueryDatasource';
import { exprBuilder, SchemaService, TFluentOps } from '@root/Services/QueryExpr';
import { addDays, startOfDay } from 'date-fns';
import { ComponentPropsWithoutRef, ReactNode } from 'react';
import { BellRinging, Report, Settings } from 'tabler-icons-react';
import { IPresetBehavior, IPresetItem, IPresetOption, PresetLabelMeta } from '../../../Settings/Presets';
import { RecurrenceRuleType, ScheduleSettings } from '../../Components/Schedule';
import { ChartSettingsInput } from './ChartSettingsInput';

export type INotificationPresetOption<TParams extends {}> = IPresetOption<UserDefinedNotificationConfig, TypedNotificationConfig<TParams>, unknown>;
export type INotificationPresetItem<TParams extends {}> = IPresetItem<UserDefinedNotificationConfig, TypedNotificationConfig<TParams>, unknown>;

type INotificationPresetBehavior<TParams extends {}> = IPresetBehavior<UserDefinedNotificationConfig, TypedNotificationConfig<TParams>, unknown>;
export interface NotificationConfigPresentation {
    presetId?: string;
}

export type PrefixedParams<T> = { [K in keyof T as `p${Capitalize<string & K>}`]: T[K] };

type ParamQueryExprs<T> = { [K in keyof T]: T[K] extends QueryOperation ? T[K] : QueryConstant<T[K]> };
type TypedNotificationConfig<TParams extends {}> = UserDefinedNotificationConfig & { QueryOptions: { Parameters: TParams } } & {
    PresentationOptions: NotificationConfigPresentation;
};

interface IDatasourcePresetsBuilder {
    getPresets(): INotificationPresetItem<UserDefinedNotificationConfig>[];
}
export abstract class BaseDatasourcePresetsBuilder implements IDatasourcePresetsBuilder {
    private readonly datasourceName: string;

    public constructor(datasourceName: string) {
        this.datasourceName = datasourceName;
    }

    public getPresets() {
        return [] as INotificationPresetItem<UserDefinedNotificationConfig>[];
    }

    protected createDatasourcePresets(
        name: string,
        icon: JSX.Element,
        alerts: INotificationPresetItem<any>[],
        reports: INotificationPresetItem<any>[]
    ) {
        const root: INotificationPresetItem<any> = {
            label: name,
            icon: icon,
            children: [],
        };

        if (alerts.length) {
            root.children!.push({
                label: 'Alerts',
                icon: <BellRinging />,
                children: alerts,
            });
        }

        if (reports.length) {
            root.children!.push({
                label: 'Scheduled Reports',
                icon: <Report />,
                children: reports,
            });
        }

        return [root];
    }

    protected createId(...idParts: Array<string | number | undefined | null>) {
        return `${this.datasourceName}-${idParts.map((v) => v ?? 'n').join('-')}`;
    }

    protected createDescriptionInputs(data: TypedNotificationConfig<any>, defaultTitle: string): FormElement[] {
        return [
            getInputSpec(data, 'string', (d) => d.Title, {
                label: 'Title',
                presentationOptions: { fullWidth: true },
            }),
            getInputSpec(data, 'string-multiline', (d) => d.Description, {
                label: 'Description',
                presentationOptions: { fullWidth: true },
            }),
        ];
    }

    protected createBaseBarChartConfig(groups: QuerySelectExpr[], values: QuerySelectExpr[], settings: UserConfiguredBarChartSettings) {
        return {
            Type: 'Chart',
            ...({
                type: 'chart',
                title: '',
                chartType: 'bar',
                datasourceName: this.datasourceName,
                groups,
                values,
                settings,
            } as ChartConfig),
        } as NotificationComponentConfig;
    }

    protected mergeConfig<TParams>(
        defaultOptions: Partial<TypedNotificationConfig<TParams>>,
        data: UserDefinedNotificationConfig
    ): TypedNotificationConfig<TParams> {
        return {
            ...data,
            Title: data.Title ?? defaultOptions?.Title ?? 'Untitled',
            Description: data.Description ?? defaultOptions?.Description ?? '',
            ComponentConfig: { ...defaultOptions.ComponentConfig, ...data.ComponentConfig },
            Trigger: this.mergeTrigger(defaultOptions.Trigger ?? {}, data.Trigger ?? {}),
            QueryOptions: this.mergeQueryOptions<TParams>(defaultOptions.QueryOptions ?? {}, data.QueryOptions ?? {}),
            PresentationOptions: { ...defaultOptions.PresentationOptions, ...data.PresentationOptions },
        };
    }

    protected createCustomAlertPreset<TParams>(behavior: INotificationPresetBehavior<TParams>) {
        const id = this.createId('custom', 'report');
        const label = 'Custom Alert';
        const description = 'Custom alert with configurable data and alert condition';

        return {
            id,
            label,
            icon: <Settings />,
            description,
            behavior,
        };
    }

    protected createCustomReportPreset<TParams>(behavior: INotificationPresetBehavior<TParams>) {
        const id = this.createId('custom', 'report');
        const label = 'Custom Report';
        const description = 'Custom report with configurable data and schedule';

        return {
            id,
            label,
            icon: <Settings />,
            description,
            behavior,
        };
    }

    protected createCustomChartConfig(chartType: ChartTypes, dimFld?: QuerySelectExpr, metricFld?: QuerySelectExpr) {
        return {
            Type: 'Chart',
            ...({
                title: '',
                chartType,
                datasourceName: this.datasourceName,
                type: 'chart',
                filters: [],
                groups: dimFld ? [dimFld] : [],
                values: metricFld ? [metricFld] : [],
                settings: {},
            } as ChartConfig),
        } as NotificationComponentConfig;
    }

    protected createCustomChartBehavior(queryDs: QueryDatasource, schemaSvc: SchemaService) {
        return (data: UserDefinedNotificationConfig) => {
            const chartSettings: FormElement = {
                type: 'custom',
                value: data.ComponentConfig,
                component: ChartSettingsInput.buildForDatasource(queryDs, schemaSvc),
                onChange: (v) => (data.ComponentConfig = v),
            };

            return this.createSection('Data Options', chartSettings);
        };
    }

    protected createFilterSettings(datasource: QueryDatasource, schemaSvc: SchemaService) {
        const schemaCtx = createDatasourceSchemaContext(schemaSvc, datasource);

        const filterInput = (data: UserDefinedNotificationConfig) => {
            const config = (data.ComponentConfig ??= { filters: [] } as { filters: QueryExpr[] });

            return {
                type: 'custom',
                onChange: (filters) => {
                    config.filters = filters;
                },
                component: FilterSettings,
                get value() {
                    return (config.filters ??= []) as QueryExpr[];
                },
                props: {
                    schemaCtx,
                },
            } as ICustomInputSpec<QueryExpr[], ComponentPropsWithoutRef<typeof FilterSettings>>;
        };
        return filterInput;
    }

    protected mergeQueryOptions<TParams>(
        defaultOptions: Partial<TypedNotificationConfig<TParams>['QueryOptions']>,
        custom: UserDefinedNotificationConfig['QueryOptions']
    ) {
        return {
            ...custom,
            ApiParameters: {
                ...defaultOptions.ApiParameters,
                ...custom?.ApiParameters,
            },
            Parameters: {
                ...defaultOptions.Parameters,
                ...custom?.Parameters,
            },
            DatasourceName: custom?.DatasourceName ?? defaultOptions.DatasourceName,
            Filters: custom?.Filters ?? defaultOptions.Filters,
        } as TypedNotificationConfig<TParams>['QueryOptions'];
    }

    protected mergeTrigger(defaultTrigger: UserDefinedNotificationTrigger, data: UserDefinedNotificationTrigger) {
        return {
            TriggerType: data.TriggerType ?? defaultTrigger.TriggerType,
            Schedule: this.mergeScheduleOptions('Weekly', data.Schedule ?? defaultTrigger.Schedule ?? {}),
            Threshold: data.Threshold ?? defaultTrigger.Threshold,
        };
    }

    protected mergeScheduleOptions(type: RecurrenceRuleType, schedule: ScheduleDefinition): ScheduleDefinition {
        return {
            DefaultTimezoneId: FormatService.instance.getTzName(),
            OccurrenceRules: !schedule?.OccurrenceRules?.length ? [this.getScheduleDefaultsByType(type)] : schedule.OccurrenceRules,
        };
    }

    protected getDefaultScheduleOptions(type: RecurrenceRuleType): ScheduleDefinition {
        return {
            DefaultTimezoneId: FormatService.instance.getTzName(),
            OccurrenceRules: [this.getScheduleDefaultsByType(type)],
        };
    }

    protected getScheduleDefaultsByType(type: RecurrenceRuleType) {
        const eightAmOffsetMin = 8 * 60;
        return {
            Type: type,
            StartAt: new Date().toISOString(),
            TimeOffsetsInMinutes: [eightAmOffsetMin],
            Increment: 1,
            EndAt: null,
            Date: addDays(startOfDay(new Date()), 1).toISOString(),
            DaysOfMonth: [0],
            DaysOfWeek: [1],
            SkipWeekends: true,
        };
    }

    protected addDivider(elements: FormElement[]) {
        if (elements.length) {
            elements.push({ type: 'divider' });
        }
    }

    protected concatSectionItems(...elements: FormElement[][]) {
        return elements.reduce((acc, el) => {
            this.addDivider(acc);
            return acc.concat(el);
        }, []);
    }

    protected createPresetLabel(...parts: Array<string | number | { value?: string | number; placeholder?: string; suffix?: string }>) {
        const result: Array<string | PresetLabelMeta> = [];
        for (const part of parts) {
            const { value, placeholder, suffix } = typeof part === 'object' ? part : { value: part, placeholder: '', suffix: '' };
            const valueDefined = value !== undefined && value !== null;
            const text = !valueDefined ? placeholder ?? 'X' : value!.toString();
            let separator = ' ';
            if (suffix) {
                separator = suffix + ' ';
            }
            const item: string | PresetLabelMeta = !valueDefined
                ? { text, type: 'variable' }
                : typeof part === 'string'
                ? text
                : { text, type: 'explicit' };
            result.push(item, separator);
        }
        return result;
    }

    protected createPresetDescription(...parts: Array<string | number | { value?: string | number; placeholder?: string; suffix?: string }>) {
        const result: string[] = [];
        for (const part of parts) {
            const { value, placeholder, suffix } = typeof part === 'object' ? part : { value: part, placeholder: '', suffix: '' };
            const rawText = value === undefined || value === null ? placeholder ?? 'configured' : value.toString();
            const text = suffix ? rawText + suffix : rawText;
            result.push(text);
        }
        return result.join(' ');
    }

    protected createScheduleForm(schedule: ScheduleDefinition, type?: RecurrenceRuleType) {
        type SchedProps = { value: ScheduleDefinition; onChange: (value: ScheduleDefinition) => void };
        return this.createSectionItem('Schedule Options', {
            type: 'custom',
            component: function ScheduleSettingsWrapper({ onChange }: SchedProps) {
                return <ScheduleSettings definition={schedule} typeConstraint={type} onChange={onChange} occurrencesNoun="reports" />;
            },
            onChange: () => {},
            value: schedule,
        });
    }

    protected createThresholdForm(threshold: UserDefinedNotificationThreshold) {
        return this.createSectionItem('Alert Options', {
            type: 'custom',
            component: function ThresholdSettingsWrapper(props: {
                onChange: (value: UserDefinedNotificationThreshold) => void;
                value: UserDefinedNotificationThreshold;
            }) {
                return <></>;
            },
            onChange: () => {},
            value: threshold,
        });
    }

    protected createSection(title: string, ...elements: Array<FormElement | FormSectionItem>) {
        return {
            title,
            elements,
        };
    }

    protected createSectionItem(header: string | { label: string; controls?: ReactNode }, ...elements: FormElement[]): FormSectionItem {
        return {
            header: typeof header === 'string' ? { label: header } : header,
            elements,
        };
    }

    protected initializeConfig<TParams>(
        rawConfig: undefined | UserDefinedNotificationConfig,
        presetId: string,
        config: Optional<TypedNotificationConfig<TParams>, 'PresentationOptions'>
    ) {
        rawConfig ??= {};
        return {
            ...rawConfig,
            ...config,
            PresentationOptions: { presetId, ...config.PresentationOptions },
        } as unknown as TypedNotificationConfig<TParams>;
    }

    protected createUserInputParams<TDefaults extends { [K in keyof TDefaults]: TDefaults[K] }>(
        config: UserDefinedNotificationConfig,
        defaults: TDefaults
    ) {
        return Object.fromEntries(
            Object.entries(defaults).map(([k, v]) => {
                return [k, { Value: v ?? config.QueryOptions?.Parameters?.[k]?.Value }];
            })
        ) as ParamQueryExprs<TDefaults>;
    }

    protected createBehavior<TParams, TInit extends INotificationPresetBehavior<TParams>>(initializer: TInit['initialize']) {
        const result = { initialize: initializer } as Partial<TInit>;
        const addForm = (formBuilder: TInit['getForm']) => {
            result.getForm = formBuilder;
            return {
                addValidation: (validator: TInit['validate']) => {
                    result.validate = validator;
                    return result as TInit;
                },
            };
        };
        return { addForm };
    }

    protected createInput<T>() {
        return InputSpecBuilder.create<T>();
    }

    protected selectExprs<T extends { [K in keyof T]: TFluentOps<any> }>(data: T): QuerySelectExpr[] {
        const { builder: xb } = exprBuilder<T>();
        return Object.entries(data).map(([k, v]) => ({ Alias: k, Expr: xb.resolve(v as any) }));
    }

    protected getExprs<T extends any[]>(fields: { [K in keyof T]: string }) {
        type TResult = { [K in keyof T]: TFluentOps<T[K]> };
        const { builder: xb } = exprBuilder<T>();
        return fields.map((f) => xb.fromExpr({ Field: f })) as TResult;
    }

    protected get fmtSvc() {
        return FormatService.instance;
    }
}

export interface IDatasourcePresetsBuilderFactory<TInitContext> {
    getSupportedDatasources(): QueryDatasourceMetadata[];
    get(datasourceName: string, context: TInitContext): Promise<IDatasourcePresetsBuilder>;
}
