import { createStyles, Navbar, ScrollArea, Tooltip, Space, useMantineTheme } from '@mantine/core';
import { MainNav, useMainNavPrefs } from '@root/Design/Nav';
import { useDi } from '@root/Services/DI';
import { EventEmitter, useEventValue } from '@root/Services/EventEmitter';
import { BasicRouteLoader } from '@root/Services/Router/BasicRouteLoader';
import { Fragment, useEffect, useMemo, useState } from 'react';
import { LinksGroup } from './LinkGroups';
import { colorPalette, CustomColors, mspColorPalette, theme } from '@root/Design/Themes';
import styled from '@emotion/styled';
import { NavDataItem, useGetNavConfig } from './NavData';
import { LayoutService } from '@root/Design/Layout';
import { MspService } from '@root/Services/MspService';
import { SiteMenuCompanyService } from './SiteMenuCompanyService';
import { Company } from '@apis/Customers/model';
import { useDebouncedValue } from '@mantine/hooks';

export function SiteMenu() {
    const [expanded, setExpanded] = useMainNavPrefs();
    const [logo, setLogo] = useState<string>();
    const [logoWidth, setLogoWidth] = useState<number>(0);
    const [logoMaxHeight, setLogoMaxHeight] = useState<number>(100);
    const [logoMaxWidth, setLogoMaxWidth] = useState<number>(200);
    const layoutSvc = useDi(LayoutService);
    const mspSvc = useDi(MspService);
    const siteMenuContextSvc = useDi(SiteMenuCompanyService);
    const rootCompany = useEventValue(siteMenuContextSvc.rootCompany);

    useEffect(() => {
        if (expanded) {
            if (!mspSvc.config?.isWhiteLabelEnabled) {
                setLogo('/assets/cloudsaver-logo-2024-dark-80.svg');
            } else {
                setLogo(mspSvc.config?.LogoPath);
            }
            setLogoMaxHeight(100);
            setLogoWidth(160);
            setLogoMaxWidth(160);
        } else {
            if (!mspSvc.config?.isWhiteLabelEnabled) {
                setLogo('/assets/cloudsaver-icon-2024.svg');
            } else {
                setLogo(mspSvc.config?.IconPath);
                if (!expanded) {
                    setLogoMaxHeight(38);
                    setLogoMaxWidth(100);
                }
            }
            setLogoWidth(30);
        }
        setTimeout(layoutSvc.windowSizeInvalidated.emit, 600);
    }, [expanded]);

    useDi(BasicRouteLoader);
    const { classes } = useStyles();

    return mspSvc.themeReady ? (
        <MainNav mode={expanded ? 'wide' : 'narrow'}>
            <Navbar className={classes.navbar}>
                <Navbar.Section sx={{ position: 'relative' }}>
                    <NavChevron
                        data-atid="MainNavCollapseChevron"
                        onClick={() => setExpanded(!expanded)}
                        style={{
                            transform: expanded ? `rotate(0deg)` : `rotate(180deg)`,
                            backgroundColor: mspColorPalette.CollapseNavCaretBackground as CustomColors,
                        }}
                    >
                        <i className={`ti ti-chevron-left`} />
                    </NavChevron>
                </Navbar.Section>
                <Navbar.Section sx={{ marginBottom: 10, minHeight: 52, flexBasis: 'auto', background: mspColorPalette.LeftNavLogoBg }} p="sm">
                    <SiteMenuLogo
                        icon={logo as string}
                        maxHeight={logoMaxHeight as number}
                        maxWidth={logoMaxWidth as number}
                        width={logoWidth as number}
                        name={!mspSvc.config?.isWhiteLabelEnabled ? 'Cloudsaver' : mspSvc.config.DisplayName ?? ''}
                        expanded={expanded}
                        redirectUrl={mspSvc.config?.isWhiteLabelEnabled ? mspSvc.config.RedirectUrl : undefined}
                    />
                </Navbar.Section>
                {rootCompany ? <MainNavMenu expanded={expanded} rootCompany={rootCompany} /> : null}
            </Navbar>
        </MainNav>
    ) : (
        <></>
    );
}

function MainNavMenu({ rootCompany, expanded }: { rootCompany: Company; expanded: boolean }) {
    const siteMenuContextSvc = useDi(SiteMenuCompanyService);
    const selectedCompany = useEventValue(siteMenuContextSvc.company);
    const [openedName, setOpenedName] = useState<string | undefined>();
    const [activeSubmenu, setActiveSubmenu] = useState<{ link: string }>();
    const [activeMenu, setActiveMenu] = useState<string>();
    const collapsedActiveItem = useMemo(() => new EventEmitter(''), []);
    const routeLoader = useDi(BasicRouteLoader);
    const route = useEventValue(routeLoader.routeMeta);
    const topLevelRoute = route?.find((r) => r.endpointInfo && (r.endpointName === 'settings' || r.endpointName !== 'manage-company'));
    const topLevelRoutePath = !topLevelRoute ? '' : '/' + topLevelRoute.consumedPath?.toLowerCase() + '/' + topLevelRoute?.currentPath?.toLowerCase();
    const [selectedCompanyThrottled, setSelectedCompanyThrottled] = useState<Company>();
    const [selectedCompanyId] = useDebouncedValue(selectedCompany?.Id, 500);

    const { rootMenuItems, childMenuItems, footerMenuItems } = useGetNavConfig(rootCompany, selectedCompanyThrottled);
    const mainMenuItems = useMemo(() => [...rootMenuItems, ...(childMenuItems ?? [])], [rootMenuItems, childMenuItems]);

    useEffect(() => {
        setSelectedCompanyThrottled(selectedCompany);
    }, [selectedCompanyId]);

    useEffect(() => {
        const allMenuItems = [...rootMenuItems, ...(childMenuItems ?? []), ...footerMenuItems];
        for (const menuItem of allMenuItems) {
            for (const subMenuItem of menuItem.children ?? []) {
                if (
                    topLevelRoutePath?.startsWith(subMenuItem.link.toLowerCase()) ||
                    (subMenuItem.routeMatch && subMenuItem.routeMatch.test(topLevelRoutePath))
                ) {
                    setOpenedName(menuItem.name);
                    setActiveSubmenu(subMenuItem);
                    setActiveMenu(menuItem.name);
                    return;
                }
            }
            if (topLevelRoutePath?.startsWith(menuItem.href.toLowerCase())) {
                setOpenedName(menuItem.href);
                setActiveMenu(menuItem.name);
                return;
            }
        }
    }, [topLevelRoute, rootMenuItems, childMenuItems, footerMenuItems]);

    const renderItem = (item: NavDataItem, i: number) => {
        return (
            <Fragment key={i}>
                {i > 0 ? <Space h={2} /> : null}
                <LinksGroup
                    {...item}
                    collapsedActiveItem={collapsedActiveItem}
                    opened={item.name === openedName}
                    key={item.name}
                    activeChild={item.name === activeMenu ? activeSubmenu : undefined}
                    expanded={expanded}
                    onOpened={setOpenedName}
                    atid={item.atid}
                />
            </Fragment>
        );
    };

    return (
        <>
            <Navbar.Section grow component={ScrollArea} px={expanded ? 16 : 10}>
                {mainMenuItems.map(renderItem)}
            </Navbar.Section>

            <Navbar.Section px={expanded ? 16 : 10}>
                {footerMenuItems.map(renderItem)}
                <Space h={8} />
            </Navbar.Section>
        </>
    );
}

const useStyles = createStyles((theme) => ({
    navbar: {
        backgroundColor: mspColorPalette.LeftNavBgColor,
        color: mspColorPalette.LeftNavTextColor,
    },

    divider: {
        borderTopColor: mspColorPalette.LeftNavDividerColor,
        marginBottom: 16,
    },
    selectedCompanyPicker: {
        maxWidth: '85%',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
}));

function SiteMenuLogo({
    icon,
    name,
    expanded,
    width,
    redirectUrl,
    maxHeight,
    maxWidth,
}: {
    icon: string;
    name: string;
    expanded: boolean;
    width: number;
    redirectUrl: string | undefined;
    maxHeight: number;
    maxWidth: number;
}) {
    return (
        <>
            <Tooltip label={name} disabled={expanded || !!redirectUrl} position="right" openDelay={300} withArrow>
                {redirectUrl !== undefined ? (
                    <a href={redirectUrl}>
                        <Logo src={icon} style={{ maxWidth: maxWidth, maxHeight: maxHeight }}></Logo>
                    </a>
                ) : (
                    <Logo src={icon} alt="CloudSaver logo" width={width}></Logo>
                )}
            </Tooltip>
        </>
    );
}

const Logo = styled.img`
    max-width: 160px;
    margin-left: 10px;
`;

const NavChevron = styled.a`
    position: absolute;
    right: -9px;
    top: 41px;
    width: 22px;
    height: 22px;
    display: block;
    font-size: ${(p) => p.theme.fontSizes.md}px;
    background-color: ${colorPalette.navChevronColor};
    border-radius: 50%;
    text-align: center;
    cursor: pointer;
    transition: all 100ms;

    i {
        color: ${colorPalette.fffColor};
    }
`;
