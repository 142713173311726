import styled from '@emotion/styled';
import { Card } from '@mantine/core';
import { ForwardedRef, forwardRef, ReactNode } from 'react';

export const CustomizableDashboardContainer = styled.div<{ height?: string; padding?: number }>`
    height: ${(p) => p.height ?? '100%'};
    position: relative;
    padding: ${(p) => p.padding ?? 32}px;
`;

export const DashboardTitle = styled.div`
    display: flex;
    align-items: baseline;
    justify-content: space-between;
`;

export const DashboardItemHeaderText = styled.h3`
    margin: 0;
    padding-top: ${(p) => p.theme.spacing.xs}px;
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    font-weight: 100;
    text-overflow: ellipsis;
`;
export const DashboardItemHeader = styled.div`
    display: flex;
    align-items: center;
    margin: 0;
    padding-bottom: var(--dashboard-card-header-padding);
`;
export const DashboardItemMenu = styled.div`
    flex: 0;
    min-width: ${(p) => p.theme.spacing.xl}px;
`;
export const DashboardContainer = styled.div`
    .react-grid-placeholder {
        background: ${(p) => p.theme.fn.rgba(p.theme.colors[p.theme.primaryColor][9], 0.75)};
        border-radius: ${(p) => p.theme.radius.lg}px;
    }
`;

export function DashboardCard({ children }: { children: ReactNode }) {
    return <Card component={DashboardCardEl}>{children}</Card>;
}

export const DashboardCardEl = styled.div`
    --dashboard-card-padding: 16px;
    --dashboard-card-border-radius: ${(p) => p.theme.radius.lg}px;
    --dashboard-card-border: solid 1px ${(p) => p.theme.colors.gray[3]};
    --dashboard-card-header-padding: 0.5rem;
    height: 100%;
    display: flex;
    border: var(--dashboard-card-border);
    border-radius: var(--dashboard-card-border-radius);
    flex-direction: column;
    overflow: auto;
    padding: var(--dashboard-card-padding);
    > div {
        min-height: 0;
    }
`;

interface DashboardItemContainerProps {
    drag: 'move' | 'nomove';
    mode: 'standard' | 'freeform';
    resizable?: boolean;
    children: ReactNode;
}
export const DashboardItem = styled.div<DashboardItemContainerProps>`
    ${(p) =>
        p.mode === 'standard'
            ? `
    border: solid 1px ${p.theme.colors.gray[1]};
    background: ${p.theme.colors.gray[0]};
    padding: 1rem;
    display: flex;
    flex-direction: column;   
    > div {
        min-height: 0;
    }
    `
            : null}
    --dashboard-resizer-size: ${(p) => (p.resizable ? 20 : 0)}px;
    .dashboard-header-name {
        flex: 1;
        overflow: hidden;
        cursor: ${(p) => (p.drag === 'move' ? 'grab' : 'default')};
    }
    &.react-draggable-dragging {
        .dashboard-header-name {
            cursor: grabbing;
        }
    }
    .react-resizable-handle {
        bottom: 0.5rem !important;
        right: 0.5rem !important;
        margin: 0 -2px -2px 0;
        width: var(--dashboard-resizer-size);
        height: var(--dashboard-resizer-size);
    }
`;
