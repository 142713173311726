import { QueryExpr, QueryOperation } from '@apis/Resources';
import styled from '@emotion/styled';
import {
    Overlay,
    Input,
    NumberInput,
    ActionIcon,
    Popover,
    Box,
    Tooltip,
    Space,
    Group,
    Text,
    TextInput,
    Divider,
    Button,
    Portal,
    LoadingOverlay,
    Badge,
    Select,
    createStyles,
    useMantineTheme,
    Loader,
} from '@mantine/core';
import { Calendar, DatePicker } from '@mantine/dates';
import { useDisclosure, useTimeout } from '@mantine/hooks';
import { SectionedPopover, SectionedPopoverToolbar } from '@root/Design/Primitives';
import { colorPalette, CustomColors, theme } from '@root/Design/Themes';
import { useDi } from '@root/Services/DI';
import { EventEmitter, useEvent, useEventValue, useToggle } from '@root/Services/EventEmitter';
import { FormatService, INamedFormatter, NamedFormats } from '@root/Services/FormatService';
import { createContext, ForwardedRef, forwardRef, Fragment, JSXElementConstructor, KeyboardEvent, useContext, useState } from 'react';
import { ReactNode, useCallback, useEffect, useMemo } from 'react';
import { CalendarTime, Check, ChevronDown, Pinned, Trash, X } from 'tabler-icons-react';
import { DropdownOpener } from '../Picker/Design';
import { Picker } from '../Picker/Picker';
import { useFloatedFullText } from '../Text/FloatedFullText';
import {
    FilterItem,
    FiltersContainer,
    FilterToken,
    getCompactOperandStyles,
    getCompactValueInputPickerSx,
    LineItemCompactRow,
    LineItemCompactToken,
} from './Design';
import { GlobalSearch, GlobalFilterModel } from './GlobalSearch';
import { IValueProviderFactory } from '@root/Services/Query/QueryDatasource';
import { ExprTypeProvider, IFieldInfoProvider, IOperationInfoProvider, IQueryToken, QueryDescriptorService } from './Services';
import { DateRangeCalendar } from './DateRangeFilter';

type ValueProvider = Exclude<ReturnType<IValueProviderFactory['getValueProvider']>, undefined>;
type FieldPickerRenderer = (onChange: (field: string) => void) => ReactNode;
type ValueRendererProvider = (filterModel: FilterExpr, model: DataFilterModel) => undefined | ValueRenderer;
type ScopedValueRendererProvider = (filterModel: FilterExpr) => undefined | ValueRenderer;
type ValueRenderer = JSXElementConstructor<{ filter: FilterExpr; apply: () => void }>;
export type DataFilterValueRenderer = ValueRenderer;
type TokenProvider = (filter: FilterExpr, defaultValue: IQueryToken[]) => IQueryToken[];

interface DataFiltersProps {
    filters: QueryExpr[];
    onModelLoaded?: (model: DataFilterModel) => void;
    onChange: (filters: QueryExpr[], globalSearch: string) => void;
    fieldInfoProvider: IFieldInfoProvider;
    operationInfoProvider?: IOperationInfoProvider;
    valueProvider?: IValueProviderFactory;
    renderFieldPicker: FieldPickerRenderer;
    renderAddFilter?: (model: DataFilterModel) => ReactNode;
    leftPlaceHolder?: ReactNode;
    valueRendererProvider?: ValueRendererProvider;
    tokenProvider?: TokenProvider;
    disabled?: boolean;
    dataFiltersAsLineItem?: boolean;
    lineItemCompact?: boolean;
    globalSearchText?: string;
    showGlobalSearch?: boolean;
    filterRequested?: EventEmitter<{ field: string } | undefined>;
    showErrors?: boolean;
    align?: 'start' | 'end';
    allowPin?: boolean;
    pinnedFilters?: QueryExpr[];
    onPin?: (filters: QueryExpr[]) => void;
}
export function DataFilters({ filters, onChange, fieldInfoProvider, renderAddFilter, ...props }: DataFiltersProps) {
    const { operationInfoProvider } = props;
    const model = useMemo(() => {
        const result = new DataFilterModel();
        return result;
    }, []);
    useEvent(model.viewInvalidated);
    useEffect(() => {
        model.autoApply = !!props.dataFiltersAsLineItem;
        model.load(filters, fieldInfoProvider, operationInfoProvider, props.valueProvider, props.tokenProvider, props.pinnedFilters);
    }, [filters, fieldInfoProvider, operationInfoProvider, props.tokenProvider, props.dataFiltersAsLineItem, props.pinnedFilters]);
    useEffect(() => {
        model.globalFilter.inputText = model.globalFilter.appliedText = props.globalSearchText ?? '';
    }, [props.globalSearchText]);
    useEffect(() => props.onModelLoaded?.(model), [model]);

    useEvent(
        props.filterRequested,
        useCallback(
            (evt?: { field: string }) => {
                if (evt) {
                    model.addOrOpenFilter(evt.field);
                }
            },
            [model]
        )
    );
    useEvent(model.filtersChanged);
    useEvent(
        model.appliedFilterChanged,
        useCallback(
            (changes: QueryExpr[]) => {
                onChange(changes, model.globalFilter.appliedText);
            },
            [model, onChange]
        )
    );
    useEvent(
        model.pinnedFilterChanged,
        useCallback(
            (changes: QueryExpr[]) => {
                if (props.onPin) {
                    props.onPin(changes);
                }
            },
            [model, props.onPin]
        )
    );
    const valueRendererProvider = useCallback(
        (filter: FilterExpr) => props.valueRendererProvider?.(filter, model),
        [model, props.valueRendererProvider]
    );
    const appearanceCtx = { compact: props.lineItemCompact, showErrors: props.showErrors };
    return (
        <AppearanceCtx.Provider value={appearanceCtx}>
            <FiltersContainer mode={props.dataFiltersAsLineItem ? 'inline' : 'pill'} align={props.align ?? 'start'}>
                <Box sx={{ display: 'flex' }}>
                    {props.leftPlaceHolder}
                    <Box
                        sx={{
                            display: 'contents',
                            '> *': { opacity: props.disabled ? 0.8 : undefined, pointerEvents: props.disabled ? 'none' : undefined },
                        }}
                    >
                        {props.showGlobalSearch ? (
                            <>
                                <GlobalSearch model={model} />
                                <Space w="xs"></Space>
                            </>
                        ) : null}
                        {renderAddFilter?.(model)}
                    </Box>
                </Box>
                {model.filters.map((f, i) => (
                    <Fragment key={i}>
                        <Box sx={{ opacity: props.disabled ? 0.8 : undefined, pointerEvents: props.disabled ? 'none' : undefined }}>
                            {!props.dataFiltersAsLineItem ? (
                                <DataFilter
                                    filter={f}
                                    align={props.align ?? 'start'}
                                    renderFieldPicker={props.renderFieldPicker}
                                    valueRendererProvider={valueRendererProvider}
                                    allowPin={props.allowPin}
                                />
                            ) : (
                                <DataFilterLineItem
                                    filter={f}
                                    disabled={props.disabled}
                                    renderFieldPicker={props.renderFieldPicker}
                                    valueRendererProvider={valueRendererProvider}
                                    compact={props.lineItemCompact}
                                />
                            )}
                        </Box>
                    </Fragment>
                ))}
            </FiltersContainer>
        </AppearanceCtx.Provider>
    );
}

const AppearanceCtx = createContext<{ showErrors?: boolean; compact?: boolean }>({});
export const DataFilterAppearanceCtx = AppearanceCtx;

export function DataFilterLineItem(props: {
    filter: FilterExpr;
    renderFieldPicker: FieldPickerRenderer;
    valueRendererProvider: ScopedValueRendererProvider;
    disabled?: boolean;
    compact?: boolean;
}) {
    const { filter, renderFieldPicker, valueRendererProvider, disabled, compact } = props;
    const LineItemComponent = compact ? LineItemCompact : LineItemFullSize;
    const applyValue = useCallback(() => filter.apply(), [filter]);
    const remove = useCallback(() => filter.delete(), [filter]);
    useEvent(filter.changed);
    return (
        <LineItemComponent
            filter={filter}
            renderFieldPicker={renderFieldPicker}
            applyValue={applyValue}
            disabled={disabled}
            remove={remove}
            valueRendererProvider={valueRendererProvider}
        />
    );
}

function LineItemFullSize({
    filter,
    renderFieldPicker,
    applyValue,
    disabled,
    remove,
    valueRendererProvider,
}: {
    filter: FilterExpr;
    renderFieldPicker: FieldPickerRenderer;
    applyValue: () => void;
    disabled: boolean | undefined;
    remove: () => void;
    valueRendererProvider: ScopedValueRendererProvider;
}) {
    const ValueComponent = getValueComponent(filter, valueRendererProvider, DataFilterDisabled) ?? DataFilterDisabled;
    return (
        <Group my="lg" noWrap spacing="xs">
            <Group sx={{ flex: 1, flexWrap: 'nowrap', columnGap: 8, rowGap: 4 }}>
                <Box sx={{ minWidth: 250, flex: undefined }}>
                    <FilterFieldPicker filter={filter} renderFieldPicker={renderFieldPicker} data-atid="ChooseFilterOperator" />
                </Box>
                <Box sx={{ minWidth: 150 }}>
                    <OperationPicker filter={filter} />
                </Box>
                <Box sx={{ flex: 1 }}>{ValueComponent ? <ValueComponent filter={filter} apply={applyValue} /> : null}</Box>
            </Group>
            {!disabled && (
                <Tooltip label="Remove">
                    <ActionIcon radius="xl" onClick={remove}>
                        <Trash size={16} />
                    </ActionIcon>
                </Tooltip>
            )}
        </Group>
    );
}

function LineItemCompact({
    filter,
    renderFieldPicker,
    applyValue,
    disabled,
    remove,
    valueRendererProvider,
}: {
    filter: FilterExpr;
    renderFieldPicker: FieldPickerRenderer;
    applyValue: () => void;
    disabled: boolean | undefined;
    remove: () => void;
    valueRendererProvider: ScopedValueRendererProvider;
}) {
    const ValueComponent = getValueComponent(filter, valueRendererProvider, DataFilterCompactDisabled) ?? DataFilterCompactDisabled;
    const hasField = filter.hasField;
    const hasOperation = filter.hasOperation;
    const hasValue = filter.operationHasValue();
    const components = !hasField ? 1 : !hasValue ? 2 : 3;
    return (
        <LineItemCompactRow components={components}>
            <FilterFieldCompactPicker disabled={disabled} filter={filter} renderFieldPicker={renderFieldPicker} />
            {hasField && <OperationCompactPicker filter={filter} />}
            {hasValue && hasOperation && ValueComponent ? <ValueComponent filter={filter} apply={applyValue} /> : null}
            {!disabled && (
                <Tooltip label="Remove">
                    <ActionIcon radius="xl" onClick={remove}>
                        <Trash size={16} />
                    </ActionIcon>
                </Tooltip>
            )}
        </LineItemCompactRow>
    );
}

export function DataFilter({
    filter,
    onClose,
    align,
    renderFieldPicker,
    valueRendererProvider,
    allowPin,
}: {
    filter: FilterExpr;
    onClose?: () => void;
    align: 'start' | 'end';
    renderFieldPicker: FieldPickerRenderer;
    valueRendererProvider: ScopedValueRendererProvider;
    allowPin?: boolean;
}) {
    const tokens = filter.getTokens(filter.getExpr() as QueryExpr);
    const showOptions = useEventValue(filter.showOptions);
    const [reposition, setReposition] = useState<number>(0);
    const [editing, setEditing] = useState(false);
    const close = useCallback(() => {
        filter.showOptions.emit(false);
        if (!filter.hasApplied && !filter.pinned) {
            filter.delete();
        }
        if (!filter.hasApplied && filter.pinned) {
            filter.applyPin();
        }
        onClose?.();
    }, [filter, onClose]);
    const toggleMenu = useCallback(() => filter.showOptions.emit(!filter.showOptions.value), [filter]);
    const keyUp = useCallback(
        (e: KeyboardEvent<HTMLDivElement>) => {
            if (e.key === 'Enter') {
                filter.showOptions.emit(true);
            }
        },
        [filter]
    );
    const { start } = useTimeout(() => setReposition(reposition + 1), 200);
    useEffect(start, [showOptions]);
    return (
        <>
            <Popover
                withinPortal
                opened={showOptions}
                positionDependencies={[filter, reposition, filter.field]}
                position="bottom"
                withArrow
                offset={-5}
                shadow="md"
            >
                <Popover.Target>
                    <div>
                        <Tooltip multiline sx={{ maxWidth: 500 }} label={filter.getToolTip()} position="bottom">
                            <FilterItem
                                tabIndex={0}
                                onKeyUp={keyUp}
                                onClick={toggleMenu}
                                state={filter.isValid() || filter.pinned ? 'valid' : 'invalid'}
                                data-atid="FilterClosedPill"
                            >
                                {tokens.map((t, i) => (
                                    <FilterToken
                                        maxWidth={
                                            (filter.pinned && filter.isAnyValue) ||
                                            (!(t.name instanceof Array) && t.name!.toString().toLowerCase().indexOf('compliant with') > -1)
                                                ? 1000
                                                : 100
                                        }
                                        key={i}
                                    >
                                        {t.type === 'constant' && filter.isAnyValue
                                            ? null
                                            : t.name instanceof Array
                                            ? t.name.map((n, i) => (
                                                  <Fragment key={i}>
                                                      {i === 0 ? '' : ', '}
                                                      {n}
                                                  </Fragment>
                                              ))
                                            : t.name}
                                    </FilterToken>
                                ))}
                                {filter.isAnyValue ? <FilterToken>Any</FilterToken> : null}
                                <i className="ti ti-chevron-down"></i>
                            </FilterItem>
                        </Tooltip>
                    </div>
                </Popover.Target>
                <SectionedPopover>
                    <DataFilterOptions
                        valueRendererProvider={valueRendererProvider}
                        filter={filter}
                        close={close}
                        renderFieldPicker={renderFieldPicker}
                        onEditing={setEditing}
                        allowPin={allowPin}
                    />
                </SectionedPopover>
            </Popover>
            {showOptions ? <Overlay onClick={() => (!editing ? close() : null)} opacity={0} color="#000" zIndex={5} /> : null}
        </>
    );
}

export const AddFilterButton = forwardRef<HTMLButtonElement, { onClick: () => void; icon?: string }>(function AddFilterButton(
    { onClick, icon }: { onClick: () => void; icon?: string },
    ref
) {
    const theme = useMantineTheme();
    return (
        <ActionIcon
            data-atid="GlobalFilterButton"
            ref={ref}
            variant="outline"
            onClick={onClick}
            radius="xl"
            size={30}
            style={{
                borderColor: '#0002',
                color: theme.colors.primary[6],
                background: colorPalette.fffColor,
                margin: 'var(--item-margin)',
                height: '30px',
                width: '80px',
            }}
        >
            <Tooltip label="Add Filter" withArrow withinPortal position="bottom">
                <Text size={14}>
                    <i className={icon ?? 'ti ti-filter'}></i> Filter
                </Text>
            </Tooltip>
        </ActionIcon>
    );
});

export function DataFilterOptions({
    filter,
    close,
    renderFieldPicker,
    onEditing,
    valueRendererProvider,
    allowPin,
}: {
    filter: FilterExpr;
    close: () => void;
    renderFieldPicker: FieldPickerRenderer;
    onEditing: (editing: boolean) => void;
    valueRendererProvider: ScopedValueRendererProvider;
    allowPin?: boolean;
}) {
    const theme = useMantineTheme();
    const [editingField, setEditingField] = useState(false);
    const [editingOp, setEditingOp] = useState(false);
    useEffect(() => onEditing(editingField || editingOp), [editingField, editingOp]);
    useEvent(filter.changed);
    useEvent(filter.onPinChanged);
    const apply = useCallback(() => {
        filter.apply();
        close();
    }, [close, filter]);
    const remove = useCallback(() => {
        filter.delete();
        close();
    }, [filter, close]);
    const pin = useCallback(() => {
        if (filter.pinned) {
            filter.unpin();
        } else {
            filter.pin();
        }
    }, [filter]);

    const applyPin = useCallback(() => {
        filter.applyPin();
        close();
    }, [filter]);

    const ValueComponent = getValueComponent(filter, valueRendererProvider);
    const disabled = !filter.isValid() && !filter.pinned;

    const canPin = allowPin && filter.isPinnable;

    return filter.operation == 'And' ? (
        <Box sx={{ width: 325 }}>This filter cannot be edited or deleted.</Box>
    ) : (
        <>
            <SectionedPopoverToolbar style={{ marginBottom: 12 }}>
                <Group position="right">
                    <Button onClick={filter.pinned ? applyPin : apply} disabled={disabled} variant="filled" data-atid="ApplyFilterButton">
                        Apply
                    </Button>
                    <Button onClick={close} variant="outline" data-atid="CancelFilterButton">
                        Cancel
                    </Button>
                </Group>
            </SectionedPopoverToolbar>
            <Box sx={{ width: 300 }}>
                <Group position="apart">
                    <Text>Edit filter</Text>
                    <Group>
                        {canPin && (
                            <Tooltip label={filter.pinned ? 'Unpin Filter' : 'Pin Filter'} position="right" withinPortal withArrow>
                                <ActionIcon
                                    sx={{
                                        ['&:hover svg']: { stroke: theme.colors.primary[5] },
                                        transition: 'all 300ms',
                                        transform: `rotate(${filter.pinned ? 0 : 90}deg)`,
                                    }}
                                    color={filter.pinned ? 'primary.6' : 'gray.6'}
                                    onClick={pin}
                                    data-atid="PinFilterIcon"
                                >
                                    <Pinned size={16} />
                                </ActionIcon>
                            </Tooltip>
                        )}
                        <Tooltip label="Remove Filter" position="right" withinPortal withArrow>
                            <ActionIcon color="error" onClick={remove} data-atid="RemoveFilterTrashCan">
                                <i className="ti ti-trash"></i>
                            </ActionIcon>
                        </Tooltip>
                    </Group>
                </Group>
                <Space h="md" />
                <FilterFieldPicker filter={filter} renderFieldPicker={renderFieldPicker} onEditing={setEditingField} />
                <Space h="md" />
                <OperationPicker filter={filter} onEditing={setEditingOp} />
                <Space h="md" />
                {ValueComponent ? <ValueComponent filter={filter} apply={apply} /> : null}
                <Space h="md" />
            </Box>
        </>
    );
}

function getValueComponent(
    filter: FilterExpr,
    valueRendererProvider: ScopedValueRendererProvider,
    empty: ValueRenderer = DataFilterOther,
    dateType: 'calendar' | 'text' = 'calendar'
) {
    const type = filter.getFilterType();
    const hasValueComponent = filter.operationHasValue();
    const customValueRenderer = valueRendererProvider(filter);
    const ValueComponent = !hasValueComponent
        ? null
        : customValueRenderer
        ? customValueRenderer
        : type === 'string'
        ? DataFilterString
        : type === 'number'
        ? DataFilterNumber
        : type === 'date' && dateType === 'calendar'
        ? DataFilterSingleDate
        : type === 'date' && dateType === 'text'
        ? DataFilterSingleDateText
        : type === 'boolean'
        ? DataFilterBool
        : empty;
    return ValueComponent;
}

function FilterFieldPickerBase({
    filter,
    renderFieldPicker,
    onEditing,
    children,
}: {
    filter: FilterExpr;
    renderFieldPicker: FieldPickerRenderer;
    onEditing?: (editing: boolean) => void;
    children: (props: { error?: boolean; opened: boolean; toggle: () => void }) => ReactNode;
}) {
    const [opened, { open, close, toggle }] = useDisclosure(false);
    const onSelect = useCallback(
        (field: string) => {
            filter.setField(field);
            close();
        },
        [close, filter]
    );
    useEffect(() => onEditing?.(opened), [onEditing, opened]);
    const appearance = useContext(AppearanceCtx);
    const error = appearance.showErrors && !filter.hasField;
    return (
        <>
            <OptionOverlay onClick={close} enabled={opened} />
            <Popover opened={opened} withinPortal offset={0} shadow="md">
                <Popover.Target>{children({ error, opened, toggle })}</Popover.Target>
                <Popover.Dropdown p={0} data-atid="ChooseFilterOperator">
                    {renderFieldPicker(onSelect)}
                </Popover.Dropdown>
            </Popover>
        </>
    );
}

function FilterFieldCompactPicker({
    disabled,
    ...props
}: {
    disabled?: boolean;
    filter: FilterExpr;
    renderFieldPicker: FieldPickerRenderer;
    onEditing?: (editing: boolean) => void;
}) {
    const [fieldToken] = props.filter.getTokens();
    const text = fieldToken.name ? fieldToken.name : 'Select a field...';
    return (
        <FilterFieldPickerBase {...props}>
            {({ error, opened, toggle }) => {
                const state = error ? 'error' : opened ? 'open' : !fieldToken.name ? 'empty' : disabled ? 'disabled' : undefined;
                return (
                    <LineItemCompactToken left state={state} onClick={toggle} data-atid={`ChooseFilterField: ${text}`}>
                        {text}
                    </LineItemCompactToken>
                );
            }}
        </FilterFieldPickerBase>
    );
}

function FilterFieldPicker(props: { filter: FilterExpr; renderFieldPicker: FieldPickerRenderer; onEditing?: (editing: boolean) => void }) {
    const [fieldToken] = props.filter.getTokens();
    const text = fieldToken.name ? fieldToken.name : 'Choose field';
    return (
        <FilterFieldPickerBase {...props}>
            {({ error, opened, toggle }) => <OperandButton error={error} open={opened} onClick={toggle} text={text} atid="ChooseFilterField" />}
        </FilterFieldPickerBase>
    );
}

interface OperandButtonProps {
    text: ReactNode;
    onClick: () => void;
    disabled?: boolean;
    multiline?: boolean;
    open: boolean;
    error?: boolean;
    atid?: string;
    loading?: boolean;
}
const OperandButton = forwardRef(
    ({ text, onClick, disabled, multiline, open, error, atid, loading }: OperandButtonProps, ref: ForwardedRef<HTMLButtonElement>) => {
        return (
            <>
                <Input
                    ref={ref}
                    component={OperandButtonEl}
                    error={error}
                    type="button"
                    disabled={disabled}
                    multiline={multiline}
                    onClick={onClick}
                    data-atid={'FilterOperandTextInput:' + text}
                >
                    <Group p={0} position="apart" noWrap spacing={0}>
                        {text}
                        <DropdownOpener state={{ opened: open, disabled, loading, error }} onClick={onClick} />
                    </Group>
                </Input>
            </>
        );
    }
);
export const DataFilterOperandButton = OperandButton;

const OperandButtonEl = styled.button<{ error?: boolean }>`
    border-color: ${(p) => (p.error ? p.theme.colors.error[6] : undefined)};
    padding-right: 4px;
`;

function OperationPickerBase({
    filter,
    onEditing,
    children,
    width,
}: {
    filter: FilterExpr;
    onEditing?: (editing: boolean) => void;
    children: (props: { opened: boolean; toggle: () => void }) => ReactNode;
    width?: string | number;
}) {
    const [opened, { close, toggle }] = useDisclosure(false);
    const selectOperation = useCallback(
        (op: string) => {
            if (filter.operation !== op) {
                filter.setOperation(op);
            }
            close();
        },
        [filter, close]
    );
    useEffect(() => onEditing?.(opened), [onEditing, opened]);
    return (
        <>
            <OptionOverlay onClick={close} enabled={opened} />
            <Popover opened={opened} width={width ? undefined : 'target'} withinPortal offset={0} shadow="md">
                <Popover.Target>{children({ opened, toggle })}</Popover.Target>
                <Popover.Dropdown p={0}>
                    <Box>
                        <Picker
                            noFilter
                            nameAccessor={(o) => o.name}
                            selections={[]}
                            minimizeHeight
                            height={300}
                            items={filter.getOperationOptions()}
                            onChange={([o]) => selectOperation(o.op)}
                            mode="single"
                            width={width ?? '100%'}
                        />
                    </Box>
                </Popover.Dropdown>
            </Popover>
        </>
    );
}

function OperationPicker(props: { filter: FilterExpr; onEditing?: (editing: boolean) => void }) {
    const { filter } = props;
    const [, operationToken] = filter.getTokens();
    const text = operationToken?.name ? operationToken.name : 'Choose operator';
    return (
        <OperationPickerBase {...props}>
            {({ opened, toggle }) => <OperandButton open={opened} disabled={!filter} onClick={toggle} text={text} />}
        </OperationPickerBase>
    );
}

function OperationCompactPicker(props: { filter: FilterExpr; onEditing?: (editing: boolean) => void }) {
    const { filter } = props;
    const [fieldToken, operationToken] = filter.getTokens();
    const text = operationToken?.name ? operationToken.name : <>&mdash;</>;
    return (
        <OperationPickerBase {...props} width={150}>
            {({ opened, toggle }) => {
                const disabled = !fieldToken?.name;
                const state = disabled ? 'disabled' : opened ? 'open' : !operationToken?.name ? 'empty' : undefined;
                return (
                    <LineItemCompactToken state={state} onClick={disabled ? undefined : toggle}>
                        {text}
                    </LineItemCompactToken>
                );
            }}
        </OperationPickerBase>
    );
}

function OptionOverlay({ onClick, enabled }: { onClick: () => void; enabled: boolean }) {
    return <Portal>{enabled ? <Overlay onClick={onClick} opacity={0} zIndex={300} /> : null}</Portal>;
}

function ApplyButton({ apply }: { apply: () => void }) {
    return (
        <Tooltip withinPortal withArrow position="right" label="Apply filter">
            <ActionIcon color="success" onClick={apply}>
                <Check strokeWidth={3} />
            </ActionIcon>
        </Tooltip>
    );
}

const useValuePickerSx = (error?: boolean, input: boolean = true, inputType?: string) => {
    const theme = useMantineTheme();
    const appearance = useContext(AppearanceCtx);
    if (appearance.compact) {
        const state = error ? 'error' : 'empty';
        let result = getCompactOperandStyles({ right: true, theme, state });
        result = input ? getCompactValueInputPickerSx(result, inputType) : result;
        return { ...result };
    } else {
        return {};
    }
};
export function DataFilterString({ filter, apply }: { filter: FilterExpr; apply: () => void }) {
    useEvent(filter.changed);
    const onChange = useCallback(
        ((e) => {
            filter.setValue(e.currentTarget.value);
        }) as React.ChangeEventHandler<HTMLInputElement>,
        []
    );
    const valueProvider = useMemo(() => filter.getValueProvider(), [filter, filter.field]);
    const preferValuePicker = filter.operation === 'eq' || filter.operation === 'ne';
    const onPickerDone = useCallback(
        (values: any[]) => {
            filter.setValue(values);
        },
        [filter]
    );

    const appearance = useContext(AppearanceCtx);
    const error = appearance.showErrors && !filter.isValid();
    const showPicker = valueProvider && preferValuePicker;
    const sx = useValuePickerSx(error, !showPicker);

    return showPicker ? (
        <PickerInput
            canShowAny={filter.isAnyValue}
            error={error}
            key={filter.field}
            value={filter.value}
            onChange={onPickerDone}
            valueProvider={valueProvider}
        />
    ) : (
        <TextInput error={error} sx={{ flex: 1, ...sx }} autoFocus value={filter.value} onChange={onChange} />
    );
}

export function UtcDataFilterSingleDate(props: { filter: FilterExpr; apply: () => void }) {
    return <DataFilterSingleDate {...props} useUtc />;
}
export function DataFilterSingleDate({ filter, apply, useUtc }: { filter: FilterExpr; apply: () => void; useUtc?: boolean }) {
    useEvent(filter.changed);
    const [opened, { close, toggle }] = useToggle(false);
    const fmtSvc = useDi(FormatService);
    const onChange = useCallback(
        (value: Date | null | [Date, Date]) => {
            const values = !value ? [] : Array.isArray(value) ? value : [value];
            const converted = values?.filter((v) => !!v).map((v) => (useUtc ? fmtSvc.toUtcJsonShortDate(v, true) : fmtSvc.toJsonShortDate(v)));

            if (filter.operation === 'between') {
                const nextValue = converted.length === 2 ? converted : null;
                filter.setValue(nextValue);
            } else {
                filter.setValue(converted.length ? converted[0] : null);
            }
            close();
        },
        [filter, close, filter.operation]
    );

    const values = filter.getValueAsArray();
    const dates = values.map((v) => (!v ? null : useUtc ? fmtSvc.parseDateNoTime(v) : fmtSvc.toLocalDate(v)));
    const texts = dates
        .filter((v) => !!v)
        .map((v) => filter.formatValue(fmtSvc.parseDateNoTime(v!), fmtSvc.getFormatter('short-date' as NamedFormats)));
    const text =
        filter.operation !== 'between' ? (
            <>{texts[0] || <>&nbsp;</>}</>
        ) : texts.length !== 2 ? (
            <>Select range</>
        ) : (
            <>
                {texts[0]} &ndash; {texts[1]}
            </>
        );
    const rangeValue = useMemo(() => (dates?.length === 2 ? dates : [null, null]) as [Date | null, Date | null], [JSON.stringify(dates)]);
    const dateValue = useMemo(() => (values.length === 1 ? dates[0] : null), [JSON.stringify(dates)]);

    const appearance = useContext(AppearanceCtx);
    const error = appearance.showErrors && !filter.isValid();

    return (
        <>
            <OptionOverlay onClick={close} enabled={opened} />
            <Popover withinPortal opened={opened} onClose={close} offset={0} shadow="md">
                <Popover.Target>
                    {appearance.compact ? (
                        <LineItemCompactToken right state={opened ? 'open' : error ? 'error' : undefined} onClick={toggle}>
                            {text}
                        </LineItemCompactToken>
                    ) : (
                        <Box>
                            <OperandButton open={opened} onClick={toggle} text={text} />
                        </Box>
                    )}
                </Popover.Target>
                <Popover.Dropdown>
                    {filter.operation === 'between' ? (
                        <DateRangeCalendar value={rangeValue} onChange={onChange} />
                    ) : (
                        <Calendar value={dateValue} onChange={onChange} />
                    )}
                </Popover.Dropdown>
            </Popover>
        </>
    );
}
export function DataFilterSingleDateText({ filter, apply }: { filter: FilterExpr; apply: () => void }) {
    useEvent(filter.changed);
    const fmtSvc = useDi(FormatService);
    const onChange = useCallback(
        (value: Date | null) => {
            filter.setValue(value);
        },
        [filter]
    );
    const appearance = useContext(AppearanceCtx);
    const error = appearance.showErrors && !filter.isValid();
    const sx = useValuePickerSx(error, true, 'date');

    return (
        <DatePicker
            sx={sx}
            error={error}
            icon={<CalendarTime />}
            value={filter.value ? fmtSvc.toLocalDate(filter.value) : null}
            onChange={onChange}
        />
    );
}
export function DataFilterNumber({ filter }: { filter: FilterExpr; apply: () => void }) {
    useEvent(filter.changed);
    const appearance = useContext(AppearanceCtx);
    const error = appearance.showErrors && !filter.isValid();
    const sx = useValuePickerSx(error, true, 'number');

    return (
        <NumberInput
            sx={sx}
            autoFocus
            error={error}
            icon={<i className="ti ti-hash"></i>}
            value={filter.value}
            onChange={(e) => filter.setValue(e)}
        />
    );
}
export function DataFilterPercent({ filter }: { filter: FilterExpr; apply: () => void }) {
    useEvent(filter.changed);
    return (
        <NumberInput
            autoFocus
            icon={<i className="ti ti-percentage"></i>}
            value={typeof filter.value === 'number' ? filter.value * 100 : filter.value}
            onChange={(e) => filter.setValue(typeof e === 'number' ? e / 100 : undefined)}
        />
    );
}
export function PercentTokenProvider(filter: FilterExpr, tokens: IQueryToken[]) {
    return tokens.map((token) => {
        if (token.type === 'constant' && typeof filter.value === 'number') {
            const text = Math.round(filter.value * 100) + '%';
            return {
                expr: token.expr,
                name: text,
                text,
                type: 'constant',
            } as IQueryToken;
        }
        return token;
    });
}
export function DataFilterBool({ filter }: { filter: FilterExpr; apply: () => void }) {
    return (
        <Select
            data={[
                { value: 'y', label: 'True' },
                { value: 'n', label: 'False' },
            ]}
            value={filter.value === true ? 'y' : filter.value === false ? 'n' : ''}
            onChange={(v) => filter.setValue(v === 'y' ? true : v === 'n' ? false : undefined)}
        />
    );
}
export function DataFilterOther({ filter }: { filter: FilterExpr; apply: () => void }) {
    return <></>;
}
export function DataFilterDisabled({ filter }: { filter: FilterExpr; apply: () => void }) {
    return <OperandButton onClick={() => {}} open={false} disabled text="&nbsp;" />;
}
export function DataFilterCompactDisabled({ filter }: { filter: FilterExpr; apply: () => void }) {
    return (
        <LineItemCompactToken right state="disabled">
            {''}
        </LineItemCompactToken>
    );
}

interface PickerInputItem {
    value: any;
    label: string;
}
export function PickerInput({
    value,
    onChange,
    valueProvider,
    canShowAny,
    error,
}: {
    value: any;
    onChange: (value: any) => void;
    valueProvider: ValueProvider;
    canShowAny: boolean;
    error?: boolean;
}) {
    const filterState = useMemo(
        () => ({
            filter: new EventEmitter(''),
            labelLookup: new Map<string, string>(),
            getLabel(value: string) {
                return this.labelLookup.get(value) ?? value;
            },
        }),
        []
    );
    const [opened, { toggle, close }] = useDisclosure(false);
    const [items, setItems] = useState<string[]>([]);
    const [loading, setLoading] = useState(true);
    const [selections, setSelections] = useState<string[]>(value instanceof Array ? value : typeof value === 'string' ? [value] : []);
    const [showFilter, setShowFilter] = useState(false);
    const [showingMax, setShowingMax] = useState(false);
    const { classes } = useStyles();
    const theme = useMantineTheme();
    const badgeFloatStyle = useMemo(
        () =>
            ({
                background: '#fff',
                padding: '2px 8px',
                marginLeft: '-15px',
                marginTop: '-4px',
                borderRadius: '15px',
                fontSize: '14px',
                whiteSpace: 'normal',
                border: 'solid 1px ' + theme.colors.gray[4],
            } as CSSStyleDeclaration),
        []
    );
    const { floatOnMouseEnter: badgeMouseEnter } = useFloatedFullText(badgeFloatStyle);
    const loadItems = useCallback(
        async (filter: string = '') => {
            let items: string[];
            if (typeof valueProvider === 'function') {
                const values = await valueProvider(filter ?? '', 101);
                if (filter !== filterState.filter.value) {
                    return;
                }
                const results = values.slice(0, 100).filter((v: string | null | PickerInputItem) => v !== null);
                if (!filter) {
                    setShowFilter(results.length > 20);
                }
                items = results.map((v: string | PickerInputItem) => (typeof v === 'string' ? v : v.value));

                setShowingMax(items.length !== 0 && values.length > items.length);
                filterState.labelLookup = new Map<string, string>(
                    items.map((v: string | PickerInputItem) => (typeof v === 'string' ? [v, v] : [v.value, v.label]))
                );
            } else {
                items = valueProvider.map((v) => v.value);
                filterState.labelLookup = new Map(valueProvider.map((v) => [v.value, v.label]));
            }
            setItems(items);
        },
        [setShowFilter, setShowingMax, setSelections, setItems, valueProvider]
    );
    const filter = useEventValue(filterState.filter);
    useEffect(() => {
        setLoading(true);
        (async () => {
            try {
                await loadItems();
            } finally {
                setLoading(false);
            }
        })();
    }, []);
    const { start, clear } = useTimeout(
        async () => {
            loadItems(filterState.filter.value);
        },
        300,
        { autoInvoke: false }
    );
    const deselect = (item: string) => {
        apply(selections.filter((s) => s !== item));
    };
    const handleFilterChange = (text: string) => {
        filterState.filter.emit(text);
        clear();
        start();
    };
    const apply = useCallback(
        (items: string[]) => {
            setSelections(items);
            onChange(items);
        },
        [onChange, setSelections]
    );
    useEffect(() => {
        onChange(selections);
    }, [selections]);

    const appearance = useContext(AppearanceCtx);
    const text =
        !selections.length && canShowAny ? (
            'Any'
        ) : (
            <div>
                {selections.map((selection) => (
                    <Badge
                        key={selection}
                        px={4}
                        className={classes.badge}
                        rightSection={
                            <ActionIcon
                                component="span"
                                onClick={(e: { stopPropagation: () => void }) => {
                                    deselect(selection);
                                    e.stopPropagation();
                                }}
                                size="xs"
                            >
                                <X />
                            </ActionIcon>
                        }
                    >
                        <Box
                            onMouseOver={badgeMouseEnter}
                            sx={{ maxWidth: 225, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', textTransform: 'none' }}
                        >
                            {filterState.getLabel(selection)}
                        </Box>
                    </Badge>
                ))}
            </div>
        );
    return (
        <>
            <OptionOverlay onClick={close} enabled={opened} />
            <Popover opened={opened} withinPortal offset={0} shadow="md">
                <Popover.Target>
                    {appearance.compact ? (
                        <LineItemCompactToken right state={opened ? 'open' : error ? 'error' : undefined} onClick={toggle}>
                            {text}
                        </LineItemCompactToken>
                    ) : (
                        <OperandButton loading={loading} open={opened} error={error} multiline text={text} onClick={toggle} />
                    )}
                </Popover.Target>
                <Popover.Dropdown p={0}>
                    <Box my={showFilter ? undefined : 0} sx={{ width: 400 }}>
                        {loading ? (
                            <Box sx={{ position: 'relative' }}>
                                <LoadingOverlay visible />
                            </Box>
                        ) : (
                            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                {showFilter ? (
                                    <>
                                        <TextInput
                                            size="xs"
                                            p="xs"
                                            autoFocus
                                            placeholder="Search list"
                                            value={filter}
                                            onChange={(e) => handleFilterChange(e.currentTarget.value)}
                                            data-atid="FilterTextInput"
                                        />
                                        <Divider />
                                    </>
                                ) : null}
                                <Box sx={{ flex: 1, minHeight: 0, height: '100%' }}>
                                    <Picker
                                        nameAccessor={(v) => v}
                                        childAccessor={() => undefined}
                                        noFilter={true}
                                        selections={selections}
                                        renderItem={(o) => filterState.getLabel(o)}
                                        items={items}
                                        height={260}
                                        minimizeHeight
                                        inlineFullText
                                        onChange={apply}
                                        width={400}
                                    />
                                </Box>
                                {showingMax ? (
                                    <>
                                        <Divider variant="dashed" />
                                        <Text color="dimmed" size="xs" px="sm" py="xs">
                                            Showing top 100 results
                                        </Text>
                                    </>
                                ) : null}
                            </Box>
                        )}
                    </Box>
                </Popover.Dropdown>
            </Popover>
        </>
    );
}

interface IExprTokenProps {
    token: IQueryToken;
    maxWidth?: number;
    inline?: boolean;
}
export const ExprToken = forwardRef<HTMLDivElement, IExprTokenProps>(function ExprToken({ token, ...props }, ref) {
    const tokenProps = {
        'data-token-type': token.type,
        ...token.flags?.reduce((acc, flag) => ({ ...acc, [`data-token-flag-${flag}`]: true }), {}),
        ...props,
    };
    return (
        <FilterToken ref={ref} {...tokenProps}>
            {token.name}
        </FilterToken>
    );
});

type ValidationIssueTypes = 'Missing Field' | 'Missing Value';
type ValidationIssue = { issue: ValidationIssueTypes; filter: FilterExpr };

export class DataFilterModel {
    public fieldInfo!: IFieldInfoProvider;
    public queryDescriptor!: QueryDescriptorService;
    public loaded = EventEmitter.empty();
    public filters: FilterExpr[] = [];
    public filtersChanged = EventEmitter.empty();
    private appliedFiltersKey = '';
    public appliedFilterChanged = new EventEmitter<QueryExpr[]>([]);
    private pinnedfiltersKey = '';
    public pinnedFilterChanged = new EventEmitter<QueryExpr[]>([]);
    public globalFilter = new GlobalFilterModel();
    public viewInvalidated = EventEmitter.empty();
    public valueProviderFactory?: IValueProviderFactory;
    public tokenProvider?: TokenProvider;
    public autoApply = false;

    public constructor() {
        this.globalFilter.onTextApplied.listen(() => this.raiseFilterChanged());
    }

    public getFilterType(expr: QueryOperation) {
        return this.queryDescriptor.getBestOperandType(expr);
    }

    public load(
        filters: QueryExpr[],
        fieldInfo: IFieldInfoProvider,
        operationInfoProvider: IOperationInfoProvider | undefined,
        valueProviderFactory: IValueProviderFactory | undefined,
        tokenProvider: TokenProvider | undefined,
        pinnedFilters?: QueryExpr[]
    ) {
        this.fieldInfo = fieldInfo;
        this.valueProviderFactory = valueProviderFactory;
        this.tokenProvider = tokenProvider;
        this.queryDescriptor = new QueryDescriptorService(
            this.fieldInfo,
            new ExprTypeProvider(this.fieldInfo, operationInfoProvider),
            operationInfoProvider
        );
        //before we load filters filter the pinned filters out of the filters by field name. This way only one instance of a filter can exist

        const serializedPinnedExprs = pinnedFilters?.map((f) => this.serializeExpr(f));
        this.filters = filters.map((f) => this.createFilterExpr(f as QueryOperation, serializedPinnedExprs)) ?? [];

        this.checkPinChanges(false);
        this.checkAppliedFilters(false);
        this.viewInvalidated.emit();
    }

    public addFilters(...filters: QueryOperation[]) {
        const filterModels = filters.map((f) => this.createFilterExpr(f));
        this.filters.push(...filterModels);
        this.viewInvalidated.emit();
        this.raiseFilterChanged();
    }

    public addEmptyFilter = (atEnd?: boolean, raise?: boolean) => {
        const model = this.createFilterExpr({});
        this.addFilter(model, atEnd);
        if (raise) {
            this.raiseFilterChanged();
        }
    };

    public addOrOpenFilter(field: string) {
        const filter = this.queryDescriptor.getDefaultOperation({ Field: field });
        if (filter) {
            const model = this.createFilterExpr(filter);
            const existingModel = this.filters.find((f) => f.field === model.field);
            if (existingModel) {
                existingModel.showOptions.emit(true);
            } else {
                this.addFilter(model);
            }
        }
    }

    public getValidationIssues() {
        return this.filters.reduce((result, item) => {
            const issue: ValidationIssueTypes | null = !item.hasField ? 'Missing Field' : !item.isValid() ? 'Missing Value' : null;
            if (issue) {
                result.push({ issue, filter: item });
            }
            return result;
        }, [] as ValidationIssue[]);
    }

    public addFieldFilter(field: string) {
        const filter = this.queryDescriptor.getDefaultOperation({ Field: field });
        if (filter) {
            const model = this.createFilterExpr(filter);
            this.addFilter(model);
        }
    }

    private addFilter(model: FilterExpr, atEnd?: boolean) {
        if (atEnd) {
            this.filters.push(model);
        } else {
            this.filters.unshift(model);
        }
        this.viewInvalidated.emit();
        model.hasApplied = false;
        model.showOptions.emit(true);
    }

    private createFilterExpr(operation: Partial<QueryOperation>, pinnedExprs?: string[]) {
        const result = new FilterExpr(operation, this.queryDescriptor, this.valueProviderFactory, this.tokenProvider);
        result.onDelete.listen(() => this.delete(result));
        result.onApply.listen(() => this.ensureFilter(result));
        result.onPinChanged.listen(() => this.checkPinChanges());
        if (pinnedExprs) {
            const serializedExpr = this.serializeExpr(operation);
            result.pinned = pinnedExprs.includes(serializedExpr);
        }
        result.autoApply = this.autoApply;
        return result;
    }

    public delete(filterExpr: FilterExpr) {
        const idx = this.filters.findIndex((f) => f === filterExpr);
        if (idx >= 0) {
            this.filters.splice(idx, 1);
        }
        this.raiseFilterChanged();
    }

    public ensureFilter(filterExpr: FilterExpr) {
        if (!this.filters.some((f) => f === filterExpr)) {
            this.filters.push(filterExpr);
        }
        this.raiseFilterChanged();
    }

    public raiseFilterChanged() {
        this.filtersChanged.emit();
        this.checkAppliedFilters();
    }

    private checkAppliedFilters(raise: boolean = true) {
        const nextFilters = this.getFilters();
        const nextFiltersKey = JSON.stringify([nextFilters, this.globalFilter.appliedText]);

        if (nextFiltersKey !== this.appliedFiltersKey) {
            if (raise) {
                this.appliedFilterChanged.emit(nextFilters);
            }
            this.appliedFiltersKey = nextFiltersKey;
        }
    }

    private checkPinChanges(raise: boolean = true) {
        const nextFilters = this.getPinnedFilters();
        const nextFiltersKey = JSON.stringify(nextFilters);
        if (nextFiltersKey !== this.pinnedfiltersKey) {
            if (raise) {
                this.pinnedFilterChanged.emit(nextFilters);
            }
            this.pinnedfiltersKey = nextFiltersKey;
        }
    }

    public getFilters() {
        const validFilters: QueryExpr[] = [];
        for (const filter of this.filters) {
            validFilters.push(filter.getExpr() as QueryExpr);
        }
        return validFilters;
    }

    public getPinnedFilters() {
        const validFilters: QueryExpr[] = [];
        for (const filter of this.filters.filter((f) => f.pinned === true)) {
            validFilters.push(filter.getExpr() as QueryExpr);
        }
        return validFilters;
    }

    private serializeExpr(expr: Partial<QueryExpr>) {
        return JSON.stringify(expr);
    }
}

export class FilterExpr {
    public value: any;
    public get hasValue() {
        return this.value !== undefined && this.value !== null;
    }
    public operation: string;
    public field: string;
    public changed = EventEmitter.empty();
    public showOptions = new EventEmitter(false);
    public onDelete = EventEmitter.empty();
    public onApply = EventEmitter.empty();
    public onPinChanged = new EventEmitter<boolean>(false);
    public hasApplied = true;
    public autoApply = false;
    public pinned = false;

    public get hasField() {
        return !!this.field;
    }
    public get hasOperation() {
        return !!this.operation;
    }
    public get isPinnable() {
        return this.hasField || this.isValid();
    }
    public get isAnyValue() {
        return this.hasField && this.pinned && this.operation !== 'ne' && !this.isValueValid();
    }

    public constructor(
        private origFilter: Partial<QueryOperation>,
        public readonly queryDescriptor: QueryDescriptorService,
        private readonly valueProviderFactory?: IValueProviderFactory,
        private readonly tokenProvider?: TokenProvider
    ) {
        this.operation = origFilter?.Operation ?? '';
        this.value = origFilter?.Operands?.[1]?.Value;
        this.field = origFilter?.Operands?.[0]?.Field ?? '';
    }

    public getValueProvider() {
        return this.valueProviderFactory?.getValueProvider({ Field: this.field });
    }

    public getExpr() {
        return this.origFilter;
    }

    public getFilterType(expr?: QueryOperation) {
        return this.queryDescriptor.getBestOperandType(expr ?? this.getPendingExpr());
    }

    public isValid() {
        const filter = this.getPendingExpr();
        const operands = filter.Operands?.length ?? [];
        const shouldHaveValue = this.operationHasValue();
        return (operands === 1 && !shouldHaveValue) || (operands === 2 && shouldHaveValue && this.isValueValid());
    }

    private isValueValid() {
        return (
            (this.value instanceof Array && this.value.length > 0) ||
            (!(this.value instanceof Array) && this.value !== null && this.value !== undefined && this.value !== '')
        );
    }

    public getOperationOptions() {
        const type = this.queryDescriptor.getBestOperandType(this.getPendingExpr());
        const operations = this.queryDescriptor.getOperationOptions(type);
        const result = operations.map((o) => ({ op: o, name: this.queryDescriptor.getOperationName(o, type) }));
        return result;
    }

    public operationHasValue() {
        return this.operation !== 'isNull' && this.operation !== 'isNotNull';
    }

    public getTokens(expr?: Partial<QueryExpr> & { description?: string }) {
        expr ??= this.updateQueryExpr({} as QueryOperation);
        const result =
            'description' in expr
                ? [
                      {
                          text: expr.description!,
                          name: expr.description,
                          type: 'operation' as IQueryToken['type'],
                          op: expr,
                          expr,
                      },
                  ]
                : this.queryDescriptor.getTokensWithValueProvider(expr, this.getValueNameProvider());
        return this.tokenProvider ? this.tokenProvider(this, result) : result;
    }

    public getValueAsArray() {
        return !this.hasValue ? [] : this.value instanceof Array ? this.value : [this.value];
    }

    public formatValue(value: any, fallback: INamedFormatter) {
        return this.queryDescriptor.getFormatter({ Field: this.field })?.format(value) ?? fallback.format(value);
    }

    public getTitleTokens() {
        return this.getTokens(this.updateQueryExpr({} as QueryOperation)).slice(0, 2);
    }

    private getValueNameProvider() {
        const valueProvider = this.valueProviderFactory?.getValueProvider({ Field: this.field });
        const nameLookup = valueProvider instanceof Array ? new Map(valueProvider.map((item) => [item.value, item.label])) : undefined;
        const nameProvider = nameLookup
            ? { getName: (value: any) => (typeof value === 'string' ? [value] : value).map((v: unknown) => nameLookup.get(v) ?? value) }
            : undefined;
        return nameProvider;
    }

    public getToolTip() {
        return this.getTokens(this.getExpr() as QueryExpr)
            .map((t) => (t.type === 'constant' && this.isAnyValue ? 'Any' : t.text))
            .join(' ');
    }

    public setField(field: string) {
        if (this.field !== field) {
            this.field = field;
            this.setOperation(this.queryDescriptor.getDefaultOperation({ Field: field }).Operation);
            this.clearValue();
        }
        this.raiseChanged();
    }

    public setOperation(op: string) {
        this.operation = op;
        this.raiseChanged();
    }

    public clearValue() {
        this.value = undefined;
    }

    public setValue(value: any) {
        this.value = value;
        this.raiseChanged();
    }

    public delete = () => {
        this.onDelete.emit();
    };

    private raiseChanged() {
        this.changed.emit();
        if (this.autoApply) {
            this.apply();
        }
    }

    private getPendingExpr() {
        return this.updateQueryExpr({} as QueryOperation);
    }

    private updateQueryExpr(expr: Partial<QueryOperation>) {
        expr.Operation = this.operation;
        expr.Operands = [{ Field: this.field }];
        if (this.hasValue && this.operation !== 'isNull' && this.operation !== 'isNotNull') {
            expr.Operands.push({ Value: this.value });
        }
        return expr;
    }

    public pin() {
        this.pinned = true;
        this.updateQueryExpr(this.origFilter);
        this.onPinChanged.emit(true);
    }

    public unpin() {
        this.pinned = false;
        this.onPinChanged.emit(false);
    }

    public applyPin() {
        this.origFilter = this.updateQueryExpr(this.origFilter);
        this.onApply.emit();
    }

    public apply() {
        this.updateQueryExpr(this.origFilter);
        this.onApply.emit();
        this.hasApplied = true;
    }
}

const useStyles = createStyles((theme) => ({
    badge: {
        color: theme.colors.gray[8],
        borderColor: theme.colors.gray[4],
        backgroundColor: colorPalette.white,
    },
}));
