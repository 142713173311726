import { Company } from '@apis/Customers/model';
import { BaseResource } from '@apis/Resources/model';
import { GridChartSettings } from '@root/Components/Charts/GridChart';
import { ChartConfig } from '@root/Components/DashboardLayout/Charts/ChartRenderer';
import { ICompanyContextToken } from '@root/Services/Customers/CompanyContext';
import { QueryDatasource, QueryDatasourceMetadata } from '@root/Services/Query/QueryDatasource';
import { exprBuilder, SchemaService } from '@root/Services/QueryExpr';
import { ResourceQueryDatasourceFactory } from '@root/Services/Resources/ResourceQueryDatasourceFactory';
import { ResourceQueryService } from '@root/Services/Resources/ResourceService';
import { BellRinging, BorderAll, Packages, Report, X } from 'tabler-icons-react';
import { inject, injectable } from 'tsyringe';
import { BaseDatasourcePresetsBuilder, IDatasourcePresetsBuilderFactory, INotificationPresetItem } from './BasePresets';

class ResourceDatasourcePresetsBuilder extends BaseDatasourcePresetsBuilder {
    public constructor(datasourceName: string, private readonly queryDatasource: QueryDatasource, private readonly schemaSvc: SchemaService) {
        super(datasourceName);
    }

    public getPresets() {
        return this.createDatasourcePresets('Resources', <Packages />, [this.createExpiringSpAlert()], [this.createCustomResourceReportPreset()]);
    }

    // #region Alerts
    private createExpiringSpAlert(): INotificationPresetItem<any> {
        const xb = this.createExprBuilder<{
            'State.Value': string;
            SavingsPlanArn: string;
            End: Date;
            Commitment: string;
            TermDurationInSeconds: number;
            pExpiration: Date;
            pDaysPrior: number;
        }>();
        const id = this.createId('expiring-sp');
        const [sp, expires, value] = this.selectExprs({
            'Savings Plan': xb.model.SavingsPlanArn,
            Expiration: xb.model.End,
            Value: xb.sum(
                xb.convert<number>(xb.model.Commitment, 'number').times(xb.model.TermDurationInSeconds.dividedBy(60 * 60) as unknown as number)
            ),
        });

        const initialized = this.createBehavior(({ data }) => {
            const result = this.mergeConfig(
                {
                    Title: 'Savings Plan Expiration',
                    Description: 'Some savings plan will expire soon. Please review the details.',
                    ComponentConfig: {
                        ...this.createCustomChartConfig('grid'),
                        settings: {
                            columns: [
                                { id: 'spid', select: sp, type: 'string', formatter: 'string' },
                                { id: 'end', select: expires, type: 'date', formatter: 'short-date' },
                                { id: 'value', select: value, type: 'number', formatter: 'currency-2dec' },
                            ],
                            state: {
                                columns: [
                                    { id: 'spid', width: 300 },
                                    { id: 'end', width: 150 },
                                    { id: 'value', width: 150 },
                                ],
                                filters: [],
                                sort: [{ Expr: xb.resolve(xb.model.End), Direction: 'Asc' }],
                                groupBy: [],
                            },
                            allowFilter: false,
                        } as GridChartSettings,
                    },
                    Trigger: {
                        TriggerType: 'Threshold',
                        Threshold: {
                            ThresholdCondition: xb.resolve(xb.and(xb.model.End.before(xb.model.pExpiration), xb.model['State.Value'].eq('active'))),
                            ThrottlePeriod: 'Daily',
                        },
                    },
                    PresentationOptions: { presetId: id },
                    QueryOptions: {
                        Parameters: {
                            pDaysPrior: { Value: 14 },
                            pExpiration: xb.resolve(xb.addDate(xb.currentDate(), xb.model.pDaysPrior, 'day')),
                        },
                        DatasourceName: this.queryDatasource.name,
                        Filters: [],
                    },
                },
                data
            );
            return result;
        });

        const behavior = initialized
            .addForm(({ data }) => {
                return [
                    this.createSection(
                        'Alert Settings',
                        this.createSectionItem('Title and Description', ...this.createDescriptionInputs(data, 'Savings Plan Expiring Soon'))
                    ),
                ];
            })
            .addValidation(() => []);

        return {
            id,
            label: 'Expiring Savings Plans',
            description: 'Notify in advance when savings plans are near their expiration date.',
            icon: <BorderAll />,
            behavior,
        };
    }
    // #endregion

    // #region Reports
    private createCustomResourceReportPreset(): INotificationPresetItem<any> {
        const dimExpr = this.queryDatasource.getDefaultGroup();
        const metricExpr = this.queryDatasource.getDefaultValue();

        const filterInputs = this.createFilterSettings(this.queryDatasource, this.schemaSvc);
        const chartInput = this.createCustomChartBehavior(this.queryDatasource, this.schemaSvc);

        const behavior = this.createBehavior(({ data }) => {
            return this.mergeConfig(
                {
                    ComponentConfig: this.createCustomChartConfig('bar', dimExpr, metricExpr),
                    QueryOptions: {
                        Parameters: {},
                        Filters: [],
                        DatasourceName: this.queryDatasource.name,
                    },
                },
                data
            );
        })
            .addForm(({ data }) => {
                const chartSettings = chartInput(data);
                const filterSection = this.createSectionItem('Data Filters', filterInputs(data), { type: 'divider' });
                chartSettings.elements.push(filterSection);

                return [
                    chartSettings,
                    this.createSection(
                        'Report Settings',
                        this.createSectionItem('Title and Description', ...this.createDescriptionInputs(data, 'Untitled')),
                        this.createScheduleForm(data.Trigger?.Schedule!)
                    ),
                ];
            })
            .addValidation(() => []);

        return this.createCustomReportPreset(behavior);
    }
    // #endregion

    private createExprBuilder<TParams>() {
        type Model = BaseResource & TParams;
        const { builder } = exprBuilder<Model>();
        return builder;
    }
}
@injectable()
export class ResourceDatasourcePresetsBuilderFactory implements IDatasourcePresetsBuilderFactory<{}> {
    public constructor(
        @inject(ResourceQueryDatasourceFactory) private readonly datasourceFactory: ResourceQueryDatasourceFactory,
        @inject(ResourceQueryService) private readonly resourceQuerySvc: ResourceQueryService,
        @inject(ICompanyContextToken) private readonly company: Company
    ) {}

    public getSupportedDatasources(): QueryDatasourceMetadata[] {
        return [this.datasourceFactory.getMetadata()];
    }
    public async get(datasourceName: string, context: {}) {
        const datasource = await this.getDatasourceByName(datasourceName);
        const types = await datasource.schema.getSchema();
        const schemaSvc = SchemaService.create(types);

        return new ResourceDatasourcePresetsBuilder(datasourceName, datasource, schemaSvc);
    }

    private async getDatasourceByName(name: string) {
        switch (name.toLowerCase()) {
            case 'resources':
                return await this.datasourceFactory.getDatasource((q) => this.resourceQuerySvc.query(q, this.company.Id ?? 0));
            default:
                throw new Error(`Unsupported datasource ${name}`);
        }
    }
}
