import { TextInput } from '@mantine/core';
import { UserConfiguredBarChartSettings } from '@root/Components/Charts/BarChart';
import { AnchorButton } from '@root/Design/Primitives';
import {
    SettingsInputRow,
    SettingsLabel,
    SettingsSection,
    SettingsSectionBodyDivider,
    SettingsSectionItem,
    SettingsSectionItemBody,
    SettingsSectionItemHeader,
    SettingsSectionItemHeaderLabel,
} from '@root/Design/Settings';
import { useEvent } from '@root/Services/EventEmitter';
import { observer } from 'mobx-react';
import { useMemo } from 'react';
import { AntennaBars5, ArrowBigDownLines, Calculator, ChartBar, CursorText, Layout, Plus } from 'tabler-icons-react';
import {
    castSetter,
    ChartReaggOptions,
    DropdownSettings,
    EditorExprPicker,
    EditorFilterAccordion,
    FormatSettings,
    useGetterSetter,
    useEditorGroupSelectExpr,
    useEditorValueSelectExpr,
    useDescriptorUpdates,
    NumberSettingsInput,
} from './Components';
import { IChartEditor } from './Models';

export const BarSettings = observer(function BarSettings({ editor }: { editor: IChartEditor }) {
    const settings = useMemo(
        () =>
            editor.getChartSettings<UserConfiguredBarChartSettings>({
                labelAngle: -50,
                orientation: 'Vertical',
                maxStackItems: 15,
                metricLabel: '',
                reaggOptions: { limit: 20, sortBy: 'value', sortDir: 'desc', otherLabel: 'Other' },
                descriptors: [],
            }),
        []
    );

    useEvent(editor.onSettingsChanged);

    const settingAccessors = useGetterSetter(settings, 'format', 'xFormat', 'orientation', 'metricLabel', 'descriptors', 'maxStackItems');
    const { format, setFormat, xFormat, setXFormat, orientation, setOrientation, metricLabel, setMetricLabel, setDescriptors } = settingAccessors;
    const { maxStackItems, setMaxStackItems } = useGetterSetter(settings, 'maxStackItems');
    const [barFld, setBarFld] = useEditorGroupSelectExpr(editor, 0, { format: xFormat, formatSetter: setXFormat });
    const [stackFld, setStackFld, removeStackFld] = useEditorGroupSelectExpr(editor, 1);
    const [metricFld, setMetricFld] = useEditorValueSelectExpr(editor, 0, { format, formatSetter: setFormat, aliasType: 'user-friendly' });
    useDescriptorUpdates(setDescriptors, barFld, stackFld, metricFld);

    const typeName = orientation === 'Vertical' ? 'Column' : 'Bar';
    return (
        <>
            <SettingsSection title={`${typeName} Data`}>
                <SettingsSectionItem>
                    <SettingsSectionItemHeader>
                        <SettingsSectionItemHeaderLabel>{typeName} Options</SettingsSectionItemHeaderLabel>
                    </SettingsSectionItemHeader>
                    <SettingsSectionItemBody>
                        <DropdownSettings
                            icon={<AntennaBars5 style={{ rotate: '90deg' }} />}
                            label="Orientation"
                            onChange={castSetter(setOrientation)}
                            options={[
                                { label: 'Columns', value: 'Vertical' },
                                { label: 'Bars', value: 'Horizontal' },
                            ]}
                            value={orientation}
                            center
                        />
                        <SettingsSectionBodyDivider />
                        <SettingsInputRow>
                            <SettingsLabel icon={<ChartBar />}>{typeName}s</SettingsLabel>
                            <EditorExprPicker operations={[]} editor={editor} expr={barFld} types={['string', 'date']} onChange={setBarFld} />
                        </SettingsInputRow>
                        <ChartReaggOptions settings={settings} max={100} reaggProp="reaggOptions" limitLbl="Bar" />
                        <SettingsSectionBodyDivider />
                        {stackFld ? (
                            <>
                                <SettingsInputRow>
                                    <SettingsLabel icon={<Layout rotate={90} />}>Stack by</SettingsLabel>
                                    <EditorExprPicker
                                        editor={editor}
                                        onChange={setStackFld}
                                        operations={[]}
                                        types={['string']}
                                        expr={stackFld}
                                        onRemove={removeStackFld}
                                        removeLabel="Remove stacking"
                                    />
                                </SettingsInputRow>
                                <SettingsInputRow>
                                    <SettingsLabel icon={<ArrowBigDownLines />}>Max stacked items</SettingsLabel>
                                    <NumberSettingsInput max={50} min={1} onChange={castSetter(setMaxStackItems)} center value={maxStackItems} />
                                </SettingsInputRow>
                            </>
                        ) : (
                            <EditorExprPicker
                                editor={editor}
                                onChange={setStackFld}
                                expr={stackFld}
                                removeLabel="Remove stacking"
                                types={['string']}
                                opener={(open) => (
                                    <SettingsSectionItem style={{ flex: 1 }} anchor onClick={open}>
                                        <AnchorButton size="sm" icon={<Plus size={16} />} onClick={() => {}} text="Add Stacking" />
                                    </SettingsSectionItem>
                                )}
                            />
                        )}
                    </SettingsSectionItemBody>
                </SettingsSectionItem>
                <SettingsSectionItem>
                    <SettingsSectionItemHeader>
                        <SettingsSectionItemHeaderLabel>Metric Options</SettingsSectionItemHeaderLabel>
                    </SettingsSectionItemHeader>
                    <SettingsSectionItemBody>
                        <SettingsInputRow>
                            <SettingsLabel icon={<CursorText />}>Label</SettingsLabel>
                            <TextInput size="xs" value={metricLabel} onChange={(e) => setMetricLabel(e.currentTarget.value)} placeholder="None" />
                        </SettingsInputRow>
                        <SettingsInputRow>
                            <SettingsLabel icon={<Calculator />}>Metric</SettingsLabel>
                            <EditorExprPicker
                                operations={editor.allOps}
                                editor={editor}
                                types={['number']}
                                expr={metricFld}
                                onChange={setMetricFld}
                            />
                        </SettingsInputRow>
                        <FormatSettings onChange={castSetter(setFormat)} type="number" value={format} />
                    </SettingsSectionItemBody>
                </SettingsSectionItem>
            </SettingsSection>

            <EditorFilterAccordion editor={editor} />
        </>
    );
});
