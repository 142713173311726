import { UserDefinedNotificationConfig, UserDefinedNotificationQueryOptionsParameters } from '@apis/Notification/model';
import { QueryExpr } from '@apis/Resources';
import { Query } from '@apis/Resources/model';
import { Box, Center, Text } from '@mantine/core';
import { FillerSwitch } from '@root/Design/Filler';
import { RequestThrottlingService } from '@root/Services/AsyncBundler';
import { useDiMemo } from '@root/Services/DI';
import { EventEmitter, useEvent } from '@root/Services/EventEmitter';
import { ExprEvaluator } from '@root/Services/Query/ArrayDataSource';
import { QueryDatasource } from '@root/Services/Query/QueryDatasource';
import { QueryDatasourceCollection } from '@root/Services/Query/QueryDatasourceCollection';
import { cleanBoolExpr } from '@root/Services/QueryExpr';
import { inject, injectable } from 'tsyringe';
import { ChartConfig, StandaloneChartRenderer } from '../../DashboardLayout/Charts/ChartRenderer';
import { NotificationContent } from './Design';

@injectable()
class LiveNotificationConfigProcessorService {
    public dataInvalidated = EventEmitter.empty();
    public datasourceInvalidated = EventEmitter.empty();

    private dsReqThrottle = RequestThrottlingService.create();
    private datasourceKey = '';
    private datasource: QueryDatasource | undefined;
    private parameters: UserDefinedNotificationQueryOptionsParameters = {};

    private filterKey = '';
    private configKey = '';
    private filterChangeThrottle = RequestThrottlingService.create();
    private filters: QueryExpr[] = [];

    public constructor(
        @inject(QueryDatasourceCollection) private readonly datasourceCollection: QueryDatasourceCollection,
        @inject(ExprEvaluator) private readonly exprEvaluator: ExprEvaluator
    ) {}

    public processConfig(config: UserDefinedNotificationConfig) {
        return { datasource: this.processDatasource(config), filters: this.processComponent(config) };
    }

    private processDatasource(config: UserDefinedNotificationConfig) {
        const { DatasourceName, ApiParameters, Parameters } = config.QueryOptions ?? {};
        const dsName = DatasourceName ?? '';
        const apiCtx = ApiParameters ? this.exprEvaluator.project(ApiParameters, { parameters: Parameters }) : {};
        const dsKey = this.createDsKey(dsName, apiCtx);

        if (JSON.stringify(this.parameters) !== JSON.stringify(Parameters)) {
            this.parameters = Parameters ?? {};
        }

        if (dsKey !== this.datasourceKey) {
            this.datasourceKey = dsKey;
            this.dsReqThrottle.throttle(dsKey, () => this.createDatasource(dsKey, dsName, apiCtx));
            return undefined;
        } else {
            return this.datasource;
        }
    }

    private async createDatasource(expectedKey: string, datasourceName: string, apiCtx: unknown) {
        const datasource = await this.datasourceCollection.getDatasource(datasourceName ?? '', apiCtx);
        if (expectedKey === this.datasourceKey) {
            const source = datasource.source;
            const dataReqThrottle = RequestThrottlingService.create();

            datasource.source = (query: Query) => {
                query.Parameters = this.parameters;
                return dataReqThrottle.throttle(JSON.stringify(query), () => source(query));
            };

            this.datasource = datasource;
            this.datasourceInvalidated.emit();
        }
    }

    private createDsKey(datasourceName: string, apiCtx: unknown, context?: unknown) {
        return JSON.stringify([datasourceName, apiCtx, context]);
    }

    private processComponent(config: UserDefinedNotificationConfig) {
        const { Filters } = config.QueryOptions ?? {};
        const filterKey = JSON.stringify(Filters ?? []);

        if (filterKey !== this.filterKey) {
            this.filterKey = filterKey;
            this.filters = (Filters ?? []).filter((x) => !!x) as QueryExpr[];
            this.filterChangeThrottle.throttle(filterKey, async () => this.dataInvalidated.emit());
        } else {
            const configKey = JSON.stringify(config);
            if (configKey !== this.configKey) {
                this.configKey = configKey;
                this.dataInvalidated.emit();
            }
        }

        return this.filters;
    }
}

function LiveNotificationChartRenderer(props: { config: UserDefinedNotificationConfig; context: unknown }) {
    const { config } = props;
    const configProcessorSvc = useDiMemo(LiveNotificationConfigProcessorService, [config.PresentationOptions?.presetId]);
    const { datasource, filters } = configProcessorSvc.processConfig(config);
    const { ComponentConfig } = config;
    const componentCfg = ComponentConfig as unknown as ChartConfig;

    useEvent(configProcessorSvc.datasourceInvalidated);

    return (
        <Box sx={{ height: 400 }}>
            <FillerSwitch loading={!datasource}>
                {() => (
                    <StandaloneChartRenderer
                        rerenderNeeded={configProcessorSvc.dataInvalidated}
                        config={componentCfg}
                        datasource={datasource!}
                        filters={filters}
                    />
                )}
            </FillerSwitch>
        </Box>
    );
}

export function LiveNotificationContent({ config, context }: { config: UserDefinedNotificationConfig; context: unknown }) {
    const { Title, Description } = config;

    return (
        <NotificationContent title={Title ?? 'Untitled'} description={Description ?? ''}>
            {config.ComponentConfig?.Type === 'Chart' ? (
                <LiveNotificationChartRenderer context={context} config={config} />
            ) : (
                <Center sx={{ height: 300 }}>
                    <Text color="dimmed" italic>
                        Select notification type
                    </Text>
                </Center>
            )}
        </NotificationContent>
    );
}
