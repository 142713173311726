import styled from '@emotion/styled';
import React, { ComponentProps } from 'react';
import { forwardRef, useMemo } from 'react';

const whitespaceChars = new Set(
    ' \t\n\r\v\f\u00A0\u1680\u2000\u2001\u2002\u2003\u2004\u2005\u2006\u2007\u2008\u2009\u200A\u2028\u2029\u202F\u205F\u3000'.split('')
);

const OptimizedVisibleSpaces = React.memo(({ value }: { value: string }) => {
    const elements: React.ReactNode[] = [];
    let buffer = '';
    let spaceBuffer = '';
    const len = value.length;

    let isLeading = true; // Flag to indicate leading spaces

    for (let i = 0; i < len; i++) {
        let char = value[i];

        // Handle \r\n as a single whitespace
        if (char === '\r' && value[i + 1] === '\n') {
            i++; // Skip the next character '\n'
            spaceBuffer += '\r\n';
            continue;
        }

        if (whitespaceChars.has(char)) {
            spaceBuffer += char;
        } else {
            // Non-whitespace character
            if (spaceBuffer) {
                if (isLeading || spaceBuffer.length > 1) {
                    // Leading spaces or multiple consecutive spaces
                    elements.push(<VisibleSpaceEls key={elements.length} length={spaceBuffer.length} />);
                } else {
                    // Single space between words
                    buffer += spaceBuffer;
                }
                spaceBuffer = '';
                isLeading = false;
            }
            buffer += char;
            isLeading = false;
        }

        // Flush buffer when next character is whitespace or end of text
        if (buffer && (i === len - 1 || whitespaceChars.has(value[i + 1]) || (value[i + 1] === '\r' && value[i + 2] === '\n'))) {
            elements.push(<React.Fragment key={elements.length}>{buffer}</React.Fragment>);
            buffer = '';
        }
    }

    // Handle any remaining whitespace at the end
    if (spaceBuffer) {
        // Trailing spaces
        elements.push(<VisibleSpaceEls key={elements.length} length={spaceBuffer.length} />);
    }

    // Handle any remaining buffer
    if (buffer) {
        elements.push(<React.Fragment key={elements.length}>{buffer}</React.Fragment>);
    }

    return <>{elements}</>;
});

const spaceRegex = /^\s+|\s+$|\s{2,}/g;
function LegacyVisibleSpaces({ value }: { value: string }) {
    const fragments = useMemo(() => {
        return value.replace(spaceRegex, (v) => v.replaceAll(/\s/g, '\0')).split('\0');
    }, [value]);

    return <>{fragments.map((frag, i) => (frag === '' ? <VisibleSpace key={i} /> : frag))}</>;
}

export const VisibleSpaces = OptimizedVisibleSpaces;

export function VisibleSpace() {
    return <VisibleSpaceEl>⎵</VisibleSpaceEl>;
}
export function VisibleSpaceEls({ length }: { length: number }) {
    return <VisibleSpaceEl>{'⎵'.repeat(length)}</VisibleSpaceEl>;
}

export const VisibleSpaceEl = styled.span`
    background: #0001;
    padding: 2px;
    display: inline-block;
    height: 15px;
    line-height: 10px;
    border-radius: 1px;
    color: #889273;
    box-sizing: border-box;
    margin-right: 2px;
    letter-spacing: 2px;
`;

export const EmptyDataText = forwardRef<HTMLSpanElement, { text?: string } & ComponentProps<'span'>>(function EmptyData(
    { text = '« Empty »', ...spanProps }: { text?: string } & ComponentProps<'span'>,
    ref
) {
    return (
        <EmptyDataTextEl {...spanProps} ref={ref}>
            {text}
        </EmptyDataTextEl>
    );
});
const EmptyDataTextEl = styled.span`
    font-style: italic;
    color: ${(p) => p.theme.colors.gray[6]};
`;
