import { getAccountGetCompanyAccounts, getCompanyGetCompanyCloudProviders } from '@apis/Customers';
import { Company } from '@apis/Customers/model';
import { CompanyTenantPrereqService } from '@root/Components/Router/CompanyContent';
import { inject, injectable, Lifecycle, scoped, singleton } from 'tsyringe';
import { CspConnectionService } from './Connections/CspConnectionService';
import { BaseCacheByTenant } from './Customers/BaseCacheByTenant';
import { ICompanyContextToken } from './Customers/CompanyContext';

export type Platform = 'Aws' | 'Azure';

@singleton()
export class PlatformServiceTenantCache extends BaseCacheByTenant<Set<Platform>> {
    public constructor(
        @inject(CompanyTenantPrereqService) tenantPrereqSvc: CompanyTenantPrereqService,
        @inject(CspConnectionService) private readonly cspConnectionService: CspConnectionService
    ) {
        super(tenantPrereqSvc);
        this.cspConnectionService.connectionStateChanged.listen(() => this.invalidateAll());
    }
}

@scoped(Lifecycle.ContainerScoped)
export class PlatformService {
    private platforms = new Set<Platform>();
    public constructor(
        @inject(PlatformServiceTenantCache) private readonly tenantCache: PlatformServiceTenantCache,
        @inject(ICompanyContextToken) private readonly company: Company
    ) {}

    public async init() {
        this.platforms = await this.tenantCache.get(this.company?.Id ?? 0, async () => await this.getConnectedCloud());
    }

    public async initIfNeeded() {
        if (this.platforms.size === 0) {
            await this.init();
        }
    }

    public getPlatforms() {
        return this.platforms;
    }

    public hasPlatform(platform: Platform) {
        return this.platforms.has(platform);
    }

    public async getConnectedCloud() {
        const [providers, accounts] = await Promise.all([getCompanyGetCompanyCloudProviders(), getAccountGetCompanyAccounts()]);
        const result = new Set<Platform>();
        if (accounts.length) {
            result.add('Aws');
        }
        if (providers.length) {
            for (const provider of providers) {
                result.add(provider.CloudPlatform!);
            }
        }
        return result;
    }

    public getPickerOptions() {
        const result: { label: string; value: string }[] = [];
        if (this.hasPlatform('Aws')) {
            result.push({ label: 'AWS', value: 'Aws' });
        }
        if (this.hasPlatform('Azure')) {
            result.push({ label: 'Azure', value: 'Azure' });
        }
        return result;
    }
}
