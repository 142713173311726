import styled from '@emotion/styled';
import { MantineSize, MantineSizes } from '@mantine/core';
import { useDi } from '@root/Services/DI';
import { EventEmitter } from '@root/Services/EventEmitter';
import { useEffect } from 'react';
import { singleton } from 'tsyringe';
import { colorPalette } from './Themes';

export const ContentRoot = styled.div`
    height: 100%;
    flex: 1;
    overflow: hidden;
    width: 100%;
`;

/**
 * Page outer container, not scrollable, content laid out horizontally
 */
export const PageBody = styled.div`
    height: 100%;
    display: flex;
    overflow: hidden;
    width: 100%;
`;

/**
 * Scrollable container for page content
 */
export const PageContent = styled.div`
    flex: 1;
    height: 100%;
    overflow: auto;
    background: ${(p) => p.theme.colors.gray[1]};
`;

export const PageToolbar = styled.div`
    label: page-toolbar;
`;

export const PageSection = styled.div<{ height?: number | 'full' }>`
    height: ${(p) => (p.height === 'full' ? 'calc(100vh - 53px)' : typeof p.height === 'number' ? p.height + 'px' : 'auto')};
    padding: ${(p) => p.theme.spacing.lg}px;
    label: page-section;

    [class$='page-toolbar'] {
        margin: ${(p) => -p.theme.spacing.lg}px;
    }
`;

/**
 * Container for panel content, full height, aligns items vertically
 *
 * page hiearchy: PageContent > PaneledPage > **PanelContent**
 *
 * drawer hiearchy: **PanelContent**
 */
export const PanelContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    height: 100%;
    min-height: 0;
`;

/**
 * Container for the header content of a panel, content is spaced *apart* (flex: space-between)
 *
 * page hiearchy: PageContent > PaneledPage > PagePanel > **PanelHeader**
 *
 * drawer hiearchy: PanelContent > **PanelHeader**
 *
 * frequently use: <PanelHeader><Text size={20}>The Title</Text></PanelHeader>
 */
export const PanelHeader = styled.div<{ mode?: 'compact' }>`
    display: flex;
    align-items: center;
    padding: ${(p) => (p.mode === 'compact' ? '20px' : '12px 24px')};
    width: 100%;
    justify-content: space-between;
`;

export const PanelSubHeader = styled.div`
    display: flex;
    align-items: center;
    min-height: 36px;
    width: 100%;
    padding: 12px 24px;
    color: ${colorPalette.navHoverBgColor};
`;

/**
 * Container for main content of a panel, scrollable, consumes available height
 *
 * page hiearchy: PageContent > PaneledPage > PagePanel > **PanelBody**
 *
 * drawer hiearchy: PanelContent > **PanelBody**
 */
export const PanelBody = styled.div<{ noPadding?: boolean; bg?: string; mode?: 'compact' }>`
    padding: ${(p) => (p.noPadding ? 0 : p.mode === 'compact' ? 20 : p.theme.spacing.xl)}px;
    background: ${(p) => (p.bg ? p.bg : undefined)};
    flex: 1;
    overflow: auto;
`;

/**
 * Container for toolbar of a panel, aligns content right
 *
 * page hiearchy: PageContent > PaneledPage > PagePanel > **PanelToolbar**
 *
 * drawer hiearchy: PanelContent > **PanelToolbar**
 */
export const PanelToolbar = styled.div<{ padding?: MantineSize }>`
    margin: ${(p) => (p.padding ? p.theme.spacing[p.padding] : p.theme.spacing.lg)}px;
    display: flex;
    justify-content: end;
    align-items: center;
    flex: 0;
    Button {
        margin-right: 10px;
    }
`;

/**
 * Container for a page with content panels
 *
 * page hiearhcy: PageContent > **PaneledPage** > PagePanel
 */
export const PaneledPage = styled.div`
    height: 100%;
    display: flex;
    min-width: 0;
    align-items: stretch;
`;

/**
 * Container for content of a PaneledPage
 *
 * page hierarchy: PageContent > PaneledPage > **PagePanel**
 */
export const PagePanel = styled(PanelContent)<{ size: number | 'fill' | 'lg' | 'md' | 'sm'; padded?: boolean }>`
    padding: ${(p) => (p.padded ? p.theme.spacing.lg : null)}px;
    flex-grow: ${(p) => (p.size === 'fill' ? 1 : 0)};
    flex-shrink: ${(p) => (p.size === 'fill' ? 1 : 0)};
    flex-basis: ${(p) =>
        p.size === 'fill' ? undefined : p.size === 'lg' ? '66%' : p.size === 'md' ? '50%' : typeof p.size === 'number' ? p.size + 'px' : '33%'};
    min-width: 0;
`;

@singleton()
export class LayoutService {
    public windowSizeInvalidated = EventEmitter.empty();

    public raiseSizeInvalidated = () => this.windowSizeInvalidated.emit();

    public attach = () => {
        const { x, y } = getScrollBarDimensions();
        document.body.style.setProperty('--native-scrollbar-width', `${x}px`);
        document.body.style.setProperty('--native-scrollbar-height', `${y}px`);

        window.addEventListener('resize', this.raiseSizeInvalidated);

        return this.detach;
    };

    public detach = () => {
        window.removeEventListener('resize', this.raiseSizeInvalidated);
    };
}

export function useFullScreenCloudBg() {
    useEffect(() => {
        const originalBg = document.body.style.background;
        const originalBgSize = document.body.style.backgroundSize;
        document.body.style.background = `url('https://cdn.cloudsaver.com/images/background/platform_background_light.jpg') 50% 50% no-repeat fixed`;
        document.body.style.backgroundSize = `cover`;
        return () => {
            document.body.style.background = originalBg;
            document.body.style.backgroundSize = originalBgSize;
        };
    }, []);
}

let scrollBarDimensions: { x: number; y: number } | null = null;
const getScrollBarDimensions = () => {
    if (!scrollBarDimensions) {
        const div = document.createElement('div');
        div.style.overflow = 'scroll';
        div.style.position = 'absolute';
        div.style.top = '-9999px';
        document.body.appendChild(div);
        const { width: y, height: x } = div.getBoundingClientRect();
        scrollBarDimensions = { x, y };
        document.body.removeChild(div);
    }
    return scrollBarDimensions;
};
/**
 * Get dimensions of browser's native scrollbar as:
 * {
 *      x: int, // scrollbar width in pixels
 *      y: int  // scrollbar height in pixels
 * }
 */
export function useScrollBarDimensions() {
    return getScrollBarDimensions();
}

/**
 * App-root hook to init layout info and events
 */
export function useLayoutInfo() {
    const layoutSvc = useDi(LayoutService);
    useEffect(layoutSvc.attach, []);
}
