import type { Company, UserListItem } from '@apis/Customers/model';
import { postJobCompanyQuery } from '@apis/Jobs';
import { Anchor, Drawer, Skeleton, Space } from '@mantine/core';
import { ICompanyContextToken } from '@root/Services/Customers/CompanyContext';
import { useDi, useDiContainer } from '@root/Services/DI';
import { EventEmitter, useEvent, useEventValue } from '@root/Services/EventEmitter';
import { ActivityDetailsOpener } from '@root/Components/Actions/ActionDetails';
import { useMemo, useEffect, useState, ReactNode } from 'react';
import { inject, injectable } from 'tsyringe';
import { DataGrid } from '../DataGrid';
import { DataGridModel } from '../DataGrid/DataGridModel';
import { ColumnConfig, ColumnGroupConfig, IDataSource } from '../DataGrid/Models';
import { DataColumnConfig, DataGridState } from '../DataGrid/Models';
import { formatDateTime } from './model';
import { queryBuilder } from '@root/Services/QueryExpr';
import { IQueryExpr } from '@apis/Jobs/model/iQueryExpr';
import { getUserGetCompanyUsers } from '@apis/Customers';
import { AnonymousJob, Job, QueryField } from '@apis/Resources';
import { ActivityItemAdapter, ActivityItemModel, JobStatus } from './ActivityPanel/ActivityTypes';
import { JobHierarchyProgress, JobService } from '@root/Services/Jobs/JobService';
import { FormatService } from '@root/Services/FormatService';
import { IValueProviderFactory } from '@root/Services/Query/QueryDatasource';
import { AnonymousQueueJobJobStatus } from '@apis/Jobs/model';

class LazyLoadingJobStatus {
    private _status?: JobHierarchyProgress;
    private _summary?: ActivityItemModel;

    public loading = new EventEmitter(true);
    public get status() {
        this.init();
        return this._status!;
    }
    public get summary() {
        if (!this._summary) {
            this._summary = this.activityItemAdapter.getActivityModel({ job: this.job, status: this.status });
        }
        return this._summary!;
    }
    public get statusName() {
        return this.activityItemAdapter.getStatusName(this.summary);
    }
    public get isLoading() {
        return this.loading.value;
    }

    public constructor(private grid: ActionsGridModel, public job: AnonymousJob, private activityItemAdapter: ActivityItemAdapter) {}
    public init() {
        if (!this._status) {
            this._status = { Created: 0, Failed: 0, Started: 0, Succeeded: 0, Canceled: 0 };
            this.loadStatus();
        }
    }
    private async loadStatus() {
        const status = await this.grid.loadStatus(this.job.HierarchyId!);
        if (status) {
            this._summary = undefined;
            this._status = status;
        }
        this.loading.emit(false);
    }
}

@injectable()
export class ActionsGridModel {
    public dataGrid?: DataGridModel;
    public isLoading = new EventEmitter<boolean>(true);
    public availableColumns: DataColumnConfig<Job>[] = [];
    public state: DataGridState = { columns: [], filters: [], sort: [] };
    public datasource?: IDataSource<LazyLoadingJobStatus>;
    public onActivitySelected = new EventEmitter<Job | undefined>(undefined);

    private pendingStatus: string[] = [];
    private progressLookup = new Map<string, { promise: Promise<JobHierarchyProgress>; resolver: (p: JobHierarchyProgress) => void }>();
    private statusRequestThrottle: any = 0;
    private lookupThrottle = 100;

    public constructor(
        @inject(ICompanyContextToken) private readonly company: Company,
        @inject(ActivityItemAdapter) private readonly activityItemAdapter: ActivityItemAdapter,
        @inject(JobService) private readonly jobService: JobService
    ) {}
    public async init() {
        try {
            this.isLoading.emit(true);
            this.createBasicDatasource();
        } finally {
            this.isLoading.emit(false);
        }
    }

    public attachGrid = (grid: DataGridModel) => {
        this.dataGrid = grid;
    };

    public async loadStatus(hierarchyId: string) {
        if (this.progressLookup.has(hierarchyId)) {
            return await this.progressLookup.get(hierarchyId)?.promise;
        }
        let resolver = (r: JobHierarchyProgress) => {};
        const promise = new Promise<JobHierarchyProgress>((r) => (resolver = r));
        this.progressLookup.set(hierarchyId, { promise, resolver });
        this.pendingStatus.push(hierarchyId);
        clearTimeout(this.statusRequestThrottle);
        this.statusRequestThrottle = setTimeout(async () => {
            const hierarchyIds = this.pendingStatus.splice(0, Infinity);
            const progress = await this.jobService.getHierarchyProgressLookup(hierarchyIds);
            for (const [key, value] of progress) {
                this.progressLookup.get(key)?.resolver(value);
            }
        }, this.lookupThrottle);
        return await promise;
    }

    public createBasicDatasource() {
        const grid = this;
        this.datasource = {
            async getPage(start: number, end: number, state: DataGridState) {
                let where: IQueryExpr | undefined = grid.getQuery(state);
                let query = queryBuilder<Job>()
                    .where((b) => b.model.ParentId!.isNull())
                    .build();

                if (where != undefined) {
                    where = { Operation: 'and', Operands: [where, query.Where] };
                } else {
                    where = query.Where;
                }

                const response = await postJobCompanyQuery({
                    IncludeSchema: false,
                    IncludeCount: true,
                    Where: where,
                    Skip: start,
                    Take: end - start + 1,
                    Sort: state.sort,
                });
                const results = ((response.Results as AnonymousJob[]) ?? []).map((r) => new LazyLoadingJobStatus(grid, r, grid.activityItemAdapter));

                return { items: results, total: response.Count };
            },
        } as IDataSource<LazyLoadingJobStatus>;
    }

    public getJobTypes() {
        const result = [...this.activityItemAdapter.getJobTypes()].map(([value, label]) => ({ label, value }));
        result.sort((a, b) => a.label.localeCompare(b.label, undefined, { sensitivity: 'base' }));
        return result;
    }

    public getQuery(state?: DataGridState) {
        const { filters } = state ?? this.dataGrid?.gridState ?? {};
        const where = filters?.length ? { Operation: 'and', Operands: filters } : undefined;
        return where;
    }

    private formatUser(user: UserListItem) {
        return user.FirstName || user.LastName ? `${user.FirstName} ${user.LastName}` : user.EMail ?? 'Unknown';
    }

    public getUserFilterOptions(users: UserListItem[] | undefined) {
        const result = [] as { label: string; value: number | null }[];
        if (users) {
            result.push(...users.map((u) => ({ label: this.formatUser(u), value: u.Id ?? 0 })));
        }
        result.sort((a, b) => a.label.localeCompare(b.label, undefined, { sensitivity: 'base' }));
        result.push({ label: 'System', value: null });
        return result;
    }
}

function JobStatusField({ status, render }: { status: LazyLoadingJobStatus; render: () => ReactNode }) {
    useEvent(status.loading);
    return <>{render()}</>;
}

export function ActionLogGrid({ onModelLoaded }: { onModelLoaded?: (model: ActionsGridModel) => void }) {
    const [users, setUsers] = useState<UserListItem[]>();
    const formatSvc = useDi(FormatService);

    useEffect(() => {
        (async () => {
            getUserGetCompanyUsers().then((users) => {
                setUsers(users);
            });
        })();
    }, []);

    const di = useDiContainer();
    const model = useMemo(() => {
        const result = di.resolve(ActionsGridModel);
        result.init();
        return result;
    }, []);
    useEvent(model.isLoading);
    const resource = useEventValue(model.onActivitySelected);

    const handleModelLoaded = (dataGrid: DataGridModel) => {
        model.attachGrid(dataGrid);
        onModelLoaded?.(model);
    };

    const datagridColumns = useMemo(
        () =>
            [
                {
                    header: 'Type',
                    defaultWidth: 250,
                    id: 'Type',
                    cellRenderer: (item) => (
                        <JobStatusField
                            status={item}
                            render={() => {
                                item.init();
                                return <Anchor onClick={() => model.onActivitySelected.emit(item.job as Job)}>{item.summary.title}</Anchor>;
                            }}
                        />
                    ),
                    defaultFixed: true,
                    noRemove: true,
                    noSort: true,
                    filter: {
                        filterType: 'string',
                        name: 'Type',
                        filterField: 'Type',
                    },
                },
                {
                    header: 'Description',
                    defaultWidth: 350,
                    id: 'Description',
                    cellRenderer: (item) => item.summary.description,
                    defaultFixed: true,
                    noRemove: true,
                    noSort: true,
                },
                {
                    header: 'Requested By',
                    defaultWidth: 150,
                    id: 'RequestedBy',
                    noSort: true,
                    cellRenderer: (item) => {
                        let name = 'System';
                        if (item.job.RequestedById) {
                            const user = users?.find((data) => data.Id === item.job.RequestedById);
                            if (user) {
                                name = user.FirstName + ' ' + user.LastName;
                            }
                        }

                        return <>{name}</>;
                    },
                    filter: {
                        filterType: 'string',
                        name: 'RequestedById',
                        filterField: 'RequestedById',
                    },
                },
                {
                    header: 'Queued At',
                    defaultWidth: 250,
                    id: 'QueuedAt',
                    cellRenderer: (item) => formatSvc.toLocal(item.job.QueuedAt),
                    sortField: 'QueuedAt',
                    filter: {
                        filterType: 'string',
                        name: 'QueuedAt',
                        filterField: 'QueuedAt',
                    },
                },
                {
                    header: 'Finished At',
                    defaultWidth: 250,
                    id: 'FinishedAt',
                    cellRenderer: (item) => (
                        <JobStatusField
                            status={item}
                            render={() => (item.isLoading ? <Skeleton height={24} radius="xl" my={3} /> : formatSvc.toLocal(item.status.lastDate))}
                        />
                    ),
                    filter: {
                        filterType: 'string',
                        name: 'FinishedAt',
                        filterField: 'FinishedAt',
                    },
                },
                {
                    header: 'Status',
                    defaultWidth: 150,
                    id: 'Status',
                    cellRenderer: (item) => (
                        <JobStatusField
                            status={item}
                            render={() => (item.isLoading ? <Skeleton height={24} radius="xl" my={3} /> : item.statusName)}
                        />
                    ),
                    noSort: true,
                },
            ] as ColumnConfig<LazyLoadingJobStatus>[],
        [users]
    );
    const filterValueProvider = useMemo(
        () =>
            ({
                getValueProvider(field: QueryField) {
                    if (field.Field === 'Type') {
                        return model.getJobTypes();
                    } else if (field.Field === 'RequestedById') {
                        return model.getUserFilterOptions(users);
                    }
                },
            } as IValueProviderFactory),
        [users]
    );
    const defaultState: DataGridState = {
        sort: [{ Direction: 'Desc', Expr: { Field: 'QueuedAt' } }],
        filters: [{ Operation: 'isNotNull', Operands: [{ Field: 'RequestedById' }] }],
        columns: [
            { id: 'Type', width: 250 },
            { id: 'Description', width: 350 },
            { id: 'Status', width: 180 },
            { id: 'RequestedBy', width: 200 },
            { id: 'QueuedAt', width: 180 },
            { id: 'FinishedAt', width: 180 },
        ],
    };

    return model.isLoading.value ? (
        <></>
    ) : users ? (
        <>
            <DataGrid
                dataSource={model.datasource!}
                statePersistence={{ key: 'Activity Log' }}
                state={defaultState}
                columns={datagridColumns}
                onModelLoaded={handleModelLoaded}
                filterValueProvider={filterValueProvider}
                showRefresh
            />
            <ActivityDetailsOpener item={resource} onClose={() => model.onActivitySelected.emit(undefined)} />
        </>
    ) : (
        <></>
    );
}
