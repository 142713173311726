import {
    deleteCompanyBulkDeleteCompanies,
    getRbacGetRolesByCompany,
    getUserGetCompanyUsers,
    postRbacBulkAssignMspUsers,
    postRbacBulkRemoveMspUsers,
} from '@apis/Customers';
import { CompanyStat, CompanyType, Role, UserListItem } from '@apis/Customers/model';
import { Button, Divider, Group, MultiSelect, Popover, Select, SelectItem, Space, Text } from '@mantine/core';
import { useModals } from '@mantine/modals';
import { confirmAction } from '@root/Components/CompanyInfo/Administration/ConfirmActionDialog';
import { AddCompanyInfo } from '@root/Components/CompanyStats/AddCompanyInfo';
import { CompanyStatsGrid } from '@root/Components/CompanyStats/CompanyStatsGrid';
import { colorPalette } from '@root/Design/Themes';
import { useDi, useDiComponent } from '@root/Services/DI';
import { EventEmitter, useEvent } from '@root/Services/EventEmitter';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { CompanyStatsInvoicesGrid } from './CompanyStatsInvoicesGrid';
import styled from '@emotion/styled';
import { MspService } from '@root/Services/MspService';
import { useAuthZValues } from '@root/Services/AuthorizationService';

export interface MyCompaniesInvoicesGridProps {
    selectCompany: (selectedCompanyId: number) => void;
}

export const MyCompaniesInvoicesGrid = (props: MyCompaniesInvoicesGridProps) => {
    const refreshNeeded = useMemo(() => EventEmitter.empty(), []);
    const refChanged = useMemo(() => EventEmitter.empty(), []);
    const mspSvc = useDi(MspService);
    let selectedCompanies = useRef<number[]>([]);

    useEvent(refChanged);

    useEvent(mspSvc.companyListChanged, () => {
        refreshNeeded.emit();
    });

    const onCheckedCompanyChange = useCallback(async (items: CompanyStat[] | undefined) => {
        let hasCheckedItems = items && items?.length > 0;
        if (hasCheckedItems) {
            let companiesIds = items?.map((i) => +i.Id!) as number[];
            selectedCompanies.current = companiesIds;
            refChanged.emit();
        }
    }, []);

    //Todo: Check if we need to add something like Add Invoice
    //const modals = useModals();
    //const DiContainer = useDiComponent();
    // const openAddCompanyModal = () => {
    //     const id = modals.openModal({
    //         title: (
    //             <Text sx={{ padding: '0px 0px 0px 16px', margin: '0px' }} size={18} weight={600} color={colorPalette.darkTitleColor} align="center">
    //                 Add Company
    //             </Text>
    //         ),
    //         children: (
    //             <DiContainer>
    //                 <AddCompanyInfo
    //                     onClose={() => {
    //                         modals.closeModal(id);
    //                     }}
    //                     onSubmit={(companyId) => {
    //                         refreshNeeded.emit();
    //                         modals.closeModal(id);
    //                         props.selectCompany(companyId);
    //                     }}
    //                 />
    //             </DiContainer>
    //         ),
    //         closeOnClickOutside: false,
    //     });
    // };

    return (
        <CompanyStatsInvoicesGrid
            selectCompany={props.selectCompany}
            onCheckedCompanyChange={onCheckedCompanyChange}
            rightTopPlaceHolder={undefined}
            refreshNeeded={refreshNeeded}
        />
    );
};
