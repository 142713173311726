import { QueryExpr, QueryField, QueryOperation } from '@apis/Resources';
import { IQueryExpr, QuerySelectExpr } from '@apis/Resources/model';
import {
    Accordion,
    ActionIcon,
    Box,
    Button,
    createStyles,
    Divider,
    Group,
    Menu,
    NumberInput,
    Popover,
    SegmentedControl,
    Select,
    Space,
    Sx,
    Tooltip,
    useMantineTheme,
} from '@mantine/core';
import {
    ArrowBigDownLines,
    ArrowBigUpLines,
    CurrencyDollar,
    Filter,
    Plus,
    SortAscendingLetters,
    SortAscendingNumbers,
    SortDescendingLetters,
    SortDescendingNumbers,
    Trash,
} from 'tabler-icons-react';
import { useDisclosure } from '@mantine/hooks';
import { ChartMargin, IChartReaggConfig, IPlotFieldDescriptor } from '@root/Components/Charts/Common';
import { FilterItem, FilterToken, LineItemCompactToken } from '@root/Components/Filter/Design';
import { AddFilterButton, DataFilterModel, DataFilters, ExprToken } from '@root/Components/Filter/Filters';
import { IValueProviderFactory } from '@root/Services/Query/QueryDatasource';
import { IFieldInfoProvider, IQueryToken, operationLookup, ParameterInfoFlag, QueryDescriptorService } from '@root/Components/Filter/Services';
import { FieldPicker, FieldPickerTooltip } from '@root/Components/Picker/FieldPicker';
import { FlyoverOverlay } from '@root/Components/Picker/Flyover';
import { EventEmitter, useEvent, useEventValue, useToggle } from '@root/Services/EventEmitter';
import { NamedFormats, useFmtSvc } from '@root/Services/FormatService';
import { cleanBoolExpr, FieldInfo, groupExprs, SchemaService, sortedObjectId, traverseExpr, TypeInfo } from '@root/Services/QueryExpr';
import { observer } from 'mobx-react';
import React, { ForwardedRef, forwardRef, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { SettingsDivider } from './Design';
import { IChartEditor } from './Models';
import { AnchorButton, TooltipWhite, useReadonlyInputStyles } from '@root/Design/Primitives';
import {
    DatasourceSchemaContext,
    useContextualDatasource,
    useContextualFilterProps,
    useDatasourceSchemaCtx,
    withResolvedSchemaCtx,
} from '../../Filter/DatasourceContext';
import styled from '@emotion/styled';
import exp from 'constants';
import { SettingsInputRow, SettingsLabel } from '@root/Design/Settings';
import { OptionMenuButton, OptionMenuItems } from '@root/Components/Picker/OptionMenu';
import { CSSProperties } from '@emotion/react/node_modules/@emotion/serialize';
import { QueryDatasource } from '@root/Services/Query/QueryDatasource';
import { FilterSettingsSection } from '@root/Components/Settings/FilterSettings';

function getTokens(queryDescriptorSvc: QueryDescriptorService, expr: QueryExpr) {
    if ('Operation' in expr && traverseExpr(expr, (x) => 'Operation' in x && x.Operation === 'and')) {
        expr = { Operation: expr.Operation, Operands: [] };
    }
    return queryDescriptorSvc.getTokens(expr);
}

type ExprDeconstruction = { agg?: string; fieldExpr?: QueryField };
class ExprPickerModel {
    private _fieldAggs = new Map<FieldInfo, FieldInfo[]>();
    private _exprParts: ExprDeconstruction = {};
    private _filters: QueryExpr[] = [];
    private _validFilters: QueryExpr[] = [];
    private operations?: string[];
    private _exprChanged = new EventEmitter<QueryExpr | undefined>(undefined);
    private types?: string[];
    private name: string = '';

    public readonly schemaSvc;
    public readonly queryDescriptorSvc;
    public readonly dsDescription: { noun: string; nounPlural: string };

    public readonly invalidated = EventEmitter.empty();
    public readonly addFilter = EventEmitter.empty();
    public readonly filterStateChanged = new EventEmitter<{ valid: boolean; empty: boolean }>({ empty: true, valid: true });
    public get exprChanged() {
        return this._exprChanged;
    }

    public get canSetFilter() {
        return !!operationLookup.get(this._exprParts.agg ?? '')?.filterability;
    }

    public get canSetField() {
        return !this._exprParts.agg || !!operationLookup.get(this._exprParts.agg ?? '')?.params?.some((p) => p.flags?.includes('field'));
    }

    public get fieldInfo() {
        return !this.fieldExpr ? undefined : this.schemaSvc.getFieldWithId(this.fieldExpr.Field) ?? this.schemaSvc.getField(this.fieldExpr.Field);
    }

    public get opInfo() {
        return this.queryDescriptorSvc.getOperationInfo(this.agg ?? '');
    }

    public get fieldExpr() {
        return this._exprParts.fieldExpr;
    }

    public get filters() {
        return this._filters;
    }

    public get agg() {
        return this._exprParts.agg;
    }

    public get hasAgg() {
        return !!this._exprParts.agg;
    }

    public get isEmpty() {
        return !this._exprParts.agg && !this._exprParts.fieldExpr;
    }

    public get hasFilter() {
        return !!this.filters.length;
    }

    public get validFilters() {
        return this._validFilters;
    }

    public constructor(editor: IChartEditor) {
        this.schemaSvc = editor.schemaSvc;
        this.queryDescriptorSvc = editor.queryDescriptorSvc;
        this.dsDescription = editor.getDatasourceDescription();
    }

    public getExpr() {
        return this.reconstructExpr();
    }

    public init(expr: undefined | QuerySelectExpr, operations: undefined | ReadonlyArray<string>, types: undefined | string[]) {
        this._exprChanged.emit(expr?.Expr as QueryExpr);
        this.deconstructExpr(expr?.Expr as QueryExpr);
        this.operations = operations?.slice() ?? ['sum', 'avg', 'min', 'max', 'count', 'countuniquevalues', 'percent'];
        this.types = types;
        return this;
    }

    public updateName(name: string) {
        this.name = name;
        this.raiseInvalidated();
    }

    public getSelectedItem() {
        const fieldInfo = this.fieldExpr ? this.schemaSvc.getField(this.fieldExpr.Field) : undefined;
        if (fieldInfo && this.agg) {
            const fieldAggs = this.getOrCreateFieldAggs(fieldInfo);
            const op = this.getFacadeFieldOperation(fieldInfo) ?? '';
            if (op) {
                return fieldAggs?.find((f) => op === this.agg);
            }
        }
        return fieldInfo;
    }

    public raiseAddFilter = () => this.addFilter.emit();

    public getFilterTooltip() {
        const baseExpr = this.getBaseExpr();
        const name = `"${this.name || (!baseExpr ? '' : this.queryDescriptorSvc.getName(baseExpr))}"`;
        const { nounPlural } = this.dsDescription;
        return {
            empty: (
                <>
                    (Optional) Add Filters to calculate <strong>{name}</strong> for only specific {nounPlural}.
                </>
            ),
            valid: (
                <>
                    Filters are applied to calculate <strong>{name}</strong> for only specific {nounPlural}.
                </>
            ),
            invalid: (
                <>
                    Some filters are incomplete and do not yet apply to the <strong>{name}</strong> calculation.{' '}
                </>
            ),
        };
    }

    public getFieldPickerChildren = (item: FieldInfo | TypeInfo) => {
        if ('path' in item && item.isPrimitive && !item.supplementalInfo.Expr) {
            const result = this.getOrCreateFieldAggs(item);
            return result?.length ? result : undefined;
        } else if ('children' in item) {
            return item.children;
        } else {
            return undefined;
        }
    };

    public setFilters = (filters: QueryExpr[]) => {
        this._filters.splice(0, Infinity, ...filters);
        this.updateValidFilters(filters);
        this.raiseInvalidated();
    };

    public setField = (field: undefined | FieldInfo) => {
        if (field) {
            const agg = this.getFacadeFieldOperation(field);
            const canFilter = !!operationLookup.get(agg ?? '')?.filterability;
            const filterExpr = !canFilter ? undefined : this._filters?.length ? { Operation: 'and', Operands: this._filters } : undefined;
            if (!filterExpr) {
                this._filters?.splice(0, Infinity);
            }
            this.assignExprParts({ fieldExpr: { Field: field.path, Type: field.typeName }, agg });
            this.raiseInvalidated();
        }
    };

    public getUfTokens() {
        const baseExpr = this.getBaseExpr();
        const tokens = !baseExpr ? [] : this.queryDescriptorSvc.getTokens(baseExpr);
        const name = !tokens.length ? 'None' : tokens.map((t) => t.text).join(' ');

        return { tokens, name };
    }

    private deconstructExpr(expr?: QueryExpr) {
        if (!expr || 'Value' in expr) {
            this.assignExprParts();
        } else if ('Field' in expr) {
            this.assignExprParts({ fieldExpr: expr });
        } else if ('Operation' in expr) {
            this.deconstructOperation(expr);
        }
    }

    private getBaseExpr() {
        const expr = this.reconstructExpr();
        const baseOperation = !expr
            ? null
            : traverseExpr(expr, (x) => {
                  if ('Operation' in x) {
                      const opInfo = operationLookup.get(x.Operation);
                      const isBase = opInfo && opInfo.flags?.includes('agg') && !opInfo.flags?.includes('hidden');
                      return isBase ? x : undefined;
                  }
              });
        const fieldExpr = baseOperation ? null : this.fieldExpr;
        return baseOperation ?? fieldExpr;
    }

    private reconstructExpr() {
        if (!this._exprParts) {
            return undefined;
        }
        const { agg, fieldExpr } = this._exprParts;
        let opInfo = !agg ? undefined : operationLookup.get(agg ?? '');

        if (!opInfo) {
            return fieldExpr;
        }

        let operation = { Operation: agg, Operands: [] } as QueryOperation;
        if (fieldExpr) {
            this.setOperandByFlag(operation, 'field', fieldExpr);
        }

        if (!!this._filters.length) {
            const filterExpr = cleanBoolExpr(groupExprs('and', this._filters)) as QueryExpr;
            const { result, adjustment } = this.queryDescriptorSvc.applyAggFilter(operation, filterExpr);
            if (adjustment !== 'none') {
                operation = result as QueryOperation;
            }
        }

        return operation;
    }

    private deconstructOperation(operation: QueryOperation) {
        const opName = operation.Operation?.toLowerCase();
        const opInfo = operationLookup.get(opName);
        const parts = {} as ExprDeconstruction;

        if (opInfo?.flags?.includes('agg')) {
            const filterExpr = this.getOperandByFlag(operation, 'filter') as QueryOperation;
            this._filters = this.normalizeFilter(filterExpr);
            this.updateValidFilters(this._filters);
            const aggOp = (this.getOperandByFlag(operation, 'value') ?? operation) as QueryOperation;
            parts.agg = aggOp.Operation;
            parts.fieldExpr = this.getOperandByFlag(aggOp, 'field') as QueryField;
        }

        this.assignExprParts(parts);
    }

    private updateValidFilters(filters: QueryExpr[]) {
        this._validFilters = filters.filter(cleanBoolExpr);
    }

    private normalizeFilter(filter?: QueryOperation): QueryExpr[] {
        if (!filter || !('Operation' in filter)) {
            return [];
        } else {
            const operation = filter.Operation?.toLowerCase();
            return operation !== 'and' ? [filter] : (filter.Operands?.slice() as QueryExpr[]) ?? [];
        }
    }

    private getOrCreateFieldAggs(field: FieldInfo) {
        let result = this._fieldAggs.get(field);
        if (!result) {
            this._fieldAggs.set(field, (result = []));
            const fieldAggInfo = this.queryDescriptorSvc.operationInfoProvider.findOperations(this.operations, this.types, undefined, [
                { flag: 'field', type: field.typeName },
            ]);

            if (fieldAggInfo.length) {
                for (const aggInfo of fieldAggInfo) {
                    const expr = { Operation: aggInfo.operation, Operands: [] };
                    this.setOperandByFlag(expr, 'field', { Field: field.path, Type: field.typeName });
                    result.push(
                        field.copy({
                            Name: `${aggInfo.name ?? aggInfo.operation} ${field.name}`,
                            TypeName: aggInfo.type,
                            Expr: expr,
                            Flags: ['expr-facade'],
                        })
                    );
                }
            }
        }
        return this._fieldAggs.get(field);
    }

    private assignExprParts(parts?: ExprDeconstruction) {
        this._exprParts = parts ?? {};
    }

    private getFacadeFieldOperation(fieldInfo: FieldInfo) {
        return (fieldInfo.supplementalInfo.Expr ?? ({} as QueryOperation))?.Operation;
    }

    private getOperandByFlag(op: QueryOperation, flag: ParameterInfoFlag) {
        return this.queryDescriptorSvc.operationInfoProvider.getOperandByFlag(op, flag);
    }

    private setOperandByFlag(op: QueryOperation, flag: ParameterInfoFlag, value: QueryExpr) {
        this.queryDescriptorSvc.operationInfoProvider.setOperandByFlag(op, flag, value);
    }

    private raiseInvalidated() {
        this.invalidated.emit();
        const currentExpr = this.reconstructExpr();
        const lastEventedExpr = this._exprChanged.value;
        if (!currentExpr || !lastEventedExpr || sortedObjectId(currentExpr) !== sortedObjectId(lastEventedExpr)) {
            this._exprChanged.emit(currentExpr);
        }
    }
}
interface EditorExprPickerProps {
    editor: IChartEditor;
    expr?: QuerySelectExpr;
    onChange: (expr: QuerySelectExpr) => void;
    onRemove?: () => void;
    removeLabel?: string;
    types?: string[];
    opener?: (open: () => void, defaultOpener: () => ReactNode) => ReactNode;
    operations?: ReadonlyArray<string>;
    name?: string;
}
export function EditorExprPicker(props: EditorExprPickerProps) {
    const [opened, { open, close }] = useToggle(false);
    const { editor, expr, onRemove, onChange, removeLabel, types, opener, operations, name } = props;
    const model = useMemo(() => new ExprPickerModel(editor).init(expr, operations, types), [editor.schemaSvc]);
    useEffect(() => model.updateName(name ?? expr?.Alias ?? ''), [expr?.Alias, name]);
    useEvent(model.invalidated);
    useEffect(() => {
        onChange({ Alias: expr?.Alias, Expr: model.getExpr() });
    }, [JSON.stringify(model.getExpr())]);

    const selectedItem = model.getSelectedItem();
    const selection = useMemo(() => (selectedItem ? [selectedItem] : []), [selectedItem]);
    const setSelection = useCallback(
        (selection: FieldInfo[]) => {
            model.setField(selection?.[0]);
            close();
        },
        [model]
    );

    const renderExprFacadeItem = useCallback(
        (item: FieldInfo | TypeInfo, defaultRenderer: () => ReactNode) => {
            if (item instanceof FieldInfo && item.supplementalInfo.Expr) {
                return (
                    <FieldPickerTooltip helpText={item.helpText} position="right">
                        {editor.queryDescriptorSvc.getTokens(item.supplementalInfo.Expr).map((t, i) => (
                            <>
                                <ExprToken key={i} token={t} inline />
                                &nbsp;
                            </>
                        ))}
                    </FieldPickerTooltip>
                );
            }
            return defaultRenderer();
        },
        [editor.queryDescriptorSvc]
    );

    const defaultOpener = () => <ExprButton model={model} onClick={open} active={opened} />;
    return (
        <>
            <Group spacing={3} noWrap>
                <EditorExprAddFilterButton model={model} />
                <FlyoverOverlay opened={opened} onClick={close} routeBound />
                <Popover zIndex={1000} opened={opened} onClose={close} position="top" withinPortal shadow="lg" offset={0} radius="md" withArrow>
                    <Popover.Target>{opener ? opener(open, defaultOpener) : defaultOpener()}</Popover.Target>
                    <Popover.Dropdown p={0} sx={{ border: `solid 1px #0003` }}>
                        <Box sx={{ height: 400 }}>
                            <FieldPicker
                                schema={editor.schemaSvc}
                                mode="single"
                                types={types}
                                selections={selection}
                                getChildren={model.getFieldPickerChildren}
                                onChange={setSelection}
                                renderItem={renderExprFacadeItem}
                                tooltipPosition="right"
                            />
                        </Box>
                    </Popover.Dropdown>
                </Popover>
                {onRemove ? (
                    <Tooltip label={removeLabel}>
                        <ActionIcon onClick={onRemove}>
                            <Trash size={16} />
                        </ActionIcon>
                    </Tooltip>
                ) : null}
            </Group>
            <EditorExprPickerFilter editor={editor} pickerModel={model} />
        </>
    );
}

interface IExprButtonProps {
    model: ExprPickerModel;
    onClick: () => void;
    active: boolean;
}
const ExprButton = forwardRef<HTMLDivElement, IExprButtonProps>(function ExprButton(props, ref) {
    const { model, onClick, active, ...rest } = props;
    useEvent(model.invalidated);
    const { tokens } = model.getUfTokens();

    return (
        <ExprButtonEl ref={ref} onClick={onClick} state={active ? 'active' : model.isEmpty ? 'invalid' : 'valid'} {...rest}>
            {!tokens.length ? (
                <ExprToken token={{ text: 'Select', type: 'none', expr: {}, name: 'Select' }} />
            ) : (
                tokens.map((t, i) => <ExprToken key={i} token={t} />)
            )}
        </ExprButtonEl>
    );
});

export const ExprButtonEl = forwardRef<HTMLDivElement, React.ComponentPropsWithoutRef<typeof FilterItem>>(function ExprButton(props, ref) {
    const { children, ...rest } = props;
    return (
        <FilterItem ref={ref} mode="button" corners={4} {...rest}>
            {children}
        </FilterItem>
    );
});

function EditorExprAddFilterButton({ model }: { model: ExprPickerModel }) {
    const { valid, empty } = useEventValue(model.filterStateChanged)!;
    const { colors } = useMantineTheme();

    const iconColor = empty ? colors.success[6] : colors.gray[6];
    const tooltips = model.getFilterTooltip();
    const tooltip = !valid ? tooltips.invalid : empty ? tooltips.empty : tooltips.valid;
    const { classes } = useExprButtonStyles(empty);

    return !model.canSetFilter ? null : (
        <TooltipWhite multiline width={250} withinPortal position="top" withArrow label={tooltip}>
            <ActionIcon className={classes.button} size={30} variant="outline" onClick={() => (empty ? model.raiseAddFilter() : null)}>
                <Filter stroke={iconColor} size={16} />
            </ActionIcon>
        </TooltipWhite>
    );
}
const useExprButtonStyles = createStyles((theme, empty: boolean) => ({
    button: {
        borderColor: empty ? theme.colors.success[6] : theme.colors.gray[4],
        ['&::after']: {
            content: '""',
            display: empty ? 'none' : 'block',
            background: theme.white,
            width: 10,
            height: 10,
            position: 'absolute',
            transform: `translate(0px, 15px) rotate(45deg)`,
            borderStyle: 'solid',
            borderColor: empty ? theme.colors.success : theme.colors.gray[4],
            borderWidth: empty ? '0' : '0 1px 1px 0',
        },
    },
}));

function EditorExprPickerFilter(props: { editor: IChartEditor; pickerModel: ExprPickerModel }) {
    const { editor, pickerModel } = props;
    const {
        valueProvider,
        queryDescriptorSvc: { fieldInfoProvider },
    } = editor;
    const { filters, canSetFilter, setFilters: onChange, addFilter, schemaSvc, filterStateChanged } = pickerModel;
    const onStateChange = filterStateChanged.emit;
    const { empty, valid } = useEventValue(filterStateChanged)!;
    const canAdd = pickerModel.validFilters.length > 0 && valid;

    return !canSetFilter ? (
        <></>
    ) : (
        <EditorExprPickerFilterContainer empty={empty}>
            <EditorExprFilter {...{ filters, onChange, onStateChange, valueProvider, fieldInfoProvider, addFilter, schemaSvc, canAdd }} />
        </EditorExprPickerFilterContainer>
    );
}
const EditorExprPickerFilterContainer = styled.div<{ empty: boolean }>`
    border: solid ${(p) => (p.empty ? 0 : 1)}px ${(p) => p.theme.colors.gray[4]};
    border-radius: ${(p) => p.theme.radius.sm}px;
    padding-left: 6px;
    margin-bottom: ${(p) => (p.empty ? 0 : 16)}px;
`;

interface EditorExprFilterProps {
    filters: QueryExpr[];
    onChange: (filters: QueryExpr[]) => void;
    onStateChange: (state: { valid: boolean; empty: boolean }) => void;
    fieldInfoProvider: IFieldInfoProvider;
    valueProvider: IValueProviderFactory;
    schemaSvc: SchemaService;
    addFilter: EventEmitter<void>;
    canAdd?: boolean;
}
function EditorExprFilter(props: EditorExprFilterProps) {
    const { filters, onChange, onStateChange, fieldInfoProvider, valueProvider, addFilter, schemaSvc, canAdd } = props;
    const [filterModel, setFilterModel] = useState<DataFilterModel>();
    const fieldPickerRenderer = useCallback((select: (field: string) => void) => {
        return <FieldPicker mode="single" selections={[]} schema={schemaSvc} onChange={(fields) => select(fields[0].path)} />;
    }, []);
    const handleAddFilter = useCallback(() => filterModel?.addEmptyFilter(true, true), [filterModel]);
    useEvent(addFilter, handleAddFilter);
    const handleStateChange = useCallback(
        () => onStateChange({ valid: !filterModel?.getValidationIssues().length, empty: !filterModel?.filters.length }),
        [filterModel, onStateChange]
    );
    useEffect(handleStateChange, [filterModel]);
    useEvent(filterModel?.filtersChanged, handleStateChange);

    const filterProps = useContextualFilterProps();

    return (
        <>
            <DataFilters
                dataFiltersAsLineItem
                lineItemCompact
                onModelLoaded={setFilterModel}
                fieldInfoProvider={fieldInfoProvider}
                align="end"
                renderFieldPicker={fieldPickerRenderer}
                filters={filters}
                showErrors
                onChange={onChange}
                valueProvider={valueProvider}
                {...filterProps}
            />
            {!canAdd ? null : (
                <>
                    <LineItemCompactToken onClick={handleAddFilter}>
                        <Group spacing={4}>
                            <Plus size={16} /> Add another filter
                        </Group>
                    </LineItemCompactToken>
                    <Space h={4} />
                </>
            )}
        </>
    );
}

export const EditorFilterAccordion = withResolvedSchemaCtx(function EditorFilterAccordion({
    editor,
    schemaCtx,
}: {
    editor: IChartEditor;
    schemaCtx: DatasourceSchemaContext;
}) {
    const fmtSvc = useFmtSvc();
    const { noun, nounPlural } = editor.getDatasourceDescription();
    const sectionItemTitle = `${fmtSvc.titleCase(noun)} Filters`;
    const helpText = `(Optional) Add filters to limit which ${nounPlural} are applicable for this tile.`;
    const filterProps = useContextualFilterProps();
    const handleChange = useCallback(
        (filters: QueryExpr[]) => {
            editor.setFilters(filters.map((q) => cleanBoolExpr(q) as QueryExpr).filter((q) => !!q) ?? []);
        },
        [editor]
    );

    const initialFilters = useMemo(() => {
        const filters = editor.getFilters();
        return filters?.length
            ? filters
            : editor
                  .getDatasource()
                  ?.getDefaultFilterFields?.()
                  .map((f) => ({ Operation: 'eq', Operands: [{ Field: f }] })) ?? [];
    }, [editor]);

    return editor.hideFilter ? (
        <></>
    ) : (
        <FilterSettingsSection value={initialFilters} onChange={handleChange} {...{ sectionItemTitle, helpText, schemaCtx, filterProps }} />
    );
});

function useEditorExpr<T extends string>(editor: IChartEditor, name: T, index: number, type: 'group' | 'value') {
    return useMemo(
        () => (type === 'group' ? editor.createGroupAccessor(index, name) : editor.createValueAccessor(index, name)),
        [editor, name, index, type]
    );
}
export function useRawEditorGroupExpr<T extends string>(editor: IChartEditor, name: T, index: number) {
    return useEditorExpr(editor, name, index, 'group');
}
export function useRawEditorValueExpr<T extends string>(editor: IChartEditor, name: T, index: number) {
    return useEditorExpr(editor, name, index, 'value');
}
export function useSelectExprAlias(alias: string, setter: (expr: QuerySelectExpr) => void) {
    return useCallback((expr: QuerySelectExpr) => setter({ ...expr, Alias: alias }), [setter, alias]);
}
/** Wrap a querySelectExpr setter to auto-update the alias to a user-friendly name when the expr changes */
export function useSelectExprUserDefinedAlias(
    expr: QuerySelectExpr | undefined,
    setter: (expr: QuerySelectExpr) => void
): (nextExpr: QuerySelectExpr) => void {
    const schemaCtx = useDatasourceSchemaCtx();
    const queryDescriptorSvc = schemaCtx && typeof schemaCtx !== 'string' ? schemaCtx.queryDescriptorSvc : undefined;

    return useCallback(
        (expr: QuerySelectExpr) => {
            const currentDefaultAlias = expr?.Expr ? queryDescriptorSvc?.getName(expr.Expr as QueryExpr) : undefined;
            const nextDefaultAlias = queryDescriptorSvc?.getName(expr.Expr as QueryExpr);
            const aliasOverridden = expr.Alias !== currentDefaultAlias;
            const shouldUpdateAlias = nextDefaultAlias && nextDefaultAlias !== currentDefaultAlias && !aliasOverridden;
            if (shouldUpdateAlias) {
                setter({ ...expr, Alias: nextDefaultAlias });
            } else {
                setter(expr);
            }
        },
        [setter, JSON.stringify(expr), queryDescriptorSvc]
    );
}
/** Wrap a querySelectExpr setter to auto-update the formatter when the expr changes */
export function useSelectExprFormat<T extends string | NamedFormats>(
    expr: QuerySelectExpr | undefined,
    fmt: T | undefined,
    exprSetter: (expr: QuerySelectExpr) => void,
    formatSetter: undefined | ((fmt: T) => void)
): (nextExpr: QuerySelectExpr) => void {
    const schemaCtx = useDatasourceSchemaCtx();
    const queryDescriptorSvc = schemaCtx && typeof schemaCtx !== 'string' ? schemaCtx.queryDescriptorSvc : undefined;

    return useCallback(
        (nextExpr: QuerySelectExpr) => {
            if (queryDescriptorSvc && formatSetter) {
                const currentType = expr?.Expr ? queryDescriptorSvc.getExprType(expr.Expr as QueryExpr) : undefined;
                const nextType = nextExpr.Expr ? queryDescriptorSvc.getExprType(nextExpr.Expr as QueryExpr) : undefined;
                const currentExprDefaultFormat = expr?.Expr ? (queryDescriptorSvc.getFormatter(expr.Expr as QueryExpr)?.id as T) : undefined;
                const nextFormat = (queryDescriptorSvc.getFormatter(nextExpr.Expr as QueryExpr)?.id as T) ?? (nextType as T);
                const fmtOverridden = fmt !== currentExprDefaultFormat;
                const shouldUpdateFmt = (nextType && nextType !== currentType) || (nextFormat !== fmt && !fmtOverridden);
                if (shouldUpdateFmt && formatSetter) {
                    formatSetter(nextFormat ?? 'string');
                }
            }
            exprSetter(nextExpr);
        },
        [exprSetter, formatSetter, fmt, JSON.stringify(expr), queryDescriptorSvc]
    );
}

type EditorSelectExprOptions = {
    format?: string;
    formatSetter?: ((fmt: NamedFormats | undefined) => void) | ((fmt: string | undefined) => void);
    aliasType?: 'index-based' | 'user-friendly';
};
function useEditorSelectExpr(
    editor: IChartEditor,
    index: number,
    type: 'group' | 'value',
    options?: EditorSelectExprOptions
): [QuerySelectExpr | undefined, (nextExpr: QuerySelectExpr) => void, () => void] {
    const { format, formatSetter, aliasType } = options ?? {};
    const { expr, setExpr: baseSetExpr, removeExpr } = useEditorExpr(editor, 'expr', index, type);

    const setIndexBasedAliasedExpr = useSelectExprAlias(type + index, baseSetExpr);
    const setUserFriendlyAliasedExpr = useSelectExprUserDefinedAlias(expr, baseSetExpr);
    const setAliasedExpr = aliasType !== 'user-friendly' ? setIndexBasedAliasedExpr : setUserFriendlyAliasedExpr;

    const setFormatterByExpr = useSelectExprFormat(
        expr,
        format ?? 'string',
        setAliasedExpr,
        formatSetter as (fmt: string | NamedFormats | undefined) => void
    );
    const setFormatAndExpr = !formatSetter ? setAliasedExpr : setFormatterByExpr;

    return [expr, setFormatAndExpr, removeExpr];
}
export function useEditorGroupSelectExpr(editor: IChartEditor, index: number, options?: EditorSelectExprOptions) {
    return useEditorSelectExpr(editor, index, 'group', options);
}
export function useEditorValueSelectExpr(editor: IChartEditor, index: number, options?: EditorSelectExprOptions) {
    return useEditorSelectExpr(editor, index, 'value', options);
}

export const castSetter = function <TFrom, TTo>(setter: (value: TFrom) => void) {
    return setter as unknown as (value: TTo) => void;
};

type TValueAccessor<TSettings, TProp extends string & keyof TSettings> = {
    getValue: () => TSettings[TProp];
    setValue: (value: TSettings[TProp]) => void;
};
type AllowedKeys<T, V> = { [K in keyof T]: T[K] extends V ? K : never }[string & keyof T];

function createValueAccessor<TSettings, TProp extends AllowedKeys<TSettings, TDefaultValue>, TDefaultValue = TSettings[TProp]>(
    settings: TSettings,
    property: TProp,
    defaultValue?: TDefaultValue
): TValueAccessor<TSettings, TProp> {
    return {
        getValue() {
            if (!(property in settings) && defaultValue !== undefined) {
                settings[property] = defaultValue as unknown as TSettings[TProp];
            }
            return settings[property];
        },
        setValue(value: TSettings[TProp]) {
            settings[property] = value;
        },
    };
}

export function useValueAccessor<TSettings, TProp extends AllowedKeys<TSettings, TDefaultValue>, TDefaultValue = TSettings[TProp]>(
    settings: TSettings,
    property: TProp,
    defaultValue?: TDefaultValue
): TValueAccessor<TSettings, TProp> {
    return useMemo(() => createValueAccessor<TSettings, TProp, TDefaultValue>(settings, property, defaultValue), [settings, property]);
}

type TGetterSetter<TSettings, TProp extends string & keyof TSettings> = { [K in TProp]: TSettings[K] } & {
    [K in TProp as `set${Capitalize<K>}`]: (value: TSettings[K]) => void;
};
export function useGetterSetter<TSettings, TProp extends AllowedKeys<TSettings, any>>(
    settings: TSettings,
    ...properties: TProp[]
): TGetterSetter<TSettings, TProp> {
    return useMemo(() => {
        const accessors = properties.map((p) => ({
            getterName: p,
            setterName: `set${p[0].toUpperCase()}${p.slice(1)}`,
            accessor: createValueAccessor<TSettings, TProp, any>(settings, p),
        }));
        return accessors.reduce((result, { getterName, setterName, accessor: { getValue, setValue } }) => {
            return Object.defineProperties(result, {
                [getterName]: { get: getValue },
                [setterName]: { value: setValue },
            });
        }, {} as TGetterSetter<TSettings, TProp>);
    }, [settings, JSON.stringify(properties)]);
}

export const ChartReaggOptions = observer(function ChartReaggOptions<
    TSettings,
    TMpProp extends AllowedKeys<TSettings, IChartReaggConfig | undefined>
>(props: { settings: TSettings; reaggProp: TMpProp; limitLbl: string; autoLimit?: boolean; max?: number }) {
    const { settings, reaggProp: multiplotProp, limitLbl, autoLimit, max } = props;
    const { getValue } = useValueAccessor(settings, multiplotProp, {});
    const reaggOptions = getValue() as IChartReaggConfig;
    const { limit, setLimit, sortBy, sortDir, setSortBy, setSortDir } = useGetterSetter(reaggOptions, 'limit', 'otherLabel', 'sortBy', 'sortDir');

    const setSort = useCallback(
        ({ sortBy, sortDir }: IChartReaggConfig) => {
            setSortBy(sortBy);
            setSortDir(sortDir);
        },
        [setSortBy, setSortDir]
    );
    const sortOptions = useMemo(
        () =>
            [
                { label: 'Values Low to High', sortBy: 'value', sortDir: 'asc', icon: SortAscendingNumbers },
                { label: 'Values High to Low', sortBy: 'value', sortDir: 'desc', icon: SortDescendingNumbers },
                { label: 'Labels A to Z', sortBy: 'label', sortDir: 'asc', icon: SortAscendingLetters },
                { label: 'Labels Z to A', sortBy: 'label', sortDir: 'desc', icon: SortDescendingLetters },
            ].map(
                ({ icon: Icon, label, ...o }) =>
                    ({
                        ...o,
                        onClick: () => setSort(o as IChartReaggConfig),
                        icon: <Icon size={16} />,
                        label: `Sort by ${label}`,
                        selected: sortBy === o.sortBy && sortDir === o.sortDir,
                    } as OptionMenuButton)
            ),
        [sortBy, sortDir, setSort]
    );
    const { icon, label } = sortOptions.find((o) => o.selected) ?? sortOptions[1];
    const sortDescription = (label as string).replace('Sort', 'sorted').toLowerCase();
    const tooltip = autoLimit ? `Automatically show top ${limitLbl}s, ${sortDescription}` : `Show top ${limit} ${limitLbl}s, ${sortDescription}`;

    const { classes } = useReadonlyInputStyles();
    const numberSx: Sx = { width: 75, ['input']: { textAlign: 'center', paddingLeft: 0 }, ['input:disabled']: { cursor: 'default' } };
    const [sortMenuOpened, { close: closeSortMenu, toggle: toggleSortMenu }] = useToggle(false);
    const valueMax = max ?? 20;
    return (
        <>
            <SettingsInputRow>
                <SettingsLabel icon={<ArrowBigDownLines />}>{limitLbl} limit</SettingsLabel>
                <TooltipWhite offset={0} openDelay={500} disabled={sortMenuOpened} withinPortal label={tooltip}>
                    <Group spacing={6}>
                        {autoLimit ? (
                            <NumberInput className={classes.readonly} disabled sx={numberSx} pl={0} size="xs" placeholder="Auto" />
                        ) : (
                            <NumberInput sx={numberSx} pl={0} max={valueMax} min={1} size="xs" value={limit} onChange={setLimit} placeholder="None" />
                        )}
                        <Menu opened={sortMenuOpened} onClose={closeSortMenu} withinPortal position="bottom-end">
                            <Menu.Target>
                                <ActionIcon onClick={toggleSortMenu} sx={{ borderColor: 'var(--input-border-color)' }} variant="outline">
                                    {icon}
                                </ActionIcon>
                            </Menu.Target>
                            <Menu.Dropdown>
                                <OptionMenuItems options={sortOptions} close={closeSortMenu} />
                            </Menu.Dropdown>
                        </Menu>
                    </Group>
                </TooltipWhite>
            </SettingsInputRow>
        </>
    );
});

export function useDescriptorUpdates(setDescriptors: (descriptors: IPlotFieldDescriptor[]) => void, ...exprs: Array<undefined | QuerySelectExpr>) {
    const schemaCtx = useDatasourceSchemaCtx();
    const queryDescriptorSvc = schemaCtx && typeof schemaCtx !== 'string' ? schemaCtx.queryDescriptorSvc : undefined;
    useEffect(() => {
        if (queryDescriptorSvc) {
            const descriptors: IPlotFieldDescriptor[] = [];
            for (const expr of exprs) {
                if (expr?.Alias) {
                    const name = queryDescriptorSvc.getName(expr.Expr as QueryExpr);
                    descriptors.push({ field: expr.Alias, details: [{ label: name, value: '' }] });
                }
            }
            setDescriptors(descriptors);
        }
    }, [JSON.stringify(exprs), setDescriptors, queryDescriptorSvc]);
}

export const ChartMargins = observer(function ChartMargins({ settings: { margin } }: { settings: { margin?: Partial<ChartMargin> } }) {
    return (
        <>
            <SettingsDivider />
            <SettingsLabel icon="ti ti-box-margin">Margins</SettingsLabel>
            <Group position="center">
                <NumberInput
                    label="Top"
                    size="xs"
                    sx={{ width: '6rem' }}
                    value={margin!.top ?? 0}
                    onChange={(value) => (margin!.top = value || 0)}
                    step={5}
                />
            </Group>
            <Group position="apart">
                <NumberInput
                    label="Left"
                    size="xs"
                    sx={{ width: '6rem' }}
                    value={margin!.left ?? 0}
                    onChange={(value) => (margin!.left = value || 0)}
                    step={5}
                />
                <NumberInput
                    label="Right"
                    size="xs"
                    sx={{ width: '6rem' }}
                    value={margin!.right ?? 0}
                    onChange={(value) => (margin!.right = value || 0)}
                    step={5}
                />
            </Group>
            <Group position="center">
                <NumberInput
                    label="Bottom"
                    size="xs"
                    sx={{ width: '6rem' }}
                    value={margin!.bottom ?? 0}
                    onChange={(value) => (margin!.bottom = value || 0)}
                    step={5}
                />
            </Group>
        </>
    );
});

export const NumberSettings = observer(function NumberSettings(
    props: NumberSettingsInputProps & {
        label: string;
        icon?: ReactNode;
    }
) {
    const { label, icon, ...inputProps } = props;
    return (
        <SettingsInputRow>
            <SettingsLabel icon={icon}>{label}</SettingsLabel>
            <NumberSettingsInput {...inputProps} />
        </SettingsInputRow>
    );
});
interface NumberSettingsInputProps {
    value: number | undefined | null;
    onChange: (value: null | number | undefined) => void;
    decimals?: number;
    min?: number;
    max?: number;
    width?: number;
    center?: boolean;
}
export const NumberSettingsInput = observer(function NumberSettingsInput(props: NumberSettingsInputProps) {
    const { value, decimals, onChange, min, max, center, width = 60 } = props;
    const numberSx: Sx = { width, ['input']: !center ? {} : { textAlign: 'center', paddingLeft: 0 }, ['input:disabled']: { cursor: 'default' } };
    return (
        <NumberInput
            size="xs"
            sx={numberSx}
            min={min}
            max={max}
            value={value === null ? undefined : value}
            precision={decimals ?? 0}
            onChange={onChange}
        ></NumberInput>
    );
});

export const DropdownSettings = observer(function DropdownSettings({
    value,
    onChange,
    options,
    label,
    width = 140,
    icon,
    center,
    clearable,
}: {
    label: string;
    value: string | undefined | null;
    onChange: (value: null | string | undefined) => void;
    options: { value: string; label: string }[];
    width?: number;
    icon?: ReactNode;
    center?: boolean;
    clearable?: boolean;
}) {
    const inputSx: Sx = {
        width: width,
        input: { textAlign: center ? 'center' : undefined },
        ['.mantine-Select-dropdown *']: { textAlign: center ? 'center' : undefined },
    };

    return (
        <Group position="apart">
            <SettingsLabel icon={icon}>{label}</SettingsLabel>
            <Select size="xs" sx={inputSx} clearable={clearable} onChange={onChange} data={options} value={value}></Select>
        </Group>
    );
});

export const SegmentedControlSettings = observer(function SegmentedControlSettings({
    value,
    onChange,
    options,
}: {
    value: string;
    options: { value: string; label: string }[];
    onChange: (value: null | string | undefined) => void;
}) {
    const { colors } = useMantineTheme();
    const sx = {
        border: 'solid 1px var(--input-border-color)',
        padding: '1px 4px',
        background: '#fff',
        height: 'var(--input-height)',
        lineHeight: '20px',
        ['.mantine-SegmentedControl-active']: {
            marginTop: 4,
            height: 'calc(var(--input-height) - 6px)',
            border: `solid 1px ${colors.gray[4]}`,
            background: colors.primary[1],
        },
    };
    return <SegmentedControl size="xs" sx={sx} value={value} data={options} onChange={onChange} />;
});

export function FormatSettings({
    value,
    onChange,
    type,
}: {
    value: string | undefined | null;
    onChange: (value: null | string | undefined) => void;
    type: string;
}) {
    const fmtSvc = useFmtSvc();
    const options = useMemo(() => {
        const result = (type === 'string' ? [] : fmtSvc.getNamedFormatters(type)).map((o) => ({ value: o.id as string, label: o.ufName! }));
        if (result.length) {
            result.unshift({ value: '', label: 'None' });
        }
        return result;
    }, [type]);
    const handleChange = useCallback(
        (value: string | null | undefined) => {
            onChange(value === '' ? undefined : value);
        },
        [onChange]
    );
    return !options.length ? (
        <></>
    ) : (
        <DropdownSettings icon={<CurrencyDollar />} width={165} options={options} onChange={handleChange} label="Format" value={value ?? ''} />
    );
}
