import styled from '@emotion/styled';
import { useGlobalUserPrefs } from '@root/Services/Customers/UserPreferenceService';
import { navSize, mspColorPalette, CustomColors } from './Themes';

export const MainNav = styled.nav<{ mode: 'wide' | 'narrow' }>`
    height: 100%;
    min-width: ${(p) => (p.mode === 'wide' ? navSize.wide : navSize.narrow)}px;
    transition: all 0.5s;
    flex: 0;
    display: flex;
    flex-direction: column;
    background-color: ${mspColorPalette.LeftNavBgColor};
`;

export function useMainNavPrefs() {
    const { get, set } = useGlobalUserPrefs('MainNav', { expanded: true as boolean });
    return [get().expanded ?? true, (expanded: boolean) => set({ expanded })] as const;
}
export function useMainNav() {
    const [expanded] = useMainNavPrefs();
    return { expanded, width: expanded ? navSize.wide : navSize.narrow };
}

export const MainNavFiller = styled.div`
    flex: 1;
`;
//lower main nav (settings/support)
export const MainNavItem = styled.a<{ state: 'active' | 'inactive' }>`
    display: flex;
    line-height: ${navSize.itemSize}px;
    border-radius: ${(p) => p.theme.radius.md}px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    background-color: ${(p) => (p.state === 'active' ? (p.theme.other.LowerNavSelected as CustomColors) : '')};
    color: ${(p) => p.theme.colors.gray[1]};
    cursor: pointer;
    &:hover {
        background-color: ${(p) => p.theme.other.LowerNavSelected as CustomColors};
        color: ${(p) => p.theme.other.LeftNavHoverText as CustomColors};
    }
    text-align: center;
    text-decoration: none;
    i {
        width: ${navSize.itemSize}px;
        font-size: ${(p) => p.theme.fontSizes.lg}pt;
        line-height: ${navSize.itemSize}px;
        flex-shrink: 0;
    }
`;

export const SecondaryNav = styled.nav`
    background-color: ${(p) => p.theme.fn.lighten(p.theme.colors.gray[2] as CustomColors, 0.5)};
    border-right: solid 1px ${(p) => p.theme.colors.gray[3] as CustomColors};
    height: 100%;
    padding: ${(p) => p.theme.spacing.md}px 0;
    min-width: ${navSize.secondary}px;
    overflow: auto;
`;

export const SecondaryNavItem = styled.a<{ state: 'active' | 'inactive' }>`
    display: block;
    padding: 0 ${(p) => p.theme.spacing.md}px;
    line-height: 40px;
    border-top-right-radius: ${(p) => p.theme.spacing.sm}px;
    border-bottom-right-radius: ${(p) => p.theme.spacing.sm}px;
    margin-right: ${(p) => p.theme.spacing.md}px;
    text-decoration: none;
    font-weight: 500;
    background: ${(p) => (p.state === 'active' ? mspColorPalette.SecondaryNavBackground : 'transparent')};
    color: ${(p) => (p.state === 'active' ? mspColorPalette.LeftNavTextColor : 'inherit')};
    &:hover {
        background: ${mspColorPalette.SettingsNavHoverAndSelectedBgColor};
    }
`;

export const SecondaryNavSection = styled.div`
    padding: 0 ${(p) => p.theme.spacing.md}px;
    margin-top: ${(p) => p.theme.spacing.lg}px;
    margin-bottom: ${(p) => p.theme.spacing.sm}px;
    border-bottom: solid 1px ${(p) => p.theme.colors.gray[2] as CustomColors};
    font-size: ${(p) => p.theme.fontSizes.sm}px;
    color: ${mspColorPalette.LeftNavBgColor};
`;
