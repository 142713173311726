import { AppFeatures, CompanyFeature, CompanyType, Feature } from '@apis/Customers/model';
import { CompanyTenantPrereqService, useCompany } from '@root/Components/Router/CompanyContent';
import { useMemo } from 'react';
import { useDiContainer } from '../DI';
import { EventEmitter } from '../EventEmitter';
import { getCompanyGetCompanyFeatures } from '@apis/Customers';
import { inject, injectable, singleton } from 'tsyringe';
import { AppFeatureService } from './AppFeatureService';
import { SuperUserService } from './SuperUserService';
import { AuthorizationService } from '../AuthorizationService';
import { BaseCacheByTenant } from './BaseCacheByTenant';

export type AppTypes = 'Platform' | 'Assessments' | 'Optimization' | 'FinOps' | 'Compliance' | 'CloudInsights' | 'System' | 'Billing';

export enum AppFeatureNames {
    Assessments = 'Assessments',
    TagManager = 'Tag Explorer',
    MAPManager = 'MAP Manager',
    InvoiceManager = 'Invoice Explorer',
    CostForecasting = 'Cost Forecasting',
    Showback = 'Showback',
    IdleResources = 'Idle Resources',
    Rightsizing = 'Rightsizing',
}

const legacyAppMap = new Map<string, { app: AppTypes; feature?: string }>([
    ['tag manager', { app: 'Compliance', feature: AppFeatureNames.TagManager }],
    ['map manager', { app: 'Compliance', feature: AppFeatureNames.MAPManager }],
    ['invoice manager', { app: 'FinOps', feature: AppFeatureNames.InvoiceManager }],
    ['tag manager', { app: 'Optimization', feature: AppFeatureNames.IdleResources }],
    ['rightsizing', { app: 'Optimization', feature: AppFeatureNames.Rightsizing }],
    ['assessment manager', { app: AppFeatureNames.Assessments }],
]);

export interface ICompanyFeatureLookup {
    get allAppFeatures(): ReadonlyArray<AppFeatures>;
    /**
     * Returns true if the company has access to the app
     * @param app
     */
    hasApp(app: AppTypes): boolean;
    /**
     * Returns true if the user has access to the app, regardless of the company's subscriptions
     * @param app
     */
    checkApp(app: AppTypes): boolean;
    /**
     * Returns true if the company has access to the app and feature
     * @param app
     * @param feature
     */
    hasFeature(app: AppTypes, feature?: string): boolean;
    /**
     * Returns true if the user has access to the app and feature, regardless of the company's subscriptions
     * @param app
     * @param feature
     */
    checkFeature(app: AppTypes, feature?: string): boolean;
    hasLegacyApp(app: string): boolean;
    getLegacyApps(): string[];
    /**
     * Returns the list of apps and features that the company has access to
     */
    getAppFeatures(): ReadonlyArray<AppFeatures>;
    /**
     * Returns the list of apps and features that the user has access to (not a permission check, though)
     */
    getAccessibleAppFeatures(): ReadonlyArray<AppFeatures>;
}
export class CompanyFeatureLookup implements ICompanyFeatureLookup {
    private readonly featureLookup = new Set<string>();
    private readonly appLookup = new Set<AppTypes>();
    private readonly companyAppFeatures: ReadonlyArray<AppFeatures>;

    public constructor(
        public readonly companyType: CompanyType,
        public readonly allAppFeatures: ReadonlyArray<AppFeatures>,
        public readonly companyFeatures: ReadonlyArray<CompanyFeature>,
        private readonly superUserOn: EventEmitter<boolean>
    ) {
        const companyFeatureLookup = companyFeatures.reduce((acc, f) => acc.add(f.FeatureId!), new Set<number>());
        const companyAppFeatures: AppFeatures[] = [];
        for (const app of allAppFeatures) {
            if (app.CompanyType === companyType) {
                const companyAppFeature = { ...app, Features: [] as Feature[] };
                let hasFeature = false;
                for (const feature of app.Features ?? []) {
                    if (companyFeatureLookup.has(feature.Id!)) {
                        hasFeature = true;
                        this.featureLookup.add(feature.Name!);
                        this.appLookup.add(app.SystemName!);
                        companyAppFeature.Features.push(feature);
                    }
                }
                if (hasFeature) {
                    companyAppFeatures.push(companyAppFeature);
                }
            }
        }
        this.companyAppFeatures = companyAppFeatures;
    }

    public hasApp(app: AppTypes) {
        return this.appLookup.has(app);
    }

    public checkApp(app: AppTypes) {
        return this.superUserOn.value || this.hasApp(app);
    }

    public hasFeature(app: AppTypes, feature?: string) {
        return this.hasApp(app) && (feature === undefined || this.featureLookup.has(feature));
    }

    public checkFeature(app: AppTypes, feature?: string) {
        return this.superUserOn.value || this.hasFeature(app, feature);
    }

    public hasLegacyApp(app: string) {
        const legacy = legacyAppMap.get(app.toLowerCase());
        return legacy ? this.checkFeature(legacy.app, legacy.feature) : false;
    }

    public getLegacyApps() {
        return Array.from(legacyAppMap.keys()).filter((k) => this.hasLegacyApp(k));
    }

    public getAppFeatures() {
        return this.companyAppFeatures;
    }

    public getAccessibleAppFeatures() {
        return this.superUserOn.value ? this.allAppFeatures : this.companyAppFeatures;
    }
}

export const useCompanyFeatureSvc = () => {
    const container = useDiContainer();
    const company = useCompany();
    const companyFeatureSvc = useMemo(() => {
        const svc = container.resolve(CompanyFeatureService);
        return {
            getFeatures: async () => {
                return await svc.getFeatures(company ?? {});
            },
        };
    }, [company]);

    return companyFeatureSvc;
};

@singleton()
class TenantFeatureServiceCache extends BaseCacheByTenant<ICompanyFeatureLookup> {
    public constructor(@inject(CompanyTenantPrereqService) tenantPrereqSvc: CompanyTenantPrereqService) {
        super(tenantPrereqSvc);
    }
}

@injectable()
export class CompanyFeatureService {
    public constructor(
        @inject(AppFeatureService) private readonly appFeatureSvc: AppFeatureService,
        @inject(TenantFeatureServiceCache) private readonly featureCache: TenantFeatureServiceCache,
        @inject(SuperUserService) private readonly superUserService: SuperUserService,
        @inject(AuthorizationService) private readonly authSvc: AuthorizationService
    ) {}

    public async reload(companyId: number) {
        this.featureCache.invalidate(companyId);
    }

    public async checkAccess(company: { Id?: number | null; Type?: CompanyType | null }, app: AppTypes, feature?: string) {
        const companyFeatures = await this.getFeatures(company);
        return companyFeatures.checkFeature(app, feature);
    }

    public async getFeatures(company: { Id?: number | null; Type?: CompanyType | null }): Promise<ICompanyFeatureLookup> {
        return await this.featureCache.get(company.Id ?? 0, async () => {
            const companyFeatures = await getCompanyGetCompanyFeatures();
            return new CompanyFeatureLookup(
                company.Type ?? 'Customer',
                this.appFeatureSvc.getFeatures(),
                companyFeatures,
                this.superUserService.superUserOn
            );
        });
    }
}
