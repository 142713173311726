import { CompanyInfo } from '@apis/Customers/model';
import { CompanyAdministrationPanelModel } from './CompanyAdministrationPanelModel';
import { AdminPageTitle, AdminPanel } from './Design';
import { Box, Button, Group, LoadingOverlay, Modal, Text } from '@mantine/core';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { CompanyBillingTaxCode } from '@apis/Invoices/model';
import { INavigatingEvent } from '@root/Services/Router/Router';
import { confirmAction } from './ConfirmActionDialog';
import { useEvent } from '@root/Services/EventEmitter';
import { getBillingInvoiceGetCompanyBillingTaxCodes } from '@apis/Invoices';
import { useDisclosure } from '@mantine/hooks';
import { useDi, useDiComponent } from '@root/Services/DI';
import { CompanyTaxCodeForm } from '../CompanyTaxCodeForm';
import { DataColumnConfig } from '@root/Components/DataGrid/Models';
import { Cloud } from 'tabler-icons-react';
import { getCompanyGetCompanyCloudProviders } from '@apis/Customers';
import { DataGrid } from '@root/Components/DataGrid';
import { ro } from 'date-fns/locale';
import { FormatService } from '@root/Services/FormatService';
import { BillingInvoicesAdminPanelModel } from '@root/Site/Billing/BillingInvoicesAdminPanelModel';

export interface AdminTaxRatesProps {
    model: CompanyAdministrationPanelModel | BillingInvoicesAdminPanelModel;
    companyInfo: CompanyInfo;
    refreshMsp: () => void;
}

interface CompanyBillingTaxCodeItem {
    AccountName?: string;
    CloudProviderId?: number;
    Jurisdiction?: string;
    Rate?: number;
    EffectiveFrom?: string;
    EffectiveTo?: string;
    Code?: string;
    BillingTaxCodeId?: number;
    CustomerTaxCodeId?: number;
}

export const AdminTaxRates = (props: AdminTaxRatesProps) => {
    const [loading, setLoading] = useState(true);
    const [taxCodes, setTaxCodes] = useState<CompanyBillingTaxCodeItem[]>([]);
    const [selectedTaxCodeId, setSelectedTaxCodeId] = useState<number>();
    const [addModalOpened, { open: openAdd, close: closeAdd }] = useDisclosure(false);
    const [EditModalOpened, { open: openEdit, close: closeEdit }] = useDisclosure(false);
    const fmtSvc = useDi(FormatService);
    const DiContainer = useDiComponent();

    useEffect(() => {
        loadCompanyTaxCodes();
    }, []);

    useEffect(() => {
        if (selectedTaxCodeId) {
            openEdit();
        }
    }, [selectedTaxCodeId]);

    const loadCompanyTaxCodes = async () => {
        setLoading(true);
        const accounts = await getCompanyGetCompanyCloudProviders();
        getBillingInvoiceGetCompanyBillingTaxCodes().then((response) => {
            const data: CompanyBillingTaxCodeItem[] = response.map((item) => {
                return {
                    AccountName: accounts.find((a) => a.Id === item.CompanyCloudProviderId)?.Name ?? '',
                    CloudProviderId: item.CompanyCloudProviderId,
                    Jurisdiction: item.TaxCode?.Jurisdiction ?? '',
                    Rate: item.TaxCode?.Rate,
                    EffectiveFrom: item.TaxCode?.EffectiveFrom ?? '',
                    EffectiveTo: item.TaxCode?.EffectiveTo ?? '',
                    Code: item.TaxCode?.Code ?? '',
                    BillingTaxCodeId: item.TaxCodeId,
                    CustomerTaxCodeId: item.Id,
                };
            });
            setTaxCodes(data);
            setLoading(false);
        });
        setTimeout(() => {
            setLoading(false);
        }, 1000);
    };

    const onAddModalClose = useCallback(
        (didAdd: boolean) => {
            if (didAdd) {
                loadCompanyTaxCodes();
            }
            closeAdd();
        },
        [closeAdd]
    );

    const onEditModalClose = useCallback(
        (didEdit: boolean) => {
            if (didEdit) {
                loadCompanyTaxCodes();
            }
            closeEdit();
        },
        [closeEdit]
    );

    const columns = useMemo(() => {
        return [
            {
                id: 'AccountName',
                header: 'Account Name',
                accessor: 'AccountName',
                defaultWidth: 250,
                sortField: 'AccountName',
                type: 'string',
            },
            {
                id: 'Jurisdiction',
                header: 'Jurisdiction',
                accessor: 'Jurisdiction',
                defaultWidth: 150,
                sortField: 'Jurisdiction',
                type: 'string',
            },
            {
                id: 'Rate',
                header: 'Rate',
                accessor: 'Rate',
                defaultWidth: 75,
                sortField: 'Rate',
                type: 'string',
            },
            {
                id: 'Code',
                header: 'Code',
                accessor: 'Code',
                defaultWidth: 75,
                sortField: 'Code',
                type: 'string',
            },
            {
                id: 'EffectiveFrom',
                header: 'Effective From',
                accessor: 'EffectiveFrom',
                defaultWidth: 150,
                sortField: 'EffectiveFrom',
                type: 'string',
                cellRenderer: (row) => {
                    return row.EffectiveFrom ? fmtSvc.formatDate(fmtSvc.toLocalDate(row.EffectiveFrom)) : '';
                },
            },
            {
                id: 'EffectiveTo',
                header: 'Effective To',
                accessor: 'EffectiveTo',
                defaultWidth: 150,
                sortField: 'EffectiveTo',
                type: 'string',
                cellRenderer: (row) => {
                    return row.EffectiveTo ? fmtSvc.formatDate(fmtSvc.toLocalDate(row.EffectiveTo)) : '';
                },
            },
        ] as DataColumnConfig<CompanyBillingTaxCodeItem>[];
    }, [taxCodes]);

    return (
        <AdminPanel>
            {loading && <LoadingOverlay visible={true} />}
            <Modal opened={addModalOpened} closeOnClickOutside onClose={closeAdd} title="Add Company Billing Tax Code">
                <DiContainer>
                    <CompanyTaxCodeForm onClose={onAddModalClose}></CompanyTaxCodeForm>
                </DiContainer>
            </Modal>
            <Modal opened={EditModalOpened} closeOnClickOutside onClose={closeEdit} title="Edit Company Billing Tax Code">
                <DiContainer>
                    <CompanyTaxCodeForm onClose={onEditModalClose} customerTaxCodeId={selectedTaxCodeId}></CompanyTaxCodeForm>
                </DiContainer>
            </Modal>
            <Group position="apart">
                <AdminPageTitle>Company Billing Tax Code Info</AdminPageTitle>
                <Button onClick={openAdd}>Add Tax Code</Button>
            </Group>
            <Box>
                {taxCodes.length > 0 ? (
                    <div style={{ height: '525px', overflow: 'auto' }}>
                        <DataGrid
                            columns={columns}
                            dataSource={taxCodes!}
                            onRowClick={(row) => {
                                setSelectedTaxCodeId(row.CustomerTaxCodeId);
                            }}
                        ></DataGrid>
                    </div>
                ) : (
                    <Text>No tax codes found</Text>
                )}
            </Box>
        </AdminPanel>
    );
};
