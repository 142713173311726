import { postFrontEndErrorSaveError } from '@apis/Customers';
import { FrontEndError } from '@apis/Customers/model';
import { inject, singleton } from 'tsyringe';
import { AsyncBundler } from './AsyncBundler';

interface ILogReceiver {
    log(message: string, ...args: any[]): void;
    warn(message: string, ...args: any[]): void;
    error(message: string, ...args: any[]): void;
}

@singleton()
export class LoggerConfig {
    public logReceivers: ILogReceiver[] = [];

    public configure(logReceivers: ILogReceiver[]) {
        this.logReceivers.push(...logReceivers);
    }
}

@singleton()
export class Logger {
    public constructor(@inject(LoggerConfig) private config: LoggerConfig) {}

    public log(message: string, ...args: any[]) {
        this.config.logReceivers.forEach((l) => l.log(message, ...args));
    }
    public warn(message: string, ...args: any[]) {
        this.config.logReceivers.forEach((l) => l.warn(message, ...args));
    }
    public error(message: string, ...args: any[]) {
        this.config.logReceivers.forEach((l) => l.error(message, ...args));
    }
}

@singleton()
export class ConsoleLogReceiver implements ILogReceiver {
    public log(message: string, ...args: any[]) {
        console.log(message, ...args);
    }
    public warn(message: string, ...args: any[]) {
        console.warn(message, ...args);
    }
    public error(message: string, ...args: any[]) {
        console.error(message, ...args);
    }
}

@singleton()
export class FrontEndErrorLogReceiver implements ILogReceiver {
    private readonly asyncBundler = new AsyncBundler();

    public log() {}
    public warn() {}
    public error(message: string, ...args: any[]) {
        this.asyncBundler.bundle('error', { message, args }, this.sendError);
    }

    private sendError = async (errors: { message: string; args: any[] }[]) => {
        try {
            const converted = this.convertErrors(errors);
            if (converted) {
                await postFrontEndErrorSaveError(converted);
            }
        } catch (err) {
            console.error('Error saving log', err);
        }
    };

    private convertErrors(errors: { message: string; args: any[] }[]): FrontEndError | undefined {
        let result: FrontEndError | undefined = undefined;

        const errorDetails = errors.map((e) => this.getErrorDetail(e)!).filter((e) => !!e);
        if (errorDetails.length > 0) {
            const [first, ...rest] = errorDetails;
            result = { Type: 'Unknown', ErrorMessage: JSON.stringify(first), Description: first.description };

            if (rest.length) {
                result.Description = `Consolidated errors (${rest.length + 1})`;
                for (const item of rest) {
                    result.ErrorMessage += '\n' + JSON.stringify(item);
                }
            }
        }

        return result;
    }

    private getErrorDetail(error: {
        message: string;
        args: any[];
    }): undefined | { detail?: unknown; description?: string; errorMessage?: string; stack?: string } {
        const [errorArg] = error?.args ?? [];
        if (!errorArg || typeof errorArg !== 'object' || !('error' in errorArg)) return undefined;

        const thrownErr: null | Error = !errorArg.error || !(errorArg.error instanceof Error) ? null : errorArg.error;

        return {
            description: error.message,
            errorMessage: thrownErr?.message,
            detail: !thrownErr ? errorArg.error : undefined,
            stack: thrownErr?.stack,
        };
    }
}
