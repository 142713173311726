import { Query, QuerySortExpr } from '@apis/Invoices/model';
import { QueryExpr, QueryResult } from '@apis/Resources';
import { Center, Loader, useMantineTheme, Text, Tooltip } from '@mantine/core';
import { IncreaseIndicator } from '@root/Design/Data';
import { useDi, useDiContainer } from '@root/Services/DI';
import { EventEmitter, useEvent, useEventValue } from '@root/Services/EventEmitter';
import { FormatService } from '@root/Services/FormatService';
import { InvoiceApiService } from '@root/Services/Invoices/InvoiceApiService';
import { IInvoiceFieldCompatibilityLookup, InvoiceFieldCompatibilityService } from '@root/Services/Invoices/InvoiceFieldCompatibilityService';
import { InvoiceSchemaService } from '@root/Services/Invoices/InvoiceSchemaService';
import { ArrayDataSource } from '@root/Services/Query/ArrayDataSource';
import { exprBuilder, FieldInfo, SchemaService, SchemaValueProvider, TypeInfo, ValuesGroupOtherText } from '@root/Services/QueryExpr';
import { ReactNode, useCallback, useEffect, useMemo } from 'react';
import { Tilde } from 'tabler-icons-react';
import { inject, injectable } from 'tsyringe';
import { DataGrid } from '../DataGrid';
import { DataGridModel } from '../DataGrid/DataGridModel';
import { IDataSource, ColumnConfig, DataGridState, GridGroupByState, GridColumnState, ISelectionStrategy } from '../DataGrid/Models';
import { IValueProviderFactory } from '@root/Services/Query/QueryDatasource';
import { FieldPicker } from '../Picker/FieldPicker';

export function DailyMatrixInvoiceGrid({
    persistenceKey,
    days,
    invoiceApi,
    includeDifference,
    includeTotal,
    defaultGroupBy,
    filters,
    onSelectionChanged,
    onFilterChanged,
    selectionStrategy,
    renderRowSelector,
}: {
    persistenceKey: string;
    days: Date[];
    invoiceApi: InvoiceApiService;
    includeDifference?: boolean;
    includeTotal?: boolean;
    defaultGroupBy?: GridGroupByState[];
    filters?: QueryExpr[];
    onFilterChanged?: (filters: QueryExpr[]) => void;
    onSelectionChanged?: (selectionState: { getItems: () => Promise<DailyMatrixInvoiceRow[]> }) => void;
    selectionStrategy?: ISelectionStrategy<DailyMatrixInvoiceRow>;
    renderRowSelector?: (
        item: DailyMatrixInvoiceRow | null,
        selectionState: { selected?: boolean; some?: boolean; all?: boolean; toggle: (selected: boolean) => void },
        isHeader: boolean
    ) => ReactNode;
}) {
    const theme = useMantineTheme();
    const container = useDiContainer();
    const model = useMemo(() => {
        return container
            .resolve(DailyMatrixInvoiceGridModel)
            .setDays(days)
            .setFilters(filters ?? [])
            .init(invoiceApi, !!includeDifference, !!includeTotal);
    }, []);
    useEffect(() => {
        model
            .setDays(days)
            .setFilters(filters ?? [])
            .setOnFilterChanged(onFilterChanged)
            .setSelectionEnabled(!!onSelectionChanged);
    }, [JSON.stringify([days, filters]), !onSelectionChanged, !onFilterChanged]);
    const initializing = useEventValue(model.initializing);
    const renderKey = useEventValue(model.renderKey);
    const selectionProps = onSelectionChanged ? { selectionMode: 'multiple' as 'multiple', onSelectedChanged: onSelectionChanged! } : {};
    const groupByFieldPicker = useMemo(
        () => (select: (selection: { id: string }) => void) => <GroupByFieldPicker model={model} select={select} />,
        [model]
    );

    return initializing ? (
        <Center>
            <Loader />
        </Center>
    ) : (
        <DataGrid
            key={renderKey}
            childAccessor={model.childAccessor}
            columns={model.columns}
            dataSource={model.datasource}
            statePersistence={persistenceKey ? { key: persistenceKey } : undefined}
            onStateLoaded={model.handleStateLoaded}
            allowSavedViews
            onModelLoaded={model.attach}
            onRowClick={model.onGridRowClick}
            {...selectionProps}
            selectionStrategy={selectionStrategy}
            renderRowSelector={renderRowSelector}
            schemaSvc={model.schema}
            allowGroupBy
            groupByAsRows
            groupByLabels={{ count: 'Cost' }}
            groupByNameLookup={model.getGroupByName}
            groupByFieldPicker={groupByFieldPicker}
            groupByDisableSort
            groupByRequired={1}
            defaultGroupBy={defaultGroupBy}
            filterValueProvider={model.filterValueProvider}
            groupConfig={includeDifference ? { ['Approximate Variance']: { color: theme.colors.primary[2] } } : undefined}
            showHeaderGroups={includeDifference}
            allowNonColumnFilters
            hideGlobalSearch
            indentLeafNodes
            hideMenu
            renderFooter
            footerPosition="top"
        />
    );
}

type DailyMatrixInvoiceRowDay = Record<`day${number}`, number>;
export type DailyMatrixInvoiceRow = {
    parent?: DailyMatrixInvoiceRow;
    value: string;
    type: string;
    depth: number;
    resourceId?: string;
    differenceTotal?: number;
    differencePercent?: number;
    children?: DailyMatrixInvoiceRow[];
    nullValue: boolean;
    total: number;
} & DailyMatrixInvoiceRowDay;

@injectable()
export class DailyMatrixInvoiceGridModel {
    private _datasource?: IDataSource<DailyMatrixInvoiceRow>;
    private _columns?: ColumnConfig<DailyMatrixInvoiceRow>[];
    private _defaultState?: DataGridState;
    private fieldCompat?: IInvoiceFieldCompatibilityLookup;
    private onFilterChanged?: (filters: QueryExpr[]) => void;
    private days: Date[] = [];
    private filters: QueryExpr[] = [];
    private invoiceApi!: InvoiceApiService;
    private gridModel?: DataGridModel;
    private sortDatasource?: (state: DataGridState) => void;
    private invalidateDatasource?: () => void;
    private selectionEnabled = false;
    private totalRowCache = {
        rootData: [] as DailyMatrixInvoiceRow[],
        totalRow: undefined as undefined | { [key in keyof DailyMatrixInvoiceRow]: number },
    };
    private dayRange: { from?: Date; to?: Date } = {};

    public rootDataUpdated = new EventEmitter<DailyMatrixInvoiceRow[]>([]);
    public totalRow?: { [key in keyof DailyMatrixInvoiceRow]: number };
    public initializing = new EventEmitter(true);
    public schema?: SchemaService;
    public filterValueProvider?: IValueProviderFactory;
    public renderKey = new EventEmitter(0);
    public dateField: string = '';
    public get datasource(): IDataSource<DailyMatrixInvoiceRow> {
        if (!this._datasource) {
            this._datasource = this.createDatasource(this.dateField ?? '');
        }
        return this._datasource;
    }
    public get columns(): ColumnConfig<DailyMatrixInvoiceRow>[] {
        if (!this._columns) {
            this._columns = this.createColumns();
        }
        return this._columns;
    }
    public get defaultState(): DataGridState {
        if (!this._defaultState) {
            this._defaultState = this.createDefaultState();
        }
        return this._defaultState;
    }
    public get grid() {
        return this.gridModel;
    }
    public includeDifference: boolean = false;
    public includeTotal: boolean = false;
    public constructor(
        @inject(FormatService) private readonly formatSvc: FormatService,
        @inject(InvoiceSchemaService) private readonly invoiceSchemaSvc: InvoiceSchemaService,
        @inject(InvoiceFieldCompatibilityService) private readonly fieldCompatibilitySvc: InvoiceFieldCompatibilityService
    ) {}

    public init(invoiceApi: InvoiceApiService, includeDifference: boolean, includeTotal: boolean) {
        this.invoiceApi = invoiceApi;
        this.includeDifference = includeDifference;
        this.includeTotal = includeTotal;
        this.initialize();
        return this;
    }

    public async initialize() {
        try {
            this.initializing.emit(true);
            const [types, fieldCompat] = await Promise.all([this.invoiceSchemaSvc.getDailySchema(), this.fieldCompatibilitySvc.getLookup('daily')]);
            this.schema = new SchemaService(types);
            this.fieldCompat = fieldCompat;
            this.filterValueProvider = new SchemaValueProvider(this.schema, (q) => {
                if (this.filters?.length) {
                    q.Where = { Operation: 'and', Operands: [...this.filters, ...(q.Where ? [q.Where] : [])] };
                }
                return this.invoiceApi.query(q, this.dayRange, false);
            });
            this.schema.resolveChildren();
        } finally {
            this.initializing.emit(false);
        }
    }

    public setFilters(filters: QueryExpr[]) {
        if (JSON.stringify(filters) !== JSON.stringify(this.filters)) {
            this.filters = filters;
        }
        return this.invalidate();
    }

    public setOnFilterChanged(handler: ((filters: QueryExpr[]) => void) | undefined) {
        if (handler !== this.onFilterChanged) {
            this.onFilterChanged = handler;
        }
        return this;
    }

    public getDays() {
        return this.days;
    }

    public setDays(days: Date[]) {
        if (JSON.stringify(days) !== JSON.stringify(this.days)) {
            this.days = days;
            this.dayRange = days.reduce(
                (acc, day, i) => {
                    return {
                        from: day.getTime() < acc.from.getTime() ? day : acc.from,
                        to: day.getTime() > acc.to.getTime() ? day : acc.to,
                    };
                },
                { from: new Date(9999, 1, 1), to: new Date(0) }
            );
            this.updateColumns();
        }
        return this.invalidate();
    }

    public setSelectionEnabled(enabled: boolean) {
        if (enabled !== this.selectionEnabled) {
            this.selectionEnabled = enabled;
        }
        return this.invalidate();
    }

    public getTotalRow() {
        const rootData = this.rootDataUpdated.value;
        if (rootData !== this.totalRowCache.rootData) {
            const keys = [
                ...Array(this.getDays().length)
                    .fill(0)
                    .map((_, i) => `day${i}` as keyof DailyMatrixInvoiceRow),
                'total',
                'differenceTotal',
            ] as (keyof DailyMatrixInvoiceRow)[];

            const result = {
                depth: 0,
                total: 0,
                type: 'total',
                value: 'Total',
            } as unknown as { [key in keyof DailyMatrixInvoiceRow]: number };

            for (const key of keys) {
                result[key] = 0;
            }

            rootData?.forEach((row) => {
                for (const key of keys) {
                    result[key] = (result[key] ?? 0) + ((row[key] as number) ?? 0);
                }
            });

            if (result.differenceTotal) {
                result.differencePercent = result.differenceTotal / (result.day0 || 1);
            }

            this.totalRowCache.rootData = rootData;
            this.totalRowCache.totalRow = result;
        }

        return this.totalRowCache.totalRow!;
    }

    private invalidate() {
        this._datasource = undefined;
        this._columns = undefined;
        this._defaultState = undefined;
        this.renderKey.emit(this.renderKey.value + 1);
        return this;
    }

    public attach = (gridModel: DataGridModel) => {
        this.gridModel = gridModel;
        gridModel.gridStateChanged.listen((change) => {
            if (change?.changes.has('filters')) {
                this.onFilterChanged?.((change.state.filters ?? []).slice() as QueryExpr[]);
            }
            if (change?.changes.has('sort') && change.changes.size === 1) {
                this.sortDatasource?.(change.state);
            } else {
                this.invalidateDatasource?.();
            }
        });
    };

    public onGridRowClick = (row: DailyMatrixInvoiceRow) => {
        if (this.selectionEnabled) {
            this.gridModel?.setSelected(row, !this.gridModel?.isSelected(row));
        } else {
            this.gridModel?.treeModel?.toggle(row);
        }
    };

    public getGroupByName = (id: string) => {
        return this.schema?.getField(id)?.name ?? this.schema?.fieldLookup.get(id)?.name ?? id;
    };

    public childAccessor = {
        hasChildren: (row: DailyMatrixInvoiceRow) => row.depth < (this.gridModel?.gridState.groupBy?.length ?? 0) - 1,
    };

    public handleStateLoaded = () => {
        this.mergeStateColumns();
        this.onFilterChanged?.((this.gridModel?.gridState.filters ?? []).slice() as QueryExpr[]);
    };

    private updateColumns() {
        this.mergeStateColumns();
        if (this.gridModel) {
            this.gridModel?.applyColumnSelection(this.createColumns(), new Set<string>(['value']));
        }
    }

    private mergeStateColumns() {
        const state = this.gridModel?.gridState;
        if (state) {
            state.columns = this.createColumnState(state);
        }
    }

    private createColumnState(state?: DataGridState) {
        const stateLookup = new Map<string, GridColumnState>(state?.columns.map((c) => [c.id, c]) ?? []);
        const defaultColumns = this.createColumns();
        return defaultColumns.map((c) => stateLookup.get(c.id) ?? { id: c.id, width: c.defaultWidth, fixed: c.defaultFixed });
    }

    private createDefaultState() {
        return {
            filters: [],
            columns: [],
            sort: [{ Expr: { Field: 'value' }, Direction: 'Asc' }],
        } as DataGridState;
    }

    private createColumns() {
        const result: ColumnConfig<DailyMatrixInvoiceRow>[] = [
            {
                accessor: 'value',
                defaultWidth: 350,
                id: 'value',
                header: 'Detail',
                type: 'string',
                defaultFixed: true,
                footerRenderer: () => {
                    return <>Total</>;
                },
            },
        ];
        for (let i = 0; i < this.days.length; i++) {
            const day = this.days[i];
            result.push({
                accessor: `day${i}`,
                defaultWidth: 140,
                id: `day${i}`,
                header: this.formatSvc.toShortDate(day),
                type: 'number',
                align: 'right',
                formatter: (v) => this.formatSvc.formatMoneyNoDecimals(v[`day${i}`]),
                footerRenderer: () => {
                    return <TotalRowCell model={this} totalKey={`day${i}`} />;
                },
            });
        }
        if (this.includeDifference) {
            result.push({
                accessor: 'differenceTotal',
                defaultWidth: 160,
                id: 'differenceTotal',
                header: 'Dollars',
                type: 'number',
                align: 'right',
                groupName: 'Approximate Variance',
                formatter: (v) => this.formatSvc.formatMoneyNoDecimals(v.differenceTotal ?? 0),
                cellRenderer: (item: DailyMatrixInvoiceRow) => {
                    return (
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: 5 }}>
                            {this.formatSvc.formatMoneyNoDecimals(item.differenceTotal ?? 0)}
                            <IncreaseIndicator value={item.differenceTotal ?? 0} preferDecrease size="sm" />
                        </div>
                    );
                },
                footerRenderer: () => {
                    return (
                        <TotalRowCell
                            model={this}
                            renderer={(data) => (
                                <Tooltip
                                    withinPortal
                                    position="bottom"
                                    label={
                                        <>
                                            This is an approximation, a more accurate <br />
                                            calculation is available in the right panel.
                                        </>
                                    }
                                >
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: 5 }}>
                                        <Tilde size={16} />
                                        {this.formatSvc.formatMoneyNoDecimals(data.differenceTotal ?? 0)}
                                        <IncreaseIndicator value={data.differenceTotal ?? 0} preferDecrease size="sm" />
                                    </div>
                                </Tooltip>
                            )}
                        />
                    );
                },
            });
            result.push({
                accessor: 'differencePercent',
                defaultWidth: 160,
                id: 'differencePercent',
                header: 'Percent',
                type: 'number',
                align: 'right',
                groupName: 'Approximate Variance',
                cellRenderer: (item: DailyMatrixInvoiceRow) => {
                    const clamped = Math.min(100, Math.max(-10, item.differencePercent ?? 0));
                    return (
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: 5 }}>
                            {(clamped != item.differencePercent ? '>' : '') + this.formatSvc.formatPercent(clamped)}
                            <IncreaseIndicator value={item.differencePercent ?? 0} preferDecrease size="sm" />
                        </div>
                    );
                },
                footerRenderer: () => {
                    return (
                        <TotalRowCell
                            model={this}
                            renderer={(data) => (
                                <Tooltip
                                    withinPortal
                                    position="bottom"
                                    label={
                                        <>
                                            This is an approximation, a more accurate <br />
                                            calculation is available in the right panel.
                                        </>
                                    }
                                >
                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: 5 }}>
                                        <Tilde size={16} />
                                        {this.formatSvc.formatPercent(data.differencePercent ?? 0)}
                                        <IncreaseIndicator value={data.differencePercent ?? 0} preferDecrease size="sm" />
                                    </div>
                                </Tooltip>
                            )}
                        />
                    );
                },
            });
        }
        if (this.includeTotal) {
            result.push({
                accessor: 'total',
                defaultWidth: 160,
                id: 'total',
                header: 'Total',
                type: 'number',
                align: 'right',
                formatter: (v) => this.formatSvc.formatMoneyNoDecimals(v.total ?? 0),
                footerRenderer: () => {
                    return <TotalRowCell model={this} totalKey="total" />;
                },
            });
        }
        return result.map((c) => ({ ...c, noRemove: true, noReorder: true }));
    }

    private createDatasource(field: string) {
        const root = { value: 'root', type: 'root' } as DailyMatrixInvoiceRow;
        this.sortDatasource = (state: DataGridState) => {
            const sort = state.sort?.length ? state.sort : [{ Expr: { Field: 'value' }, Direction: 'Asc' } as QuerySortExpr];
            this.visitRows(root, (row) => {
                if (row.children?.length) {
                    row.children.splice(0, Infinity, ...new ArrayDataSource(row.children).applyState({ filters: [], sort }));
                    this.gridModel?.treeModel?.invalidateItem(row);
                }
            });
            this.gridModel?.treeModel?.clearChildrenLoaded?.();
            this.gridModel?.refresh(true);
        };
        this.invalidateDatasource = () => {
            root.children = undefined;
        };
        return {
            getPage: async (start, end, state, parent) => {
                const parents = this.getParents(parent);
                parent = parent ?? root;
                if (!parent?.children) {
                    parent.children = await this.getRows(state, parents, parent === root ? undefined : parent);
                }
                if (parent === root) {
                    this.rootDataUpdated.emit(parent.children);
                }
                return { items: parent.children.slice(start, end), total: parent.children.length };
            },
        } as IDataSource<DailyMatrixInvoiceRow>;
    }

    private visitRows(row: DailyMatrixInvoiceRow, visitor: (row: DailyMatrixInvoiceRow) => void) {
        visitor(row);
        if (row.children) {
            for (const child of row.children) {
                this.visitRows(child, visitor);
            }
        }
    }

    private async getRows(state: DataGridState, parents: DailyMatrixInvoiceRow[], parent: DailyMatrixInvoiceRow | undefined) {
        const filters = state.filters?.slice() ?? [];
        const flds = this.fieldCompat!.getAvailableFields('BilledCost', 'ChargePeriodStart');
        const costFld = flds[0] as 'BilledCost';
        const dateFld = flds[1] as 'ChargePeriodStart';
        if (parents.length) {
            filters.push(
                ...parents.map((p) =>
                    p.nullValue
                        ? { Operation: 'isNull', Operands: [{ Field: p.type }] }
                        : { Operation: 'eq', Operands: [{ Field: p.type }, { Value: p.value }] }
                )
            );
        }
        filters.push({
            Operation: 'eq',
            Operands: [{ Field: dateFld }, { Value: this.days.map((m) => this.formatSvc.toJsonShortDate(m)) }],
        });
        filters.push(...this.filters);
        const groupBy = state.groupBy?.[parents.length]?.id;
        const query: Query = {
            Select: [
                { Alias: 'value', Expr: { Operation: 'values', Operands: [{ Field: groupBy }, { Value: '' }, { Value: ValuesGroupOtherText }] } },
            ],
            Where: { Operation: 'and', Operands: filters },
        };
        for (let i = 0; i < this.days.length; i++) {
            const day = this.days[i];
            query.Select!.push({
                Alias: `day${i}`,
                Expr: exprBuilder<{ [dateFld]: string; [costFld]: number }>()
                    .createFluentExpr((b) => b.aggIf(b.model[dateFld].eq(this.formatSvc.toJsonShortDate(day)), b.sum(b.model[costFld])))
                    .resolve(),
            });
        }
        if (this.includeTotal) {
            query.Select!.push({
                Alias: `total`,
                Expr: exprBuilder<{ [costFld]: number }>()
                    .createFluentExpr((b) => b.sum(b.model[costFld]))
                    .resolve(),
            });
        }
        const results = (await this.invoiceApi.query(query, this.dayRange, false)) as QueryResult<{ value: string } & DailyMatrixInvoiceRowDay>;
        const items = (results.Results ?? []).map((r) => {
            for (let i = 0; i < this.days.length; i++) {
                r[`day${i}`] ??= 0;
            }
            const differenceTotal = this.includeDifference ? r[`day1`] - r[`day0`] : undefined;
            return {
                ...r,
                parent,
                value: r.value === ValuesGroupOtherText ? 'Other' : r.value,
                type: groupBy,
                depth: parents.length,
                nullValue: r.value === ValuesGroupOtherText,
                differenceTotal,
                differencePercent: !this.includeDifference
                    ? undefined
                    : !r['day0'] && !r['day1']
                    ? 0
                    : !r['day1']
                    ? -1
                    : differenceTotal! / r[`day0`],
            } as DailyMatrixInvoiceRow;
        });

        const datasource = new ArrayDataSource(items, [
            { field: 'value', type: 'string', getValue: (item: DailyMatrixInvoiceRow) => (item.nullValue ? '\uffff' : item.value) },
        ]);
        return datasource.applyState({ filters: [], sort: state.sort });
    }

    private getParents(parent: DailyMatrixInvoiceRow | undefined) {
        const parents: DailyMatrixInvoiceRow[] = [];
        while (parent) {
            parents.push(parent);
            parent = parent.parent;
        }
        return parents;
    }
}

function GroupByFieldPicker({ model, select }: { model: DailyMatrixInvoiceGridModel; select: (selection: { id: string }) => void }) {
    const fieldFilter = useCallback((fieldInfo: FieldInfo) => {
        return (
            fieldInfo.typeName === 'string' &&
            fieldInfo.name !== 'UsageMonth' &&
            fieldInfo.name !== 'BilledDate' &&
            fieldInfo.name !== 'UsageStartDate' &&
            fieldInfo.name !== 'UsageEndDate' &&
            fieldInfo.name !== 'ChargePeriodStart' &&
            fieldInfo.name !== 'ChargePeriodEnd'
        );
    }, []);
    const schemaFilter = useCallback((item: FieldInfo | TypeInfo) => {
        return 'fields' in item ? item.fields.some(fieldFilter) : fieldFilter(item);
    }, []);
    const handleChange = useCallback(
        (field: FieldInfo[]) => {
            if (field.length) {
                select({ id: field[0].path });
            }
        },
        [select]
    );
    return model.schema ? (
        <FieldPicker mode="single" onChange={handleChange} schema={model.schema} selections={[]} minimizeHeight schemaFilter={schemaFilter} />
    ) : null;
}

function TotalRowCell({
    model,
    totalKey,
    renderer,
}: {
    model: DailyMatrixInvoiceGridModel;
    totalKey?: keyof DailyMatrixInvoiceRow;
    renderer?: (value: { [key in keyof DailyMatrixInvoiceRow]: number }) => ReactNode;
}) {
    const fmtSvc = useDi(FormatService);
    useEvent(model.rootDataUpdated);
    const data = model.getTotalRow();

    return <>{renderer ? renderer(data) : totalKey ? fmtSvc.formatMoneyNoDecimals(data[totalKey] ?? 0) : <Text align="center">&mdash;</Text>}</>;
}
