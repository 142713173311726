import { NumberInput as BaseNumberInput, Select, Sx, Textarea, TextInput } from '@mantine/core';
import { DatePicker } from '@mantine/dates';
import { SettingsInputRow, SettingsInputStack, SettingsLabel } from '@root/Design/Settings';
import { useFmtSvc } from '@root/Services/FormatService';
import { DependencyList, useCallback, useMemo, useState } from 'react';
import {
    getAccessorKey,
    getInputBinding,
    IBaseInputSpec,
    IDateInputSpec,
    IInputSpecBuilderDone,
    IInputSpecBuilderType,
    IMultilineStringInputSpec,
    InputSpec,
    InputSpecBuilder,
    INumberInputSpec,
    IStringInputSpec,
    ModelAccessor,
} from './InputSpec';

type ISettingsInputProps = { spec?: InputSpec; onChanged?: (element: InputSpec) => void } | InputSpec;
export function SettingsInput(props: ISettingsInputProps) {
    const { spec, onChanged } = usePropsAsSpec(props);
    const wrappedSpec = useRerenderOnChange(spec, onChanged);

    return (
        <SettingsLabeled spec={wrappedSpec}>
            <InputSpecInput spec={wrappedSpec} />
        </SettingsLabeled>
    );
}

function usePropsAsSpec(props: ISettingsInputProps) {
    const spec = 'spec' in props ? props.spec : (props as InputSpec);
    const deps: DependencyList = 'spec' in props ? [spec, props.onChanged] : [spec?.onChange, spec?.icon, JSON.stringify(spec)];
    return useMemo(() => {
        return {
            spec: 'spec' in props && !!props.spec ? props.spec : ({ ...props } as InputSpec),
            onChanged: 'spec' in props ? props.onChanged : undefined,
        };
    }, deps);
}

export function useSpecBuilder<TData, TResult>(
    data: TData,
    build: (builder: IInputSpecBuilderType<TData>) => TResult & IInputSpecBuilderDone<TData, any>
): TResult extends IInputSpecBuilderDone<TData, infer U> ? U : never {
    return useMemo(() => {
        const builder = InputSpecBuilder.create<TData>();
        const built = build(builder);
        return built.bind(data);
    }, [data]);
}

export function useInputBinding<TModel, TModelValue>(model: TModel, accessor: ModelAccessor<TModel, TModelValue>, defaultValue?: TModelValue) {
    const rerender = useRerender();
    return useMemo(() => {
        const result = getInputBinding<TModel, TModelValue>(model, accessor, defaultValue);
        return wrapOnChange(result, { afterChange: rerender });
    }, [model, getAccessorKey(accessor, defaultValue)]);
}

export function useRerenderOnChange<T extends InputSpec['type']>(spec: InputSpec & { type: T }, onChanged?: (element: InputSpec) => void) {
    const rerender = useRerender();
    return useMemo(() => {
        return wrapOnChange(spec as any, {
            afterChange: () => {
                rerender();
                onChanged?.(spec);
            },
        }) as typeof spec;
    }, [spec]);
}

function useRerender() {
    const [, update] = useState(0);
    return useCallback(() => update((prev) => prev + 1), []);
}

type OnChangeMonitor<TValue> = {
    beforeChange?: (value: TValue) => void;
    overrideChange?: (value: TValue, callback: () => void) => void;
    afterChange?: (value: TValue) => void;
};
function wrapOnChange<TValue, T>(item: T & { onChange: (value: TValue) => void }, wrapper: OnChangeMonitor<TValue>) {
    const { beforeChange, overrideChange, afterChange } = wrapper;
    const originalOnChange = item.onChange.bind(item);
    const wrappedOnChange = (...args: any[]) => {
        let value = args[0];
        beforeChange?.(value);
        if (overrideChange) {
            overrideChange(value, () => originalOnChange(value));
        } else {
            originalOnChange(value);
        }
        afterChange?.(value);
    };

    return new Proxy(item, {
        get: (target, prop, receiver) => {
            if (prop === 'onChange') {
                return wrappedOnChange;
            }
            return Reflect.get(target, prop, receiver);
        },
    }) as T;
}

export function InputSpecInput<T extends InputSpec>(props: { spec: T }) {
    const { type } = props.spec;
    switch (type) {
        case 'string':
            return <SettingsStringInput spec={props.spec} />;
        case 'string-multiline':
            return <SettingsMultilineStringInput spec={props.spec} />;
        case 'number':
            return <SettingsNumberInput spec={props.spec} />;
        case 'date':
            return <SettingsDateInput spec={props.spec} />;
        default:
            return null;
    }
}

export function SettingsLabeled(props: { spec: IBaseInputSpec; children: React.ReactNode }) {
    const { label, description, icon, presentationOptions: pres = {} } = props.spec;
    const fullW = 'fullWidth' in pres ? (pres as { fullWidth?: boolean }).fullWidth : false;
    const Container = fullW ? SettingsInputStack : SettingsInputRow;
    return (
        <Container>
            <SettingsLabel icon={icon}>{label}</SettingsLabel>
            {props.children}
        </Container>
    );
}

function SettingsStringInput(props: { spec: IStringInputSpec }) {
    const { value, onChange, options, presentationOptions } = props.spec;
    const { align, decorLeft, decorRight, fullWidth, placeholder, width } = presentationOptions ?? {};
    const handleChange = useCallback(
        (e: string | null | React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) =>
            onChange(typeof e === 'string' ? e : !e ? '' : e.currentTarget.value),
        [onChange]
    );

    const inputSx: Sx = {
        width: fullWidth ? undefined : `var(--input-width, ${width ?? 140}px)`,
        input: { textAlign: align === 'center' ? 'center' : align === 'right' ? 'right' : 'left' },
        ['.mantine-Select-dropdown *']: { textAlign: align === 'center' ? 'center' : align === 'right' ? 'right' : 'left' },
    };

    const inputProps = { size: 'xs' as const, placeholder, sx: inputSx, value, onChange: handleChange, rightSection: decorRight, icon: decorLeft };

    return options?.length ? <Select data={options} {...inputProps} /> : <TextInput {...inputProps} />;
}

function SettingsMultilineStringInput(props: { spec: IMultilineStringInputSpec }) {
    const { value, onChange, presentationOptions } = props.spec;
    const { placeholder } = presentationOptions ?? {};
    const handleChange = useCallback(
        (e: string | null | React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) =>
            onChange(typeof e === 'string' ? e : !e ? '' : e.currentTarget.value),
        [onChange]
    );

    return <Textarea size="xs" minRows={2} maxRows={5} autosize placeholder={placeholder} value={value} onChange={handleChange} />;
}

function SettingsNumberInput(props: { spec: INumberInputSpec }) {
    const { defaultValue, value, onChange, allowEmpty, min, max, fractions, presentationOptions } = props.spec;
    const { align, decorLeft, decorRight } = presentationOptions ?? {};
    const handleChange = useCallback(
        (e: number | undefined) => {
            const num = parseFloat((e ?? '').toString());
            if (isNaN(num)) return;
            onChange(num);
        },
        [onChange, allowEmpty]
    );

    const digits = Math.max(min?.toString().length ?? 0, max?.toString().length ?? 0, defaultValue?.toString().length ?? 0) + (fractions ?? 0);
    const defaultWidth = 60 + digits * 8;
    const numberSx: Sx = {
        width: `var(--input-width, ${defaultWidth}px)`,
        ['input']: align === 'center' ? { textAlign: 'center', paddingLeft: 0 } : align === 'left' ? {} : {},
        ['input:disabled']: { cursor: 'default' },
    };

    return (
        <BaseNumberInput
            size="xs"
            sx={numberSx}
            min={min}
            max={max}
            value={value === null ? undefined : value}
            precision={fractions ?? 0}
            onChange={handleChange}
            rightSection={decorRight}
            icon={decorLeft}
        />
    );
}

function SettingsDateInput(props: { spec: IDateInputSpec }) {
    const fmtSvc = useFmtSvc();
    const { value: rawValue, onChange, presentationOptions } = props.spec;
    const { optional } = presentationOptions ?? {};
    const value = typeof rawValue === 'string' ? fmtSvc.toLocalDate(rawValue) : rawValue;
    const sx: Sx = {
        width: 'var(--input-width, 140px)',
        input: { textAlign: 'center' },
    };
    return (
        <DatePicker fixOnBlur sx={sx} inputFormat="MMM D, YYYY" clearable={!!optional} size="xs" value={value} onChange={onChange} allowFreeInput />
    );
}
