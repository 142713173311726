import { Company } from '@apis/Customers/model';
import { inject, injectable } from 'tsyringe';
import { ICompanyContextToken } from '../Customers/CompanyContext';
import { InvoiceApiService } from '../Invoices/InvoiceApiService';
import { InvoiceQueryDatasourceFactory } from '../Invoices/InvoiceQueryDatasourceFactory';
import { ResourceQueryDatasourceFactory } from '../Resources/ResourceQueryDatasourceFactory';
import { ResourceQueryService } from '../Resources/ResourceService';
import { IQueryDatasourceFactory } from './QueryDatasource';

@injectable()
export class QueryDatasourceCollection {
    public readonly datasourceFactories: IQueryDatasourceFactory[] = [];

    public constructor(
        @inject(InvoiceQueryDatasourceFactory) private readonly invoiceQueryDatasourceFactory: InvoiceQueryDatasourceFactory,
        @inject(InvoiceApiService) private readonly invoiceApi: InvoiceApiService,
        @inject(ResourceQueryDatasourceFactory) private readonly resourceQueryDatasourceFactory: ResourceQueryDatasourceFactory,
        @inject(ResourceQueryService) private readonly resourceQuerySvc: ResourceQueryService,
        @inject(ICompanyContextToken) private readonly company: Company
    ) {
        this.datasourceFactories.push(invoiceQueryDatasourceFactory, resourceQueryDatasourceFactory);
    }

    public getDatasourceMeta(datasourceName: string) {
        return this.datasourceFactories.find((f) => f.getMetadata().name === datasourceName)?.getMetadata();
    }

    public async getDatasource<TContext>(name: string, context: TContext) {
        switch (name) {
            case 'cur':
                return await this.invoiceQueryDatasourceFactory.getDatasource((query) => this.invoiceApi.query(query, context));
            case 'resources':
                return await this.resourceQueryDatasourceFactory.getDatasource((query) => this.resourceQuerySvc.query(query, this.company.Id ?? 0));
            default:
                throw new Error(`Unknown datasource: ${name}`);
        }
    }
}
