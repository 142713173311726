import styled from '@emotion/styled';
import { Box, Text } from '@mantine/core';
import { useAsync } from '@react-hookz/web';
import { Picker } from '@root/Components/Picker/Picker';
import { FillerSwitch } from '@root/Design/Filler';
import { EllipsisTextEl } from '@root/Design/Text';
import { AuthenticationService } from '@root/Services/AuthenticationService';
import { CompanyUserService, UserRecord } from '@root/Services/Customers/CompanyUserService';
import { useDiMemo } from '@root/Services/DI';
import { ComponentPropsWithoutRef, useCallback, useEffect, useMemo } from 'react';
import { useFloatedFullText } from '../Text/FloatedFullText';
import { UserImage } from './UserImage';

interface IUserPickerProps {
    selectedUserIds: number[];
    onChange: (userIds: number[]) => void;
    excludeCurrentUser?: boolean;
    includeDeactivated?: boolean;
    itemRightSection?: (item: UserRecord) => React.ReactNode;
}
type PickerProps = Pick<ComponentPropsWithoutRef<typeof Picker>, 'minimizeHeight' | 'height' | 'disableAutoFocus' | 'controlled'>;
export function UserPicker(props: IUserPickerProps & PickerProps) {
    const { excludeCurrentUser, includeDeactivated, itemRightSection, onChange, selectedUserIds, ...pickerProps } = props;

    const { loading, userList } = useCompanyUserList();
    const currentUser = useCurrentUser();

    const selectedUsers = useMemo(() => {
        return [...new Set(selectedUserIds)].map((id) => userList?.getById(id)!).filter((u) => !!u);
    }, [loading, JSON.stringify(props.selectedUserIds)]);

    const nameAccessor = useCallback((user: UserRecord) => [user.EMail ?? '', user.fullName, user.formalSortableName], []);
    const handleChange = useCallback(
        (users: UserRecord[]) => {
            onChange(users.map((u) => u.Id ?? 0).filter((id) => !!id));
        },
        [onChange]
    );

    const filter = useCallback((item: UserRecord) => {
        return (!excludeCurrentUser || currentUser?.Id !== item.Id) && (includeDeactivated || item.Status !== 'Deactivated');
    }, []);
    const { floatOnMouseEnter } = useFloatedFullText({ background: 'white', borderRadius: '3px' });
    const itemRenderer = useCallback(
        (user: UserRecord) => {
            return (
                <UserItemLayout data-userpicker-component="user-item-layout">
                    <UserImage
                        firstName={user.FirstName}
                        lastName={user.LastName}
                        email={user.EMail}
                        picture={user.Picture ?? ''}
                        id={user?.Id ?? 0}
                        diameter={28}
                    />
                    <Box data-userpicker-component="user-info">
                        <Text component={EllipsisTextEl} size={13} onMouseEnter={floatOnMouseEnter} data-userpicker-component="user-name">
                            <Text component="span" color="error" size="xs">
                                {user.Status === 'Deactivated' ? '(deactivated user)' : ''}
                            </Text>
                            {`${user?.FirstName} ${user?.LastName}`}{' '}
                        </Text>
                        <Text component={EllipsisTextEl} onMouseEnter={floatOnMouseEnter} size="xs">
                            {user?.EMail}
                        </Text>
                    </Box>
                    <Box data-userpicker-component="right-section">{itemRightSection?.(user)}</Box>
                </UserItemLayout>
            );
        },
        [itemRightSection]
    );

    return (
        <FillerSwitch loading={loading} noData={!(userList?.getList() ?? []).filter(filter).length} noDataMessage="No users found">
            {() => (
                <Picker
                    inlineFullText
                    items={userList!.getList()}
                    itemHeight={46}
                    filter={filter}
                    nameAccessor={nameAccessor}
                    onChange={handleChange}
                    selections={selectedUsers}
                    renderItem={itemRenderer}
                    {...pickerProps}
                    width="100%"
                    filterPlaceholder="Search users"
                />
            )}
        </FillerSwitch>
    );
}

export function useCurrentUser() {
    const authNSvc = useDiMemo(AuthenticationService);
    return authNSvc.user;
}

export function useCompanyUserList() {
    const userSvc = useDiMemo(CompanyUserService);
    const [{ status, result: userList }, { execute }] = useAsync(() => userSvc.getUserList());
    useEffect(() => {
        execute();
    }, []);

    return { loading: status === 'loading', userList };
}

const UserItemLayout = styled.div`
    display: grid;
    grid-template-columns: max-content auto min-content;
    align-items: center;
    gap: 6px;
    [data-userpicker-component='user-info'] {
        overflow: hidden;
    }
`;
