import { UserDefinedNotificationConfig } from '@apis/Notification/model';
import { QueryExpr } from '@apis/Resources';
import { Query } from '@apis/Resources/model';
import { Box, Center, Text } from '@mantine/core';
import { FillerSwitch } from '@root/Design/Filler';
import { useDiMemo } from '@root/Services/DI';
import { ExprEvaluator } from '@root/Services/Query/ArrayDataSource';
import { QueryDatasource } from '@root/Services/Query/QueryDatasource';
import { QueryDatasourceCollection } from '@root/Services/Query/QueryDatasourceCollection';
import { useEffect, useMemo, useState } from 'react';
import { ChartConfig, StandaloneChartRenderer } from '../../DashboardLayout/Charts/ChartRenderer';
import { NotificationContentBody } from './Design';

export function NotificationSnapshotContent(props: { config: UserDefinedNotificationConfig; snapshot: string }) {
    const { config, snapshot: rawData } = props;
    const data = useMemo(() => JSON.parse(rawData), [rawData]);
    const { ComponentConfig, QueryOptions } = config;
    const componentCfg = ComponentConfig as unknown as ChartConfig;
    const { DatasourceName, Parameters } = QueryOptions ?? {};

    const exprEvaluator = useDiMemo(ExprEvaluator);
    const datasourceCollection = useDiMemo(QueryDatasourceCollection);
    const [datasource, setDatasource] = useState<QueryDatasource>();

    useEffect(() => {
        let disposed = false;
        if (QueryOptions) {
            (async () => {
                const { ApiParameters, Parameters } = QueryOptions;
                const apiCtx = ApiParameters ? exprEvaluator.project(ApiParameters, { parameters: Parameters }) : {};
                const datasource = await datasourceCollection.getDatasource(DatasourceName ?? '', apiCtx);
                if (!disposed) {
                    const source = datasource.source;
                    datasource.source = (query: Query) => {
                        query.Parameters = Parameters ?? {};
                        return source(query);
                    };
                    setDatasource(datasource);
                }
            })();
        }
        return () => {
            disposed = true;
        };
    }, [JSON.stringify({ DatasourceName, Parameters }), config]);

    const filters = useMemo(() => (config.QueryOptions?.Filters ?? []) as QueryExpr[], [JSON.stringify(config.QueryOptions?.Filters)]);

    return (
        <NotificationContentBody>
            <FillerSwitch loading={!datasource}>
                {() => <StandaloneChartRenderer config={componentCfg} datasource={datasource!} filters={filters} />}
            </FillerSwitch>
        </NotificationContentBody>
    );
}
