import { inject, injectable } from 'tsyringe';
import { DatasourcePresetsCollection } from './Presets/PresetsCollection';

@injectable()
export class NotificationPresetService {
    public constructor(@inject(DatasourcePresetsCollection) private readonly invoicePresets: DatasourcePresetsCollection) {}

    public async getPresets<TContext extends {} = {}>(datasource: string | undefined = undefined, context: TContext = {} as TContext) {
        const presetBuilders = await this.invoicePresets.getBuilders(datasource, context);
        return presetBuilders.flatMap((b) => b.getPresets());
    }
}
