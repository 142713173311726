import { NotificationTypePreference, UserDefinedNotificationConfig, UserDefinedNotificationRecipient } from '@apis/Notification/model';
import { Space } from '@mantine/core';
import { SettingsSection, SettingsSectionItem } from '@root/Design/Settings';
import { useDiMemo } from '@root/Services/DI';
import { EventEmitter, useEvent } from '@root/Services/EventEmitter';
import { useEffect } from 'react';
import { injectable } from 'tsyringe';
import { FormSection, SettingsForm } from '../../Settings/SettingsForms';
import { DeliveryOptions } from './DeliveryOptions';
import { NotificationSettingsEditorScope } from './Models';
import { IPresetOption, PresetLookup, PresetPicker } from '../../Settings/Presets';
import { INotificationPresetItem, INotificationPresetOption, NotificationConfigPresentation } from './Presets/BasePresets';

@injectable()
class NotificationDefinitionEditorModel {
    private typePref!: NotificationTypePreference;
    private presetLookup: PresetLookup<INotificationPresetOption<any>>;
    private onChange: (config: UserDefinedNotificationConfig | undefined) => void = () => {};

    public readonly settingsSections: FormSection[] = [];
    public readonly descriptionSection: FormSection[] = [];
    public readonly presetChanged = EventEmitter.empty();
    public scope!: NotificationSettingsEditorScope;
    public recipients: UserDefinedNotificationRecipient[] = [];

    public definition: UserDefinedNotificationConfig & { PresentationOptions?: NotificationConfigPresentation } = {};
    public readonly presets: INotificationPresetItem<any>[] = [];

    public constructor() {
        this.presetLookup = new PresetLookup([]);
    }

    public init(
        typePref: undefined | NotificationTypePreference,
        scope: NotificationSettingsEditorScope,
        presets: INotificationPresetItem<any>[],
        onChange: (config: UserDefinedNotificationConfig | undefined) => void
    ) {
        this.scope = scope;
        this.typePref = typePref ?? {};
        this.definition = this.typePref.NotificationDefinition ??= {};
        this.recipients = this.typePref.Recipients ?? [];

        this.presets.splice(0, Infinity, ...presets);
        this.presetLookup = new PresetLookup(presets);

        const presetId = this.definition.PresentationOptions?.presetId;
        const preset = presetId ? this.presetLookup.getItem(presetId) : undefined;
        if (preset) {
            if (!typePref?.Id) {
                this.applyPreset(preset);
            } else {
                this.updateForms();
            }
        }

        this.updateOnChange(onChange);
        this.invalidateConfig();

        return this;
    }

    public updateOnChange(onChange: (config: UserDefinedNotificationConfig | undefined) => void) {
        this.onChange = onChange;
    }

    public getDefinitionPreset() {
        const presetId = this.definition.PresentationOptions?.presetId;
        return presetId ? this.presetLookup.getItem(presetId) : undefined;
    }

    public invalidateConfig = () => {
        this.onChange(this.definition);
    };

    public onPresetChange = (preset: IPresetOption<any, any, any> | null) => {
        this.changePreset(preset);
    };

    private updateContext = (context: any) => {
        this.scope.context = context;
    };

    private changePreset(option: INotificationPresetOption<any> | null) {
        const preset = option as unknown as INotificationPresetOption<any>;
        const currentPresetId = this.definition.PresentationOptions?.presetId;

        if (currentPresetId !== preset.id || !this.definition) {
            this.definition = { PresentationOptions: { presetId: preset.id } };
            this.applyPreset(preset);
        }
    }

    private applyPreset(preset: INotificationPresetOption<any>) {
        const args = { context: this.scope.context, updateContext: this.updateContext };
        const updatedDef = preset.behavior.initialize({ data: this.definition, ...args });
        this.updateDefinition(updatedDef);
        this.updateForms();
        this.presetChanged.emit();
    }

    private updateForms() {
        const preset = this.getDefinitionPreset();
        if (preset) {
            type DataType = Parameters<(typeof preset.behavior)['getForm']>[0]['data'];
            this.settingsSections.splice(
                0,
                Infinity,
                ...preset.behavior.getForm({
                    data: this.definition as DataType,
                    context: this.scope.context,
                    updateContext: this.updateContext,
                })
            );
            this.invalidateConfig();
        }
    }

    private updateDefinition(update: UserDefinedNotificationConfig) {
        this.typePref.NotificationDefinition = this.definition = update;
    }
}

interface INotificationSettingsEditorProps {
    typePref: NotificationTypePreference;
    scope: NotificationSettingsEditorScope;
    presets: INotificationPresetItem<any>[];
    onChange: (config: UserDefinedNotificationConfig | undefined) => void;
}
export function NotificationSettingsEditor(props: INotificationSettingsEditorProps) {
    const { presets, typePref, scope, onChange } = props;
    const model = useDiMemo(NotificationDefinitionEditorModel, [typePref, scope, presets], (m) => m.init(typePref, scope, presets, onChange));
    useEffect(() => model.updateOnChange(onChange), [model, onChange]);
    useEvent(model.presetChanged);

    const presetId = model.getDefinitionPreset()?.id;
    return (
        <>
            <SettingsSection title="Notification Types" collapsed={model.getDefinitionPreset() !== undefined}>
                <SettingsSectionItem>
                    <PresetPicker items={presets} onChange={model.onPresetChange} selectedId={presetId} />
                </SettingsSectionItem>
            </SettingsSection>
            <Space h="md" />
            <SettingsForm onChanged={model.invalidateConfig} sections={model.settingsSections} />
            {!presetId ? null : (
                <>
                    <Space h="md" />
                    <DeliveryOptions typePrefId={typePref?.Id} recipients={model.recipients} ownerUserId={typePref?.UserId ?? 0} />
                </>
            )}
        </>
    );
}
