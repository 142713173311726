import { IQueryExpr } from '@apis/Jobs/model';
import { QueryExpr, QueryField } from '@apis/Resources';
import { BaseResource, QuerySortExpr } from '@apis/Resources/model';
import { Group, Text } from '@mantine/core';
import { CustomColors } from '@root/Design/Themes';
import { EventEmitter } from '@root/Services/EventEmitter';
import { Logger } from '@root/Services/Logger';
import { IValueProvider } from '@root/Services/Query/ArrayDataSource';
import { SchemaService } from '@root/Services/QueryExpr';
import { inject, injectable } from 'tsyringe';
import { IDashboardConfig } from '../DashboardLayout/Models';
import { DashboardPersistenceService } from '../DashboardPersistence/DashboardPersistenceService';
import { ITreeItemState, VirtualTree } from '../VirtualTree';
import { Node } from '../VirtualTree/Node';
import { VirtualTreeModel } from '../VirtualTree/VirtualTreeModel';
import { ColumnSelectorOption, IColumnSelectorGroup, IColumnSelectorOption } from './ColumnSelector';
import { GridArrayDataSource, BufferingDataSource, QueryApiDataSource } from './DataSources';
import { InBrowserExportService } from './InBrowserExportService';
import {
    ColumnConfig,
    ColumnFilterConfig,
    ColumnGroupConfig,
    DataColumnConfig,
    DataGridProps,
    DataGridState,
    DataGridStateChange,
    DataSourceConfig,
    GridColumnState,
    GridGroupByState,
    ISelectionStrategy,
} from './Models';
import { DashboardUserSettings } from '@apis/Customers/model';
import { getDashboardGetOrCreateDashboardUserSettings } from '@apis/Customers';
import { TooltipWhite } from '@root/Design/Primitives';
import { AuthenticationService } from '@root/Services/AuthenticationService';
import { ExportRequest } from '@apis/Export/model';

@injectable()
export class DataGridModel {
    private columnLookup = new Map<string, DataGridColumn>();
    private filterFieldLookup = new Map<string, ColumnFilterConfig>();
    private dataSource: BufferingDataSource<any> = new BufferingDataSource(new GridArrayDataSource([], []));
    private pageThrottle: any = 0;
    private pageThrottleTimeoutMs = 300;
    private configId?: number;
    private maxLoadAllPages = 100000;
    private _schemaSvc?: SchemaService;
    private _showOnlySelected?: boolean = false;
    private _filteredByFieldLookup: Set<string> | null = null;
    private _autoColumnConfigs = new WeakMap<ColumnConfig<any>, ColumnConfig<any>[]>();
    private _autoColumnSource = new WeakMap<ColumnConfig<any>, ColumnConfig<any>>();
    private _columnsInvalidated = false;

    public currentOptions?: DataGridProps<any>;
    public userSettings?: DashboardUserSettings;
    public selections: ISelectionStrategy<any> = new BasicSelectionStrategy(() => this.treeModel?.items.map((n) => n.item) ?? []);
    public gridState: DataGridState = { columns: [], filters: [], sort: [] };
    public columns: DataGridColumn[] = [];
    public customColumns?: ColumnConfig<BaseResource>[] | undefined;
    public columnOffsets = new Map<DataGridColumn, ColumnOffsetInfo>();
    public visibleColumns: DataGridColumn[] = [];
    public columnSets: GridColumnSet[] = [];
    public checkboxColWidth = 30;
    public treeModel?: VirtualTreeModel<any>;
    public treeView?: VirtualTree | null;
    public modifyColumnSelectorOptions?: (options: { options: ColumnSelectorOption[]; selections: IColumnSelectorOption[] }) => void;
    public beforeApplyColumns?: (columns: GridColumnState[]) => void;
    public ensureViewable = new EventEmitter<{ left: number; right: number; speed?: number; finish?: boolean } | undefined>(undefined);
    public scrollPosChanged = new EventEmitter<{ top: number[]; left: number } | undefined>(undefined);
    public filterDisabled = new EventEmitter<boolean>(false);
    public scrollTo = new EventEmitter<{ left?: number; top?: number } | undefined>(undefined);
    public hovered = new EventEmitter<any>(undefined);
    public stateSaved = EventEmitter.empty();
    public configName?: string;
    public get filteredByFieldLookup() {
        if (!this._filteredByFieldLookup) {
            this._filteredByFieldLookup = new Set(
                this.gridState.filters.map((f) => 'Operands' in f && 'Field' in f.Operands[0] && f.Operands[0].Field)
            );
        }
        return this._filteredByFieldLookup;
    }
    public get selectRowOnClick() {
        return this.currentOptions?.onRowClick === 'select';
    }
    public get rowClickHandler() {
        return this.currentOptions?.onRowClick;
    }
    public get itemHeight() {
        return this.currentOptions?.itemHeight ?? 30;
    }
    public get expandable() {
        return !!this.currentOptions?.childAccessor;
    }
    public get showCheckbox() {
        return this.currentOptions?.selectionMode === 'multiple' || !!this.currentOptions?.renderRowSelector;
    }
    public get schemaSvc() {
        return this._schemaSvc;
    }
    public scrollPos = { top: [0], left: 0 };

    public get pageInvalidated() {
        return this.dataSource.dataLoaded;
    }
    public get itemCount() {
        return this.currentOptions?.itemCount ?? this.dataSource.itemCount;
    }

    public get filterValueProvider() {
        return (
            this.currentOptions?.filterValueProvider ?? {
                getValueProvider: (field: QueryField) => {
                    const filterOptions = this.filterFieldLookup.get(field.Field);
                    return filterOptions?.options?.getValueProvider(field);
                },
            }
        );
    }

    public async refresh(clear: boolean = true) {
        if (clear) {
            this.dataSource.clear();
        }
        await this.dataSource.load(this.gridState, !clear);
        this.treeModel?.reloadTree();
        this.viewInvalidated.emit(clear);
    }

    public get hasGroups() {
        return this.visibleColumns.some((c) => !!c.groupConfig);
    }
    public get displayMode() {
        return this.currentOptions?.displayMode ?? 'grid';
    }
    public get canExport() {
        return (
            this.currentOptions?.export !== undefined || this.currentOptions?.dataSource instanceof Array || this.currentOptions?.allowLoadAllPages
        );
    }
    public selectionChanged = EventEmitter.empty();
    public viewInvalidated = new EventEmitter<void | boolean>(void 0);
    public gridStateChanged = new EventEmitter<DataGridStateChange | undefined>(undefined);
    public columnSetSizeChanged = EventEmitter.empty();
    public attachedBodyChanged = EventEmitter.empty();
    public groupByChanged = EventEmitter.empty();
    public newFilterRequested = new EventEmitter<DataGridColumn | null>(null);
    public updateRenderSize = () => {};

    public constructor(
        @inject(Logger) private logger: Logger,
        @inject(DashboardPersistenceService) private statePersistenceSvc: DashboardPersistenceService,
        @inject(AuthenticationService) private readonly authSvc: AuthenticationService
    ) {}

    public load(props: DataGridProps<any>) {
        if (!this.currentOptions) {
            this.currentOptions = props;
            this.init();
        } else {
            let hasChanges = false;
            if (this.currentOptions.dataSource !== props.dataSource) {
                hasChanges = true;
                this.dataSource = new BufferingDataSource(
                    this.getDataSource(props.dataSource),
                    this.currentOptions.requestSize ?? 200,
                    this.currentOptions.backtrackSize ?? 20
                );
            }
            this.currentOptions = props;
            if (this._columnsInvalidated) {
                this.updateColumns(props.columns);
                this._columnsInvalidated = false;
                hasChanges = true;
            }
            if (hasChanges) {
                this.viewInvalidated.emit();
                this.applyState();
            }
        }
    }

    public loadSchema(schemaSvc: SchemaService) {
        this._schemaSvc = schemaSvc;
    }

    public getShowOnlySelected() {
        return this._showOnlySelected;
    }

    public async showOnlySelected(showOnlySelected: boolean) {
        this._showOnlySelected = showOnlySelected;
        this.viewInvalidated.emit();
    }

    public scrollToColumn(colId: string) {
        const col = this.columnLookup.get(colId);
        const offsetInfo = col ? this.getOffset(col) : null;
        if (offsetInfo) {
            this.scrollTo.emit({ left: offsetInfo.offset });
        }
    }

    public loadAvailableColumns(columns: ColumnConfig<any>[]) {
        const colLookup = new Set(columns.map((c) => c.id));
        let changed = false;
        const addedColumns = this.createColumns(columns.filter((c) => !this.columnLookup.has(c.id)));
        if (addedColumns.length) {
            this.columns.push(...addedColumns);
            changed = true;
        }
        this.columns = this.columns.filter((c) => {
            if (!colLookup.has(c.config.id)) {
                this.columnLookup.delete(c.config.id);
                changed = true;
                return false;
            }
            return true;
        });
        if (changed) {
            this.applyState();
            this.viewInvalidated.emit();
        }
    }

    public attachBody(treeState: ITreeItemState<any>) {
        this.treeModel = treeState.model;
        if (this.currentOptions?.selectionMode === 'single') {
            this.treeModel.onHighlightChanged = () => this.selectionChanged.emit();
        }
        this.attachedBodyChanged.emit();
    }

    public updateSetWidths() {
        let setTotal = 0;
        this.columnSets.forEach((set) => {
            set.calculate();
            set.offset = setTotal;
            setTotal += set.width;
        });
        this.columnSetSizeChanged.emit();
    }

    public async getData() {
        const result = await this.dataSource.load(this.gridState);
        return result.items;
    }

    public applyFilters(filters: IQueryExpr[]) {
        if (this.currentOptions?.onFilterAdded) {
            this.currentOptions.onFilterAdded(filters);
        }
        this.gridState.filters.splice(0, Infinity, ...filters);
        this.raiseStateChanged(true, 'filters');
        this.selections.setSelectAll(false);
        this._filteredByFieldLookup = null;
        this.viewInvalidated.emit();
    }

    public addFilter(column: DataGridColumn) {
        this.newFilterRequested.emit(column);
    }
    public clearFilters(): void {
        this.currentOptions?.onFilterClearing?.();
        this.gridState.filters = [];
        this.raiseStateChanged(true, 'filters');
        this._filteredByFieldLookup = null;
        this.viewInvalidated.emit();
    }

    public checkItems(items: Node<any>[]) {
        clearTimeout(this.pageThrottle);
        this.pageThrottle = setTimeout(
            () =>
                this.dataSource.loadUnknownItems(
                    this.gridState,
                    items.map((n) => n.item)
                ),
            this.pageThrottleTimeoutMs
        );
    }

    public hasChildren(parent: any) {
        if (this.currentOptions?.childAccessor) {
            return this.currentOptions?.childAccessor.hasChildren(parent) ?? true;
        } else {
            return false;
        }
    }

    public async getChildren(parent: any) {
        if (this.currentOptions?.childAccessor) {
            if (this.currentOptions?.childAccessor?.getChildren) {
                return this.currentOptions?.childAccessor.getChildren(parent);
            } else {
                const result = await this.dataSource.loadChildren(this.gridState, parent);
                return result.items;
            }
        }
    }

    public getOffset(column: DataGridColumn) {
        return this.columnOffsets.get(column)!;
    }

    public getGroupConfig(groupName?: string) {
        return this.currentOptions?.groupConfig?.[groupName ?? ''];
    }

    public sort(sortExpr: QuerySortExpr | QuerySortExpr[]) {
        if (Array.isArray(sortExpr)) {
            this.gridState.sort.splice(0, Infinity, ...sortExpr);
        } else if (this.currentOptions?.allowMultiSort) {
            const idx = this.gridState.sort.findIndex(
                (s) => s.Expr && 'Field' in s.Expr && sortExpr.Expr && 'Field' in sortExpr.Expr && s.Expr.Field === sortExpr.Expr.Field
            );
            if (idx >= 0) {
                this.gridState.sort.splice(idx, 1);
            }
            this.gridState.sort.unshift(sortExpr);
        } else {
            this.gridState.sort.splice(0, Infinity, sortExpr);
        }
        this.raiseStateChanged(true, 'sort');
        this.viewInvalidated.emit();
    }

    public removeSort(queryField: QueryField) {
        const idx = this.gridState.sort.findIndex((s) => s.Expr && 'Field' in s.Expr && s.Expr.Field === queryField.Field);
        if (idx >= 0) {
            this.gridState.sort.splice(idx, 1);
            this.raiseStateChanged(true, 'sort');
            this.viewInvalidated.emit();
        }
    }

    public setSelected(item: any, selected: boolean) {
        if (this.selections.isSelected(item) !== selected) {
            this.selections.setSelected(item, selected);
            this.selectionChanged.emit();
        }
    }

    public async setSelectAll(selected: boolean) {
        await this.selections.setSelectAll(selected);
        this.selectionChanged.emit();
    }

    public select(item: any) {
        this.setSelected(item, true);
    }
    public deselect(item: any) {
        this.setSelected(item, false);
    }
    public isSelected(item: any) {
        return this.selections.isSelected(item);
    }
    public getSelectedItems = async () => {
        return await this.selections.getSelected();
    };

    public hasSelectedItems = () => {
        return this.selections.count() > 0;
    };

    public isLoading(item: any) {
        return this.dataSource.isLoading(item) || this.dataSource.isUnknown(item);
    }

    public getColumnById(id: string) {
        return this.columnLookup.get(id);
    }

    public export = async () => {
        if (this.currentOptions?.export === 'unavailable') {
            return;
        }

        if (this.currentOptions?.export) {
            this.currentOptions.export(this);
        } else if (this.currentOptions?.dataSource instanceof Array || this.currentOptions?.allowLoadAllPages) {
            const exportSvc = new InBrowserExportService();
            const rawDataSource =
                this.currentOptions?.dataSource instanceof Array
                    ? this.currentOptions.dataSource ?? []
                    : (await this.dataSource.load({ ...this.gridState }, false, this.maxLoadAllPages)).items;
            if (this.currentOptions.splitBodyProps) {
                this.currentOptions.splitBodyProps.forEach(async (prop, index, array) => {
                    if (prop.datasource && prop.datasource instanceof Array) {
                        rawDataSource.push(...prop.datasource);
                    }
                });
            }
            const dataSource = this.createArrayDataSource(rawDataSource);
            const data = dataSource.applyState(this.gridState);
            const name = this.currentOptions?.exportName
                ? this.currentOptions.exportName
                : !this.configName || this.configName === 'Default'
                ? 'Export'
                : this.configName;
            const columns = this.gridState.columns
                .map((c) => this.columnLookup.get(c.id)!)
                .filter((c) => !!c && c.config.exportOptions?.hidden !== true)
                .map((c) => ({ value: c.renderForExport, header: c.renderExportHeader(), format: c.config?.exportOptions?.format ?? '' }));

            exportSvc.export(data, columns, name);
        }
    };

    public invalidateColumns() {
        this._columnsInvalidated = true;
    }
    private async init() {
        this.updateColumns(this.currentOptions!.columns);
        this._filteredByFieldLookup = null;
        await this.loadState();
        this.dataSource = new BufferingDataSource(
            this.getDataSource(this.currentOptions!.dataSource),
            this.currentOptions!.requestSize ?? 200,
            this.currentOptions!.backtrackSize ?? 20
        );
        await this.reloadUserSettings();
        this.applyState();
        this.initSelection();
        this.viewInvalidated.emit();
    }

    public canRevert() {
        return this.detectStateChanges(this.gridState, this.currentOptions?.state ?? {});
    }

    public async revert() {
        const prevState = this.gridState;
        const nextState = this.currentOptions?.onStateReverting?.();
        if (this.currentOptions?.allowSavedViews) {
            const defaultConfig = await this.tryGetDefaultConfig();
            this.configName = 'Default';
            this.configId = defaultConfig?.id;
        }
        this.gridState = this.copy(nextState ?? this.currentOptions?.state!);
        this.detectAndRaiseStateChanges(prevState);
        this.applyState();
        this.viewInvalidated.emit();
        this.saveState();
    }

    public getGroupBy() {
        return this.gridState.groupBy ?? [];
    }
    public setGroupBy(groupBy: GridGroupByState[]) {
        this.gridState.groupBy = groupBy;
        this.applyGroupByChanges();
    }
    public getGroupByDepth() {
        return this.gridState.groupBy?.length ?? 0;
    }
    public hasGroupBy() {
        return !!this.gridState.groupBy?.length;
    }
    public setGroupBySort(groupId: string, sortMode: 'count' | 'value', sortDir: 'Asc' | 'Desc') {
        const groupIdx = this.gridState.groupBy?.findIndex((g) => g.id === groupId);
        if (typeof groupIdx === 'number' && groupIdx >= 0) {
            const group = this.gridState.groupBy![groupIdx];
            if (group.sortDir !== sortDir || group.sortMode !== sortMode) {
                group.sortDir = sortDir;
                group.sortMode = sortMode;
                this.applyGroupByChanges();
            }
        }
    }
    public toggleGroupBy(column: DataGridColumn) {
        if (!this.gridState.groupBy) {
            this.gridState.groupBy = [];
        }
        const idx = this.gridState.groupBy.findIndex((g) => g.id === column.config.id);
        if (idx < 0) {
            this.gridState.groupBy.push({ id: column.config.id, sortDir: 'Desc', sortMode: 'count' });
        } else {
            const requiredGroups = this.currentOptions?.groupByRequired ?? 0;
            if (this.gridState.groupBy.length - 1 < requiredGroups) {
                return;
            }
            this.gridState.groupBy.splice(idx, 1);
        }
        this.applyGroupByChanges();
    }

    public clearGroupBy = () => {
        this.gridState.groupBy = [];
        this.applyGroupByChanges();
    };

    private applyGroupByChanges() {
        this.applyState();
        this.selections.setSelectAll(false);
        this.groupByChanged.emit();
        this.raiseStateChanged(true, 'groupBy');
        this.viewInvalidated.emit();
    }

    public renderGroupBy = (item: Node<any>) => {
        if (this.currentOptions?.renderGroupBy) {
            return this.currentOptions.renderGroupBy(item);
        } else {
            return <></>;
        }
    };

    private copy<T>(item: T) {
        return JSON.parse(JSON.stringify(item)) as T;
    }

    private initSelection() {
        if (this.currentOptions!.selectionStrategy) {
            this.selections = this.currentOptions!.selectionStrategy;
        }
        if (this.currentOptions?.selectionMode === 'single') {
            this.selections = new TreeSingleSelectionStrategy(() => this.treeModel);
            if ('selection' in this.currentOptions && this.currentOptions?.selection) {
                this.selections.setSelected(this.currentOptions.selection, true);
            }
        }
        if (
            this.currentOptions!.selectionMode === 'multiple' &&
            'initialSelection' in this.currentOptions! &&
            this.currentOptions!.initialSelection
        ) {
            for (const item of this.currentOptions!.initialSelection) {
                this.selections.setSelected(item, true);
            }
        }
    }

    public async reloadUserSettings() {
        const { statePersistence } = this.currentOptions!;
        if (statePersistence?.key) {
            this.userSettings = await getDashboardGetOrCreateDashboardUserSettings();
        }
    }

    public async loadStateConfig(id: number, config: IDashboardConfig, applyState: boolean = false) {
        if (config.layout) {
            this.configId = id;
            this.configName = config.name;
            const [gridLayout] = config.layout;
            const state = gridLayout.data;
            if (state.columns) {
                this.gridState = state;
                this.currentOptions!.onStateLoaded?.(config);
                if (applyState) {
                    this.applyState();
                    this.viewInvalidated.emit();
                }
            }
            this.raiseStateChanged(false, 'filters');
        }
    }

    private async loadState() {
        const state = this.currentOptions!.state ? this.copy(this.currentOptions!.state) : this.createDefaultState(this.currentOptions!.columns);
        this.gridState = state;
        const { statePersistence } = this.currentOptions!;
        if (statePersistence && statePersistence.key) {
            const { key } = statePersistence;
            let layouts = await this.statePersistenceSvc.getLayouts<IDashboardConfig>(key);
            if (this.currentOptions?.allowSavedViews && !layouts.length) {
                await this.saveState();
                layouts = await this.statePersistenceSvc.getLayouts<IDashboardConfig>(key);
            }

            if (layouts?.length) {
                layouts = layouts
                    .filter((l) => l.ownerUserId == this.authSvc.user?.Id)
                    .sort((a, b) => (b.layout.dateAccessed ?? new Date(0)).getTime() - (a.layout.dateAccessed ?? new Date(0)).getTime());

                if (layouts.length) {
                    const [config] = layouts;
                    this.loadStateConfig(config.id, config.layout);
                }
            } else {
                this.currentOptions!.onStateLoaded?.(undefined);
            }
        }
    }

    public getConfigId() {
        return this.configId;
    }

    public async handleStateDelete(id: number) {
        if (id === this.configId) {
            this.configId = undefined;
            this.configName = undefined;
            this.gridState = this.currentOptions!.state
                ? this.copy(this.currentOptions!.state)
                : this.createDefaultState(this.currentOptions!.columns);
            this.currentOptions!.onStateLoaded?.(undefined);
        }
    }

    public handleStateRename(id: number, name: string) {
        if (id === this.configId) {
            this.configName = name;
        }
    }

    public async saveStateCopy(name: string) {
        const { statePersistence } = this.currentOptions!;
        if (statePersistence && statePersistence.key) {
            const config = this.getDashboardConfig(name);
            this.currentOptions!.onStateSaving?.(config);
            const id = await this.statePersistenceSvc.save(statePersistence.key, undefined, config);
            this.configName = name;
            this.configId = id;
            this.stateSaved.emit();
        }
    }

    private async tryGetDefaultConfig() {
        const { statePersistence } = this.currentOptions!;
        if (statePersistence?.key) {
            const layouts = await this.statePersistenceSvc.getLayouts(statePersistence.key);
            layouts.sort((a, b) => a.id - b.id);
            const defaultLayout = layouts.find((l) => l.layout.name === 'Default');
            return defaultLayout;
        }
    }

    public async saveState(manualSave: boolean = false) {
        const { statePersistence } = this.currentOptions!;
        const shouldSave = this.userSettings?.DashboardAutoSave || manualSave;
        if (statePersistence && statePersistence.key && shouldSave) {
            const config = this.getDashboardConfig();
            this.currentOptions!.onStateSaving?.(config);
            this.configId = await this.statePersistenceSvc.save(statePersistence.key, this.configId, config);
            this.stateSaved.emit();
        }
    }

    public getFilterByFilterField(filterField: string) {
        return this.filterFieldLookup.get(filterField);
    }

    public getDashboardConfig(name?: string, stateOverride?: DataGridState) {
        const state = stateOverride ?? this.gridState;
        return {
            name: name ?? this.configName ?? 'Unnamed View',
            layout: [{ x: 0, y: 0, w: 12, h: 24, data: { type: 'Grid', ...state } }],
        } as IDashboardConfig;
    }

    public getDashboardConfigForExport(name?: string, sheetName?: string, stateOverride?: DataGridState): ExportRequest['DashboardConfig'];
    public getDashboardConfigForExport(name?: string, sheetName?: string, filters?: QueryExpr[]): ExportRequest['DashboardConfig'];
    public getDashboardConfigForExport(
        name?: string,
        sheetName?: string,
        stateOverride?: QueryExpr[] | DataGridState
    ): ExportRequest['DashboardConfig'] {
        const config = this.getDashboardConfig(name);
        if (sheetName) {
            config.layout[0].data.DashboardItemName = sheetName;
        }
        if (Array.isArray(stateOverride)) {
            config.filters = [...(stateOverride ?? []), ...(config.filters ?? [])];
        } else if (stateOverride) {
            Object.assign(config.layout[0].data, stateOverride);
        }
        return {
            Layout: config.layout,
            Name: config.name,
            Filters: config.filters,
        };
    }

    private updateColumns(columns: ColumnConfig<any>[]) {
        this.columnLookup.clear();
        this.columns = this.createColumns(this.currentOptions!.columns);
    }

    public updateColumnBackgroundColor(columnId: string, color?: CustomColors) {
        const col = this.columnLookup.get(columnId);
        if (col) {
            col.backgroundColor = color;
        }
    }

    public getAutoColumnOptions(aggColumn: DataGridColumn) {
        const source = aggColumn.config.aggregations ? aggColumn.config : this._autoColumnSource.get(aggColumn.config);
        const options = source ? this._autoColumnConfigs.get(source) : undefined;
        if (options) {
            return { source, options };
        }
        return undefined;
    }

    private createColumns(columns: ColumnConfig<any>[]) {
        const result: DataGridColumn[] = [];
        for (const config of this.getAggColumns(columns)) {
            const column = new DataGridColumn(this, config);
            result.push(column);

            if (this.columnLookup.has(config.id)) {
                throw new Error(`Grid configuration fail, config contains duplicate column headers "${config.id}". `);
            }
            this.columnLookup.set(config.id, column);
            if (config.filter && typeof config.filter === 'object' && config.filter.filterField) {
                this.filterFieldLookup.set(config.filter.filterField, config.filter);
            }
        }
        return result;
    }

    private *getAggColumns(columns: ColumnConfig<any>[]) {
        for (const col of columns) {
            yield col;
            if (col.aggregations) {
                const autoColumns: ColumnConfig<any>[] = [];
                for (const agg of col.aggregations) {
                    const accessorField = `${agg}(${col.sortField})`;
                    const accessor = col.aggAccessor
                        ? (v: any) => col.aggAccessor!(v, agg)
                        : (v: any) => (accessorField in v ? v[accessorField] : v[col.sortField ?? '']);
                    const autoColumn = {
                        ...col,
                        aggregations: undefined,
                        id: `${agg}(${col.id})`,
                        header: `${agg} ${col.header}`,
                        filter: undefined,
                        aggregator: agg,
                        accessor: accessor,
                        sortField: accessorField,
                        cellRenderer: undefined,
                    };
                    autoColumns.push(autoColumn);
                    this._autoColumnSource.set(autoColumn, col);
                    yield autoColumn;
                }
                this._autoColumnConfigs.set(col, autoColumns);
            }
        }
    }

    private createDefaultState(columns: ColumnConfig<any>[]) {
        return {
            columns: this.createDefaultColumnState(columns),
            filters: [],
            sort: [],
            groupBy: this.currentOptions?.defaultGroupBy,
        } as DataGridState;
    }

    private createDefaultColumnState(columns: ColumnConfig<any>[]) {
        const result: GridColumnState[] = [];
        for (const col of columns) {
            if (!col.defaultHidden) {
                result.push({ id: col.id, width: col.defaultWidth, fixed: col.defaultFixed });
            }
        }
        return result;
    }
    private getDataSource(config: DataSourceConfig<any>) {
        if (Array.isArray(config)) {
            return this.createArrayDataSource(config);
        } else if (typeof config === 'function') {
            return new QueryApiDataSource(config);
        } else if ('getPage' in config) {
            return config;
        } else {
            throw new Error('Datasource is invalid. ', config);
        }
    }

    public createArrayDataSource(items: any[]) {
        return new GridArrayDataSource(items, this.createValueProviders());
    }

    private createValueProviders() {
        return this.columns.reduce((result, item) => {
            for (const valueProvider of item.getValueProviders()) {
                result.push(valueProvider);
            }
            return result;
        }, [] as IValueProvider[]);
    }

    private applyState() {
        this.updateVisibleColumns();
        this.updateColumnSets();
    }

    public swapColumn(currentColumn: DataGridColumn, config: ColumnConfig<any>) {
        const columnState = this.gridState.columns.slice();
        const current = columnState.find((c) => c.id === currentColumn.config.id);
        const next = columnState.find((c) => c.id === config.id);
        if (current && !next) {
            current.id = config.id;
            this.applyGridColumnState(columnState);
        }
    }

    private updateVisibleColumns() {
        this.visibleColumns = this.gridState.columns.reduce((result, item) => {
            const column = this.columnLookup.get(item.id);
            if (!column) {
                this.logger.warn(`Found invalid column state for grid. `, item);
            } else {
                column.width = item.width;
                result.push(column);
            }
            return result;
        }, [] as DataGridColumn[]);
    }

    private updateColumnSets() {
        const fixedLookup = new Set(this.gridState.columns.filter((c) => c.fixed).map((c) => c.id));
        const fixedColumns = this.visibleColumns.filter((c) => fixedLookup.has(c.config.id));
        const columns = this.visibleColumns.filter((c) => !fixedLookup.has(c.config.id));
        this.columnOffsets = new Map<DataGridColumn, ColumnOffsetInfo>();
        const sets: GridColumnSet[] = [];
        const addColumns = (columns: DataGridColumn[], set: GridColumnSet) => {
            sets.push(set);
            set.reordered.listen(this.updateColGridState);
            set.resizing.listen(this.updateColGridState);
            set.extraWidth = set.isFirst && this.showCheckbox ? this.checkboxColWidth : 0;
            for (const column of columns) {
                const info = new ColumnOffsetInfo(column, set);
                this.columnOffsets.set(column, info);
                set.columnInfo.push(info);
            }
        };

        if (!fixedColumns.length) {
            addColumns(columns, new GridColumnSet());
        } else if (fixedColumns.length && !columns.length) {
            addColumns(fixedColumns, new GridColumnSet());
        } else {
            addColumns(fixedColumns, new GridColumnSet(true));
            addColumns(columns, new GridColumnSet(false, 1));
        }

        this.columnSets = sets;
        this.updateSetWidths();
    }

    private updateColGridState = () => {
        const cols: GridColumnState[] = [];
        for (const set of this.columnSets) {
            for (const col of set.columnInfo) {
                cols.push({ id: col.column.config.id, fixed: set.fixed, width: col.column.width });
            }
        }
        this.gridState.columns.splice(0, Infinity, ...cols);
        this.raiseStateChanged(true, 'columns');
    };

    private detectStateChanges(prevState: Partial<DataGridState>, nextState: Partial<DataGridState>) {
        const { groupBy: prevGroupBy, columns: prevColumns, filters: prevFilters, sort: prevSort } = prevState;
        const { groupBy: nextGroupBy, columns: nextColumns, filters: nextFilters, sort: nextSort } = nextState;
        const comparisons = [
            [prevGroupBy, nextGroupBy, 'groupBy'],
            [prevColumns, nextColumns, 'columns'],
            [prevFilters, nextFilters, 'filters'],
            [prevSort, nextSort, 'sort'],
        ] as [unknown, unknown, keyof DataGridState][];
        return comparisons.filter(([prev, next]) => JSON.stringify(prev) !== JSON.stringify(next)).map(([, , key]) => key);
    }

    private detectAndRaiseStateChanges(prevState: DataGridState) {
        const changes = this.detectStateChanges(prevState, this.gridState);
        this.raiseStateChanged(true, ...changes);
    }

    private raiseStateChanged(saveState: boolean, ...changes: (keyof DataGridState)[]) {
        this.gridStateChanged.emit({
            changes: new Set(changes),
            state: this.gridState,
        });

        if (saveState) {
            this.saveState();
        }
    }

    public getColumnSelectorOptions(filterableOnly?: boolean) {
        const selections: IColumnSelectorOption[] = [];
        const options: ColumnSelectorOption[] = [];
        const groups = new Map<string, IColumnSelectorGroup>();
        const result = {
            selections,
            options,
        };

        for (const column of this.columns) {
            const groupName = column.config.groupName ?? this.currentOptions?.defaultGroupName;
            let optionOwner = groupName ? groups.get(groupName)?.options : options;
            if (!optionOwner) {
                const group = { name: groupName!, options: (optionOwner = []) };
                options.push(group);
                groups.set(groupName!, group);
            }
            if (!filterableOnly || column.config.filter) {
                optionOwner.push({ column: column.config });
            }
        }
        result.options = options.filter((o) => !('options' in o) || o.options.length > 0);
        for (const column of this.gridState.columns) {
            const selection = this.columnLookup.get(column.id);
            if (selection) {
                selections.push({ column: selection.config, locked: column.fixed });
            }
        }

        this.modifyColumnSelectorOptions?.(result);

        return result;
    }

    public getDefaultGroup() {
        return this.currentOptions?.defaultGroupName;
    }

    public addColumnById(id: string, index?: number) {
        const column = this.columnLookup.get(id);
        if (column && this.gridState.columns.filter((c) => c.id === id).length === 0) {
            const columns = this.gridState.columns.slice();
            const newColumnState = { id: id, width: column.width > 100 ? column.width : 100 };
            if (index !== undefined) {
                columns.splice(index, 0, newColumnState);
            } else {
                columns.push(newColumnState);
            }
            this.applyGridColumnState(columns);
        }
    }

    public addColumnsByIds(ids: string[]) {
        const existingColumns = new Set(this.gridState.columns.map((c) => c.id));
        const newColumns: GridColumnState[] = [];
        for (const id of ids) {
            const column = this.columnLookup.get(id);
            if (!existingColumns.has(id) && column) {
                newColumns.push({
                    id,
                    width: column.width > 0 ? column.width : column.config.defaultWidth > 0 ? column.config.defaultWidth : 100,
                    fixed: false,
                });
            }
        }
        if (newColumns.length) {
            let columns = [...this.gridState.columns, ...newColumns];
            this.applyGridColumnState(columns);
        }
    }

    public removeColumnsById(ids: string[]) {
        if (ids && ids.length > 0) {
            const columns = this.gridState.columns.filter((c) => !ids.includes(c.id));
            if (columns && columns.length > 0) {
                this.applyGridColumnState(columns);
            }
        }
    }

    public removeColumnById(id: string) {
        const columns = this.gridState.columns.filter((c) => c.id !== id);
        if (columns && columns.length > 0) {
            this.applyGridColumnState(columns);
        }
    }

    public applyColumnSelection(selections: DataColumnConfig<any>[], fixed?: Set<string>, applySave: boolean = true) {
        const currentSelections = new Map(this.gridState.columns.map((c) => [c.id, c]));
        const columns: GridColumnState[] = [];
        for (const item of selections) {
            const selection = currentSelections.get(item.id) ?? { id: item.id, width: item.defaultWidth };
            if (fixed) {
                selection.fixed = fixed?.has(item.id);
            }
            columns.push(selection);
        }
        this.applyGridColumnState(columns, applySave);
    }

    private applyGridColumnState(columns: GridColumnState[], applySave?: boolean) {
        this.beforeApplyColumns?.(columns);
        this.gridState.columns = columns;
        this.applyState();
        this.viewInvalidated.emit();
        if (applySave) {
            this.saveState();
        }
    }
}

class TreeSingleSelectionStrategy implements ISelectionStrategy<any> {
    public constructor(private readonly getTree: () => VirtualTreeModel<any> | undefined) {}
    public isSelected(item: any) {
        return this.getTree()?.getHighlightedItem() === item;
    }
    public getSelectAllValidity() {
        return undefined;
    }
    public setSelected(item: any, selected: boolean) {
        if (!selected) {
            if (this.getTree()?.getHighlightedItem() === item) {
                this.getTree()?.highlight(undefined);
            }
        } else {
            this.getTree()?.highlight(item);
        }
    }
    public async setSelectAll(selected: boolean) {
        if (!selected) {
            this.getTree()?.highlight(undefined);
        }
    }
    public async getSelected() {
        return [this.getTree()?.getHighlightedItem()];
    }
    public count() {
        return this.getTree()?.getHighlightedItem() === undefined ? 0 : 1;
    }
}

class BasicSelectionStrategy implements ISelectionStrategy<any> {
    private selections = new WeakSet<any>();

    public constructor(private getAll: () => any[]) {}
    public isSelected(item: any) {
        return this.selections.has(item);
    }
    public getSelectAllValidity() {
        return '';
    }
    public setSelected(item: any, selected: boolean) {
        if (selected) {
            this.selections.add(item);
        } else {
            this.selections.delete(item);
        }
    }
    public async setSelectAll(selected: boolean) {
        if (selected) {
            for (const item of this.getAll()) {
                this.selections.add(item);
            }
        } else {
            this.selections = new WeakSet<any>();
        }
    }
    public async getSelected() {
        return this.getSelectedItems();
    }
    public count() {
        return this.getSelectedItems().length;
    }

    private getSelectedItems() {
        const result: any[] = [];
        for (const item of this.getAll()) {
            if (this.selections.has(item)) {
                result.push(item);
            }
        }
        return result;
    }
}
export class DataGridColumn {
    public width: number = 0;
    public widthChanged = EventEmitter.empty();
    public maxWidth: number;
    public minWidth: number;
    public sortField?: string;
    public filterField?: string;
    public reordering = false;
    public resizing = false;
    public filterRequest = new EventEmitter<{ handled?: boolean }>({});
    public align: 'left' | 'right' | 'center';
    public type: string;
    public filterType?: string;
    public groupConfig?: ColumnGroupConfig;
    public backgroundColor?: CustomColors;
    public header: string;
    public aggregator?: string;

    private accessor: (item: Record<string, any>) => any;
    private filterFieldAccessor: (item: Record<string, any>) => any;

    public constructor(public readonly grid: DataGridModel, public readonly config: DataColumnConfig<any>) {
        const accessor = config.accessor;
        this.accessor = typeof accessor === 'function' ? accessor : (item) => item[accessor as string | number];
        this.minWidth = config.minWidth ?? grid.itemHeight;
        this.maxWidth = config.maxWidth ?? Infinity;
        this.sortField = config.sortField ?? (typeof accessor === 'string' ? accessor : undefined);
        this.filterField = typeof config.filter === 'object' ? config.filter.filterField : typeof accessor === 'string' ? accessor : undefined;
        this.align = config.align ?? 'left';
        this.type = config.type ?? 'unknown';
        this.filterType = typeof config.filter === 'object' ? config.filter.filterType : this.type;
        this.groupConfig = grid.getGroupConfig(config.groupName);
        this.header = config.header ?? (typeof config.accessor === 'string' ? config.accessor : config.id);
        this.backgroundColor = config.backgroundColor;
        this.filterFieldAccessor =
            typeof accessor === 'function' || this.filterField === accessor ? this.accessor : (item) => item[this.filterField as string | number];
        this.aggregator = config.aggregator;
    }

    public startResize = (e: { clientX: number }) => {
        const startX = e.clientX;
        const startW = this.width;
        this.resizing = true;
        this.getOffest().startResizing();
        const onMove = (e: MouseEvent) => {
            const delta = e.clientX - startX;
            const rawNextW = startW + delta;
            const nextW = Math.min(this.maxWidth, Math.max(this.minWidth, rawNextW));
            if (nextW !== this.width) {
                this.setWidth(nextW);
            }
        };
        const onMouseup = () => {
            this.resizing = false;
            this.getOffest().stopResizing();
            window.removeEventListener('mousemove', onMove);
            window.removeEventListener('mouseup', onMouseup);
        };
        window.addEventListener('mousemove', onMove);
        window.addEventListener('mouseup', onMouseup);
    };

    public startReordering = (e: { clientX: number }) => {
        if (this.resizing || this.config.noReorder) {
            return;
        }
        const startX = e.clientX;
        const offset = this.getOffest();
        const startScroll = this.grid.scrollPos.left;
        const startOffset = this.getOffest().offset;
        offset.startReordering();

        const onMove = (e: MouseEvent) => {
            const delta = e.clientX - startX;
            const scrollDelta = this.grid.scrollPos.left - startScroll;
            const nextOffset = startOffset + delta + scrollDelta;
            this.reordering = true;
            this.grid.ensureViewable.emit({ left: nextOffset, right: nextOffset + this.width + offset.set.offset });
            offset.setOffset(nextOffset);
        };
        const onMouseup = (e: MouseEvent) => {
            setTimeout(() => {
                this.reordering = false;
                offset.stopReordering();
            }, 0);
            window.removeEventListener('mousemove', onMove);
            window.removeEventListener('mouseup', onMouseup);
            this.grid.ensureViewable.emit(undefined);
        };
        window.addEventListener('mousemove', onMove);
        window.addEventListener('mouseup', onMouseup);
    };

    public getAggregatedField() {
        if (this.aggregator) {
            const accessor = typeof this.config.accessor === 'string' ? this.config.accessor : this.config.sortField ?? this.config.id;
            return accessor.substring(this.aggregator.length + 1, accessor.length - 1);
        }
    }

    public getValueProviders() {
        const result: IValueProvider[] = [];
        if (this.sortField) {
            result.push({ field: this.sortField, type: this.type, getValue: this.accessor });
        }
        if (this.filterField && this.filterField !== this.sortField) {
            result.push({ field: this.filterField, type: this.type, getValue: this.filterFieldAccessor });
        }
        return result;
    }

    public editFilter = async () => {
        await this.grid.currentOptions?.onFilterAdding?.(this);
        const state = { handled: false };
        this.filterRequest.emit(state);
        if (!state.handled) {
            this.grid.addFilter(this);
        }
    };

    public canGroup() {
        return !!this.config.allowGrouping;
    }

    public isFilteredBy() {
        return this.filterField && this.grid.filteredByFieldLookup.has(this.filterField);
    }

    public isGroupedBy() {
        return this.grid.gridState.groupBy && this.grid.gridState.groupBy.findIndex((g) => g.id === this.config.id) >= 0;
    }

    public toggleGroupedBy = async () => {
        await this.grid.currentOptions?.onBeforeGroupBy?.(this);
        this.grid.toggleGroupBy(this);
    };

    public showExpander(item: unknown) {
        return this.getOffest().isFirst && this.grid.expandable && this.grid.hasChildren(item);
    }

    public canSort() {
        return this.config.noSort !== true && !!this.sortField;
    }

    public canFilter() {
        return !!this.config.filter && !this.grid.currentOptions?.hideFilterOnly;
    }

    public canRemove() {
        return this.config.noRemove == undefined || this.config.noRemove === false;
    }

    public getSortDir() {
        const sort = this.grid.gridState.sort?.find((s) => (s.Expr as QueryField)?.Field === this.sortField);
        return !sort ? undefined : sort.Direction ?? 'Asc';
    }

    public async applySort(asc: boolean) {
        await this.grid.currentOptions?.onBeforeSort?.(this);
        if (this.canSort()) {
            this.grid.sort({ Direction: asc ? 'Asc' : 'Desc', Expr: { Field: this.sortField, Type: this.type } as QueryField });
        }
    }

    public async removeSort() {
        if (this.canSort()) {
            this.grid.removeSort({ Field: this.sortField, Type: this.type } as QueryField);
        }
    }

    public getOffest() {
        return this.grid.getOffset(this);
    }

    public getWidthPercent() {
        const total = this.grid.visibleColumns.reduce((total, c) => total + c.width, 0);
        return this.width / total;
    }

    public getAggregateOptions() {
        const aggs = this.grid.getAutoColumnOptions(this);
        return !aggs?.options || !aggs.source
            ? undefined
            : [aggs.source, ...aggs.options].map((c) => ({
                  config: c,
                  label: c.aggregator ?? 'None',
                  selected: c.id === this.config.id,
                  enabled: !this.grid.gridState.columns.some((cs) => cs.id === c.id),
              }));
    }

    public setAggregate = (config: ColumnConfig<any>) => {
        this.grid.swapColumn(this, config);
    };

    public setWidth(width: number) {
        this.width = width;
        this.widthChanged.emit();
        this.grid.updateSetWidths();
    }

    public renderCell = (item: any) => {
        if (this.config.cellRenderer) {
            return this.config.cellRenderer(item);
        } else {
            return <>{this.getCellDisplayValue(item)}</>;
        }
    };

    public renderForExport = (item: any) => {
        const exportRenderer = this.config.exportOptions?.renderer ?? this.accessor;
        return exportRenderer(item);
    };

    public renderExportHeader = () => {
        if (this.config.exportOptions?.header) {
            return this.config.exportOptions.header;
        } else {
            const groupPrefix = !this.config?.groupName ? '' : this.config.groupName + ': ';
            return groupPrefix + this.config.header;
        }
    };

    private getCellDisplayValue = (item: Record<string, any>) => this.formatCell(item, this.getCellValue(item));

    private getCellValue(item: Record<string, any>) {
        return this.accessor(item);
    }

    public renderFilterCell() {
        if (this.config.headerRenderer) {
            return this.config.headerRenderer(this.config);
        } else {
            return <>{this.config.header}</>;
        }
    }

    private formatCell(item: any, value: any) {
        return this.config.formatter ? this.config.formatter(item, value) : value;
    }
}

export class GridColumnSet {
    public reordering = EventEmitter.empty();
    public reordered = EventEmitter.empty();
    public resizing = new EventEmitter<boolean>(false);
    public extraWidth = 0;
    private renderSets: (ColumnOffsetInfo | { blank: number })[] = [];
    private renderSetKey = '';

    private offsetBreaks: { offset: ColumnOffsetInfo; break: number }[] = [];

    public constructor(
        public fixed: boolean = false,
        public index: number = 0,
        public columnInfo: ColumnOffsetInfo[] = [],
        public groups: ColumnGroupInfo[] = [],
        public offset: number = 0,
        public width: number = 0
    ) {}

    public get isFirst() {
        return this.index === 0;
    }

    public getColumnRenderSets(left: number, right: number) {
        const key = `${left}-${right}-${this.width}-${this.offset}`;
        if (key !== this.renderSetKey) {
            this.renderSetKey = key;
            this.renderSets = this.calculateRenderSets(left, right);
        }
        return this.renderSets;
    }

    private calculateRenderSets(left: number, right: number): (ColumnOffsetInfo | { blank: number })[] {
        const result = [] as (ColumnOffsetInfo | { blank: number })[];
        let lastItem: ColumnOffsetInfo | { blank: number } | null = null;
        for (const item of this.columnInfo) {
            if (item.isColumnVisible(left, right)) {
                result.push((lastItem = item));
            } else {
                if (lastItem && 'blank' in lastItem) {
                    lastItem.blank += item.column.width;
                } else {
                    result.push((lastItem = { blank: item.column.width }));
                }
            }
        }
        return result;
    }

    public calculate() {
        let totalWidth = this.extraWidth;
        this.groups = [];

        let i = 0;
        let group = new ColumnGroupInfo();
        this.groups.push(group);
        for (const col of this.columnInfo) {
            col.index = i++;
            col.offset = totalWidth;
            col.set = this;
            if (col.column.groupConfig === group.group) {
                group.width += col.column.width;
            } else {
                group = new ColumnGroupInfo(col.column.config.groupName, col.column.groupConfig, col.column.width, totalWidth);
                this.groups.push(group);
            }
            totalWidth += col.column.width;
        }
        this.width = totalWidth;
        this.invalidateRenderSets();
    }
    public startReorder(lockedOffset: ColumnOffsetInfo) {
        this.calculateBreaks(lockedOffset);
    }

    private calculateBreaks(lockedOffset: ColumnOffsetInfo) {
        this.offsetBreaks = [];
        for (const col of this.columnInfo) {
            if (col !== lockedOffset) {
                this.offsetBreaks.push({ offset: col, break: col.offset + col.column.width / 2 });
            }
        }
    }

    public indexesFromOffsets(lockedOffset: ColumnOffsetInfo) {
        let nextIndexByBreak = 0;
        const lockedBreak = lockedOffset.offset + lockedOffset.column.width / 2;
        for (const breakPoint of this.offsetBreaks) {
            if (lockedBreak < breakPoint.break) {
                break;
            }
            nextIndexByBreak++;
        }
        if (nextIndexByBreak !== lockedOffset.index) {
            this.columnInfo.splice(lockedOffset.index, 1);
            this.columnInfo.splice(nextIndexByBreak, 0, lockedOffset);
            const offset = lockedOffset.offset;
            this.calculate();
            lockedOffset.offset = offset;
            this.calculateBreaks(lockedOffset);
        }
        this.reordering.emit();
        this.renderSetKey = '';
    }

    public invalidateRenderSets() {
        this.renderSetKey = '';
    }
}
export class ColumnGroupInfo {
    public constructor(public name?: string, public group?: ColumnGroupConfig, public width: number = 0, public offset: number = 0) {}
}
export class ColumnOffsetInfo {
    public get isFirst() {
        return this.index === 0 && this.set.isFirst;
    }
    public constructor(public column: DataGridColumn, public set: GridColumnSet, public index: number = 0, public offset: number = 0) {}
    public startReordering() {
        this.set.startReorder(this);
    }
    public stopReordering() {
        this.set.calculate();
        this.set.reordered.emit();
    }
    public setOffset(nextOffset: number) {
        this.offset = nextOffset;
        this.set.indexesFromOffsets(this);
    }
    public startResizing() {
        this.set.resizing.emit(true);
    }
    public stopResizing() {
        this.set.resizing.emit(false);
        this.set.invalidateRenderSets();
    }

    public isColumnVisible(scrollLeft: number, viewableRight: number) {
        return this.set.fixed || (this.offset + this.column.width > scrollLeft && this.offset < scrollLeft + viewableRight);
    }
}
