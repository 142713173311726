import { inject, injectable } from 'tsyringe';
import { IQueryDatasourceFactory, QueryDatasource, QueryDatasourceMetadata } from '../Query/QueryDatasource';
import { ResourceSchemaProvider } from './ResourceService';
import { ObjectQueryResult, Query } from '@apis/Invoices/model';
import { PlatformService } from '../PlatformService';

@injectable()
export class ResourceQueryDatasourceFactory implements IQueryDatasourceFactory {
    public constructor(
        @inject(ResourceSchemaProvider) private readonly resourceSchemaProvider: ResourceSchemaProvider,
        @inject(PlatformService) private readonly platformSvc: PlatformService
    ) {}

    public getMetadata() {
        return {
            name: 'resources',
            noun: 'resource',
            nounPlural: 'resources',
        };
    }

    public async getDatasource(api: (query: Query) => Promise<ObjectQueryResult>) {
        await this.platformSvc.initIfNeeded();

        return {
            ...this.getMetadata(),
            source: api,
            schema: this.resourceSchemaProvider,
            getValueProviderFactory: this.resourceSchemaProvider.createValueProviderFactory(),
            getDefaultGroup: () =>
                this.platformSvc.hasPlatform('Azure')
                    ? { Expr: { Field: 'ResourceType' }, Alias: 'Resource Type' }
                    : { Expr: { Field: 'Region' }, Alias: 'Region' },
            getDefaultValue: () => ({ Expr: { Operation: 'count' }, Alias: 'Count' }),
            getDefaultHistogram: () =>
                this.platformSvc.hasPlatform('Azure')
                    ? {
                          Expr: { Field: 'Microsoft_Storage/storageAccounts.creationTime' },
                          Alias: 'Storage Create Time',
                      }
                    : { Expr: { Field: 'CreateTime' }, Alias: 'Create Time' },
        } as QueryDatasource;
    }
}
