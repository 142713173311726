import { Box, Button, Divider, Group, ScrollArea, Stack, Text } from '@mantine/core';
import { useDi } from '@root/Services/DI';
import { FormatService } from '@root/Services/FormatService';
import { addDays, addMonths, eachMonthOfInterval, endOfMonth, endOfQuarter, format, startOfMonth, startOfQuarter } from 'date-fns';
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { DateRangePicker } from '../Picker/DateRangePicker';

export function InvoiceDateRange({
    constraint,
    onChange,
    value,
}: {
    constraint: { min?: Date; max?: Date };
    onChange: (value: { from?: Date; to?: Date }) => void;
    value: { from?: Date; to?: Date };
}) {
    const [dateRange, setDateRange] = useState<{ from?: Date; to?: Date }>();
    const handleDateChanged = useCallback((range: { from?: Date; to?: Date }) => {
        setDateRange(range);
    }, []);
    useEffect(() => {
        if (dateRange && dateRange.from && dateRange.to) {
            onChange(dateRange);
        }
    }, [dateRange?.from, dateRange?.to, onChange]);
    const pickerConstraint = useMemo(() => ({ ...constraint, unenforced: true }), [constraint?.max, constraint?.min]);

    return (
        <DateRangePicker
            corners={4}
            height={36}
            constraint={pickerConstraint}
            value={value}
            onChange={handleDateChanged}
            mode="calendar"
            onRenderCalendar={(calendar, close) => (
                <>
                    <Group sx={{ height: 325 }} noWrap align="baseline">
                        <DateRangePopoverPresets availableRange={constraint} close={close} onSelect={handleDateChanged} value={value} />
                        <Divider orientation="vertical" />
                        {calendar}
                    </Group>
                </>
            )}
        />
    );
}

function DateRangePopoverPresets(props: {
    value?: { from?: Date; to?: Date };
    onSelect: (value: { from: Date; to: Date }) => void;
    close: () => void;
    availableRange: { min?: Date; max?: Date };
}) {
    const { value, onSelect, close, availableRange } = props;
    const fmtSvc = useDi(FormatService);
    const getDateKey = (date: Date) => fmtSvc.to8DigitDate(date);
    const getRangeKey = (range: { from: Date; to: Date }) => `${getDateKey(range.from)}-${getDateKey(range.to)}`;
    type IOptionItem = { label: string; from: Date; to: Date; key?: string } | { type: 'header'; title: string };
    const options = useMemo(() => {
        const { min, max } = availableRange;
        const months = eachMonthOfInterval({ start: startOfMonth(min ?? new Date()), end: startOfMonth(max ?? new Date()) });
        const validMonths = new Set(months.map((m) => fmtSvc.to8DigitDate(m)));
        const options: IOptionItem[] = [{ label: 'Current Month', from: startOfMonth(new Date()), to: endOfMonth(new Date()) }];
        const lastMo = startOfMonth(addMonths(new Date(), -1));
        if (validMonths.has(getDateKey(lastMo))) {
            options.push({ label: 'Last 30 Days', from: addDays(new Date(), -30), to: new Date() });
            options.push({ label: 'Last Month', from: lastMo, to: endOfMonth(lastMo) });
        }
        const quarterStart = startOfQuarter(new Date());
        if (validMonths.has(getDateKey(quarterStart))) {
            options.push({ label: 'Current Quarter', from: quarterStart, to: endOfQuarter(quarterStart) });
        }
        const prevQuarterStart = startOfQuarter(addMonths(new Date(), -3));
        if (validMonths.has(getDateKey(prevQuarterStart))) {
            options.push({ label: 'Last Quarter', from: prevQuarterStart, to: endOfQuarter(prevQuarterStart) });
        }
        if (options.length > 2) {
            options.push({ type: 'header', title: 'Billing Periods' });
            for (const month of months.reverse()) {
                options.push({ label: format(month, 'MMMM yyyy'), from: month, to: endOfMonth(month) });
            }
        }
        return options.map((item) => ('type' in item ? item : { ...item, key: getRangeKey(item) }));
    }, [availableRange?.max, availableRange?.min]);
    const selectedRangeKey = !value?.from || !value?.to ? '' : getRangeKey({ from: value.from!, to: value.to! });

    const handleClick = ({ from, to }: { from: Date; to: Date }) => {
        onSelect({ from, to });
        close();
    };

    return (
        <Box sx={{ width: 200, height: '100%', marginRight: -15 }}>
            <ScrollArea sx={{ height: '100%' }}>
                <Stack spacing="xs" sx={{ marginRight: 15 }}>
                    {options.map((item, idx) =>
                        'type' in item ? (
                            <Fragment key={idx}>
                                <Divider />
                                <Text size="xs" color="dimmed" weight="bold">
                                    {item.title}
                                </Text>
                            </Fragment>
                        ) : (
                            <Button
                                size="sm"
                                variant={item.key === selectedRangeKey ? 'outline' : 'white'}
                                key={item.key}
                                onClick={() => handleClick(item)}
                            >
                                {item.label}
                            </Button>
                        )
                    )}
                </Stack>
            </ScrollArea>
        </Box>
    );
}
