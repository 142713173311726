import styled from '@emotion/styled';
import { Box, Card, Divider, Sx, Text, useMantineTheme } from '@mantine/core';
import { ResponsiveLine } from '@nivo/line';
import { ScaleSpec } from '@nivo/scales';
import { useDi } from '@root/Services/DI';
import { FormatService, NamedFormats } from '@root/Services/FormatService';
import { useIdGen } from '@root/Services/IdGen';
import { format } from 'date-fns';
import { Fragment, useCallback, useMemo } from 'react';
import { FlyoverHost, useTooltip } from '../Picker/Flyover';
import { EmptyDataText } from '../Text/VisibleSpaces';
import {
    adjustHistogramMargin,
    chartColors,
    ChartMargin,
    ChartTooltipInfo,
    crispBorder,
    getCombinedMetricRange,
    getMetricAxisRange,
    getValueFormatter,
    histogramVerticalStripes,
    IChartReaggConfig,
    IPlotData,
    IPlotFieldDescriptor,
    IPlotStats,
    minimizeTooltipData,
    missingRangesLayer,
    transformHistogramData,
    typedFormatter,
    useChartTheme,
    useSliceTooltip,
    xMonthsAxis,
    xWeekdaysAxis,
    yAxisLabelLayer,
} from './Common';
import { StandardChartProps } from './Models';

export interface DetailedLineChartSettings {
    margin?: Partial<ChartMargin>;
    mulitplotOptions?: IChartReaggConfig;
    descriptors?: IPlotFieldDescriptor[];
    stacked?: boolean;
    interval?: 'hour' | 'day' | 'week' | 'month';
    curve?: 'monotoneX' | 'step' | 'linear';
    labels?: { x: string; y: string; group?: string };
    /** Y-axis format */
    format?: NamedFormats;
    /** X-axis, typically a date format */
    xFormat?: NamedFormats;
    chartColors?: string[];
    area?: 'area' | 'line' | 'both';
    disableLine?: boolean;
    yMax?: number;
    dataAvailAccessor?: string;
}
export interface DetailedLineChartProps<T extends Record<string, string | number | Date>> extends StandardChartProps<string, T> {
    settings?: DetailedLineChartSettings;
}
export function DetailedLineChart<T extends Record<string, string | number | Date>>(props: DetailedLineChartProps<T>) {
    const { colors } = useMantineTheme();
    const { data, dateField, groupField, settings, valueFields } = getDefaultProps(props);
    const { curve, xFormat, chartColors, interval, format, stacked, area, mulitplotOptions, descriptors, dataAvailAccessor } = settings;
    const labels = getLabelDefaults(settings?.labels, descriptors, dateField, valueFields[0], groupField);
    const yFormatter = getValueFormatter(format ?? 'number');
    const fmtSvc = useDi(FormatService);
    const { getId } = useIdGen();
    const tooltipId = useMemo(() => `tooltip-${getId({})}`, []);
    const { plots: lineData, stats } = transformHistogramData(
        data,
        dateField,
        groupField,
        valueFields,
        interval,
        descriptors,
        mulitplotOptions,
        chartColors
    );
    const { plots: monthData } = transformHistogramData(
        data,
        dateField,
        groupField,
        valueFields,
        'month',
        descriptors,
        mulitplotOptions,
        chartColors,
        dataAvailAccessor
    );

    const dateConverter = (value: string) => new Date(value);
    const dateFormatter = fmtSvc.getFormatter(xFormat)?.format ?? ((value: Date) => fmtSvc.toShortDate(value));
    const xFormatter = (value: string) => dateFormatter(dateConverter(value));

    const theme = useChartTheme();
    const finalMargin = adjustHistogramMargin({}, stats, yFormatter, labels?.y, theme);

    //#region Layers
    const borderLayer = crispBorder();

    const monthStripesLayer = histogramVerticalStripes(stats, colors.gray[1], finalMargin);

    const yAxisLblLayer = yAxisLabelLayer(labels.y);

    const onHover = useDetailedLineChartGroupedTooltip(labels.y, tooltipId);
    const monthAxisLayer = xMonthsAxis({ ...stats, monthData, formatter: yFormatter, onHover, height: 45 });

    const dayAxisLayer = xWeekdaysAxis({ ...stats, offset: 45, height: 15, lineColor: colors.gray[4] });

    const missingRanges = missingRangesLayer({ plotData: lineData });

    const layers = [
        borderLayer,
        monthStripesLayer,
        'axes',
        'areas',
        yAxisLblLayer,
        monthAxisLayer,
        dayAxisLayer,
        'lines',
        missingRanges,
        'slices',
        'mesh',
        'crosshair',
    ] as ResponsiveLine['props']['layers'];
    //#endregion

    const { onContainerMouseMove, onMouseLeave, sliceTooltip } = useDetailedLineChartTooltip(labels.y, monthData, tooltipId);

    const yScale = getYScale(lineData, stats, stacked);

    return (
        <>
            <ChartBox onMouseLeave={onMouseLeave} onMouseMove={onContainerMouseMove}>
                <ResponsiveLine
                    theme={theme}
                    xFormat={typedFormatter(xFormatter)}
                    yFormat={typedFormatter(yFormatter)}
                    margin={finalMargin}
                    data={lineData}
                    layers={layers}
                    enableArea={area !== 'line'}
                    areaBlendMode="normal"
                    areaOpacity={area === 'area' ? 0.5 : 0.2}
                    curve={curve}
                    enableSlices="x"
                    enableCrosshair
                    yScale={yScale}
                    sliceTooltip={sliceTooltip}
                    enablePoints={false}
                    axisBottom={null}
                    axisLeft={{ format: typedFormatter(yFormatter) }}
                    lineWidth={area === 'area' ? 0 : area === 'both' ? 1 : 2}
                    colors={(d) => d.color}
                />
            </ChartBox>
            <FlyoverHost flyoverKey={tooltipId} />
        </>
    );
}

const ChartBox = styled.div`
    height: 100%;
    width: 100%;
    overflow: hidden;
`;

function getDefaultProps(props: DetailedLineChartProps<any>) {
    const { data, groups, values, settings: rawSettings } = props;
    const settings = {
        ...getDefaultSettings(rawSettings),
        ...rawSettings,
    };
    const valueFields = values as string[];
    const dateField = (groups[0] ?? '').toString();
    const groupField = (groups[1] ?? '').toString();
    return { data, valueFields, groupField, dateField, settings };
}

function getDefaultSettings(settings?: DetailedLineChartSettings) {
    return {
        xFormat: 'short-date' as NamedFormats,
        chartColors,
        interval: 'day' as const,
        dataAvailAccessor: 'ct',
        stacked: false,
        labels: { x: '', y: '', ...settings?.labels },
        multiplotOptions: { ...settings?.mulitplotOptions },
        area: 'line' as const,
        descriptors: [],
        ...settings,
    };
}

function getLabelDefaults(labels: DetailedLineChartSettings['labels'], descriptors: IPlotFieldDescriptor[], x: string, y: string, group?: string) {
    const firstLbl = (f?: string) => descriptors.find((d) => d.field === f)?.details?.[0].label ?? '';
    return {
        x: labels?.x || firstLbl(x) || '',
        y: labels?.y || firstLbl(y) || '',
        group: labels?.group || firstLbl(group) || '',
    };
}

function getYScale(plots: IPlotData[], stats: IPlotStats, stacked: boolean) {
    let { min: dataMin, max: dataMax } = stacked ? getCombinedMetricRange(plots) : { min: stats.minY, max: stats.maxY };
    const { min, max } = getMetricAxisRange(dataMin, dataMax);
    const yScale: ScaleSpec = { type: 'linear', min, max, nice: true, clamp: true, stacked: !!stacked };

    return yScale;
}

function useDetailedLineChartGroupedTooltip(metricLabel: string, tooltipId: string = '') {
    const [show] = useTooltip({ anchor: ['top-center', 'bottom-center'], nonInteractive: true, offsetY: 12 }, tooltipId);
    return useCallback(
        (info: ChartTooltipInfo[], month: Date, target: Element) => {
            const pos = target.getBoundingClientRect();
            const x = pos.left + pos.width / 2;
            const y = pos.bottom;
            const monthLbl = format(month, 'MMMM yyyy');

            show({ x, y, renderer: () => <DetailedLineChartTooltip date={monthLbl} items={info} total="" valueLbl={metricLabel} /> });
        },
        [show]
    );
}

function useDetailedLineChartTooltip(metricLabel: string, monthlyData: IPlotData[] = [], tooltipId: string = '') {
    const getKey = (date: string, group: string) => `${date.slice(0, 7)}-${group}`;
    const getPtMonthItem = (group: IPlotData, item: { x: string; y: number }) => [getKey(item.x, group.id), { group, item }] as const;
    const monthItemLookup = useMemo(() => new Map(monthlyData.flatMap((d) => d.data.map((item) => getPtMonthItem(d, item)))), [monthlyData]);
    return useSliceTooltip(
        useCallback(
            (props) => {
                const { slice } = props;
                const pt = slice.points[0].data;
                const { xFormatted: datelbl, x: rawDate } = pt;
                const date = rawDate as string;
                type PtType = (typeof slice.points)[number] & { data: { ct: number } };
                const monthItems = (slice.points as PtType[])
                    .filter((pt) => ('ct' in pt.data ? pt.data.ct !== 0 : true))
                    .map((item) => ({ key: getKey(date, item.serieId as string), value: item.data.yFormatted as string }))
                    .map(({ key, value }) => ({ group: monthItemLookup.get(key)?.group, value }));
                const items = monthItems.map(({ group, value }) => ({ color: group?.color ?? '', details: group?.details ?? [], value }));

                return <DetailedLineChartTooltip date={datelbl as string} total="" items={items} valueLbl={metricLabel} />;
            },
            [monthItemLookup, monthlyData]
        ),
        tooltipId
    );
}

function DetailedLineChartTooltip(props: { date: string; total: string; valueLbl: string; items: ChartTooltipInfo[] }) {
    const { date, items, valueLbl, total } = props;

    const { headers, rows } = minimizeTooltipData(items);
    const tableData = rows;
    if (total) {
        tableData.push({ color: '#fff0', labelValues: ['Total'], value: total });
    }

    return (
        <Card p={0} shadow="md" radius="md" withBorder>
            <Text align="center" py="xs" p="sm">
                {date}
            </Text>
            <Divider />
            <Box p="xs" px="lg">
                {!items.length ? <>No Data</> : <DetailedLineChartTooltipTable headers={headers} valueLbl={valueLbl} rows={tableData} />}
            </Box>
        </Card>
    );
}

function DetailedLineChartTooltipTable(props: {
    headers: string[];
    valueLbl: string;
    rows: { color: string; value: string; labelValues: string[] }[];
}) {
    const { headers, rows, valueLbl } = props;

    const lblSx: Sx = { whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: 200 };
    const lblProps = { color: 'dimmed' as const, size: 12, sx: lblSx };
    const colorSx: Sx = { width: 16, height: 16, borderRadius: 3 };
    const valueProps = { size: 12, align: 'right' as const };

    return (
        <Box sx={{ columnGap: 8, display: 'grid', gridTemplateColumns: '16px min-content max-content' }}>
            <Box sx={colorSx}></Box>
            <Text {...lblProps}>{headers.join(', ')}</Text>
            <Text {...valueProps}>{valueLbl}</Text>
            {rows.map((row, idx) => (
                <Fragment key={idx}>
                    <Box sx={{ ...colorSx, backgroundColor: row.color }}></Box>
                    <Text {...lblProps}>
                        {row.labelValues.map((v, i) => (
                            <Fragment key={i}>
                                {i > 0 ? ', ' : null}
                                {v ? v : <EmptyDataText text={v} />}
                            </Fragment>
                        ))}
                    </Text>
                    <Text {...valueProps}>{row.value}</Text>
                </Fragment>
            ))}
        </Box>
    );
}
