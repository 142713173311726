import { inject, injectable } from 'tsyringe';
import { IQueryDatasourceFactory, QueryDatasource, QueryDatasourceMetadata } from '../Query/QueryDatasource';
import { InvoiceSchemaService } from './InvoiceSchemaService';
import { DateOnlyQueryApiWrapper } from '../Query/DateOnlyQueryApiWrapper';
import { InvoiceFieldCompatibilityService } from './InvoiceFieldCompatibilityService';
import { SchemaValueProvider } from '../QueryExpr';
import { ObjectQueryResult, Query } from '@apis/Invoices/model';

@injectable()
export class InvoiceQueryDatasourceFactory implements IQueryDatasourceFactory {
    public constructor(
        @inject(InvoiceSchemaService) private readonly invoiceSchemaSvc: InvoiceSchemaService,
        @inject(DateOnlyQueryApiWrapper) private readonly apiWrapper: DateOnlyQueryApiWrapper,
        @inject(InvoiceFieldCompatibilityService) private readonly fieldLookupFactory: InvoiceFieldCompatibilityService
    ) {}

    public getMetadata() {
        return {
            name: 'cur',
            noun: 'invoice line item',
            nounPlural: 'invoice line items',
        } as QueryDatasourceMetadata;
    }

    public async getDatasource(api: (query: Query) => Promise<ObjectQueryResult>) {
        const { apiWrapper, fieldLookupFactory } = this;

        const wrappedApi = apiWrapper.wrap(api);
        const schema = await this.invoiceSchemaSvc.getDailySchema();
        const fieldCompat = await fieldLookupFactory.getLookup(schema);
        const svcCategory = fieldCompat.getAvailableField('ServiceCategory');
        const [date, cost, region, acct] = fieldCompat.getAvailableFields('ChargePeriodStart', 'BilledCost', 'RegionId', 'SubAccountName');

        return {
            ...this.getMetadata(),
            source: wrappedApi,
            schema: { getSchema: async () => schema },
            getValueProviderFactory: (schemaSvc) => {
                const schemaValueProvider = new SchemaValueProvider(schemaSvc, wrappedApi);
                return {
                    getValueProvider: schemaValueProvider.getValueProvider,
                };
            },
            getDefaultFilterFields: () => [svcCategory?.path, acct, region],
            getDefaultGroup: () => ({ Expr: { Field: svcCategory?.path }, Alias: svcCategory?.name }),
            getDefaultValue: () => ({ Expr: { Operation: 'sum', Operands: [{ Field: cost }] }, Alias: 'Cost' }),
            getDefaultHistogram: () => ({
                Expr: { Field: date },
                Alias: 'Date',
            }),
        } as QueryDatasource;
    }
}
