import { PageContent, PagePanel, PanelBody, PaneledPage } from '@root/Design/Layout';
import { endpoint } from '@root/Services/Router/EndpointRegistry';
import { MyCompaniesGrid } from '../Msp/MyCompaniesGrid';
import { useCallback, useEffect, useState } from 'react';
import { MyCompaniesInvoicesGrid } from './MyCompaniesInvoicesGrid';
import { EventEmitter, useEventValue } from '@root/Services/EventEmitter';
import { useDi } from '@root/Services/DI';
import { singleton } from 'tsyringe';
import { confirmAction } from '@root/Components/CompanyInfo/Administration/ConfirmActionDialog';
import { useNav } from '@root/Services/NavigationService';
import { Divider, Drawer } from '@mantine/core';
import { BillingInvoicesSidePanel } from './BillingInvoicesSidePanel';
import { useCompany } from '@root/Components/Router/CompanyContent';
import { CompanyContext } from '@root/Components/Router/CompanyContext';

@singleton()
export class BillingInvoicesSidePanelChangeContext {
    public sidePanelHasChange = new EventEmitter(false);
}

export function BillingInvoices() {
    const [showSidePanel, setshowSidePanel] = useState(false);
    const [sidePanelDefaultTab, setSidePanelDefaultTab] = useState<string>();
    const [selectedCompanyId, setSelectedCompanyId] = useState<number>();
    const company = useCompany();
    const nav = useNav();
    const [panelCloseInitiated, setPanelCloseInitiated] = useState<boolean>(false);
    const sidePanelChangeCtx = useDi(BillingInvoicesSidePanelChangeContext);
    const editing = useEventValue(sidePanelChangeCtx.sidePanelHasChange);

    const onUnloadCompany = useCallback(() => {
        setSelectedCompanyId(0);
    }, [setSelectedCompanyId]);

    const handleToggleSidePanel = (showSidePanel: boolean) => {
        setshowSidePanel(showSidePanel);
    };

    const selectCompany = (selectedCompanyId: number): void => {
        if (selectedCompanyId && selectedCompanyId > 0) {
            setSelectedCompanyId(selectedCompanyId);
            handleToggleSidePanel(true);
        }
    };

    useEffect(() => {
        if (showSidePanel && editing) {
            confirmAction({
                headerText: 'Close Panel',
                promptText: `You have unsaved changes. Are you sure you want to close?`,
                confirmButtonText: 'Discard Changes and Close',
                cancelButtonText: 'Save Changes',
                confirmAction: () => {
                    setshowSidePanel(false);
                    setPanelCloseInitiated(false);
                },
            });
        } else {
            setshowSidePanel(false);
            setPanelCloseInitiated(false);
        }
    }, [panelCloseInitiated]);

    useEffect(() => {
        const { select: rawId, tab } = nav.getData('select', 'tab');
        const childIdNum = rawId ? parseInt(rawId) : '';
        if (childIdNum) {
            setSelectedCompanyId(childIdNum);
            setshowSidePanel(true);
            setSidePanelDefaultTab(tab);
        }
    }, []);

    return (
        <PageContent>
            <PaneledPage>
                <PagePanel size="fill">
                    <PanelBody>
                        <MyCompaniesInvoicesGrid selectCompany={selectCompany}></MyCompaniesInvoicesGrid>
                    </PanelBody>
                </PagePanel>
                <Divider orientation="vertical" />
                {showSidePanel && (
                    <Drawer
                        opened={showSidePanel}
                        onClose={() => setPanelCloseInitiated(true)}
                        size={900}
                        padding={0}
                        position="right"
                        overlayOpacity={0.1}
                        withinPortal={false}
                        withCloseButton={false}
                        withOverlay={true}
                        shadow="md"
                        closeOnClickOutside={true}
                        closeOnEscape={true}
                    >
                        <CompanyContext unloadOnUnmount companyId={selectedCompanyId!} type="company" onUnload={onUnloadCompany}>
                            {() => (
                                <PagePanel padded={false} size="md" style={{ minWidth: '350px', width: '100%', height: '100%' }}>
                                    <BillingInvoicesSidePanel
                                        defaultTab={sidePanelDefaultTab}
                                        parentCompany={company!}
                                        toggleSidePanel={handleToggleSidePanel}
                                    />
                                </PagePanel>
                            )}
                        </CompanyContext>
                    </Drawer>
                )}
            </PaneledPage>
        </PageContent>
    );
}
endpoint('billing-invoices', BillingInvoices, 'Billing Invoices');
