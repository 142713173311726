import { getUserGetMyProfile, postNewSubscriptionUpdateCompanyInfo, postUserSaveTermsOfSvcAgreement, postUserUpdateMyProfile } from '@apis/Customers';
import { Company, User } from '@apis/Customers/model';
import styled from '@emotion/styled';
import {
    ActionIcon,
    Group,
    Text,
    Popover,
    Menu,
    Avatar,
    Anchor,
    Tooltip,
    Box,
    Modal,
    Title,
    TextInput,
    ThemeIcon,
    Divider,
    Space,
    useMantineTheme,
    Button,
    ThemeIconProps,
    Loader,
    Checkbox,
    ScrollArea,
    Center,
} from '@mantine/core';
import { AuthenticationService } from '@root/Services/AuthenticationService';
import { DiContext, useDi, useDiMemo } from '@root/Services/DI';
import { useEventValue } from '@root/Services/EventEmitter';
import { NavigationService, useNav } from '@root/Services/NavigationService';
import { BasicRouteLoader, IRouteMeta } from '@root/Services/Router/BasicRouteLoader';
import { useLink } from '@root/Services/Router/Router';
import { ReactNode, useCallback, useEffect, useState } from 'react';
import { ActivityPanelOpener } from '@root/Components/Actions/ActivityPanel/Components';
import { UserImage } from '../Users/UserImage';
import { useDebouncedValue, useDisclosure } from '@mantine/hooks';
import { Check, ChevronLeft, FileLike, Mail, User as UserIcon } from 'tabler-icons-react';
import { CustomColors } from '@root/Design/Themes';
import { CompanyTenantPrereqService, useCompany } from '../Router/CompanyContent';
import { NotificationPreferenceService } from '@root/Services/Notification/NotificationPreferenceService';
import { NotificationTypeConfig } from '@apis/Notification/model';
import { SiteMenuCompanyService } from './SiteMenuCompanyService';
import { SuperUserService } from '@root/Services/Customers/SuperUserService';
import { CompanyContextService } from '@root/Services/Customers/CompanyContext';
import { CompanyUserService } from '@root/Services/Customers/CompanyUserService';
import { NotificationViewerProvider } from '../Notifications/NotificationOpener';

export function BreadcrumbItem({ routeMeta, isLast, splitter }: { routeMeta: IRouteMeta; isLast: boolean; splitter: ReactNode }) {
    const breadCrumb = routeMeta.endpointInfo?.breadCrumb;
    const Item = !breadCrumb
        ? null
        : typeof breadCrumb === 'string'
        ? () => <span data-atid={'SiteMainHeader:' + breadCrumb}>{breadCrumb}</span>
        : breadCrumb;
    const { getRootUrl } = useNav();
    const link = useLink();
    return !Item ? null : isLast ? (
        <Text color="primary">
            <Item />
        </Text>
    ) : (
        <>
            {'noLink' in (Item as any) ? (
                <Item />
            ) : (
                <Anchor {...link(getRootUrl(routeMeta.route))} color="primary">
                    <Item />
                </Anchor>
            )}
            {splitter}
        </>
    );
}

export function Breadcrumb({ headerWidth }: { headerWidth: number }) {
    const routeLoader = useDi(BasicRouteLoader);
    const routeMeta = useEventValue(routeLoader.routeMeta);
    const validRouteMeta = routeMeta?.filter((r) => r.endpointInfo && r.endpointInfo?.breadCrumb !== false);
    const lastRouteMeta = validRouteMeta?.slice(-1)[0];
    const breadCrumbStart = validRouteMeta?.[0]?.endpointInfo?.breadCrumb;
    const canAscend = !breadCrumbStart || typeof breadCrumbStart === 'string' || !('noLink' in breadCrumbStart);
    const ascendUrl = canAscend && (validRouteMeta?.length ?? 0) > 1 && lastRouteMeta?.container.resolve(NavigationService).getAscendUrl();
    const link = useLink();
    const lastIdx = (routeMeta?.length ?? 0) - 1;

    let retVal = (
        <ScrollArea style={{ width: headerWidth + 'px', height: 30, lineHeight: 20 }} type="auto">
            <Group noWrap>
                {routeMeta && ascendUrl ? (
                    <Anchor {...link(ascendUrl)} color="primary">
                        <ActionIcon variant="filled" size="md" radius="md" color="primary">
                            <ChevronLeft size={20} />
                        </ActionIcon>
                    </Anchor>
                ) : null}
                {routeMeta?.map((m, i) => (
                    <DiContext.Provider key={i} value={m.container}>
                        <BreadcrumbItem routeMeta={m} isLast={lastIdx === i} splitter={<Text color="primary">/</Text>} />
                    </DiContext.Provider>
                ))}
            </Group>
        </ScrollArea>
    );

    return retVal;
}

export function HeaderBar({ headerWidth }: { headerWidth: number }) {
    const [profile, setProfile] = useState<User>();
    const [agreement, setAgreement] = useState<boolean>();
    const [agreementAcceptanceNeeded, { close: gotAgreement, open: needAgreement }] = useDisclosure(false);
    const [profileNeeded, { close: gotProfile, open: needProfile }] = useDisclosure(false);
    const [notificationConfigs, setNotificationConfigs] = useState<NotificationTypeConfig[]>();
    const notificationPrefSvc = useDi(NotificationPreferenceService);
    const [userMenuOpen, { close: closeUserMenu, toggle: toggleUserMenu }] = useDisclosure(false);
    const authSvc = useDi(AuthenticationService);
    const superUserSvc = useDi(SuperUserService);
    const siteMenuContextSvc = useDi(SiteMenuCompanyService);
    const companyCtx = useDi(CompanyContextService);
    const selectedCompany = useEventValue(siteMenuContextSvc.company);
    const [selectedCompanyId] = useDebouncedValue(selectedCompany?.Id, 500);
    const fallbackCompany = useCompany();
    const company = companyCtx?.parentCompany ?? fallbackCompany;
    const isSupport = company?.Type === 'Msp' || company?.Type === 'Support' || company?.Type === 'PlatformSupport';
    const canGoSuperUser = company?.Type === 'PlatformSupport';
    const superUserOn = useEventValue(superUserSvc.superUserOn);
    const [compData, setCompData] = useState<Company>();
    const tenantSvc = useDi(CompanyTenantPrereqService);

    const loadCompany = async () => {
        setCompData(await tenantSvc.getCompanyById(company!.Id!));
    };

    const loadProfile = async () => {
        const user = await getUserGetMyProfile();
        if (!user.FirstName || !user.LastName || !user.EMail || !user.TermsOfSvcAgreementAccepted) {
            needProfile();
        } else {
            gotProfile();
        }
        setProfile(user);
        await notificationPrefSvc.getApplicableNotificationsConfigs().then(setNotificationConfigs);
    };

    const finishAgreement = async () => {
        loadCompany();
    };

    const loadAgreement = async () => {
        if (compData && !compData?.AgreementAccepted) {
            needAgreement();
        } else {
            gotAgreement();
        }
    };

    useEffect(() => {
        loadCompany();
        loadProfile();
    }, []);

    useEffect(() => {
        loadAgreement();
        setAgreement(compData?.AgreementAccepted!);
    }, [compData]);

    const routeLoader = useDi(BasicRouteLoader);
    const { getDescendUrl, goto } = useNav(routeLoader.getTopRouteMeta());
    const notificationSettingsUrl = getDescendUrl('notification-settings');
    const gotoNotificationSettings = useCallback(() => {
        if (routeLoader.getTopRouteMeta().endpointInfo?.name?.toLowerCase() !== 'notification-settings') {
            goto(notificationSettingsUrl);
        }
        closeUserMenu();
    }, [notificationSettingsUrl, closeUserMenu]);

    return !profile ? (
        <></>
    ) : (
        <>
            <Modal
                opened={profileNeeded}
                onClose={() => {}}
                closeOnClickOutside={false}
                withCloseButton={false}
                size={500}
                overlayBlur={5}
                overlayColor="white"
                radius="lg"
            >
                {profile && <ProfilePrompt onFinish={loadProfile} profile={profile} />}
            </Modal>
            {!agreement && (
                <Modal
                    opened={agreementAcceptanceNeeded}
                    onClose={() => {}}
                    closeOnClickOutside={false}
                    withCloseButton={false}
                    size={400}
                    overlayBlur={5}
                    padding={0}
                    radius="md"
                >
                    <AgreementPrompt onFinish={finishAgreement} company={company!} />
                </Modal>
            )}
            <HeaderBarItem
                style={{ backgroundColor: superUserOn ? '#FEF0C7' : undefined, borderTop: superUserOn ? '5px solid #FDB022' : undefined }}
                mode={'wide'}
            >
                <Group noWrap position="apart" sx={{ height: '100%' }}>
                    <Breadcrumb headerWidth={headerWidth} />
                    {superUserOn && (
                        <Box sx={{ position: 'absolute', right: 120, backgroundColor: '#FDB022', padding: '5px 10px', borderRadius: '10px' }}>
                            <Text sx={{ color: '#fff', fontSize: '12px' }}>Super User Mode</Text>
                        </Box>
                    )}

                    <Group>
                        <Tooltip label="Activity Log" offset={10}>
                            <Box>
                                <ActivityPanelOpener />
                            </Box>
                        </Tooltip>
                        <Popover width={300} opened={userMenuOpen} onClose={closeUserMenu} position="bottom-end" withArrow shadow="md">
                            <Popover.Target>
                                <ActionIcon data-atid="UserImageButton" onClick={toggleUserMenu}>
                                    <UserImage
                                        firstName={profile?.FirstName ?? ''}
                                        lastName={profile?.LastName ?? ''}
                                        email={profile?.EMail ?? ''}
                                        picture={profile?.Picture ?? ''}
                                        id={profile?.Id ?? 0}
                                        diameter={32}
                                    />
                                </ActionIcon>
                            </Popover.Target>
                            <Popover.Dropdown>
                                <div style={{ display: 'flex' }}>
                                    {profile?.Picture === 'string' && profile?.Picture?.trim().length > 0 ? (
                                        <Avatar style={{ marginTop: 20 }} color="gray" radius="xs" size={30}>
                                            <div>profile.FirstName?.charAt(0)profile.LastName?.charAt(0)</div>
                                        </Avatar>
                                    ) : (
                                        <div>
                                            <UserImage
                                                firstName={profile?.FirstName ?? ''}
                                                lastName={profile?.LastName ?? ''}
                                                email={profile?.EMail ?? ''}
                                                picture={profile?.Picture ?? ''}
                                                id={profile?.Id ?? 0}
                                                diameter={48}
                                            />
                                        </div>
                                    )}
                                    <div>
                                        <NameStyle>{profile?.FirstName + ' ' + profile?.LastName}</NameStyle>
                                        <EmailStyle>{profile?.EMail}</EmailStyle>
                                    </div>
                                </div>
                                <Menu.Divider />

                                {(!isSupport && notificationConfigs?.length) || (isSupport && selectedCompanyId) ? (
                                    <>
                                        <Anchor size="sm" onClick={gotoNotificationSettings} data-atid="NotificationsSettingLink">
                                            Notification Settings
                                        </Anchor>
                                        <Menu.Divider />
                                    </>
                                ) : (
                                    <></>
                                )}

                                {canGoSuperUser ? (
                                    <>
                                        <Anchor size="sm" onClick={() => superUserSvc.toggleSuperUserMode()} data-atid="SuperUserActivateLink">
                                            {superUserOn ? 'Deactivate Super User Mode' : 'Activate Super User Mode'}
                                        </Anchor>
                                        <Menu.Divider />
                                    </>
                                ) : (
                                    <></>
                                )}

                                <Anchor color="primary" onClick={() => authSvc.signout()} data-atid="HeaderSignoutLink">
                                    Sign Out
                                </Anchor>
                            </Popover.Dropdown>
                        </Popover>
                    </Group>
                </Group>
            </HeaderBarItem>
            <NotificationViewerProvider />
        </>
    );
}

function ProfilePrompt({ onFinish, profile }: { onFinish: () => void; profile: User }) {
    const theme = useMantineTheme();
    const [firstName, setFirstName] = useState(profile.FirstName ?? '');
    const [lastName, setLastName] = useState(profile.LastName ?? '');
    const [email, setEmail] = useState(profile.EMail ?? '');
    const [loading, setLoading] = useState(false);
    const [userAgrees, setUserAgrees] = useState<boolean>(false);
    const companyUserSvc = useDiMemo(CompanyUserService);

    const alreadyHaveEmail = !!profile.EMail;
    const nameOk = firstName && lastName;
    const emailOk =
        email &&
        String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );

    const okIcon: Partial<ThemeIconProps> = { color: 'primary', variant: 'filled', size: 'lg', radius: 'lg' };
    const notOkIcon: Partial<ThemeIconProps> = { color: 'gray', variant: 'outline', size: 'lg', radius: 'lg' };

    const finish = async () => {
        setLoading(true);
        try {
            await postUserSaveTermsOfSvcAgreement({ userId: profile.Id });
            await postUserUpdateMyProfile({ ...profile, EMail: email, FirstName: firstName, LastName: lastName });
            companyUserSvc.invalidate();
            onFinish();
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <Box p={40}>
                <Title align="center" order={2}>
                    Welcome
                </Title>
                <Space h={0} />
                <Title align="center" order={5}>
                    Let's Set Up Your Profile
                </Title>
                <Space h={30} />
                <Group>
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignSelf: 'stretch', alignItems: 'center' }}>
                        <ThemeIcon {...(nameOk ? okIcon : notOkIcon)}>
                            <UserIcon size={18} />
                        </ThemeIcon>
                        <Space h={10} />
                        <Divider
                            size="sm"
                            sx={{ alignSelf: 'center', height: '100%' }}
                            color={theme.colors.gray[3] as CustomColors}
                            orientation="vertical"
                        />
                    </Box>
                    <Box pb="xl" sx={{ flex: 1 }}>
                        <Text>What's your name?</Text>
                        <TextInput value={firstName} onChange={(e) => setFirstName(e.target.value)} required label="First Name" />
                        <TextInput value={lastName} onChange={(e) => setLastName(e.target.value)} required label="Last Name" />
                    </Box>
                </Group>
                <Space h={10} />
                <Group noWrap>
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignSelf: 'stretch', alignItems: 'center' }}>
                        <ThemeIcon {...(emailOk ? okIcon : notOkIcon)}>
                            <Mail size={18} />
                        </ThemeIcon>
                        <Space h={12} />
                        <Divider
                            size="sm"
                            sx={{ alignSelf: 'center', height: '100%' }}
                            color={theme.colors.gray[3] as CustomColors}
                            orientation="vertical"
                        />
                    </Box>
                    <Box pb="xl" sx={{ flex: 1 }}>
                        <Text>{alreadyHaveEmail ? 'Your email address:' : `What's your email address?`}</Text>
                        <Box>
                            {alreadyHaveEmail ? (
                                <Text color="primary">{email}</Text>
                            ) : (
                                <TextInput value={email} onChange={(e) => setEmail(e.target.value)} required label="Email Address" />
                            )}
                        </Box>
                        <Space h={4} />
                        <Text size="xs" color="dimmed">
                            We do not share your email with any third parties.
                        </Text>
                    </Box>
                </Group>
                <Space h={10} />
                <Group noWrap>
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignSelf: 'stretch', alignItems: 'center' }}>
                        <ThemeIcon {...(userAgrees ? okIcon : notOkIcon)}>
                            <FileLike size={18} />
                        </ThemeIcon>
                        <Space h={12} />
                        <Divider
                            size="sm"
                            sx={{ alignSelf: 'center', height: '100%' }}
                            color={theme.colors.gray[3] as CustomColors}
                            orientation="vertical"
                        />
                    </Box>
                    <Box pb="xl" sx={{ flex: 1 }}>
                        <Box>
                            <Group position="left" spacing={6}>
                                <Checkbox onChange={(event) => setUserAgrees(event.currentTarget.checked)} checked={userAgrees} />I accept the
                                <Anchor href="https://www.cloudsaver.com/legal/privacy/" target="_blank">
                                    Privacy Policy
                                </Anchor>
                            </Group>
                        </Box>
                        <Space h={4} />
                        <Text size="xs" color="dimmed">
                            Before proceeding, you must agree to our Privacy Policy.
                        </Text>
                    </Box>
                </Group>
                <Space h={10} />
                <Group noWrap>
                    <Box sx={{ display: 'flex', flexDirection: 'column', alignSelf: 'stretch', alignItems: 'center' }}>
                        <ThemeIcon {...(nameOk && userAgrees && emailOk ? okIcon : notOkIcon)}>
                            <Check size={18} />
                        </ThemeIcon>
                    </Box>
                    <Box>
                        <Text>That's all we need!</Text>
                        <Space h="xs" />
                        <Button
                            leftIcon={loading ? <Loader size="sm" color={theme.white as CustomColors} /> : null}
                            sx={{ width: 125 }}
                            disabled={!nameOk || !emailOk || loading || !userAgrees}
                            onClick={finish}
                        >
                            Finish
                        </Button>
                    </Box>
                </Group>
            </Box>
        </>
    );
}

function AgreementPrompt({ onFinish, company }: { onFinish: () => void; company: Company }) {
    const [loading, setLoading] = useState(false);
    const theme = useMantineTheme();
    const [agreementAccepted, setAgreementAccepted] = useState<boolean>(false);

    const finish = async () => {
        setLoading(true);
        try {
            await postNewSubscriptionUpdateCompanyInfo({
                CompanyId: company.Id,
                AgreementAccepted: agreementAccepted,
            });
            onFinish();
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            {loading ? (
                <Center sx={{ height: 150 }}>
                    <Loader />
                </Center>
            ) : (
                <>
                    <Text p="md">Terms of Service</Text>
                    <Divider />
                    <Box p="md" sx={{ background: theme.colors.gray[2], borderRadius: `0 0 ${theme.radius.md}px ${theme.radius.md}px` }}>
                        <Group position="center" spacing={6} px="lg">
                            <Checkbox
                                onChange={(event: any) => {
                                    setAgreementAccepted(event.target.checked);
                                }}
                                checked={agreementAccepted}
                            />
                            <Text sx={{ cursor: 'pointer' }} onClick={() => setAgreementAccepted(!agreementAccepted)}>
                                I agree with the
                            </Text>
                            <Anchor href={'https://www.cloudsaver.com/legal/master-subscription-agreement/'} target="_blank">
                                terms of service
                            </Anchor>
                        </Group>
                        <Space h="lg" />
                        <Group position="right">
                            <Button disabled={!agreementAccepted} onClick={finish}>
                                Proceed
                            </Button>
                        </Group>
                    </Box>
                </>
            )}
        </>
    );
}

export const HeaderBarItem = styled.div<{ mode: 'wide' | 'narrow' }>`
    border-bottom: solid 1px ${(p) => p.theme.colors.gray[3]};
    width: 100%;
    height: 53px;
    padding: 0 24px;

    button {
        font-size: ${(p) => p.theme.fontSizes.xl + 2}px;
    }
    justify-content: start;
    align-items: center;
`;

const NameStyle = styled.div`
    margin-left: 10px;
    flex-wrap: wrap;
    font-size: medium;
    flex-direction: column;
    margin-top: 4px;
`;
const EmailStyle = styled.div`
    margin-left: 12px;
    font-size: 13px;
    color: gray;
    text-overflow: ellipsis;
    width: 200px;
    overflow: hidden;
    white-space: nowrap;
`;

const imageStyle = {
    width: '50px',
    height: '50px',
    'border-radius': '50px',
    'margin-bottom': '12px',
    'margin-top': '4px',
};
