import { NotificationComponentConfig } from '@apis/Notification/model';
import { Space } from '@mantine/core';
import { ChartSettingsSwitch, ChartTypePicker } from '@root/Components/DashboardLayout/Charts/ChartDashboardItemEditor';
import { ChartConfig } from '@root/Components/DashboardLayout/Charts/ChartRenderer';
import { StandaloneChartEditor } from '@root/Components/DashboardLayout/Charts/Models';
import { QueryDescriptorService } from '@root/Components/Filter/Services';
import { SettingsSectionItem, SettingsSectionItemBody, SettingsSectionItemHeader, SettingsSectionItemHeaderLabel } from '@root/Design/Settings';
import { useEvent } from '@root/Services/EventEmitter';
import { QueryDatasource } from '@root/Services/Query/QueryDatasource';
import { SchemaService, SchemaValueProvider } from '@root/Services/QueryExpr';
import { useEffect, useMemo, useRef } from 'react';

interface IChartSettingsInputProps {
    hideFilter?: boolean;
    queryDatasource: QueryDatasource;
    schemaSvc: SchemaService;
    value: NotificationComponentConfig;
    onChange: (value: NotificationComponentConfig) => void;
}
type NotificationChartConfig = NotificationComponentConfig & ChartConfig;
export function ChartSettingsInput(props: IChartSettingsInputProps) {
    const { hideFilter, queryDatasource, schemaSvc, value, onChange } = props;
    const chartConfig = value as NotificationChartConfig;
    const changeHandler = useRef(onChange);
    const chartEditor = useMemo(() => {
        const chartModel = {
            settings: chartConfig,
            getDatasource: () => queryDatasource,
            updateSettings: () => {},
        };
        const queryDescriptorSvc = QueryDescriptorService.create(schemaSvc);
        const valueProvider = new SchemaValueProvider(schemaSvc, queryDatasource.source);
        const chartEditor = StandaloneChartEditor.create(chartModel, { queryDescriptorSvc, schemaSvc, valueProvider });
        chartEditor.hideFilter = !!hideFilter;

        return chartEditor;
    }, [queryDatasource, chartConfig.chartType, schemaSvc, hideFilter]);

    useEffect(() => {
        const { dispose } = chartEditor.onSettingsChanged.listen(() => {
            changeHandler.current?.(chartEditor.settings as unknown as NotificationComponentConfig);
        });
        return dispose;
    }, [chartEditor.onSettingsChanged]);

    return (
        <>
            <SettingsSectionItem>
                <SettingsSectionItemHeader>
                    <SettingsSectionItemHeaderLabel>Content Type</SettingsSectionItemHeaderLabel>
                </SettingsSectionItemHeader>
                <SettingsSectionItemBody>
                    <Space h="md" />
                    <ChartTypePicker editor={chartEditor} selectionLabel="content" />
                </SettingsSectionItemBody>
            </SettingsSectionItem>
            <ChartSettingsSwitch editor={chartEditor} />
        </>
    );
}

ChartSettingsInput.buildForDatasource = (queryDatasource: QueryDatasource, schemaSvc: SchemaService, hideFilter?: boolean) => {
    return function DsBoundChartSettingsInput(props: { value: NotificationComponentConfig; onChange: (value: NotificationComponentConfig) => void }) {
        return <ChartSettingsInput hideFilter={hideFilter} queryDatasource={queryDatasource} schemaSvc={schemaSvc} {...props} />;
    };
};
