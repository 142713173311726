import { UserDefinedNotification } from '@apis/Notification/model';
import { Button, Center, Group, Text } from '@mantine/core';
import { useAsync } from '@react-hookz/web';
import { FillerSwitch } from '@root/Design/Filler';
import { SidePanelContainer } from '@root/Design/SidePanel';
import { useDiMemo } from '@root/Services/DI';
import { useFmtSvc } from '@root/Services/FormatService';
import { UserDefinedNotificationApiService } from '@root/Services/Notification/UserDefinedNotificationApiService';
import { useEffect } from 'react';
import { NotificationContent } from './Design';
import { NotificationSnapshotContent } from './NotificationSnapshotContent';

interface IInAppNotificationViewerProps {
    notificationId: number;
    onShowSettings: () => void;
    onClose: () => void;
}

export function InAppNotificationViewer(props: IInAppNotificationViewerProps) {
    const { notificationId, onShowSettings, onClose } = props;

    const notificationApi = useDiMemo(UserDefinedNotificationApiService);
    const [{ status, result: notification }, { execute }] = useAsync(async (id: number) => notificationApi.getNotificationById(id));

    useEffect(() => {
        execute(notificationId);
    }, [notificationId]);

    return (
        <SidePanelContainer
            title="Notification"
            onClose={onClose}
            toolbar={
                <Group>
                    <Button onClick={onClose} variant="outline">
                        Close
                    </Button>
                    <Button onClick={onShowSettings} variant="light" color="gray">
                        Open Settings
                    </Button>
                </Group>
            }
        >
            <FillerSwitch loading={status === 'loading'} noData={!notification}>
                {() =>
                    notification?.NotificationDefinition && notification?.NotificationDataSnapshot ? (
                        <InAppNotificationRenderer notification={notification as ValidNotification} />
                    ) : (
                        <InvalidNotification notification={notification} />
                    )
                }
            </FillerSwitch>
        </SidePanelContainer>
    );
}

function InvalidNotification({ notification }: { notification?: UserDefinedNotification }) {
    const fmtSvc = useFmtSvc();
    const { Date: date, NotificationDefinition: definition, NotificationDataSnapshot: snapshot } = notification ?? {};
    const title = definition?.Title ?? 'Unknown Notification';
    const description = definition?.Description ?? '';
    const issue = !definition ? 'Invalid notification' : !snapshot ? `No data found for notification` : 'Unknown Notification';
    const additionalDetails = `Notification date: ${!date ? 'Unknown' : fmtSvc.formatDatetime(fmtSvc.toLocalDate(date))}`;
    return (
        <NotificationContent title={title} description={description}>
            <Center sx={{ height: '100%' }}>
                <Text color="dimmed" italic>
                    {issue}
                </Text>
                <Text color="dimmed" size="sm">
                    {additionalDetails}
                </Text>
            </Center>
        </NotificationContent>
    );
}

type ValidNotification = Defined<UserDefinedNotification, 'NotificationDataSnapshot' | 'NotificationDefinition'>;
interface IInAppNotificationRendererProps {
    notification: ValidNotification;
}
function InAppNotificationRenderer({ notification }: IInAppNotificationRendererProps) {
    const { NotificationDefinition: def, NotificationDataSnapshot: data } = notification;

    return (
        <NotificationContent title={def?.Title ?? 'Untitled'} description={def?.Description ?? ''}>
            <NotificationSnapshotContent config={def} snapshot={data} />
        </NotificationContent>
    );
}
