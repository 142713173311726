/**
 * Generated by orval v6.8.1 🍺
 * Do not edit manually.
 * Cloudsaver.Notification.Host.Api
 * OpenAPI spec version: 1.0
 */
import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey
} from 'react-query'
import type {
  QueuedNotification,
  PostNotificationSaveParams,
  PostNotificationRetrieveByIdParams,
  NotificationPreference,
  PostNotificationGetUserPreferenceByIdParams,
  PostNotificationValidateInviteCodeParams,
  AnonymousNotificationModel,
  PostNotificationSendNotificationParams,
  PostNotificationSaveCommunicationPreferenceParams,
  NotificationTypeConfig,
  ScheduleDefinition,
  PostNotificationGenerateScheduleOccurrencesParams,
  GetNotificationGetLastNotificationDateParams,
  PostNotificationUpsertLastNotificationDateParams,
  PostNotificationSaveMyNotificationOptionParams,
  NotificationTypePreference,
  UserDefinedNotification,
  UserDefinedNotificationRequest,
  UserDefinedNotificationRecipient,
  PostNotificationSaveNotificationRecipientsParams,
  UserDefinedNotificationStatusUpdate
} from './Notification/model'
import { request } from '../Services/BasicApi'


type AwaitedInput<T> = PromiseLike<T> | T;

      type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;


// eslint-disable-next-line
  type SecondParameter<T extends (...args: any) => any> = T extends (
  config: any,
  args: infer P,
) => any
  ? P
  : never;

export const postNotificationSave = (
    params?: PostNotificationSaveParams,
 options?: SecondParameter<typeof request>,
) => {
      return request<QueuedNotification>(
      {url: `/Notification/Save`, method: 'post',
        params,
    },
      { type: 'Notification',  ...options});
    }
  


    export type PostNotificationSaveMutationResult = NonNullable<Awaited<ReturnType<typeof postNotificationSave>>>
    
    export type PostNotificationSaveMutationError = unknown

    export const usePostNotificationSave = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postNotificationSave>>, TError,{params?: PostNotificationSaveParams}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postNotificationSave>>, {params?: PostNotificationSaveParams}> = (props) => {
          const {params} = props ?? {};

          return  postNotificationSave(params,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postNotificationSave>>, TError, {params?: PostNotificationSaveParams}, TContext>(mutationFn, mutationOptions)
    }
    
export const postNotificationRetrieveById = (
    params?: PostNotificationRetrieveByIdParams,
 options?: SecondParameter<typeof request>,
) => {
      return request<QueuedNotification[]>(
      {url: `/Notification/RetrieveById`, method: 'post',
        params,
    },
      { type: 'Notification',  ...options});
    }
  


    export type PostNotificationRetrieveByIdMutationResult = NonNullable<Awaited<ReturnType<typeof postNotificationRetrieveById>>>
    
    export type PostNotificationRetrieveByIdMutationError = unknown

    export const usePostNotificationRetrieveById = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postNotificationRetrieveById>>, TError,{params?: PostNotificationRetrieveByIdParams}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postNotificationRetrieveById>>, {params?: PostNotificationRetrieveByIdParams}> = (props) => {
          const {params} = props ?? {};

          return  postNotificationRetrieveById(params,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postNotificationRetrieveById>>, TError, {params?: PostNotificationRetrieveByIdParams}, TContext>(mutationFn, mutationOptions)
    }
    
export const postNotificationRetrieveAll = (
    
 options?: SecondParameter<typeof request>,
) => {
      return request<QueuedNotification[]>(
      {url: `/Notification/RetrieveAll`, method: 'post'
    },
      { type: 'Notification',  ...options});
    }
  


    export type PostNotificationRetrieveAllMutationResult = NonNullable<Awaited<ReturnType<typeof postNotificationRetrieveAll>>>
    
    export type PostNotificationRetrieveAllMutationError = unknown

    export const usePostNotificationRetrieveAll = <TError = unknown,
    TVariables = void,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postNotificationRetrieveAll>>, TError,TVariables, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postNotificationRetrieveAll>>, TVariables> = () => {
          ;

          return  postNotificationRetrieveAll(requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postNotificationRetrieveAll>>, TError, TVariables, TContext>(mutationFn, mutationOptions)
    }
    
export const postNotificationGetUserPreferenceById = (
    params?: PostNotificationGetUserPreferenceByIdParams,
 options?: SecondParameter<typeof request>,
) => {
      return request<NotificationPreference>(
      {url: `/Notification/GetUserPreferenceById`, method: 'post',
        params,
    },
      { type: 'Notification',  ...options});
    }
  


    export type PostNotificationGetUserPreferenceByIdMutationResult = NonNullable<Awaited<ReturnType<typeof postNotificationGetUserPreferenceById>>>
    
    export type PostNotificationGetUserPreferenceByIdMutationError = unknown

    export const usePostNotificationGetUserPreferenceById = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postNotificationGetUserPreferenceById>>, TError,{params?: PostNotificationGetUserPreferenceByIdParams}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postNotificationGetUserPreferenceById>>, {params?: PostNotificationGetUserPreferenceByIdParams}> = (props) => {
          const {params} = props ?? {};

          return  postNotificationGetUserPreferenceById(params,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postNotificationGetUserPreferenceById>>, TError, {params?: PostNotificationGetUserPreferenceByIdParams}, TContext>(mutationFn, mutationOptions)
    }
    
export const postNotificationValidateInviteCode = (
    params?: PostNotificationValidateInviteCodeParams,
 options?: SecondParameter<typeof request>,
) => {
      return request<boolean>(
      {url: `/Notification/ValidateInviteCode`, method: 'post',
        params,
    },
      { type: 'Notification',  ...options});
    }
  


    export type PostNotificationValidateInviteCodeMutationResult = NonNullable<Awaited<ReturnType<typeof postNotificationValidateInviteCode>>>
    
    export type PostNotificationValidateInviteCodeMutationError = unknown

    export const usePostNotificationValidateInviteCode = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postNotificationValidateInviteCode>>, TError,{params?: PostNotificationValidateInviteCodeParams}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postNotificationValidateInviteCode>>, {params?: PostNotificationValidateInviteCodeParams}> = (props) => {
          const {params} = props ?? {};

          return  postNotificationValidateInviteCode(params,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postNotificationValidateInviteCode>>, TError, {params?: PostNotificationValidateInviteCodeParams}, TContext>(mutationFn, mutationOptions)
    }
    
export const postNotificationSendNotification = (
    anonymousNotificationModel: AnonymousNotificationModel,
    params?: PostNotificationSendNotificationParams,
 options?: SecondParameter<typeof request>,
) => {
      return request<void>(
      {url: `/Notification/SendNotification`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: anonymousNotificationModel,
        params,
    },
      { type: 'Notification',  ...options});
    }
  


    export type PostNotificationSendNotificationMutationResult = NonNullable<Awaited<ReturnType<typeof postNotificationSendNotification>>>
    export type PostNotificationSendNotificationMutationBody = AnonymousNotificationModel
    export type PostNotificationSendNotificationMutationError = unknown

    export const usePostNotificationSendNotification = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postNotificationSendNotification>>, TError,{data: AnonymousNotificationModel;params?: PostNotificationSendNotificationParams}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postNotificationSendNotification>>, {data: AnonymousNotificationModel;params?: PostNotificationSendNotificationParams}> = (props) => {
          const {data,params} = props ?? {};

          return  postNotificationSendNotification(data,params,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postNotificationSendNotification>>, TError, {data: AnonymousNotificationModel;params?: PostNotificationSendNotificationParams}, TContext>(mutationFn, mutationOptions)
    }
    
export const postNotificationSaveCommunicationPreference = (
    params?: PostNotificationSaveCommunicationPreferenceParams,
 options?: SecondParameter<typeof request>,
) => {
      return request<void>(
      {url: `/Notification/SaveCommunicationPreference`, method: 'post',
        params,
    },
      { type: 'Notification',  ...options});
    }
  


    export type PostNotificationSaveCommunicationPreferenceMutationResult = NonNullable<Awaited<ReturnType<typeof postNotificationSaveCommunicationPreference>>>
    
    export type PostNotificationSaveCommunicationPreferenceMutationError = unknown

    export const usePostNotificationSaveCommunicationPreference = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postNotificationSaveCommunicationPreference>>, TError,{params?: PostNotificationSaveCommunicationPreferenceParams}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postNotificationSaveCommunicationPreference>>, {params?: PostNotificationSaveCommunicationPreferenceParams}> = (props) => {
          const {params} = props ?? {};

          return  postNotificationSaveCommunicationPreference(params,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postNotificationSaveCommunicationPreference>>, TError, {params?: PostNotificationSaveCommunicationPreferenceParams}, TContext>(mutationFn, mutationOptions)
    }
    
export const getNotificationGetNotificationTypeConfigs = (
    
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<NotificationTypeConfig[]>(
      {url: `/Notification/GetNotificationTypeConfigs`, method: 'get', signal
    },
      { type: 'Notification',  ...options});
    }
  

export const getGetNotificationGetNotificationTypeConfigsQueryKey = () => [`/Notification/GetNotificationTypeConfigs`];

    
export type GetNotificationGetNotificationTypeConfigsQueryResult = NonNullable<Awaited<ReturnType<typeof getNotificationGetNotificationTypeConfigs>>>
export type GetNotificationGetNotificationTypeConfigsQueryError = unknown

export const useGetNotificationGetNotificationTypeConfigs = <TData = Awaited<ReturnType<typeof getNotificationGetNotificationTypeConfigs>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getNotificationGetNotificationTypeConfigs>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetNotificationGetNotificationTypeConfigsQueryKey();

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getNotificationGetNotificationTypeConfigs>>> = ({ signal }) => getNotificationGetNotificationTypeConfigs(requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getNotificationGetNotificationTypeConfigs>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const postNotificationGenerateScheduleOccurrences = (
    scheduleDefinition: ScheduleDefinition,
    params?: PostNotificationGenerateScheduleOccurrencesParams,
 options?: SecondParameter<typeof request>,
) => {
      return request<string[]>(
      {url: `/Notification/GenerateScheduleOccurrences`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: scheduleDefinition,
        params,
    },
      { type: 'Notification',  ...options});
    }
  


    export type PostNotificationGenerateScheduleOccurrencesMutationResult = NonNullable<Awaited<ReturnType<typeof postNotificationGenerateScheduleOccurrences>>>
    export type PostNotificationGenerateScheduleOccurrencesMutationBody = ScheduleDefinition
    export type PostNotificationGenerateScheduleOccurrencesMutationError = unknown

    export const usePostNotificationGenerateScheduleOccurrences = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postNotificationGenerateScheduleOccurrences>>, TError,{data: ScheduleDefinition;params?: PostNotificationGenerateScheduleOccurrencesParams}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postNotificationGenerateScheduleOccurrences>>, {data: ScheduleDefinition;params?: PostNotificationGenerateScheduleOccurrencesParams}> = (props) => {
          const {data,params} = props ?? {};

          return  postNotificationGenerateScheduleOccurrences(data,params,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postNotificationGenerateScheduleOccurrences>>, TError, {data: ScheduleDefinition;params?: PostNotificationGenerateScheduleOccurrencesParams}, TContext>(mutationFn, mutationOptions)
    }
    
export const getNotificationGetLastNotificationDate = (
    params?: GetNotificationGetLastNotificationDateParams,
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<string>(
      {url: `/Notification/GetLastNotificationDate`, method: 'get', signal,
        params,
    },
      { type: 'Notification',  ...options});
    }
  

export const getGetNotificationGetLastNotificationDateQueryKey = (params?: GetNotificationGetLastNotificationDateParams,) => [`/Notification/GetLastNotificationDate`, ...(params ? [params]: [])];

    
export type GetNotificationGetLastNotificationDateQueryResult = NonNullable<Awaited<ReturnType<typeof getNotificationGetLastNotificationDate>>>
export type GetNotificationGetLastNotificationDateQueryError = unknown

export const useGetNotificationGetLastNotificationDate = <TData = Awaited<ReturnType<typeof getNotificationGetLastNotificationDate>>, TError = unknown>(
 params?: GetNotificationGetLastNotificationDateParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getNotificationGetLastNotificationDate>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetNotificationGetLastNotificationDateQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getNotificationGetLastNotificationDate>>> = ({ signal }) => getNotificationGetLastNotificationDate(params, requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getNotificationGetLastNotificationDate>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const postNotificationUpsertLastNotificationDate = (
    params?: PostNotificationUpsertLastNotificationDateParams,
 options?: SecondParameter<typeof request>,
) => {
      return request<void>(
      {url: `/Notification/UpsertLastNotificationDate`, method: 'post',
        params,
    },
      { type: 'Notification',  ...options});
    }
  


    export type PostNotificationUpsertLastNotificationDateMutationResult = NonNullable<Awaited<ReturnType<typeof postNotificationUpsertLastNotificationDate>>>
    
    export type PostNotificationUpsertLastNotificationDateMutationError = unknown

    export const usePostNotificationUpsertLastNotificationDate = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postNotificationUpsertLastNotificationDate>>, TError,{params?: PostNotificationUpsertLastNotificationDateParams}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postNotificationUpsertLastNotificationDate>>, {params?: PostNotificationUpsertLastNotificationDateParams}> = (props) => {
          const {params} = props ?? {};

          return  postNotificationUpsertLastNotificationDate(params,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postNotificationUpsertLastNotificationDate>>, TError, {params?: PostNotificationUpsertLastNotificationDateParams}, TContext>(mutationFn, mutationOptions)
    }
    
export const postNotificationSaveMyNotificationOption = (
    params?: PostNotificationSaveMyNotificationOptionParams,
 options?: SecondParameter<typeof request>,
) => {
      return request<void>(
      {url: `/Notification/SaveMyNotificationOption`, method: 'post',
        params,
    },
      { type: 'Notification',  ...options});
    }
  


    export type PostNotificationSaveMyNotificationOptionMutationResult = NonNullable<Awaited<ReturnType<typeof postNotificationSaveMyNotificationOption>>>
    
    export type PostNotificationSaveMyNotificationOptionMutationError = unknown

    export const usePostNotificationSaveMyNotificationOption = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postNotificationSaveMyNotificationOption>>, TError,{params?: PostNotificationSaveMyNotificationOptionParams}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postNotificationSaveMyNotificationOption>>, {params?: PostNotificationSaveMyNotificationOptionParams}> = (props) => {
          const {params} = props ?? {};

          return  postNotificationSaveMyNotificationOption(params,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postNotificationSaveMyNotificationOption>>, TError, {params?: PostNotificationSaveMyNotificationOptionParams}, TContext>(mutationFn, mutationOptions)
    }
    
export const getNotificationGetMyNotificationTypePreferences = (
    
 options?: SecondParameter<typeof request>,signal?: AbortSignal
) => {
      return request<NotificationTypePreference[]>(
      {url: `/Notification/GetMyNotificationTypePreferences`, method: 'get', signal
    },
      { type: 'Notification',  ...options});
    }
  

export const getGetNotificationGetMyNotificationTypePreferencesQueryKey = () => [`/Notification/GetMyNotificationTypePreferences`];

    
export type GetNotificationGetMyNotificationTypePreferencesQueryResult = NonNullable<Awaited<ReturnType<typeof getNotificationGetMyNotificationTypePreferences>>>
export type GetNotificationGetMyNotificationTypePreferencesQueryError = unknown

export const useGetNotificationGetMyNotificationTypePreferences = <TData = Awaited<ReturnType<typeof getNotificationGetMyNotificationTypePreferences>>, TError = unknown>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getNotificationGetMyNotificationTypePreferences>>, TError, TData>, request?: SecondParameter<typeof request>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, request: requestOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getGetNotificationGetMyNotificationTypePreferencesQueryKey();

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getNotificationGetMyNotificationTypePreferences>>> = ({ signal }) => getNotificationGetMyNotificationTypePreferences(requestOptions, signal);

  const query = useQuery<Awaited<ReturnType<typeof getNotificationGetMyNotificationTypePreferences>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}


export const postNotificationGetMyUserDefinedNotifications = (
    userDefinedNotificationRequest: UserDefinedNotificationRequest,
 options?: SecondParameter<typeof request>,
) => {
      return request<UserDefinedNotification[]>(
      {url: `/Notification/GetMyUserDefinedNotifications`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: userDefinedNotificationRequest
    },
      { type: 'Notification',  ...options});
    }
  


    export type PostNotificationGetMyUserDefinedNotificationsMutationResult = NonNullable<Awaited<ReturnType<typeof postNotificationGetMyUserDefinedNotifications>>>
    export type PostNotificationGetMyUserDefinedNotificationsMutationBody = UserDefinedNotificationRequest
    export type PostNotificationGetMyUserDefinedNotificationsMutationError = unknown

    export const usePostNotificationGetMyUserDefinedNotifications = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postNotificationGetMyUserDefinedNotifications>>, TError,{data: UserDefinedNotificationRequest}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postNotificationGetMyUserDefinedNotifications>>, {data: UserDefinedNotificationRequest}> = (props) => {
          const {data} = props ?? {};

          return  postNotificationGetMyUserDefinedNotifications(data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postNotificationGetMyUserDefinedNotifications>>, TError, {data: UserDefinedNotificationRequest}, TContext>(mutationFn, mutationOptions)
    }
    
export const postNotificationSaveNotificationTypePreference = (
    notificationTypePreference: NotificationTypePreference,
 options?: SecondParameter<typeof request>,
) => {
      return request<NotificationTypePreference>(
      {url: `/Notification/SaveNotificationTypePreference`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: notificationTypePreference
    },
      { type: 'Notification',  ...options});
    }
  


    export type PostNotificationSaveNotificationTypePreferenceMutationResult = NonNullable<Awaited<ReturnType<typeof postNotificationSaveNotificationTypePreference>>>
    export type PostNotificationSaveNotificationTypePreferenceMutationBody = NotificationTypePreference
    export type PostNotificationSaveNotificationTypePreferenceMutationError = unknown

    export const usePostNotificationSaveNotificationTypePreference = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postNotificationSaveNotificationTypePreference>>, TError,{data: NotificationTypePreference}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postNotificationSaveNotificationTypePreference>>, {data: NotificationTypePreference}> = (props) => {
          const {data} = props ?? {};

          return  postNotificationSaveNotificationTypePreference(data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postNotificationSaveNotificationTypePreference>>, TError, {data: NotificationTypePreference}, TContext>(mutationFn, mutationOptions)
    }
    
export const postNotificationSaveNotificationRecipients = (
    userDefinedNotificationRecipient: UserDefinedNotificationRecipient[],
    params?: PostNotificationSaveNotificationRecipientsParams,
 options?: SecondParameter<typeof request>,
) => {
      return request<void>(
      {url: `/Notification/SaveNotificationRecipients`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: userDefinedNotificationRecipient,
        params,
    },
      { type: 'Notification',  ...options});
    }
  


    export type PostNotificationSaveNotificationRecipientsMutationResult = NonNullable<Awaited<ReturnType<typeof postNotificationSaveNotificationRecipients>>>
    export type PostNotificationSaveNotificationRecipientsMutationBody = UserDefinedNotificationRecipient[]
    export type PostNotificationSaveNotificationRecipientsMutationError = unknown

    export const usePostNotificationSaveNotificationRecipients = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postNotificationSaveNotificationRecipients>>, TError,{data: UserDefinedNotificationRecipient[];params?: PostNotificationSaveNotificationRecipientsParams}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postNotificationSaveNotificationRecipients>>, {data: UserDefinedNotificationRecipient[];params?: PostNotificationSaveNotificationRecipientsParams}> = (props) => {
          const {data,params} = props ?? {};

          return  postNotificationSaveNotificationRecipients(data,params,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postNotificationSaveNotificationRecipients>>, TError, {data: UserDefinedNotificationRecipient[];params?: PostNotificationSaveNotificationRecipientsParams}, TContext>(mutationFn, mutationOptions)
    }
    
export const postNotificationUpdateUserDefinedNotification = (
    userDefinedNotificationStatusUpdate: UserDefinedNotificationStatusUpdate,
 options?: SecondParameter<typeof request>,
) => {
      return request<UserDefinedNotification>(
      {url: `/Notification/UpdateUserDefinedNotification`, method: 'post',
      headers: {'Content-Type': 'application/json'},
      data: userDefinedNotificationStatusUpdate
    },
      { type: 'Notification',  ...options});
    }
  


    export type PostNotificationUpdateUserDefinedNotificationMutationResult = NonNullable<Awaited<ReturnType<typeof postNotificationUpdateUserDefinedNotification>>>
    export type PostNotificationUpdateUserDefinedNotificationMutationBody = UserDefinedNotificationStatusUpdate
    export type PostNotificationUpdateUserDefinedNotificationMutationError = unknown

    export const usePostNotificationUpdateUserDefinedNotification = <TError = unknown,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postNotificationUpdateUserDefinedNotification>>, TError,{data: UserDefinedNotificationStatusUpdate}, TContext>, request?: SecondParameter<typeof request>}
) => {
      const {mutation: mutationOptions, request: requestOptions} = options ?? {}

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postNotificationUpdateUserDefinedNotification>>, {data: UserDefinedNotificationStatusUpdate}> = (props) => {
          const {data} = props ?? {};

          return  postNotificationUpdateUserDefinedNotification(data,requestOptions)
        }

      return useMutation<Awaited<ReturnType<typeof postNotificationUpdateUserDefinedNotification>>, TError, {data: UserDefinedNotificationStatusUpdate}, TContext>(mutationFn, mutationOptions)
    }
    
