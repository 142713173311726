import { QueryResult, QueryExpr } from '@apis/Resources';
import { Query } from '@apis/Resources/model';
import { Card, useMantineTheme, Group, Text, Stack, Space, Divider, Box, ScrollArea, Tooltip, Center } from '@mantine/core';
import { IncreaseIndicator } from '@root/Design/Data';
import { FillerSwitch } from '@root/Design/Filler';
import { useDi, useDiMemo } from '@root/Services/DI';
import { FormatService, useFmtSvc } from '@root/Services/FormatService';
import { InvoiceCostFields } from '@root/Services/Invoices/InvoiceCostFieldContext';
import { InvoiceFieldCompatibilityService } from '@root/Services/Invoices/InvoiceFieldCompatibilityService';
import { IBaseInvoiceRecord } from '@root/Services/Invoices/InvoiceSchemaService';
import { queryBuilder, ValuesGroupOtherText } from '@root/Services/QueryExpr';
import { useState, useEffect } from 'react';
import { Asterisk } from 'tabler-icons-react';

type VarianceDataChange = { increase: number; decrease: number };
type VarianceData = {
    resource: VarianceDataChange;
    usage: VarianceDataChange;
    price: VarianceDataChange;
    other: VarianceDataChange;
    total: VarianceDataChange;
};
interface IVarianceDetailsProps {
    queryApi: <T>(query: Query) => Promise<QueryResult<T>>;
    filters?: QueryExpr[];
    groupBy: 'ChargePeriodStart' | 'UsageMonth';
    from: Date;
    to: Date;
    costField?: InvoiceCostFields;
}
export function VarianceDetails(props: IVarianceDetailsProps) {
    const fmtSvc = useFmtSvc();
    const { from, to } = props;
    const datesEqual = fmtSvc.to8DigitDate(from) === fmtSvc.to8DigitDate(to);
    return (
        <Card radius="lg" shadow="md" sx={{ height: '100%' }}>
            {datesEqual ? (
                <Center sx={{ height: '100%' }}>
                    <Text italic color="dimmed">
                        No difference
                    </Text>
                </Center>
            ) : (
                <ValidatedVarianceDetails {...props} />
            )}
        </Card>
    );
}
function ValidatedVarianceDetails({ queryApi, filters, groupBy, from, to, costField = 'BilledCost' }: IVarianceDetailsProps) {
    const fmtSvc = useFmtSvc();
    const theme = useMantineTheme();
    const [loading, setLoading] = useState(true);
    const [titleData, setTitleData] = useState<{ from: number; to: number; net: number }>();
    const [data, setData] = useState<VarianceData>();
    const groupByField = groupBy;
    const filter = !filters?.length ? undefined : filters.length === 1 ? filters[0] : { Operation: 'and', Operands: filters };
    const fieldCompatSvc = useDiMemo(InvoiceFieldCompatibilityService, []);

    const getData = async () => {
        const qb = queryBuilder<IBaseInvoiceRecord & Record<string, number>>();
        if (filter) {
            qb.where((b) => b.fromExpr(filter));
        }
        const fieldCompat = await fieldCompatSvc.getLookup(groupBy === 'ChargePeriodStart' ? 'daily' : 'monthly');
        const [rate, usage] = fieldCompat.getAvailableFields('ContractedUnitPrice', 'ConsumedQuantity');
        const query = qb
            .select((b) => ({
                key: b.values(b.model.VarianceKey, '', ValuesGroupOtherText),
                date: b.model[groupByField],
                rate: b.values(b.model[rate], null, 0),
                cost: b.sum(b.model[costField]),
                usage: b.sum(b.model[usage]),
            }))
            .build();

        const titleData = { from: 0, to: 0, net: 0 };
        const fromLabel = fmtSvc.toJsonShortDate(from);
        const results = await queryApi<{ key: string; date: string; cost: number; usage: number; rate: number }>(query);
        const grouped = (results?.Results ?? []).reduce((acc, curr) => {
            const [_, type] = curr.key.split('/');
            const key = curr.key + '/' + curr.rate;
            const result: any = acc.get(key) ?? { fromCost: 0, toCost: 0, fromUsage: 0, toUsage: 0, fromRate: 0, toRate: 0, type };
            const label = curr.date.startsWith(fromLabel) ? 'from' : 'to';

            const cost = curr.cost ?? 0;
            const usage = curr.usage ?? 0;
            const rate = curr.rate ?? 0;

            result[label + 'Cost'] += cost;
            result[label + 'Usage'] += usage;
            result[label + 'Rate'] += rate ? rate : Math.round((usage !== 0 ? cost / usage : cost !== 0 ? 1 : 0) * 10000000) / 10000000;

            acc.set(key, result);
            return acc;
        }, new Map<string, { fromCost: number; toCost: number; fromRate: number; toRate: number; fromUsage: number; toUsage: number; type: string }>());
        const data: VarianceData & { fee: VarianceDataChange; credit: VarianceDataChange } = {
            resource: { increase: 0, decrease: 0 },
            usage: { increase: 0, decrease: 0 },
            price: { increase: 0, decrease: 0 },
            fee: { increase: 0, decrease: 0 },
            credit: { increase: 0, decrease: 0 },
            other: { increase: 0, decrease: 0 },
            total: { increase: 0, decrease: 0 },
        };
        const creditFeeTypes = new Set<string>([
            'SavingsPlanNegation',
            'SavingsPlanRecurringFee',
            'SavingsPlanUpfrontFee',
            'DiscountedUsage',
            'DistributorDiscount',
            'EdpDiscount',
            'PrivateRateDiscount',
            'BundledDiscount',
            'SppDiscount',
            'RiVolumeDiscount',
            'RIFee',
            'Fee',
            'Refund',
            'Credit',
            'Tax',
        ]);
        for (const [, value] of grouped.entries()) {
            const diffCost = (value.toCost ?? 0) - (value.fromCost ?? 0);
            titleData.from += value.fromCost;
            titleData.to += value.toCost;

            if (creditFeeTypes.has(value.type)) {
                if (value.fromCost < 0 || value.toCost < 0) {
                    data.credit.increase += diffCost < 0 ? diffCost : 0;
                    data.credit.decrease += diffCost > 0 ? diffCost : 0;
                } else {
                    data.fee.increase += diffCost > 0 ? diffCost : 0;
                    data.fee.decrease += diffCost < 0 ? diffCost : 0;
                }
            } else if (value.fromCost === 0 && value.toCost !== 0) {
                data.resource.increase += diffCost;
            } else if (value.toCost === 0 && value.fromCost !== 0) {
                data.resource.decrease += diffCost;
            } else {
                const diffRate = value.toRate - value.fromRate;
                const diffRateCost = diffRate * value.fromUsage;
                const diff = diffCost - diffRateCost;
                if (diff < 0) {
                    data.usage.decrease += diff;
                } else {
                    data.usage.increase += diff;
                }
                if (diffRate < 0) {
                    data.price.decrease += diffRateCost;
                } else {
                    data.price.increase += diffRateCost;
                }
            }
        }
        data.other.increase = data.fee.increase + data.fee.decrease;
        data.other.decrease = data.credit.increase + data.credit.decrease;

        const totalIncrease = data.resource.increase + data.usage.increase + data.price.increase + data.other.increase;
        const totalDecrease = data.resource.decrease + data.usage.decrease + data.price.decrease + data.other.decrease;
        data.total.increase = totalIncrease;
        data.total.decrease = totalDecrease;

        return { data, titleData };
    };
    useEffect(() => {
        setLoading(true);
        setData(undefined);
        setTitleData(undefined);
        (async () => {
            const { data, titleData } = await getData();
            setData(data);
            setTitleData(titleData);
        })().finally(() => setLoading(false));
    }, [queryApi, filters, groupBy, from, to]);
    const fmtDate = groupBy === 'UsageMonth' ? (date: Date) => fmtSvc.formatMonth(date) : (date: Date) => fmtSvc.toShortDate(date);
    const titleDiff = titleData ? titleData.to - titleData.from : 0;
    const titleDiffPct = !titleData ? 0 : titleData.from ? titleDiff / titleData.from : 0;
    const totalDiff = data ? data.total.increase + data.total.decrease : 0;
    const roundingError = titleData ? Math.abs(Math.abs(Math.round(titleData.to) - Math.round(titleData.from)) - Math.round(Math.abs(totalDiff))) : 0;
    const roundingErrorMessage = `Differs from ${
        groupBy === 'UsageMonth' ? 'month-over-month' : 'day-over-day'
    } value due to rounding error of ${fmtSvc.formatMoneyNoDecimals(roundingError)}`;

    return (
        <ScrollArea sx={{ height: '100%' }}>
            <FillerSwitch loading={loading} noData={(!loading && !data) || from === to}>
                {() => (
                    <>
                        <Card withBorder radius="md">
                            <Group position="center">
                                {roundingError > 0 ? (
                                    <Tooltip withinPortal label={roundingErrorMessage}>
                                        <div>
                                            <Asterisk size={16} color={theme.colors.error[6]} />
                                        </div>
                                    </Tooltip>
                                ) : null}
                                <Text size="lg" weight="bold">
                                    {fmtSvc.formatMoneyNoDecimals(titleDiff)}
                                </Text>
                                <IncreaseIndicator preferDecrease value={titleDiff} size="lg" />
                            </Group>
                            <Text align="center" size="sm">
                                {fmtSvc.formatPercent(titleDiffPct)} {titleDiff > 0 ? 'Increase' : titleDiff < 0 ? 'Decrease' : 'No Change'}
                            </Text>
                            <Group position="apart" mx="lg" mt={6}>
                                <Box>
                                    <Text align="center" size="sm">
                                        {fmtDate(from)}
                                    </Text>
                                    <Text weight="bold" align="center" size="sm">
                                        {fmtSvc.formatMoneyNoDecimals(titleData?.from ?? 0)}
                                    </Text>
                                </Box>
                                <Box>
                                    <Text align="center" size="sm">
                                        {fmtDate(to)}
                                    </Text>
                                    <Text weight="bold" align="center" size="sm">
                                        {fmtSvc.formatMoneyNoDecimals(titleData?.to ?? 0)}
                                    </Text>
                                </Box>
                            </Group>
                        </Card>
                        <Space h={10} />
                        <Stack spacing={0}>
                            <VarianceDetail
                                radius="top"
                                decrease={data!.resource.decrease}
                                increase={data!.resource.increase}
                                title="Service Changes"
                                increaseLabel="Added"
                                decreaseLabel="Removed"
                            />
                            <VarianceDetail
                                decrease={data!.price.decrease}
                                increase={data!.price.increase}
                                title="Price Changes"
                                increaseLabel="Increase"
                                decreaseLabel="Decrease"
                            />
                            <VarianceDetail
                                decrease={data!.usage.decrease}
                                increase={data!.usage.increase}
                                title="Usage Changes"
                                increaseLabel="Increase"
                                decreaseLabel="Decrease"
                            />
                            <VarianceDetail
                                radius="bottom"
                                decrease={data!.other.decrease}
                                increase={data!.other.increase}
                                title="Other"
                                increaseLabel={`Fees (${data!.other.increase < 0 ? 'Decreased' : 'Increased'})`}
                                decreaseLabel={`Credits (${data!.other.decrease < 0 ? 'Increased' : 'Decreased'})`}
                            />
                            <Space h={10} />
                            <VarianceDetail
                                radius="both"
                                decrease={data!.total.decrease}
                                increase={data!.total.increase}
                                title="Total"
                                increaseLabel="Increase"
                                decreaseLabel="Decrease"
                            />
                        </Stack>
                    </>
                )}
            </FillerSwitch>
        </ScrollArea>
    );
}

function VarianceDetail({
    increase,
    decrease,
    title,
    increaseLabel,
    decreaseLabel,
    radius,
    net,
    roundingError,
}: {
    increase: number;
    decrease: number;
    title: string;
    increaseLabel: string;
    decreaseLabel: string;
    net?: number;
    radius?: 'top' | 'bottom' | 'both';
    roundingError?: boolean;
}) {
    const fmtSvc = useDi(FormatService);
    const theme = useMantineTheme();
    net ??= increase + decrease;
    const bg = net > 0 ? theme.colors.warning[1] : net < 0 ? theme.colors.success[1] : theme.colors.gray[1];
    const rad = theme.radius.md;
    const radiusValue =
        radius === 'top' ? `${rad}px ${rad}px 0 0` : radius === 'bottom' ? `0 0 ${rad}px ${rad}px` : radius === 'both' ? `${rad}px` : '0';

    return (
        <Box py="xs" sx={{ background: bg, borderRadius: radiusValue }} mb={2} p="sm">
            <Group position="apart">
                <Text weight="bold">{title}</Text> <IncreaseIndicator size="lg" value={net} preferDecrease />
            </Group>
            <Group position="apart">
                <Text size="sm" color="dimmed">
                    {increaseLabel}
                </Text>
                <Text size="sm">{fmtSvc.formatMoneyNoDecimals(increase)}</Text>
            </Group>
            <Group position="apart">
                <Text size="sm" color="dimmed">
                    {decreaseLabel}
                </Text>
                <Text size="sm">{fmtSvc.formatMoneyNoDecimals(decrease)}</Text>
            </Group>
            <Divider />
            <Group position="apart">
                <Text size="sm" color="dimmed">
                    Net
                </Text>
                <Text size="sm">{fmtSvc.formatMoneyNoDecimals(net)}</Text>
            </Group>
        </Box>
    );
}
