import { MapContract, MapContractStatus } from '@apis/TagManager/model';
import { Box, Center, Loader, Text, useMantineTheme } from '@mantine/core';
import { CustomizableDashboard, DashboardAddOption } from '@root/Components/DashboardLayout/CustomizableDashboard';
import { useEffect, useMemo, useState } from 'react';
import { Query } from '@apis/Resources/model';
import { postResourcesQuery } from '@apis/Resources';
import { useDi } from '@root/Services/DI';
import { ResourceSchemaProvider } from '@root/Services/Resources/ResourceService';
import { useCompany } from '@root/Components/Router/CompanyContent';
import { PlatformService } from '@root/Services/PlatformService';
import { DashboardItemProps, IDashboardConfig, IDashboardItemType } from '@root/Components/DashboardLayout/Models';
import { ChartDashboardItem } from '@root/Components/DashboardLayout/ChartDashboardItem';
import { defaultMapDashboardLayout } from './MapDashboardConfigs';
import { MapDashboardModel } from './MapDashboard';
import { IStringFluentOperators, queryBuilder } from '@root/Services/QueryExpr';
import { BaseMapResource } from '../../Services/MapResourceQueryService';
import { MapContractHomeModel } from '../Models';
import { EligibleResourcesByRegion } from './EligibleResourcesByRegion';
import { FormatService } from '@root/Services/FormatService';
import { PieChart } from '@root/Components/Charts/PieChart';
import { ReportContainer, ReportHeader, ReportPanel } from '../Design';
import { BarChart } from '@root/Components/Charts/BarChart';
import { useEventValue } from '@root/Services/EventEmitter';
import { useNav } from '@root/Services/NavigationService';
import { Check, Tool } from 'tabler-icons-react';
import styled from '@emotion/styled';
import { MapListChartRenderer } from './MapListChartRenderer';
import { QueryDatasource } from '@root/Services/Query/QueryDatasource';

export const MapDashboardCustomizable = ({ contract }: { contract: MapContract }) => {
    const [loading, setLoading] = useState(true);
    const [connected, setConnected] = useState<null | boolean>(true);
    const resourceSchema = useDi(ResourceSchemaProvider);
    const company = useCompany()!;
    const platformSvc = useDi(PlatformService);
    const home = useDi(MapContractHomeModel);
    const [itemTypes, setItemTypes] = useState<IDashboardItemType[]>([]);
    const [coverageData, setCoverageData] = useState<CoverageData>();
    const [spendData, setSpendData] = useState<SpendCoverageData>();
    const [connectedCount, setConnectedCount] = useState<number>(0);
    const [notConnectedCount, setNotConnectedCount] = useState<number>(0);
    const [keyValuesApplied, setKeyValuesApplied] = useState<ListData[]>([]);
    const [top5Owners, setTop5Owwners] = useState<ListData[]>([]);
    const [top5Resources, setTop5Resources] = useState<ListData[]>([]);
    const [contractStatus, setContractStatus] = useState<MapContractStatus[]>();
    const [actionsRequired, setActionsRequired] = useState<{ name: string; count: number }[]>();
    const homeLoading = useEventValue(home.loading);

    useEffect(() => {
        if (!homeLoading) {
            setLoading(true);
            setContractStatus([...home.contractStatus.values()]);
            Promise.all([
                getTagCoverageData(),
                getTagCoverageSpendData(),
                getKeyValuesAppliedData(),
                getTop5OwnersData(),
                getTop5ResourcesData(),
                getContractStatusData(),
                getActionsRequiredData(),
            ]).then(() => setLoading(false));
        }
    }, [homeLoading]);

    const getActionsRequiredData = async () => {
        const resources = await queryBuilder<BaseMapResource>()
            .where((b) => b.and(model.querySvc.getMgmtAccountQuery()))
            .select((b) => ({
                untagged: b.countIf(model.querySvc.getUndecidedQuery()),
                incorrectlyTagged: b.countIf(model.querySvc.getIncorrectlyTaggedQuery()),
                outsideContractTerm: b.countIf(model.querySvc.getOverrideQuery()),
            }))
            .execute(postResourcesQuery);

        const { untagged, incorrectlyTagged, outsideContractTerm } = resources?.Results?.[0] ?? {
            untagged: 0,
            incorrect: 0,
            ineligible: 0,
        };

        let aRvalues: { name: string; count: number }[] = [];

        aRvalues.push({ name: 'Tagged Incorrectly', count: incorrectlyTagged ?? 0 });
        aRvalues.push({ name: 'Not Tagged', count: untagged ?? 0 });
        aRvalues.push({ name: 'Ineligible MAP Tags', count: outsideContractTerm ?? 0 });

        setActionsRequired(aRvalues);
    };

    const getContractStatusData = async () => {
        await platformSvc.init();
        const accounts = platformSvc.getPlatforms();
        setConnected(accounts.size > 0);
        setConnectedCount(home.accounts.filter((a) => a.IsConnected).length);
        setNotConnectedCount(home.accounts.length - home.accounts.filter((a) => a.IsConnected).length);
    };

    const getTop5ResourcesData = async () => {
        const results = await queryBuilder<BaseMapResource>()
            .where((b) => b.and(model.querySvc.getMgmtAccountQuery(), model.querySvc.getValidResourcesQuery()))
            .select((b) => ({
                key: b.model['ResourceType'] as unknown as IStringFluentOperators,
                value: b.sum(b.model['Last30DaysCost'] as unknown as number),
            }))
            .execute(postResourcesQuery);

        setTop5Resources(results.Results?.sort((a, b) => b.value - a.value).slice(0, 5) ?? []);
    };

    const getTop5OwnersData = async () => {
        const results = await queryBuilder<BaseMapResource>()
            .where((b) => b.and(model.querySvc.getMgmtAccountQuery()))
            .select((b) => ({
                key: b.model['CsTags.Owner'] as unknown as IStringFluentOperators,
                totalResources: b.count(),
            }))
            .execute(postResourcesQuery);

        setTop5Owwners(
            results.Results?.map((x) => ({ key: x.key, value: x.totalResources / (results.Count || 1) }))
                .filter((x) => x.value > 0)
                .sort((a, b) => b.value - a.value)
                .slice(0, 5) ?? []
        );
    };

    const getKeyValuesAppliedData = async () => {
        const results = await queryBuilder<BaseMapResource>()
            .where((b) => b.and(model.querySvc.getMgmtAccountQuery()))
            .select((b) => ({
                key: b.model['CsTags.map-migrated'] as unknown as IStringFluentOperators,
                value: b.countIf(b.model['CsTags.map-migrated'].eq(model.querySvc.validContractOptions)),
            }))
            .execute(postResourcesQuery);

        setKeyValuesApplied(results.Results?.filter((x) => x.value > 0).sort((a, b) => b.value - a.value) ?? []);
    };

    const getTagCoverageSpendData = async () => {
        // Had to run three separate queries because painless (used for iif) won't implicitly convert a string to a date to compare to createdDate.
        // Gabe quote: Painful Painless update would be required ;)

        const results = await queryBuilder<BaseMapResource>()
            .where((b) => b.and(model.querySvc.getMgmtAccountQuery(), model.querySvc.getValidResourcesQuery()))
            .select((b) => ({
                taggedSpend: b.aggIf(
                    b.model[`CsTags.map-migrated`].eq(model.querySvc.validTagOptions),
                    b.sum(b.model['Last30DaysCost'] as unknown as number)
                ),
                incorrectlyTaggedSpend: b.aggIf(
                    b.and(b.model[`CsTags.map-migrated`].ne(model.querySvc.validTagOptions), b.model[`CsTags.map-migrated`].isNotNull()),
                    b.sum(b.model['Last30DaysCost'] as unknown as number)
                ),
                untaggedSpend: b.aggIf(b.model[`CsTags.map-migrated`].isNull(), b.sum(b.model['Last30DaysCost'] as unknown as number)),
            }))
            .execute(postResourcesQuery);
        const {
            taggedSpend,
            incorrectlyTaggedSpend: IncorrectlyTaggedSpend,
            untaggedSpend,
        } = results?.Results?.[0] ?? { taggedSpend: 0, incorrectlyTaggedSpend: 0, untaggedSpend: 0 };
        const spendTotal = taggedSpend + untaggedSpend + IncorrectlyTaggedSpend;
        const taggedSpendPct = spendTotal ? taggedSpend / spendTotal : 0;
        const untaggedSpendPct = spendTotal ? untaggedSpend / spendTotal : 0;
        const IncorrectlyTaggedSpendPct = spendTotal ? IncorrectlyTaggedSpend / spendTotal : 0;

        const spendData = {
            taggedSpend,
            taggedSpendPct,
            untaggedSpend,
            untaggedSpendPct,
            IncorrectlyTaggedSpend,
            IncorrectlyTaggedSpendPct,
        };
        setSpendData(spendData);
    };

    const getTagCoverageData = async () => {
        const resources = await queryBuilder<BaseMapResource>()
            .where((b) => b.and(model.querySvc.getMgmtAccountQuery()))
            .select((b) => ({
                anyData: b.count(),
                tagged: b.countIf(model.querySvc.getCorrectlyTaggedQuery()),
                untagged: b.countIf(model.querySvc.getUndecidedQuery()),
                incorrect: b.countIf(model.querySvc.getIncorrectlyTaggedQuery()),
            }))
            .execute(postResourcesQuery);

        const { tagged, untagged, incorrect, anyData } = resources?.Results?.[0] ?? {
            tagged: 0,
            untagged: 0,
            incorrect: 0,
            anyData: 0,
            taggedSpend: 0,
            IncorrectlyTaggedSpend: 0,
            untaggedSpend: 0,
        };
        const total = tagged + untagged + incorrect;
        const taggedPct = total ? tagged / total : 0;
        const untaggedPct = total ? untagged / total : 0;
        const incorrectPct = total ? incorrect / total : 0;

        setCoverageData({ taggedPct, untaggedPct, incorrectPct, anyData, tagged, untagged, incorrect });
    };

    const model = useMemo(() => new MapDashboardModel(contract, home.mapResourceQuerySvc!), [contract]);
    const datasource = useMemo(
        () =>
            [
                {
                    name: 'resources',
                    source: (query: Query) => {
                        // todo: add where
                        return postResourcesQuery(query, { companyId: company.Id });
                    },
                    schema: resourceSchema,
                    getValueProviderFactory: resourceSchema.createValueProviderFactory(),
                    getDefaultGroup: () => [{ Expr: { Field: 'key' }, Alias: 'Coverage' }],
                    getDefaultValue: () => [{ Expr: { Field: 'value' }, Alias: 'Count' }],
                    getDefaultHistogram: () => ({ Expr: {}, Alias: '' }),
                },
            ] as QueryDatasource[],
        [connected]
    );

    const addOptions = useMemo(() => [] as DashboardAddOption[], [connected]);

    useEffect(() => {
        const result = coverageData
            ? ([
                  ChartDashboardItem.itemType,
                  {
                      type: 'eligible-resources-by-region',
                      component: (props: DashboardItemProps<{ title: string }>) => {
                          return <EligibleResourcesByRegion model={model} dashboardItemModel={props.model} />;
                      },
                  },
                  {
                      type: 'current-tag',
                      component: (model: DashboardItemProps<{ title: string }>) => (
                          <MapCoverageChartRenderer
                              connectedCount={connectedCount}
                              notConnectedCount={notConnectedCount}
                              model={model}
                              data={coverageData}
                          />
                      ),
                  },
                  {
                      type: 'accounts-connected',
                      component: (model: DashboardItemProps<{ title: string }>) => (
                          <MapCoverageChartRenderer
                              connectedCount={connectedCount}
                              notConnectedCount={notConnectedCount}
                              model={model}
                              data={coverageData}
                          />
                      ),
                  },
                  {
                      type: 'configuration-settings',
                      component: (model: DashboardItemProps<{ title: string }>) => (
                          <MapCoverageChartRenderer
                              connectedCount={connectedCount}
                              notConnectedCount={notConnectedCount}
                              model={model}
                              data={coverageData}
                              contractStatus={contractStatus}
                          />
                      ),
                  },
              ] as IDashboardItemType[])
            : [];

        if (keyValuesApplied) {
            result.push({
                type: 'key-values-applied',
                component: (model: DashboardItemProps<{ title: string }>) => (
                    <MapListChartRenderer model={model} data={keyValuesApplied} field="CsTags.map-migrated" />
                ),
            });
        }

        if (top5Owners) {
            result.push({
                type: 'top-5-owners',
                component: (model: DashboardItemProps<{ title: string }>) => (
                    <MapListChartRenderer model={model} data={top5Owners} field="CsTags.Owner" />
                ),
            });
        }
        if (top5Resources) {
            result.push({
                type: 'top-5-resources',
                component: (model: DashboardItemProps<{ title: string }>) => (
                    <MapListChartRenderer model={model} data={top5Resources} field="ResourceType" />
                ),
            });
        }

        if (actionsRequired) {
            result.push({
                type: 'actions-required',
                component: (model: DashboardItemProps<{ title: string }>) => <MapBarChartRenderer model={model} data={actionsRequired} />,
            });
        }

        if (spendData) {
            result.push({
                type: 'current-tag-spend',
                component: (model: DashboardItemProps<{ title: string }>) => (
                    <MapCoverageChartRenderer
                        connectedCount={connectedCount}
                        notConnectedCount={notConnectedCount}
                        model={model}
                        spendData={spendData}
                    />
                ),
            });
        }

        setItemTypes(result);
    }, [spendData, connectedCount, notConnectedCount, coverageData, keyValuesApplied, top5Owners, top5Resources, actionsRequired]);

    const defaultConfig = useMemo(
        () =>
            ({
                name: 'Default MAP Dashboard',
                layout: defaultMapDashboardLayout,
            } as IDashboardConfig),
        []
    );
    return (
        <ReportPanel>
            <ReportHeader>Dashboards</ReportHeader>
            <ReportContainer bg="gray" fullHeight>
                {itemTypes.length && !loading ? (
                    <CustomizableDashboard
                        padding={0}
                        dashboardKey="map-mgr7"
                        addOptions={addOptions}
                        datasources={datasource}
                        hideHeader
                        itemTypes={itemTypes}
                        defaultConfig={defaultConfig}
                    />
                ) : (
                    <Center sx={{ height: 400 }}>
                        <Loader />
                    </Center>
                )}
            </ReportContainer>
        </ReportPanel>
    );
};
interface CoverageData {
    taggedPct: number;
    untaggedPct: number;
    incorrectPct: number;
    anyData: number;
    tagged: number;
    untagged: number;
    incorrect: number;
}

interface SpendCoverageData {
    taggedSpend: number;
    taggedSpendPct: number;
    untaggedSpend: number;
    untaggedSpendPct: number;
    IncorrectlyTaggedSpend: number;
    IncorrectlyTaggedSpendPct: number;
}

export interface ListData {
    key: string;
    value: number;
    [key: string]: string | number;
}

function MapCoverageChartRenderer({
    model,
    data,
    spendData,
    connectedCount,
    notConnectedCount,
    contractStatus,
}: {
    data?: CoverageData | undefined;
    spendData?: SpendCoverageData | undefined;
    model: DashboardItemProps<{ title: string }>;
    connectedCount: number;
    notConnectedCount: number;
    contractStatus?: MapContractStatus[];
}) {
    useEffect(() => {
        model.model.getHeader = () => <>{model.model.settings.title}</>;
    }, []);
    return (
        <MapCoverageChart
            connectedCount={connectedCount}
            notConnectedCount={notConnectedCount}
            data={data}
            spendData={spendData}
            type={model.model.settings.type as MapCoverageChartTypes}
            contractStatus={contractStatus}
        />
    );
}

type MapCoverageChartTypes = 'current-tag' | 'current-tag-spend' | 'accounts-connected' | 'configuration-settings';
function MapCoverageChart({
    data,
    spendData,
    type,
    connectedCount,
    notConnectedCount,
    contractStatus,
}: {
    data: CoverageData | undefined;
    spendData: SpendCoverageData | undefined;
    type: MapCoverageChartTypes;
    connectedCount: number;
    notConnectedCount: number;
    contractStatus?: MapContractStatus[];
}) {
    const fmtSvc = useDi(FormatService);
    const theme = useMantineTheme();

    const home = useDi(MapContractHomeModel);
    const { descend, move } = useNav();
    const [coverageGridData, setCoverageGridData] = useState<{ label: string; value: number; color: string }[]>([]);
    const [spendGridData, setSpendGridData] = useState<{ label: string; value: number; color: string }[]>([]);

    const gotoResourceBrowser = ({ label }: { label: string | number }) => {
        const query =
            label === 'Not Tagged'
                ? home.mapResourceQuerySvc?.getUndecidedQuery()
                : label === 'Tagged Incorrectly'
                ? home.mapResourceQuerySvc?.getIncorrectlyTaggedQuery()
                : home.mapResourceQuerySvc?.getCorrectlyTaggedQuery();
        descend('map-resource-browser', {
            type: label.toString(),
            filter: JSON.stringify([{ Operation: 'and', Operands: [query?.resolve()], description: label }]),
            id: home.contract?.Id!.toString() ?? '',
        });
    };

    const getTagCoverageChartColors = (data: { label: string; value: number; color: string }[]) => {
        const colorsToReturn: string[] = [];
        data = data.sort((x, y) => (x.value > y.value ? -1 : 1));
        data.forEach((item) => {
            if (item.value > 0) {
                colorsToReturn.push(item.color);
            }
        });
        return colorsToReturn;
    };

    useEffect(() => {
        setCoverageGridData([
            { label: 'Tagged Incorrectly', value: data?.incorrectPct ?? 0, color: '#F97066' },
            { label: 'Not Tagged', value: data?.untaggedPct ?? 0, color: '#009FE1' },
            { label: 'Tagged Correctly', value: data?.taggedPct ?? 0, color: '#12B76A' },
        ]);
    }, [data]);

    useEffect(() => {
        setSpendGridData([
            { label: 'Tagged Incorrectly', value: spendData ? spendData.IncorrectlyTaggedSpendPct ?? 0 : 0, color: '#F97066' },
            { label: 'Not Tagged', value: spendData ? spendData.untaggedSpendPct ?? 0 : 0, color: '#009FE1' },
            { label: 'Tagged Correctly', value: spendData ? spendData.taggedSpendPct ?? 0 : 0, color: '#12B76A' },
        ]);
    }, [spendData]);
    switch (type) {
        case 'current-tag':
            return (
                <PieChart
                    data={coverageGridData}
                    groups={['label']}
                    values={['value']}
                    settings={{
                        valueFormat: ' ^-.0~%',
                        chartColors: getTagCoverageChartColors(coverageGridData),
                        enableArcLinkLabels: false,
                        enableArcLabels: false,
                        hideToolTip: false,
                        margin: { left: 0, bottom: 70, top: 10, right: 0 },
                        showTotal: true,
                        total: fmtSvc.formatInt((data?.incorrect ?? 0) + (data?.untagged ?? 0) + (data?.tagged ?? 0)),
                        totalLabelPosition: 'bottom',
                        totalLabel: 'Resources',
                        legend: [
                            {
                                onClick: gotoResourceBrowser,
                                data: [
                                    { label: 'Tagged Incorrectly', id: spendData?.IncorrectlyTaggedSpendPct ?? 0, color: '#F97066' },
                                    { label: 'Not Tagged', id: spendData?.untaggedSpendPct ?? 0, color: '#009FE1' },
                                    { label: 'Tagged Correctly', id: spendData?.taggedSpendPct ?? 0, color: '#12B76A' },
                                ],
                                anchor: 'bottom',
                                translateX: 0,
                                translateY: 70,
                                itemWidth: 100,
                                direction: 'column',
                                itemHeight: 18,
                            },
                        ],
                    }}
                />
            );
        case 'current-tag-spend':
            return (
                <PieChart
                    data={spendGridData}
                    groups={['label']}
                    values={['value']}
                    settings={{
                        valueFormat: ' ^-.0~%',
                        chartColors: getTagCoverageChartColors(spendGridData),
                        enableArcLinkLabels: false,
                        enableArcLabels: false,
                        hideToolTip: false,
                        legend: [
                            {
                                data: [
                                    { label: 'Tagged Incorrectly', id: spendData?.IncorrectlyTaggedSpendPct ?? 0, color: '#F97066' },
                                    { label: 'Not Tagged', id: spendData?.untaggedSpendPct ?? 0, color: '#009FE1' },
                                    { label: 'Tagged Correctly', id: spendData?.taggedSpendPct ?? 0, color: '#12B76A' },
                                ],
                                onClick: home.contract ? gotoResourceBrowser : undefined,
                                anchor: 'bottom',
                                translateX: 0,
                                translateY: 70,
                                itemWidth: 100,
                                direction: 'column',
                                itemHeight: 18,
                            },
                        ],
                        margin: { left: 0, bottom: 70, top: 10, right: 0 },
                        showTotal: true,
                        total: fmtSvc.formatMoneyNoDecimals(
                            (spendData?.taggedSpend ?? 0) + (spendData?.IncorrectlyTaggedSpend ?? 0) + (spendData?.untaggedSpendPct ?? 0)
                        ),
                        totalLabelPosition: 'bottom',
                        totalLabel: 'Spend',
                    }}
                />
            );
        case 'accounts-connected': {
            const canFix = notConnectedCount;
            const bgColor = canFix ? theme.colors.warning[2] : theme.colors.success[3];
            const fgColor = canFix ? theme.colors.warning[6] : theme.colors.success[6];
            const gotoAccounts = () => move('map-contract', { id: home.contract?.Id?.toString() ?? '', section: 'connection' });

            // The chart will always show the largest number first.
            // The problem is that the chart colors are not tied to the values,
            // but the legend colors are. We have to make sure that the values
            // are added to the chart in the correct order so that the legend
            // colors match the chart colors. Same on the chart below.
            const connectedPercent = connectedCount / (connectedCount + notConnectedCount);
            const notConnectedPercent = notConnectedCount / (connectedCount + notConnectedCount);

            var chartData = [];
            var legendData = [];
            if (connectedPercent >= notConnectedPercent) {
                chartData.push({ label: 'Connected', value: connectedPercent });
                chartData.push({ label: 'Not connected', value: notConnectedPercent });

                legendData.push({
                    label: `Connected (${connectedCount})`,
                    id: connectedCount > 0 ? connectedPercent : 0,
                    color: '#5C4B8C',
                });
                legendData.push({
                    label: `Not Connected (${notConnectedCount})`,
                    id: notConnectedCount > 0 ? notConnectedPercent : 0,
                    color: '#CA4127',
                });
            } else {
                chartData.push({ label: 'Not connected', value: notConnectedPercent });
                chartData.push({ label: 'Connected', value: connectedPercent });

                legendData.push({
                    label: `Not Connected (${notConnectedCount})`,
                    id: notConnectedCount > 0 ? notConnectedPercent : 0,
                    color: '#5C4B8C',
                });
                legendData.push({
                    label: `Connected (${connectedCount})`,
                    id: connectedCount > 0 ? connectedPercent : 0,
                    color: '#CA4127',
                });
            }
            return (
                <PieChart
                    data={chartData}
                    groups={['label']}
                    values={['value']}
                    settings={{
                        valueFormat: ' ^-.0~%',
                        chartColors: ['#5C4B8C', '#CA4127'],
                        enableArcLinkLabels: false,
                        enableArcLabels: false,
                        legend: [
                            {
                                data: legendData,
                                anchor: 'top-right',
                                translateX: 0,
                                translateY: 60,
                                direction: 'column',
                                itemWidth: 18,
                                itemHeight: 24,
                            },
                        ],
                        margin: { left: 0, bottom: 20, top: 20, right: 100 },
                        showTotal: false,
                        renderCenter: () => (
                            <Box component={DonutHole} onClick={gotoAccounts} sx={{ background: bgColor, color: fgColor }}>
                                {canFix ? <Tool size={32} strokeWidth={0} fill={fgColor} /> : <Check size={32} />}
                                <Text>{canFix ? 'Fix' : 'No Issues'}</Text>
                            </Box>
                        ),
                    }}
                />
            );
        }
        case 'configuration-settings': {
            const totalConfigPoints = (contractStatus ?? []).length * 2;
            const configured = (contractStatus ?? []).reduce(
                (result, status) => result + (status.TagActivated ? 1 : 0) + (status.TagExists ? 1 : 0),
                0
            );
            const notConfigured = totalConfigPoints - configured;
            const canFix = notConfigured;
            const bgColor = canFix ? theme.colors.warning[2] : theme.colors.success[3];
            const fgColor = canFix ? theme.colors.warning[6] : theme.colors.success[6];
            const gotoAccounts = () => move('map-contract', { id: home.contract?.Id?.toString() ?? '', section: 'connection' });

            // The chart will always show the largest number first.
            // The problem is that the chart colors are not tied to the values,
            // but the legend colors are. We have to make sure that the values
            // are added to the chart in the correct order so that the legend
            // colors match the chart colors. Same on the chart above.
            const configuredPoints = totalConfigPoints > 0 ? configured / totalConfigPoints : 0;
            const notConfiguredPoints = totalConfigPoints > 0 ? notConfigured / totalConfigPoints : 0;

            var chartData = [];
            var legendData = [];
            if (configuredPoints >= notConfiguredPoints) {
                chartData.push({ label: `Configured (${configured})`, value: configuredPoints });
                chartData.push({ label: `Not Configured (${notConfigured})`, value: notConfiguredPoints });

                legendData.push({
                    label: `Configured (${configured})`,
                    id: configuredPoints,
                    color: theme.colors.success[5],
                });
                legendData.push({
                    label: `Not Configured (${notConfigured})`,
                    id: notConfiguredPoints,
                    color: theme.colors.gray[5],
                });
            } else {
                chartData.push({ label: `Not Configured (${notConfigured})`, value: notConfiguredPoints });
                chartData.push({ label: `Configured (${configured})`, value: configuredPoints });

                legendData.push({
                    label: `Not Configured (${notConfigured})`,
                    id: notConfiguredPoints,
                    color: theme.colors.gray[5],
                });
                legendData.push({
                    label: `Configured (${configured})`,
                    id: configuredPoints,
                    color: theme.colors.success[5],
                });
            }
            return (
                <PieChart
                    data={chartData}
                    groups={['label']}
                    values={['value']}
                    settings={{
                        valueFormat: ' ^-.0~%',
                        chartColors: [theme.colors.success[5], theme.colors.gray[5]],
                        enableArcLinkLabels: false,
                        enableArcLabels: false,
                        legend: [
                            {
                                data: legendData,
                                anchor: 'top-right',
                                translateX: 0,
                                translateY: 60,
                                direction: 'column',
                                itemWidth: 18,
                                itemHeight: 24,
                            },
                        ],
                        margin: { left: 0, bottom: 20, top: 20, right: 100 },
                        showTotal: false,
                        renderCenter: () => (
                            <Box component={DonutHole} onClick={gotoAccounts} sx={{ background: bgColor, color: fgColor }}>
                                {canFix ? <Tool size={32} strokeWidth={0} fill={fgColor} /> : <Check size={32} />}
                                <Text>{canFix ? 'Fix' : 'No Issues'}</Text>
                            </Box>
                        ),
                    }}
                />
            );
        }
        default:
            return <>Not Implemented</>;
    }
}

const DonutHole = styled.div`
    cursor: pointer;
    border-radius: 50%;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

function MapBarChartRenderer({ model, data }: { data: { name: string; count: number }[]; model: DashboardItemProps<{ title: string }> }) {
    useEffect(() => {
        model.model.getHeader = () => <>{model.model.settings.title}</>;
    }, []);
    return <MapBarChart data={data} type={model.model.settings.type as MapBarChartTypes} />;
}

export type MapBarChartTypes = 'actions-required';
export function MapBarChart({ data, type }: { data: { name: string; count: number }[]; type: MapBarChartTypes }) {
    const theme = useMantineTheme();
    const home = useDi(MapContractHomeModel);
    const { descend } = useNav();
    const gotoResourceBrowser = ({ label }: { label: string | number }) => {
        const query =
            label === 'Not Tagged'
                ? home.mapResourceQuerySvc?.getUndecidedQuery()
                : label === 'Tagged Incorrectly'
                ? home.mapResourceQuerySvc?.getIncorrectlyTaggedQuery()
                : home.mapResourceQuerySvc?.getIneligibleQuery();
        descend('map-resource-browser', {
            type: label as string,
            filter: JSON.stringify([{ Operation: 'and', Operands: [query?.resolve()], description: label }]),
            id: home.contract?.Id!.toString() ?? '',
        });
    };

    switch (type) {
        case 'actions-required':
            return (
                <BarChart
                    data={data}
                    groups={['name', 'name']}
                    values={['count']}
                    settings={{
                        noWrapper: true,
                        margin: { top: 20, bottom: 110, left: 110, right: 10 },
                        orientation: 'Horizontal',
                        gridLines: true,
                        chartColors: ['#F97066', '#009FE1', '#FDB022'],
                        legend: [
                            {
                                onClick: home.contract ? gotoResourceBrowser : undefined,
                                anchor: 'bottom-left',
                                dataFrom: 'keys',
                                translateY: 110,
                                itemWidth: 130,
                                direction: 'column',
                                itemHeight: 18,
                            },
                        ],
                    }}
                />
            );
    }
}
