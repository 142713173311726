import { endpoint } from '@root/Services/Router/EndpointRegistry';
import { SettingsPage } from './SettingsPage';

export function NotificationsPage() {
    return (
        <SettingsPage>
            <></>
        </SettingsPage>
    );
}
endpoint('notifications', NotificationsPage, 'Notifications');
