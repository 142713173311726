export type RequestBundle = { request: unknown; resolver: (() => void) | ((result: unknown) => void) };

export class AsyncBundler {
    private readonly bundles = new Map<string, RequestBundle[]>();

    public constructor(private readonly timeoutMs = 200) {}

    public bundle<TRequest>(key: string, request: TRequest, action: (requests: TRequest[]) => Promise<void>): Promise<void>;
    public bundle<TRequest, TResult>(key: string, request: TRequest, action: (requests: TRequest[]) => Promise<TResult[]>): Promise<TResult>;
    public async bundle<TRequest, TResult>(key: string, request: TRequest, action: (requests: TRequest[]) => Promise<void | TResult[]>) {
        let bundle = this.bundles.get(key);
        if (!bundle) {
            this.bundles.set(key, (bundle = []));
            setTimeout(() => this.flush(key, bundle!, action), this.timeoutMs);
        }
        const item = { request, resolver: (_: TResult) => {} };
        bundle.push(item as RequestBundle);
        return new Promise<TResult>((r) => (item.resolver = r));
    }

    private async flush(key: string, bundle: RequestBundle[], action: (requests: any[]) => unknown) {
        this.bundles.delete(key);
        const requests = bundle!.map((b) => b.request);
        let results: unknown = [];
        try {
            results = await action(requests);
        } finally {
            this.resolveResults(bundle!, results);
        }
    }

    private resolveResults(bundle: RequestBundle[], results?: unknown) {
        let i = 0;
        for (const b of bundle) {
            try {
                if (results && Array.isArray(results)) {
                    b.resolver(results[i++]);
                } else {
                    b.resolver(void 0);
                }
            } catch (e) {
                console.error(e);
            }
        }
    }
}

/**
 * Debounce spammy requests, waiting for a timeout before executing an action,
 * then executing the last action. The last action's promise is only resolved
 * if new requests are not made or an arbitrary data key has not changed.
 */
export class RequestThrottlingService {
    private timerHandle: number | null = null;
    private dataKey: string | null = null;
    private timeoutMs: number = 200;
    private lastPromise: Promise<unknown> | null = null;

    public static create(timeoutMs: undefined | number = 200) {
        const result = new RequestThrottlingService();
        if (timeoutMs) {
            result.timeoutMs = timeoutMs;
        }
        return result;
    }

    public throttle<T>(dataKey: string, action: () => Promise<T>) {
        let resolver: (value: T) => void = () => {};
        let rejector: (reason?: any) => void = () => {};

        if (this.dataKey === dataKey && this.lastPromise) {
            return this.lastPromise as Promise<T>;
        }

        this.dataKey = dataKey;
        if (this.timerHandle) {
            clearTimeout(this.timerHandle);
        }

        let timerHandle: number;
        const execute = () => this.executeAction(dataKey, timerHandle, action, resolver, rejector);
        timerHandle = this.timerHandle = setTimeout(execute, this.timeoutMs) as unknown as number;

        return (this.lastPromise = new Promise<T>((resolve, reject) => {
            resolver = resolve;
            rejector = reject;
        }));
    }

    private executeAction = <T>(
        dataKey: string,
        timerHandle: number,
        action: () => Promise<T>,
        resolver: (value: T) => void,
        rejector: (reason?: any) => void
    ) => {
        const shouldComplete = () => this.getThrottleStatus(dataKey, timerHandle);
        action()
            .then(
                (result) => {
                    if (shouldComplete()) {
                        resolver(result);
                    }
                },
                (failure) => {
                    if (shouldComplete()) {
                        rejector(failure);
                    }
                }
            )
            .finally(() => {
                if (shouldComplete()) {
                    this.dataKey = null;
                    this.lastPromise = null;
                }
            });
    };

    private getThrottleStatus(dataKey: string, timerHandle: number) {
        return timerHandle === this.timerHandle && dataKey === this.dataKey;
    }
}
