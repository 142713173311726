import {
    getNotificationGetMyNotificationTypePreferences,
    postNotificationSaveNotificationRecipients,
    postNotificationSaveNotificationTypePreference,
    postNotificationUpdateUserDefinedNotification,
    postNotificationGetMyUserDefinedNotifications,
} from '@apis/Notification';
import {
    NotificationTypePreference,
    UserDefinedNotification,
    UserDefinedNotificationRecipient,
    UserDefinedNotificationRecipientMessageTypeItem,
} from '@apis/Notification/model';
import { UserDefinedNotificationRequest } from '@apis/Notification/model/userDefinedNotificationRequest';

export class UserDefinedNotificationApiService {
    public async getNotificationDefinitions() {
        return await getNotificationGetMyNotificationTypePreferences();
    }

    public async saveNotificationDefinition(definition: NotificationTypePreference) {
        return await postNotificationSaveNotificationTypePreference(definition);
    }

    public async saveNotificationRecipients(definitionId: number, recipients: UserDefinedNotificationRecipient[]) {
        return await postNotificationSaveNotificationRecipients(recipients, { preferenceId: definitionId });
    }

    public async getNotifications(options: UserDefinedNotificationRequest) {
        return await postNotificationGetMyUserDefinedNotifications(options);
    }

    public async getNotificationDefinitionById(id: number) {
        const definitions = await this.getNotificationDefinitions();
        return definitions.find((d) => d.Id === id);
    }

    public async getNotificationById(notificationId: number) {
        const notifications = await this.getNotifications({ NotificationIds: [notificationId] });
        return notifications.find(() => true);
    }
}

export class InAppNotificationApiService {
    public async dismissNotfication(notificationId: number) {
        return await this.updateNotification(notificationId, { IsOpened: true });
    }

    public async markNotificationAsRead(notificationId: number) {
        return await this.updateNotification(notificationId, { IsOpened: true });
    }

    private async updateNotification(notificationId: number, update: { IsDismissed?: boolean; IsOpened?: boolean }) {
        postNotificationUpdateUserDefinedNotification({
            ...update,
            UserDefinedNotificationId: notificationId,
        });
    }
}
