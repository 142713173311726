import {
    useMantineTheme,
    Card,
    Grid,
    Title,
    Group,
    Box,
    Text,
    Loader,
    Center,
    Switch,
    Tooltip,
    Select,
    Space,
    SelectItem,
    Divider,
    Badge,
    UnstyledButton,
    Button,
} from '@mantine/core';
import { endpoint } from '@root/Services/Router/EndpointRegistry';
import { PageBody, PageContent, PagePanel, PageToolbar, PanelBody, PanelContent, PaneledPage, PanelHeader, PanelToolbar } from '@root/Design/Layout';
import { CustomColors } from '@root/Design/Themes';
import { useNav } from '@root/Services/NavigationService';
import { NotificationTypeConfig, NotificationTypeConfigOptionId, NotificationTypePreferenceNotificationType } from '@apis/Notification/model';
import { NotificationCrudService, useNotificationCrudSvc } from '@root/Services/Notification/NotificationCrudService';
import { useEvent, useEventValue } from '@root/Services/EventEmitter';
import { useCallback, useMemo, useState } from 'react';
import { NavBarItem, PageNavBar } from '@root/Components/PageNavBar/PageNavBar';
import { useLink } from '@root/Services/Router/Router';
import { LetterF } from 'tabler-icons-react';
import { Clearfix } from '@root/Design/Primitives';
import { useNotificationViewer } from '@root/Components/Notifications/NotificationOpener';
import {
    NotificationDefinitionList,
    NotificationDefinitionListControls,
    useNotificationDefinitionListControls,
} from '@root/Components/Notifications/Settings/NotificationDefinitionList';

const appNames = new Map<string, string>([
    ['map manager', 'MAP Manager'],
    ['tag manager', 'Tag Manager'],
    ['idle resources', 'Idle Resources'],
    ['invoice manager', 'Invoice Manager'],
]);

const notificationTypeDescriptions = new Map<NotificationTypePreferenceNotificationType, { name: string; description: string }>([
    [
        'CostSpike',
        {
            name: 'Cost Spike Notification',
            description: 'Receive an email if your spend increases above a percent, signalling a potential problem.',
        },
    ],
    [
        'WeeklyMAPHealthUpdate',
        { name: 'Weekly MAP Health Update', description: 'Receive a weekly email with your MAP health status and a trend from the previous week.' },
    ],
    [
        'LowMAPHealthNotification',
        {
            name: 'Low MAP Health Notification',
            description: 'Get notified via email if your MAP health drops below a percent.',
        },
    ],
    [
        'IneligibleMAPSpendSupportUserNotification',
        {
            name: 'Ineligible MAP Spend Notification',
            description: 'Get notified via email if your ineligible MAP spend rises above a percent of total spend.',
        },
    ],
    [
        'RdsAutoRestartedNotification',
        {
            name: 'RDS Restarted Notification',
            description: "Would you like to recieve notifications when an RDS auto restarts after it's been stopped?",
        },
    ],
    [
        'WeeklyIdleResources',
        {
            name: 'Idle Resources Notification',
            description: 'Would you like to recieve weekly notifications regarding idle resources?',
        },
    ],
]);

export function NotificationPreference({ config, crudSvc }: { config: NotificationTypeConfig; crudSvc: NotificationCrudService }) {
    const { name, description } = notificationTypeDescriptions.get(config?.Type!) ?? { name: '', description: '' };
    const [changing, setChanging] = useState(false);
    useEvent(crudSvc.prefsChanging);

    const toggle = useCallback(async (value: boolean) => {
        if (!changing) {
            try {
                setChanging(true);
                await crudSvc.setPref(config.Type!, 'Email', value);
            } finally {
                setChanging(false);
            }
        }
    }, []);

    const setOption = useCallback(async (Id: NotificationTypeConfigOptionId, value: any) => {
        if (!changing) {
            try {
                setChanging(true);
                await crudSvc.setOption(config.Type!, Id, value);
            } finally {
                setChanging(false);
            }
        }
    }, []);

    return (
        <Group noWrap align="flex-start" p="md">
            <Box pt={4} sx={{ opacity: changing ? 0.6 : 1 }}>
                <Switch size="sm" checked={crudSvc.getPref(config.Type!, 'Email')} onChange={(e) => toggle(e.target.checked)} />
            </Box>
            <Box>
                <Text sx={{ cursor: 'pointer' }} onClick={() => toggle(!crudSvc.getPref(config.Type!, 'Email'))} size="md" weight="bold">
                    {name}
                </Text>
                <Space h={5} />
                <Text size="sm">{description}</Text>
                {config.Options != null
                    ? config.Options.map((o) => (
                          <>
                              <Space h="sm" />
                              <Group>
                                  {o.ComponentType == 'Select' ? (
                                      <Select
                                          size="xs"
                                          disabled={!crudSvc.getPref(config.Type!, 'Email')}
                                          data={o.SelectOptions!.map((o) => {
                                              return { value: o.value, label: o.label } as SelectItem;
                                          })}
                                          value={(crudSvc.getOptionValue(config.Type!, o.Id!) ?? o.DefaultValue!) as string}
                                          withinPortal
                                          onChange={(value) => setOption(o.Id!, value)}
                                      ></Select>
                                  ) : o.ComponentType == 'Label' ? null : (
                                      <>invalid component type</>
                                  )}
                                  {o.ComponentType != 'Label' ? (
                                      <Tooltip label={o.Description}>
                                          <Text size="sm">{o.Label}</Text>
                                      </Tooltip>
                                  ) : null}
                              </Group>
                          </>
                      ))
                    : null}
            </Box>
        </Group>
    );
}

export function AppNotificationPreferences({ app, crudSvc }: { app: string; crudSvc: NotificationCrudService }) {
    return (
        <Card withBorder shadow="xs" radius="sm">
            <Title order={4}>{appNames.get(app.toLocaleLowerCase())}</Title>
            <Card.Section>
                {crudSvc.notificationConfigs.map((c) =>
                    c.ApplicableApps?.includes(app) && c.Options !== null ? (
                        <NotificationPreference key={c.Type ?? ''} config={c} crudSvc={crudSvc} />
                    ) : (
                        <></>
                    )
                )}
            </Card.Section>
        </Card>
    );
}

export function NotificatioNavBar() {
    const theme = useMantineTheme();
    const menuItems = useMemo(() => {
        return [
            {
                endpoint: 'map-notification-settings',
                icon: <i className="ti ti-arrows-right" />,
                text: 'Map Manager',
                type: 'link',
                atid: 'notifications-map-nav',
            },
            {
                endpoint: 'invoice-notification-settings',
                icon: <i className="ti ti-file-invoice" />,
                text: 'Invoice Manager',
                type: 'link',
                atid: 'notifications-invoice-nav',
            },
            {
                endpoint: 'idle-resources-notification-settings',
                icon: <i className="ti ti-bolt" />,
                text: 'Idle Resources',
                type: 'link',
                atid: 'notifications-idle-nav',
            },
        ].map((r) => {
            return { ...r };
        }) as NavBarItem[];
    }, []);
    return (
        <Box sx={{ width: '250px', height: '100%' }}>
            <PageNavBar items={menuItems} />
        </Box>
    );
}

const EmailNotificationPage = () => {
    const notificationCrudSvc = useNotificationCrudSvc();
    const loading = useEventValue(notificationCrudSvc.loading);
    const apps = notificationCrudSvc.getApps();
    const theme = useMantineTheme();
    const goToLink = useNav();
    const link = useLink();

    const getAppHref = (app: string) => {
        switch (app) {
            case 'Map Manager':
                return 'map-notification-settings';
            case 'Invoice Manager':
                return 'invoice-notification-settings';
            case 'Idle Resources':
                return 'idle-resources-notification-settings';
            default:
                return '';
        }
    };

    const getAppIcon = (app: string) => {
        switch (app) {
            case 'Map Manager':
                return 'ti ti-arrows-right';
            case 'Invoice Manager':
                return 'ti ti-file-invoice';
            case 'Idle Resources':
                return 'ti ti-bolt';
            default:
                return '';
        }
    };

    const getAppDescription = (app: string) => {
        switch (app) {
            case 'Map Manager':
                return 'Manage and configure notifications for Map Manager';
            case 'Invoice Manager':
                return 'Manage and configure notifications for Invoice Manager';
            case 'Idle Resources':
                return 'Manage and configure notifications for Idle Resources';
            default:
                return '';
        }
    };

    return loading ? (
        <Center sx={{ height: 400 }}>
            <Loader />
        </Center>
    ) : (
        <Box sx={{ display: 'flex', height: '100%' }}>
            <NotificatioNavBar />
            <Divider orientation="vertical" />
            <PageContent style={{ background: theme.colors.gray[1] }}>
                <PaneledPage>
                    <PagePanel size="fill">
                        <Title order={3} px="xl" pt="md">
                            Notification Settings
                        </Title>
                        <Divider orientation="horizontal" />
                        <PanelBody>
                            <Grid>
                                {apps.map((app) => (
                                    <UnstyledButton component="a" {...link(getAppHref(app))}>
                                        <Card
                                            sx={{
                                                height: '150px',
                                                width: '250px',
                                                borderWidth: '1px',
                                                borderStyle: 'solid',
                                                borderColor: theme.colors.gray[4],
                                                borderRadius: '7.5%',
                                                margin: '15px',
                                            }}
                                        >
                                            <i className={getAppIcon(app)} style={{ fontSize: '24px', float: 'left', top: '4px' }}></i>
                                            <Text style={{ marginLeft: '10px', float: 'left' }}>{app}</Text>
                                            <Clearfix />
                                            <Text style={{ fontSize: '14px', color: theme.colors.gray[5], margin: '10px' }}>
                                                {getAppDescription(app)}
                                            </Text>
                                        </Card>
                                    </UnstyledButton>
                                ))}
                            </Grid>
                        </PanelBody>
                    </PagePanel>
                </PaneledPage>
            </PageContent>
        </Box>
    );
};

function NotificationSettingsPage() {
    const theme = useMantineTheme();
    const { open } = useNotificationViewer();
    const controls = useNotificationDefinitionListControls();
    const handleNotificationViewerClose = useCallback(
        (changed: boolean) => {
            if (changed) {
                controls.refresh();
            }
        },
        [open, controls]
    );
    const handleAddNotification = useCallback(() => {
        open({ mode: 'edit', scope: { context: {} }, onClose: handleNotificationViewerClose });
    }, [open]);

    const config = useEventValue(controls.config);

    return (
        <PageContent>
            <PanelContent>
                <PanelHeader style={{ width: 1100 }}>
                    <Text size={20}>Notification Settings</Text>
                    <Group>
                        <NotificationDefinitionListControls controls={controls} />
                        <Button onClick={handleAddNotification}>Add Notification</Button>
                    </Group>
                </PanelHeader>
                <Divider color="gray.3" />
                <PanelBody style={{ background: theme.colors.gray[2] }}>
                    <NotificationDefinitionList
                        config={config}
                        onRefreshHandlerReady={controls.provideRefreshHandler}
                        onLoadingChange={controls.loading.emit}
                    />
                </PanelBody>
            </PanelContent>
        </PageContent>
    );
}

endpoint('notification-settings', NotificationSettingsPage, 'Notification Settings');
