import styled from '@emotion/styled';
import { Accordion, Box, createStyles, Divider, Group, Stack, Text } from '@mantine/core';
import { forwardRef, ReactNode } from 'react';

/**
 * Container for related settings
 */
export const SettingsSectionItem = styled.div<{ anchor?: boolean }>`
    border: solid 1px ${(p) => p.theme.colors.gray[4]};
    border-radius: ${(p) => p.theme.radius.sm}px;
    background: ${(p) => (p.anchor ? p.theme.colors.gray[1] : p.theme.white)};
    box-shadow: ${(p) => (p.anchor ? undefined : p.theme.shadows.xs)};
    &:hover {
        box-shadow: ${(p) => (p.anchor ? p.theme.shadows.sm : undefined)};
        background: ${(p) => (p.anchor ? p.theme.colors.gray[1] : undefined)};
    }
    display: ${(p) => (p.anchor ? 'flex' : 'block')};
    flex-direction: column;
    align-items: stretch;
    ${(p) => (!p.anchor ? '' : '&> a')} {
        &:hover {
            text-decoration: none;
        }
    }
`;

/**
 * Title for related settings under a SettingsSectionItem
 */
export const SettingsSectionItemHeader = styled.div`
    background: ${(p) => p.theme.colors.gray[1]};
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 8px;
    padding: 4px 8px;
    border-bottom: solid 1px ${(p) => p.theme.colors.gray[3]};
    .--hover-visible {
        visibility: hidden;
    }
    &:hover .--hover-visible {
        visibility: visible;
    }
`;

/**
 * Label for the title of a related settings under a SettingsSectionItemHeader
 */
export const SettingsSectionItemHeaderLabel = styled.div`
    font-size: ${(p) => p.theme.fontSizes.xs}px;
    margin-bottom: -2px;
    line-height: 28px;
    &:first-of-type {
        padding-left: 8px;
    }
`;

/**
 * Container for related settings under a SettingsSectionItem
 */
export const SettingsSectionItemBody = styled.div`
    --settings-body-padding: 12px;
    padding: var(--settings-body-padding);

    & [role='note'] + [role='separator'] {
        margin-top: 0;
    }
    & [role='separator']:has(+ [role='note']) {
        margin-bottom: 0;
    }
    & [role='note']:has(+ [role='separator']) {
        padding-bottom: var(--settings-divider-vertical-margin);
    }
    & [role='note'] + [role='note'] {
        margin-top: calc(-1 * var(--settings-divider-vertical-margin));
    }
    & :last-child[role='note'] {
        margin-bottom: calc(-1 * var(--settings-body-padding));
    }
`;

export const SettingsSectionBodyDivider = styled.div`
    margin: var(--settings-divider-vertical-margin, 8px) calc(-1 * var(--settings-body-padding));
    border-top: solid 1px ${(p) => p.theme.colors.gray[3]};
`;
SettingsSectionBodyDivider.defaultProps = { role: 'separator' };

/**
 * A single settings row which should display a label above an input that is full-width
 */
export const SettingsInputStack = styled.div`
    display: block;
    --input-label-lineHeight: 20px;
    --input-label-fontSize: 13px;
    padding: 4px 0;
`;

/**
 * Block of text that provides additional information, lighter, smaller text, full width
 */
export const SettingsInfoRowText = styled.div<{ indent?: boolean }>`
    font-size: var(--input-label-fontSize, 13px);
    color: ${(p) => p.theme.colors.gray[6]};
    padding: ${(p) => (p.indent ? '0 16px' : '0')};
`;

/**
 * A single setting row which should contain a label and info row text, offwhite background
 */
export const SettingsInfoRow = styled.div`
    --input-label-lineHeight: 30px;
    --input-label-fontSize: 13px;
    margin: 0 calc(-1 * var(--settings-body-padding));
    padding: var(--settings-body-padding);
    padding-top: 0;
    background: ${(p) => p.theme.colors.gray[1]};
    &: ;
`;
SettingsInfoRow.defaultProps = { role: 'note' };

/**
 * A single settings row which should contain a label and an input, spaced apart
 */
export const SettingsInputRow = styled.div`
    display: grid;
    grid-template-columns: max-content 1fr;
    align-items: center;
    column-gap: 16px;
    --input-border-color: ${(p) => p.theme.colors.gray[4]};
    --input-height: 30px;

    & > :nth-child(1) {
        grid-column: 1;
        justify-self: start;
    }
    & > :nth-child(2) {
        grid-column: 2;
        justify-self: end;
    }
    & > :nth-child(n + 3) {
        grid-column: 1 / -1;
    }
`;
export const SettingsInteractiveRow = styled(SettingsInputRow)`
    cursor: pointer;
    margin: 0 calc(-0.5 * var(--settings-body-padding));
    padding: 0 calc(0.5 * var(--settings-body-padding));
    &:hover {
        background: ${(p) => p.theme.colors.primary[1]};
    }
`;
SettingsInteractiveRow.defaultProps = { role: 'button', tabIndex: 0 };

/**
 * The label for an input, which should be the first child of a SettingsInputRow
 * @param param0
 * @returns
 */
export function SettingsLabel(props: { children: ReactNode; icon?: ReactNode } & ISettingsLabelIconProps) {
    const { children, icon, ...iconProps } = props;
    return (
        <Text sx={{ whiteSpace: 'nowrap', lineHeight: 'var(--input-label-lineHeight, 40px)', fontSize: 'var(--input-label-fontSize, 14px)' }}>
            {!icon ? null : (
                <SettingsLabelIcon {...iconProps} className={typeof icon == 'string' ? icon : undefined}>
                    {typeof icon !== 'string' ? icon : null}
                </SettingsLabelIcon>
            )}{' '}
            {children}
        </Text>
    );
}

interface ISettingsLabelIconProps {
    transform?: string;
    iconColor?: string;
    strokeWidth?: number;
}
export const SettingsLabelIcon = styled.i<ISettingsLabelIconProps>`
    font-size: 1.25rem;
    vertical-align: sub;
    color: ${(p) => p.iconColor ?? p.theme.colors.gray[6]};
    margin-right: ${(p) => p.theme.fontSizes.xs / 4}px;
    svg {
        transform: ${(p) => (p.transform ? p.transform : `translate(0px, -1.5px)`)};
        width: 16px;
        height: 16px;
        stroke-width: ${(p) => p.strokeWidth ?? 1};
    }
`;

/**
 * Base settings preview areas with content that stacks vertically
 */
export const SettingsPreviewArea = styled.div<{ transparent?: boolean; scrollable?: boolean }>`
    --settings-preview-padding: ${(p) => p.theme.spacing.lg}px;
    height: 100%;
    flex: 1;
    overflow: ${(p) => (p.scrollable ? 'auto' : 'hidden')};
    padding: var(--settings-preview-padding);
    background-image: linear-gradient(90deg, #0001, transparent 12px);
    background-color: ${(p) => (p.transparent ? `${p.theme.colors.gray[3]}80` : p.theme.colors.gray[2])};
    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        background: inherit;
        backdrop-filter: blur(2px);
        pointer-events: none;
    }
`;

/**
 * For preview areas with content that stacks vertically, extends SettingsPreviewArea
 */
export const SettingsPreviewStack = styled(SettingsPreviewArea)`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: ${(p) => p.theme.spacing.md}px;
    align-items: stretch;
`;

/**
 * For preview areas with content that is centered, extends SettingsPreviewArea
 */
export const SettingsPreviewCenter = styled(SettingsPreviewArea)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

/**
 * Container for title over a preview area
 */
export const SettingsPreviewTitle = styled.div`
    display: flex;
    justify-content: flex-end;
    flex: 0;
    padding: ${(p) => p.theme.spacing.md}px;
    min-height: 52px;
    align-items: center;
    justify-content: flex-end;
    padding: 0 ${(p) => p.theme.spacing.lg}px;
    background-color: ${(p) => p.theme.colors.gray[1]};
    background-image: linear-gradient(90deg, #0001, transparent 12px);
    border-bottom: solid 1px ${(p) => p.theme.colors.gray[4]};
    color: ${(p) => p.theme.colors.gray[7]};
`;

/**
 * Container for preview, which should contain the title and preview area
 */
export const SettingsPreviewContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0;
    height: 100%;
    flex: 1;
    overflow: hidden;
`;

export const SettingsPanelStack = styled(Stack)<{ width?: number }>`
    height: 100%;
    flex: 0 0 ${(p) => p.width ?? 500}px;
    gap: 0;
    background: #fff;
    --settings-divider-vertical-margin: 8px;
`;

export const SettingsWithPreviewContainerEl = styled(Group)`
    display: flex;
    height: 100%;
    gap: 0;
`;

export function SettingsWithPreviewContainer({
    settings,
    settingsWidth = 500,
    preview,
    previewTransparent = true,
    previewScrollable = false,
    previewType = 'stack',
    title,
}: {
    settings: ReactNode;
    settingsWidth?: number;
    previewScrollable?: boolean;
    preview: ReactNode;
    previewTransparent?: boolean;
    previewType?: 'center' | 'stack';
    title?: ReactNode;
}) {
    const PreviewEl = previewType === 'center' ? SettingsPreviewCenter : SettingsPreviewStack;
    return (
        <SettingsWithPreviewContainerEl>
            <SettingsPanelStack width={settingsWidth}>{settings}</SettingsPanelStack>
            <Divider orientation="vertical" />
            <SettingsPreviewContainer>
                <SettingsPreviewTitle>{title}</SettingsPreviewTitle>
                <PreviewEl transparent={previewTransparent} scrollable={previewScrollable}>
                    {preview}
                </PreviewEl>
            </SettingsPreviewContainer>
        </SettingsWithPreviewContainerEl>
    );
}

/**
 * Scrollable container for settings
 */
export function SettingsContainer({ children }: { children: ReactNode }) {
    return (
        <Box p="lg" sx={{ overflow: 'auto', height: '100%' }}>
            {children}
        </Box>
    );
}

/**
 * Collapsible container for related settings with optional title
 * @param props
 * @returns
 */
export function SettingsSection(props: { children: ReactNode; title?: ReactNode; collapsed?: boolean; allowNestedSections?: boolean }) {
    const { children, title, collapsed, allowNestedSections } = props;
    const { classes } = useSettingsAccordionStyles({ allowNestedSections });
    return (
        <Accordion className={classes.accordion} variant="separated" multiple defaultValue={collapsed ? [] : ['section']}>
            <Accordion.Item value="section">
                {title ? <Accordion.Control value="Type">{title}</Accordion.Control> : null}
                <Accordion.Panel>{children}</Accordion.Panel>
            </Accordion.Item>
        </Accordion>
    );
}

export const useSettingsAccordionStyles = createStyles((theme, { allowNestedSections }: { allowNestedSections?: boolean }) => ({
    accordion: {
        '.mantine-Accordion-item,.mantine-Accordion-item[data-active=true]': {
            background: theme.colors.gray[2],
            border: `solid 1px ${theme.colors.gray[3]}`,
            borderRadius: theme.radius.md,
            padding: 8,
        },
        '.mantine-Accordion-label': {
            padding: `0 10px`,
            fontSize: theme.fontSizes.sm,
        },
        '.mantine-Accordion-control': {
            marginTop: 4,
            padding: 0,
        },
        '.mantine-Accordion-panel': {
            padding: `8px 0`,
        },
        '.mantine-Accordion-content': {
            padding: `0`,
            display: 'flex',
            flexDirection: 'column',
            gap: theme.spacing.md,
            '.mantine-Accordion-panel, .mantine-Accordion-item': { display: allowNestedSections ? undefined : 'contents' },
            '.mantine-Accordion-control': { display: allowNestedSections ? undefined : 'none' },
        },
    },
}));
