import { Query } from '@apis/Invoices/model';
import { QueryResult } from '@apis/Resources';
import styled from '@emotion/styled';
import { ActionIcon, Box, Divider, Group, Space, Stack, Text, Tooltip, useMantineTheme } from '@mantine/core';
import { LineItemCompactToken } from '@root/Components/Filter/Design';
import { DataFilterModel, DataFilters } from '@root/Components/Filter/Filters';
import { IFieldInfoProvider, SchemaFieldNameProvider } from '@root/Components/Filter/Services';
import { useFilterValueProviders } from '@root/Components/Invoices/InvoiceFilterComponents';
import { FieldPicker } from '@root/Components/Picker/FieldPicker';
import { AnchorButton, InfoIconTooltip } from '@root/Design/Primitives';
import { SettingsSectionItem, SettingsSectionItemHeader } from '@root/Design/Settings';
import { useEvent, useToggle } from '@root/Services/EventEmitter';
import { SchemaService, SchemaValueProvider } from '@root/Services/QueryExpr';
import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { ChevronDown, ChevronRight, Plus, Trash } from 'tabler-icons-react';
import { NamedFilterSetModel, IRulesModel, INamedFilterModel } from './NamedFilterSetModel';

interface ISectionDescription {
    title?: string;
    helpText?: ReactNode;
}

interface IDescriptions {
    inclusion?: ISectionDescription;
    exclusion?: ISectionDescription;
}

export interface FilterSetEditorProps {
    filterSet: NamedFilterSetModel;
    disabled?: boolean;
    datasource: <T>(query: Query) => Promise<QueryResult<T>>;
    schemaSvc: SchemaService;
    descriptions?: IDescriptions;
}
export function FilterSetEditor({ filterSet, disabled, schemaSvc, datasource, descriptions }: FilterSetEditorProps) {
    const valueProvider = useMemo(() => new SchemaValueProvider(schemaSvc, datasource), [schemaSvc]);
    const fieldInfoProvider = useMemo(() => new SchemaFieldNameProvider(schemaSvc), [schemaSvc]);
    const commonProps = { datasource, valueProvider, fieldInfoProvider, schemaSvc, disabled, descriptions };

    return (
        <Stack>
            <FilterSection rulesModel={filterSet.inclusionRules} mode="inclusion" {...commonProps} />
            <FilterSection rulesModel={filterSet.exclusionRules} mode="exclusion" {...commonProps} />
        </Stack>
    );
}

interface FilterConfig {
    valueProvider: SchemaValueProvider;
    fieldInfoProvider: IFieldInfoProvider;
    schemaSvc: SchemaService;
    disabled?: boolean;
    datasource: <T>(query: Query) => Promise<QueryResult<T>>;
    descriptions?: IDescriptions;
}
interface FilterSectionProps extends FilterConfig {
    rulesModel: IRulesModel;
    mode: 'inclusion' | 'exclusion';
    helpText?: string;
}

const getUfText = (descriptions: IDescriptions | undefined, mode: 'inclusion' | 'exclusion') => {
    const description = mode === 'exclusion' ? descriptions?.exclusion : descriptions?.inclusion;
    const ufType = mode === 'inclusion' ? 'Included' : 'Excluded';
    const typeTitle = ufType === 'Included' ? 'Inclusion' : 'Exclusion';
    return {
        title: description?.title ?? `${typeTitle} conditions`,
        info: description?.helpText,
    };
};

function FilterSection({ rulesModel, ...props }: FilterSectionProps) {
    const theme = useMantineTheme();
    useEvent(rulesModel.onSetChanged);
    const { title, info } = getUfText(props.descriptions, props.mode);
    return (
        <RuleSectionEl>
            <Group px={4} position="apart">
                <Text size="sm" pl={8}>
                    {title}
                </Text>
                {!info ? null : <InfoIconTooltip label={info} />}
            </Group>
            <Space h={4} />
            <Stack>
                {rulesModel.items.map((item, idx) => (
                    <FilterSectionItem
                        key={idx}
                        disabled={props.disabled}
                        namedFilter={item}
                        schemaSvc={props.schemaSvc}
                        fieldInfoProvider={props.fieldInfoProvider}
                        valueProvider={props.valueProvider}
                        datasource={props.datasource}
                    />
                ))}
                {!props.disabled && (
                    <SettingsSectionItem anchor onClick={() => rulesModel.addItem()}>
                        <AnchorButton icon={<Plus size={14} />} size="xs" text="Add condition group" onClick={() => {}} />
                    </SettingsSectionItem>
                )}
            </Stack>
        </RuleSectionEl>
    );
}

const RuleSectionEl = styled.div`
    border: solid 1px ${(p) => p.theme.colors.gray[3]};
    border-radius: ${(p) => p.theme.radius.md}px;
    background: ${(p) => p.theme.colors.gray[2]};
    padding: 8px;
`;

function FilterSectionItem({ namedFilter, ...props }: { namedFilter: INamedFilterModel } & FilterConfig) {
    const { getFilters, setFilters, raiseFilterChanged } = namedFilter;
    const [filterModel, setFilterModel] = useState<DataFilterModel>();
    const [collapsed, { toggle }] = useToggle(false);
    useEffect(() => {
        const listener = filterModel?.filtersChanged.listen(() => {
            setFilters(filterModel.getFilters());
        });
        return () => listener?.dispose();
    }, [filterModel]);
    const addFilter = useCallback(() => {
        filterModel?.addEmptyFilter(true);
    }, [filterModel]);

    const defaultName = `Condition Group ${namedFilter.getIndex() + 1}`;
    const name = namedFilter.getName() || defaultName;
    const ExpanderIcon = collapsed ? ChevronRight : ChevronDown;
    const valueRendererProvider = useFilterValueProviders(props.datasource);

    return (
        <SettingsSectionItem>
            <SettingsSectionItemHeader>
                <ActionIcon onClick={toggle} variant="transparent">
                    <ExpanderIcon size={16} />
                </ActionIcon>
                <Text size="sm">{name}</Text>
                <Space w="lg" sx={{ flex: 1 }} />
                <Tooltip label="Remove condition" disabled={props.disabled}>
                    <ActionIcon className="--hover-visible" onClick={namedFilter.remove} variant="transparent">
                        <Trash size={16} />
                    </ActionIcon>
                </Tooltip>
            </SettingsSectionItemHeader>
            <Divider />
            <Box px={12} py={12} hidden={collapsed}>
                <DataFilters
                    filters={getFilters()}
                    disabled={props.disabled}
                    onChange={setFilters}
                    valueProvider={props.valueProvider}
                    fieldInfoProvider={props.fieldInfoProvider}
                    valueRendererProvider={valueRendererProvider}
                    renderFieldPicker={(select) => (
                        <FieldPicker mode="single" selections={[]} schema={props.schemaSvc} onChange={([f]) => select(f.path)} />
                    )}
                    onModelLoaded={setFilterModel}
                    dataFiltersAsLineItem
                    lineItemCompact
                />
                <Space h={4} />
                <LineItemCompactToken styles={{ width: '100%' }} onClick={addFilter}>
                    <Group spacing={4}>
                        <Plus size={16} /> Add Condition
                    </Group>
                </LineItemCompactToken>
            </Box>
        </SettingsSectionItem>
    );
}
