import { postResourcesQuery } from '@apis/Resources';
import { PageContent } from '@root/Design/Layout';
import { endpoint } from '@root/Services/Router/EndpointRegistry';
import { ReactNode, useEffect, useMemo, useState } from 'react';
import { HomeShell } from './Common';
import { CustomizableDashboard, DashboardAddOption } from '@root/Components/DashboardLayout/CustomizableDashboard';
import { Box, Button, Card, Group, Overlay, Space, Text, ThemeIcon, Title, useMantineTheme } from '@mantine/core';
import { Query } from '@apis/Resources/model';
import { ChartDashboardItem } from '@root/Components/DashboardLayout/ChartDashboardItem';
import { IDashboardConfig, IDashboardItemType } from '@root/Components/DashboardLayout/Models';
import { QueryDatasource } from '@root/Services/Query/QueryDatasource';
import { useCompany } from '@root/Components/Router/CompanyContent';
import { useDi } from '@root/Services/DI';
import { ResourceQueryService, ResourceSchemaProvider } from '@root/Services/Resources/ResourceService';
import { useLink } from '@root/Services/Router/Router';
import { useNav } from '@root/Services/NavigationService';
import { PlugConnected, Lock } from 'tabler-icons-react';
import { PieChart, PieChartSettings } from '@root/Components/Charts/PieChart';
import { CustomBarChartSettings } from '@root/Components/Charts/BarChart';
import { IRouteMeta, IRouteMetaToken } from '@root/Services/Router/BasicRouteLoader';
import { LineChart, LineChartSettings } from '@root/Components/Charts/LineChart';
import { InitialSyncCheck } from '@root/Components/Resources/IntialSyncCheck';
import { PlatformService } from '@root/Services/PlatformService';
import { defaultAws, defaultAzure } from './DashboardConfigs';
import { CustomColors, theme } from '@root/Design/Themes';
import { CompanyFeatureService } from '@root/Services/Customers/CompanyFeatureService';
import { useAsync } from '@react-hookz/web';
import { ResourceQueryDatasourceFactory } from '@root/Services/Resources/ResourceQueryDatasourceFactory';

export function HomeDashboardContent({ dashboard }: { dashboard?: string }) {
    const route = useDi(IRouteMetaToken) as IRouteMeta;
    const nav = useNav();
    const company = useCompany()!;
    if (company.Type === 'StrategicPartner') {
        nav.goto('../cloud-intelligence/customer-requests');
    } else if (company.Type === 'Msp') {
        nav.goto('../msp/my-companies');
    } else if (company.Type === 'Support' || company.Type === 'PlatformSupport') {
        nav.goto('../read-only/support-companies');
    } else if (route.endpointInfo?.name?.toLowerCase() === 'dashboard') {
        nav.goto('../landing');
    }

    const dsFactory = useDi(ResourceQueryDatasourceFactory);
    const resourceApi = useDi(ResourceQueryService);
    const [{ result: datasource }, { execute: loadDatasource }] = useAsync(() =>
        dsFactory.getDatasource((q) => resourceApi.query(q, company.Id ?? 0))
    );

    const platformSvc = useDi(PlatformService);
    const [connected, setConnected] = useState<null | boolean>();
    const [hasSubscription, setHasSubscription] = useState<null | boolean>();
    const [loading, setLoading] = useState(true);
    const companyFeatureSvc = useDi(CompanyFeatureService);
    useEffect(() => {
        (async () => {
            await platformSvc.init();
            const accounts = platformSvc.getPlatforms();
            setConnected(accounts.size > 0);
            const companyFeatures = await companyFeatureSvc.getFeatures(company);
            setHasSubscription(companyFeatures.checkFeature('Compliance', 'Tag Dashboard'));
            setLoading(false);
        })();
        loadDatasource();
    }, [company]);

    const dashboardDatasource = useMemo(() => (!connected || !datasource ? [] : ([datasource] as QueryDatasource[])), [connected, datasource]);

    const itemTypes = useMemo(() => {
        const result = [ChartDashboardItem.itemType] as IDashboardItemType[];
        return result;
    }, []);
    const defaultConfig = useMemo(() => {
        const result = {
            name: 'Default Dashboard',
            layout: [],
        } as IDashboardConfig;
        if (connected) {
            result.layout.push(...(platformSvc.hasPlatform('Azure') ? defaultAzure : defaultAws));
        }
        return result;
    }, [connected]);
    const addOptions = useMemo(
        () =>
            [
                {
                    label: 'New KPI',
                    category: 'blank',
                    settings: {
                        type: 'chart',
                        chartType: 'kpi',
                        groups: [],
                        values: [{ Alias: 'Resources', Expr: { Operation: 'count', Operands: [] } }],
                        settings: { labels: [''], format: [undefined] },
                        datasourceName: 'resources',
                        title: 'Resources',
                    },
                },
                {
                    label: 'New Table',
                    category: 'blank',
                    settings: {
                        type: 'chart',
                        chartType: 'grid',
                        groups: [],
                        values: [],
                        settings: {
                            state: {
                                columns: [
                                    { id: 'TagKey', width: 200 },
                                    { id: 'Resources', width: 100 },
                                ],
                                filters: [],
                                sort: [],
                            },
                            columns: [
                                { type: 'string', id: 'TagKey', select: { Alias: 'Tag Key', Expr: { Field: 'Tags.Key' } } },
                                { type: 'number', id: 'Resources', select: { Alias: 'Resources', Expr: { Operation: 'count' } } },
                            ],
                        },
                        datasourceName: 'resources',
                        title: 'Resources Count by Tag',
                    },
                },
                {
                    label: 'New Pie',
                    category: 'blank',
                    settings: {
                        type: 'chart',
                        chartType: 'pie',
                        groups: [
                            platformSvc.hasPlatform('Azure')
                                ? { Expr: { Field: 'ResourceType' }, Alias: 'Resource Type' }
                                : { Alias: 'Region', Expr: { Field: 'Region' } },
                        ],
                        values: [{ Alias: 'Count', Expr: { Operation: 'count' } }],
                        settings: { angle: 'large', margin: { top: 30, bottom: 30, left: 20, right: 20 }, threshold: 3 } as PieChartSettings,
                        datasourceName: 'resources',
                        title: 'Resources per Region',
                    },
                },
                {
                    label: 'New Bar',
                    category: 'blank',
                    settings: {
                        type: 'chart',
                        chartType: 'bar',
                        groups: [
                            platformSvc.hasPlatform('Azure')
                                ? { Expr: { Field: 'ResourceType' }, Alias: 'Resource Type' }
                                : { Alias: 'Region', Expr: { Field: 'Region' } },
                        ],
                        values: [{ Alias: 'Count', Expr: { Operation: 'count' } }],
                        settings: { margin: { top: 30, bottom: 70, left: 70, right: 20 } } as CustomBarChartSettings,
                        datasourceName: 'resources',
                        title: 'Resources per Region',
                    },
                },
                {
                    label: 'New Line',
                    category: 'blank',
                    settings: {
                        type: 'chart',
                        chartType: 'line',
                        groups: [
                            platformSvc.hasPlatform('Azure')
                                ? { Expr: { Field: 'Microsoft_Storage/storageAccounts.creationTime' }, Alias: 'Resource Type' }
                                : { Alias: 'CreationDate', Expr: { Field: 'CreationDate' } },
                        ],
                        values: [{ Alias: 'Count', Expr: { Operation: 'count' } }],
                        settings: { interval: 'month', margin: { top: 30, bottom: 70, left: 70, right: 20 } } as LineChartSettings,
                        datasourceName: 'resources',
                        title: 'Resources Added Over Time',
                    },
                },
            ] as DashboardAddOption[],
        [connected]
    );
    const loadDashboard = (id?: number) => {
        nav.mergeParams({ dashboard: id?.toString() ?? '' });
    };

    return (
        <HomeShell>
            <PageContent>
                {loading || connected === null ? null : connected === false && hasSubscription ? (
                    <Box p="xl">
                        <GetConnected />
                        <Space h={30} />
                        <Group spacing="lg" position="center" align="stretch" noWrap>
                            <DummyPie />
                            <DummyLine />
                        </Group>
                    </Box>
                ) : !loading && !hasSubscription ? (
                    <Box p="xl">
                        <Subscribe />
                        <Space h={30} />
                        <Group spacing="lg" position="center" align="stretch" noWrap>
                            <DummyPie />
                            <DummyLine />
                        </Group>
                    </Box>
                ) : (
                    <>
                        <CustomizableDashboard
                            dashboardKey="home15"
                            addOptions={addOptions}
                            allowAdd={connected}
                            allowFilter={connected}
                            allowLoader={connected}
                            datasources={dashboardDatasource}
                            itemTypes={itemTypes}
                            id={(dashboard && parseInt(dashboard)) || undefined}
                            onIdChanged={loadDashboard}
                            defaultConfig={defaultConfig}
                        />
                    </>
                )}
            </PageContent>
        </HomeShell>
    );
}
export function HomeDashboard() {
    const nav = useNav();
    const { dashboard } = nav.getData('dashboard');
    return <InitialSyncCheck>{() => <HomeDashboardContent dashboard={dashboard} />}</InitialSyncCheck>;
}
endpoint('tag-dashboard', HomeDashboard, 'Tag Manager');

function GetConnected() {
    return (
        <ActionPrompt
            title="Connect your cloud account"
            description="Use the CloudSaver platform Connection Wizard to automate setup and connection."
            url="connections-wizard"
            linkType="descend"
            icon={<PlugConnected />}
            buttonText="Get started"
        />
    );
}

function Subscribe() {
    return (
        <ActionPrompt
            title="Subscribe to Tag Manager"
            description="Select a subscription plan to continue using Tag Manager."
            url="settings/subscription"
            linkType="root"
            icon={<Lock color={theme.colors?.primary?.[8] as CustomColors} />}
            buttonText="Subscribe Now"
        />
    );
}

function DummyPie() {
    return (
        <Card radius="lg" withBorder sx={{ height: 400, width: '50%' }}>
            <Overlay blur={8} />
            <PieChart
                groups={['x']}
                values={['y']}
                settings={{ noWrapper: true }}
                data={[
                    { x: 'Sample 1', y: 30 },
                    { x: 'Sample 2', y: 20 },
                    { x: 'Sample 3', y: 10 },
                    { x: 'Sample 4', y: 10 },
                ]}
            />
        </Card>
    );
}

function DummyLine() {
    return (
        <Card radius="lg" withBorder sx={{ height: 400, width: '50%' }}>
            <Overlay blur={8} />
            <LineChart
                groups={['x']}
                values={['y']}
                settings={{ noWrapper: true }}
                data={[
                    { x: 'Sample 1', y: 30 },
                    { x: 'Sample 2', y: 8 },
                    { x: 'Sample 3', y: 11 },
                    { x: 'Sample 4', y: 9 },
                    { x: 'Sample 5', y: 12 },
                    { x: 'Sample 6', y: 15 },
                    { x: 'Sample 7', y: 7 },
                ]}
            />
        </Card>
    );
}

export function ActionPrompt({
    title,
    description,
    icon,
    url,
    linkType,
    buttonText,
}: {
    title: string;
    description: string;
    icon: ReactNode;
    url: string;
    linkType: string;
    buttonText: string;
}) {
    const theme = useMantineTheme();
    const link = useLink();
    const { getDescendUnencodedUrl, getRootUrl } = useNav();
    return (
        <Card
            radius="lg"
            sx={{
                background: theme.colors.primary[0],
                borderColor: theme.colors?.primary?.[6] as CustomColors,
                borderWidth: 1,
                borderStyle: 'solid',
            }}
            shadow="xl"
            color={theme.colors?.primary?.[6] as CustomColors}
        >
            <Group position="apart" p="lg" align="start">
                <Group align="start" spacing="md">
                    <ThemeIcon
                        sx={{
                            background: theme.colors?.primary?.[2] as CustomColors,
                            color: theme.colors?.primary?.[6] as CustomColors,
                        }}
                        radius="xl"
                        p={8}
                        size="xl"
                        mt={3}
                    >
                        {icon}
                    </ThemeIcon>
                    <Box>
                        <Title sx={{ color: theme.colors.primary[9] }}>{title}</Title>
                        <Space h="xs" />
                        <Text sx={{ color: theme.colors.primary[8] }}>{description}</Text>
                    </Box>
                </Group>
                {linkType == 'descend' ? (
                    <>
                        <Button size="lg" component="a" {...link(getDescendUnencodedUrl(url))}>
                            {buttonText}
                        </Button>
                    </>
                ) : (
                    <>
                        <Button size="lg" component="a" {...link(getRootUrl(url))}>
                            {buttonText}
                        </Button>
                    </>
                )}
            </Group>
        </Card>
    );
}
