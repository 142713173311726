import { UserDefinedNotificationRecipient, UserDefinedNotificationRecipientMessageTypeItem } from '@apis/Notification/model';
import styled from '@emotion/styled';
import { ActionIcon, Divider, Group, Switch, Tooltip, useMantineTheme } from '@mantine/core';
import { InfoIconTooltip } from '@root/Design/Primitives';
import {
    SettingsInteractiveRow,
    SettingsLabel,
    SettingsSection,
    SettingsSectionItem,
    SettingsSectionItemBody,
    SettingsSectionItemHeader,
    SettingsSectionItemHeaderLabel,
} from '@root/Design/Settings';
import { UserRecord } from '@root/Services/Customers/CompanyUserService';
import { EventEmitter, useEvent } from '@root/Services/EventEmitter';
import { MouseEventHandler, ReactNode, useCallback, useMemo } from 'react';
import { ListCheck, Mail, Plus } from 'tabler-icons-react';
import { useCurrentUser, UserPicker } from '../../Users/UserPicker';

type MessageType = UserDefinedNotificationRecipientMessageTypeItem;
const getAllMessageTypes = () => ['InApp', 'Email'] as MessageType[];
type SelectableMessageType = { value: MessageType; label: string; icon: ReactNode; selected: boolean; toggle: () => void };

interface IDeliveryOptionsProps {
    recipients: UserDefinedNotificationRecipient[];
    typePrefId: number | undefined;
    ownerUserId: number;
}
export function DeliveryOptions(props: IDeliveryOptionsProps) {
    const { ownerUserId, recipients, typePrefId } = props;
    const currentUser = useCurrentUser();
    const isCurrentUserOwner = currentUser?.Id === ownerUserId;

    const currentUserRecipient = useMemo(() => {
        let currentUserRecipient = recipients.find((r) => r.UserId === currentUser?.Id);
        if (!currentUserRecipient) {
            currentUserRecipient = {
                NotificationTypePreferenceId: typePrefId,
                UserId: currentUser?.Id,
                MessageType: getAllMessageTypes(),
                Type: 'SingleInclude',
            } as UserDefinedNotificationRecipient;
            recipients.push(currentUserRecipient);
        }
        return currentUserRecipient;
    }, [recipients]);

    return (
        <SettingsSection title="Delivery Options">
            <UserPreferenceSection {...props} currentUserRecipient={currentUserRecipient} />
            {!isCurrentUserOwner ? null : <OtherRecipientsSection {...props} currentUserRecipient={currentUserRecipient} />}
        </SettingsSection>
    );
}

interface IDeliveryOptionsSectionProps extends IDeliveryOptionsProps {
    currentUserRecipient: UserDefinedNotificationRecipient;
}

function UserPreferenceSection(props: IDeliveryOptionsSectionProps) {
    const { colors } = useMantineTheme();
    const { currentUserRecipient } = props;
    const selectionChanged = useMemo(() => EventEmitter.empty(), []);

    const { availableMessageTypes, changedEvent } = useMessageTypePickerController(currentUserRecipient, selectionChanged.emit);
    useEvent(changedEvent);

    return (
        <>
            <SettingsSectionItem>
                <SettingsSectionItemHeader>
                    <SettingsSectionItemHeaderLabel>Preference</SettingsSectionItemHeaderLabel>
                    <InfoIconTooltip label="Choose how you'd like to receive this notification." />
                </SettingsSectionItemHeader>
                <SettingsSectionItemBody>
                    {availableMessageTypes.map(({ icon, label, selected, toggle }, i) => (
                        <SettingsInteractiveRow key={i} onClick={toggle}>
                            <SettingsLabel strokeWidth={selected ? 2 : 1} iconColor={selected ? colors.primary[5] : undefined} icon={icon}>
                                {label}
                            </SettingsLabel>
                            <Switch
                                readOnly
                                checked={selected}
                                sx={{
                                    transform: 'rotate(180deg)',
                                    input: { cursor: 'pointer' },
                                }}
                                color="primary.5"
                                size="xs"
                                radius={3}
                                onChange={() => {}}
                            />
                        </SettingsInteractiveRow>
                    ))}
                </SettingsSectionItemBody>
            </SettingsSectionItem>
        </>
    );
}

function OtherRecipientsSection(props: IDeliveryOptionsSectionProps) {
    const { currentUserRecipient, recipients, typePrefId } = props;
    const selectionChanged = useMemo(() => EventEmitter.empty(), []);

    const { onAdd, onDelete, noop, selectedUserIds, userPrefLookup } = useMemo(() => {
        const userPrefLookup = new Map(recipients.map((r) => [r.UserId, r] as [number, UserDefinedNotificationRecipient]));

        return {
            userPrefLookup,
            noop: () => {},
            onAdd: (userId: number) => {
                if (!userPrefLookup.has(userId)) {
                    userPrefLookup.set(userId, { NotificationTypePreferenceId: typePrefId ?? 0, UserId: userId, MessageType: getAllMessageTypes() });
                    recipients.push(userPrefLookup.get(userId)!);
                    selectionChanged.emit();
                }
            },
            onDelete: (userId: number) => {
                const recipient = userPrefLookup.get(userId);
                if (recipient) {
                    recipients.splice(recipients.indexOf(recipient), 1);
                    userPrefLookup.delete(userId);
                    selectionChanged.emit();
                }
            },
            get selectedUserIds() {
                return [...userPrefLookup.values()].filter((r) => !!r.MessageType?.length).map((r) => r.UserId!);
            },
        };
    }, [recipients]);

    const OtherRecipientItem = useMemo(() => {
        return function OtherRecipientItem({ user }: { user: UserRecord }) {
            useEvent(selectionChanged);
            const recipient = userPrefLookup.get(user.Id);
            const handleAdd = useCallback(() => onAdd(user.Id), [user.Id]);
            const handleDelete = useCallback(() => onDelete(user.Id), [user.Id]);
            return (
                <>
                    {!recipient ? (
                        <OtherRecipientAdd onClick={handleAdd} />
                    ) : (
                        <OtherRecipientPreference recipient={recipient} onEmpty={handleDelete} />
                    )}
                </>
            );
        };
    }, [selectionChanged, userPrefLookup]);
    const renderOthersPreferences = useCallback((user: UserRecord) => <OtherRecipientItem user={user} />, [OtherRecipientItem]);

    return (
        <SettingsSectionItem>
            <SettingsSectionItemHeader>
                <SettingsSectionItemHeaderLabel>Other Recipients</SettingsSectionItemHeaderLabel>
                <InfoIconTooltip label="Choose other the users who should receive this notification." />
            </SettingsSectionItemHeader>
            <SettingsSectionItemBody style={{ padding: 0 }}>
                <PickerStyles>
                    <UserPicker
                        excludeCurrentUser
                        selectedUserIds={selectedUserIds}
                        onChange={noop}
                        controlled
                        itemRightSection={renderOthersPreferences}
                        height={250}
                        disableAutoFocus
                    />
                </PickerStyles>
            </SettingsSectionItemBody>
        </SettingsSectionItem>
    );
}

const PickerStyles = styled.div`
    display: content;
    --picker-item-selected-bg: transparent;
    --picker-item-hover-bg: ${(p) => p.theme.colors.primary[2]};
    --picker-item-hover-selected-bg: ${(p) => p.theme.colors.primary[2]};
    .picker-item {
    }
    .add-other-item {
        width: 0;
        overflow: hidden;
    }
    .picker-item:hover {
        .add-other-item {
            width: auto;
        }
    }
    .picker-item-text {
        padding-left: 0;
    }
    .picker-item-checkbox {
        width: 0;
        display: none;
    }
`;

function OtherRecipientPreference(props: { recipient: UserDefinedNotificationRecipient; onEmpty: () => void }) {
    const { recipient, onEmpty } = props;
    const handleContainerClick: MouseEventHandler<HTMLDivElement> = useCallback((e) => {
        e.stopPropagation();
        e.preventDefault();
    }, []);

    const onChange = useCallback(() => {
        if (!recipient.MessageType?.length) {
            onEmpty();
        }
    }, [onEmpty, recipient]);
    const { changedEvent, availableMessageTypes } = useMessageTypePickerController(recipient, onChange);
    useEvent(changedEvent);

    return (
        <>
            <Divider orientation="vertical" />
            <Group noWrap onClick={handleContainerClick}>
                {availableMessageTypes.map(({ icon, label, selected, toggle }, i) => (
                    <Tooltip withinPortal key={i} label={`${label}: ${selected ? 'Enabled' : 'Disabled'}`}>
                        <ActionIcon component={PreferenceIcon} selected={selected} onClick={toggle}>
                            {icon}
                        </ActionIcon>
                    </Tooltip>
                ))}
            </Group>
        </>
    );
}
function OtherRecipientAdd({ onClick }: { onClick?: () => void }) {
    return (
        <>
            <Divider orientation="vertical" />
            <Group noWrap className="add-other-item">
                <Tooltip withinPortal label="Add Recipient">
                    <ActionIcon component={PreferenceIcon} selected onClick={onClick}>
                        <Plus />
                    </ActionIcon>
                </Tooltip>
            </Group>
        </>
    );
}

const PreferenceIcon = styled.div<{ selected: boolean }>`
    color: ${(p) => (p.selected ? p.theme.colors.primary[5] : p.theme.colors.gray[6])};
    svg {
        stroke-width: ${(p) => (p.selected ? 2 : 1)}px;
        width: 22px;
        height: 22px;
    }
`;

function useMessageTypePickerController(recipient: UserDefinedNotificationRecipient, onChange?: () => void) {
    const result = useMemo(() => {
        const changedEvent = EventEmitter.empty();
        const types = (recipient.MessageType ??= []);
        const baseTypes = [
            {
                value: 'InApp' as const,
                label: 'In-App Notifications',
                icon: <ListCheck />,
            },
            {
                value: 'Email' as const,
                icon: <Mail />,
                label: 'Email Notifications',
            },
        ];

        return {
            changedEvent,
            availableMessageTypes: baseTypes.map((item) => ({
                ...item,
                get selected() {
                    return types.includes(item.value);
                },
                toggle: () => {
                    const index = types.findIndex((t) => t === item.value);
                    if (index >= 0) {
                        types.splice(index, 1);
                    } else {
                        types.push(item.value);
                    }
                    onChange?.();
                    changedEvent.emit();
                },
            })) as Array<SelectableMessageType>,
        };
    }, [recipient, onChange]);

    return result;
}
