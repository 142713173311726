import { ActionIcon, Box, Button, Center, Space, Text, useMantineTheme } from '@mantine/core';
import { InvoiceDetail } from '@apis/Customers/model';
import { getInvoiceGetInvoices, getInvoiceDownloadInvoice } from '@apis/Customers';
import { DataGrid } from '@root/Components/DataGrid';
import { DataColumnConfig } from '@root/Components/DataGrid/Models';
import { colorPalette } from '@root/Design/Themes';
import { useDi, useDiComponent } from '@root/Services/DI';
import { FormatService } from '@root/Services/FormatService';
import { useState, useEffect, useMemo } from 'react';
import { BillingInvoice } from '@apis/Invoices/model';
import { getBillingInvoiceGetAllBillingInvoices, getBillingInvoiceGetBillingInvoices } from '@apis/Invoices';
import { PageContent, PaneledPage } from '@root/Design/Layout';
import { endpoint } from '@root/Services/Router/EndpointRegistry';
import { useModals } from '@mantine/modals';
import { useForm } from '@mantine/form';

export const MyBillingInvoices = () => {
    const formatSvc = useDi(FormatService);
    const [invoices, setInvoices] = useState([] as BillingInvoice[]);
    useEffect(() => {
        getBillingInvoiceGetAllBillingInvoices().then((data) => {
            setInvoices(data);
        });
    }, [] as BillingInvoice[]);

    const columns = useMemo(
        () =>
            [
                {
                    id: 'CompanyId',
                    accessor: (item) => item.CompanyId,
                    header: 'CompanyId',
                    align: 'left',
                    type: 'number',
                    defaultWidth: 165,
                    sortField: 'CompanyId',
                    filter: {
                        filterField: 'CompanyId',
                        filterType: 'number',
                        name: 'CompanyId',
                    },
                },
                {
                    id: 'Status',
                    accessor: (item) => item.Status,
                    header: 'Status',
                    align: 'left',
                    type: 'string',
                    defaultWidth: 100,
                    sortField: 'Status',
                    filter: {
                        filterField: 'Status',
                        filterType: 'string',
                        name: 'Status',
                    },
                },
                {
                    id: 'Year',
                    accessor: (item) => item.Year,
                    header: 'Year',
                    align: 'left',
                    type: 'number',
                    defaultWidth: 100,
                    sortField: 'Year',
                    filter: {
                        filterField: 'Year',
                        filterType: 'number',
                        name: 'Year',
                    },
                },
                {
                    id: 'Month',
                    accessor: (item) => item.Month,
                    header: 'Month',
                    align: 'left',
                    type: 'number',
                    defaultWidth: 100,
                    sortField: 'Month',
                    filter: {
                        filterField: 'Month',
                        filterType: 'number',
                        name: 'Month',
                    },
                },
                {
                    id: 'BillingTerm',
                    accessor: (item) => item.BillingTerm,
                    header: 'Billing Term',
                    align: 'left',
                    type: 'string',
                    defaultWidth: 150,
                    sortField: 'BillingTerm',
                    filter: {
                        filterField: 'BillingTerm',
                        filterType: 'string',
                        name: 'Billing Term',
                    },
                },
            ] as DataColumnConfig<BillingInvoice>[],
        []
    );

    const modals = useModals();
    const DiContainer = useDiComponent();

    return (
        <PageContent>
            <PaneledPage>
                <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', width: '95%', margin: '10px' }}>
                    <Box sx={{ flex: 1, minHeight: 0 }}>{invoices.length == 0 ? null : <DataGrid dataSource={invoices} columns={columns} />}</Box>
                </Box>
            </PaneledPage>
        </PageContent>
    );
};
endpoint('billing-invoices', MyBillingInvoices, 'Billing Invoices');
