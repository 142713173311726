import { useEffect, useState } from 'react';
import { Tabs, Group, Button, Title, Divider, Box, CloseButton, Text } from '@mantine/core';
import { getNewSubscriptionGetCompanyInfo } from '@apis/Customers';
import { Company, CompanyInfo } from '@apis/Customers/model';
import styled from '@emotion/styled';
import { useDi, useDiContainer } from '@root/Services/DI';
import { CompanyAdministrationPanelModel } from './CompanyAdministrationPanelModel';
import { useEvent } from '@root/Services/EventEmitter';
import { useCompany } from '@root/Components/Router/CompanyContent';
import { confirmAction } from './ConfirmActionDialog';
import { MspService } from '@root/Services/MspService';
import { useAuthZValues } from '@root/Services/AuthorizationService';
import { AdminCompanyInfo } from './AdminCompanyInfo';
import { AdminConnections } from './AdminConnections';
import { AdminUsers } from './AdminUsers';
import { AdminMarketplaceOffers } from './AdminMarketplaceOffers';
import { AdminAppAccess } from './AdminAppAccess';
import { FormatService } from '@root/Services/FormatService';
import { CompanyContextService } from '@root/Services/Customers/CompanyContext';
import { AdminTaxRates } from './AdminTaxRates';
import { BillingInvoicesInfo } from '@root/Components/BillingInvoices/BillingInvoicesInfo';

export interface AdminSidePanelProps {
    toggleSidePanel: (showSidePanel: boolean) => void;
    toggleCompanyDeleted: (companyDeleted: boolean) => void;
    parentCompany: Company;
    defaultTab?: string;
}

export const AdminSidePanel = (props: AdminSidePanelProps) => {
    const contextSvc = useDi(CompanyContextService);
    const [compInfo, setCompInfo] = useState<CompanyInfo>({} as CompanyInfo);
    const [hasChanges, setHasChanges] = useState<boolean>(false);
    const [isSubscriptionUpdated, setIsSubscriptionUpdated] = useState<boolean>(false);
    const [activeTab, setActiveTab] = useState<string | null>(props?.defaultTab ?? 'company');
    const { canManageOffers } = useAuthZValues({ canManageOffers: { MarketplaceOffers: 'Manage' } });
    const container = useDiContainer();
    const model = container.resolve(CompanyAdministrationPanelModel);
    useEvent(model.isModifiedChanged, (value) => setHasChanges(value));
    useEvent(model.isSubscriptionUpdated, (value) => setIsSubscriptionUpdated(value));

    const company = useCompany();
    const mspSvc = useDi(MspService);
    const fmtSvc = useDi(FormatService);
    const parentCompany = contextSvc.parentCompany;
    const [isSupport, setIsSupport] = useState<boolean>(parentCompany?.Type === 'Support');
    const [isPlatformSupport, setIsPlatformSupport] = useState<boolean>(parentCompany?.Type === 'PlatformSupport');
    const companyType = company!.Type ?? 'Customer';
    const [isCustomer, setIsCustomer] = useState<boolean>(companyType === 'Customer');

    const refreshMspCompany = () => {
        mspSvc.notifyCompanyListChanged();
        loadCompanyInfo();
    };

    useEffect(() => {
        loadCompanyInfo();
    }, []);

    const loadCompanyInfo = () => {
        if (company) {
            getNewSubscriptionGetCompanyInfo()
                .then((info) => {
                    setCompInfo(info);
                })
                .catch((e) => {
                    //console.log(e);
                });
        }
    };

    const saveChanges = async () => {
        setHasChanges(false);
        model.saveChangesRequested.emit();
    };

    const resetChanges = () => {
        setHasChanges(false);
        model.discardChangesRequested.emit();
    };

    const handleTabChange = (tabName: string) => {
        setTimeout(() => window.dispatchEvent(new Event('resize')), 1);
        model.tabChanged.emit(tabName);
        if (tabName !== activeTab && hasChanges) {
            confirmTabChange(tabName);
        } else {
            setActiveTab(tabName);
        }
    };

    const confirmTabChange = (newTab: string) => {
        confirmAction({
            headerText: 'Change Tab',
            promptText: `You have unsaved changes. Are you sure you want to leave this tab?`,
            confirmButtonText: 'Leave This Tab',
            cancelButtonText: 'Stay On Tab',
            confirmAction: () => {
                resetChanges();
                setActiveTab(newTab);
            },
        });
    };

    return (
        <>
            <Box sx={{ display: 'flex', height: '100%', flexDirection: 'column', minHeight: 0 }}>
                <Group position="apart">
                    <Box px="lg" py="md">
                        <Title order={3}>{compInfo.CompanyName}</Title>
                        <Text size="sm">{fmtSvc.userFriendlyCamelCase(company?.Type ?? 'Customer')}</Text>
                    </Box>
                    <CloseButton
                        variant="transparent"
                        size="xl"
                        onClick={() => {
                            if (!hasChanges) {
                                props?.toggleSidePanel?.(false);
                            } else {
                                confirmAction({
                                    headerText: 'Close Panel',
                                    promptText: `You have unsaved changes. Are you sure you want to close?`,
                                    confirmButtonText: 'Discard Changes and Close',
                                    cancelButtonText: 'Save Changes',
                                    confirmAction: () => {
                                        props?.toggleSidePanel?.(false);
                                    },
                                });
                            }
                        }}
                    ></CloseButton>
                </Group>
                <Tabs
                    defaultValue="company"
                    value={activeTab}
                    onTabChange={handleTabChange}
                    sx={{ flex: 1, display: 'flex', height: '100%', flexDirection: 'column', minHeight: 0 }}
                >
                    <Tabs.List px="lg">
                        <Tabs.Tab value="company">Company</Tabs.Tab>
                        {isCustomer || isSupport || isPlatformSupport ? <Tabs.Tab value="connections">Connections</Tabs.Tab> : null}
                        <Tabs.Tab value="users">Users</Tabs.Tab>
                        {isPlatformSupport && canManageOffers ? <Tabs.Tab value="offers">Offers</Tabs.Tab> : null}
                        {isCustomer || isPlatformSupport ? <Tabs.Tab value="app_access">App Access</Tabs.Tab> : null}
                        {/** TODO: Permission for billing companies **/}
                        {isPlatformSupport ? <Tabs.Tab value="tax_rates">Tax Rates</Tabs.Tab> : null}
                        {isPlatformSupport ? <Tabs.Tab value="billing_invoices">Billing Invoices</Tabs.Tab> : null}
                    </Tabs.List>
                    <PanelContainer>
                        <Tabs.Panel value="company">
                            <AdminCompanyInfo
                                loadCompany={loadCompanyInfo}
                                toggleSidePanel={props.toggleSidePanel}
                                toggleCompanyDeleted={props.toggleCompanyDeleted}
                                statJobComplete={refreshMspCompany}
                                model={model}
                                companyInfo={compInfo}
                                parentCompany={props.parentCompany}
                            />
                        </Tabs.Panel>

                        <Tabs.Panel value="connections">
                            <AdminConnections model={model} companyInfo={compInfo} isSupport={isSupport || isPlatformSupport} />
                        </Tabs.Panel>

                        <Tabs.Panel value="users">
                            <AdminUsers model={model} companyInfo={compInfo} isSupport={isSupport || isPlatformSupport} />
                        </Tabs.Panel>

                        <Tabs.Panel value="offers">
                            <AdminMarketplaceOffers companyInfo={compInfo} />
                        </Tabs.Panel>

                        {/* <Tabs.Panel value="invoices">
                            <AdminInvoices />
                        </Tabs.Panel> */}

                        <Tabs.Panel value="app_access">
                            <AdminAppAccess model={model} companyInfo={compInfo} refreshMsp={refreshMspCompany}></AdminAppAccess>
                        </Tabs.Panel>

                        <Tabs.Panel value="tax_rates">
                            <AdminTaxRates model={model} companyInfo={compInfo} refreshMsp={refreshMspCompany}></AdminTaxRates>
                        </Tabs.Panel>

                        <Tabs.Panel value="billing_invoices">
                            <BillingInvoicesInfo model={model} companyInfo={compInfo}></BillingInvoicesInfo>
                        </Tabs.Panel>

                        {/* <Tabs.Panel value="credit_usage">
                            <AdminCreditUsage
                                companyInfo={compInfo}
                                loadCompany={loadCompanyInfo}
                                model={model}
                                toggleSidePanel={props.toggleSidePanel}
                            />
                        </Tabs.Panel> */}
                    </PanelContainer>
                </Tabs>
                {hasChanges && (
                    <>
                        <Divider />
                        <Group px="xl" py="md" position="left">
                            <Button color="primary" onClick={saveChanges}>
                                Save
                            </Button>
                            <Button variant="light" onClick={resetChanges}>
                                Discard Changes
                            </Button>
                        </Group>
                    </>
                )}
            </Box>
        </>
    );
};

const PanelContainer = styled.div`
    height: 100%;
    flex: 1;
    min-height: 0;
    > div {
        height: 100%;
        overflow: auto;
    }
`;
