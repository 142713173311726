import { QueryExpr } from '@apis/Resources';
import { ActionIcon, Group } from '@mantine/core';
import { InfoCircle, Plus } from 'tabler-icons-react';
import { ComponentPropsWithRef, useCallback, useState } from 'react';
import { TooltipWhite } from '@root/Design/Primitives';
import {
    SettingsSection,
    SettingsSectionItem,
    SettingsSectionItemBody,
    SettingsSectionItemHeader,
    SettingsSectionItemHeaderLabel,
} from '@root/Design/Settings';
import { DatasourceSchemaContext, withResolvedSchemaCtx } from '../Filter/DatasourceContext';
import { DataFilterModel, DataFilters } from '../Filter/Filters';
import { FieldPicker } from '../Picker/FieldPicker';
import { LineItemCompactToken } from '../Filter/Design';

interface IBaseEditorFilterProps {
    value: QueryExpr[];
    onChange: (filters: QueryExpr[]) => void;
    filterProps?: Partial<ComponentPropsWithRef<typeof DataFilters>>;
    schemaCtx: DatasourceSchemaContext;
}
export const FilterSettings = withResolvedSchemaCtx(function BaseEditorFilter(props: IBaseEditorFilterProps) {
    const { filterProps, onChange, schemaCtx, value } = props;
    const { schemaSvc, queryDescriptorSvc, valueProvider } = schemaCtx;
    const { fieldInfoProvider } = queryDescriptorSvc;
    const [filterModel, setFilterModel] = useState<DataFilterModel>();
    const fieldPickerRenderer = useCallback((select: (field: string) => void) => {
        return <FieldPicker mode="single" selections={[]} schema={schemaSvc} onChange={(fields) => select(fields[0].path)} />;
    }, []);
    const handleAddFilter = useCallback(() => filterModel?.addEmptyFilter(true, true), [filterModel]);

    return (
        <>
            <DataFilters
                dataFiltersAsLineItem
                lineItemCompact
                onModelLoaded={setFilterModel}
                fieldInfoProvider={fieldInfoProvider}
                align="end"
                renderFieldPicker={fieldPickerRenderer}
                filters={value}
                showErrors
                onChange={onChange}
                valueProvider={valueProvider}
                {...filterProps}
            />
            <LineItemCompactToken onClick={handleAddFilter}>
                <Group spacing={4}>
                    <Plus size={16} /> Add {filterModel?.filters.length ? 'another' : 'a'} filter
                </Group>
            </LineItemCompactToken>
        </>
    );
});

interface IFilterSettingsSectionItemProps extends IBaseEditorFilterProps {
    sectionItemTitle?: string;
    helpText?: string;
}
export function FilterSettingsSectionItem(props: IFilterSettingsSectionItemProps) {
    const { helpText, sectionItemTitle, ...rest } = props;

    return (
        <SettingsSectionItem>
            {!sectionItemTitle && !helpText ? null : (
                <SettingsSectionItemHeader>
                    <SettingsSectionItemHeaderLabel>{sectionItemTitle ?? 'Filters'}</SettingsSectionItemHeaderLabel>
                    {!helpText ? null : (
                        <TooltipWhite width={300} multiline label={helpText}>
                            <ActionIcon>
                                <InfoCircle size={16} />
                            </ActionIcon>
                        </TooltipWhite>
                    )}
                </SettingsSectionItemHeader>
            )}
            <SettingsSectionItemBody>
                <FilterSettings {...rest} />
            </SettingsSectionItemBody>
        </SettingsSectionItem>
    );
}

interface IFilterSettingsSectionProps extends IFilterSettingsSectionItemProps {
    sectionTitle?: string;
}
export function FilterSettingsSection(props: IFilterSettingsSectionProps) {
    const { sectionTitle, ...rest } = props;

    return (
        <SettingsSection title={sectionTitle ?? 'Filters'}>
            <FilterSettingsSectionItem {...rest} />
        </SettingsSection>
    );
}
