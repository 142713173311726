import { QueryExpr, QueryResult } from '@apis/Resources';
import {
    Box,
    Card,
    Center,
    CloseButton,
    Divider,
    Drawer,
    Group,
    Select,
    Space,
    Stack,
    Title,
    useMantineTheme,
    Text,
    Button,
    Badge,
} from '@mantine/core';
import { BarChart, CustomBarChartSettings } from '@root/Components/Charts/BarChart';
import { PageBody, PageContent, PaneledPage, PanelHeader } from '@root/Design/Layout';
import { useDi, useDiMemo } from '@root/Services/DI';
import { FormatService } from '@root/Services/FormatService';
import { InvoiceApiService } from '@root/Services/Invoices/InvoiceApiService';
import { IBaseInvoiceRecord } from '@root/Services/Invoices/InvoiceSchemaService';
import { exprBuilder, groupExprs, ISearchBuilder, queryBuilder } from '@root/Services/QueryExpr';
import { endpoint } from '@root/Services/Router/EndpointRegistry';
import { addMonths, endOfMonth, startOfMonth } from 'date-fns';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Search } from 'tabler-icons-react';
import { useDisclosure } from '@mantine/hooks';
import { ContextButton } from '@root/Components/ContextButton/ContextButton';
import { DataGrid } from '@root/Components/DataGrid';
import { ColumnConfig, DataGridState, GridGroupByState } from '@root/Components/DataGrid/Models';
import { FillerSwitch } from '@root/Design/Filler';
import { DailyInvoiceGrid } from '@root/Components/Invoices/DailyInvoiceGrid';
import { Score } from '@root/Design/Primitives';
import { IncreaseIndicator } from '@root/Design/Data';
import { DailyVarianceModal } from './Components/DailyVariance';
import { useGridResizer } from './Components/GridResizer';
import { ConnectionCheck } from '@root/Components/Resources/ConnectionCheck';
import { useNav } from '@root/Services/NavigationService';
import { InvoiceCostFields, useInvoiceCostField } from '@root/Services/Invoices/InvoiceCostFieldContext';
import { CostFieldSelector } from './Components/CostFieldSelector';
import { withSchemaPreloader } from '@root/Components/Invoices/SchemaPreloader';
import { IInvoiceFieldCompatibilityLookup, useInvoiceFieldCompatibility } from '@root/Services/Invoices/InvoiceFieldCompatibilityService';

export function InvoiceExplorer() {
    const nav = useNav();
    const { resourceId } = nav.getData('resourceId');
    const invoiceApi = useDiMemo(InvoiceApiService);
    const costField = useInvoiceCostField();
    const [invoices, setInvoices] = useState<InvoiceDetail[]>();
    const [month, setMonth] = useState<Date>();
    const [gridFilters, setGridFilters] = useState<QueryExpr[]>([]);
    const { builder: xb } = exprBuilder<IBaseInvoiceRecord>();
    const fieldCompat = useInvoiceFieldCompatibility('daily', true);
    const { loading: invoiceOptionsLoading, data: invoiceDetails } = useInvoiceRange(invoiceApi, costField, fieldCompat);

    useEffect(() => {
        if (invoiceDetails?.invoices.length) {
            const monthIndex = invoiceDetails.months.length - 1;
            const defaultMonth = invoiceDetails.months[monthIndex];
            const invoices = invoiceDetails.invoices.filter((i) => i.month.getTime() === defaultMonth.getTime());
            setInvoices(invoices);
            setMonth((v) => v ?? defaultMonth);
        }
    }, [invoiceOptionsLoading, invoiceDetails, costField]);
    const handleSelect = useCallback((month: Date, invoices: InvoiceDetail[]) => {
        setMonth(month);
        setInvoices(invoices);
    }, []);

    const navFilters = useMemo(() => {
        const filter: QueryExpr[] = [];
        if (resourceId) {
            filter.push({
                ...xb.resolve(xb.model.ResourceId!.eq(resourceId)),
                description: 'Resource Id is ' + resourceId,
            } as unknown as QueryExpr);
        }
        return filter;
    }, [resourceId]);

    const invoiceFilters = useMemo(() => {
        if (!invoices?.length || invoices.length === invoiceDetails?.invoices.length) {
            return [];
        }
        const accountIds = [...new Set(invoiceDetails?.invoices.map((v) => v.accountId).filter((v) => !!v))];

        return [xb.resolve(xb.and(xb.model.BillingAccountId.eq(accountIds))) as QueryExpr];
    }, [invoices]);

    const allFilters = useMemo(() => [...gridFilters, ...invoiceFilters, ...navFilters], [gridFilters, invoiceFilters, navFilters]);

    const dateRange = useMemo(() => (!month ? {} : { from: startOfMonth(month), to: startOfMonth(month) }), [month]);
    const gridFiltersChanged = useCallback((filters: QueryExpr[]) => setGridFilters([...filters]), []);
    const defaultGroupBy = useMemo(() => {
        return !fieldCompat
            ? null
            : ([{ id: fieldCompat.getAvailableField('ServiceCategory')?.pathWithRoot, sortDir: 'Desc', sortMode: 'count' }] as GridGroupByState[]);
    }, [fieldCompat]);
    const { Resizer, containerStyle, setScrollContainer } = useGridResizer();

    return (
        <ConnectionCheck>
            {() => (
                <PageBody>
                    <PageContent ref={setScrollContainer}>
                        <PaneledPage>
                            <Box p="lg" sx={{ gap: 24, height: '100%', width: '100%', display: 'flex', flexDirection: 'column', minHeight: 850 }}>
                                <PanelHeader style={{ flex: 0, padding: 0 }}>
                                    <Title order={3} data-atid="InvoiceExplorerHeader">
                                        Invoice Explorer
                                    </Title>

                                    <Group position="apart" spacing={8}>
                                        <CostFieldSelector />
                                        {!invoices?.length || !invoiceDetails ? null : (
                                            <InvoicePickerButton
                                                onSelect={handleSelect}
                                                invoiceDetails={invoiceDetails}
                                                selectedMonth={month!}
                                                selectedInvoices={invoices}
                                            />
                                        )}
                                    </Group>
                                </PanelHeader>

                                <Group noWrap>
                                    <Card withBorder sx={{ flexGrow: 1, height: 300 }} radius="md" shadow="sm">
                                        <FillerSwitch loading={invoiceOptionsLoading} noData={!invoices?.length}>
                                            {() => <DailyBar invoiceApi={invoiceApi} month={month!} filters={allFilters} costField={costField} />}
                                        </FillerSwitch>
                                    </Card>
                                    <Stack sx={{ height: '100%', width: 300 }}>
                                        <TotalSpend
                                            invoiceApi={invoiceApi}
                                            month={month}
                                            invoiceFilters={invoiceFilters}
                                            otherFilters={[...gridFilters, ...navFilters]}
                                            costField={costField}
                                        />
                                        <DailyAverage
                                            invoiceApi={invoiceApi}
                                            month={month}
                                            invoiceFilters={invoiceFilters}
                                            otherFilters={[...gridFilters, ...navFilters]}
                                            costField={costField}
                                        />
                                    </Stack>
                                </Group>
                                <Box sx={{ height: '100%', overflow: 'hidden', ...containerStyle }}>
                                    <FillerSwitch loading={!defaultGroupBy || !month}>
                                        {() => (
                                            <DailyInvoiceGrid
                                                persistenceKey={!(navFilters.length > 0) ? 'InvoiceExplorer' : ''}
                                                dateRange={dateRange}
                                                invoiceApi={invoiceApi}
                                                onFilterChange={gridFiltersChanged}
                                                scope={invoiceFilters[0]}
                                                defaultGroupBy={defaultGroupBy!}
                                                rightPlaceholder={
                                                    <>
                                                        <Resizer />
                                                        <Space w="md" />
                                                    </>
                                                }
                                                costField={costField}
                                                filters={navFilters}
                                                disabledSavedViews={navFilters.length > 0}
                                            />
                                        )}
                                    </FillerSwitch>
                                </Box>
                            </Box>
                        </PaneledPage>
                    </PageContent>
                </PageBody>
            )}
        </ConnectionCheck>
    );
}

endpoint('invoice-explorer', withSchemaPreloader(InvoiceExplorer), 'Invoice Explorer');

interface IInvoicePickerProps {
    invoiceDetails: InvoiceDetailBundle;
    selectedMonth: Date;
    selectedInvoices: InvoiceDetail[];
    onSelect: (month: Date, invoices: InvoiceDetail[]) => void;
}
function InvoicePickerButton({ invoiceDetails, selectedMonth, selectedInvoices, onSelect }: IInvoicePickerProps) {
    const fmtSvc = useDi(FormatService);
    const [opened, { toggle, close }] = useDisclosure(false);
    const invoiceLabel =
        selectedInvoices.length === 1 ? (
            selectedInvoices[0].accountName ?? selectedInvoices[0].accountId
        ) : (
            <Badge variant="light" mt={1}>
                {selectedInvoices.length}
            </Badge>
        );
    const buttonSections = [
        { label: 'Month', text: fmtSvc.formatLongMonthYear(selectedMonth) },
        { label: 'Accounts', text: invoiceLabel },
    ];
    return (
        <>
            <ContextButton icon={<Search />} onClick={toggle} sections={buttonSections} />
            <Drawer onClose={close} opened={opened} size={802} withCloseButton={false} position="right">
                <InvoicePicker
                    invoiceDetails={invoiceDetails}
                    onClose={close}
                    onSelect={onSelect}
                    selectedInvoices={selectedInvoices}
                    selectedMonth={selectedMonth}
                />
            </Drawer>
        </>
    );
}

function InvoicePicker({ invoiceDetails, onSelect, selectedInvoices, selectedMonth, onClose }: IInvoicePickerProps & { onClose: () => void }) {
    const theme = useMantineTheme();
    const fmtSvc = useDi(FormatService);
    const [month, setMonth] = useState<Date>(selectedMonth);
    const [invoices, setInvoices] = useState<InvoiceDetail[]>(selectedInvoices);
    const availableMonths = useMemo(
        () => invoiceDetails.months.map((m) => ({ label: fmtSvc.formatLongMonthYear(m), value: fmtSvc.formatYearMonth(m), date: m })).reverse(),
        [invoiceDetails]
    );
    const getMonthInvoices = (month: Date) => invoiceDetails.invoices.filter((i) => i.month.getTime() === month.getTime());
    const availableInvoices = useMemo(() => getMonthInvoices(month), [invoiceDetails, month]);
    const updateMonth = useCallback((month: string) => {
        const monthDate = fmtSvc.fromMonthYear(month);
        setMonth(monthDate);
        setInvoices(getMonthInvoices(monthDate));
    }, []);
    const columns = useMemo(
        () =>
            [
                {
                    id: 'accountNumber',
                    accessor: 'accountId',
                    defaultWidth: 150,
                    header: 'Account ID',
                    formatter: (v: InvoiceDetail) => (v.accountId.length === 12 ? fmtSvc.awsAccountId(v.accountId) : v.accountId),
                    type: 'string',
                },
                {
                    id: 'accountName',
                    accessor: 'accountName',
                    defaultWidth: 200,
                    header: 'Billing Account',
                    type: 'string',
                },
                {
                    id: 'billingEntity',
                    accessor: 'billingEntity',
                    defaultWidth: 200,
                    header: 'Billing Entity',
                    type: 'string',
                },
                {
                    id: 'amount',
                    accessor: 'amount',
                    defaultWidth: 120,
                    header: 'Total',
                    formatter: (v: InvoiceDetail) => fmtSvc.formatMoneyNoDecimals(v.amount),
                    type: 'number',
                    align: 'right',
                },
            ].map((c) => ({ ...c, noReorder: true, noRemove: true })) as ColumnConfig<InvoiceDetail>[],
        []
    );
    const defaultState = useMemo(
        () =>
            ({
                columns: columns.map((c) => ({ id: c.id, width: c.defaultWidth })),
                filters: [],
                sort: [
                    { Expr: { Field: 'accountId' }, Direction: 'Asc' },
                    { Expr: { Field: 'amount' }, Direction: 'Desc' },
                ],
            } as DataGridState),
        []
    );

    const onSelectedChanged = useCallback(async (selected: { getItems: () => Promise<InvoiceDetail[]> }) => {
        const items = await selected.getItems();
        setInvoices(items);
    }, []);
    const canApply = invoices.length > 0;
    const apply = useCallback(() => {
        if (canApply) {
            onSelect(month, invoices);
            onClose();
        }
    }, [canApply, invoices, month, onSelect, onClose]);
    const reset = useCallback(() => {
        setInvoices(selectedInvoices);
        setMonth(selectedMonth);
    }, [selectedInvoices, selectedMonth]);
    useEffect(() => {
        reset();
    }, [selectedInvoices, selectedMonth]);

    return (
        <Stack sx={{ height: '100%' }}>
            <Box>
                <Group position="apart" p="lg">
                    <Title order={4}>Account Selection</Title>
                    <CloseButton onClick={onClose} />
                </Group>
                <Divider />
            </Box>
            <Box p="lg" sx={{ flex: 1 }}>
                <Select
                    label="Month / Year"
                    sx={{ width: 300 }}
                    data={availableMonths}
                    value={fmtSvc.formatYearMonth(month)}
                    onChange={updateMonth}
                ></Select>
                <Space h="xl" />
                <Text size="sm">Select Billing Accounts</Text>
                <Space h={6} />
                <Box sx={{ height: 400 }}>
                    <DataGrid
                        key={month.getTime()}
                        columns={columns}
                        dataSource={availableInvoices}
                        selectionMode="multiple"
                        onSelectedChanged={onSelectedChanged}
                        initialSelection={invoices}
                        state={defaultState}
                        onRowClick="select"
                        hideFilter
                        hideHeader
                    />
                </Box>
            </Box>
            <Box sx={{ background: theme.colors.gray[3] }}>
                <Divider />
                <Group position="apart" p="lg">
                    <Button variant="outline" onClick={reset}>
                        Reset
                    </Button>
                    <Group>
                        <Button variant="outline" onClick={onClose}>
                            Cancel
                        </Button>
                        <Button disabled={!canApply} onClick={apply}>
                            Apply
                        </Button>
                    </Group>
                </Group>
            </Box>
        </Stack>
    );
}

interface InvoiceDetailBundle {
    invoices: InvoiceDetail[];
    months: Date[];
}

interface InvoiceDetail {
    month: Date;
    accountId: string;
    accountName: string;
    billingEntity: string;
    amount: number;
}

function useInvoiceRange(invoiceApi: InvoiceApiService, costField: InvoiceCostFields, fieldCompat: IInvoiceFieldCompatibilityLookup | undefined) {
    const fmtSvc = useDi(FormatService);
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState<InvoiceDetailBundle>();

    useEffect(() => {
        if (fieldCompat) {
            const [issuer] = fieldCompat.getAvailableFields('InvoiceIssuerName');
            (async () => {
                const builder = queryBuilder<IBaseInvoiceRecord & Record<string, number>>().select((b) => ({
                    month: b.model.UsageMonth,
                    accountId: b.model.BillingAccountId,
                    accountName: b.values(b.model.BillingAccountName!, undefined, 'Unknown'),
                    billingEntity: b.model[issuer as 'InvoiceIssuerName']!,
                    amount: b.sum(b.model[costField!]),
                }));
                const rawInvoices = await builder.execute((q) => invoiceApi.queryMonthlyRollup(q, []));
                const invoices = (rawInvoices.Results ?? [])
                    .filter((r) => !!r.accountId)
                    .map((r) => ({
                        ...r,
                        month: fmtSvc.parseDateNoTime(r.month),
                    }));
                const months = [
                    ...invoices.reduce((result, i) => result.set(fmtSvc.formatMonth(i.month), i.month), new Map<string, Date>()).values(),
                ].sort((a, b) => a.getTime() - b.getTime());
                setData({ invoices, months });
            })().finally(() => setLoading(false));
        }
    }, [fieldCompat]);

    return { loading, data };
}

function DailyBar({
    invoiceApi,
    month,
    filters,
    costField,
}: {
    invoiceApi: InvoiceApiService;
    month: Date;
    filters?: QueryExpr[];
    costField: InvoiceCostFields;
}) {
    const fmtSvc = useDi(FormatService);
    const [loading, setLoading] = useState(true);
    const [dayVarianceOpener, setDayVarianceOpener] = useState<{ open: (day: Date) => void }>();
    const [maxValue, setMaxValue] = useState<number>(0);

    var chartProps = useMemo(
        () => ({
            groups: ['Date'],
            values: ['Total'],
            settings: {
                sort: 'none',
                format: maxValue >= 100 ? 'money-whole' : ('money' as const),
                orientation: 'Vertical',
                showTrend: true,
                barPadding: 0.7,
                margin: { top: 20, right: 20, bottom: 60, left: 60 },
                onBarClick: (d) => {
                    dayVarianceOpener?.open(fmtSvc.parseDateNoTime(d.Ts as string));
                },
                enableLabel: 'on-hover',
            } as CustomBarChartSettings,
        }),
        [dayVarianceOpener, maxValue]
    );
    const [data, setData] = useState<Record<string, number | string>[]>([]);
    useEffect(() => {
        setLoading(true);
        (async () => {
            const monthStart = startOfMonth(month);
            const query = queryBuilder<IBaseInvoiceRecord & Record<string, number>>()
                .where((b) => b.and(...(filters ?? []).map((f) => b.fromExpr(f)), b.model.UsageMonth.eq(fmtSvc.formatYearMonth(month))))
                .select((b) => ({
                    Day: b.model.ChargePeriodStart,
                    Value: b.sum(b.model[costField]),
                }))
                .build();

            const results = (await invoiceApi.queryByUsageMonth(query, monthStart)) as QueryResult<{
                Day: string;
                Value: number;
            }>;
            const items = results?.Results ?? [];
            setMaxValue(Math.max(...items.map((m) => m.Value)));

            const data = items
                .filter((o) => o.Day)
                .sort((a, b) => a.Day.localeCompare(b.Day))
                .map((o) => ({
                    Ts: o.Day,
                    Date: fmtSvc.formatLongDay(fmtSvc.parseDateNoTime(o.Day)),
                    Total: maxValue >= 10 ? Math.round(o.Value) : o.Value,
                }));
            setData(data);
        })().finally(() => setLoading(false));
    }, [month, filters, costField]);

    return (
        <FillerSwitch loading={loading} noData={!data?.length}>
            {() => (
                <>
                    <Stack sx={{ height: '100%' }} spacing={4}>
                        <Text size="lg">Daily Spend</Text>
                        <Box sx={{ flex: '1 1 100%', overflow: 'hidden' }}>
                            <BarChart data={data} {...chartProps} />
                        </Box>
                    </Stack>
                    <DailyVarianceModal filters={filters} invoiceApi={invoiceApi} opener={setDayVarianceOpener} />
                </>
            )}
        </FillerSwitch>
    );
}

function TotalSpend({
    month,
    invoiceApi,
    invoiceFilters,
    otherFilters,
    costField,
}: {
    month?: Date;
    invoiceApi: InvoiceApiService;
    invoiceFilters?: QueryExpr[];
    otherFilters?: QueryExpr[];
    costField: InvoiceCostFields;
}) {
    const fmtSvc = useDi(FormatService);
    const [loading, setLoading] = useState(true);
    const [details, setDetails] = useState<{ lastMonth?: Date; monthTotal: number; invoiceTotal: number; difference?: number }>();

    useEffect(() => {
        if (!month || !invoiceFilters) {
            setDetails(undefined);
        } else {
            const monthValue = fmtSvc.formatYearMonth(month);
            const lastMonthValue = fmtSvc.formatYearMonth(addMonths(month, -1));
            const lastMonth = addMonths(month, -1);
            (async () => {
                const applyOtherFilters = (q: ISearchBuilder<IBaseInvoiceRecord & Record<string, number>>) => {
                    if (otherFilters?.length) {
                        q.where((b) => b.fromExpr<boolean>({ Operation: 'and', Operands: otherFilters }));
                    }
                    return q;
                };
                const results = await applyOtherFilters(queryBuilder<IBaseInvoiceRecord & Record<string, number>>())
                    .select((b) => ({
                        monthTotal: b.aggIf(b.model.UsageMonth.eq(monthValue), b.sum(b.model[costField!])),
                        invoiceTotal: b.aggIf(
                            b.and(...invoiceFilters.map((f) => b.fromExpr<boolean>(f)), b.model.UsageMonth.eq(monthValue)),
                            b.sum(b.model[costField])
                        ),
                        lastMonth: b.aggIf(b.model.UsageMonth.eq(lastMonthValue), b.sum(b.model[costField!])),
                        lastMonthCount: b.countIf(b.model.UsageMonth.eq(lastMonthValue)),
                    }))
                    .execute((q) => invoiceApi.query(q, { from: startOfMonth(lastMonth), to: endOfMonth(month) }, false));

                const result = results.Results?.[0];
                if (result) {
                    setDetails({
                        monthTotal: result.monthTotal ?? 0,
                        invoiceTotal: result.invoiceTotal ?? 0,
                        difference:
                            result.monthTotal === undefined || result.lastMonth === undefined ? undefined : result.monthTotal - result.lastMonth,
                        lastMonth: result.lastMonthCount ? lastMonth : undefined,
                    });
                } else {
                    setDetails(undefined);
                }
            })().finally(() => setLoading(false));
        }
    }, [month, JSON.stringify([invoiceFilters, otherFilters]), costField]);
    const monthTotal = details?.monthTotal !== undefined ? fmtSvc.formatMoneyNoDecimals(details.monthTotal) : '';
    const monthTotalLabel = `${monthTotal} month total`;

    return (
        <ScoreCard
            title="Invoice Total"
            loading={loading}
            noData={!month || !details}
            total={details?.invoiceTotal}
            totalLabel={monthTotalLabel}
            difference={details?.difference}
            lastMonth={details?.lastMonth}
        />
    );
}

function DailyAverage({
    month,
    invoiceApi,
    invoiceFilters,
    otherFilters,
    costField,
}: {
    month?: Date;
    invoiceApi: InvoiceApiService;
    invoiceFilters?: QueryExpr[];
    otherFilters?: QueryExpr[];
    costField: InvoiceCostFields;
}) {
    const fmtSvc = useDi(FormatService);
    const [loading, setLoading] = useState(true);
    const [details, setDetails] = useState<{ lastMonth?: Date; monthAvg?: number; difference?: number }>();

    useEffect(() => {
        if (!month || !invoiceFilters) {
            setDetails(undefined);
        } else {
            const monthValue = fmtSvc.formatYearMonth(month);
            const lastMonthValue = fmtSvc.formatYearMonth(addMonths(month, -1));
            const lastMonth = addMonths(month, -1);
            (async () => {
                const applyAllFilters = (q: ISearchBuilder<IBaseInvoiceRecord & Record<string, number>>) => {
                    const filters = [...(otherFilters ?? []), ...invoiceFilters];
                    if (filters.length) {
                        q.where((b) => b.fromExpr<boolean>({ Operation: 'and', Operands: filters }));
                    }

                    return q;
                };

                const applyOtherFiltersOnly = (q: ISearchBuilder<IBaseInvoiceRecord & Record<string, number>>) => {
                    if (otherFilters?.length) {
                        q.where((b) => b.fromExpr<boolean>({ Operation: 'and', Operands: otherFilters }));
                    }
                    return q;
                };
                const monthQuery = applyAllFilters(queryBuilder<IBaseInvoiceRecord & Record<string, number>>())
                    .select((b) => ({
                        day: b.model.ChargePeriodStart,
                        daySum: b.aggIf(b.model.UsageMonth.eq(monthValue), b.sum(b.model[costField])),
                    }))
                    .execute((q) => invoiceApi.queryByUsageMonth(q, month));
                const lastMonthQuery = applyOtherFiltersOnly(queryBuilder<IBaseInvoiceRecord & Record<string, number>>())
                    .select((b) => ({
                        day: b.model.ChargePeriodStart,
                        daySum: b.aggIf(b.model.UsageMonth.eq(lastMonthValue), b.sum(b.model[costField])),
                    }))
                    .execute((q) => invoiceApi.queryByUsageMonth(q, lastMonth));
                const [monthResults, lastMonthResults] = await Promise.all([monthQuery, lastMonthQuery]);
                const currResult = monthResults.Results ?? [];
                const prevResult = lastMonthResults.Results ?? [];

                if (currResult?.length) {
                    const avg = (items: undefined | { daySum: number }[]) =>
                        !items?.length ? undefined : items.reduce((sum, item) => sum + item.daySum, 0) / items.length;
                    const currAvg = avg(currResult);
                    const prevAvg = avg(prevResult);
                    setDetails({
                        monthAvg: currAvg,
                        difference: prevAvg === undefined ? undefined : currAvg! - prevAvg,
                        lastMonth: prevResult ? lastMonth : undefined,
                    });
                } else {
                    setDetails(undefined);
                }
            })().finally(() => setLoading(false));
        }
    }, [month, JSON.stringify([invoiceFilters, otherFilters]), costField]);

    return (
        <ScoreCard
            title="Average Daily Spend"
            loading={loading}
            noData={!month || !details}
            total={details?.monthAvg}
            difference={details?.difference}
            lastMonth={details?.lastMonth}
        />
    );
}

function ScoreCard({
    title,
    loading,
    noData,
    total,
    totalLabel,
    difference,
    lastMonth,
}: {
    title: string;
    loading: boolean;
    noData: boolean;
    total?: number;
    totalLabel?: string;
    lastMonth?: Date;
    difference?: number;
}) {
    const fmtSvc = useDi(FormatService);
    const differenceDescription = difference === 0 ? 'no change' : difference === undefined ? '' : difference > 0 ? 'increase' : 'decrease';
    const lastMonthLabel = lastMonth ? fmtSvc.formatShortMonthYear(lastMonth) : '';
    const ufTotal = total !== undefined ? fmtSvc.formatMoneyNoDecimals(total) : '';
    const differenceTotal = difference !== undefined ? fmtSvc.formatMoneyNoDecimals(Math.abs(difference)) : '';

    return (
        <Card withBorder shadow="xs" p="xs" sx={{ flex: 1 }}>
            <FillerSwitch loading={loading} noData={noData}>
                {() => (
                    <Stack justify="space-between" spacing={0} sx={{ height: '100%' }}>
                        <Score title={title} value={ufTotal} helpText={totalLabel} />
                        {!lastMonth ? null : (
                            <Box>
                                <Divider color="gray.3" />
                                <Space h={4} />
                                <Center>
                                    <IncreaseIndicator value={difference ?? 0} size="lg" preferDecrease />
                                    <Text size="sm" ml={4}>
                                        {differenceTotal} {differenceDescription} from {lastMonthLabel}
                                    </Text>
                                </Center>
                            </Box>
                        )}
                    </Stack>
                )}
            </FillerSwitch>
        </Card>
    );
}
