import styled from '@emotion/styled';
import { Checkbox, Box, TextInput, Space, Divider, Tooltip, ActionIcon, Text, useMantineTheme } from '@mantine/core';
import { useSet } from '@react-hookz/web';
import { ReactNode, useState, useRef, useMemo, useCallback, useEffect, KeyboardEvent } from 'react';
import { useFloatedFullText } from '../Text/FloatedFullText';
import { VirtualTree, ITypedTreeConfig } from '../VirtualTree';
import { VirtualTreeModel } from '../VirtualTree/VirtualTreeModel';

export interface PickerProps<T> {
    items: T[];
    selections: T[];
    onChange: (selections: T[]) => void;
    onFilterChange?: (filter: string) => void | undefined;
    readonly?: boolean;
    isSelectable?: (item: T) => boolean;
    isDisabled?: (item: T) => boolean;
    childAccessor?: (item: T) => T[] | undefined;
    renderItem?: (item: T) => ReactNode;
    nameAccessor: keyof T | ((item: T) => string | string[]);
    width?: number | string;
    filterPlaceholder?: string;
    onApply?: () => void;
    onCancel?: () => void;
    resizeDeps?: any[];
    mode?: 'single' | 'multiple';
    filter?: (item: T) => boolean;
    noFilter?: boolean;
    minimizeHeight?: boolean;
    height?: number;
    itemHeight?: number;
    /**
     * Selections appear at the top
     */
    bubbleUpSelections?: boolean;
    /**
     * Display full text on hover
     */
    inlineFullText?: boolean;
    /**
     * If true, the picker selection state is controlled by the selections prop
     */
    controlled?: boolean;
    /**
     * Disable auto-focus on filter text input
     */
    disableAutoFocus?: boolean;
    onModelLoaded?: (model?: VirtualTreeModel<T>, tree?: VirtualTree) => void;
}
export function Picker<T>(props: PickerProps<T>) {
    const [filterText, setFilterText] = useState('');
    const [model, setModel] = useState<VirtualTreeModel<T>>();
    const treeRef = useRef<VirtualTree>(null);
    const floatTextStyle = useMemo(
        () =>
            ({
                background: 'white',
                borderRadius: '4px',
                padding: '2px 5px',
                marginLeft: '5px',
                marginTop: '-1px',
                opacity: '0.92',
                boxShadow: '0 0 5px 0 #0003',
            } as CSSStyleDeclaration),
        []
    );
    const { floatOnMouseEnter } = useFloatedFullText(floatTextStyle);
    const onMouseEnter = props.inlineFullText ? floatOnMouseEnter : undefined;

    useEffect(() => {
        props?.onModelLoaded?.(model, treeRef?.current ?? undefined);
    }, [model, treeRef.current]);

    useEffect(() => {
        if (props.onFilterChange != undefined) props.onFilterChange(filterText);
    }, [filterText]);

    const nameAccessor = useMemo(() => {
        const accessor = typeof props.nameAccessor === 'function' ? props.nameAccessor : (item: T) => item[props.nameAccessor as keyof T];
        const normAccessor = (item: T) => {
            const result = accessor(item);
            return Array.isArray(result) ? result.join('\0') : result;
        };
        return normAccessor;
    }, [props.nameAccessor]);
    const loadModel = (model: VirtualTreeModel<T>) => {
        setModel(model);
        model.setFilter(createFilter(''));
    };
    const createFilter = (text: string) => {
        const filterTextLc = text.toLocaleLowerCase();
        const textFilter = filterTextLc ? (item: T) => ('' + nameAccessor(item)).toLocaleLowerCase().includes(filterTextLc) : undefined;
        const result =
            props.filter || textFilter
                ? (item: T) => {
                      return (!props.filter || props.filter(item)) && (!textFilter || textFilter(item));
                  }
                : undefined;
        return result;
    };
    const applyFilterText = (text: string) => {
        setFilterText(text);
        if (model) {
            model.setFilter(createFilter(text));
            model.expandAll();
        }
    };
    const selections = useSet(props.selections);
    useEffect(() => {
        if (props.controlled) {
            selections.clear();
            props.selections.reduce((r, item) => r.add(item), selections);
        }
    }, [props.selections, props.controlled]);
    const activate = useCallback(
        (item: T, model: VirtualTreeModel<T>) => {
            model.highlight(item);
            if (props.isSelectable?.(item) !== false && props?.isDisabled?.(item) !== true) {
                if (model?.isExpandable(item)) {
                    model.toggle(item);
                } else if (!props.readonly) {
                    if (props.mode === 'single') {
                        selections.clear();
                        selections.add(item);
                    } else {
                        if (props.isSelectable?.(item) !== false) {
                            if (selections.has(item)) {
                                selections.delete(item);
                            } else {
                                selections.add(item);
                            }
                        }
                    }
                    props.onChange([...selections]);
                }
            } else {
                if (model?.isExpandable(item)) {
                    model.toggle(item);
                }
            }
        },
        [props.onChange, model]
    );
    const checkBoxSelect = useCallback(
        (item: T, evt: { stopPropagation: () => void }) => {
            if (model?.isExpandable(item)) {
                if (selections.has(item)) {
                    selections.delete(item);
                } else {
                    selections.add(item);
                }
                props.onChange([...selections]);
                evt.stopPropagation();
            }
        },
        [props.onChange, model]
    );
    const filterKeyUp = (evt: KeyboardEvent<HTMLDivElement>) => {
        if (evt.key === 'ArrowDown') {
            model?.navigate('Down');
            treeRef.current?.scrollToItem(model?.getHighlightedItem());
        } else if (evt.key === 'ArrowUp') {
            model?.navigate('Up');
            treeRef.current?.scrollToItem(model?.getHighlightedItem());
        } else if (evt.key === 'Enter') {
            const highlightedItem = model?.getHighlightedItem();
            if (highlightedItem) {
                activate(highlightedItem, model!);
            }
        }
    };
    const containerKeyUp = (evt: KeyboardEvent<HTMLDivElement>) => {
        const highlightedItem = model?.getHighlightedItem();
        if (highlightedItem) {
            if (evt.key === 'Enter' && props.isSelectable?.(highlightedItem)) {
                activate(highlightedItem, model!);
            }
        }
    };
    const theme = useMantineTheme();
    const config = useMemo(
        () =>
            ({
                itemHeight: props.itemHeight ?? 30,
                renderNode: (node, state) => (
                    <PickerItem
                        className="picker-item"
                        itemHeight={props.itemHeight ?? 30}
                        onClick={() => activate(node.item, state.model)}
                        selectable={props.isSelectable?.(node.item) !== false ? 'y' : 'n'}
                        expandable={state.model.canExpand(node.item) ? 'expandable' : undefined}
                        state={state.isHighlighted(node.item) ? 'highlight' : undefined}
                        style={{ [`--depth`]: node.depth * theme.spacing.md + 'px' } as React.CSSProperties}
                        data-atid={'operatorSelection-' + String(nameAccessor(node.item))}
                    >
                        <Box>
                            {props.isSelectable?.(node.item) !== false && props.mode !== 'single' ? (
                                <Checkbox
                                    onClick={(e) => checkBoxSelect(node.item, e)}
                                    readOnly
                                    disabled={props.isDisabled?.(node.item)}
                                    size="xs"
                                    checked={selections.has(node.item)}
                                    id="PickerCheckbox"
                                    className="picker-item-checkbox"
                                />
                            ) : (
                                <></>
                            )}
                            <Text
                                onMouseEnter={onMouseEnter}
                                size="sm"
                                pl="xs"
                                className="picker-item-text"
                                data-atid={'PickerItem:' + nameAccessor(node.item)}
                                sx={{ flex: 1 }}
                            >
                                {props.renderItem ? props.renderItem(node.item) : String(nameAccessor(node.item))}
                            </Text>
                            {!state.model.isExpandable(node.item) ? null : !state.isExpanded(node.item) ? (
                                <i className="ti ti-chevron-down"></i>
                            ) : (
                                <i className="ti ti-chevron-up"></i>
                            )}
                        </Box>
                    </PickerItem>
                ),
                height: props.height,
                minimizeHeight: props.minimizeHeight,
                childAccessor: props.childAccessor,
                canExpand: props.childAccessor ? (item) => !!props.childAccessor?.(item) : undefined,
                onModelLoaded: loadModel,
            } as ITypedTreeConfig<T>),
        [activate, props.childAccessor, setModel, props.renderItem, theme, props.minimizeHeight]
    );
    const resizeDeps = JSON.stringify(props.resizeDeps ? props.resizeDeps : []);
    useEffect(() => {
        setTimeout(() => {
            treeRef.current?.invalidateSize();
            treeRef.current?.invalidate();
        }, 1);
    }, [treeRef.current, resizeDeps]);
    const hasButtons = !!props.onApply || !!props.onCancel;

    return (
        <PickerContainer width={props.width}>
            {props.noFilter ? null : (
                <>
                    <Box sx={{ flex: '0', display: 'flex', alignItems: 'center' }} py="xs" px="sm">
                        <TextInput
                            value={filterText}
                            autoFocus={props.disableAutoFocus ? false : true}
                            size="xs"
                            autoComplete="off"
                            icon={<i className="ti ti-filter"></i>}
                            onKeyUp={filterKeyUp}
                            placeholder={props.filterPlaceholder ?? 'Filter'}
                            onChange={(e) => applyFilterText(e.currentTarget.value)}
                            sx={{ flex: '1' }}
                            data-atid="PickerTextEntry"
                        />
                        {hasButtons ? (
                            <>
                                <Space w="xs" />
                                <Divider orientation="vertical" />
                                <Space w="xs" />
                            </>
                        ) : null}
                        {props.onApply ? (
                            <>
                                <Tooltip label="Done" position="bottom" withArrow>
                                    <ActionIcon onClick={props.onApply}>
                                        <i className="ti ti-check"></i>
                                    </ActionIcon>
                                </Tooltip>
                                <Space w="xs" />
                            </>
                        ) : null}
                        {props.onCancel ? (
                            <Tooltip label="Cancel" position="bottom" withArrow>
                                <ActionIcon onClick={props.onCancel}>
                                    <i className="ti ti-x"></i>
                                </ActionIcon>
                            </Tooltip>
                        ) : null}
                    </Box>
                    <Divider />
                </>
            )}
            <Box sx={{ flex: '1' }} onKeyUp={containerKeyUp}>
                <VirtualTree
                    ref={treeRef}
                    data={!props.bubbleUpSelections ? props.items : [...selections, ...new Set(props.items.filter((item) => !selections.has(item)))]}
                    config={config}
                ></VirtualTree>
            </Box>
        </PickerContainer>
    );
}

const PickerContainer = styled.div<{ width?: number | string }>`
    height: 100%;
    display: flex;
    flex-direction: column;
    width: ${(p) => (typeof p.width === 'string' ? p.width : (p.width ?? 250) + 'px')};
    --selected-bg: var(--picker-item-selected-bg, ${(p) => p.theme.colors.primary[2]});
    --hover-bg: var(--picker-item-hover-bg, ${(p) => p.theme.colors.primary[2]});
    --hover-selected-bg: var(--picker-item-hover-selected-bg, ${(p) => p.theme.colors.primary[3]});
`;
const PickerItem = styled.div<{ state?: 'highlight'; expandable?: 'expandable'; itemHeight: number; selectable: 'y' | 'n' }>`
    > div {
        background: ${(p) => (p.state === 'highlight' && p.selectable === 'y' ? 'var(--selected-bg)' : undefined)};
        border-radius: ${(p) => p.theme.radius.sm}px;
        justify-content: ${(p) => (p.expandable ? 'space-between' : undefined)};
        display: flex;
        align-items: center;
        overflow: hidden;
        padding: 1px ${(p) => p.theme.spacing.xs}px;
        height: ${(p) => p.itemHeight - 2}px;
    }
    :hover > div {
        background: ${(p) =>
            p.selectable === 'n' && !p.expandable ? undefined : p.state === 'highlight' ? 'var(--hover-selected-bg)' : 'var(--hover-bg)'};
    }
    cursor: ${(p) => (p.selectable === 'n' && !p.expandable ? 'default' : 'pointer')};
    padding: 1px;
    height: ${(p) => p.itemHeight}px;
    margin: 0;
    .picker-item-text {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        margin-left: var(--depth);
    }
    * {
        transition: none;
    }
`;
