import {
    NotificationComponentConfig,
    UserDefinedNotification,
    UserDefinedNotificationConfig,
    UserDefinedNotificationTrigger,
    UserDefinedNotificationTriggerTriggerType,
} from '@apis/Notification/model';
import { QueryExpr } from '@apis/Resources';
import { singleton } from 'tsyringe';
import { EventEmitter } from '../EventEmitter';

export interface INotificationViewerOpenRequest<TContext = {}> {
    typePrefId?: number;
    notificationId?: number;
    mode: 'view' | 'edit';
    onClose?: (changed: boolean) => void;
    scope: {
        datasourceName?: string;
        /**
         * Additional context to be passed to the viewer
         */
        context: TContext;
        /**
         * Configuration for seeding a new notification
         */
        config?: UserDefinedNotificationConfig;
    };
}
@singleton()
export class NotificationViewerService {
    public readonly openRequested = new EventEmitter<INotificationViewerOpenRequest | undefined>(undefined);

    public open = <TContext>(request: INotificationViewerOpenRequest<TContext>) => {
        this.openRequested.emit(request);
    };

    public create = <TContext>(
        datasourceName: string,
        context: TContext,
        options: {
            title?: string;
            componentConfig?: Partial<NotificationComponentConfig>;
            filters?: QueryExpr[];
            trigger?: UserDefinedNotificationTrigger | UserDefinedNotificationTriggerTriggerType;
            presetId?: string;
        }
    ) => {
        const { componentConfig, filters, presetId: rawPresetId, title, trigger: rawTrigger } = options;
        const trigger = typeof rawTrigger === 'string' ? { TriggerType: rawTrigger } : rawTrigger;
        const presetId = rawPresetId ?? [datasourceName, 'custom', trigger?.TriggerType === 'Threshold' ? 'alert' : 'report'].join('-');
        this.open({
            mode: 'edit',
            scope: {
                datasourceName,
                context,
                config: {
                    Title: title,
                    ComponentConfig: {
                        Type: 'Chart',
                        ...componentConfig,
                    },
                    Trigger: {
                        Schedule: trigger?.TriggerType !== 'Schedule' ? {} : undefined,
                        Threshold: trigger?.TriggerType !== 'Threshold' ? {} : undefined,
                        ...trigger,
                    },
                    PresentationOptions: { presetId },
                    QueryOptions: {
                        DatasourceName: datasourceName,
                        Filters: filters ?? [],
                    },
                },
            },
        });
    };
}
