import { ComputedDatum, PieCustomLayerProps, PieLayer, ResponsivePie } from '@nivo/pie';
import { ArcLabelComponent } from '@nivo/arcs';
import { animated } from '@react-spring/web';
import { ComponentPropsWithoutRef, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
    chartColors,
    ChartMargin,
    createCapsulePath,
    createPlottableSliceData,
    IChartSortConfig,
    IPieArcLabelLayout,
    IPieSliceStats,
    IPlotFieldDescriptor,
    IPlottablePieSlice,
    IPlottablePieVisualStats,
    transformPieArcLabels,
    transformPieSliceData,
    useSizeCtx,
    withContainerDimensions,
} from './Common';
import { StandardChartProps } from './Models';
import { LegendProps } from '@nivo/legends';
import { ValueFormat } from '@nivo/core';
import styled from '@emotion/styled';
import { Score } from '@root/Design/Primitives';
import { useElementSize } from '@mantine/hooks';
import { ChartWrapper } from './Design';
import { INamedFormatter, NamedFormats, useFmtSvc } from '@root/Services/FormatService';
import { EventEmitter, useEvent, useEventValue } from '@root/Services/EventEmitter';
import { setLightness } from '@root/Design/Colors';
import { Box, Card, Group, Stack, Sx, Text, useMantineTheme } from '@mantine/core';
import { IncreaseIndicator } from '@root/Design/Data';
import { EllipsisTextEl, useEllipsisTextClass } from '@root/Design/Text';
import { useFloatedFullText } from '../Text/FloatedFullText';
import { FlyoverHost, useFlyoverModel, useTooltip } from '../Picker/Flyover';
import { useId } from '@root/Services/IdGen';

export type PieChartProps<T extends Record<string, string | number>> =
    | (CustomPieChartProps<T> | UserConfiguredPieChartProps<T>) & { mode?: 'user-configured' | 'custom' };

// #region Custom Pie Chart
export function PieChart<T extends Record<string, string | number>>(props: PieChartProps<T>) {
    if (!('mode' in props) || props.mode !== 'user-configured') {
        return <CustomPieChart {...(props as CustomPieChartProps<T>)} />;
    } else {
        return <UserConfiguredPieChart {...(props as UserConfiguredPieChartProps<T>)} />;
    }
}

function CustomPieChart<T extends Record<string, string | number>>(props: CustomPieChartProps<T>) {
    const getId = useCallback((item: T) => props.groups.map((g) => `${item[g]}`).join('-'), [props.groups]);
    const bindData = useMemo(() => transformPieData(props), [props.data, props.groups, props.values[0]]);
    const { ref: sizeRef, width, height } = useElementSize();
    const margin = { left: 25, bottom: 25, top: 25, right: 25, ...props.settings?.margin };
    const innerDiameter = Math.min(width - margin.left - margin.right, height - margin.top - margin.bottom) * 0.6;

    const responsivePie = (
        <>
            <ResponsivePie
                data={bindData}
                id={getId}
                tooltip={props.settings?.hideToolTip ? () => <></> : undefined}
                value={props.values[0] as string}
                colors={props.settings?.chartColors ?? chartColors}
                valueFormat={
                    props.settings?.valueFormat == undefined
                        ? '>-,'
                        : props.settings?.valueFormat === 'money'
                        ? ' >-$,.2f'
                        : props.settings?.valueFormat === 'money-whole'
                        ? ' >-$,.0f'
                        : props.settings?.valueFormat === 'percent'
                        ? ' >-.0%'
                        : props.settings?.valueFormat
                }
                innerRadius={props.settings?.innerRadius ?? 0.6}
                padAngle={0.5}
                cornerRadius={props.settings?.cornerRadius ?? 5}
                enableArcLinkLabels={props.settings?.enableArcLinkLabels ?? true}
                arcLinkLabelsColor={{ from: 'color' }}
                enableArcLabels={props.settings?.enableArcLabels ?? true}
                arcLinkLabelsTextColor={{
                    from: 'color',
                    modifiers: [['darker', 1.2]],
                }}
                margin={margin}
                legends={props.settings?.legend}
            />
            <PositionWrapper chartMargin={margin} width={innerDiameter}>
                {props.settings?.showTotal && (
                    <Score
                        titlePos={props.settings.totalLabelPosition ?? 'top'}
                        title={props.settings?.totalLabel ?? ''}
                        value={props.settings?.total ?? ''}
                    />
                )}
            </PositionWrapper>
            {props.settings?.renderCenter && (
                <CenterWrapper chartMargin={margin} width={innerDiameter}>
                    {props.settings?.renderCenter?.()}
                </CenterWrapper>
            )}
        </>
    );

    if (!props.settings?.noWrapper) {
        return (
            <ChartWrapper ref={sizeRef} className="chartWrapper">
                {responsivePie}
            </ChartWrapper>
        );
    } else {
        return responsivePie;
    }
}

export interface PieChartSettings {
    noWrapper?: boolean;
    margin?: ChartMargin;
    threshold?: number;
    topN?: number;
    valueFormat?: ValueFormat<number, void> | undefined | NamedFormats;
    legend?: LegendProps[] | undefined;
    enableArcLinkLabels?: boolean | undefined;
    showTotal?: boolean | undefined;
    renderCenter?: () => ReactNode;
    total?: string | undefined;
    totalLabel?: string | undefined;
    enableArcLabels?: boolean | undefined;
    chartColors?: string[] | undefined;
    totalLabelPosition?: 'top' | 'bottom' | undefined;
    hideToolTip?: boolean | undefined;
    innerRadius?: number | undefined;
    cornerRadius?: number | undefined;
}
interface CustomPieChartProps<T> extends StandardChartProps<string | number, T> {
    settings?: PieChartSettings;
}

function transformPieData<T extends Record<string, string | number>>(props: CustomPieChartProps<T>): T[] {
    let result: T[] = [];
    const pieGroup = props.groups[0] ?? '';
    const valueKey = props.values[0] ?? '';
    let thresholdTotal = 0;
    let totalValue = 0;
    const threshold = props.settings?.threshold ? props.settings?.threshold : 0;
    if (props.settings?.valueFormat === 'percent') {
        props.data.forEach((item) => {
            totalValue += item[valueKey] as number;
        });
    }
    for (const item of props.data) {
        const id = (item[pieGroup] || '') as string;
        const value = (item[valueKey] || 0) as number;
        if (value > threshold) {
            const sliceValue = props.settings?.valueFormat === 'percent' ? value / totalValue : value;
            const slice: T = { [pieGroup]: id, [valueKey]: sliceValue } as unknown as T;
            result.push(slice);
        } else {
            thresholdTotal += value;
        }
    }

    if (thresholdTotal != 0) {
        const threshholdValue = props.settings?.valueFormat === 'percent' ? thresholdTotal / totalValue : thresholdTotal;
        const thresholdSlice = { [pieGroup]: 'Other', [valueKey]: threshholdValue } as unknown as T;
        result.push(thresholdSlice);
    }

    result.sort((a, b) => (b[valueKey] as number) - (a[valueKey] as number));

    if (props.settings?.topN) {
        result.splice(props.settings.topN);
    }
    return result;
}

const PositionWrapper = styled.div<{ chartMargin: ChartMargin; width: number }>`
    position: absolute;
    width: ${(p) => p.width - 16}px;
    margin-left: ${(p) => p.chartMargin.left}px;
    margin-right: ${(p) => p.chartMargin.right}px;
    margin-top: ${(p) => p.chartMargin.top}px;
    margin-bottom: ${(p) => p.chartMargin.bottom}px;
`;

const CenterWrapper = styled.div<{ chartMargin: ChartMargin; width: number }>`
    position: absolute;
    height: ${(p) => p.width - 16}px;
    width: ${(p) => p.width - 16}px;
    margin-left: ${(p) => p.chartMargin.left}px;
    margin-right: ${(p) => p.chartMargin.right}px;
    margin-top: ${(p) => p.chartMargin.top}px;
    margin-bottom: ${(p) => p.chartMargin.bottom}px;
`;
// #endregion

// #region User Configured Pie Chart
export interface UserConfiguredPieChartSettings {
    valueFormat?: ValueFormat<number, void> | undefined | NamedFormats;
    sortOptions?: IChartSortConfig;
    descriptors?: IPlotFieldDescriptor[];
    margin?: ChartMargin;
}
interface UserConfiguredPieChartProps<T> extends StandardChartProps<string | number, T> {
    settings?: UserConfiguredPieChartSettings;
}
export const UserConfiguredPieChart = withContainerDimensions(function UserConfiguredPieChart<T extends Record<string, string | number>>(
    props: UserConfiguredPieChartProps<T> & { width: number; height: number }
) {
    const { data, groups, values, settings, width, height } = props;
    const metricField = (values[0] ?? 'value') as string;
    const dimensionFields = groups as string[];
    const { valueFormat, sortOptions: sortConfig, descriptors, margin } = getDefaultSettings(settings ?? {});
    const fmtSvc = useFmtSvc();
    const valueFormatter = fmtSvc.getFormatter(valueFormat, 'number')?.format;

    const { slices, stats } = useMemo(
        () => transformPieSliceData(data, { dimensionFields, metricField, valueFormatter, descriptors, sortConfig }),
        [data, JSON.stringify([dimensionFields, sortConfig]), metricField, valueFormatter]
    );

    const containerSize = { w: width, h: height };
    const minLblSize = { w: 36, h: 16 };

    const { slices: bindData, visualStats } = useMemo(
        () => createPlottableSliceData({ stats, slices, containerSize, margin, minLblSize }),
        [containerSize.w, containerSize.h, slices, stats]
    );

    const { outer: outerArc, inner: innerArc, innerRadiusPct, labelRadiusOffsetPct } = visualStats;
    const size: PieDetailsSize =
        innerArc.diameterPx > 300 ? 'full' : innerArc.diameterPx > 220 ? 'partial' : innerArc.diameterPx > 100 ? 'compact' : 'mini';

    const hoveredSlice = useMemo(() => new EventEmitter<IPlottablePieSlice | undefined>(undefined), [bindData]);
    const handleHover = useCallback((s: ComputedDatum<IPlottablePieSlice>) => hoveredSlice.emit(s.data), [hoveredSlice]);
    const handleMouseLeave = useCallback(() => {
        if (size === 'mini' || size === 'compact') {
            hoveredSlice.emit(undefined);
        }
    }, [hoveredSlice, size]);

    const centerLayer = createPieCenterLayer({ dataStats: stats, visualStats, hoveredSlice, containerSize, valueFormatter, margin, size });
    const arcLinkLabelLayer = createPieArcLinkLabelLayer(visualStats, hoveredSlice, size);
    const arcLabelComponent = createPieArcLabelComponent(stats, minLblSize, hoveredSlice);

    const layers: PieLayer<IPlottablePieSlice>[] = [centerLayer, arcLinkLabelLayer, 'arcs', 'arcLabels'];

    return (
        <ResponsivePie
            layers={layers}
            data={bindData}
            id="id"
            value="size"
            colors={(s) => s.data.color}
            innerRadius={innerRadiusPct}
            padAngle={outerArc.padAngleDeg}
            cornerRadius={2}
            onMouseMove={handleHover}
            onMouseLeave={handleMouseLeave}
            animate
            activeOuterRadiusOffset={5}
            arcLabelsRadiusOffset={labelRadiusOffsetPct}
            tooltip={() => <></>}
            enableArcLinkLabels={false}
            arcLinkLabelsStraightLength={0}
            arcLinkLabelsDiagonalLength={10}
            arcLabelsComponent={arcLabelComponent}
            margin={margin}
        />
    );
});

function createPieCenterLayer(crossSliceProps: IPieCenterContentProps) {
    const { containerSize, margin } = crossSliceProps;
    const { inner } = crossSliceProps.visualStats;

    const padding = inner.diameterPx * 0.2;
    const x = containerSize.w / 2 - margin.left - inner.radiusPx + padding / 2;
    const w = inner.diameterPx - padding;
    const y = containerSize.h / 2 - margin.top - inner.radiusPx + padding / 2;
    const h = inner.diameterPx - padding;

    return function PieCenterLayer(props: PieCustomLayerProps<IPlottablePieSlice>) {
        return (
            <g data-layer-type="pieCenter">
                <foreignObject x={x} y={y} width={w} height={h}>
                    <PieCenterContent {...crossSliceProps} containerSize={{ w, h }} />
                </foreignObject>
            </g>
        );
    };
}

type PieDetailsSize = 'full' | 'partial' | 'compact' | 'mini';
const detailSizeFont = { full: 24, partial: 18, compact: 16, mini: 11 };
interface IPieCenterContentProps {
    dataStats: IPieSliceStats;
    visualStats: IPlottablePieVisualStats;
    hoveredSlice: EventEmitter<IPlottablePieSlice | undefined>;
    valueFormatter: INamedFormatter['format'];
    margin: ChartMargin;
    containerSize: { w: number; h: number };
    size: PieDetailsSize;
}
function PieCenterContent(props: IPieCenterContentProps) {
    const { dataStats, visualStats, hoveredSlice, containerSize, size, valueFormatter } = props;
    const { total } = dataStats;

    const slice = useEventValue(hoveredSlice);
    const containerSx: Sx = { width: containerSize.w, height: containerSize.h, overflow: 'hidden' };

    const totalFmt = valueFormatter(total, 'N/A');

    return (
        <Stack align="center" justify="center" spacing={0} sx={containerSx}>
            {!slice || size === 'mini' || size === 'compact' ? null : <SliceDetail {...{ size, dataStats, slice, total, valueFormatter }} />}
            <Score
                maxSize={detailSizeFont[size]}
                titleAlign="center"
                value={(style) => <Text sx={{ maxWidth: '100%', ...(style as Sx) }}>{totalFmt}</Text>}
            />
            <PieSliceTooltipContainer {...props} />
            <Text sx={{ maxWidth: '100%' }} align="center" color="gray.5" size={Math.min(12, detailSizeFont[size])}>
                Total
            </Text>
        </Stack>
    );
}

interface ISliceDetailProps {
    dataStats: IPieSliceStats;
    slice: IPlottablePieSlice;
    valueFormatter: INamedFormatter['format'];
    size: PieDetailsSize | 'complete';
}
function SliceDetail(props: ISliceDetailProps) {
    const { colors } = useMantineTheme();
    const fmtSvc = useFmtSvc();
    const { dataStats } = props;
    const { slice, valueFormatter, size } = props;
    const { positive, negative, label, formattedTotal, color } = slice;
    const labelSx = { borderBottom: `solid 2px ${color}`, color: colors.gray[7], fontSize: '12px', fontWeight: 'bold' as const, background: '#fff' };
    const { floatOnMouseEnter } = useFloatedFullText(labelSx);

    return (
        <>
            <Text component={EllipsisTextEl} sx={{ maxWidth: '100%', ...labelSx }} onMouseEnter={floatOnMouseEnter}>
                {label}
            </Text>
            {size !== 'full' && size !== 'complete' ? null : <PositiveNegativeLabel {...{ positive, negative, valueFormatter, dataStats }} />}
            {size === 'complete' ? (
                <Group noWrap position="apart" spacing={5}>
                    <Text size="sm">({fmtSvc.formatPercent(slice.total / dataStats.total)})</Text>
                    <Text>{formattedTotal}</Text>
                </Group>
            ) : (
                <Score maxSize={detailSizeFont[size]} value={formattedTotal} />
            )}
        </>
    );
}
function PositiveNegativeLabel(props: { positive: number; negative: number; valueFormatter: INamedFormatter['format']; dataStats: IPieSliceStats }) {
    const fmtSvc = useFmtSvc();
    const { positive, negative, valueFormatter, dataStats } = props;
    const { total } = dataStats;

    const posTxt = `${valueFormatter(positive, 'N/A')} (${fmtSvc.formatPercent(positive / total, 100)})`;
    const negTxt = `${valueFormatter(negative, 'N/A')} (${fmtSvc.formatPercent(positive / total, 100)})`;
    return (
        <>
            {positive === 0 || negative === 0 ? null : (
                <Box>
                    <PieLabelSm color="dark" indicator="up" text={posTxt} />
                    <PieLabelSm color="dark" indicator="down" text={negTxt} />
                </Box>
            )}
        </>
    );
}

function PieSliceTooltipContainer(props: IPieSliceTooltipProps) {
    const { visualStats, size, hoveredSlice } = props;
    const tooltipId = useId(visualStats)?.toString();
    const model = useFlyoverModel(tooltipId);
    const containerEl = useRef<HTMLDivElement | null>(null);

    useEvent(
        hoveredSlice,
        useCallback(
            (slice: IPlottablePieSlice | undefined) => {
                if (containerEl.current && slice) {
                    const { width, height } = containerEl.current.getBoundingClientRect();
                    const radius = Math.min(width, height) / 2;
                    const { negative, positive } = slice;
                    if (size === 'mini' || (size !== 'full' && negative && positive)) {
                        model.provideAnchoredFlyover({
                            renderer: () => <PieSliceTooltip {...props} />,
                            subject: containerEl.current,
                            anchor: ['center-right', 'center-left'],
                            subjectAnchor: 'center-center',
                            offsetX: radius + visualStats.outer.radiusPx + 20,
                            nonInteractive: true,
                        });
                    }
                } else {
                    model.invalidateFlyover();
                }
            },
            [model, containerEl.current, size, hoveredSlice]
        )
    );

    return (
        <Box sx={{ width: '100%', height: 1 }} ref={containerEl}>
            <FlyoverHost key={tooltipId} routeBound flyoverKey={tooltipId} />
        </Box>
    );
}

interface IPieSliceTooltipProps {
    dataStats: IPieSliceStats;
    visualStats: IPlottablePieVisualStats;
    valueFormatter: INamedFormatter['format'];
    hoveredSlice: EventEmitter<IPlottablePieSlice | undefined>;
    size: PieDetailsSize;
}
function PieSliceTooltip(props: IPieSliceTooltipProps) {
    const { hoveredSlice, dataStats, valueFormatter } = props;
    const slice = useEventValue(hoveredSlice);

    return (
        <Card shadow="md" withBorder sx={{ width: 200 }}>
            {!slice ? null : <SliceDetail {...{ dataStats, size: 'complete', valueFormatter, slice: slice }} />}
        </Card>
    );
}

function PieLabelSm(props: ComponentPropsWithoutRef<typeof PieLabelSmEl> & { indicator?: 'up' | 'down'; text: string }) {
    const { indicator, text, ...rest } = props;
    return (
        <Group noWrap position="center" spacing={5}>
            {!indicator ? null : <IncreaseIndicator size="sm" value={indicator === 'up' ? 1 : -1} />}
            <PieLabelSmEl {...rest}>{text}</PieLabelSmEl>
        </Group>
    );
}

const PieLabelSmEl = styled.span<{ size?: 10 | 12; color: 'light' | 'dark' }>`
    font-size: ${(p) => (p.size === 12 ? 12 : 10)}px;
    color: ${(p) => (p.color === 'light' ? p.theme.colors.gray[4] : p.theme.colors.gray[7])};
`;

function createPieArcLinkLabelLayer(
    visualStats: IPlottablePieVisualStats,
    hoveredSlice: EventEmitter<IPlottablePieSlice | undefined>,
    size: PieDetailsSize
) {
    const PieArcLinkLabelLayer = (props: PieCustomLayerProps<IPlottablePieSlice>) => {
        const { dataWithArc, centerX, centerY } = props;
        const labelLayouts = useMemo(() => {
            const slices = dataWithArc.map(({ data, arc: { startAngle, angle } }) => ({ slice: data, rawAngleRdn: startAngle + angle / 2 }));
            return transformPieArcLabels({ labelMaxW: 200, labelMinW: 60, lineHeight: 18, slices, visualStats });
        }, [visualStats]);
        const tooltipId = 'pie-arc-label-' + useId(labelLayouts);
        const hoveredLayout = usePieArcLinkLabelTooltip(labelLayouts, hoveredSlice, tooltipId, size);

        return (
            <>
                <g transform={`translate(${centerX}, ${centerY})`} width="100%" height="100%" data-layer-type="arcLinkLabels">
                    {labelLayouts.map((labelLayout) => (
                        <PieArcLinkLabel key={labelLayout.slice.id} {...{ labelLayout, hovered: hoveredLayout === labelLayout, centerX, centerY }} />
                    ))}
                </g>
                <FlyoverHost routeBound flyoverKey={tooltipId} />
            </>
        );
    };
    return useMemo(() => PieArcLinkLabelLayer, [visualStats]);
}
function usePieArcLinkLabelTooltip(
    labelLayouts: IPieArcLabelLayout[],
    hoveredSlice: EventEmitter<IPlottablePieSlice | undefined>,
    tooltipId: string,
    detailSize: PieDetailsSize
) {
    const sizeProvider = useSizeCtx();
    const [show, hide] = useTooltip({}, tooltipId);
    const layoutLookup = useMemo(() => new Map(labelLayouts.map((l) => [l.slice.id, l])), [labelLayouts]);
    const slice = useEventValue(hoveredSlice);
    const [shown, setShown] = useState<IPieArcLabelLayout>();
    useEffect(() => {
        const layout = layoutLookup.get(slice?.id ?? '');
        const size = sizeProvider.getSize();
        if (layout && size && detailSize === 'compact' && (layout.slice.positive === 0 || layout.slice.negative === 0)) {
            const { x, y, width, height } = size;
            const [, offset] = layout.connector;
            show({
                transition: 'none',
                renderer: () => <PieArcLinkLabelContent labelLayout={layout} dim="full" shadow />,
                x: x + width / 2,
                y: y + height / 2,
                anchor: layout.orientation === 'left' ? 'center-right' : 'center-left',
                offsetX: layout.orientation === 'left' ? -offset.x : offset.x,
                offsetY: -offset.y,
            });
            setShown(layout);
        } else {
            hide();
            setShown(undefined);
        }
    }, [slice]);

    return shown;
}
function PieArcLinkLabel(props: { labelLayout: IPieArcLabelLayout; hovered: boolean }) {
    const { labelLayout, hovered } = props;
    const { fit, visible, connector, slice } = labelLayout;

    return (
        <g>
            {!hovered && !visible ? null : (
                <line x1={connector[0].x} y1={connector[0].y} x2={connector[1].x} y2={connector[1].y} stroke={slice.color} />
            )}
            {!visible ? null : (
                <foreignObject x={fit.x} y={fit.y} width={fit.w} height={fit.h}>
                    <PieArcLinkLabelContent labelLayout={labelLayout} dim="fit" />
                </foreignObject>
            )}
        </g>
    );
}

function PieArcLinkLabelContent({ labelLayout, dim, shadow }: { labelLayout: IPieArcLabelLayout; dim: 'fit' | 'full'; shadow?: boolean }) {
    const { shadows } = useMantineTheme();
    const { orientation, slice } = labelLayout;
    const { color, formattedTotal, label } = slice;
    const { w: maxWidth, h: height } = labelLayout[dim];
    const pad = 4;
    const lineHeight = height / 2 - pad;
    const borderProp = orientation === 'left' ? 'borderRight' : ('borderLeft' as const);
    const boxSx: Sx = {
        padding: '3px 6px',
        background: 'white',
        [borderProp]: `solid 3px ${color}`,
        height,
        maxWidth,
        ['> div']: { lineHeight: `${lineHeight}px`, textAlign: orientation == 'left' ? 'right' : 'left' },
        boxShadow: shadow ? shadows.sm : 'none',
    };
    const ellipsisCls = useEllipsisTextClass();
    return (
        <Box sx={boxSx}>
            <Text className={ellipsisCls} size={11}>
                {label}
            </Text>
            <Text className={ellipsisCls} size={11}>
                {formattedTotal}
            </Text>
        </Box>
    );
}

function createPieArcLabelComponent(
    stats: IPieSliceStats,
    lblSize: { w: number; h: number },
    hoverSlice: EventEmitter<IPlottablePieSlice | undefined>
) {
    const { w, h } = lblSize;
    return function PieArcLabel(props: ComponentPropsWithoutRef<typeof ArcLabelComponent>) {
        const fmtSvc = useFmtSvc();
        const { data: slice } = props.datum as ComputedDatum<IPlottablePieSlice>;
        const hovered = useEventValue(hoverSlice) === slice;
        const opacity = hovered || (!slice.hideLabel && !slice.isOther) ? 1 : 0;
        return (
            <>
                <animated.g transform={props.style.transform} style={{ pointerEvents: 'none', opacity }}>
                    <path d={createCapsulePath(w, h)} fill={setLightness(slice.color, 0.94)} stroke={slice.color} />
                    <text
                        fill={setLightness(slice.color, 0.25)}
                        style={{ fontSize: 10 }}
                        textAnchor="middle"
                        dominantBaseline="middle"
                        transform="translate(0, 1)"
                    >
                        {fmtSvc.formatPercent(slice.total / stats.total, 100)}
                    </text>
                </animated.g>
            </>
        );
    };
}

function getDefaultSettings(settings: UserConfiguredPieChartSettings) {
    return {
        descriptors: settings.descriptors ?? [],
        margin: settings.margin ?? { left: 18, bottom: 18, top: 18, right: 18 },
        sortOptions: settings.sortOptions ?? { sortBy: 'value', order: 'desc', ...(settings.sortOptions ?? {}) },
        valueFormat: typeof settings.valueFormat === 'string' ? (settings.valueFormat as NamedFormats) : 'number',
    };
}
// #endregion
