import styled from '@emotion/styled';
import { createStyles } from '@mantine/core';
import { theme } from './Themes';

class GlyphSizeInfo {
    public readonly key: string;
    public readonly size: string;
    public readonly style: string;

    public constructor(public readonly family: string, public readonly weight: string, size: string | number) {
        this.size = typeof size === 'number' ? size + 'px' : size;
        this.key = `${this.family}-${this.size}-${this.weight}`;
        this.style = `${this.weight} ${this.size} ${this.family}`;
    }
}

class GlyphSizes {
    private readonly sizeLookup = new Map<string, number>();
    private averageWidth?: number;
    private maxAscent?: number;
    private maxDescent?: number;

    public constructor(public readonly sizeInfo: GlyphSizeInfo) {}

    public add(character: string, width: number, ascent: number, descent: number) {
        this.sizeLookup.set(character, width);
        this.maxAscent = Math.max(this.maxAscent ?? 0, ascent);
        this.maxDescent = Math.max(this.maxDescent ?? 0, descent);
    }
    public get(characters: string) {
        let result = 0;
        for (const c of characters) {
            result += this.getCharWidth(c);
        }
        return result;
    }
    public getMaxHeight() {
        return (this.maxAscent ?? 0) + (this.maxDescent ?? 0);
    }
    private getCharWidth(c: string) {
        return this.sizeLookup.get(c) ?? this.getAverageWidth();
    }
    private getAverageWidth() {
        if (this.averageWidth !== undefined) {
            return this.averageWidth;
        }
        const sizes = Array.from(this.sizeLookup.values());
        return sizes.reduce((a, b) => a + b, 0) / sizes.length;
    }

    public static getFallback(sizeInfo: GlyphSizeInfo) {
        const result = new GlyphSizes(sizeInfo);
        const sizePx = parseInt(sizeInfo.size);
        const averageWidth = sizePx * 0.6;
        result.averageWidth = averageWidth;
        return result;
    }
}

class GlyphSizeCache {
    private readonly cache = new Map<string, GlyphSizes>();

    private readonly knownSizes = [11, 12, 14, 16, 18, 20, 22, 26, 34].map((size) => size + 'px');
    private readonly knownFamilies = [theme.fontFamily as string];
    private readonly knownWeights = ['normal', 'bold'];
    private readonly cachedGlyphCodeRange = { from: 0, to: 255 };

    public constructor() {
        this.prepopulate();
    }

    private prepopulate() {
        const ctx = this.createCanvasCtx();
        if (!ctx) {
            return;
        }

        const sizeInfos = this.knownFamilies.flatMap((fam) =>
            this.knownSizes.flatMap((size) => this.knownWeights.map((weight) => new GlyphSizeInfo(fam, weight, size)))
        );
        for (const info of sizeInfos) {
            this.cache.set(info.key, this.createSizes(info, ctx));
        }
    }

    public getTextSizes(text: string[], size: string | number = 16, family?: string, weight: string = 'normal') {
        family ??= theme.fontFamily as string;
        const sizeInfo = new GlyphSizeInfo(family, weight, size);
        const sizes = this.getSizes(sizeInfo);
        const widths = text.map((t) => sizes.get(t));

        return { widths, height: sizes.getMaxHeight() };
    }

    public getSize(text: string, size: string | number = 16, family?: string, weight: string = 'normal') {
        const {
            widths: [width],
            height,
        } = this.getTextSizes([text], size, family, weight);
        return { width, height };
    }

    public getWidth(text: string, size: string | number = 16, family?: string, weight: string = 'normal') {
        this.getSize(text, size, family, weight).width;
    }

    private createCanvasCtx() {
        const canvas = document.createElement('canvas');
        return canvas.getContext('2d');
    }

    private getSizes(info: GlyphSizeInfo) {
        if (!this.cache.has(info.key)) {
            this.cache.set(info.key, this.createSizes(info));
        }
        return this.cache.get(info.key)!;
    }

    private createSizes(info: GlyphSizeInfo, ctx?: CanvasRenderingContext2D) {
        const result = new GlyphSizes(info);
        ctx ??= this.createCanvasCtx() ?? undefined;

        if (!ctx) {
            return GlyphSizes.getFallback(info);
        }

        this.populateSizes(ctx, result);

        return result;
    }

    private populateSizes(ctx: CanvasRenderingContext2D, sizes: GlyphSizes) {
        const { from, to } = this.cachedGlyphCodeRange;

        ctx.font = sizes.sizeInfo.style;
        for (let c = from; c < to; c++) {
            const character = String.fromCharCode(c);
            const metrics = ctx.measureText(character);
            sizes.add(character, metrics.width, metrics.actualBoundingBoxAscent, metrics.actualBoundingBoxDescent);
        }
    }
}

export const glyphSizeCache = new GlyphSizeCache();
export const measureTextSize = glyphSizeCache.getTextSizes.bind(glyphSizeCache);
export const measureTextWidth = glyphSizeCache.getWidth.bind(glyphSizeCache);

export function useGlyphWidth(text: string, size: string | number = 16, family?: string, weight: string = 'normal') {
    return glyphSizeCache.getWidth(text, size, family, weight);
}

export const EllipsisTextEl = styled.div`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
`;
const useEllipsisTextStyle = createStyles((theme) => ({
    text: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
}));
export function useEllipsisTextClass() {
    return useEllipsisTextStyle().classes.text;
}
