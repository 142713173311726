import { NotificationTypePreference, UserDefinedNotification, UserDefinedNotificationRequest } from '@apis/Notification/model';
import styled from '@emotion/styled';
import { ActionIcon, Box, Card, Center, Group, Menu, Space, Stack, Text, ThemeIcon, Tooltip } from '@mantine/core';
import { OptionMenuButton, OptionMenuItems, OptionMenuItemTypes } from '@root/Components/Picker/OptionMenu';
import { EmptyDataText } from '@root/Components/Text/VisibleSpaces';
import { FillerMessage, FillerSwitch } from '@root/Design/Filler';
import { AnchorButton } from '@root/Design/Primitives';
import { AuthenticationService } from '@root/Services/AuthenticationService';
import { CompanyUserService, ICompanyUserList, UserRecord } from '@root/Services/Customers/CompanyUserService';
import { useDiMemo } from '@root/Services/DI';
import { EventEmitter, useEvent, useEventValue, useToggle } from '@root/Services/EventEmitter';
import { useFmtSvc } from '@root/Services/FormatService';
import { UserDefinedNotificationApiService } from '@root/Services/Notification/UserDefinedNotificationApiService';
import { QueryDatasourceMetadata } from '@root/Services/Query/QueryDatasource';
import { QueryDatasourceCollection } from '@root/Services/Query/QueryDatasourceCollection';
import { useCallback, useEffect, useMemo } from 'react';
import {
    AlertTriangle,
    Edit,
    History,
    Icon,
    LayoutSidebarRight,
    Mail,
    Mailbox,
    Refresh,
    SortAscending,
    SortAZ,
    SortDescending,
    SortZA,
} from 'tabler-icons-react';
import { inject, injectable } from 'tsyringe';
import { describeComponent } from '../Components/ComponentDescriptor';
import { describeTrigger } from '../Components/Trigger';
import { useNotificationViewer } from '../NotificationOpener';

interface INotificationDefinitionListControls {
    config: EventEmitter<INotificationDefinitionListConfig>;
    loading: EventEmitter<boolean>;
    refresh: () => Promise<void>;
    provideRefreshHandler: (refreshHandler: () => void) => void;
    hasRefreshProvider?: boolean;
}

interface INotificationDefinitionListConfig {
    ownedBy?: number;
    notificationIds?: number[];
    latestOnly?: boolean;
    definitionId?: number;
    sort?: {
        field: 'name' | 'lastSent' | 'updatedAt';
        direction: 'asc' | 'desc';
    };
}
@injectable()
class NotificationDefinitionListModel {
    public readonly state = new EventEmitter<'loading' | 'ready' | 'error'>('loading');
    private typePrefs?: { list: NotificationTypePreference[]; lookup: Map<number, NotificationTypePreference> };
    private notificationsRequest?: { requestKey: string; items: UserDefinedNotification[] };
    private users?: ICompanyUserList;
    private listConfig: INotificationDefinitionListConfig = {
        latestOnly: true,
    };

    public listItems?: NotificationListItemModel[];

    public constructor(
        @inject(UserDefinedNotificationApiService) private readonly notificationApi: UserDefinedNotificationApiService,
        @inject(CompanyUserService) private readonly companyUserSvc: CompanyUserService,
        @inject(QueryDatasourceCollection) private readonly datasources: QueryDatasourceCollection
    ) {}

    public init(listConfig?: INotificationDefinitionListConfig) {
        this.updateListConfig(listConfig ?? this.listConfig);
        return this;
    }

    public reload = () => {
        this.typePrefs = undefined;
        this.notificationsRequest = undefined;
        this.listItems = undefined;
        this.users = undefined;
        return this.load();
    };

    public updateListConfig = (listConfig?: INotificationDefinitionListConfig) => {
        this.listConfig = !listConfig ? { latestOnly: true } : { ...listConfig };
        this.load();
    };

    public get isFiltered() {
        return !!this.listConfig.ownedBy || !!this.listConfig.definitionId || !!this.listConfig.notificationIds?.length;
    }

    private async load() {
        try {
            this.state.emit('loading');

            await Promise.all([this.loadTypePrefs(), this.loadNotifications(), this.loadUsers()]);
            this.listItems = this.createListItems();
            this.applySort();

            this.state.emit('ready');
        } catch (e) {
            this.state.emit('error');
        }
    }

    private async loadTypePrefs() {
        if (this.typePrefs === undefined) {
            const typePrefs = await this.notificationApi.getNotificationDefinitions();
            this.typePrefs = {
                list: typePrefs,
                lookup: new Map(typePrefs.map((tp) => [tp.Id ?? 0, tp])),
            };
        }
    }

    private async loadNotifications() {
        const createKey = ({ ownedBy, ...requestConfig }: INotificationDefinitionListConfig) => {
            return JSON.stringify(requestConfig);
        };

        const requestKey = createKey(this.listConfig);
        if (this.notificationsRequest?.requestKey !== requestKey) {
            const { definitionId: DefinitionId, latestOnly: LatestOnly, notificationIds: NotificationIds } = this.listConfig;
            const notifications = await this.notificationApi.getNotifications({ DefinitionId, LatestOnly, NotificationIds });
            this.notificationsRequest = {
                requestKey,
                items: notifications,
            };
        }
    }

    private async loadUsers() {
        if (this.users === undefined) {
            this.users = await this.companyUserSvc.getUserList();
        }
    }

    private createListItems() {
        const { lookup } = this.typePrefs ?? { list: [], lookup: new Map<number, NotificationTypePreference>() };
        const notifications = this.notificationsRequest?.items ?? [];
        const typesWithNotifications = new Set<number>();

        const result: NotificationListItemModel[] = [];

        for (const item of notifications) {
            const typePref = lookup.get(item.NotificationTypePreferenceId ?? 0);
            typesWithNotifications.add(typePref?.Id ?? 0);
            result.push(new NotificationListItemModel(item, typePref ?? {}, this.users, this.datasources));
        }

        for (const type of this.typePrefs?.list ?? []) {
            if (!typesWithNotifications.has(type.Id ?? 0)) {
                result.push(new NotificationListItemModel(undefined, type, this.users, this.datasources));
            }
        }

        return result;
    }

    private applySort() {
        const { field, direction } = this.listConfig.sort ?? { field: 'name', direction: 'asc' };
        const fieldAccessor =
            field === 'name'
                ? (item: NotificationListItemModel) => item.title
                : field === 'lastSent'
                ? (item: NotificationListItemModel) => item.notification?.Date ?? ''
                : field === 'updatedAt'
                ? (item: NotificationListItemModel) => item.typePref.UpdatedAt ?? ''
                : (item: NotificationListItemModel) => item.title;
        this.listItems = this.listItems?.sort((a, b) => {
            const aVal = fieldAccessor(a);
            const bVal = fieldAccessor(b);
            return direction === 'asc' ? aVal.localeCompare(bVal) : bVal.localeCompare(aVal);
        });
    }
}

interface INotficationDefinitionListProps {
    onRefreshHandlerReady?: (refreshHandler: () => Promise<void>) => void;
    config?: INotificationDefinitionListConfig;
    onLoadingChange?: (loading: boolean) => void;
}
export function NotificationDefinitionList(props: INotficationDefinitionListProps) {
    const { config, onRefreshHandlerReady, onLoadingChange } = props;
    const model = useDiMemo(NotificationDefinitionListModel, [], (m) => m.init(config));
    useEffect(() => {
        onRefreshHandlerReady?.(model.reload);
    }, [model]);

    useEffect(() => model.updateListConfig(config), [config, model]);

    const state = useEventValue(model.state);
    useEffect(() => {
        onLoadingChange?.(state === 'loading');
    }, [state, onLoadingChange]);

    return (
        <FillerSwitch loading={state === 'loading'}>
            {() =>
                state === 'error' ? (
                    <FillerMessage icon={<AlertTriangle />} title="Error" message="Failed to load notification settings. Please contact support. " />
                ) : !model.listItems?.length && !model.isFiltered ? (
                    <FillerMessage icon={<Mailbox />} title="Nothing Here" message="No notifications have been set up yet. " />
                ) : !model.listItems?.length ? (
                    <FillerMessage icon={<Mailbox />} title="Nothing Here" message="No notifications found. " />
                ) : (
                    <NotificationList items={model.listItems} />
                )
            }
        </FillerSwitch>
    );
}

class NotificationDefinitionListControlsModel implements INotificationDefinitionListControls {
    private refreshHandler?: () => void;

    public readonly loading = new EventEmitter<boolean>(true);

    public readonly config = new EventEmitter<INotificationDefinitionListConfig>({
        latestOnly: true,
        sort: { field: 'name', direction: 'asc' },
    });

    public refresh = async () => {
        if (!this.loading.value) {
            await this.refreshHandler?.();
        }
    };

    public provideRefreshHandler = (refreshHandler: () => void) => {
        this.refreshHandler = refreshHandler;
    };

    public get hasRefreshProvider() {
        return !!this.refreshHandler;
    }
}
export function useNotificationDefinitionListControls() {
    return useMemo(() => new NotificationDefinitionListControlsModel(), []);
}
interface INotificationDefinitionListControlsProps {
    controls: INotificationDefinitionListControls;
}
export function NotificationDefinitionListControls({ controls }: INotificationDefinitionListControlsProps) {
    const config = useEventValue(controls.config);
    const loading = useEventValue(controls.loading);
    const [sortMenuOpened, { close: closeSortMenu, toggle: toggleSortMenu }] = useToggle(false);

    type SortField = Required<INotificationDefinitionListConfig>['sort']['field'];

    const sortOptions = useMemo(() => {
        const sortKey = (name: string, direction: string) => `${name}-${direction}`;
        const selectedId = sortKey(config?.sort?.field ?? 'name', config?.sort?.direction ?? 'asc');
        const sortIcon: { [K in SortField]: (asc: boolean) => Icon } = {
            name: (asc: boolean) => (asc ? SortAZ : SortZA),
            lastSent: (asc: boolean) => (asc ? SortAscending : SortDescending),
            updatedAt: (asc: boolean) => (asc ? SortAscending : SortDescending),
        };
        const createMenuIcon = (Icon: Icon) => <Icon size={18} />;

        const createSortOption = ({ field, label }: { field: SortField; label: string }, direction: 'asc' | 'desc') =>
            ({
                label: `Sort by ${label} ${direction === 'asc' ? 'Ascending' : 'Descending'}`,
                tooltip: `Sorted by: ${label} ${direction === 'asc' ? 'Ascending' : 'Descending'}`,
                icon: createMenuIcon(sortIcon[field](direction === 'asc')),
                selected: selectedId === sortKey(field, direction),
                onClick: () => controls.config.emit({ sort: { field, direction } }),
                iconType: sortIcon[field](direction === 'asc'),
            } as OptionMenuButton & { tooltip: string; iconType: Icon });

        const options: { field: SortField; label: string }[] = [
            { field: 'name', label: 'Title' },
            { field: 'lastSent', label: 'Sent' },
            { field: 'updatedAt', label: 'Last Updated' },
        ];

        return options.flatMap((item) => [createSortOption(item, 'asc'), createSortOption(item, 'desc')]);
    }, [config?.sort]);

    const selectedSortOption = sortOptions.find((o) => o.selected);
    const SortIcon = selectedSortOption?.iconType ?? SortAZ;
    const sortTooltip = selectedSortOption?.tooltip ?? 'Sorted by: Title Ascending';

    return (
        <Group noWrap>
            <Menu shadow="sm" opened={sortMenuOpened} onClose={closeSortMenu} withinPortal position="bottom-end" withArrow offset={0}>
                <Menu.Target>
                    <Center>
                        <Tooltip disabled={sortMenuOpened} label={sortTooltip} position="bottom" withinPortal>
                            <ActionIcon color="primary" variant="light" onClick={toggleSortMenu}>
                                <SortIcon />
                            </ActionIcon>
                        </Tooltip>
                    </Center>
                </Menu.Target>
                <Menu.Dropdown>
                    <OptionMenuItems options={sortOptions} close={closeSortMenu} />
                </Menu.Dropdown>
            </Menu>
            {!controls.hasRefreshProvider ? null : (
                <Tooltip label="Refresh" position="bottom">
                    <ActionIcon color="primary" onClick={controls.refresh} disabled={loading}>
                        <Refresh size={18} />
                    </ActionIcon>
                </Tooltip>
            )}
        </Group>
    );
}

function NotificationList({ items }: { items: NotificationListItemModel[] }) {
    return (
        <ListItemContainerStyle>
            {items.map((item, i) => (
                <NotificationDefinitionListItem key={i} item={item} />
            ))}
        </ListItemContainerStyle>
    );
}
const ListItemContainerStyle = styled.div`
    display: content;
    > div {
        max-width: 900px;
        margin: 0.5rem 0;
    }
`;

class NotificationListItemModel {
    public readonly recipients: number;
    public readonly datasource: QueryDatasourceMetadata | undefined;

    public constructor(
        public readonly notification: UserDefinedNotification | undefined,
        public readonly typePref: NotificationTypePreference,
        public readonly userLookup: ICompanyUserList | undefined,
        public readonly datasources: QueryDatasourceCollection
    ) {
        this.recipients = new Set(this.typePref.Recipients?.filter((r) => r.MessageType?.length).map((r) => r.UserId ?? 0) ?? []).size;
        this.datasource = this.datasources.getDatasourceMeta(this.typePref.NotificationDefinition?.QueryOptions?.DatasourceName ?? '');
    }

    public get definitionId() {
        return this.typePref.Id ?? 0;
    }
    public get title() {
        return this.typePref.NotificationDefinition?.Title ?? 'Untitled';
    }
    public get trigger() {
        return this.typePref.NotificationDefinition?.Trigger ?? {};
    }
    public get description() {
        return this.typePref.NotificationDefinition?.Description ?? '';
    }
    public get owner() {
        return this.userLookup?.getById(this.typePref.UserId ?? 0);
    }

    public getRecipient(userId: number) {
        return this.typePref.Recipients?.find((r) => r.UserId === userId);
    }

    public getUser(userId: number) {
        return this.userLookup?.getById(userId);
    }
}

function NotificationDefinitionListItem(props: { item: NotificationListItemModel }) {
    const { item } = props;
    const fmtSvc = useFmtSvc();
    const authSvc = useDiMemo(AuthenticationService);
    const { open } = useNotificationViewer();

    const edit = useCallback(() => {
        open({ mode: 'edit', typePrefId: item?.definitionId, scope: { context: {} } });
    }, [item, open]);

    const view = useCallback(() => {
        open({ mode: 'view', typePrefId: item?.definitionId, notificationId: item.notification?.Id, scope: { context: {} } });
    }, [item, open]);

    const sentAt = item.notification?.Date ? `Last sent: ${fmtSvc.formatDatetime(fmtSvc.toLocalDate(item.notification.Date!))}` : null;

    const triggerDesc = describeTrigger(item.trigger);
    const triggerText = `${triggerDesc?.type ?? 'Unspecified'} ${triggerDesc?.brief}`;
    const TriggerIcon = triggerDesc?.icon ?? Mail;

    const ownedBy = item.owner?.fullName ?? 'Unknown';

    const recipient = item.getRecipient(authSvc.user?.Id ?? 0);
    const deliverBy = !recipient?.MessageType?.length
        ? 'Unsubscribed'
        : `Subsribed to: ${recipient.MessageType.map((t) => (t === 'InApp' ? 'In-App' : t)).join(', ')}`;

    const componentDetails = describeComponent(item.typePref.NotificationDefinition?.ComponentConfig ?? {});
    const VisIcon = componentDetails.icon;
    const datasource = item.datasource ? `${item.datasource.nounPlural}` : 'Unspecified data source';
    const notificationBriefDesc = `From ${fmtSvc.titleCase(datasource)}, ${triggerText}`;

    return (
        <Card withBorder p="md" py="md" radius="md">
            <Stack spacing={4}>
                <Group mt={-2} noWrap position="left" spacing={4}>
                    <AnchorButton iconOnHover iconPosition="right" size="sm" text={item?.title ?? ''} icon={<Edit size={16} />} onClick={edit} />
                </Group>
                {!item.description ? null : <Text size="xs">{item.description}</Text>}
                <Group noWrap spacing={4}>
                    <ThemeIcon size={18} variant="light" color="gray.5">
                        <VisIcon />
                    </ThemeIcon>
                    <Text color="gray.7" size="xs">
                        {notificationBriefDesc}
                    </Text>
                </Group>
                <Group noWrap position="apart">
                    <Group noWrap spacing={16}>
                        <Group noWrap spacing={4}>
                            <ThemeIcon size={18} variant="light" color="gray.5">
                                <History />
                            </ThemeIcon>
                            {!sentAt ? (
                                <Text size="xs">
                                    <EmptyDataText color="dimmed" text="Not yet sent" />
                                </Text>
                            ) : (
                                <AnchorButton size="xs" iconOnHover icon={<LayoutSidebarRight />} text={sentAt} onClick={view} />
                            )}
                        </Group>
                        <Text color="dimmed" size="xs">
                            {deliverBy}
                        </Text>
                        <Text color="dimmed" size="xs">
                            Recipients: {fmtSvc.formatInt0Dec(item.recipients)}
                        </Text>
                    </Group>
                    <Group noWrap spacing={16}>
                        <Text color="dimmed" size="xs">
                            Owner: {ownedBy}
                        </Text>
                        <Text color="dimmed" size="xs">
                            Last updated: {fmtSvc.formatDatetime(fmtSvc.toLocalDate(item.typePref.UpdatedAt)) || 'Unknown'}
                        </Text>
                    </Group>
                </Group>
            </Stack>
        </Card>
    );
}
