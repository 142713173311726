import { QueryExpr } from '@apis/Resources';
import { SchemaType } from '@apis/Resources/model';
import { AuthenticationService } from '@root/Services/AuthenticationService';
import { EventEmitter } from '@root/Services/EventEmitter';
import { cleanBoolExpr, SchemaService } from '@root/Services/QueryExpr';
import { ComponentType, ReactNode } from 'react';
import { inject, injectable } from 'tsyringe';
import { DashboardPersistenceService, IDashboardConfigBase } from '../DashboardPersistence/DashboardPersistenceService';
import { QueryDatasource } from '@root/Services/Query/QueryDatasource';

export interface LayoutItem<T> {
    layout: Layout<T>;
    header?: ((layout: Layout<T>) => ReactNode) | string;
    headerChanged?: EventEmitter<void>;
    menu?: (layout: Layout<T>) => ReactNode;
    noResize?: boolean;
    noMove?: boolean;
    custom?: boolean;
    render(layout: Layout<T>, invalidate: () => void): ReactNode;
    onResize(): void;
    getModel(): DashboardItemModel<T>;
}

export interface Layout<T> {
    x: number;
    y: number;
    w: number;
    h: number;
    data: T;
    layoutItemId?: string;
}

export interface IDashboardConfig extends IDashboardConfigBase {
    layout: Layout<AnonymousDashboardItemConfig>[];
    filters?: QueryExpr[];
    pinnedFilters?: QueryExpr[];
}

export interface IDashboardItemType {
    type: string;
    component: ComponentType<AnonymousDashboardItemConfig>;
    custom?: boolean;
    noResize?: boolean;
    noMove?: boolean;
}

@injectable()
export class DashboardItemTypeService {
    private readonly componentTypes = new Map<string, IDashboardItemType>();
    public getComponentType(type: string) {
        return this.componentTypes.get(type);
    }
    public register(...items: IDashboardItemType[]) {
        for (const item of items) {
            this.componentTypes.set(item.type, item);
        }
    }
}

export type DashboardItemProps<Settings> = { type: string } & { model: DashboardItemModel<Settings> };
export type DashboardItemConfig<Settings> = { type: string; title: string } & Settings;
export type AnonymousDashboardItemConfig = DashboardItemConfig<any>;

@injectable()
export class CustomizableDashboardModel {
    private lastConfigJson = '';
    private config?: IDashboardConfig;
    private dashboardKey: string = 'none';
    private datasources = new Map<string, QueryDatasource>();
    private configId?: number;
    private datasourceSchemas = new Map<string, SchemaType[]>();
    private defaultConfig?: IDashboardConfig;
    private tileDimensionsProvider?: (item: LayoutItem<AnonymousDashboardItemConfig>) => { w?: number; h?: number };

    public configChanged = new EventEmitter<IDashboardConfig | undefined>(undefined);
    public currentLayout: LayoutItem<AnonymousDashboardItemConfig>[] = [];
    public layoutChanged = EventEmitter.empty();
    public filters?: QueryExpr[];
    public pinnedFilters?: QueryExpr[];
    public filtersChanged = EventEmitter.empty();
    public loading = new EventEmitter<boolean>(true);
    public implicitFilter?: QueryExpr[];
    public isStatic = false;
    public schemaSvc!: SchemaService;
    public schemasLoading = new EventEmitter<boolean>(true);
    public ensureVisible = new EventEmitter<LayoutItem<AnonymousDashboardItemConfig> | null>(null);

    public get persistenceKey() {
        return this.dashboardKey;
    }

    public constructor(
        @inject(DashboardItemTypeService) private readonly itemService: DashboardItemTypeService,
        @inject(DashboardPersistenceService) private readonly persistenceService: DashboardPersistenceService,
        @inject(AuthenticationService) private readonly authSvc: AuthenticationService
    ) {}

    public setDatasources(datasources: QueryDatasource[]) {
        this.datasources = new Map(datasources.map((d) => [d.name, d]));
        this.loadDatasourceSchemas();
    }

    public getDatasource(name: string) {
        return this.datasources.get(name);
    }

    public getDatasourceSchema(name: string) {
        return this.datasourceSchemas.get(name);
    }

    public getDatasourceDescription(name: string) {
        const { noun, nounPlural } = this.datasources.get(name) ?? {};
        return { noun: noun ?? 'record', nounPlural: nounPlural ?? 'records' };
    }

    public getDefaultDatasource() {
        for (const datasource of this.datasources.values()) {
            return datasource;
        }
    }

    public updateImplicitFilter(filter?: QueryExpr[]) {
        if (this.implicitFilter !== filter) {
            this.implicitFilter = filter;
            this.filtersChanged.emit();
        }
    }

    public setTileDimensionsProvider(provider: (item: LayoutItem<AnonymousDashboardItemConfig>) => { w?: number; h?: number }) {
        this.tileDimensionsProvider = provider;
    }

    public getTileDimensions(layoutItem: LayoutItem<AnonymousDashboardItemConfig>) {
        return this.tileDimensionsProvider?.(layoutItem);
    }

    public getFilters() {
        const result: QueryExpr[] = [];
        if (this.filters) {
            const validFilters = cleanBoolExpr({ Operation: 'and', Operands: JSON.parse(JSON.stringify(this.filters)) });
            if (validFilters?.Operands) {
                result.push(...validFilters.Operands);
            }
        }
        if (this.implicitFilter) {
            result.push(...this.implicitFilter);
        }
        return result;
    }

    public handleFilterUpdate(filters: QueryExpr[], validOnly: boolean = true) {
        if (validOnly) {
            this.filtersChanged.emit();
        } else {
            this.filters = filters;
            if (this.config) {
                this.config.filters = filters;
                this.saveLayout();
            }
        }
    }

    public handlePinnedFiltersUpdate(filters: QueryExpr[]) {
        this.pinnedFilters = filters;
        if (this.config) {
            this.config.pinnedFilters = filters;
            this.saveLayout();
        }
    }

    public setItemTypes(types: { type: string; component: ComponentType<AnonymousDashboardItemConfig> }[]) {
        this.itemService.register(...types);
    }

    public get title() {
        return this.config?.name ?? '';
    }
    public rename(name: string) {
        if (this.config && this.config.name !== name) {
            this.config.name = name;
            this.saveLayoutChanges(this.currentLayout);
            this.layoutChanged.emit();
        }
    }

    public addItem(data: { type: string }, placement?: Omit<Layout<any>, 'data'>) {
        const result = this.createLayoutItem({
            x: 0,
            y: 1024, //Large number to ensure that the new chart is added to the bottom of the page
            h: 4,
            w: 4,
            layoutItemId: crypto.randomUUID(),
            ...placement,
            data,
        });
        this.currentLayout = [...this.currentLayout, result];
        this.layoutChanged.emit();
        this.ensureVisible.emit(result);
        return result;
    }

    public async load(key: string, defaultConfig: IDashboardConfig, id?: number, isStatic?: boolean) {
        this.dashboardKey = key;
        this.defaultConfig = defaultConfig;
        try {
            this.loading.emit(true);
            this.isStatic = !!isStatic;
            if (!id || !(await this.loadConfig(key, id))) {
                var configs = await this.persistenceService.getLayouts(key);
                const userConfigs = configs.filter((l) => l.ownerUserId === this.authSvc.user?.Id);

                if (userConfigs && userConfigs.length > 0) {
                    configs = userConfigs;
                }
                configs.sort((a, b) => (b.layout.dateAccessed ?? new Date(0)).getTime() - (a.layout.dateAccessed ?? new Date(0)).getTime());

                if (!configs.length || !(await this.loadConfig(key, configs[0].id))) {
                    this.config = defaultConfig;
                }
            }
            this.filters = this.config?.filters;
            this.pinnedFilters = this.config?.pinnedFilters;
            this.currentLayout = this.createLayout(this.config!);
            this.filtersChanged.emit();
        } finally {
            this.loading.emit(false);
        }
    }

    private async loadDatasourceSchemas() {
        try {
            this.schemasLoading.emit(true);
            const schemaRequests = [...this.datasources.values()].map(async (d) => [d.name, await d.schema.getSchema()] as [string, SchemaType[]]);
            const schemas = await Promise.all(schemaRequests);
            this.datasourceSchemas = new Map(schemas);
            this.schemaSvc = new SchemaService(schemas.reduce((result, item) => [...result, ...item[1]], [] as SchemaType[]));
        } finally {
            this.schemasLoading.emit(false);
        }
    }

    private async loadConfig(key: string, id: number) {
        if (this.configId !== id && !this.isStatic) {
            const config = await this.persistenceService.load(key, id);
            if (config) {
                this.configId = id;
                this.config = config;
                this.config?.layout.forEach((l) => {
                    if (l.layoutItemId === undefined) {
                        l.layoutItemId = crypto.randomUUID();
                    }
                });
                this.lastConfigJson = JSON.stringify(this.config);
                return true;
            }
        } else if (this.configId === id) {
            return true;
        }
        return false;
    }

    private createLayout(config: IDashboardConfig) {
        const layoutItems: LayoutItem<AnonymousDashboardItemConfig>[] = [];
        for (const item of config.layout) {
            layoutItems.push(this.createLayoutItem(item));
        }
        return layoutItems;
    }

    private createLayoutItem(layout: Layout<AnonymousDashboardItemConfig>) {
        const layoutType = this.itemService.getComponentType(layout.data.type);

        const result = {
            layout,
            render: (_, invalidate) => {
                model.invalidate = invalidate;
                const Component = this.itemService.getComponentType(layout.data.type)?.component;
                return Component ? <Component {...{ ...layout.data, model }} /> : null;
            },
            header: layoutType?.custom
                ? undefined
                : (l) => {
                      return model.getHeader(l);
                  },
            menu: layoutType?.custom ? undefined : () => model.getMenu(),
            custom: layoutType?.custom,
            noResize: layoutType?.noResize,
            noMove: layoutType?.noResize,
            getModel: () => model,
            onResize: () => model.resized.emit(),
        } as LayoutItem<AnonymousDashboardItemConfig>;
        const model = new DashboardItemModel(this, result);

        return result;
    }

    public saveLayout() {
        this.saveLayoutChanges(this.currentLayout);
    }

    public async saveCopy(name: string) {
        if (this.config) {
            this.config.name = name;
            this.configId = undefined;
            this.saveLayoutChanges(this.currentLayout);
            this.layoutChanged.emit();
        }
    }

    public saveLayoutChanges = async (layoutItems: LayoutItem<AnonymousDashboardItemConfig>[]) => {
        if (this.isStatic) {
            return;
        }
        const layout = layoutItems.map((l) => l.layout);
        if (this.config) {
            const nextJson = JSON.stringify({ ...this.config, layout: layout });
            if (this.lastConfigJson !== nextJson) {
                this.config.layout = layout;
                const id = await this.persistenceService.save(this.dashboardKey, this.configId, this.config);
                this.lastConfigJson = nextJson;
                if (id) {
                    this.configId = id;
                }
            }
        }
    };

    public duplicate(item: LayoutItem<AnonymousDashboardItemConfig>) {
        const idx = this.currentLayout.indexOf(item);
        if (idx >= 0) {
            this.addItem(structuredClone(item.layout.data), { ...item.layout });
        }
        this.layoutChanged.emit();
    }

    public remove(item: LayoutItem<AnonymousDashboardItemConfig>) {
        const idx = this.currentLayout.indexOf(item);
        if (idx >= 0) {
            this.currentLayout.splice(idx, 1);
        }
        this.layoutChanged.emit();
    }

    public handleConfigChange = (config: { id: number; layout: { name: string } }, action: 'rename' | 'delete') => {
        if (this.configId === config.id) {
            if (action === 'delete') {
                this.load(this.dashboardKey, this.defaultConfig!, undefined, this.isStatic);
            } else {
                this.config!.name = config.layout.name;
                this.layoutChanged.emit();
            }
        }
    };
}

export class DashboardItemModel<TSettings> {
    public editOnLoad = false;
    public resized = EventEmitter.empty();
    private headerChanged = EventEmitter.empty();
    private _getHeader: (layout: Layout<DashboardItemConfig<TSettings>>) => ReactNode = () => <></>;

    public get dashboard() {
        return this._dashboard;
    }

    public constructor(
        private readonly _dashboard: CustomizableDashboardModel,
        private readonly _layout: LayoutItem<DashboardItemConfig<TSettings>>
    ) {
        this._layout.headerChanged = this.headerChanged;
    }

    public get settings() {
        return this._layout.layout.data;
    }

    public updateSettings(settings: TSettings) {
        Object.assign(this._layout.layout.data, settings);
    }

    public saveLayout() {
        this.dashboard.ensureVisible.emit(this._layout);
        this.dashboard.saveLayout();
    }

    public get layout() {
        return this._layout.layout;
    }

    public duplicate = () => this._dashboard.duplicate(this._layout);

    public remove = () => this.dashboard.remove(this._layout);

    public get getHeader() {
        return this._getHeader;
    }
    public set getHeader(headerRenderer: (layout: Layout<DashboardItemConfig<TSettings>>) => ReactNode) {
        this._getHeader = headerRenderer ?? (() => <></>);
        this.headerChanged.emit();
    }
    public getMenu = () => <></>;
    public invalidate = () => {};

    public getDatasource(name: string) {
        return this.dashboard.getDatasource(name);
    }

    public getDimensions() {
        return this.dashboard.getTileDimensions(this._layout) ?? {};
    }
}
