import { Menu, Sx, Text, useMantineTheme } from '@mantine/core';
import { AnchorButton } from '@root/Design/Primitives';
import { useDi } from '@root/Services/DI';
import { useEvent } from '@root/Services/EventEmitter';
import { FormatService } from '@root/Services/FormatService';
import {
    createContext,
    DependencyList,
    Fragment,
    MouseEventHandler,
    ReactElement,
    ReactNode,
    useCallback,
    useContext,
    useEffect,
    useId,
    useLayoutEffect,
    useMemo,
    useState,
} from 'react';
import { CaretDown, CaretRight, Check } from 'tabler-icons-react';
import {
    PropertyGridSectionBodyEl,
    PropertyGridArrayItem,
    PropertyGridIndex,
    PropertyGridArrayItemValue,
    PropertyGridValueEl,
    PropertyGridRowEl,
    PropertyGridExpander,
    PropertyGridKey,
} from './Design';
import { PropertyGridItem, PropertyGridViewModel } from './Models';
import { FlyoverOverlay, useAnchoredFlyoverEvents } from '../Picker/Flyover';
import { useIdGen } from '@root/Services/IdGen';
import { OptionMenuButton, OptionMenuItem, OptionMenuItems, OptionMenuItemTypes } from '../Picker/OptionMenu';

type PropertyGridContextType = {
    model: PropertyGridViewModel;
    config: PropertyGridConfig;
    showOptions: (evt: { currentTarget: EventTarget }, item: PropertyGridItem) => void;
};
const PropertyGridCtx = createContext({ model: new PropertyGridViewModel({}), config: {}, showOptions: () => {} } as PropertyGridContextType);
function usePropertyGridCtx() {
    return useContext(PropertyGridCtx);
}

type PropertyGridOptionProviderCtxType = {
    transformOptions?: (item: PropertyGridItem, options: PropertyGridOptionItem[], config: PropertyGridConfig) => PropertyGridOptionItem[];
};
const PropertyGridOptionProviderCtx = createContext({} as PropertyGridOptionProviderCtxType);
export function usePropertyGridOptionTranformer(transformOptions: PropertyGridOptionProviderCtxType['transformOptions']) {
    return useCallback(
        ({ children }: { children: ReactNode }) => {
            return <PropertyGridOptionProviderCtx.Provider value={{ transformOptions }}>{children}</PropertyGridOptionProviderCtx.Provider>;
        },
        [transformOptions]
    );
}

const RootMatch = Symbol('RootMatch');
const AllMatch = Symbol('AllMatch');
const MatchSymbol = Symbol('MatchSymbol');
export interface PropertyGridConfigRule extends PropertyGridItemConfig {
    /**
     * Full property path to match, regex for partial match, func to match a property grid item,
     * or a special value RootMatch to match the root object
     * This determines which property grid items the configuration applies to
     *
     * Fort string and RegExp, the object property path is tested, which does not array indices
     * e.g., 'a.b' will match both { a: { b: 1 } } and { a: [{ b: 2 }] }
     *
     * Matches are merged, and the last conflicting option wins
     */
    match: string | string[] | RegExp | ((item: PropertyGridItem) => boolean) | typeof RootMatch | typeof AllMatch;
    [MatchSymbol]: unknown;
}
interface PropertyGridFieldwiseConfig extends Record<string, PropertyGridItemConfig & { props?: PropertyGridFieldwiseConfig }> {}
export type PropertyGridConfigOption = PropertyGridConfigRule | PropertyGridFieldwiseConfig;
type SortHandler = (a: PropertyGridItem, b: PropertyGridItem) => number;
type SortHandlerFactory = (config: PropertyGridConfig, defaultSort: SortHandler, parent?: PropertyGridItem) => SortHandler;
interface PropertyGridItemConfigArgs {
    (
        item: PropertyGridItem,
        defaultRender: (key?: string | PropertyGridItem) => ReactNode,
        baseLabel: () => ReactNode,
        config: PropertyGridConfig
    ): ReactNode;
}
interface PropertyGridItemConfig {
    /**
     * True to skip rendering the matched property grid items
     */
    hidden?: boolean | ((item: PropertyGridItem) => boolean);
    /**
     * Provide custom formatting for a property grid item value. This is ignored if valueRenderer is
     * provided, unless the defaultRenderer is used for the valueRenderer
     */
    format?: (item: PropertyGridItem) => string | undefined;
    /**
     * Provide custom rendering for a property grid item value. This takes precedence over format.
     * Use the defaultRenderer to allow the prop grid to render the value as it normally would
     */
    valueRenderer?: (item: PropertyGridItem, defaultRender: () => ReactNode) => ReactNode;
    /**
     * Provide custom rendering for a property grid item key. Use the defaultRenderer to allow the
     * prop grid to render the value as it normally would, optionally overriding the key text
     */
    label?: string | PropertyGridItemConfigArgs;
    /**
     * Whether to sort the items alphabetically by property name(default), retain the original order, or provide a custom sort
     */
    sortChildren?: 'property' | 'none' | SortHandlerFactory;
}
interface PropertyGridConfigRuleBuilder {
    (match: PropertyGridConfigRule['match'], config: PropertyGridItemConfig): PropertyGridConfigRule;
    all: (config: PropertyGridItemConfig) => PropertyGridConfigRule;
    root: (config: PropertyGridItemConfig) => PropertyGridConfigRule;
}
export function usePropertyGridConfigBuilder() {
    const result = (match: PropertyGridConfigRule['match'], config: PropertyGridItemConfig) =>
        ({ match, [MatchSymbol]: true, ...config } as PropertyGridConfigRule);
    result.all = (config: PropertyGridItemConfig) => ({ match: AllMatch, [MatchSymbol]: true, ...config } as PropertyGridConfigRule);
    result.root = (config: PropertyGridItemConfig) => ({ match: RootMatch, [MatchSymbol]: true, ...config } as PropertyGridConfigRule);
    return result;
}
/**
 * Configure the property grid with rendering rules. Rules are used to identify fields and apply grid configuration
 * at a granular level. Multiple matches are merged, last one in wins. Additional options are merged in.
 *
 * Note: The config should be memoized to avoid re-creating the config on every render
 * @param config
 * @param additionalOptions
 */
export function usePropertyGridConfig(
    config: PropertyGridConfigOption[] | ((ruleBuilder: PropertyGridConfigRuleBuilder) => PropertyGridConfigOption[]),
    additionalOptions: Omit<PropertyGridConfig, 'select' | 'renderKey' | 'renderValue' | 'format'> = {},
    deps: DependencyList = []
) {
    const fmtSvc = useDi(FormatService);
    const configFn = typeof config === 'function';
    const ruleBuilder = usePropertyGridConfigBuilder();
    const configs = useMemo(() => (configFn ? config(ruleBuilder) : config), [configFn ? null : config, ...deps]);
    const convertFieldwiseConfig = (
        config: PropertyGridFieldwiseConfig,
        path: string = '',
        result: PropertyGridConfigRule[] = []
    ): PropertyGridConfigRule[] => {
        for (const [key, value] of Object.entries(config)) {
            const { props, ...rest } = value;
            const currPath = path ? `${path}.${key}` : key;
            result.push({ ...rest, match: currPath, [MatchSymbol]: true });
            if (props) {
                convertFieldwiseConfig(props, currPath, result);
            }
        }
        return result;
    };
    const normalizeConfigOption = (option: PropertyGridConfigOption): PropertyGridConfigRule[] => {
        return !option
            ? []
            : MatchSymbol in option
            ? [option as PropertyGridConfigRule]
            : convertFieldwiseConfig(option as PropertyGridFieldwiseConfig);
    };
    type InheritingItemConfig = PropertyGridItemConfig & { base?: InheritingItemConfig };
    const getConfig = useMemo(() => {
        const createMatchEvaluator = (rule: PropertyGridConfigRule) => {
            const { match } = rule;
            if (typeof match === 'string' || match instanceof Array) {
                const paths = new Set<string>(match instanceof Array ? match : [match]);
                return (item: PropertyGridItem) => paths.has(item.objectPath);
            } else if (match instanceof RegExp) {
                return (item: PropertyGridItem) => match.test(item.objectPath);
            } else if (match === RootMatch) {
                return (parent: PropertyGridItem | null) => parent === null;
            } else if (match === AllMatch) {
                return () => true;
            } else {
                return match;
            }
        };
        const configLookup = new Map<string | Symbol, InheritingItemConfig>();
        const matchEvaluators = configs.flatMap(normalizeConfigOption).map((c) => ({ match: createMatchEvaluator(c), config: c }));
        return (item: PropertyGridItem | null) => {
            const key = item?.objectPath || RootMatch;
            if (!configLookup.has(key)) {
                configLookup.set(
                    key,
                    matchEvaluators.reduce((result, { match, config }) => {
                        const isMatch = item !== null ? match(item) : config.match === RootMatch || config.match === AllMatch ? true : false;
                        if (isMatch) {
                            result = Object.assign({}, result, config, { base: result });
                        }
                        return result;
                    }, {} as InheritingItemConfig)
                );
            }
            return configLookup.get(key);
        };
    }, [configs, ...deps]);
    return useMemo(() => {
        const getKeyText = (item: PropertyGridItem, config: PropertyGridConfig) => {
            const rawKey = options.renderKey!(item, (overrideText?: string) => overrideText, config);
            return typeof rawKey === 'string'
                ? rawKey
                : typeof rawKey === 'number'
                ? rawKey + 1 + '.'
                : fmtSvc.userFriendlyCamelCase(item.property as string);
        };

        const renderKey = (
            item: PropertyGridItem,
            defaultRenderer: (overrideText?: string, overrideItem?: PropertyGridItem) => ReactNode,
            config: InheritingItemConfig,
            gridConfig: PropertyGridConfig
        ): ReactNode => {
            const renderer = config?.label;
            const extendedDefault = (key?: string | PropertyGridItem) => {
                return !key
                    ? defaultRenderer()
                    : typeof key === 'string'
                    ? defaultRenderer(key)
                    : gridConfig.renderKey!(key, createScopedDefaultKeyRenderer(key), gridConfig);
            };
            const baseLabel = () => (config.base ? renderKey(item, defaultRenderer, config.base, gridConfig) : defaultRenderer());
            return typeof renderer === 'string'
                ? defaultRenderer(renderer)
                : renderer
                ? renderer(item, extendedDefault, baseLabel, gridConfig)
                : defaultRenderer();
        };

        const createDefaultSort = (config: PropertyGridConfig) => {
            const sortKeyHandler = (a: PropertyGridItem, b: PropertyGridItem) => {
                const aKey = getKeyText(a, config);
                const bKey = getKeyText(b, config);
                return aKey.toString().localeCompare(bKey.toString(), undefined, { sensitivity: 'base' });
            };

            const defaultSort = (a: PropertyGridItem, b: PropertyGridItem) =>
                a.itemType === 'index' && b.itemType === 'index'
                    ? (a.property as number) - (b.property as number)
                    : a.itemType === b.itemType
                    ? sortKeyHandler(a, b)
                    : a.itemType === 'index'
                    ? -1
                    : 1;
            return defaultSort;
        };

        const defaultFormatter = (item: PropertyGridItem) => {
            if (item.valueType === 'date') {
                const dateValue = fmtSvc.toLocalDate(item.value as string);
                return fmtSvc.formatDateWithTodaysTime(dateValue);
            }
            return undefined;
        };
        const options = {
            ...additionalOptions,
            select: (parent: PropertyGridItem | undefined, children: PropertyGridItem[], gridConfig: PropertyGridConfig) => {
                const filteredItems = children.filter((c) => {
                    const config = getConfig(c);
                    const hide = !config ? false : typeof config.hidden === 'function' ? config.hidden(c) : config.hidden;
                    return !hide;
                });
                const parentConfig = getConfig(parent || null) ?? {};
                const sortBy = parentConfig.sortChildren;
                const defaultSort = createDefaultSort(gridConfig);
                const sortHandler =
                    !sortBy || sortBy === 'property' ? defaultSort : sortBy === 'none' ? null : sortBy(parentConfig, defaultSort, parent);
                return sortHandler ? filteredItems.sort(sortHandler) : filteredItems;
            },
            renderKey: (item, defaultRenderer, gridConfig) => {
                const config = getConfig(item);
                return renderKey(item, defaultRenderer, config ?? {}, gridConfig);
            },
            renderValue: (item: PropertyGridItem, defaultRender: () => ReactNode) => {
                const renderer = getConfig(item)?.valueRenderer;
                return renderer ? renderer(item, defaultRender) : defaultRender();
            },
            format: (item: PropertyGridItem) => {
                const formatter = getConfig(item)?.format ?? defaultFormatter;
                return formatter ? formatter(item) : undefined;
            },
        } as PropertyGridConfig;

        return options;
    }, [configs, additionalOptions, ...deps]);
}

export type PropertyGridOptionItem = OptionMenuItemTypes<PropertyGridItem>;
interface PropertyGridConfigRenderKeyHandler {
    (
        item: PropertyGridItem,
        defaultRenderer: (overrideText?: string, overrideItem?: PropertyGridItem) => ReactNode,
        config: PropertyGridConfig
    ): ReactNode;
}
export interface PropertyGridConfig {
    /**
     * Sort, filter, and adjust property grid items before they are rendered
     */
    select?: (parent: PropertyGridItem | undefined, children: PropertyGridItem[], config: PropertyGridConfig) => PropertyGridItem[];
    /**
     * Format property grid item value, return undefined to use default formatting
     */
    format?: (item: PropertyGridItem) => string | undefined;
    /**
     * Override the default key renderer to allow rendering custom components and key aliases
     * To simply override the key text, call the defaultRenderer with the override text
     */
    renderKey?: PropertyGridConfigRenderKeyHandler;
    /**
     * Override default value rendering
     */
    renderValue?: (item: PropertyGridItem, defaultRender: () => ReactNode, config: PropertyGridConfig) => ReactNode;
    /**
     * Override default row rendering
     */
    renderRow?: (item: PropertyGridItem, defaultRender: () => ReactNode, config: PropertyGridConfig) => ReactNode;
    /**
     * Get menu item options for a property grid item, return undefined to disable options
     */
    getOptions?: (item: PropertyGridItem, model: PropertyGridViewModel, config: PropertyGridConfig) => undefined | PropertyGridOptionItem[];
}
interface PropertyGridProps extends PropertyGridConfig {
    target: Record<string, unknown>;
    size?: 'compact' | 'normal';
    /**
     * Render a list of side-by-side key-value pairs (rows: default)
     * Or render key-value pairs a list of labeled values (labels)
     */
    layout?: 'rows' | 'labels';
    /**
     * For a 'labels' layout, specify the number of columns to render (default: 1)
     * For > 1 columns, items are rendered left to right
     */
    columns?: number;
    /**
     * True to transform nested objects and arrays to siblings (default: false)
     */
    flatten?: boolean;
    /**
     * Provide an empty state to render when the target object is empty
     */
    emptyState?: ReactNode;
}
export function PropertyGrid({ target, size, layout, flatten, ...config }: PropertyGridProps) {
    const model = useMemo(() => new PropertyGridViewModel(target).flattened(!!flatten), [target, flatten]);
    const [menuHost, showOptions] = useItemOptions(model, config);
    const context = useMemo(() => ({ model, config, showOptions }), [model, config, showOptions]);

    return (
        <PropertyGridSectionBodyEl isRoot layout={layout ?? 'rows'} columns={config.columns} size={size === 'compact' ? 'compact' : undefined}>
            <PropertyGridCtx.Provider value={context}>
                <>
                    {model.items.length === 0 ? config?.emptyState : null}
                    <PropertyGridItems items={model.items} />
                    {menuHost()}
                </>
            </PropertyGridCtx.Provider>
        </PropertyGridSectionBodyEl>
    );
}

function useItemOptions(model: PropertyGridViewModel, config: PropertyGridConfig) {
    const { transformOptions } = useContext(PropertyGridOptionProviderCtx);
    const modelId = useIdGen().getId(model);
    const flyoverKey = `pg-options-${modelId}`;
    const flyover = useAnchoredFlyoverEvents(
        {
            subjectAnchor: 'center-left',
            transition: 'opacity',
            anchor: ['center-right', 'top-right', 'bottom-right'],
        },
        flyoverKey
    );
    const showOptions = useCallback(
        (evt: { currentTarget: EventTarget }, item: PropertyGridItem) => {
            const options = config.getOptions?.(item, model, config);
            const transformedOptions = transformOptions?.(item, options ?? [], config) ?? options;
            if (transformedOptions?.length) {
                const target = evt.currentTarget as HTMLElement;
                const close = () => {
                    target.removeAttribute('data-optionsopen');
                    flyover.close();
                };
                target.setAttribute('data-optionsopen', 'y');
                flyover.onClickOpen(evt, {
                    renderer: (reposition) => {
                        return <PropertyGridMenu onCreated={() => reposition()} item={item} options={transformedOptions} close={close} />;
                    },
                });
            }
        },
        [flyover, config, model, transformOptions]
    );
    return [flyover.host, showOptions] as [() => ReactNode, typeof showOptions];
}

function PropertyGridMenu(props: { item: PropertyGridItem; options: PropertyGridOptionItem[]; close: () => void; onCreated: () => void }) {
    const { options, onCreated, ...itemProps } = props;
    useLayoutEffect(() => {
        onCreated();
    }, []);
    return (
        <>
            <FlyoverOverlay opened={true} onClick={itemProps.close} />
            <Menu opened shadow="lg" styles={{ item: { paddingTop: 6, paddingBottom: 6 } }}>
                <Menu.Dropdown sx={{ position: 'static' }}>
                    <OptionMenuItems close={close} data={props.item} options={options} />
                </Menu.Dropdown>
            </Menu>
        </>
    );
}

function PropertyGridItems({ parent, items }: { parent?: PropertyGridItem; items: PropertyGridItem[] }) {
    const { config } = usePropertyGridCtx();
    const { select, renderRow } = config;
    const adjustedItems = useMemo(() => select?.(parent, items, config) ?? items, [parent, items, select]);
    return (
        <>
            {adjustedItems.map((p, i) => (
                <Fragment key={i}>{renderRow?.(p, () => <PropertyGridRow item={p} />, config) ?? <PropertyGridRow item={p} />}</Fragment>
            ))}
        </>
    );
}

function PropertyGridRow({ item }: { item: PropertyGridItem }) {
    const rowMenuProps = useRowMenuInteractions(item);
    return item.itemType === 'index' ? (
        <PropertyGridArrayItem>
            <KeyRenderer item={item} />
            <PropertyGridArrayItemValue>
                {item.type !== 'object' ? (
                    <PropertyGridValueEl>
                        <ValueRenderer item={item} />
                    </PropertyGridValueEl>
                ) : null}
                {item.children && item.children.map((p, i) => <PropertyGridRow key={i} item={p} />)}
            </PropertyGridArrayItemValue>
        </PropertyGridArrayItem>
    ) : (
        <>
            <PropertyGridRowEl {...rowMenuProps}>
                <KeyRenderer item={item} />
                <PropertyGridValueEl>
                    <ValueRenderer item={item} />
                </PropertyGridValueEl>
            </PropertyGridRowEl>
            {item.children ? <PropertyGridSubItems item={item} /> : null}
        </>
    );
}

function useRowMenuInteractions(item: PropertyGridItem) {
    const { config, model, showOptions } = usePropertyGridCtx();
    const [hasOptions, setHasOptions] = useState<boolean>();
    const onMouseEnter = useCallback(() => {
        setHasOptions(!!config.getOptions?.(item, model, config)?.length);
    }, [item, model]);
    const onClick = useCallback(
        (evt: { currentTarget: EventTarget; defaultPrevented: boolean }) => {
            if (!evt.defaultPrevented) {
                showOptions(evt, item);
            }
        },
        [item, showOptions, hasOptions]
    );
    return { onMouseEnter, onClick, 'data-hasoptions': hasOptions ? 'y' : 'n' };
}

const defaultKeyRenderer = (item: PropertyGridItem, text?: string) => <DefaultKeyRenderer item={item} text={text} />;
const createScopedDefaultKeyRenderer = (item: PropertyGridItem) => {
    return (overrideText?: string, overrideItem?: PropertyGridItem) => defaultKeyRenderer(overrideItem ?? item, overrideText);
};
function KeyRenderer({ item }: { item: PropertyGridItem }) {
    const { config } = usePropertyGridCtx();
    const { renderKey } = config;

    if (renderKey) {
        const renderedKey = renderKey(item, createScopedDefaultKeyRenderer(item), config);
        return <>{typeof renderedKey === 'string' ? <PropertyGridKey>{renderedKey}</PropertyGridKey> : renderedKey}</>;
    } else {
        return <>{defaultKeyRenderer(item)}</>;
    }
}

function DefaultKeyRenderer({ item, text }: { item: PropertyGridItem; text?: string }) {
    const formatSvc = useDi(FormatService);
    if (item.itemType === 'index') {
        return (
            <PropertyGridIndex>
                <Text>{text === undefined ? `${(item.property as number) + 1}.` : text}</Text>
            </PropertyGridIndex>
        );
    } else {
        return <PropertyGridKey>{text ?? formatSvc.userFriendlyCamelCase(item.property as string)}</PropertyGridKey>;
    }
}

const defaultValueRenderer = (item: PropertyGridItem) => <DefaultValueRenderer item={item} />;
function ValueRenderer({ item }: { item: PropertyGridItem }) {
    const { config } = usePropertyGridCtx();
    const { renderValue } = config;

    return <>{renderValue ? renderValue(item, () => defaultValueRenderer(item), config) : defaultValueRenderer(item)}</>;
}

function DefaultValueRenderer({ item }: { item: PropertyGridItem }) {
    const formatSvc = useDi(FormatService);
    const { format } = usePropertyGridCtx().config;
    const formattedValue = format?.(item);
    if (formattedValue !== undefined) {
        return <>{formattedValue}</>;
    }

    switch (item.valueType) {
        case 'number':
            const num = item.valueAsNum(0);
            if (num % 1 === 0) {
                return <>{formatSvc.formatInt(num)}</>;
            } else {
                return <>{formatSvc.formatDecimal(num, 2)}</>;
            }
        case 'boolean':
            return <>{item.valueAsBool(false) ? 'Yes' : 'No'}</>;
        case 'null':
            return (
                <Text color="dimmed" italic>
                    Nothing
                </Text>
            );
        case 'array':
        case 'object':
            return <ChildrenExpanderValue item={item} />;
        case 'string':
            return <>{item.value}</>;
        case 'date':
            return <>{item.value}</>;
        default:
            return <></>;
    }
}

export function ChildrenExpanderValue({ item }: { item: PropertyGridItem }) {
    const fmtSvc = useDi(FormatService);
    const { model } = usePropertyGridCtx();
    const hasChildren = item.children?.length ?? 0;
    const label = !hasChildren ? 'Empty' : item.valueType === 'array' ? `${fmtSvc.formatInt(item.children?.length ?? 0)} items` : 'More details';
    const toggle: MouseEventHandler<HTMLAnchorElement> = useCallback(
        (evt) => {
            evt.stopPropagation();
            model.toggle(item);
        },
        [item]
    );
    useEvent(model.expanded);
    const opened = model.isExpanded(item);

    return hasChildren ? (
        <PropertyGridExpander>
            <AnchorButton text={label} iconPosition="left" icon={opened ? <CaretDown size={16} /> : <CaretRight size={16} />} onClick={toggle} />
        </PropertyGridExpander>
    ) : (
        <Text color="dimmed" component="a" onClick={toggle} italic>
            {label}
        </Text>
    );
}

function PropertyGridSubItems({ item }: { item: PropertyGridItem }) {
    const { isExpanded, expanded } = usePropertyGridCtx().model;
    useEvent(expanded);
    return (
        <>
            {isExpanded(item) ? (
                <PropertyGridSectionBodyEl>
                    <PropertyGridItems items={item.children!} parent={item} />
                </PropertyGridSectionBodyEl>
            ) : null}
        </>
    );
}
