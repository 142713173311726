import { QueryExpr, QueryResult } from '@apis/Resources';
import styled from '@emotion/styled';
import {
    Badge,
    Box,
    Button,
    Card,
    CloseButton,
    Divider,
    Drawer,
    Group,
    MultiSelect,
    Select,
    Space,
    Stack,
    Switch,
    Text,
    Title,
    useMantineTheme,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { BarChart, CustomBarChartSettings } from '@root/Components/Charts/BarChart';
import { ContextButton } from '@root/Components/ContextButton/ContextButton';
import { GridGroupByState } from '@root/Components/DataGrid/Models';
import { getFilterFromSelection, MonthlyInvoiceRow, MonthlyInvoicesGrid } from '@root/Components/Invoices/MonthlyInvoicesGrid';
import { IncreaseIndicator } from '@root/Design/Data';
import { FillerSwitch } from '@root/Design/Filler';
import { PageBody, PageContent, PaneledPage, PanelHeader } from '@root/Design/Layout';
import { useDi, useDiMemo } from '@root/Services/DI';
import { FormatService } from '@root/Services/FormatService';
import { InvoiceApiService } from '@root/Services/Invoices/InvoiceApiService';
import { IBaseInvoiceRecord } from '@root/Services/Invoices/InvoiceSchemaService';
import { exprBuilder, queryBuilder } from '@root/Services/QueryExpr';
import { endpoint } from '@root/Services/Router/EndpointRegistry';
import { addMonths, startOfMonth } from 'date-fns';
import { Fragment, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { Search } from 'tabler-icons-react';
import { useRowSelector } from './Components/ChartKeyRowSelector';
import { useGridResizer } from './Components/GridResizer';
import { getUniqueAccounts, getUniqueMonths, RangePickerItem, useRangePickerData } from './Components/MonthlyRangePicker';
import { AccountValueItem, AccountPickerItem } from './Components/AccountPicker';
import { ChartKeySelectionStrategy } from './Models/ChartKeySelectionStrategy';
import { ConnectionCheck } from '@root/Components/Resources/ConnectionCheck';
import { CostFieldSelector } from './Components/CostFieldSelector';
import { InvoiceCostFields, useInvoiceCostField } from '@root/Services/Invoices/InvoiceCostFieldContext';
import { withSchemaPreloader } from '@root/Components/Invoices/SchemaPreloader';
import { IInvoiceFieldCompatibilityLookup, useInvoiceFieldCompatibility } from '@root/Services/Invoices/InvoiceFieldCompatibilityService';

export function TrendAnalysis() {
    const fmtSvc = useDi(FormatService);
    const invoiceApi = useDiMemo(InvoiceApiService);

    const { data: ranges, loading: rangesLoading } = useRangePickerData(invoiceApi);
    const [selectedRange, setSelectedRange] = useState<RangeSelection>();
    const [gridSelectionFilters, setGridSelectionFilters] = useState<QueryExpr[]>();
    const [gridFilters, setGridFilters] = useState<QueryExpr[]>();
    const [chartItems, setChartItems] = useState<ChartItem[]>([]);
    const gridSelectionStrategy = useMemo(() => new ChartKeySelectionStrategy(10), []);
    const fieldCompat = useInvoiceFieldCompatibility('monthly', true);

    const costField = useInvoiceCostField();

    useEffect(() => {
        if (!rangesLoading && ranges?.length) {
            setSelectedRange({
                accounts: getUniqueAccounts(ranges),
                months: getUniqueMonths(
                    ranges.filter((r) => !!r.accountId),
                    fmtSvc
                )
                    .map((m) => m.date)
                    .slice(-6),
            });
        }
    }, [rangesLoading, ranges]);

    const filters = useMemo(() => {
        const result: QueryExpr[] = [];
        const [acct] = fieldCompat?.getAvailableFields('BillingAccountId') ?? [];
        if (selectedRange?.months.length && acct) {
            result.push({ Operation: 'eq', Operands: [{ Field: acct }, { Value: selectedRange.accounts.map((a) => a.accountId) }] });
        }
        return result;
    }, [selectedRange, costField, fieldCompat]);
    const chartFilters = useMemo(() => {
        const result = [...filters] as QueryExpr[];
        if (gridFilters?.length) {
            result.push(...gridFilters);
        }
        if (gridSelectionFilters?.length) {
            if (gridSelectionFilters.length == 1) {
                result.push(...gridSelectionFilters);
            } else {
                const consolidatedResult = { Operation: 'or', Operands: gridSelectionFilters };
                result.push(consolidatedResult);
            }
        }
        return result;
    }, [filters, JSON.stringify(gridFilters), gridSelectionFilters, costField]);
    const groupBy = useMemo(() => {
        return !fieldCompat
            ? null
            : ([{ id: fieldCompat.getAvailableField('ServiceCategory')?.pathWithRoot, sortDir: 'Desc', sortMode: 'count' }] as GridGroupByState[]);
    }, [fieldCompat]);
    const handleSelectionChanged = useCallback(async ({ getItems }: { getItems: () => Promise<MonthlyInvoiceRow[]> }) => {
        const selections = await getItems();
        const chartItems = selections.map((s) => ({
            filter: getFilterFromSelection([s]),
            color: gridSelectionStrategy.getSelectionColor(s)!,
            label: s.value,
        }));
        const gridFilters = getFilterFromSelection(selections);
        setGridSelectionFilters(gridFilters);
        setChartItems(chartItems);
    }, []);
    const rowSelector = useRowSelector({ gridSelectionStrategy, tooltip: 'Chart this data in Monthly Spend' });
    const { Resizer, containerStyle, setScrollContainer } = useGridResizer();
    return (
        <ConnectionCheck>
            {() => (
                <PageBody>
                    <PageContent ref={setScrollContainer}>
                        <PaneledPage>
                            <Box p="lg" sx={{ gap: 24, height: '100%', width: '100%', display: 'flex', flexDirection: 'column', minHeight: 850 }}>
                                <PanelHeader style={{ flex: 0, padding: 0 }}>
                                    <Title order={3} data-atid="TrendAnalysisHeader">
                                        Trend Analysis
                                    </Title>
                                    <Group position="apart" spacing={8}>
                                        <CostFieldSelector />
                                        {selectedRange && ranges ? (
                                            <RangePickerButton onSelected={setSelectedRange} selected={selectedRange} ranges={ranges} />
                                        ) : null}
                                    </Group>
                                </PanelHeader>
                                <Group noWrap align="stretch">
                                    <Card shadow="sm" withBorder sx={{ flexBasis: '40%', minWidth: 0, overflow: 'visible' }} radius="md">
                                        <Box sx={{ height: 320 }}>
                                            <Text size="lg">Monthly Spend</Text>
                                            <FillerSwitch loading={rangesLoading} noData={!selectedRange?.months}>
                                                {() => (
                                                    <TrendBarChart
                                                        invoiceApi={invoiceApi}
                                                        months={selectedRange?.months!}
                                                        filters={chartFilters}
                                                        chartItems={chartItems}
                                                        costField={costField}
                                                    />
                                                )}
                                            </FillerSwitch>
                                        </Box>
                                    </Card>
                                    <Card shadow="sm" withBorder sx={{ flexBasis: '60%' }} radius="md">
                                        <FillerSwitch loading={rangesLoading || !fieldCompat} noData={!selectedRange?.months}>
                                            {() => (
                                                <TopSpendingChanges
                                                    invoiceApi={invoiceApi}
                                                    months={selectedRange?.months!}
                                                    filters={chartFilters}
                                                    costField={costField}
                                                    fieldCompat={fieldCompat!}
                                                />
                                            )}
                                        </FillerSwitch>
                                    </Card>
                                </Group>
                                <Box sx={{ height: '100%', overflow: 'hidden', ...containerStyle }}>
                                    <FillerSwitch noData={!selectedRange?.months} loading={rangesLoading || !fieldCompat}>
                                        {() => (
                                            <MonthlyInvoicesGrid
                                                invoiceApi={invoiceApi}
                                                months={selectedRange?.months!}
                                                persistenceKey="TrendAnalysis"
                                                defaultGroupBy={groupBy!}
                                                filters={filters}
                                                includeTotal
                                                onFilterChanged={setGridFilters}
                                                onSelectionChanged={handleSelectionChanged}
                                                selectionStrategy={gridSelectionStrategy}
                                                renderRowSelector={rowSelector}
                                                costField={costField}
                                                rightPlaceholder={
                                                    <>
                                                        <Resizer />
                                                        <Space w="md" />
                                                    </>
                                                }
                                            />
                                        )}
                                    </FillerSwitch>
                                </Box>
                            </Box>
                        </PaneledPage>
                    </PageContent>
                </PageBody>
            )}
        </ConnectionCheck>
    );
}

endpoint('trend-analysis', withSchemaPreloader(TrendAnalysis), 'Trend Analysis');

interface RangeSelection {
    accounts: { accountId: string; accountName: string }[];
    months: Date[];
}

function RangePickerButton({
    ranges,
    selected,
    onSelected,
}: {
    ranges: RangePickerItem[];
    selected: RangeSelection;
    onSelected: (item: RangeSelection) => void;
}) {
    const fmtSvc = useDi(FormatService);
    const [opened, { toggle, close }] = useDisclosure(false);
    const monthLabel =
        selected.months.length > 1
            ? `${fmtSvc.formatShortMonthYear(selected.months[0])} - ${fmtSvc.formatLongMonthYear(selected.months[selected.months.length - 1])}`
            : 'None';
    const accountLabel: ReactNode =
        selected.accounts.length === 1 ? (
            <Text color="primary" size="sm">
                {fmtSvc.awsAccountId(selected.accounts[0].accountId) + ' ' + selected.accounts[0].accountName}
            </Text>
        ) : (
            <Badge mt={4} variant="light">
                {selected.accounts.length}
            </Badge>
        );
    const accountPlural = selected.accounts.length > 1 ? 's' : '';
    const handleChange = useCallback(
        (selected: RangeSelection) => {
            onSelected(selected);
            close();
        },
        [close, onSelected]
    );
    const sections = [
        { label: 'Month Range', text: monthLabel },
        { label: 'Account' + accountPlural, text: accountLabel },
    ];

    return (
        <>
            <ContextButton sections={sections} onClick={toggle} icon={<Search />} />
            <Drawer position="right" opened={opened} onClose={close} padding={0} size={400} withCloseButton={false}>
                <RangePicker ranges={ranges} selected={selected} onSelected={handleChange} onClose={close} />
            </Drawer>
        </>
    );
}

function RangePicker({
    ranges,
    selected,
    onSelected,
    onClose,
}: {
    ranges: RangePickerItem[];
    selected: RangeSelection;
    onSelected: (item: RangeSelection) => void;
    onClose: () => void;
}) {
    const fmtSvc = useDi(FormatService);
    const theme = useMantineTheme();
    const [selection, setSelection] = useState<RangeSelection>({ ...selected });
    useEffect(() => {
        setSelection({ ...selected });
    }, [selected]);
    const availableAccounts = useMemo(() => getUniqueAccounts(ranges), [ranges]);
    const availableMonths = useMemo(() => getUniqueMonths(ranges, fmtSvc).reverse(), [ranges]);
    const updateMonths = useCallback(
        (from?: string, to?: string) => {
            const fromMonth = from ? startOfMonth(fmtSvc.fromMonthYear(from)) : selection.months[0];
            const toMonth = to ? fmtSvc.fromMonthYear(to) : selection.months[selection.months.length - 1];
            const months: Date[] = [fromMonth.getTime() > toMonth.getTime() ? toMonth : fromMonth];
            const endMonth = fromMonth.getTime() > toMonth.getTime() ? fromMonth : toMonth;
            let month = addMonths(months[0], 1);
            while (month <= endMonth) {
                months.push(month);
                month = addMonths(month, 1);
            }
            setSelection((s) => ({
                ...s,
                months,
            }));
        },
        [selection]
    );
    const accountOptions = useMemo(
        () => availableAccounts.map((a) => ({ value: a.accountId, label: fmtSvc.awsAccountId(a.accountId), accountName: a.accountName })),
        [availableAccounts]
    );
    const selectedAccounts = useMemo(() => selection.accounts.map((a) => a.accountId), [selection]);
    const [from, to] = useMemo(
        () =>
            (!selection.months.length ? [] : [selection.months[0], selection.months[selection.months.length - 1]]).map((d) =>
                fmtSvc.formatYearMonth(d)
            ),
        [selection]
    );

    const updateMonthFrom = useCallback((month: string) => updateMonths(month), [updateMonths]);
    const updateMonthTo = useCallback((month: string) => updateMonths(undefined, month), [updateMonths]);
    const updateAccounts = useCallback(
        (accounts: string[]) => {
            setSelection((s) => ({
                ...s,
                accounts: accounts.map((a) => availableAccounts.find((aa) => aa.accountId === a)).filter((a) => !!a) as RangePickerItem[],
            }));
        },
        [selection]
    );
    const apply = useCallback(() => onSelected(selection), [selection, onSelected]);
    const reset = useCallback(() => setSelection({ ...selected }), [selected]);

    const canApply = selection.months.length > 1 && selection.accounts.length > 0;
    return (
        <Stack sx={{ height: '100%' }}>
            <Box>
                <Group position="apart" p="lg">
                    <Title order={4}>Trend Analysis Selection</Title>
                    <CloseButton onClick={onClose} />
                </Group>
                <Divider />
            </Box>
            <Box p="lg" sx={{ flex: 1 }}>
                <MultiSelect
                    label="Accounts"
                    data={accountOptions}
                    value={selectedAccounts}
                    onChange={updateAccounts}
                    valueComponent={AccountValueItem}
                    itemComponent={AccountPickerItem}
                    clearable
                />
                <Space h="md" />
                <Text size="sm">Month Range</Text>
                <Card sx={{ background: theme.colors.gray[2], position: 'static' }}>
                    <Select label="From" data={availableMonths} value={from} onChange={updateMonthFrom}></Select>
                    <Select label="To" data={availableMonths} value={to} onChange={updateMonthTo}></Select>
                </Card>
            </Box>
            <Box sx={{ background: theme.colors.gray[3] }}>
                <Divider />
                <Group position="apart" p="lg">
                    <Button variant="outline" onClick={reset}>
                        Reset
                    </Button>
                    <Group>
                        <Button variant="outline" onClick={onClose}>
                            Cancel
                        </Button>
                        <Button disabled={!canApply} onClick={apply}>
                            Apply
                        </Button>
                    </Group>
                </Group>
            </Box>
        </Stack>
    );
}

function TopSpendingChanges({
    invoiceApi,
    months,
    filters,
    costField,
    fieldCompat,
}: {
    invoiceApi: InvoiceApiService;
    months: Date[];
    filters?: QueryExpr[];
    costField: InvoiceCostFields;
    fieldCompat: IInvoiceFieldCompatibilityLookup;
}) {
    const theme = useMantineTheme();
    const fmtSvc = useDi(FormatService);
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState<{ product: string; change: number; changePct: number }[]>();
    const [shapeStats, setShapeStats] = useState<{ min: number; max: number; firstMonth: Date; lastMonth: Date }>();
    const [mode, setMode] = useState<'increase' | 'decrease'>('increase');

    useEffect(() => {
        setLoading(true);
        (async () => {
            const [category] = fieldCompat.getAvailableFields('ServiceCategory') ?? [];
            const results = await queryBuilder<IBaseInvoiceRecord & Record<string, number>>()
                .where((b) =>
                    b.and(...(filters ?? []).map((f) => b.fromExpr(f)), b.model.UsageMonth.eq(months.map((m) => fmtSvc.formatYearMonth(m))))
                )
                .select((b) => ({
                    month: b.model.UsageMonth,
                    product: b.model[category] as unknown as string,
                    total: b.sum(b.model[costField]),
                }))
                .execute((q) => invoiceApi.queryMonthlyRollup(q, months));
            const items = results?.Results ?? [];
            if (items?.length) {
                const {
                    pmTotals,
                    months: uniqueMonths,
                    products,
                } = items.reduce(
                    (acc, item) => {
                        acc.months.add(item.month);
                        acc.products.add(item.product);
                        acc.pmTotals.set(`${item.product}-${item.month}`, item.total);
                        return acc;
                    },
                    { pmTotals: new Map<string, number>(), months: new Set<string>(), products: new Set<string>() }
                );
                const months = [...uniqueMonths].sort();
                const firstMonth = months.shift();
                const lastMonth = months.pop();
                const data = [...products].map((product) => {
                    const firstTotal = pmTotals.get(`${product}-${firstMonth}`) ?? 0;
                    const lastTotal = pmTotals.get(`${product}-${lastMonth}`) ?? 0;
                    const change = lastTotal - firstTotal;
                    const changePct = !firstTotal ? 1 : change / firstTotal;
                    return { product, change, changePct };
                });
                const sortedTop10 = data
                    .filter((d) => (mode === 'increase' ? d.change > 0 : d.change < 0))
                    .sort((a, b) => Math.abs(b.change) - Math.abs(a.change))
                    .slice(0, 10)
                    .sort((a, b) => {
                        if (a.change < 0 && b.change > 0) return 1;
                        if (a.change > 0 && b.change < 0) return -1;
                        return Math.abs(b.change) - Math.abs(a.change);
                    });
                const shapeStats = sortedTop10.reduce(
                    (acc, item) => {
                        acc.min = Math.min(acc.min, Math.abs(item.change));
                        acc.max = Math.max(acc.max, Math.abs(item.change));
                        return acc;
                    },
                    { min: 0, max: 0 }
                );
                setShapeStats({ ...shapeStats, firstMonth: fmtSvc.parseDateNoTime(firstMonth!), lastMonth: fmtSvc.parseDateNoTime(lastMonth!) });
                setData(sortedTop10);
            } else {
                setData(undefined);
            }
        })().finally(() => setLoading(false));
    }, [filters, months, mode, costField]);

    return (
        <FillerSwitch loading={loading} noData={!shapeStats || !data}>
            {() => (
                <>
                    <Group position="apart" noWrap>
                        <Group noWrap>
                            <Text size="lg">Top Spending Changes</Text>
                            <Text size="sm" color="dimmed">
                                {fmtSvc.formatLongMonthYear(shapeStats!.firstMonth)} vs {fmtSvc.formatLongMonthYear(shapeStats!.lastMonth)}
                            </Text>
                        </Group>
                        <Switch
                            color="warning"
                            sx={{ input: { backgroundColor: mode === 'increase' ? theme.colors.warning[6] : theme.colors.primary[6] } }}
                            checked={mode === 'increase'}
                            label={mode === 'decrease' ? 'Decreases' : 'Increases'}
                            onChange={(e) => setMode(e.currentTarget.checked ? 'increase' : 'decrease')}
                        />
                    </Group>
                    <Space h={10} />
                    <Box sx={{ height: 280 }}>
                        <TopChangeGrid>
                            {data?.map((d, i) => (
                                <Fragment key={d.product}>
                                    {i > 0 ? <Divider color="gray.3" sx={{ gridColumn: '1 / span 4' }} /> : null}
                                    <Text align="right">{i + 1}.</Text>
                                    <Text
                                        size="sm"
                                        sx={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}
                                        data-atid={'SpendingChangeResource:' + d.product}
                                    >
                                        {d.product}
                                    </Text>
                                    <Group noWrap position="apart" spacing={4}>
                                        <IncreaseIndicator size="md" value={d.change} preferDecrease />
                                        <Text align="right" data-atid={'ResourceChangeCost:' + d.change}>
                                            {fmtSvc.formatMoneyNoDecimals(d.change)}
                                        </Text>
                                    </Group>
                                    <Box>
                                        <TopChangeBar value={d.change} max={shapeStats?.max ?? 1} />
                                    </Box>
                                </Fragment>
                            ))}
                        </TopChangeGrid>
                    </Box>
                </>
            )}
        </FillerSwitch>
    );
}

const TopChangeGrid = styled.div`
    display: grid;
    grid-template-columns: min-content minmax(200px, 300px) min-content minmax(300px, auto);
    height: 100%;
    align-items: center;
    grid-gap: 0 8px;
`;
const TopChangeBar = styled.div<{ value: number; max: number }>`
    border-radius: ${(p) => p.theme.radius.sm}px;
    background: ${(p) => (p.value > 0 ? p.theme.colors.warning[6] : p.theme.colors.primary[6])};
    width: ${(p) => Math.round((Math.abs(p.value) / p.max) * 100)}%;
    height: 12px;
`;

type ChartItem = { filter: QueryExpr[]; color: string; label: string };

function TrendBarChart({
    invoiceApi,
    months,
    filters,
    chartItems,
    costField,
}: {
    invoiceApi: InvoiceApiService;
    months: Date[];
    filters?: QueryExpr[];
    chartItems: ChartItem[];
    costField: InvoiceCostFields;
}) {
    const fmtSvc = useDi(FormatService);
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState<Record<string, number | string>[]>([]);

    const chartProps = useMemo(
        () => ({
            groups: chartItems.length ? ['Date', 'Selection'] : ['Date'],
            values: ['Total'],
            settings: {
                noWrapper: true,
                sort: 'none',
                format: 'money-whole',
                orientation: 'Vertical',
                showTrend: true,
                barPadding: 0.8,
                enableLabel: false,
                margin: { top: 20, right: 20, bottom: 80, left: 60 },
                chartColors: chartItems.length ? chartItems.map((c) => c.color) : undefined,
            } as CustomBarChartSettings,
        }),
        [chartItems]
    );
    const reportAllData = useCallback(
        async (months: Date[], filters?: QueryExpr[]) => {
            const query = queryBuilder<IBaseInvoiceRecord & Record<string, number>>()
                .select((b) => ({
                    Date: b.model.UsageMonth,
                    Total: b.sum(b.model[costField!]),
                }))
                .build();
            if (filters?.length) {
                query.Where = { Operation: 'and', Operands: filters };
            }
            const results = (await invoiceApi.queryMonthlyRollup(query, months)) as QueryResult<{
                Date: string;
                Total: number;
            }>;
            const items = results?.Results ?? [];
            const data = items
                .sort((a, b) => a.Date.localeCompare(b.Date))
                .map((o) => ({ Date: fmtSvc.formatShortMonthYear(fmtSvc.parseDateNoTime(o.Date)), Total: o.Total }));
            const valueLookup = new Map<string, number>(data.map((d) => [d.Date, d.Total]));
            return months.map((m) => ({ Date: fmtSvc.formatShortMonthYear(m), Total: valueLookup.get(fmtSvc.formatShortMonthYear(m)) ?? 0 }));
        },
        [invoiceApi, costField]
    );
    const reportSpecificData = useCallback(
        async (months: Date[], chartItems: ChartItem[], filters?: QueryExpr[]) => {
            const query = queryBuilder<IBaseInvoiceRecord & Record<string, number>>()
                .select((b) => ({
                    Date: b.model.UsageMonth,
                }))
                .build();

            const aliasStats = chartItems.reduce((acc, item) => {
                if (!acc[item.label]) {
                    acc[item.label] = { count: 0, used: 0 };
                }
                acc[item.label].count++;
                return acc;
            }, {} as Record<string, { count: number; used: number }>);
            const aliasLookup = new Map<ChartItem, string>();
            for (const chartItem of chartItems) {
                const aliasStat = aliasStats[chartItem.label];
                const alias = chartItem.label + (aliasStat.count > 1 ? ` (${++aliasStat.used})` : '');
                aliasLookup.set(chartItem, alias);
                const filter = chartItem.filter.length > 1 ? { Operation: 'and', Operands: chartItem.filter } : chartItem.filter[0];
                query.Select!.push({
                    Alias: alias,
                    Expr: exprBuilder<IBaseInvoiceRecord & Record<string, number>>().createExpr((b) =>
                        b.aggIf(b.fromExpr<boolean>(filter), b.sum(b.model[costField!]))
                    ),
                });
            }
            if (filters?.length) {
                query.Where = { Operation: 'and', Operands: filters };
            }
            const results = (await invoiceApi.queryMonthlyRollup(query, months)) as QueryResult<{ Date: string } & Record<string, number>>;
            const items = results?.Results ?? [];
            const flattenedItems: Record<string, number | string>[] = [];
            for (const item of items) {
                const entries = Object.entries(item);
                const date = item.Date;
                for (const [key, value] of entries) {
                    if (key !== 'Date') {
                        flattenedItems.push({ Date: date, Total: value, Selection: key });
                    }
                }
            }
            const data = flattenedItems
                .sort((a, b) => (a.Date as string).localeCompare(b.Date as string))
                .map((o) => ({
                    Date: fmtSvc.formatShortMonthYear(fmtSvc.parseDateNoTime(o.Date as string)),
                    Selection: o.Selection,
                    Total: o.Total as number,
                }));
            const valueLookup = data.reduce(
                (acc, item) => acc.set(`${item.Date}-${item.Selection}`, item),
                new Map<string, Record<string, string | number>>()
            );
            const result: Record<string, string | number>[] = [];
            for (const month of months) {
                for (const chartItem of chartItems) {
                    const alias = aliasLookup.get(chartItem)!;
                    const key = `${fmtSvc.formatShortMonthYear(month)}-${alias}`;
                    const value = valueLookup.get(key);
                    result.push({
                        Date: fmtSvc.formatShortMonthYear(month),
                        Selection: alias,
                        Total: value ? value.Total : 0,
                    });
                }
            }
            return result;
        },
        [invoiceApi, costField]
    );
    useEffect(() => {
        setLoading(true);
        (async () => {
            if (chartItems.length) {
                setData(await reportSpecificData(months, chartItems, filters));
            } else {
                setData(await reportAllData(months, filters));
            }
        })().finally(() => setLoading(false));
    }, [JSON.stringify([months, filters]), reportSpecificData, reportAllData]);
    return <FillerSwitch loading={loading}>{() => <BarChart data={data} {...chartProps} />}</FillerSwitch>;
}
