import styled from '@emotion/styled';
import { Menu, useMantineTheme } from '@mantine/core';
import { MouseEventHandler, ReactNode, useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { Check } from 'tabler-icons-react';

type OptionMenuItemOnClickResult = { confirmation: string } | 'cancel' | void;
export interface OptionMenuButton<TData = undefined> {
    icon?: ReactNode;
    label: ReactNode;
    /**
     * Provide the onClick behavior for the item.
     * Return nothing to close the menu immediately
     * { confirmation: 'Confirmation Message' } to close the menu after a confirmation of the action
     * 'cancel' to keep the menu open
     */
    onClick: (item: TData, close?: () => void) => OptionMenuItemOnClickResult | Promise<OptionMenuItemOnClickResult>;
    disabled?: boolean;
    selected?: boolean;
}
export interface OptionMenuHeader {
    type: 'header';
    label: ReactNode;
}
export type OptionMenuItemTypes<TData = undefined> = OptionMenuHeader | OptionMenuButton<TData> | 'divider';

export function OptionMenuClickConfirmation({ confirmation, close, width }: { confirmation: string; width: number; close: () => void }) {
    const theme = useMantineTheme();
    const animationMs = 400;
    useEffect(() => {
        const timeout = setTimeout(close, animationMs);
        return () => clearTimeout(timeout);
    }, []);
    return (
        <Menu.Item sx={{ width }} icon={<Check size={18} stroke={theme.colors.success[6]} strokeWidth={2} />}>
            {confirmation}
        </Menu.Item>
    );
}

type OptionalDataProp<TData = undefined> = TData extends undefined ? Partial<{ data: TData }> : { data: TData };
type OptionMenuItemProps<TData = undefined> = OptionalDataProp<TData> & { option: OptionMenuItemTypes<TData>; close: () => void };
export function OptionMenuItem<TData>({ option, close, ...props }: OptionMenuItemProps<TData>) {
    if (option === 'divider') {
        return <Menu.Divider />;
    } else if ('type' in option) {
        if (option.type === 'header') {
            return <Menu.Label>{option.label}</Menu.Label>;
        } else {
            return <></>;
        }
    } else {
        const { selected, disabled, icon } = option;
        const [nextState, setNextState] = useState<{ confirmation: string; width: number }>();
        const onClick: MouseEventHandler<HTMLAnchorElement> = useCallback(
            async (evt) => {
                const width = evt.currentTarget.clientWidth;
                const clickResult = await option.onClick(props.data as TData, close);
                if (!clickResult) {
                    close();
                } else if (typeof clickResult === 'object' && 'confirmation' in clickResult) {
                    setNextState({ ...clickResult, width });
                }
            },
            [option, close]
        );
        return nextState ? (
            <OptionMenuClickConfirmation width={nextState.width} confirmation={nextState.confirmation} close={close} />
        ) : (
            <Menu.Item component={OptionMenuItemEl} icon={option.icon} onClick={onClick} selected={selected} disabled={disabled}>
                {option.label}
            </Menu.Item>
        );
    }
}

export function OptionMenuItems<TData>(props: OptionalDataProp<TData> & { options: OptionMenuItemTypes<TData>[]; close: () => void }) {
    const { options, ...itemProps } = props;
    return (
        <>
            {options.map((o, i) => (
                <OptionMenuItem<any> key={i} {...itemProps} option={o} />
            ))}
        </>
    );
}

export const OptionMenuItemEl = styled.a<{ selected?: boolean; disabled?: boolean }>`
    color: ${(p) => (p.selected ? p.theme.white : 'inherit')};
    opacity: ${(p) => (p.disabled ? 0.7 : 1)};
    cursor: ${(p) => (p.disabled ? 'not-allowed' : 'pointer')};
    color: ${(p) => (p.selected ? p.theme.white : 'inherit')};
    background: ${(p) => (p.selected ? p.theme.colors[p.theme.primaryColor][5] : 'none')};
    padding-top: 4px;
    padding-bottom: 4px;
    &:hover {
        background: ${(p) => (p.disabled ? 'inherit' : p.selected ? p.theme.colors.primary[4] : p.theme.colors.primary[2])};
    }
`;
