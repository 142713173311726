import { NotificationComponentConfig, NotificationComponentConfigType, UserDefinedNotificationConfig } from '@apis/Notification/model';
import { BorderAll, ChartBar, ChartLine, ChartPie, CircleNumber1, Icon, QuestionMark } from 'tabler-icons-react';

type TypeDetail = { type: NotificationComponentConfigType | 'Unspecified'; subType?: string; description: string; icon: Icon };
const typeDetails: TypeDetail[] = [
    {
        type: 'Chart',
        subType: 'pie',
        description: 'Pie Chart',
        icon: ChartPie,
    },
    {
        type: 'Chart',
        subType: 'grid',
        description: 'Table',
        icon: BorderAll,
    },
    {
        type: 'Chart',
        subType: 'bar',
        description: 'Bar Chart',
        icon: ChartBar,
    },
    {
        type: 'Chart',
        subType: 'line',
        description: 'Line Chart',
        icon: ChartLine,
    },
    {
        type: 'Chart',
        subType: 'kpi',
        description: 'KPI',
        icon: CircleNumber1,
    },
    {
        type: 'DataGrid',
        description: 'Table',
        icon: BorderAll,
    },
];

const typeLookup = {
    types: new Map<string, TypeDetail>(typeDetails.map((td) => [`${td.type}:${td.subType}`, td])),
    get: (type: string, subType?: string) => typeLookup.types.get(`${type}:${subType}`),
};

export function describeComponent(componentConfig: NotificationComponentConfig): TypeDetail {
    const type = componentConfig.Type;
    const subType = type === 'Chart' ? (componentConfig as { chartType: string }).chartType : undefined;
    return typeLookup.get(type ?? '', subType) ?? { type: 'Unspecified', description: 'Not Configured', icon: QuestionMark };
}
