import { inject, injectable } from 'tsyringe';
import { IDatasourcePresetsBuilderFactory } from './BasePresets';
import { CostForecastDatasourcePresetsBuilderFactory } from './CostForecastPresets';
import { InvoiceDatasourcePresetsBuilderFactory } from './InvoicePresets';
import { ResourceDatasourcePresetsBuilderFactory } from './ResourcePresets';

@injectable()
export class DatasourcePresetsCollection {
    private readonly factoryByDsName = new Map<string, IDatasourcePresetsBuilderFactory<unknown>>();
    private readonly factories: ReadonlyArray<IDatasourcePresetsBuilderFactory<unknown>> = [];

    public constructor(
        @inject(InvoiceDatasourcePresetsBuilderFactory) invoicePresets: InvoiceDatasourcePresetsBuilderFactory,
        @inject(ResourceDatasourcePresetsBuilderFactory) resourcePresets: ResourceDatasourcePresetsBuilderFactory,
        @inject(CostForecastDatasourcePresetsBuilderFactory) costForecastPresets: CostForecastDatasourcePresetsBuilderFactory
    ) {
        const factories = (this.factories = [invoicePresets, resourcePresets, costForecastPresets]);
        for (const factory of factories) {
            for (const ds of factory.getSupportedDatasources()) {
                this.factoryByDsName.set(ds.name, factory);
            }
        }
    }

    public getFactories() {
        return this.factories;
    }

    public getDatasourceFactories(datasource?: string): Array<[string, IDatasourcePresetsBuilderFactory<unknown>]> {
        return datasource ? [[datasource, this.factoryByDsName.get(datasource)!]] : [...this.factoryByDsName.entries()];
    }

    public async getBuilders(datasource: string | undefined, context: {}) {
        const factories = this.getDatasourceFactories(datasource);
        return await Promise.all(factories.map(([ds, f]) => f.get(ds, context)));
    }
}
