import { ActionIcon, Box, createStyles, Drawer, Group, Modal, Tooltip } from '@mantine/core';
import { useWindowSize } from '@react-hookz/web';
import { useRouteBoundPortal } from '@root/Components/Router/RouteBoundPortal';
import { useMainNav } from '@root/Design/Nav';
import { SettingsWithPreviewContainer } from '@root/Design/Settings';
import { useGlobalUserPrefs } from '@root/Services/Customers/UserPreferenceService';
import { ReactNode, useCallback, useMemo } from 'react';
import { Maximize, Minimize } from 'tabler-icons-react';

interface DashboardEditorOverlayProps {
    settings: () => ReactNode;
    preview: (maxH?: number, maxW?: number) => ReactNode;
    open: boolean;
    settingsWidth?: number;
    ratio?: number;
}
export function DashboardEditorOverlay({ settings, preview, open, settingsWidth = 500, ratio = 1.5 }: DashboardEditorOverlayProps) {
    const { previewCardHeight, previewCardWidth, previewWidth, totalWidth } = useOverlayDimensions(ratio, settingsWidth);
    const drawerWidth = settingsWidth + previewWidth;
    const { classes } = useDrawerStyles({ width: drawerWidth });
    const target = useRouteBoundPortal();
    const { setZoom, zoom } = useChartEditorPrefs();

    return (
        <>
            <Drawer
                shadow="lg"
                overlayBlur={4}
                position="right"
                target={target}
                closeOnClickOutside={false}
                opened={open}
                onClose={() => {}}
                withCloseButton={false}
                className={classes.drawer}
                sx={{
                    '> div': { backgroundColor: '#0000' },
                }}
            >
                <Box sx={{ width: totalWidth, height: '100%' }}>
                    {!open ? null : (
                        <SettingsWithPreviewContainer
                            settings={settings()}
                            previewType="center"
                            title={
                                <Group>
                                    <PreviewSizeToggle zoom={zoom} onChange={setZoom} />
                                    Preview
                                </Group>
                            }
                            preview={preview(zoom ? undefined : previewCardHeight, zoom ? undefined : previewCardWidth)}
                        />
                    )}
                </Box>
            </Drawer>
        </>
    );
}

function useChartEditorPrefs() {
    const userPrefs = useGlobalUserPrefs<{ previewZoom: boolean }>('ChartEditorPrefs', { previewZoom: false });
    return useMemo(
        () => ({
            get zoom() {
                return userPrefs.get().previewZoom;
            },
            setZoom(value: boolean) {
                userPrefs.set({ previewZoom: value });
            },
        }),
        []
    );
}

function useOverlayDimensions(ratio: number, settingsWidth = 500) {
    const { width: windowW, height: windowH } = useWindowSize();
    const { width: mainNavW } = useMainNav();
    const totalWidth = windowW - mainNavW;
    const previewWidth = totalWidth - settingsWidth;
    const previewCardWidth = previewWidth - 200;
    const previewCardHeight = Math.min(windowH - 100, previewCardWidth / ratio);
    return { previewCardHeight, previewCardWidth, previewWidth, totalWidth };
}

function PreviewSizeToggle({ zoom, onChange }: { zoom: boolean; onChange: (value: boolean) => void }) {
    const label = zoom ? 'Show actual size' : 'Show full size';
    const toggle = useCallback(() => onChange(!zoom), [zoom, onChange]);
    const Icon = zoom ? Minimize : Maximize;
    return (
        <Tooltip label={label} position="bottom">
            <ActionIcon onClick={toggle}>
                <Icon strokeWidth={1} size={18} />
            </ActionIcon>
        </Tooltip>
    );
}

const useDrawerStyles = createStyles((theme, params: { width?: number }) => ({
    drawer: {
        '.mantine-Drawer-drawer': {
            width: params.width,
        },
        '.mantine-Drawer-overlay': {
            clipPath: `inset(0 ${params.width ?? 0}px 0 0)`,
        },
    },
}));
