import { Alert, Box, Button, Group, MultiSelect, Space, Textarea, Text, Anchor } from '@mantine/core';
import React, { useCallback, useEffect, useState } from 'react';
import { useInputState } from '@mantine/hooks';
import { postNotificationSendNotification } from '@apis/Notification';
import { useDi, useDiMemo } from '@root/Services/DI';
import { NotificationService } from '@root/Services/Notification/NotificationService';
import { useCompany } from '@root/Components/Router/CompanyContent';
import { AuthenticationService } from '@root/Services/AuthenticationService';
import { CompanyRoleService } from './Users/CompanyRoleService';
import { MspService } from '@root/Services/MspService';
import { CompanyUserService } from '@root/Services/Customers/CompanyUserService';

export function UserInviteModal(props: { onClose: (didInvite: boolean) => void }) {
    const companyId = useCompany()?.Id!;
    const companyRoleSvc = useDi(CompanyRoleService);
    const [errorMessage, setErrorMessage] = useState<JSX.Element>(<Text></Text>);
    const [error, setError] = useInputState(false);
    const [roles, setRoles] = useState([] as { value: number; label: string }[]);
    const notificationService = useDi(NotificationService);
    const authNSvc = useDi(AuthenticationService);
    const user = authNSvc.user;
    const userId = user?.ExternalId!;
    const [invitesSent, setInvitesSent] = useState(false);
    const [emailError, setEmailError] = useState<string>();
    const [formIsValid, setFormIsValid] = useState<boolean>(false);
    const [selectedEmails, setSelectedEmails] = useState<string>('');
    const [selectedRoles, setSelectedRoles] = useState<number[]>([]);
    const [emailTouched, setEmailTouched] = useState<boolean>(false);
    const [emailFocus, setEmailFocus] = useState<boolean>(false);
    const mspSvc = useDi(MspService);
    const companyUserSvc = useDiMemo(CompanyUserService);

    const emailSupport = () => {
        document.location.href = mspSvc.mspSupportAndLogos.SupportEmail;
    };

    useEffect(() => {
        (async () => {
            const roles = await companyRoleSvc.getRoleList();
            roles.sort((a, b) => a.Role?.Name?.localeCompare(b.Role?.Name ?? '') || a.Role?.Id! - b.Role?.Id!);
            setRoles(roles.map((role) => ({ value: role.Role!.Id!, label: role.Role!.Name! })));
        })();
    }, []);

    useEffect(() => {
        let validForm = formsValid();
        setFormIsValid(validForm);
    }, [JSON.stringify(selectedRoles), selectedEmails]);

    async function SendInvites() {
        if (!invitesSent) {
            setInvitesSent(true);
            // Fix any spaces, line breaks, etc. that the user might have put in while entering email addresses
            var emails = removeSpecialChars();
            setErrorMessage(<Text></Text>);
            setError(false);
            try {
                await postNotificationSendNotification(
                    { companyId, userId, WelcomeInviteData: [{ emails: emails, roles: selectedRoles }] },
                    { notificationType: 'Welcome' }
                );
                companyUserSvc.invalidate();
                notificationService.notify(
                    'Invitations Sent',
                    'New users have been invited to your company',
                    'success',
                    <i className="ti ti-mail-forward" />
                );
                props.onClose(true);
            } catch (err: any) {
                setErrorMessage(
                    <Text>
                        <Text>There was an error inviting the user(s):</Text>
                        <Space h={12} />
                        <Text style={{ fontWeight: 'bold', fontStyle: 'italic' }}>{err.message}</Text>
                        <Space h={12} />
                        <Text>
                            Please contact {mspSvc.mspSupportAndLogos.CompanyName} customer support at{' '}
                            <Anchor onClick={emailSupport}>{mspSvc.mspSupportAndLogos.SupportEmail.replace('mailto:', '')}</Anchor>.
                        </Text>
                    </Text>
                );
                setError(true);
                setInvitesSent(false);
            }
        }
    }
    const cancel = useCallback(() => props.onClose(false), [props.onClose]);

    const removeSpecialChars = () => {
        var emails = selectedEmails;
        emails = emails.replace(/ /g, '');
        emails = emails.trim();
        while (emails.indexOf('\n') > -1) {
            emails = emails.replace('\n', ',');
        }
        while (emails.slice(-1) == ',') {
            emails = emails.slice(0, -1);
        }
        while (emails.indexOf(',,') > -1) {
            emails = emails.replace(',,', ',');
        }
        return emails;
    };

    const formsValid = (): boolean => {
        return roleIsValid() && allEmailAddressesAreValid();
    };

    const roleIsValid = (): boolean => {
        return selectedRoles.length > 0 && roles.filter((r) => selectedRoles.includes(r.value)).length > 0;
    };

    const allEmailAddressesAreValid = () => {
        let emails = selectedEmails.split(',');
        let errors = emails.filter((email) => validateEmailAddress(email) !== null);
        if (selectedEmails.length === 0) {
            setEmailError('Email address is required.');
            return false;
        } else if (errors?.length > 0) {
            setEmailError(
                `There ${errors.length > 1 ? 'are' : 'is'} ${errors.length} invalid email ${errors.length > 1 ? 'addresses.' : 'address.'}`
            );
            return false;
        } else {
            setEmailError(undefined);
        }
        return true;
    };

    const validateEmailAddress = (email: string) => {
        // Ignore trailing comma
        if (email.length === 0) {
            return null;
        }
        return /(.+)@(.+){2,}\.(.+){2,}/.test(email) ? null : `"${email}" is not a valid email address.`;
    };

    const handleEmailChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setSelectedEmails(e.target.value);
    };

    const handleRoleChange = (e: string[]) => {
        let values = e.map((val) => +val);
        setSelectedRoles(values);
    };

    const handleEmailFocus = () => {
        setEmailFocus(true);
    };

    const handleEmailBlur = () => {
        setEmailTouched(true);
        setEmailFocus(false);
    };

    return (
        <>
            <Box p="m">
                <div>
                    <div>
                        <Textarea
                            minRows={2}
                            label="Invite By Email"
                            name="email"
                            placeholder="Email, comma separated"
                            required={true}
                            onChange={(e) => handleEmailChange(e)}
                            onFocus={handleEmailFocus}
                            onBlur={handleEmailBlur}
                            data-atid="UsersEmailInput"
                        />
                        <Text color="error" hidden={emailError == undefined || !emailTouched || emailFocus}>
                            * {emailError}
                        </Text>
                    </div>
                    <Space h="md" />
                    <div>
                        <Space w="sm" />
                        <MultiSelect
                            required
                            data-atid="UserRolesDDL"
                            label="Assign Roles"
                            data={roles.map((i) => {
                                return { label: i.label, value: i.value.toString(), atid: 'AssignRole:' + i.label };
                            })}
                            style={{ flex: 12 }}
                            onChange={handleRoleChange}
                        />
                        <Space w="sm" />
                    </div>
                </div>
            </Box>
            <div hidden={!error}>
                <Box>
                    <Alert color="error">{errorMessage}</Alert>
                    <Space p="md" />
                </Box>
            </div>
            <Space h="md" />

            <Group position="right">
                <Button data-atid="SettingsCancelButton" onClick={cancel} variant="outline" disabled={!invitesSent ? false : true}>
                    Cancel
                </Button>
                <Button data-atid="SendInviteButton" disabled={!formIsValid} onClick={SendInvites} variant={!invitesSent ? 'filled' : 'light'}>
                    {!invitesSent ? 'Send Invite' : 'Sending...'}
                </Button>
            </Group>
        </>
    );
}
