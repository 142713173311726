import { getCreditsGetCreditSummary } from '@apis/Customers';
import { NavBarItem, PageNavBar } from '@root/Components/PageNavBar/PageNavBar';
import { useCompany } from '@root/Components/Router/CompanyContent';
import { useAuthZValues } from '@root/Services/AuthorizationService';
import { useEffect, useState } from 'react';
import { useCompanyFeaturesParentContext, useUserAccessContext } from '@root/Components/Shell/AppFeatureAccess';

export function SettingsNav() {
    const company = useCompany();
    if (company?.Type === 'StrategicPartner') {
        return <StrategicPartnerSettingsNav />;
    } else {
        return <CustomerSettingsNav />;
    }
}

function CustomerSettingsNav() {
    const currentCompany = useCompany();
    const isSupport = !(currentCompany?.Type === 'Customer' || !currentCompany?.Type);
    const [canUseCredits, setCanUseCredits] = useState(true);
    useEffect(() => {
        const fetchCreditSummary = async () => {
            const creditSummary = await getCreditsGetCreditSummary();
            setCanUseCredits(creditSummary?.CanUseCredits ?? true);
        };

        fetchCreditSummary();
    }, []);
    const accessLookup = useUserAccessContext();

    const hasFeature = (feature: string) => accessLookup.checkAccessByName(feature);

    const items = [
        { type: 'link', text: 'Overview', icon: <i className="ti ti-home"></i>, endpoint: isSupport ? 'msp-settings-overview' : 'settings-overview' },
    ] as NavBarItem[];

    if (hasFeature('Users'))
        items.push({
            type: 'link',
            text: 'Users',
            icon: <i className="ti ti-users"></i>,
            endpoint: 'user-admin',
            atid: 'SettingsNavUsers',
        });
    if (hasFeature('Roles & Permissions'))
        items.push({
            type: 'link',
            text: 'Roles & Permissions',
            icon: <i className="ti ti-lock"></i>,
            endpoint: 'user-roles',
            atid: 'SettingsNavUserRoles',
        });
    if (!isSupport && hasFeature('Cloud Connections'))
        items.push({
            type: 'link',
            text: 'Connections',
            icon: <i className="ti ti-vector-bezier"></i>,
            endpoint: 'connections',
            atid: 'SettingsNavConnections',
        });
    if (!isSupport && hasFeature('Cloud Connections'))
        items.push({
            type: 'link',
            text: 'Connection Health',
            icon: <i className="ti ti-checkup-list"></i>,
            endpoint: 'connection-health',
            atid: 'SettingsNavConnectionHealth',
        });
    if (!isSupport)
        items.push({
            type: 'link',
            text: 'Activity Log',
            icon: <i className="ti ti-list-check"></i>,
            endpoint: 'settings-activity-log',
            atid: 'SettingsNavActivityLog',
        });
    if (hasFeature('Single Sign On'))
        items.push({
            type: 'link',
            text: 'Single Sign-On',
            icon: <i className="ti ti-cloud-lock"></i>,
            endpoint: 'sso',
            atid: 'SettingNavSSO',
        });
    if (isSupport)
        items.push({
            type: 'link',
            text: 'Billing Tax Codes',
            icon: <i className="ti ti-receipt-2"></i>,
            endpoint: 'billing-tax-codes',
            atid: 'SettingsNavTaxCodes',
        });
    if (
        // (!isSupport && hasFeature('Subscriptions')) ||
        // (canUseCredits && !isSupport && hasFeature('Credit Usage')) ||
        hasFeature('Company Info') ||
        // (!isSupport && hasFeature('Invoices')) ||
        (!isSupport && hasFeature('Payment Methods')) ||
        isSupport
    )
        if (hasFeature('Company Info'))
            items.push({
                type: 'link',
                text: 'Company Info',
                icon: <i className="ti ti-building-skyscraper"></i>,
                endpoint: 'company-info',
                atid: 'SettingsNavCompanyInfo',
            });
    if (!isSupport && hasFeature('Payment Methods'))
        items.push({
            type: 'link',
            text: 'Payment Methods',
            icon: <i className="ti ti-credit-card"></i>,
            endpoint: 'payment-methods',
            atid: 'SettingsNavPaymentMethods',
        });

    return <PageNavBar items={items} />;
}

function StrategicPartnerSettingsNav() {
    const { users } = useAuthZValues({
        users: { User: 'View' },
    });
    const items = [
        { type: 'title', text: 'General' },
        {
            type: 'link',
            text: 'Overview',
            icon: <i className="ti ti-home"></i>,
            endpoint: 'settings-overview',
            atid: 'SettingsNavStrategicPartnerOverview',
        },
    ] as NavBarItem[];

    if (users)
        items.push({
            type: 'link',
            text: 'Users',
            icon: <i className="ti ti-users"></i>,
            endpoint: 'user-admin',
            atid: 'SettingsNavStrategicPartnerUsers',
        });

    return <PageNavBar items={items} />;
}
