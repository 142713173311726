import { postBillingInvoiceSaveBillingTaxCode } from '@apis/Invoices';
import { BillingTaxCode } from '@apis/Invoices/model';
import { Button, Grid, Group, LoadingOverlay, Space, Text, TextInput } from '@mantine/core';
import { DatePicker } from '@mantine/dates';
import { set } from 'date-fns';
import { useState } from 'react';

export function BillingTaxCodeForm(props: { onClose: (reload: boolean) => void; taxCode?: BillingTaxCode }) {
    const [loading, setLoading] = useState(false);
    const [hasChanges, setHasChanges] = useState<boolean>(false);
    const [rate, setRate] = useState<number>(props.taxCode?.Rate!);
    const [code, setCode] = useState<string>(props.taxCode?.Code!);
    const [effectiveFrom, setEffectiveFrom] = useState<Date>(new Date(props.taxCode?.EffectiveFrom!));
    const [effectiveTo, setEffectiveTo] = useState<Date>(new Date(props.taxCode?.EffectiveTo!));
    const [jurisdiction, setJurisdiction] = useState<string>(props.taxCode?.Jurisdiction!);

    const handleJurisdictionChange = (e: any) => {
        if (e.target.value !== props.taxCode?.Jurisdiction) {
            setJurisdiction(e.target.value);
            setHasChanges(true);
        }
    };

    const handleRateChange = (e: any) => {
        if (parseInt(e.currentTarget.value, 10) !== props.taxCode?.Rate) {
            setRate(parseInt(e.currentTarget.value, 10));
            setHasChanges(true);
        }
    };

    const handleCodeChange = (e: any) => {
        if (e.target.value !== props.taxCode?.Code) {
            setCode(e.target.value);
            setHasChanges(true);
        }
    };

    const handleEffectiveFromDateChange = (date: Date) => {
        if (date === undefined || date < new Date(props.taxCode?.EffectiveFrom!)) {
            setEffectiveFrom(date);
            setHasChanges(true);
        }
    };

    const handleEffectiveToDateChange = (date: Date) => {
        if (date === undefined || date > new Date(props.taxCode?.EffectiveTo!)) {
            setEffectiveTo(date);
            setHasChanges(true);
        }
    };

    const handleReset = () => {
        setRate(props.taxCode?.Rate!);
        setCode(props.taxCode?.Code!);
        setEffectiveFrom(new Date(props.taxCode?.EffectiveFrom!));
        setEffectiveTo(new Date(props.taxCode?.EffectiveTo!));
        setJurisdiction(props.taxCode?.Jurisdiction!);
        setHasChanges(false);
    };

    const handleSave = async (e: any) => {
        if (e) {
            e.preventDefault();
        }

        if (hasChanges) {
            try {
                setLoading(true);
                const changedBillingTaxCode = {
                    ...props.taxCode!,
                    Rate: rate,
                    Code: code,
                    EffectiveFrom: effectiveFrom.toISOString(),
                    EffectiveTo: effectiveTo.toISOString(),
                    Jurisdiction: jurisdiction,
                };
                await postBillingInvoiceSaveBillingTaxCode(changedBillingTaxCode);
                props.onClose(true);
            } finally {
                setLoading(false);
            }
        }
    };
    return (
        <>
            {loading && <LoadingOverlay visible={true} />}
            <div>
                <Space h="md" />
                <Grid>
                    <Grid.Col span={4}>
                        <Text>Jurisdiction</Text>
                    </Grid.Col>
                    <Grid.Col span={8}>
                        <TextInput value={jurisdiction} onChange={handleJurisdictionChange}></TextInput>
                    </Grid.Col>
                    <Grid.Col span={4}>
                        <Text>Rate</Text>
                    </Grid.Col>
                    <Grid.Col span={8}>
                        <TextInput value={rate.toString()} onChange={handleRateChange}></TextInput>
                    </Grid.Col>
                    <Grid.Col span={4}>
                        <Text>Code</Text>
                    </Grid.Col>
                    <Grid.Col span={8}>
                        <TextInput value={code} onChange={handleCodeChange}></TextInput>
                    </Grid.Col>
                    <Grid.Col span={4}>
                        <Text>Effective From</Text>
                    </Grid.Col>
                    <Grid.Col span={8}>
                        <DatePicker value={new Date(effectiveFrom)} onChange={handleEffectiveFromDateChange}></DatePicker>
                    </Grid.Col>
                    <Grid.Col span={4}>
                        <Text>Effective To</Text>
                    </Grid.Col>
                    <Grid.Col span={8}>
                        <DatePicker value={new Date(effectiveTo)} onChange={handleEffectiveToDateChange}></DatePicker>
                    </Grid.Col>
                </Grid>
                <Space h="md" />
                <Group position="right">
                    <Button onClick={handleReset} variant="outline" disabled={!hasChanges}>
                        Reset
                    </Button>
                    <Button onClick={handleSave} disabled={!hasChanges}>
                        Save
                    </Button>
                </Group>
                <Space h="md" />
            </div>
        </>
    );
}
