import { Company } from '@apis/Customers/model';
import { getAssessmentsGetAssessmentDefinitions, postAssessmentsQueryAssessments } from '@apis/Resources';
import { AssessmentDefinition, AssessmentResult } from '@apis/Resources/model';
import { Box, Button, Center, Drawer, Loader, Stack, Text, useMantineTheme } from '@mantine/core';
import { DataGrid } from '@root/Components/DataGrid';
import { DataGridModel } from '@root/Components/DataGrid/DataGridModel';
import { ColumnConfig, DataGridState } from '@root/Components/DataGrid/Models';
import { useCompany } from '@root/Components/Router/CompanyContent';
import { ICompanyContextToken } from '@root/Services/Customers/CompanyContext';
import { useDi, useDiContainer } from '@root/Services/DI';
import { EventEmitter, useEventValue } from '@root/Services/EventEmitter';
import { FormatService } from '@root/Services/FormatService';
import { queryBuilder } from '@root/Services/QueryExpr';
import { useMemo, useState } from 'react';
import { inject, injectable } from 'tsyringe';
import { AssessmentDetails } from './AssessmentDetails';
import { CustomColors } from '@root/Design/Themes';
import { FooterBox, FooterSavingsCell, getAssessmentColor, GridNACell, GridScoreCell, SavingsCell, ScoreCell } from './Design';
import { AssessmentAction } from './Components/AssessmentActions';
import { PlatformService } from '@root/Services/PlatformService';
import { ExternalLink } from 'tabler-icons-react';
import { IValueProviderFactory } from '@root/Services/Query/QueryDatasource';

interface FooterRecord {
    AverageScore: number;
    TotalSavings: number;
    TotalOpportunities: number;
}

export function AssessmentsGrid() {
    const theme = useMantineTheme();
    const di = useDiContainer();
    const formatSvc = useDi(FormatService);
    const company = useCompany();
    const model = useMemo(() => di.resolve(AssessmentsGridModel).init(), []);
    const loading = useEventValue(model.loading);
    const [selectedItem, setSelectedItem] = useState<AssessmentDefLatestResult>();
    const [showSidePanel, setshowSidePanel] = useState(false);

    const handleRowClick = async (item: AssessmentDefLatestResult) => {
        if (item.latest !== undefined) {
            setSelectedItem(item);
            setshowSidePanel(true);
        }
    };

    function closeAssessmentDetails() {
        setSelectedItem(undefined);
        setshowSidePanel(false);
    }

    const handleToggleSidePanel = (showSidePanel: boolean) => {
        setshowSidePanel(showSidePanel);
    };

    const blankData = useMemo(() => [], []);

    const data = useEventValue(model.data) ?? blankData;
    const footerRecord = useEventValue(model.footerRecord);

    function openManagedServices() {
        window.open('https://www.cloudsaver.com/schedule-a-consultation/');
    }

    return (
        <Stack sx={{ height: '100%' }} p="lg">
            <Box
                sx={{
                    flex: 1,
                    height: '100%',
                    overflow: 'hidden',
                    ['tbody tr']: {
                        backgroundImage: 'linear-gradient(180deg, rgba(0, 0, 0, .10) 0%, rgba(74, 74, 74, 0) 17%, rgba(74, 74, 74, 0) 100%)',
                    },
                }}
            >
                <DataGrid
                    dataSource={model.datasource}
                    columns={model.columns}
                    state={model.state}
                    onRowClick={handleRowClick}
                    onModelLoaded={model.attachGrid}
                    highlightColor={theme.colors.primary[1]}
                    rowStyle={(item, index, hovered) => ({
                        backgroundColor: !item.latest ? theme.colors.gray[3] : hovered ? theme.colors.primary[2] : 'transparent',
                        cursor: item.latest ? 'pointer' : 'default',
                    })}
                    onRefreshing={model.refreshDataOnly}
                    itemHeight={60}
                    showRefresh
                    exportName="Assessments"
                    selectionMode="none"
                />
            </Box>
            <FooterBox>
                {loading ? (
                    <Center sx={{ width: '100%' }}>
                        <Loader color="gray.0" />
                    </Center>
                ) : (
                    <>
                        <div style={{ width: 705 }}>
                            <Text size="lg">Total for {company?.CompanyName}</Text>
                        </div>
                        <div style={{ width: 125 }}>
                            <ScoreCell backgroundColor={getAssessmentColor(footerRecord?.AverageScore ?? 0) as unknown as CustomColors}>
                                {!isNaN(footerRecord?.AverageScore ?? 0) ? Math.round((footerRecord?.AverageScore ?? 0) * 100) : 0}
                            </ScoreCell>
                        </div>
                        <div style={{ width: 150 }}>
                            <FooterSavingsCell>{formatSvc.formatMoneyNoDecimals(footerRecord?.TotalSavings ?? 0)}</FooterSavingsCell>
                        </div>
                        <div style={{ width: 150 }}>
                            <FooterSavingsCell>{formatSvc.formatInt0Dec(footerRecord?.TotalOpportunities ?? 0)}</FooterSavingsCell>
                        </div>
                        <div style={{ width: 200, textAlign: 'center' }}>
                            <Button variant="white" rightIcon={<ExternalLink size={16} />} onClick={openManagedServices}>
                                Managed Services
                            </Button>
                        </div>
                    </>
                )}
            </FooterBox>
            {showSidePanel && (
                <Drawer
                    opened={showSidePanel}
                    onClose={() => closeAssessmentDetails()}
                    size={550}
                    padding={0}
                    position="right"
                    overlayOpacity={0.1}
                    withinPortal={false}
                    withCloseButton={false}
                    withOverlay={true}
                    shadow="md"
                    closeOnClickOutside={true}
                    closeOnEscape={true}
                >
                    <AssessmentDetails
                        toggleSidePanel={handleToggleSidePanel}
                        assessmentResult={selectedItem?.latest!}
                        assessmentDef={selectedItem!}
                    ></AssessmentDetails>
                </Drawer>
            )}
        </Stack>
    );
}

type AssessmentDefLatestResult = AssessmentDefinition & { latest?: AssessmentResult; notApplicable: boolean };

@injectable()
class AssessmentsGridModel {
    public loading = new EventEmitter<boolean>(false);
    public dataGrid?: DataGridModel;
    public footerRecord = new EventEmitter<FooterRecord | undefined>(undefined);
    public data = new EventEmitter<AssessmentDefLatestResult[]>([]);
    public datasource = {
        getPage: async (start: number, end: number, state: DataGridState) => {
            const items = this.data.value as AssessmentDefLatestResult[];
            const ds = this.dataGrid?.createArrayDataSource(items);
            const stateOverride: DataGridState = {
                ...state,
                sort: [{ Direction: 'Asc', Expr: { Field: 'notApplicable', Type: 'boolean' } }, ...(state.sort ?? [])],
            };
            return ds?.getPage(start, end, stateOverride) ?? { items: [], total: 0 };
        },
    };
    public columns: ColumnConfig<AssessmentDefLatestResult>[] = [];
    public state: DataGridState = { filters: [], sort: [], columns: [] };
    public filterValueProvider: IValueProviderFactory = {
        getValueProvider: (fieldExpr) => {
            const field = fieldExpr.Field;
            if (field === 'Platform') {
                return [
                    { value: 'Aws', label: 'AWS' },
                    { value: 'Azure', label: 'Azure' },
                ];
            }
            return async () => {
                const items = this.data.value as unknown as { [key: string]: string }[];
                const values = [...items.map((item) => item[field]).reduce((result, item) => result.add(item), new Set<string>())].sort();
                return values;
            };
        },
    };

    public constructor(
        @inject(FormatService) private readonly formatSvc: FormatService,
        @inject(ICompanyContextToken) private readonly company: Company,
        @inject(PlatformService) private readonly platformSvc: PlatformService
    ) {}

    public init() {
        this.columns = this.createColumns();

        this.state = {
            columns: this.columns.map((c) => ({ id: c.id, width: c.defaultWidth })),
            filters: [],
            sort: [{ Direction: 'Desc', Expr: { Field: 'Savings' } }],
        };

        this.load();
        return this;
    }

    private async load() {
        try {
            this.loading.emit(true);
            await this.platformSvc.initIfNeeded();
            const assessmentResults = await queryBuilder<AssessmentResult>()
                .where((b) => b.and(b.model.IsLatest!.eq(true), b.model.CompanyId!.eq(this.company.Id!)))
                .take(1000)
                .execute(postAssessmentsQueryAssessments);
            const assessmentDefs = await getAssessmentsGetAssessmentDefinitions();
            const resultLookup = (assessmentResults.Results ?? []).reduce(
                (lookup, result) => lookup.set(`${result.AssessmentDefId}-${result.Platform}`, result),
                new Map<string, AssessmentDefLatestResult['latest']>()
            );

            const companyPlatforms = this.platformSvc.getPlatforms();

            const results = (assessmentDefs ?? []).flatMap((def) => {
                const applicablePlatforms = def.ApplicablePlatforms!.filter((platform) => companyPlatforms.has(platform));
                return (applicablePlatforms ?? ['']).map((platform) => {
                    const latest = resultLookup.get(`${def.Id}-${platform}`);
                    return { ...def, notApplicable: latest?.AssessmentScore?.TotalCount === 0, latest };
                });
            });

            this.data.emit(results);
            this.dataGrid?.refresh(true);

            this.updateFooter(results);
        } finally {
            this.loading.emit(false);
        }
    }

    private getScore(res: AssessmentResult | undefined) {
        return !res?.AssessmentScore?.TotalCount ? -1 : res?.AssessmentScore?.AssessmentScore ?? 0;
    }

    private createColumns(): ColumnConfig<AssessmentDefLatestResult>[] {
        return [
            {
                header: 'Service',
                accessor: (d) => this.formatSvc.adjustCspName(d.latest?.Platform ?? ''),
                id: 'Service',
                type: 'string',
                defaultWidth: 125,
                align: 'center',
                cellRenderer: (d) => {
                    return d.latest?.Platform === 'Aws' ? (
                        <img src="/assets/Amazon_Web_Services_Logo.svg" style={{ height: 26 }} />
                    ) : d.latest?.Platform === 'Azure' ? (
                        <img src="/assets/azure_logo.svg" style={{ height: 32 }} />
                    ) : null;
                },
                sortField: 'Platform',
                filter: {
                    filterType: 'string',
                    name: 'Service',
                    filterField: 'Platform',
                    options: this.filterValueProvider,
                },
            },
            {
                header: 'Assessment',
                accessor: 'UfTitle',
                id: 'Name',
                type: 'string',
                defaultWidth: 200,
                filter: {
                    filterType: 'string',
                    name: 'Assessment',
                    filterField: 'UfTitle',
                    options: this.filterValueProvider,
                },
            },
            {
                header: 'Category',
                accessor: 'AssessmentCategory',
                id: 'AssessmentCategory',
                type: 'string',
                defaultWidth: 200,
                filter: {
                    filterType: 'string',
                    name: 'Category',
                    filterField: 'AssessmentCategory',
                    options: this.filterValueProvider,
                },
            },
            {
                header: 'Type',
                accessor: 'AssessmentType',
                id: 'AssessmentType',
                type: 'string',
                defaultWidth: 200,
                sortField: 'AssessmentType',
                filter: {
                    filterType: 'string',
                    name: 'Type',
                    filterField: 'AssessmentType',
                    options: this.filterValueProvider,
                },
            },
            {
                id: 'Score',
                accessor: (a) => {
                    const score = this.getScore(a.latest);
                    return score === -1 ? null : Math.round(score * 100);
                },
                header: 'Score',
                noResize: true,
                defaultWidth: 125,
                align: 'left',
                type: 'number',
                filter: {
                    filterType: 'number',
                    name: 'Score',
                    filterField: 'AssessmentScore.AssessmentScore',
                },
                sortField: 'Score',
                cellRenderer: (item: AssessmentDefLatestResult) => {
                    let score = this.getScore(item.latest);
                    return score == -1 ? (
                        <GridNACell>
                            <Text>N/A</Text>
                        </GridNACell>
                    ) : (
                        <GridScoreCell backgroundColor={getAssessmentColor(score) as unknown as CustomColors}>
                            {Math.round(score * 100)}
                        </GridScoreCell>
                    );
                },
            },
            {
                id: 'Savings',
                accessor: (a) => a.latest?.AssessmentScore?.AssessmentSavings,
                header: 'Savings or Risk',
                noResize: true,
                defaultWidth: 150,
                align: 'right',
                type: 'number',
                sortField: 'Savings',
                filter: {
                    filterType: 'number',
                    name: 'Savings',
                    filterField: 'AssessmentScore.AssessmentSavings',
                },
                cellRenderer: (item: AssessmentDefLatestResult) => (
                    <SavingsCell>{this.formatSvc.formatMoneyNoDecimals(item.latest?.AssessmentScore?.AssessmentSavings ?? 0)}</SavingsCell>
                ),
            },
            {
                id: 'Opportunities',
                accessor: (a) => a.latest?.AssessmentScore?.AssessmentOpportunityCount,
                header: 'Opportunities',
                noResize: true,
                defaultWidth: 150,
                type: 'number',
                align: 'right',
                filter: {
                    filterType: 'number',
                    name: 'Opportunities',
                    filterField: 'AssessmentScore.AssessmentOpportunityCount',
                },
                sortField: 'Opportunities',
                cellRenderer: (item: AssessmentDefLatestResult) => (
                    <SavingsCell>{this.formatSvc.formatInt0Dec(item.latest?.AssessmentScore?.AssessmentOpportunityCount ?? 0)}</SavingsCell>
                ),
            },
            {
                header: '',
                id: 'Action',
                accessor: () => undefined,
                defaultWidth: 200,
                align: 'center',
                exportOptions: { hidden: true },
                noResize: true,
                noReorder: true,
                noRemove: true,
                noSort: true,
                cellRenderer: (item) => {
                    return <AssessmentAction assessment={item.latest} definition={item} />;
                },
            },
        ];
    }
    public handleRunAssessment(data: AssessmentDefLatestResult) {}

    public refreshDataOnly = async () => {
        await this.load();
    };

    public updateFooter(data: AssessmentDefLatestResult[]) {
        const totals =
            data.reduce(
                (result, item) => {
                    let score = this.getScore(item.latest!) ?? 0;
                    result.AverageScore += score > -1 ? score : 0;
                    result.TotalSavings += Math.round(item.latest?.AssessmentScore?.AssessmentSavings ?? 0);
                    result.TotalOpportunities += Math.round(item.latest?.AssessmentScore?.AssessmentOpportunityCount ?? 0);
                    result.TotalCount += score > -1 ? 1 : 0;
                    return result;
                },
                {
                    AverageScore: 0,
                    TotalSavings: 0,
                    TotalOpportunities: 0,
                    TotalCount: 0,
                }
            ) ?? {};
        totals.AverageScore = totals.AverageScore / totals.TotalCount;
        this.footerRecord.emit(totals);
    }

    public attachGrid = (grid: DataGridModel) => {
        if (this.dataGrid !== grid) {
            this.dataGrid = grid;
        }
    };
}
