import { getAccountGetCompanyAccounts } from '@apis/Customers';
import { postResourcesQuery, QueryResult } from '@apis/Resources';
import { postTagHealthSearch } from '@apis/TagManager';
import { BaseResource, Query } from '@apis/Resources/model';
import { Box, Card, Divider, Grid, Loader, Title, useMantineTheme } from '@mantine/core';
import { ChartDashboardItem } from '@root/Components/DashboardLayout/ChartDashboardItem';
import { DashboardAddOption, CustomizableDashboard } from '@root/Components/DashboardLayout/CustomizableDashboard';
import { IDashboardItemType, IDashboardConfig, DashboardItemProps } from '@root/Components/DashboardLayout/Models';
import { ColumnConfig } from '@root/Components/DataGrid/Models';
import { ConnectionCheck } from '@root/Components/Resources/ConnectionCheck';
import { InitialSyncCheck } from '@root/Components/Resources/IntialSyncCheck';
import { useCompany } from '@root/Components/Router/CompanyContent';
import { PageContent } from '@root/Design/Layout';
import { useDi } from '@root/Services/DI';
import { exprBuilder, queryBuilder, ValuesGroupOtherText } from '@root/Services/QueryExpr';
import { ResourceSchemaProvider } from '@root/Services/Resources/ResourceService';
import { endpoint } from '@root/Services/Router/EndpointRegistry';
import { useState, useEffect, useMemo, useCallback } from 'react';
import { TagIntelligencePage } from '../TagManager/TagIntelligence/TagIntelligencePage';
import styled from '@emotion/styled';
import { Clearfix } from '@root/Design/Primitives';
import { LineChart } from '@root/Components/Charts/LineChart';
import { FormatService } from '@root/Services/FormatService';
import { ViewByGrid } from '../TagManager/TagIntelligence/ViewByGrid';
import { IQueryExpr } from '@apis/Jobs/model';
import { Lifecycle, scoped } from 'tsyringe';
import { EventEmitter, useEventValue } from '@root/Services/EventEmitter';
import { DataGridModel } from '@root/Components/DataGrid/DataGridModel';
import { CustomColors } from '@root/Design/Themes';
import { ChartWrapper } from '@root/Components/Charts/Design';
import { withAppFeatureCheck } from '@root/Components/Shell/AppFeatureAccess';
import { AppFeatureNames } from '@root/Services/Customers/CompanyFeatureService';
import { QueryDatasource } from '@root/Services/Query/QueryDatasource';

@scoped(Lifecycle.ContainerScoped)
class IntelligenceState {
    public readonly viewByRowsChanged = new EventEmitter<string[] | null>(null);
    public viewBy = 'Type';
}

export function IntelligenceDashboardContent() {
    const resourceSchema = useDi(ResourceSchemaProvider);
    const company = useCompany()!;
    const [connected, setConnected] = useState<null | boolean>();
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        (async () => {
            const accounts = await getAccountGetCompanyAccounts();
            resourceSchema.getSchema();
            setConnected(accounts.length > 0);
            setLoading(false);
        })();
    }, []);

    const datasource = useMemo(
        () =>
            [
                {
                    name: 'resources',
                    source: (query: Query) => postResourcesQuery(query, { companyId: company.Id }),
                    schema: resourceSchema,
                    getDefaultGroup: () => ({ Expr: { Field: 'Region' }, Alias: 'Region' }),
                    getDefaultValue: () => ({ Expr: { Operation: 'count' }, Alias: 'Count' }),
                    getDefaultHistogram: () => ({ Expr: { Field: 'CreateTime' }, Alias: 'Create Time' }),
                },
            ] as QueryDatasource[],
        []
    );

    const itemTypes = useMemo(() => {
        const result = [
            ChartDashboardItem.itemType,
            { type: 'intelligence-grid', component: IntelligenceGrid },
            { type: 'intelligence-histogram', component: IntelligenceTiles, custom: true },
        ] as IDashboardItemType[];
        return result;
    }, []);
    const defaultConfig = useMemo(() => {
        const result = {
            name: 'Environment Summary',
            description: `Intelligence Dashboard`,
            layout: [],
        } as IDashboardConfig;
        result.layout.push(
            {
                x: 0,
                y: 2,
                h: 8,
                w: 12,
                data: {
                    type: 'intelligence-grid',
                },
            },
            {
                x: 0,
                y: 0,
                h: 2,
                w: 12,
                data: {
                    type: 'intelligence-histogram',
                },
            }
        );
        return result;
    }, [connected]);
    const addOptions: DashboardAddOption[] = [];

    return (
        <TagIntelligencePage>
            <PageContent>
                {loading || connected === null ? null : (
                    <CustomizableDashboard
                        dashboardKey="intelligence-2"
                        addOptions={addOptions}
                        allowAdd={false}
                        allowFilter={false}
                        datasources={datasource}
                        itemTypes={itemTypes}
                        defaultConfig={defaultConfig}
                        static={true}
                    />
                )}
            </PageContent>
        </TagIntelligencePage>
    );
}
function IntelligenceDashboard() {
    return <ConnectionCheck>{() => <InitialSyncCheck>{() => <IntelligenceDashboardContent />}</InitialSyncCheck>}</ConnectionCheck>;
}

endpoint('intelligence-dashboard', withAppFeatureCheck(IntelligenceDashboard, 'Compliance', AppFeatureNames.TagManager), 'Tag Intelligence');

function IntelligenceGrid(props: DashboardItemProps<{}>) {
    const intelligenceState = useDi(IntelligenceState);
    const [viewBy, setViewBy] = useState('ResourceType');
    const [grid, setGrid] = useState<DataGridModel>();
    const getData = useCallback(async (viewByField: string) => {
        const results = await queryBuilder<BaseResource>()
            .take(500)
            .select((b) => ({
                type: {
                    Operation: 'values',
                    Operands: [{ Field: viewByField }, { Value: '' }, { Value: ValuesGroupOtherText }],
                } as unknown as string,
                resourceCount: b.count(),
                tagCount: b.countValues(b.model['Tags.Key']),
                avgTags: b.avg(b.countValues(b.model['Tags.Key']) as unknown as number),
                tagHealth: b.avg(b.model['TagHealthScore'] as unknown as number),
            }))
            .execute(postResourcesQuery);
        return results.Results ?? [];
    }, []);
    const [data, setData] = useState<ByServiceDataItem[]>([]);
    const formatSvc = useDi(FormatService);
    const columns = useMemo(() => {
        return [
            {
                header: 'Taggable Resources',
                accessor: 'resourceCount',
                defaultWidth: 150,
                id: 'resourceCount',
                type: 'number',
                align: 'center',
                filter: {
                    filterField: 'resourceCount',
                    filterType: 'number',
                    name: 'Resources',
                },
                cellRenderer: (item) => item.resourceCount.toLocaleString(),
            },
            {
                header: 'Number of Tags',
                accessor: 'tagCount',
                defaultWidth: 150,
                id: 'tagCount',
                type: 'number',
                align: 'center',
                filter: {
                    filterField: 'tagCount',
                    filterType: 'number',
                    name: 'Tags',
                },
                cellRenderer: (item) => item.tagCount.toLocaleString(),
            },
            {
                header: 'Average Tags Per Resource',
                defaultWidth: 150,
                accessor: 'avgTags',
                id: 'avgTags',
                type: 'number',
                align: 'center',
                sortField: 'avgTags',
                filter: {
                    filterField: 'avgTags',
                    filterType: 'number',
                    name: 'Tags',
                },
                cellRenderer: (item) => {
                    return item.avgTags.toFixed(1);
                },
            },
            {
                header: 'Overall Tag Health',
                accessor: (item) => item.tagHealth * 100,
                defaultWidth: 150,
                id: 'tagHealth',
                type: 'number',
                align: 'center',
                sortField: 'tagHealth',
                filter: {
                    filterField: 'tagHealth',
                    filterType: 'number',
                    name: 'Overall Tag Health',
                },
                cellRenderer: (item) => {
                    return formatSvc.formatPercent(item.tagHealth);
                },
            },
        ] as ColumnConfig<ByServiceDataItem>[];
    }, []);

    const onViewByChanged = async (viewByField: string) => {
        intelligenceState.viewBy = viewByField;
        const data = await getData(viewByField);
        intelligenceState.viewByRowsChanged.emit(null);
        setData(data);
    };

    useEffect(() => {
        onViewByChanged(viewBy);
    }, [viewBy]);

    const onFilter = (filters: IQueryExpr[]) => {
        let types: null | string[] = null;
        if (grid) {
            const datasource = grid.createArrayDataSource(data);
            types = datasource.filter(filters).map((r: ByServiceDataItem) => r.type);
        }
        intelligenceState.viewByRowsChanged.emit(types);
    };

    if (props.model.dashboard.schemaSvc && data) {
        return (
            <ViewByGrid<ByServiceDataItem>
                data={data}
                onGridLoaded={setGrid}
                onViewByChanged={setViewBy}
                onFilter={onFilter}
                metricColumns={columns}
                schema={props.model.dashboard.schemaSvc}
            />
        );
    } else {
        return null;
    }
}

type ByServiceDataItem = {
    type: string;
    resourceCount: number;
    tagCount: number;
    avgTags: number;
    tagHealth: number;
};

interface IntelligenceTilesMetrics {
    resources: number;
    tags: number;
    avgTags: number;
    overall: number;
}

function IntelligenceTiles() {
    const [metrics, setMetrics] = useState<IntelligenceTilesMetrics>();
    const theme = useMantineTheme();
    const formatSvc = useDi(FormatService);
    const intelligenceState = useDi(IntelligenceState);
    const viewByRowValues = useEventValue(intelligenceState.viewByRowsChanged);

    useEffect(() => {
        (async () => {
            const query = queryBuilder<BaseResource>()
                .select((b) => ({
                    resources: b.count(),
                    tags: b.countValues(b.model['Tags.Key']),
                    avgTags: b.avg(b.countValues(b.model['Tags.Key']) as unknown as number),
                    overall: b.avg(b.model['TagHealthScore'] as unknown as number),
                }))
                .build();
            if (viewByRowValues) {
                query.Where = exprBuilder<Record<string, string>>().createExpr((b) => b.model[intelligenceState.viewBy].eq(viewByRowValues));
            }
            const queryResult = (await postResourcesQuery(query)) as QueryResult<IntelligenceTilesMetrics>;
            setMetrics(queryResult.Results?.[0]);
        })();
    }, [viewByRowValues]);

    const maxNum = Math.max(metrics?.resources !== undefined ? metrics?.resources : 0, metrics?.tags !== undefined ? metrics?.tags : 0);
    var fontSize = '32px';
    const windowWidth = window.innerWidth;
    const minWindow =
        maxNum >= 10000000 ? 1400 : maxNum >= 1000000 ? 1360 : maxNum >= 100000 ? 1260 : maxNum >= 10000 ? 1230 : maxNum >= 1000 ? 1190 : 0;
    if (windowWidth < minWindow) {
        const factor = Math.ceil((minWindow - windowWidth) / 18);
        fontSize = 32 - factor + 'px';
    }

    return (
        <Grid sx={{ height: '100%' }} p={0}>
            <Grid.Col span={3}>
                <KpiTile
                    topColor="#78B23B"
                    title="Taggable Resources"
                    value={metrics?.resources !== undefined ? formatSvc.formatInt(metrics?.resources) : undefined}
                    fontSize={fontSize}
                />
            </Grid.Col>
            <Grid.Col span={3}>
                <KpiTile
                    topColor="#9FDAEA"
                    title="Number of Tags"
                    value={metrics?.tags !== undefined ? formatSvc.formatInt(metrics?.tags) : undefined}
                    fontSize={fontSize}
                />
            </Grid.Col>
            <Grid.Col span={3}>
                <KpiTile
                    topColor="#0489BA"
                    title="Avg Tags per Resource"
                    value={metrics?.avgTags !== undefined ? formatSvc.formatInt(Math.round(metrics.avgTags * 10) / 10) : undefined}
                    fontSize={fontSize}
                />
            </Grid.Col>
            <Grid.Col span={3}>
                <KpiTile
                    topColor={
                        metrics?.overall !== undefined
                            ? metrics?.overall >= 0.8
                                ? theme.colors.success[5]
                                : metrics?.overall >= 0.4
                                ? theme.colors.warning[5]
                                : theme.colors.error[5]
                            : '#f0f0f0'
                    }
                    title="Overall Tag Health"
                    value={metrics?.overall !== undefined ? formatSvc.formatPercent(metrics?.overall) : undefined}
                    fontSize={fontSize}
                />
            </Grid.Col>
        </Grid>
    );
}

export function KpiTile({ value, title, fontSize, topColor }: { value?: string; title: string; fontSize: string; topColor: string }) {
    return (
        <Card withBorder radius="lg" p="lg" sx={{ height: '100%', borderTop: '5px solid ' + topColor }}>
            <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                <Box sx={{ textAlign: 'center' }}>
                    <Title order={4}>{title}</Title>
                </Box>
                <BigNumber style={{ fontSize: fontSize }}>{value === undefined ? <Loader /> : value}</BigNumber>
            </Box>
        </Card>
    );
}

const BigNumber = styled.div`
    font-weight: bold;
    text-align: center;
`;

type TagHealthMetric = {
    Id: string | null;
    CompanyId: number;
    CreatedAt: Date;
    PolicyComplianceScore: number;
    TaggableResourceCount: number;
    NumberOfTags: number;
    AverageNumberOfTagsPerResource: number;
};

function IntelligenceScoreMetricsHistogram() {
    const company = useCompany()!;
    const [complianceHistogramData, setComplianceHistogramData] = useState<Record<string, string | number | Date>[]>();
    const [latestComplianceScore, setLatestComplianceScore] = useState<number>();
    const [differenceComplianceScore, setDifferenceComplianceScore] = useState<number>();
    const [tagCountHistogramData, setTagCountHistogramData] = useState<Record<string, string | number | Date>[]>();
    const [latestTagCountScore, setLatestTagCountScore] = useState<number>();
    const [differenceTagCountScore, setDifferenceTagCountScore] = useState<number>();
    const [resourceCountHistogramData, setResourceCountHistogramData] = useState<Record<string, string | number | Date>[]>();
    const [latestResourceCountScore, setLatestResourceCountScore] = useState<number>();
    const [differenceResourceCountScore, setDifferenceResourceCountScore] = useState<number>();
    const [avgTagCountOnResourceCountHistogramData, setAvgTagCountOnResourceCountHistogramData] =
        useState<Record<string, string | number | Date>[]>();
    const [latestAvgTagCountOnResourceCountScore, setLatestAvgTagCountOnResourceCountScore] = useState<number>();
    const [differenceAvgTagCountOnResourceCountScore, setDifferenceAvgTagCountOnResourceCountScore] = useState<number>();
    const date = new Date();
    const dateToCheck = new Date(date.setMonth(date.getMonth() - 1));

    useEffect(() => {
        (async () => {
            const results = await queryBuilder<TagHealthMetric>()
                .where((m) => m.and(m.model.CompanyId!.eq(company.Id!), m.model.CreatedAt!.onOrAfter(dateToCheck)))
                .execute(postTagHealthSearch);

            if (results.Results != null) {
                const sortedResultsAsc = results.Results.sort((a, b) => new Date(a.CreatedAt).getTime() - new Date(b.CreatedAt).getTime());

                const diffComplianceScore =
                    sortedResultsAsc[0].PolicyComplianceScore - sortedResultsAsc[sortedResultsAsc.length - 1].PolicyComplianceScore;

                setDifferenceComplianceScore(diffComplianceScore);
                setLatestComplianceScore(sortedResultsAsc[sortedResultsAsc.length - 1].PolicyComplianceScore);

                const complianceScoreData: Record<string, string | number | Date>[] = sortedResultsAsc!.map((m) => {
                    const container: LineChartMetric = { date: new Date(m.CreatedAt).toDateString(), score: m.PolicyComplianceScore! };
                    return container;
                });

                setComplianceHistogramData(complianceScoreData);

                const diffTagCountScore =
                    ((sortedResultsAsc[0].NumberOfTags - sortedResultsAsc[sortedResultsAsc.length - 1].NumberOfTags) /
                        sortedResultsAsc[0].NumberOfTags) *
                    100;

                setDifferenceTagCountScore(diffTagCountScore);
                setLatestTagCountScore(sortedResultsAsc[sortedResultsAsc.length - 1].NumberOfTags);

                const tagCountHistogramData: Record<string, string | number | Date>[] = sortedResultsAsc!.map((m) => {
                    const container: LineChartMetric = { date: new Date(m.CreatedAt).toDateString(), score: m.NumberOfTags! };
                    return container;
                });

                setTagCountHistogramData(tagCountHistogramData);

                const diffResourceCountScore =
                    ((sortedResultsAsc[0].TaggableResourceCount - sortedResultsAsc[sortedResultsAsc.length - 1].TaggableResourceCount) /
                        sortedResultsAsc[0].TaggableResourceCount) *
                    100;

                setDifferenceResourceCountScore(diffResourceCountScore);
                setLatestResourceCountScore(sortedResultsAsc[sortedResultsAsc.length - 1].TaggableResourceCount);

                const resourceCountHistogramData: Record<string, string | number | Date>[] = sortedResultsAsc!.map((m) => {
                    const container: LineChartMetric = { date: new Date(m.CreatedAt).toDateString(), score: m.TaggableResourceCount! };
                    return container;
                });

                setResourceCountHistogramData(resourceCountHistogramData);

                const diffAvgTagCountOnResourceCountScore =
                    ((sortedResultsAsc[0].AverageNumberOfTagsPerResource -
                        sortedResultsAsc[sortedResultsAsc.length - 1].AverageNumberOfTagsPerResource) /
                        sortedResultsAsc[0].AverageNumberOfTagsPerResource) *
                    100;

                setLatestAvgTagCountOnResourceCountScore(diffAvgTagCountOnResourceCountScore);
                setDifferenceAvgTagCountOnResourceCountScore(sortedResultsAsc[sortedResultsAsc.length - 1].AverageNumberOfTagsPerResource);

                const avgTagCountOnResourceHistogramData: Record<string, string | number | Date>[] = sortedResultsAsc!.map((m) => {
                    const container: LineChartMetric = { date: new Date(m.CreatedAt).toDateString(), score: m.AverageNumberOfTagsPerResource! };
                    return container;
                });

                setAvgTagCountOnResourceCountHistogramData(avgTagCountOnResourceHistogramData);
            }
        })();
    }, []);

    if (complianceHistogramData && tagCountHistogramData && avgTagCountOnResourceCountHistogramData && resourceCountHistogramData) {
        return (
            <EnvironmentSummaryContainer>
                <IntelligenceKpiChartWithLineGraph
                    kpi={latestResourceCountScore!}
                    label="Taggable Resources"
                    data={resourceCountHistogramData}
                    differencePercentage={differenceResourceCountScore!}
                ></IntelligenceKpiChartWithLineGraph>
                <Divider orientation="vertical"></Divider>
                <IntelligenceKpiChartWithLineGraph
                    kpi={latestTagCountScore!}
                    label="Number of Tags"
                    data={tagCountHistogramData}
                    differencePercentage={differenceTagCountScore!}
                ></IntelligenceKpiChartWithLineGraph>
                <Divider orientation="vertical"></Divider>
                <IntelligenceKpiChartWithLineGraph
                    kpi={latestAvgTagCountOnResourceCountScore!}
                    label="Avg Tags Per Resource"
                    data={avgTagCountOnResourceCountHistogramData}
                    differencePercentage={differenceAvgTagCountOnResourceCountScore!}
                ></IntelligenceKpiChartWithLineGraph>
                <Divider orientation="vertical"></Divider>
                <IntelligenceKpiChartWithLineGraph
                    kpi={latestComplianceScore! / 100}
                    label="Overall Tag Health"
                    format="percent"
                    data={complianceHistogramData}
                    differencePercentage={differenceComplianceScore!}
                ></IntelligenceKpiChartWithLineGraph>
            </EnvironmentSummaryContainer>
        );
    } else {
        return <></>;
    }
}

type LineChartMetric = {
    date: string;
    score: string | number | Date;
};

function IntelligenceKpiChartWithLineGraph(props: {
    differencePercentage: number;
    label: string;
    format?: 'percent' | 'money' | undefined;
    data: Record<string, string | number | Date>[];
    kpi: number;
}) {
    const theme = useMantineTheme();
    const differencePositive = props.differencePercentage > 0;

    return (
        <KpiHistogramContainer>
            <KpiContainer>
                <h5>{props.label}</h5>
                <h2>
                    {props.format === 'percent'
                        ? (props.kpi * 100).toFixed() + '%'
                        : props.format === 'money'
                        ? props.kpi.toFixed(2)
                        : props.kpi
                              .toFixed()
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                </h2>
                <h5>
                    <span
                        style={
                            differencePositive
                                ? { color: theme.colors?.success?.[6] as CustomColors }
                                : { color: theme.colors?.error?.[5] as CustomColors }
                        }
                    >
                        {differencePositive ? <i className="ti ti-arrow-up"></i> : <i className="ti ti-arrow-down"></i>}
                        {new Number(props.differencePercentage).toFixed() + '%'}
                    </span>
                    <br></br>
                    &nbsp; last 30 days
                </h5>
            </KpiContainer>
            <LineContainer>
                <LineChart
                    groups={['date']}
                    values={['score']}
                    data={props.data}
                    settings={{ interval: 'day', direction: differencePositive ? 'up' : 'down', mode: 'trend-curve' }}
                ></LineChart>
            </LineContainer>
            <Clearfix></Clearfix>
        </KpiHistogramContainer>
    );
}
export const KpiContainer = styled.div`
    height: 100%;
    width: 45%;
    float: left;
`;
export const LineContainer = styled.div`
    height: 100%;
    width: 50%;
    float: left;
    margin-left: -50px;
`;
export const KpiHistogramContainer = styled.div`
    width: 25%;
    height: 100%;
    float: left;
    display: block;
    position: relative;
`;

export const EnvironmentSummaryContainer = styled.div`
    width: 100%;
    height: 100%;
`;
