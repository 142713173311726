import { NotificationTypePreference, UserDefinedNotification, UserDefinedNotificationConfig } from '@apis/Notification/model';
import { ActionIcon, Button, Center, Group, Loader, Text } from '@mantine/core';
import { FillerSwitch } from '@root/Design/Filler';
import { SettingsWithPreviewContainer } from '@root/Design/Settings';
import { SidePanelContainer } from '@root/Design/SidePanel';
import { AuthenticationService } from '@root/Services/AuthenticationService';
import { useDiMemo } from '@root/Services/DI';
import { EventEmitter, useEventValue } from '@root/Services/EventEmitter';
import { Logger } from '@root/Services/Logger';
import { NotificationService } from '@root/Services/Notification/NotificationService';
import { UserDefinedNotificationApiService } from '@root/Services/Notification/UserDefinedNotificationApiService';
import { useCallback, useEffect } from 'react';
import { injectable, inject } from 'tsyringe';
import { LiveNotificationContent } from '../Viewer/NotificationLiveContent';
import { NotificationPresetService } from './NotificationDatasourcePresets';
import { NotificationSettingsEditorScope } from './Models';
import { NotificationSettingsEditor } from './NotificationSettingsEditor';
import { INotificationPresetItem } from './Presets/BasePresets';

@injectable()
class NotificationDefinitionSettingsLoaderModel {
    private onSaved?: () => void;

    public readonly configChanged = new EventEmitter<UserDefinedNotificationConfig | undefined>(undefined);
    public readonly presets: INotificationPresetItem<any>[] = [];
    public typePref?: NotificationTypePreference;
    public readonly notification = new EventEmitter<UserDefinedNotification | undefined>(undefined);
    public readonly saving = new EventEmitter<boolean>(false);
    public readonly loading = new EventEmitter<boolean>(true);

    public constructor(
        @inject(UserDefinedNotificationApiService) private readonly notificationApi: UserDefinedNotificationApiService,
        @inject(NotificationPresetService) private readonly notificationPresetSvc: NotificationPresetService,
        @inject(NotificationService) private readonly toastSvc: NotificationService,
        @inject(Logger) private readonly logger: Logger,
        @inject(AuthenticationService) private readonly authSvc: AuthenticationService
    ) {}

    public init(typePrefId: number | undefined, scope: NotificationSettingsEditorScope, onSaved?: () => void) {
        this.load(typePrefId, scope);
        this.onSaved = onSaved;
    }

    private async load(typePrefId: number | undefined, scope: NotificationSettingsEditorScope) {
        this.loading.emit(true);
        try {
            const typePref = typePrefId ? await this.notificationApi.getNotificationDefinitionById(typePrefId) : null;
            this.typePref = typePref ?? this.createTypePref(scope.config);
            const { datasource, context } = scope;
            this.presets.splice(0, Infinity, ...(await this.notificationPresetSvc.getPresets(datasource, context)));
        } finally {
            this.loading.emit(false);
        }
    }

    public handleConfigChange = (config: UserDefinedNotificationConfig | undefined) => {
        this.configChanged.emit(config);
        const notification: UserDefinedNotification | undefined = this.typePref
            ? {
                  NotificationTypePreferenceId: this.typePref.Id ?? 0,
                  NotificationDefinition: config,
              }
            : undefined;
        this.notification.emit(notification);
    };

    public save = async () => {
        if (!this.typePref) {
            return;
        }

        const { NotificationDefinition } = this.typePref;
        if (!NotificationDefinition) {
            return;
        }

        try {
            this.saving.emit(true);
            const recipients = this.typePref.Recipients ?? [];
            const def = await this.notificationApi.saveNotificationDefinition(this.typePref);
            if (def.Id && recipients.length) {
                await this.notificationApi.saveNotificationRecipients(def.Id, recipients);
            }

            this.toastSvc.notify('Saved', `Notification settings saved.`, 'success', null);
            this.onSaved?.();
        } catch (error) {
            this.logger.error('Failed to save notification definition', { error });
            this.toastSvc.notify('Save Failed', `Error: Failed to save notification settings. Please contact support.`, 'error', null);
        } finally {
            this.saving.emit(false);
        }
    };

    public isNew() {
        return !this.typePref?.Id;
    }

    private createTypePref(definition?: UserDefinedNotificationConfig): NotificationTypePreference {
        return {
            NotificationType: 'UserDefined',
            UserId: this.authSvc.user?.Id,
            Recipients: [{ UserId: this.authSvc.user?.Id, MessageType: ['InApp', 'Email'], NotificationTypePreferenceId: 0, Type: 'SingleInclude' }],
            NotificationDefinition: definition,
        };
    }
}

interface NotificationDefinitionSettingsEditorProps {
    typePrefId?: number;
    onFinish: (changed: boolean) => void;
    scope: NotificationSettingsEditorScope;
    settingsWidth: number;
}
export function NotificationDefinitionSettingsLoader(props: NotificationDefinitionSettingsEditorProps) {
    const { typePrefId, onFinish, scope, settingsWidth } = props;
    const model = useDiMemo(NotificationDefinitionSettingsLoaderModel);
    useEffect(() => {
        model.init(typePrefId, scope, () => onFinish(true));
    }, [model, typePrefId, scope]);
    const loading = useEventValue(model.loading);

    const handleCancel = useCallback(() => onFinish(false), [onFinish]);

    const DynamicNotificationViewer = useCallback(() => {
        const config = useEventValue(model.configChanged);
        return !config ? (
            <Center>
                <Text italic color="dimmed">
                    Preview unavailable
                </Text>
            </Center>
        ) : (
            <LiveNotificationContent config={config} context={scope.context} />
        );
    }, [model.configChanged]);

    const saving = useEventValue(model.saving);

    return (
        <SettingsWithPreviewContainer
            title="Preview"
            preview={
                <FillerSwitch loading={loading} noDataMessage="Preview unavailable">
                    {() => <DynamicNotificationViewer />}
                </FillerSwitch>
            }
            previewTransparent={false}
            previewScrollable
            settings={
                <SidePanelContainer
                    title="Notification Settings"
                    toolbar={
                        <>
                            <Button disabled={saving} onClick={model.save} leftIcon={!saving ? null : <Loader color="gray.0" size="sm" />}>
                                {model.isNew() ? 'Save Notification' : 'Update Settings'}
                            </Button>
                            <Button onClick={handleCancel} variant="outline">
                                Cancel
                            </Button>
                        </>
                    }
                >
                    <FillerSwitch loading={loading} noData={!model.typePref}>
                        {() => (
                            <NotificationSettingsEditor
                                onChange={model.handleConfigChange}
                                typePref={model.typePref!}
                                presets={model.presets}
                                scope={scope}
                            />
                        )}
                    </FillerSwitch>
                </SidePanelContainer>
            }
            settingsWidth={settingsWidth}
            previewType="stack"
        />
    );
}
