import { CostForecastQueryDatasourceFactory } from '@root/Services/Invoices/CostForecastService';
import { QueryDatasource, QueryDatasourceMetadata } from '@root/Services/Query/QueryDatasource';
import { SchemaService } from '@root/Services/QueryExpr';
import { SunHigh } from 'tabler-icons-react';
import { inject, injectable } from 'tsyringe';
import { BaseDatasourcePresetsBuilder, IDatasourcePresetsBuilderFactory, INotificationPresetItem } from './BasePresets';

class CostForecastDatasourcePresetsBuilder extends BaseDatasourcePresetsBuilder {
    public constructor(private readonly queryDatasource: QueryDatasource, private readonly schemaSvc: SchemaService) {
        super(queryDatasource.name);
    }

    public getPresets() {
        return this.createDatasourcePresets(
            'Cost Forecast',
            <SunHigh />,
            [this.createCustomForecastAlertPreset()],
            [this.createCustomForecastReportPreset()]
        );
    }

    // #region Alerts
    private createCustomForecastAlertPreset(): INotificationPresetItem<any> {
        const dimExpr = this.queryDatasource.getDefaultGroup();
        const metricExpr = this.queryDatasource.getDefaultValue();

        const filterInputs = this.createFilterSettings(this.queryDatasource, this.schemaSvc);

        const behavior = this.createBehavior(({ data }) => {
            return this.mergeConfig(
                {
                    ComponentConfig: this.createCustomChartConfig('bar', dimExpr, metricExpr),
                    QueryOptions: {
                        Parameters: {},
                        Filters: [],
                        DatasourceName: this.queryDatasource.name,
                    },
                    Trigger: {
                        Threshold: {},
                    },
                },
                data
            );
        })
            .addForm(({ data }) => {
                const filterSection = this.createSectionItem('Data Filters', filterInputs(data), { type: 'divider' });

                return [
                    filterSection,
                    this.createSection(
                        'Alert Settings',
                        this.createSectionItem('Title and Description', ...this.createDescriptionInputs(data, 'Untitled')),
                        this.createThresholdForm(data.Trigger?.Threshold!)
                    ),
                ];
            })
            .addValidation(() => []);

        return this.createCustomAlertPreset(behavior);
    }
    // #endregion Alerts

    // #region Reports
    private createCustomForecastReportPreset(): INotificationPresetItem<any> {
        const dimExpr = this.queryDatasource.getDefaultGroup();
        const metricExpr = this.queryDatasource.getDefaultValue();

        const filterInputs = this.createFilterSettings(this.queryDatasource, this.schemaSvc);
        const chartInput = this.createCustomChartBehavior(this.queryDatasource, this.schemaSvc);

        const behavior = this.createBehavior(({ data }) => {
            return this.mergeConfig(
                {
                    ComponentConfig: this.createCustomChartConfig('bar', dimExpr, metricExpr),
                    QueryOptions: {
                        Parameters: {},
                        Filters: [],
                        DatasourceName: this.queryDatasource.name,
                    },
                },
                data
            );
        })
            .addForm(({ data }) => {
                const chartSettings = chartInput(data);
                const filterSection = this.createSectionItem('Data Filters', filterInputs(data), { type: 'divider' });
                chartSettings.elements.push(filterSection);

                return [
                    chartSettings,
                    this.createSection(
                        'Report Settings',
                        this.createSectionItem('Title and Description', ...this.createDescriptionInputs(data, 'Untitled')),
                        this.createScheduleForm(data.Trigger?.Schedule!)
                    ),
                ];
            })
            .addValidation(() => []);

        return this.createCustomReportPreset(behavior);
    }
    // #endregion Reports
}
@injectable()
export class CostForecastDatasourcePresetsBuilderFactory implements IDatasourcePresetsBuilderFactory<{}> {
    public constructor(@inject(CostForecastQueryDatasourceFactory) private readonly fcDatasourceFactory: CostForecastQueryDatasourceFactory) {}

    public getSupportedDatasources(): QueryDatasourceMetadata[] {
        return [this.fcDatasourceFactory.getMetadata()];
    }

    public async get(datasourceName: string, context: {}) {
        const queryDatasource = await this.fcDatasourceFactory.getDatasource();
        const types = await queryDatasource.schema.getSchema();
        const schemaSvc = SchemaService.create(types);
        return new CostForecastDatasourcePresetsBuilder(queryDatasource, schemaSvc);
    }
}
