import { TextInput } from '@mantine/core';
import { DetailedLineChartSettings } from '@root/Components/Charts/DetailedLineChart';
import { AnchorButton } from '@root/Design/Primitives';
import {
    SettingsInputRow,
    SettingsLabel,
    SettingsSection,
    SettingsSectionBodyDivider,
    SettingsSectionItem,
    SettingsSectionItemBody,
    SettingsSectionItemHeader,
    SettingsSectionItemHeaderLabel,
} from '@root/Design/Settings';
import { useEvent } from '@root/Services/EventEmitter';
import { NamedFormats } from '@root/Services/FormatService';
import { observer } from 'mobx-react';
import { useMemo } from 'react';
import { ArrowFork, Calendar, ChartAreaLine, Timeline, Plus, CursorText, VectorSpline, Paint } from 'tabler-icons-react';
import {
    DropdownSettings,
    EditorExprPicker,
    FormatSettings,
    ChartReaggOptions,
    SegmentedControlSettings,
    useRawEditorGroupExpr,
    useRawEditorValueExpr,
    useGetterSetter,
    useSelectExprAlias,
    castSetter,
    useDescriptorUpdates,
    EditorFilterAccordion,
} from './Components';
import { IChartEditor } from './Models';

export const LineSettings = observer(function LineSettings({ editor }: { editor: IChartEditor }) {
    useEvent(editor.onSettingsChanged);

    const settings = useMemo(
        () =>
            editor.getChartSettings<DetailedLineChartSettings>({
                labels: { x: '', y: '', group: '' },
                xFormat: 'short-date-with-dow',
                mulitplotOptions: { limit: 10, sortBy: 'value', sortDir: 'desc', otherLabel: 'Other' },
                area: 'line',
                curve: 'linear',
            }),
        []
    );

    const { dateFld, setDateFld } = useRawEditorGroupExpr(editor, 'dateFld', 0);
    const { splitLineFld, setSplitLineFld: setSplit, removeSplitLineFld } = useRawEditorGroupExpr(editor, 'splitLineFld', 1);
    const setSplitLineFld = useSelectExprAlias('split', setSplit);
    const { metric, setMetric } = useRawEditorValueExpr(editor, 'metric', 0);
    const settingAccessors = useGetterSetter(settings, 'labels', 'xFormat', 'stacked', 'curve', 'area', 'descriptors');
    const { labels, xFormat, setXFormat, stacked, setStacked, curve, setCurve, area, setArea, setDescriptors } = settingAccessors;
    const { y: yLabel, setY: setYLabel } = useGetterSetter(labels!, 'y');
    useDescriptorUpdates(setDescriptors, dateFld, metric, splitLineFld);

    return (
        <>
            <SettingsSection title="Line Options">
                <SettingsSectionItem>
                    <SettingsSectionItemHeader>
                        <SettingsSectionItemHeaderLabel>X-Axis</SettingsSectionItemHeaderLabel>
                    </SettingsSectionItemHeader>
                    <SettingsSectionItemBody>
                        <SettingsInputRow>
                            <SettingsLabel icon={<Calendar />}>Date</SettingsLabel>
                            <EditorExprPicker operations={[]} editor={editor} expr={dateFld} types={['date']} onChange={setDateFld} />
                        </SettingsInputRow>
                        <FormatSettings onChange={(v) => setXFormat(v as NamedFormats)} type="date" value={xFormat} />
                    </SettingsSectionItemBody>
                </SettingsSectionItem>
                <SettingsSectionItem>
                    <SettingsSectionItemHeader>
                        <SettingsSectionItemHeaderLabel>Y-Axis</SettingsSectionItemHeaderLabel>
                    </SettingsSectionItemHeader>
                    <SettingsSectionItemBody>
                        <SettingsInputRow>
                            <SettingsLabel icon={<CursorText />}>Label</SettingsLabel>
                            <TextInput size="xs" value={yLabel} onChange={(e) => setYLabel(e.currentTarget.value)} placeholder="None" />
                        </SettingsInputRow>
                        <SettingsInputRow>
                            <SettingsLabel icon={<Timeline />}>Metric</SettingsLabel>
                            <EditorExprPicker
                                editor={editor}
                                onChange={setMetric}
                                types={['number']}
                                operations={editor.numericOps}
                                expr={metric}
                                name={yLabel}
                            />
                        </SettingsInputRow>
                        <FormatSettings onChange={(f) => (settings.format = f as NamedFormats)} type="number" value={settings.format} />

                        <SettingsSectionBodyDivider />
                        {splitLineFld ? (
                            <>
                                <SettingsInputRow>
                                    <SettingsLabel icon={<ArrowFork />}>Split by</SettingsLabel>
                                    <EditorExprPicker
                                        editor={editor}
                                        onChange={setSplitLineFld}
                                        operations={[]}
                                        types={['string']}
                                        expr={splitLineFld}
                                        onRemove={removeSplitLineFld}
                                        removeLabel="Remove line splitting"
                                    />
                                </SettingsInputRow>
                                <ChartReaggOptions limitLbl="Line" settings={settings} reaggProp="mulitplotOptions" />

                                <SettingsInputRow>
                                    <SettingsLabel icon={<ChartAreaLine />}>Stacking</SettingsLabel>
                                    <SegmentedControlSettings
                                        value={stacked ? 'stacked' : 'normal'}
                                        options={[
                                            { value: 'stacked', label: 'Stack' },
                                            { value: 'normal', label: 'Overlap' },
                                        ]}
                                        onChange={(value) => setStacked(value === 'stacked')}
                                    />
                                </SettingsInputRow>
                            </>
                        ) : (
                            <EditorExprPicker
                                editor={editor}
                                onChange={setSplitLineFld}
                                operations={[]}
                                types={['string']}
                                opener={(open) => (
                                    <SettingsSectionItem style={{ flex: 1 }} anchor onClick={open}>
                                        <AnchorButton icon={<Plus size={16} />} size="sm" text="Add line splitting" onClick={() => {}} />
                                    </SettingsSectionItem>
                                )}
                            />
                        )}
                    </SettingsSectionItemBody>
                </SettingsSectionItem>
            </SettingsSection>

            <SettingsSection title="Appearance Options">
                <SettingsSectionItem>
                    <SettingsSectionItemHeader>
                        <SettingsSectionItemHeaderLabel>Line Style</SettingsSectionItemHeaderLabel>
                    </SettingsSectionItemHeader>
                    <SettingsSectionItemBody>
                        <DropdownSettings
                            label="Line type"
                            options={[
                                { value: 'linear', label: 'Linear' },
                                { value: 'step', label: 'Step' },
                                { value: 'monotoneX', label: 'Curved' },
                            ]}
                            center
                            value={curve ?? 'linear'}
                            onChange={castSetter(setCurve)}
                            icon={<VectorSpline />}
                        />
                        <DropdownSettings
                            icon={<Paint />}
                            center
                            label="Area mode"
                            options={[
                                { value: 'line', label: 'Line Only' },
                                { value: 'area', label: 'Area Only' },
                                { value: 'both', label: 'Line & Area' },
                            ]}
                            onChange={castSetter(setArea)}
                            value={area}
                        />
                    </SettingsSectionItemBody>
                </SettingsSectionItem>
            </SettingsSection>
            <EditorFilterAccordion editor={editor} />
        </>
    );
});
