import { Box, Button, Divider, Group, Popover, ScrollArea, Stack, Text, useMantineTheme } from '@mantine/core';
import { createContext, CSSProperties, Fragment, ReactNode, useCallback, useContext, useMemo, useState } from 'react';
import { useDi } from '@root/Services/DI';
import { FilterItem, FilterToken } from './Design';
import { FormatService } from '@root/Services/FormatService';
import { DayModifiers, RangeCalendar } from '@mantine/dates';
import { useDisclosure } from '@mantine/hooks';
import { injectable } from 'tsyringe';
import { EventEmitter, useEvent } from '@root/Services/EventEmitter';
import { Picker } from '../Picker/Picker';
import { ChevronDown } from 'tabler-icons-react';
import { QueryExpr, QueryField, QueryOperation } from '@apis/Resources';
import { addDays, addMonths, eachMonthOfInterval, endOfMonth, endOfQuarter, format, startOfMonth, startOfQuarter } from 'date-fns';
import { IQueryExpr } from '@apis/Customers/model';

export function DateRangeFilter({
    value,
    onChange,
    constraint,
    options,
    disabled,
    model,
    mode,
    listItems,
    handleItemChanged,
    corners,
    height,
    emptyLabel,
    onRenderCalendar,
}: DateRangeFilterProps) {
    const [opened, { open, toggle, close }] = useDisclosure(false);
    const formatSvc = useDi(FormatService);
    const [showCalendar, setShowCalendar] = useState<boolean>(false);

    useEvent(model?.toggleDialog, () => {
        toggle();
    });
    useEvent(model?.showCalendar, (value) => {
        setShowCalendar(value);

        // Close the dialog if we are hiding the calendar
        if (value === false) {
            close();
        }
    });
    const handleChange = useCallback(
        ([from, to]: [Date, Date]) => {
            onChange({ from, to });
        },
        [onChange]
    );
    const optionLookup = useMemo(
        () => options?.reduce((result, item) => result.set(item.id, item), new Map<string, LabeledRangeOption>()),
        [options]
    );
    const calendarValue = useMemo(() => [value.from || null, value.to || null] as [Date | null, Date | null], [value.from, value.to]);
    const selectedLabel = value.id ? optionLookup?.get(value.id) : null;
    const selectOption = useCallback(
        (option: { id: string }) => {
            onChange(option);
            close();
        },
        [close]
    );
    const handlePickerChange = (items: string[]) => {
        if (handleItemChanged) {
            handleItemChanged(items);
        }
    };
    return (
        <Popover opened={opened} withinPortal shadow="md" onClose={close}>
            <Popover.Target>
                <FilterItem
                    style={{ height }}
                    state="valid"
                    corners={corners}
                    onClick={() => {
                        if (!disabled) {
                            toggle();
                            setShowCalendar(false);
                        }
                    }}
                    data-atid="GlobalDateRangeFilter"
                >
                    <FilterToken maxWidth={30}>
                        <i className="ti ti-calendar" />
                    </FilterToken>
                    {selectedLabel ? (
                        <FilterToken data-atid={'FilterToken:' + selectedLabel.label}>{selectedLabel.label}</FilterToken>
                    ) : emptyLabel && !value.from && !value.to ? (
                        <FilterToken>{emptyLabel}</FilterToken>
                    ) : (
                        <>
                            <FilterToken maxWidth={200} data-atid="GlobalDateRangeFilterFrom">
                                {value.from ? formatSvc.toShortDate(value.from) : 'All prior'}
                            </FilterToken>
                            <FilterToken>&mdash;</FilterToken>
                            <FilterToken maxWidth={200} data-atid="GlobalDateRangeFilterTo">
                                {value.to ? formatSvc.toShortDate(value.to) : 'All later'}
                            </FilterToken>
                        </>
                    )}
                    {!disabled && (
                        <FilterToken
                            style={{
                                transition: 'all 400ms',
                                transformOrigin: 'center',
                                transform: 'translate(0, 3px)',
                                rotate: opened ? '180deg' : '0deg',
                            }}
                        >
                            <ChevronDown size={16} />
                        </FilterToken>
                    )}
                </FilterItem>
            </Popover.Target>
            <Popover.Dropdown>
                <Box>
                    {mode !== 'combo' || showCalendar ? (
                        <>
                            {options?.map((o) => (
                                <Button radius="xl" size="xs" fullWidth variant="outline" onClick={() => selectOption(o)} my="sm">
                                    {o.label}
                                </Button>
                            ))}
                            <DateRangeCalendar
                                constraint={constraint}
                                onChange={handleChange}
                                value={calendarValue}
                                onRenderCalendar={onRenderCalendar}
                            />
                        </>
                    ) : (
                        <Picker
                            mode="single"
                            width="250px"
                            height={300}
                            minimizeHeight
                            // default type is an object with children. This is just a noop override
                            childAccessor={() => undefined}
                            items={listItems ?? []}
                            selections={[]}
                            nameAccessor={(n) => n}
                            renderItem={(item) => <Text size="sm">{item}</Text>}
                            noFilter={true}
                            onChange={handlePickerChange}
                        />
                    )}
                </Box>
            </Popover.Dropdown>
        </Popover>
    );
}
export interface DateRangeFilterProps {
    value: { from?: Date; to?: Date; id?: string };
    onChange: (value: { from?: Date; to?: Date; id?: string }) => void;
    options?: LabeledRangeOption[];
    constraint: { min?: Date; max?: Date; unenforced?: boolean };
    disabled?: boolean;
    model?: DateRangeFilterModel;
    mode?: 'combo' | 'calendar';
    listItems?: string[];
    handleItemChanged?: (item: string[]) => void;
    corners?: number;
    height?: number;
    emptyLabel?: string;
    onRenderCalendar?: (calendar: ReactNode, close: () => void) => ReactNode;
}
interface LabeledRangeOption {
    id: string;
    label: string;
}

@injectable()
export class DateRangeFilterModel {
    public toggleDialog = EventEmitter.empty();
    public showCalendar = new EventEmitter<boolean>(false);
}

interface LabeledDateRangePreset {
    label: string;
}
interface DateRangeConcretePreset extends LabeledDateRangePreset {
    type: 'concrete';
    from: Date;
    to: Date;
    dateKey: string;
}
interface DateRangeRelativePreset extends LabeledDateRangePreset {
    type: 'relative';
    start?: QueryExpr;
}
interface DateRangePresetHeader extends LabeledDateRangePreset {
    type: 'header';
}
interface DateRangePresetDivider {
    type: 'divider';
}
export type DateRangePreset = DateRangeConcretePreset | DateRangeRelativePreset | DateRangePresetHeader | DateRangePresetDivider;

interface IRelativeDateRangeCalendarProps extends IBaseDateRangeCalendarProps {
    value: NullablePair<Date> | NullablePair<QueryExpr>;
    onChange: (value: Pair<Date> | Pair<QueryExpr>) => void;
}
interface IConcreteDateRangeCalendarProps extends IBaseDateRangeCalendarProps {
    value: NullablePair<Date>;
    onChange: (value: Pair<Date>) => void;
}
type IDateRangeCalendarProps = IRelativeDateRangeCalendarProps | IConcreteDateRangeCalendarProps;
type Pair<T> = [T, T];
type NullablePair<T> = [T | undefined | null, T | undefined | null];
interface IBaseDateRangeCalendarProps {
    onRenderCalendar?: (calendar: ReactNode, close: () => void) => ReactNode;
    constraint?: { min?: Date; max?: Date; unenforced?: boolean };
    presets?: DateRangePreset[];
}
export function DateRangeCalendar(props: IConcreteDateRangeCalendarProps): JSX.Element;
export function DateRangeCalendar(props: IRelativeDateRangeCalendarProps): JSX.Element;
export function DateRangeCalendar(props: IDateRangeCalendarProps) {
    const { value, onChange, onRenderCalendar, constraint } = props;
    const theme = useMantineTheme();
    const calendarRenderer = onRenderCalendar ?? ((calendar, _) => calendar);

    const constraintDayStyle = useCallback(
        (date: Date, modifiers: DayModifiers): CSSProperties => {
            if (constraint?.unenforced) {
                const { firstInRange, lastInRange, selected, inRange } = modifiers;
                const rangeEdge = firstInRange || lastInRange || selected;
                if ((constraint.min && date < constraint.min) || (constraint.max && date > constraint.max)) {
                    return {
                        background: rangeEdge ? theme.colors.gray[5] : inRange ? theme.colors.gray[2] : undefined,
                        color: rangeEdge ? theme.colors.gray[1] : theme.colors.gray[6],
                    };
                }
            }
            return {};
        },
        [JSON.stringify(constraint)]
    );
    const calendarValue = [value[0] ?? null, value[1] ?? null] as [Date | null, Date | null];

    return (
        <>
            {calendarRenderer(
                <RangeCalendar
                    onChange={onChange}
                    firstDayOfWeek="sunday"
                    value={calendarValue}
                    minDate={constraint?.unenforced ? undefined : constraint?.min}
                    maxDate={constraint?.unenforced ? undefined : constraint?.max}
                    dayStyle={constraintDayStyle}
                    id="date-range-filter"
                />,
                close
            )}
        </>
    );
}

interface DateRangeContextInfo {
    constraint?: { min?: Date; max?: Date; unenforced?: boolean };
    contextualRange?: { from?: QueryExpr; to?: QueryExpr };
    presets?: DateRangePreset[];
}
const DateRangeContext = createContext<DateRangeContextInfo | undefined>(undefined);
function useDateRangeContext() {
    return useContext(DateRangeContext);
}

export function DateRangeContextProvider(props: { children: ReactNode; value: undefined | DateRangeContextInfo }) {
    return <DateRangeContext.Provider value={props.value}>{props.children}</DateRangeContext.Provider>;
}

// #region Presets and Relative Dates
type DateRangePresetCalendarProps = Omit<IRelativeDateRangeCalendarProps, 'onRenderCalendar'> & {
    presets?: DateRangePreset[];
    contextualRange?: { from?: QueryExpr; to?: QueryExpr };
};
export function DateRangePresetCalendar(props: DateRangePresetCalendarProps) {
    const { onChange, value, presets, ...rest } = props;
    const handlePresetClick = useCallback(
        (preset: DateRangeRelativePreset | DateRangeConcretePreset) => {
            if (preset.type === 'concrete') {
                onChange([preset.from, preset.to]);
            } else {
            }
        },
        [onChange]
    );
    const handleRangeChange = useCallback(
        (range: Pair<Date> | Pair<QueryExpr>) => {
            onChange(range);
        },
        [onChange]
    );
    return (
        <>
            <Group sx={{ height: 325 }} noWrap align="baseline">
                {!presets?.length ? null : <DateRangePresetList presets={presets} onClick={handlePresetClick} />}
                <DateRangeCalendar {...rest} onChange={handleRangeChange} value={value} />
            </Group>
        </>
    );
}

// #region Conversion
const dateValueMatcher = /^\d{8}$/;
function toCompactDateValueKey(value: Date) {
    return FormatService.instance.to8DigitDate(value);
}
function fromCompactDateValueKey(value: string) {
    return FormatService.instance.from8DigitDate(value);
}
export const dateMathUnits = [
    { label: 'Days', unit: 'day', short: 'd' },
    { label: 'Months', unit: 'month', short: 'm' },
    { label: 'Years', unit: 'year', short: 'y' },
];
function toCompactDateOpKey(value: QueryExpr | IQueryExpr) {
    if ('Operation' in value) {
        const operation = (value.Operation as string).toLowerCase();
        if (operation === 'currentdate') {
            return `currentdate`;
        } else if (operation === 'adddate') {
            const opnKeys = value.Operands.map(toCompactDateOpKey);
            const [field, amt, unit] = opnKeys;
            const neg = amt.Value < 0 ? 'n' : '';
            const shortUnit = dateMathUnits.find((u) => u.unit === unit)?.short ?? 'd';
            return `${field}|${neg}${amt}${shortUnit}`;
        }
    } else if ('Field' in value) {
        return value.Field as string;
    } else if ('Value' in value) {
        return value.Value as string;
    } else if (value instanceof Date) {
        return toCompactDateValueKey(value);
    }
    return '';
}
const relativeDateMatcher = /^([a-zA-Z][a-zA-Z0-9]+)\|([n]?)(\d+)([dmy])$/;
function fromCompactDateOpKey(value: string): QueryExpr | undefined {
    if (value === 'currentdate') {
        return { Operation: 'CurrentDate', Operands: [] };
    } else {
        const match = value.match(relativeDateMatcher);
        if (match) {
            const [_, field, neg, rawAmt, rawUnit] = match;
            const subjectExpr = field === 'currentdate' ? { Operation: 'CurrentDate', Operands: [] } : { Field: field };
            const parseAmt = parseInt(rawAmt);
            const negModifier = neg ? -1 : 1;
            const amtExpr = { Value: isNaN(parseAmt) ? 0 : negModifier * parseAmt };
            const unitExpr = { Value: dateMathUnits.find((u) => u.short === rawUnit)?.unit ?? 'day' };
            return { Operation: 'AddDate', Operands: [subjectExpr, amtExpr, unitExpr] };
        }
    }
    return undefined;
}

function getRangeKeyType(range: string | Pair<string>) {
    const [from, to] = Array.isArray(range) ? range : range.split('-');
    const hasRel = relativeDateMatcher.test(from) && relativeDateMatcher.test(to);
    const hasCon = dateValueMatcher.test(from) && dateValueMatcher.test(to);
    return hasRel ? 'relative' : hasCon ? 'concrete' : ('none' as const);
}
function toCompactRangeKey(range: NullablePair<Date> | NullablePair<QueryExpr>) {
    const [from, to] = range;
    if (from && to) {
        const hasRel = ['Operation', 'Field', 'Value'].every((key) => key in from) && ['Operation', 'Field', 'Value'].every((key) => key in to);
        if (hasRel) {
            return [toCompactDateOpKey(from), toCompactDateOpKey(to)].join('-');
        } else if (from instanceof Date && to instanceof Date) {
            return `${toCompactDateValueKey(from)}-${toCompactDateValueKey(to)}`;
        }
    }
    return '';
}

function fromCompactRangeKey(range: string): NullablePair<QueryExpr> | NullablePair<Date> {
    const [from, to] = range.split('-');
    const type = getRangeKeyType([from, to]);

    if (type === 'relative') {
        return [fromCompactDateOpKey(from), fromCompactDateOpKey(to)] as Pair<QueryExpr>;
    } else if (type === 'concrete') {
        return [fromCompactDateValueKey(from), fromCompactDateValueKey(to)] as Pair<Date>;
    }

    return [undefined, undefined];
}
// #endregion

function getConstraintInfo(constraint: { min?: Date; max?: Date }) {
    const { min, max } = constraint;
    const toDateKey = (value: Date | QueryExpr) => (value instanceof Date ? toCompactDateValueKey(value) : toCompactDateOpKey(value));
    const fromDateKey = (value: string) => (relativeDateMatcher.test(value) ? fromCompactDateOpKey(value) : fromCompactDateValueKey(value));
    const toRangeKey = toCompactRangeKey;
    const fromRangeKey = fromCompactRangeKey;
    const validMonths = !min || !max ? [] : eachMonthOfInterval({ start: min, end: max });
    const validMonthKeys = new Set<string>(validMonths.map(toDateKey));
    return { validMonths, validMonthKeys, toDateKey, fromDateKey, toRangeKey, fromRangeKey };
}

interface DateRangePresetListProps {
    presets: DateRangePreset[];
    onClick: (preset: DateRangeConcretePreset | DateRangeRelativePreset) => void;
    selectedRange?: NullablePair<Date> | NullablePair<QueryExpr>;
}
function DateRangePresetList(props: DateRangePresetListProps) {
    const { presets, onClick, selectedRange } = props;

    const selectedRangeType = !selectedRange ? 'none' : getRangeKeyType(`${selectedRange[0]}-${selectedRange[1]}`);
    const selectedRangeKey = !selectedRange || selectedRangeType === 'none' ? null : toCompactRangeKey(selectedRange);
    const isSelected = (preset: DateRangeConcretePreset | DateRangeRelativePreset) => {
        return (
            (preset.type === 'concrete' && preset.dateKey === selectedRangeKey) || (preset.type === 'relative' && selectedRangeType === 'relative')
        );
    };

    return (
        <Box sx={{ width: 200, height: '100%', marginRight: -15 }}>
            <ScrollArea sx={{ height: '100%' }}>
                <Stack spacing="xs" sx={{ marginRight: 15 }}>
                    {presets.map((item, idx) =>
                        item.type === 'header' ? (
                            <Fragment key={idx}>
                                <Text size="xs" color="dimmed" weight="bold">
                                    {item.label}
                                </Text>
                            </Fragment>
                        ) : item.type === 'divider' ? (
                            <Divider />
                        ) : (
                            <Button size="sm" variant={isSelected(item) ? 'outline' : 'white'} key={idx} onClick={() => onClick(item)}>
                                {item.label}
                            </Button>
                        )
                    )}
                </Stack>
            </ScrollArea>
        </Box>
    );
}

export function createBusinessPeriodConstraintPresets(constraint: { min?: Date; max?: Date }) {
    const { validMonthKeys, toDateKey } = getConstraintInfo(constraint);

    const result: Omit<DateRangeConcretePreset, 'dateKey'>[] = [
        { type: 'concrete', label: 'Current Month', from: startOfMonth(new Date()), to: endOfMonth(new Date()) },
    ];

    const lastMo = startOfMonth(addMonths(new Date(), -1));
    if (validMonthKeys.has(toDateKey(lastMo))) {
        result.push({ type: 'concrete', label: 'Last 30 Days', from: addDays(new Date(), -30), to: new Date() });
        result.push({ type: 'concrete', label: 'Last Month', from: lastMo, to: endOfMonth(lastMo) });
    }

    const quarterStart = startOfQuarter(new Date());
    if (validMonthKeys.has(toDateKey(quarterStart))) {
        result.push({ type: 'concrete', label: 'Current Quarter', from: quarterStart, to: endOfQuarter(quarterStart) });
    }

    const prevQuarterStart = startOfQuarter(addMonths(new Date(), -3));
    if (validMonthKeys.has(toDateKey(prevQuarterStart))) {
        result.push({ type: 'concrete', label: 'Last Quarter', from: prevQuarterStart, to: endOfQuarter(prevQuarterStart) });
    }

    return result.map((item) => ({ ...item, dateKey: `${toDateKey(item.from)}-${toDateKey(item.to)}` }));
}

export function createMonthlyConstraintPresets(constraint: { min?: Date; max?: Date }, reverse: boolean = true) {
    const { validMonths, toDateKey } = getConstraintInfo(constraint);

    const result: Omit<DateRangeConcretePreset, 'dateKey'>[] = [
        { type: 'concrete', label: 'Current Month', from: startOfMonth(new Date()), to: endOfMonth(new Date()) },
    ];

    const months = reverse ? validMonths.reverse() : validMonths;
    for (const month of months.reverse()) {
        result.push({ type: 'concrete', label: format(month, 'MMMM yyyy'), from: month, to: endOfMonth(month) });
    }

    return result.map((item) => ({ ...item, dateKey: `${toDateKey(item.from)}-${toDateKey(item.to)}` }));
}

export function createRelativeConstraintPresets(range?: { from?: QueryExpr; to?: QueryExpr }) {
    const result: Omit<DateRangeRelativePreset, 'dateKey'>[] = [];

    if (range?.from) {
        result.push({ type: 'relative', label: 'Relative', start: range.from });
    }
    if (range?.to) {
        result.push({ type: 'relative', label: 'Relative', start: range.from });
    }
    result.push({ type: 'relative', label: 'Relative' });

    return result;
}

export function createPresetGroups(...presetGroupsAndHeaders: Array<(DateRangeConcretePreset | DateRangeRelativePreset)[] | string>) {
    const result: DateRangePreset[] = [];

    for (const group of presetGroupsAndHeaders) {
        if (typeof group === 'string') {
            if (result.length) {
                result.push({ type: 'divider' });
            }
            result.push({ type: 'header', label: group });
        } else {
            result.push(...group);
        }
    }

    return result;
}
// #endregion
