import { getDashboardGetByKey, postDashboardSaveMyPreferences, getDashboardGetMyPreferences } from '@apis/Customers';
import { useEffect, useMemo } from 'react';
import { inject, injectable, singleton } from 'tsyringe';
import { AsyncBundler } from '../AsyncBundler';
import { useDiMemo } from '../DI';
import { EventEmitter, useEvent } from '../EventEmitter';
import { Logger } from '../Logger';

type JsonValue = string | number | boolean | null;
type Json = JsonValue | Record<string, JsonValue | Json[]> | Json[];

export type UserPrefs = Record<string, Json>;

@singleton()
export class UserPreferenceService {
    private prefs: Record<string, any> = {};
    private listeners: Record<string, EventEmitter<void>> = {};
    private initialized = false;
    private _prefsKey?: string;
    private get prefsKey() {
        if (!this.initialized) {
            throw new Error('UserPreferenceService not initialized');
        }
        return this._prefsKey;
    }
    private readonly saveBundler = new AsyncBundler();

    public constructor(@inject(Logger) private readonly logger: Logger) {}

    public async init(prefsKey: string = 'UserPreferences') {
        this._prefsKey = prefsKey;
        this.initialized = true;
        await this.reload();
    }

    public get<T extends Json>(prefName: string, defaultValue: T): T {
        return this.prefs[prefName] ?? defaultValue;
    }

    public set<T extends Json>(prefName: string, value: T) {
        this.prefs[prefName] = value;
        this.listeners[prefName]?.emit();
        this.savePrefs(prefName);
    }

    public listen(prefNames: string[]) {
        const result = new EventEmitter<string | null>(null);
        const disposers: (() => void)[] = [];
        for (const name of prefNames) {
            const emitter = (this.listeners[name] ??= EventEmitter.empty());
            disposers.push(emitter.listen(() => result.emit(name)).dispose);
        }
        return { event: result, dispose: () => disposers.forEach((d) => d()) };
    }

    private async reload() {
        try {
            this.prefs = await this.loadPrefs();
        } catch (error) {
            this.logger.error('Failed to load user preferences', { error });
            this.prefs = {};
        }
    }

    private async savePrefs(prefName: string) {
        this.saveBundler.bundle('save', prefName, this.mergeChanges);
    }

    private mergeChanges = async (prefNames: string[]) => {
        try {
            const lastPrefs = await this.loadPrefs();
            for (const name of prefNames) {
                lastPrefs[name] = this.prefs[name];
            }
            this.prefs = lastPrefs;
            await postDashboardSaveMyPreferences({ Key: this.prefsKey, PreferencesJson: JSON.stringify(lastPrefs), CompanyId: 0 });
        } catch (error) {
            this.logger.error('Failed to save user preferences', { error });
        }
    };

    private async loadPrefs(): Promise<UserPrefs> {
        try {
            const result = await getDashboardGetMyPreferences({ companyId: 0, key: this.prefsKey });

            return !result ? {} : this.deserializePrefs(result.PreferencesJson ?? '{}') ?? {};
        } catch (error) {
            this.logger.error('Failed to load user preferences', { error });
            return {};
        }
    }

    private deserializePrefs(prefJson: string) {
        try {
            return JSON.parse(prefJson);
        } catch (error) {
            this.logger.error('Failed to deserialize user preferences', { error });
            return null;
        }
    }
}

export function useGlobalUserPrefs<T extends Json>(prefName: string, defaultValue: T) {
    const prefSvc = useDiMemo(UserPreferenceService);
    const { event, dispose, get, set } = useMemo(
        () => ({
            ...prefSvc.listen([prefName]),
            get: () => prefSvc.get(prefName, defaultValue),
            set: (value: T) => prefSvc.set(prefName, value),
        }),
        [prefSvc]
    );
    useEvent(event);
    useEffect(() => dispose, [dispose]);
    return { get, set };
}

export function useGlobalUserPrefsValue<T extends Json>(key: string, defaultValue: T) {
    const { get } = useGlobalUserPrefs(key, defaultValue);
    return get();
}
