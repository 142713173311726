import styled from '@emotion/styled';
import { Portal } from '@mantine/core';
import { useDi } from '@root/Services/DI';
import { EventEmitter, useEvent } from '@root/Services/EventEmitter';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { singleton } from 'tsyringe';
import { useRouteBoundPortal } from '../Router/RouteBoundPortal';

type VertAnchor = 'top' | 'center' | 'bottom';
type HorzAnchor = 'left' | 'center' | 'right';
type VhAnchor = `${VertAnchor}-${HorzAnchor}`;
export interface ITooltipRequest extends IFlyoverRequest {}
export interface IFlyoverRequest {
    x: number;
    y: number;
    renderer: (invalidateSize: () => void) => React.ReactNode | null;
    /**
     * Points on the flyover that should align with the x/y coordinate, defaults to [top, center]
     */
    anchor?: VhAnchor[] | VhAnchor;
    /**
     * Distance from the x coordinate to the flyover
     */
    offsetX?: number;
    /**
     * Distance from the y coordinate to the flyover
     */
    offsetY?: number;
    /**
     * True to prevent the flyover from recieving mouse events
     */
    nonInteractive?: boolean;
    /**
     * Transition settings to specify which css properties to animate, defaults to 'transform'
     */
    transition?: 'transform' | 'opacity' | 'all' | 'none';
    /**
     * Delay in ms before showing the flyover
     */
    delayMs?: number;
}

@singleton()
class FlyoverModelFactory {
    private models = new Map<string, { model: FlyoverModel; refs: Set<number> }>();
    public get(key?: string, onDisposing?: () => void) {
        key ??= 'default';
        let result = this.models.get(key);
        if (!result) {
            this.models.set(key, (result = { model: new FlyoverModel().init(), refs: new Set<number>() }));
        }
        const refId = result.refs.size;
        result.refs.add(refId);
        const disposer = () => {
            result?.refs.delete(refId);
            if (result?.refs.size === 0) {
                onDisposing?.();
                result?.model.dispose();
                this.models.delete(key!);
            }
        };

        return { model: result.model, disposer };
    }
}
type FoBox = { x: number; y: number; w: number; h: number };
export type IBoxFlyoverRequest = Omit<IFlyoverRequest, 'x' | 'y'> & {
    subject: { getBoundingClientRect: () => { x: number; y: number; width: number; height: number } };
    subjectAnchor?: VhAnchor;
};
class FlyoverModel {
    private _isHostAttached = false;
    private disposers: (() => void)[] = [];
    private disposed = false;
    private availSpace = { width: 0, height: 0 };
    private requestThrottleMs = 100;
    private throttleReqHandle: number | undefined = undefined;
    private anchorOrigins: { [key in VertAnchor | HorzAnchor]: [number, number] } = {
        top: [0, 1],
        center: [-0.5, -1],
        bottom: [-1, -1],
        left: [0, 1],
        right: [-1, -1],
    };

    public get isHostAttached() {
        return this._isHostAttached;
    }

    public readonly flyoverRequested = new EventEmitter<IFlyoverRequest | undefined>(undefined);

    public init() {
        window.addEventListener('resize', this.handleWindowResize);
        this.handleWindowResize();
        this.disposers.push(() => window.removeEventListener('resize', this.handleWindowResize));
        return this;
    }

    public hostAttached() {
        if (this.isHostAttached) {
            console.warn(`Flyover host already attached`);
        }
        this._isHostAttached = true;
        return () => {
            this._isHostAttached = false;
        };
    }

    public provideAnchoredFlyover(request: IBoxFlyoverRequest) {
        if (request.subject) {
            const { x: left, y: top, width: w, height: h } = request.subject.getBoundingClientRect();
            const { x, y } = this.getAnchorPoint({ x: left, y: top, w, h }, request.subjectAnchor ?? 'bottom-center') ?? { x: left, y: top };
            this.provideFlyover({ ...request, x, y });
        }
    }

    public provideFlyover(request: IFlyoverRequest) {
        this.emitRequest(request);
    }

    public invalidateFlyover() {
        this.emitRequest(undefined);
    }

    public dispose() {
        clearTimeout(this.throttleReqHandle);
        this.disposers.forEach((d) => d());
        this.disposed = true;
    }

    public scope(defaults: Partial<IBoxFlyoverRequest>) {
        const baseRequest = {
            subject: {
                getBoundingClientRect() {
                    console.warn(
                        `Anchored flyover request made without a subject. Did you forget to provide a subject (HTML element) to position the flyover against?`
                    );
                    return { x: 0, y: 0, width: 1, height: 1 };
                },
            },
            renderer: () => <></>,
            ...defaults,
        };
        return {
            open: (options: Partial<IBoxFlyoverRequest> = {}) => {
                const request = { ...baseRequest, ...options };
                this.provideAnchoredFlyover(request);
            },
            close: () => this.invalidateFlyover(),
        };
    }

    public reposition(flyoverHost: HTMLDivElement, request: IFlyoverRequest) {
        if (this.flyoverRequested.value === request) {
            const { x, y, anchor, offsetX = 0, offsetY = 0 } = this.flyoverRequested.value ?? {};
            const { width: w, height: h } = flyoverHost.getBoundingClientRect();

            return this.getFit(x, y, w, h, anchor, offsetX, offsetY);
        }
    }

    private getFit(x: number, y: number, w: number, h: number, anchor: VhAnchor | VhAnchor[] | undefined, offsetX: number, offsetY: number) {
        const bounds = { x: 0, y: 0, w: this.availSpace.width, h: this.availSpace.height };
        return this.getAnchorPoints({ x, y, w, h }, anchor, offsetX, offsetY, bounds);
    }

    private getAnchorPoints(box: FoBox, anchor: VhAnchor | VhAnchor[] | undefined, offsetX: number, offsetY: number, bounds: FoBox) {
        const { w: width, h: height, x: minX, y: minY } = bounds;
        const { x, y, w, h } = box;
        const anchors = anchor && typeof anchor === 'string' ? [anchor] : !anchor || !anchor.length ? ['top-center'] : anchor;
        let result: { orgX: number; orgY: number; x: number; y: number } | undefined = undefined;

        for (const rawAnchor of anchors) {
            const { orgX, orgY, offsetModX, offsetModY } = this.getAnchorTransforms(rawAnchor as VhAnchor);
            const offX = offsetModX * offsetX;
            const offY = offsetModY * offsetY;
            const nextX = x + offX;
            const nextY = y + offY;
            const posL = offX + x + orgX * w;
            const posT = offY + y + orgY * h;
            const posR = posL + w;
            const posB = posT + h;
            const adjX = posL < minX ? -posL : posR > width ? width - posR : 0;
            const adjY = posT < minY ? -posT : posB > height ? height - posB : 0;

            if (!adjX && !adjY) {
                return { orgX, orgY, x: nextX, y: nextY };
            } else if (result === undefined) {
                result = { orgX, orgY, x: nextX + adjX, y: nextY + adjY };
            }
        }

        return result;
    }

    private getAnchorPoint({ x, y, w, h }: FoBox, anchor: VhAnchor) {
        const { orgX, orgY } = this.getAnchorTransforms(anchor);
        return { x: x + Math.abs(orgX) * w, y: y + Math.abs(orgY) * h };
    }

    private getAnchorTransforms(anchor: VhAnchor) {
        const [ancV, ancH] = anchor.split('-') as [VertAnchor, HorzAnchor];
        const [orgX, offsetModX] = this.anchorOrigins[ancH];
        const [orgY, offsetModY] = this.anchorOrigins[ancV];
        return { orgX, orgY, offsetModX, offsetModY };
    }

    private emitRequest(request: IFlyoverRequest | undefined) {
        if (!this.disposed) {
            clearTimeout(this.throttleReqHandle);
            const delayMs = request?.delayMs ?? this.requestThrottleMs;
            this.throttleReqHandle = setTimeout(() => this.flyoverRequested.emit(request), delayMs) as unknown as number;
        }
    }

    private handleWindowResize = () => {
        this.availSpace = { width: window.innerWidth, height: window.innerHeight };
    };
}

function useFlyoverPortalHost(flyoverModel: FlyoverModel, disposer: () => void, key?: string) {
    const hostDisposer = useMemo(() => {
        let portalDisposer = () => {};
        if (!flyoverModel.isHostAttached) {
            // const container = document.createElement('div');
            // document.body.appendChild(container);
            // const root = createRoot(container);
            // root.render(<FlyoverHost flyoverKey={key} />);
            // portalDisposer = () => {
            //     unmountComponentAtNode(container);
            //     document.body.removeChild(container);
            // };
            // flyoverModel.hostAttached();
        }
        return () => {
            portalDisposer();
            disposer();
        };
    }, [flyoverModel]);
    useEffect(() => hostDisposer, []);
}

export function useFlyoverModel(key?: string) {
    const factory = useDi(FlyoverModelFactory);
    const { model: flyoverModel, disposer } = useMemo(() => factory.get(key), []);
    useFlyoverPortalHost(flyoverModel, disposer, key);
    return flyoverModel;
}

export function useAnchoredFlyoverEvents(defaults: Partial<IBoxFlyoverRequest>, key?: string) {
    const flyoverModel = useFlyoverModel(key);
    const scope = useMemo(() => flyoverModel.scope(defaults), [flyoverModel, defaults]);

    return {
        ...scope,
        onClickOpen: (evt: { currentTarget: EventTarget }, options: Partial<IBoxFlyoverRequest> = {}) =>
            scope.open({ subject: evt.currentTarget as HTMLElement, ...options }),
        host: () => <FlyoverHost flyoverKey={key} />,
    };
}

function UnportaledFlyoverHost({ flyoverKey }: { flyoverKey?: string }) {
    const flyoverModel = useFlyoverModel(flyoverKey);
    const [hostEl, setHostEl] = useState<HTMLDivElement | null>(null);
    const [renderer, setRenderer] = useState<{ fn: () => React.ReactNode | null }>();

    const reposition = useCallback((request: IFlyoverRequest, hostEl: HTMLDivElement) => {
        if (hostEl) {
            const pos = flyoverModel.reposition(hostEl, request);
            if (pos) {
                hostEl.style.pointerEvents = request.nonInteractive ? 'none' : 'auto';
                const { transition = 'transform' } = request;
                const { orgX, orgY, x, y } = pos;
                const transform = `translate(${orgX * 100}%, ${orgY * 100}%) translate(${Math.round(x)}px, ${Math.round(y)}px)`;
                if (hostEl.style.opacity === '0') {
                    hostEl.style.transition = 'none';
                    hostEl.style.transform = transform;
                    hostEl.style.opacity = '1';
                }
                hostEl.style.transition = `${transition} 0.3s`;
                hostEl.style.transform = transform;
            }
        }
    }, []);
    const flyoverReqHandler = useCallback(
        (request: IFlyoverRequest | undefined) => {
            if (hostEl) {
                if (!request) {
                    hostEl.style.opacity = '0';
                    setRenderer(undefined);
                } else {
                    if (request.renderer) {
                        setRenderer({
                            fn: () => {
                                const result = request.renderer(() => reposition(request, hostEl));
                                setTimeout(() => requestAnimationFrame(() => reposition(request, hostEl)));
                                return result;
                            },
                        });
                    }
                }
            }
        },
        [hostEl]
    );
    useEvent(flyoverModel.flyoverRequested, flyoverReqHandler);

    return <FlyoverHostEl ref={setHostEl}>{renderer?.fn()}</FlyoverHostEl>;
}

export function FlyoverHost({ flyoverKey, routeBound }: { flyoverKey?: string; routeBound?: boolean }) {
    const target = useRouteBoundPortal();
    return (
        <Portal target={routeBound ? target : document.body}>
            <UnportaledFlyoverHost flyoverKey={flyoverKey} />
        </Portal>
    );
}

const FlyoverLayer = styled.div`
    position: fixed;
    top: 0;
    left: 0;
`;
const FlyoverHostEl = styled(FlyoverLayer)`
    z-index: 1000;
    transition: none 0.3s;
`;

const FlyoverOverlayEl = styled(FlyoverLayer)`
    z-index: 999;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.01);
`;

export function FlyoverOverlay({ opened, onClick, routeBound }: { opened?: boolean; onClick?: () => void; routeBound?: boolean }) {
    const target = useRouteBoundPortal();
    return <Portal target={routeBound ? target : document.body}>{opened ? <FlyoverOverlayEl onClick={onClick} /> : null}</Portal>;
}

export function useTooltip(defaults?: Partial<ITooltipRequest>, flyoverKey: string = 'tooltip'): [(request: ITooltipRequest) => void, () => void] {
    const tooltipModel = useFlyoverModel(flyoverKey);
    const close = useCallback(() => {
        tooltipModel.invalidateFlyover();
    }, []);
    const show = useCallback((request: ITooltipRequest) => {
        tooltipModel.provideFlyover({ ...defaults, ...request, nonInteractive: true });
    }, []);

    return [show, close];
}
