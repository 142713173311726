import { InvoiceSchemaInfo } from '@apis/Invoices/model';
import { QueryExpr } from '@apis/Resources';
import { UserConfiguredBarChartSettings } from '@root/Components/Charts/BarChart';
import { FormElement } from '@root/Components/Settings/SettingsForms';
import { InvoiceApiService } from '@root/Services/Invoices/InvoiceApiService';
import { IInvoiceFieldCompatibilityLookup, InvoiceFieldCompatibilityService } from '@root/Services/Invoices/InvoiceFieldCompatibilityService';
import { InvoiceQueryDatasourceFactory } from '@root/Services/Invoices/InvoiceQueryDatasourceFactory';
import { IBaseInvoiceRecord, IMixedInvoiceRecord, InvoiceSchemaService } from '@root/Services/Invoices/InvoiceSchemaService';
import { QueryDatasource } from '@root/Services/Query/QueryDatasource';
import { exprBuilder, SchemaService } from '@root/Services/QueryExpr';
import { endOfMonth, startOfMonth } from 'date-fns';
import { BellRinging, ChartBar, Settings, Report, Coin } from 'tabler-icons-react';
import { inject, injectable } from 'tsyringe';
import { RecurrenceRuleType } from '../../Components/Schedule';
import { BaseDatasourcePresetsBuilder, IDatasourcePresetsBuilderFactory, INotificationPresetItem, PrefixedParams } from './BasePresets';
import { ChartSettingsInput } from './ChartSettingsInput';

class InvoiceDatasourcePresetsBuilder extends BaseDatasourcePresetsBuilder {
    public constructor(
        private readonly defaultDateRange: { from: Date; to: Date },
        private readonly queryDatasource: QueryDatasource,
        private readonly schemaSvc: SchemaService,
        private readonly schemaInfo: InvoiceSchemaInfo,
        private readonly fieldCompat: IInvoiceFieldCompatibilityLookup
    ) {
        super(queryDatasource.name);
    }

    public getPresets() {
        return this.createDatasourcePresets(
            'Costs',
            <Coin />,
            [
                this.createAvgUsageChangePreset({ days: 10, percent: 0.2, change: 'increase' }),
                this.createAvgUsageChangePreset({ days: 10, percent: 0.2, change: 'decrease' }),
                this.createAvgUsageChangePreset({ days: 30, percent: 0.2, change: 'increase' }),
                this.createAvgUsageChangePreset({}),
            ],
            [
                this.createSchedUsageCostPreset({ days: 15, period: 'Weekly' }),
                this.createSchedUsageCostPreset({ days: 30, period: 'Weekly' }),
                this.createSchedTotalCostPreset({ days: 15, period: 'Weekly' }),
                this.createSchedTotalCostPreset({ days: 30, period: 'Weekly' }),
                this.createSchedCostPreset('user-defined', {}),
                this.createSchedMonthToDateCostPreset('usage', { recurrence: 'Weekly' }),
                this.createSchedMonthToDateCostPreset('total', { recurrence: 'Weekly' }),
                this.createCustomCostReportPreset(),
            ]
        );
    }

    // #region Alerts
    private createAvgUsageChangePreset(options: {
        days?: number;
        percent?: number;
        change?: 'increase' | 'decrease' | 'change';
    }): INotificationPresetItem<any> {
        const { days, percent, change } = options;
        const { days: daysDef = days ?? 10, change: changeDef = change ?? 'increase', percent: percentDef = percent ?? 0.2 } = options;

        const daysDesc = 'days' in options ? `${options.days}-day ` : 'configured days of';
        const percentLbl = ` ${percentDef * 100}%`;
        const percentDesc = percent === undefined ? 'by a configured percent' : `by ${percentLbl}`;
        const changeDirLbl = options.change === 'increase' ? 'up' : options.change === 'decrease' ? 'down' : 'change';
        const changeDirDesc = options.change === 'increase' ? 'increases' : options.change === 'decrease' ? 'decreases' : 'changes';

        const id = this.createId(days, 'day-usage', change, 'by', percent);
        const description = `Alert when the ${daysDesc} daily usage cost ${changeDirDesc} ${percentDesc}`;

        type Params = PrefixedParams<Required<typeof options>>;

        const [dateExpr, costExpr] = this.getExprs<[Date, number]>(this.getFields('ChargePeriodStart', 'BilledCost'));

        const initialized = this.createBehavior(({ data }) => {
            const xb = this.createExprBuilder<Params>();
            const groups = this.selectExprs({ Date: dateExpr });
            const values = this.selectExprs({ 'Usage Cost': xb.sum(costExpr) });

            const result = this.initializeConfig(data, id, {
                Title: 'Significant Usage Cost Change',
                Description: '',
                ComponentConfig: this.createBaseBarChartConfig(groups, values, {
                    xFormat: 'short-date',
                    format: 'money-whole',
                    ...((data.ComponentConfig?.settings ?? {}) as UserConfiguredBarChartSettings),
                    metricLabel: 'Usage Cost',
                    reaggOptions: { sortBy: 'label', sortDir: 'asc' },
                }),
                Trigger: {
                    TriggerType: 'Threshold',
                    Threshold: {
                        ThrottlePeriod: 'Daily',
                        ThrottleFrequency: 1,
                    },
                },
                QueryOptions: {
                    Parameters: {
                        ...this.createUserInputParams(data, { pDays: daysDef, pPercent: percentDef, pChange: changeDef }),
                        pFrom: xb.resolve(xb.snapDate(xb.addDate(xb.currentDate(), xb.model.pDays.plus(1).times(-1), 'day'), 'day', 'start')),
                        pTo: xb.resolve(xb.snapDate(xb.addDate(xb.currentDate(), xb.param(-1), 'day'), 'day', 'end')),
                    },
                    Filters: [this.createUsageFilterExpr()],
                    DatasourceName: this.queryDatasource.name,
                    ApiParameters: {
                        from: xb.resolve(xb.model.pFrom),
                        to: xb.resolve(xb.model.pTo),
                    },
                },
            });

            return result;
        });
        const forms = initialized.addForm(({ data }) => {
            const params = data.QueryOptions!.Parameters;
            type Params = typeof params;

            const elements = [] as FormElement[];

            if (days === undefined) {
                elements.push(
                    this.createInput<Params>()
                        .type('number')
                        .options({ label: 'Days', min: 7, max: 45 })
                        .map((d) => d.pDays.Value)
                        .bind(params)
                );
            }
            if (percent === undefined) {
                elements.push(
                    this.createInput<Params>()
                        .type('number')
                        .options({ label: 'Percent' })
                        .map((d) => d.pPercent.Value)
                        .modelToView((v) => v * 100)
                        .viewToModel((v) => v / 100)
                        .bind(params)
                );
            }
            if (change === undefined) {
                elements.push(
                    this.createInput<Params>()
                        .type('string')
                        .options({
                            label: 'Change Type',
                            options: [
                                { value: 'increase', label: 'Increase' },
                                { value: 'decrease', label: 'Decrease' },
                                { value: 'change', label: 'Increase or Decrease' },
                            ],
                            presentationOptions: { width: 150, align: 'center' },
                        })
                        .map((d) => d.pChange.Value)
                        .bind(params)
                );
            }

            if (elements.length) {
                elements.push({ type: 'divider' });
            }

            return [
                this.createAlertSettingsForm(
                    'Usage cost alert settings',
                    ...elements,
                    ...this.createDescriptionInputs(data, 'Significant Usage Cost Change')
                ),
            ];
        });
        const behavior = forms.addValidation((args) => []);

        return {
            id,
            icon: !days || !percent || !change ? <Settings /> : <ChartBar />,
            label: [
                days === undefined ? { type: 'variable', text: '##' } : days.toString(),
                '-day daily usage ',
                change === undefined ? { type: 'variable', text: 'up/down' } : changeDirLbl,
                percent === undefined ? { type: 'variable', text: '##%' } : percentLbl,
            ],
            description,
            behavior,
        };
    }

    private createAlertSettingsForm(header: string, ...elements: FormElement[]) {
        return {
            title: 'Alert Settings',
            elements: [
                {
                    header: { label: header },
                    elements,
                },
            ],
        };
    }
    // #endregion

    // #region Reports

    private createSchedUsageCostPreset(options: { days?: number; period?: RecurrenceRuleType }): INotificationPresetItem<any> {
        return this.createSchedCostPreset('usage', options);
    }
    private createSchedTotalCostPreset(options: { days?: number; period?: RecurrenceRuleType }): INotificationPresetItem<any> {
        return this.createSchedCostPreset('total', options);
    }

    private createSchedCostPreset(
        costType: 'usage' | 'total' | 'user-defined',
        options: { days?: number; period?: RecurrenceRuleType }
    ): INotificationPresetItem<any> {
        const { days, period } = options;
        const { days: daysDef = days ?? 15, period: periodDef = period ?? 'Weekly' } = options;
        const costDesc = costType === 'usage' ? 'Usage Cost' : costType === 'total' ? 'Total Cost' : 'Cost';

        const id = this.createId(days, `${costType}-cost`, period);
        const description = this.createPresetDescription({ value: period, placeholder: 'Periodic' }, `report of usage cost over the previous`, {
            value: days,
            placeholder: 'configurable number of',
            suffix: ' days',
        });

        type Params = PrefixedParams<Required<typeof options>>;

        const [dateExpr, costExpr] = this.getExprs<[Date, number]>(this.getFields('ChargePeriodStart', 'BilledCost'));
        const costFilter = costType === 'total' ? [] : [this.createUsageFilterExpr()];

        const initialized = this.createBehavior(({ data }) => {
            const xb = this.createExprBuilder<Params>();
            const groups = this.selectExprs({ Date: dateExpr });
            const values = this.selectExprs({ Amount: xb.sum(costExpr) });

            const result = this.initializeConfig(data, id, {
                Title: `${costDesc} Report`,
                Description: '',
                ComponentConfig: this.createBaseBarChartConfig(groups, values, {
                    xFormat: 'short-date',
                    format: 'money-whole',
                    ...((data.ComponentConfig?.settings ?? {}) as UserConfiguredBarChartSettings),
                    metricLabel: 'Amount',
                    reaggOptions: { sortBy: 'label', sortDir: 'asc' },
                }),
                Trigger: {
                    TriggerType: 'Schedule',
                    Schedule: this.getDefaultScheduleOptions(periodDef),
                },
                QueryOptions: {
                    Parameters: {
                        ...this.createUserInputParams(data, { pDays: daysDef }),
                        pFrom: xb.resolve(xb.snapDate(xb.addDate(xb.currentDate(), xb.model.pDays.plus(1).times(-1), 'day'), 'day', 'start')),
                        pTo: xb.resolve(xb.snapDate(xb.addDate(xb.currentDate(), xb.param(-1), 'day'), 'day', 'end')),
                    },
                    Filters: costFilter,
                    DatasourceName: this.queryDatasource.name,
                    ApiParameters: this.createApiParams(),
                },
            });

            return result;
        });
        const behavior = initialized
            .addForm(({ data }) => {
                const params = data.QueryOptions!.Parameters;
                type Params = typeof params;

                const elements = [] as FormElement[];

                if (days === undefined) {
                    elements.push(
                        this.createInput<Params>()
                            .type('number')
                            .options({ label: 'Days', min: 7, max: 45 })
                            .map((d) => d.pDays.Value)
                            .bind(params)
                    );
                }

                return [
                    this.createSection(
                        `Report Settings`,
                        this.createSectionItem(
                            `${costDesc} Report Options`,
                            ...this.concatSectionItems(
                                elements,
                                this.createDescriptionInputs(data, `${this.fmtSvc.titleCase(periodDef)} ${costDesc} Report`)
                            )
                        ),
                        this.createScheduleForm(data.Trigger?.Schedule!, periodDef)
                    ),
                ];
            })
            .addValidation((args) => []);

        return {
            id,
            icon: !days || !period ? <Settings /> : <ChartBar />,
            label: this.createPresetLabel(
                { value: period, placeholder: 'Periodic' },
                { value: days, placeholder: '##', suffix: '-day' },
                { value: costType === 'user-defined' ? undefined : costDesc, placeholder: 'Cost' }
            ),
            description,
            behavior,
        } as INotificationPresetItem<any>;
    }

    private createSchedMonthToDateCostPreset(
        costType: 'usage' | 'total' | 'user-defined',
        options: { recurrence?: RecurrenceRuleType }
    ): INotificationPresetItem<any> {
        const { recurrence } = options;
        const { recurrence: recurrenceDef = recurrence ?? 'Weekly' } = options;
        const costDesc = costType === 'usage' ? 'Usage Cost' : costType === 'total' ? 'Total Cost' : 'Cost';

        const id = this.createId(`${costType}-cost`, recurrence);
        const description = this.createPresetDescription(
            { value: recurrence, placeholder: 'Configurable' },
            'report of',
            costType === 'user-defined' ? 'configured' : costType,
            'cost for the current month'
        );

        type Params = PrefixedParams<Required<typeof options>>;

        const [dateExpr, costExpr] = this.getExprs<[Date, number]>(this.getFields('ChargePeriodStart', 'BilledCost'));

        const initialized = this.createBehavior(({ data }) => {
            const xb = this.createExprBuilder<Params & { pMonth: Date }>();
            const groups = this.selectExprs({ Date: dateExpr });
            const values = this.selectExprs({ Amount: xb.sum(costExpr) });
            const costFilter = costType === 'total' ? [] : [this.createUsageFilterExpr()];

            const result = this.initializeConfig(data, id, {
                Title: `${costDesc} Report`,
                Description: '',
                ComponentConfig: this.createBaseBarChartConfig(groups, values, {
                    xFormat: 'short-date',
                    format: 'money-whole',
                    ...((data.ComponentConfig?.settings ?? {}) as UserConfiguredBarChartSettings),
                    metricLabel: 'Amount',
                    reaggOptions: { sortBy: 'label', sortDir: 'asc' },
                }),
                Trigger: {
                    TriggerType: 'Schedule',
                    Schedule: this.getDefaultScheduleOptions(recurrenceDef),
                },
                QueryOptions: {
                    Parameters: {
                        pMonth: xb.resolve(xb.snapDate(xb.currentDate(), 'month', 'start')),
                    },
                    Filters: [this.createCurrentMonthFilter(), ...costFilter],
                    DatasourceName: this.queryDatasource.name,
                    ApiParameters: { from: xb.resolve(xb.model.pMonth) },
                },
            });

            return result;
        });
        const behavior = initialized
            .addForm(({ data }) => {
                return [
                    this.createSection(
                        `Report Settings`,
                        this.createSectionItem(
                            `${costDesc} Report Options`,
                            ...this.concatSectionItems(
                                this.createDescriptionInputs(data, `${this.fmtSvc.titleCase(recurrenceDef)} ${costDesc} Report`)
                            )
                        ),
                        this.createScheduleForm(data.Trigger?.Schedule!, recurrenceDef)
                    ),
                ];
            })
            .addValidation((args) => []);

        return {
            id,
            icon: <ChartBar />,
            label: this.createPresetLabel({ value: recurrence, placeholder: 'Periodic' }, 'month-to-date', { value: costDesc, placeholder: 'Cost' }),
            description,
            behavior,
        } as INotificationPresetItem<any>;
    }

    private createCustomCostReportPreset() {
        const xb = this.createExprBuilder();
        const [category, cost] = this.getExprs<[string, number]>(this.getFields('ServiceCategory', 'BilledCost'));
        const [dimExpr, metricExpr] = this.selectExprs({ category, cost: xb.sum(cost) });
        const { input: monthLookbackInput, params: monthLookbackParams } = this.createMonthLookbackInput();
        const defaultQueryOptions = {
            Parameters: {
                ...this.createMonthLookbackParams(),
                ...monthLookbackParams,
            },
            Filters: [],
            DatasourceName: this.queryDatasource.name,
            ApiParameters: this.createApiParams(),
        };

        const filterInput = this.createFilterSettings(this.queryDatasource, this.schemaSvc);
        const chartInput = this.createCustomChartBehavior(this.queryDatasource, this.schemaSvc);

        const behavior = this.createBehavior(({ data }) => {
            return this.mergeConfig(
                {
                    ComponentConfig: this.createCustomChartConfig('pie', dimExpr, metricExpr),
                    QueryOptions: defaultQueryOptions,
                },
                data
            );
        })
            .addForm(({ data }) => {
                const chartSettings = chartInput(data);

                const monthsSetting = monthLookbackInput.bind(data.QueryOptions!.Parameters);
                const filterSetting = filterInput(data);
                const filterSection = this.createSectionItem('Data Filters', filterSetting, { type: 'divider' }, monthsSetting);

                chartSettings.elements.push(filterSection);

                return [
                    chartSettings,
                    this.createSection(
                        'Report Settings',
                        this.createSectionItem('Title and Description', ...this.createDescriptionInputs(data, 'Untitled')),
                        this.createScheduleForm(data.Trigger?.Schedule!)
                    ),
                ];
            })
            .addValidation((args) => []);

        return this.createCustomReportPreset(behavior);
    }

    // #endregion

    private createCurrentMonthFilter() {
        const [usageMoField] = this.getFieldExprs('UsageMonth');
        const xb = this.createExprBuilder<{ pMonth: Date }>();
        return xb.resolve(xb.fromExpr(usageMoField).eq(xb.model.pMonth));
    }

    private createUsageFilterExpr() {
        const xb = this.createExprBuilder<{}>();

        return xb.resolve(
            xb.or(
                xb.model.ChargeCategory!.eq('Usage'),
                xb.model['lineItem/LineItemType']!.eq([
                    'Usage',
                    'SavingsPlanCoveredUsage',
                    'SavingsPlanNegation',
                    'DiscountedUsage',
                    'Discount',
                    'PrivateRateDiscount',
                    'EdpDiscount',
                ])
            )
        );
    }

    private createExprBuilder<TParams>() {
        type Model = IMixedInvoiceRecord & TParams & { pFrom: Date; pTo: Date };
        const { builder } = exprBuilder<Model>();
        return builder;
    }

    private getFieldExprs(...fields: (keyof IBaseInvoiceRecord)[]) {
        return fields.map((f) => ({ Field: f }));
    }

    private getFields<T extends string[]>(...fields: string[]): T {
        return this.fieldCompat.getAvailableFields(...(fields as (keyof IBaseInvoiceRecord)[])) as T;
    }

    private createApiParams() {
        const xb = this.createExprBuilder<{ pFrom: Date; pTo: Date }>();
        return {
            from: xb.resolve(xb.model.pFrom),
            to: xb.resolve(xb.model.pTo),
        };
    }

    private createMonthLookbackInput() {
        const params = { pMonths: { Value: 1 } };
        const input = this.createInput<typeof params>()
            .type('string')
            .options({
                label: 'Lookback Period',
                options: Array.from({ length: 13 }, (_, i) => ({
                    value: i.toString(),
                    label: i === 0 ? 'Current Month Only' : `${i} Months`,
                })),
                defaultValue: '1',
            })
            .presentation({ align: 'center' })
            .map((d) => d.pMonths.Value)
            .modelToView((v) => v.toString())
            .viewToModel((v) => parseInt(v));

        return { params, input };
    }

    private createMonthLookbackParams() {
        const xb = this.createExprBuilder<{ pFrom: Date; pTo: Date; pMonths: number }>();
        return {
            pFrom: xb.resolve(xb.snapDate(xb.addDate(xb.currentDate(), xb.model.pMonths.times(-1), 'month'), 'month', 'start')),
            pTo: xb.resolve(xb.snapDate(xb.addDate(xb.currentDate(), xb.param(-1), 'day'), 'month', 'start')),
        };
    }
}

@injectable()
export class InvoiceDatasourcePresetsBuilderFactory implements IDatasourcePresetsBuilderFactory<{ from: Date; to: Date }> {
    public constructor(
        @inject(InvoiceSchemaService) private readonly schemaSvc: InvoiceSchemaService,
        @inject(InvoiceQueryDatasourceFactory) private readonly datasourceFactory: InvoiceQueryDatasourceFactory,
        @inject(InvoiceApiService) private readonly invoiceApi: InvoiceApiService,
        @inject(InvoiceFieldCompatibilityService) private readonly fieldCompat: InvoiceFieldCompatibilityService
    ) {}

    public getSupportedDatasources() {
        return [this.datasourceFactory.getMetadata()];
    }

    public async get(_: string, dateRange: { from: Date; to: Date }) {
        const [schemaDetail, fieldCompat] = await Promise.all([
            this.schemaSvc.getDailySchemaTransformationDetail(),
            this.fieldCompat.getLookup('daily'),
        ]);
        const { types, months, schemaInfo } = schemaDetail;
        const mostRecentMonth = months.sort((a, b) => b.getTime() - a.getTime())[0];
        const defaultRangeDate = mostRecentMonth ?? new Date();
        const defaultRange = { from: startOfMonth(defaultRangeDate), to: endOfMonth(defaultRangeDate) };
        const finalDateRange = dateRange ?? defaultRange;
        const queryDatasource = await this.datasourceFactory.getDatasource((q) => this.invoiceApi.query(q, finalDateRange));
        const schemaSvc = SchemaService.create(types);
        return new InvoiceDatasourcePresetsBuilder(finalDateRange, queryDatasource, schemaSvc, schemaInfo, fieldCompat);
    }
}
