import { ReactNode } from 'react';
import { Box, Card, Divider, Stack, Text } from '@mantine/core';
import styled from '@emotion/styled';

export function NotificationContent({ title, description, children }: { title: string; description: string; children: ReactNode }) {
    return (
        <Card shadow="xs" p={0} radius="xs" color="gray.1" sx={{ overflow: 'visible' }}>
            <Stack spacing={0}>
                <Box p="md">
                    <Text size={16}>{title}</Text>
                    {!description ? null : (
                        <Text sx={{ whiteSpace: 'pre-wrap' }} size={13}>
                            {description}
                        </Text>
                    )}
                </Box>
                <Divider color="gray.3" />
                <Box p="md">{children}</Box>
            </Stack>
        </Card>
    );
}

export const NotificationContentBody = styled.div`
    height: 400px;
`;
