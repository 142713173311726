import { IQueryExpr, UserListItem } from '@apis/Customers/model';
import { DataGrid } from '@root/Components/DataGrid';
import { ColumnConfig, DataGridProps, DataGridState, IDataSource } from '@root/Components/DataGrid/Models';
import { useDi, useDiContainer } from '@root/Services/DI';
import { EventEmitter, useEvent, useEventValue } from '@root/Services/EventEmitter';
import { SchemaService } from '@root/Services/QueryExpr';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { inject, injectable } from 'tsyringe';
import { BaseResource, ResourceChange } from '@apis/Resources/model';
import { Logger } from '@root/Services/Logger';
import { FieldInfoColumnAdapter } from '../DataGrid/FieldInfoColumnAdapter';
import { QueryField, postResourceChangedQueryResourceChangeLog, Job } from '@apis/Resources';
import { getUserGetCompanyUsers } from '@apis/Customers';
import { useCompany } from '../Router/CompanyContent';
import { FormatService } from '@root/Services/FormatService';
import { DataGridModel } from '../DataGrid/DataGridModel';
import { Box, Button, CloseButton, Divider, Group, Space, Table, Title, createStyles, useMantineTheme } from '@mantine/core';
import { PagePanel, PanelBody, PanelHeader, PaneledPage } from '@root/Design/Layout';
import { ResourceService } from '@root/Services/Resources/ResourceService';
import { queryBuilder } from '@root/Services/QueryExpr';
import { IValueProviderFactory } from '@root/Services/Query/QueryDatasource';
import { PlatformService } from '@root/Services/PlatformService';
import { CustomColors } from '@root/Design/Themes';
import { VisibleSpaces } from '../Text/VisibleSpaces';
import { ActivityDetailsOpener } from './ActionDetails';
import { JobService } from '@root/Services/Jobs/JobService';

@injectable()
export class ResourceChangeModel {
    public dataGrid?: DataGridModel;
    public isLoading = new EventEmitter<boolean>(true);
    public schema = new SchemaService([]);
    public gridProps?: DataGridProps<ResourceChange>;
    public datasource?: IDataSource<ResourceChange>;
    public selectedResource = new EventEmitter<ResourceChange | undefined>(undefined);
    public resourceSelected?: ResourceChange;
    public onResourceChangeSelected = new EventEmitter<ResourceChange | undefined>(undefined);
    public state: DataGridState = { columns: [], filters: [], sort: [] };
    public additionalFilter: IQueryExpr | undefined;

    onSelectionChanged: any;
    public constructor(
        @inject(FieldInfoColumnAdapter) private readonly columnAdapter: FieldInfoColumnAdapter<ResourceChange>,
        @inject(Logger) private readonly logger: Logger,
        @inject(PlatformService) public readonly platformSvc: PlatformService
    ) {}

    public async init() {
        try {
            this.isLoading.emit(true);
            await this.createBasicDatasource();
        } finally {
            this.isLoading.emit(false);
        }
    }

    public attachGrid = (grid: DataGridModel) => {
        this.dataGrid = grid;
    };

    public createBasicDatasource() {
        const grid = this;
        this.datasource = {
            async getPage(start: number, end: number, state: DataGridState) {
                let where: IQueryExpr | undefined = grid.getQuery(state);
                let query = queryBuilder<ResourceChange>()
                    .where((b) => b.and(b.model.Status!.eq('Succeeded'), b.model.AccountId!.isNotNull()))
                    .build();

                where = where != undefined ? { Operation: 'and', Operands: [where, query.Where] } : query.Where;
                where = grid.additionalFilter != undefined ? { Operation: 'and', Operands: [where, grid.additionalFilter.Where] } : where;

                const response = await postResourceChangedQueryResourceChangeLog({
                    IncludeSchema: false,
                    IncludeCount: true,
                    Where: where,
                    Skip: start,
                    Take: end - start + 1,
                    Sort: state.sort,
                });

                return { items: response.Results, total: response.Count };
            },
        } as IDataSource<ResourceChange>;
    }

    public getQuery(state?: DataGridState) {
        const { filters } = state ?? this.dataGrid?.gridState ?? {};
        const where = filters?.length ? { Operation: 'and', Operands: filters } : undefined;
        return where;
    }

    private formatUser(user: UserListItem) {
        return user.FirstName || user.LastName ? `${user.FirstName} ${user.LastName}` : user.EMail ?? 'Unknown';
    }

    public getUserFilterOptions(users: UserListItem[] | undefined) {
        const result = [] as { label: string; value: number | null }[];
        if (users) {
            result.push(...users.map((u) => ({ label: this.formatUser(u), value: u.Id ?? 0 })));
        }
        result.sort((a, b) => a.label.localeCompare(b.label, undefined, { sensitivity: 'base' }));
        result.push({ label: 'System', value: null });
        return result;
    }

    public getPlatformFilterOptions() {
        const result = [] as { label: string; value: string | null }[];
        if (this.datasource) {
            result.push({ label: 'AWS', value: 'Aws' }, { label: 'Azure', value: '1' });
        }
        return result;
    }
}

export function ResourceChangeGrid({ onModelCreated, noPadding }: { onModelCreated?: (model: ResourceChangeModel) => void; noPadding?: boolean }) {
    const [resourceSelected, setResourceSelected] = useState<ResourceChange | undefined>(undefined);
    const resourceService = useDi(ResourceService);
    const [resource, setResource] = useState<BaseResource>();
    const [users, setUsers] = useState<UserListItem[]>();
    const formatSvc = useDi(FormatService);
    useEffect(() => {
        (async () => {
            getUserGetCompanyUsers().then((users) => {
                setUsers(users);
            });
        })();
    }, []);

    useEffect(() => {
        if (resource !== undefined && resource.Id && resource.ResourceType) {
            resourceService.getResource(resource.Id, resource.ResourceType).then(setResource);
        }
    }, [resourceSelected, resource]);

    useEffect(() => {
        if (resourceSelected === undefined) {
            closeSidePanel();
            setTimeout(() => window.dispatchEvent(new Event('resize')), 1);
        }
    }, [resourceSelected]);

    function closeSidePanel() {
        setResourceSelected(undefined);
    }

    const di = useDiContainer();
    const model = useMemo(() => {
        const result = di.resolve(ResourceChangeModel);
        onModelCreated?.(result);
        result.init();
        return result;
    }, []);
    useEvent(model.isLoading);

    const handleModelLoaded = (dataGrid: DataGridModel) => {
        model.attachGrid(dataGrid);
    };

    const onRowClick = (e: any) => {
        setResourceSelected(e);
    };

    const dataGridColumns = useMemo(
        () =>
            [
                {
                    id: 'Status',
                    accessor: 'Status',
                    header: 'Status',
                    noSort: 'Status',
                    filter: {
                        name: 'Status',
                        filterType: 'string',
                        filterField: 'Status',
                    },
                    cellRenderer: (item) => item.Status,
                },
                {
                    id: 'TimeStamp',
                    header: 'Updated At',
                    type: 'date',
                    accessor: 'TimeStamp',
                    cellRenderer: (item) => formatSvc.toLocal(item.TimeStamp),
                    sortField: 'TimeStamp',
                    filter: {
                        filterType: 'date',
                        name: 'TimeStamp',
                        filterField: 'TimeStamp',
                    },
                },
                {
                    id: 'AccountId',
                    header: 'AccountId',
                    accessor: 'AccountId',
                    cellRenderer: (item) => item.AccountId,
                    sortField: 'AccountId',
                    type: 'string',
                    filter: {
                        filterType: 'string',
                        name: 'AccountId',
                        filterField: 'AccountId',
                    },
                },
                {
                    id: 'RequestedById',
                    header: 'Requested By',
                    noSort: true,
                    cellRenderer: (item) => {
                        let name = 'System';
                        if (item.RequestedById) {
                            const user = users?.find((data) => data.Id === item.RequestedById);
                            if (user) {
                                name = user.FirstName + ' ' + user.LastName;
                            }
                        }
                        return <>{name}</>;
                    },
                    filter: {
                        filterType: 'string',
                        name: 'RequestedById',
                        filterField: 'RequestedById',
                    },
                },

                {
                    id: 'Description',
                    header: 'Description',
                    noSort: true,
                    defaultFixed: true,
                    noRemove: true,
                    type: 'string',
                    accessor: 'Description',
                    cellRenderer: (item) => item.Description,
                    sortField: 'Description',
                    filter: {
                        name: 'Description',
                        filterType: 'string',
                        filterField: 'Description',
                    },
                },
                {
                    id: 'CloudPlatform',
                    accessor: (item) => (item.ResourceId?.CloudPlatform === '0' ? 'AWS' : 'Azure'),
                    header: 'Platform',
                    type: 'string',
                    sortField: 'CloudPlatform',
                    allowGrouping: true,
                    cellRenderer: (item) => {
                        let platform = 'AWS';
                        if (item.ResourceId?.CloudPlatform === '1') {
                            platform = 'Azure';
                        }
                        return <>{platform}</>;
                    },
                    filter: {
                        filterType: 'string',
                        name: 'CloudPlatform',
                        filterField: 'ResourceId.CloudPlatform',
                    },
                },
                {
                    id: 'ResourceId',
                    header: 'ResourceId',
                    type: 'string',
                    sortField: 'ResourceId.Id',
                    cellRenderer: (item) => item.ResourceId?.Id,
                    accessor: (item) => item.ResourceId?.Id,
                    filter: {
                        name: 'ResourceId',
                        filterType: 'string',
                        filterField: 'ResourceId.Id',
                    },
                },
                {
                    id: 'ResourceType',
                    header: 'ResourceType',
                    type: 'string',
                    sortField: 'ResourceId.ResourceType',
                    cellRenderer: (item) => item?.ResourceId?.ResourceType,
                    accessor: (item) => item?.ResourceId!.ResourceType,
                    filter: {
                        name: 'ResourceType',
                        filterType: 'string',
                        filterField: 'ResourceId.ResourceType',
                    },
                },
            ] as ColumnConfig<ResourceChange>[],
        [users]
    );

    const filterValueProvider = useMemo(() => {
        return {
            getValueProvider: (field: QueryField) => {
                if (field.Field === 'RequestedById') {
                    return model.getUserFilterOptions(users);
                }

                let result: undefined | ((filter: string, max: number) => Promise<any>) = undefined;
                result = async (filter: string, max: number = 100) => {
                    const qb = queryBuilder<Record<string, string>>();

                    const queryResult = await qb
                        .take(max)
                        .select((b) => ({
                            value: { Operation: 'values', Operands: [{ Field: field.Field }, { Value: filter }] } as unknown as string,
                            count: b.count(),
                        }))
                        .execute(postResourceChangedQueryResourceChangeLog);

                    const comparer = new Intl.Collator(undefined, { sensitivity: 'base' }).compare;
                    let sorted = queryResult.Results?.map((r) => r.value).sort(comparer);
                    let distinct = new Set(sorted);
                    return Array.from(distinct);
                };
                return result;
            },
        } as IValueProviderFactory;
    }, [users]);

    const defaultState: DataGridState = {
        sort: [{ Direction: 'Desc', Expr: { Field: 'TimeStamp' } }],
        filters: [],
        columns: [
            { id: `AccountId`, width: 140, fixed: true },
            { id: `Description`, width: 350, fixed: true },
            { id: `ResourceType`, width: 180, fixed: true },
            { id: `ResourceId`, width: 300, fixed: true },
            { id: `TimeStamp`, width: 170, fixed: true },
            { id: `RequestedById`, width: 200, fixed: true },
            { id: 'Status', width: 100, fixed: true },
            { id: `CloudPlatform`, width: 100, fixed: true },
        ],
    };

    return model.isLoading.value ? (
        <></>
    ) : users && model.datasource ? (
        <PaneledPage>
            <PagePanel size="fill" padded={!noPadding}>
                <PanelBody noPadding style={{ padding: noPadding ? '24px' : undefined }}>
                    <DataGrid
                        dataSource={model.datasource!}
                        state={defaultState}
                        columns={dataGridColumns}
                        onModelLoaded={handleModelLoaded}
                        filterValueProvider={filterValueProvider}
                        showRefresh
                        onRowClick={onRowClick}
                    />
                </PanelBody>
            </PagePanel>
            {resourceSelected === undefined ? null : (
                <>
                    <Divider orientation="vertical" />
                    <PagePanel size="md" style={{ overflow: 'auto', backgroundColor: '#fff' }}>
                        <Group position="apart">
                            <Title p="lg" order={3}>
                                Resource History Comparison
                            </Title>
                            <CloseButton mr="lg" onClick={() => closeSidePanel()} />
                        </Group>
                        <ChangeDetails resourceSelected={resourceSelected} users={users} />
                        <Divider />
                        <TagsComparisonTable resourceSelected={resourceSelected} />
                    </PagePanel>
                </>
            )}
        </PaneledPage>
    ) : (
        <></>
    );
}

function TagsComparisonTable({ resourceSelected }: { resourceSelected: ResourceChange }) {
    return (
        <>
            <div style={{ display: 'flex' }}>
                <Box style={{ flex: 1, flexDirection: 'column', alignItems: 'center', width: '50%' }}>
                    <TagList tagsString={resourceSelected.OriginalState!} listTitle={'Original'} />
                </Box>
                <Divider orientation="vertical" />
                <div style={{ flex: 1, flexDirection: 'column', alignItems: 'center', width: '50%' }}>
                    <TagList tagsString={resourceSelected.UpdatedState!} listTitle={'Updated'} />
                </div>
            </div>
        </>
    );
}

function TagList({ tagsString, listTitle }: { tagsString: string; listTitle: string }) {
    const theme = useMantineTheme();
    return (
        <div>
            <Box px="md">
                <h3 style={{ textAlign: 'center' }}>{listTitle}</h3>
            </Box>
            <Table>
                <thead style={{ fontSize: 12, backgroundColor: theme.colors.gray[3] as CustomColors }}>
                    <tr>
                        <th style={{ width: '50%' }}>Tag Key</th>
                        <th style={{ width: '50%' }}>Value</th>
                    </tr>
                </thead>
                <tbody>
                    {tagsString === null ? (
                        <></>
                    ) : (
                        Object.entries(JSON.parse(tagsString)).map(([key, value]) => {
                            return (
                                <tr key={key}>
                                    <td style={{ wordBreak: 'break-all' }}>
                                        <VisibleSpaces value={key} />
                                    </td>
                                    <td style={{ wordBreak: 'break-all' }}>{value === null ? <></> : <VisibleSpaces value={value as string} />}</td>
                                </tr>
                            );
                        })
                    )}
                </tbody>
            </Table>
        </div>
    );
}

function ChangeDetails({ resourceSelected, users }: { resourceSelected: ResourceChange; users: UserListItem[] | undefined }) {
    const jobService = useDi(JobService);
    const jobSelected = useMemo(() => new EventEmitter<undefined | Job>(undefined), []);
    const formatSvc = useDi(FormatService);
    const openJobDetails = useCallback(async () => {
        if (resourceSelected.JobId) {
            const job = (await jobService.getJobById(resourceSelected.JobId)) as Job;
            return jobSelected.emit(job);
        }
    }, [resourceSelected]);
    const selectedJob = useEventValue(jobSelected);
    return (
        <>
            <div>
                <Button onClick={openJobDetails} variant="outline" style={{ marginLeft: 20, marginBottom: 5 }}>
                    View Batch
                </Button>
                <Space h="md" />
                <Box p="md">
                    <Table>
                        <tbody style={{ border: '1px black', borderColor: 'black' }}>
                            <tr>
                                <td>User Initiated: </td>
                                <td>{getUserName(resourceSelected.RequestedById!, users)}</td>
                            </tr>
                            <tr>
                                <td>Status: </td>
                                <td>{resourceSelected.Status}</td>
                            </tr>
                            <tr>
                                <td>Time: </td>
                                <td>{formatSvc.toLocal(resourceSelected.TimeStamp)}</td>
                            </tr>
                            <tr>
                                <td>Resource Id: </td>
                                <td>{resourceSelected.ResourceId?.Id}</td>
                            </tr>
                            <tr>
                                <td>Resource Type: </td>
                                <td>{resourceSelected.ResourceId?.ResourceType}</td>
                            </tr>
                        </tbody>
                    </Table>
                </Box>
            </div>
            <ActivityDetailsOpener item={selectedJob} onClose={() => jobSelected.emit(undefined)} />
        </>
    );
}

function getUserName(userId: number | null | undefined, users: UserListItem[] | undefined) {
    if (userId !== null && userId !== undefined) {
        var user = users?.find((data) => data.Id == userId);
        if (user != undefined) {
            return user.FirstName + ' ' + user.LastName;
        }
    }
    return 'Company';
}
