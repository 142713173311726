import { useEffect, useState } from 'react';
import { Tabs, Group, Button, Title, Divider, Box, CloseButton, Text } from '@mantine/core';
import { getNewSubscriptionGetCompanyInfo } from '@apis/Customers';
import { Company, CompanyInfo } from '@apis/Customers/model';
import styled from '@emotion/styled';
import { useDi, useDiContainer } from '@root/Services/DI';
import { useEvent } from '@root/Services/EventEmitter';
import { useCompany } from '@root/Components/Router/CompanyContent';
import { MspService } from '@root/Services/MspService';
import { useAuthZValues } from '@root/Services/AuthorizationService';
import { FormatService } from '@root/Services/FormatService';
import { CompanyContextService } from '@root/Services/Customers/CompanyContext';
import { BillingInvoicesAdminPanelModel } from './BillingInvoicesAdminPanelModel';
import { confirmAction } from '@root/Components/CompanyInfo/Administration/ConfirmActionDialog';
import { AdminTaxRates } from '@root/Components/CompanyInfo/Administration/AdminTaxRates';

export interface BillingInvoicesPanelProps {
    toggleSidePanel: (showSidePanel: boolean) => void;
    parentCompany: Company;
    defaultTab?: string;
}

export const BillingInvoicesSidePanel = (props: BillingInvoicesPanelProps) => {
    const contextSvc = useDi(CompanyContextService);
    const [compInfo, setCompInfo] = useState<CompanyInfo>({} as CompanyInfo);
    const [hasChanges, setHasChanges] = useState<boolean>(false);
    const [isSubscriptionUpdated, setIsSubscriptionUpdated] = useState<boolean>(false);
    const [activeTab, setActiveTab] = useState<string | null>(props?.defaultTab ?? 'company');
    const { canManageOffers } = useAuthZValues({ canManageOffers: { MarketplaceOffers: 'Manage' } });
    const container = useDiContainer();
    const model = container.resolve(BillingInvoicesAdminPanelModel);
    useEvent(model.isModifiedChanged, (value) => setHasChanges(value));

    const company = useCompany();
    const mspSvc = useDi(MspService);
    const fmtSvc = useDi(FormatService);
    const parentCompany = contextSvc.parentCompany;
    const [isSupport, setIsSupport] = useState<boolean>(parentCompany?.Type === 'Support');
    const [isPlatformSupport, setIsPlatformSupport] = useState<boolean>(parentCompany?.Type === 'PlatformSupport');
    const companyType = company!.Type ?? 'Customer';
    const [isCustomer, setIsCustomer] = useState<boolean>(companyType === 'Customer');

    const refreshMspCompany = () => {
        mspSvc.notifyCompanyListChanged();
        loadCompanyInfo();
    };

    useEffect(() => {
        loadCompanyInfo();
    }, []);

    const loadCompanyInfo = () => {
        if (company) {
            getNewSubscriptionGetCompanyInfo()
                .then((info) => {
                    setCompInfo(info);
                })
                .catch((e) => {
                    //console.log(e);
                });
        }
    };

    const saveChanges = async () => {
        setHasChanges(false);
        model.saveChangesRequested.emit();
    };

    const resetChanges = () => {
        setHasChanges(false);
        model.discardChangesRequested.emit();
    };

    const handleTabChange = (tabName: string) => {
        setTimeout(() => window.dispatchEvent(new Event('resize')), 1);
        model.tabChanged.emit(tabName);
        if (tabName !== activeTab && hasChanges) {
            confirmTabChange(tabName);
        } else {
            setActiveTab(tabName);
        }
    };

    const confirmTabChange = (newTab: string) => {
        confirmAction({
            headerText: 'Change Tab',
            promptText: `You have unsaved changes. Are you sure you want to leave this tab?`,
            confirmButtonText: 'Leave This Tab',
            cancelButtonText: 'Stay On Tab',
            confirmAction: () => {
                resetChanges();
                setActiveTab(newTab);
            },
        });
    };

    return (
        <>
            <Box sx={{ display: 'flex', height: '100%', flexDirection: 'column', minHeight: 0 }}>
                <Group position="apart">
                    <Box px="lg" py="md">
                        <Title order={3}>{compInfo.CompanyName}</Title>
                        <Text size="sm">{fmtSvc.userFriendlyCamelCase(company?.Type ?? 'Customer')}</Text>
                    </Box>
                    <CloseButton
                        variant="transparent"
                        size="xl"
                        onClick={() => {
                            if (!hasChanges) {
                                props?.toggleSidePanel?.(false);
                            } else {
                                confirmAction({
                                    headerText: 'Close Panel',
                                    promptText: `You have unsaved changes. Are you sure you want to close?`,
                                    confirmButtonText: 'Discard Changes and Close',
                                    cancelButtonText: 'Save Changes',
                                    confirmAction: () => {
                                        props?.toggleSidePanel?.(false);
                                    },
                                });
                            }
                        }}
                    ></CloseButton>
                </Group>
                <Tabs
                    defaultValue="company"
                    value={activeTab}
                    onTabChange={handleTabChange}
                    sx={{ flex: 1, display: 'flex', height: '100%', flexDirection: 'column', minHeight: 0 }}
                >
                    <Tabs.List px="lg">
                        <Tabs.Tab value="placeholder1">PlaceHolder 1</Tabs.Tab>
                        <Tabs.Tab value="placeholder2">PlaceHolder 2</Tabs.Tab>
                        <Tabs.Tab value="placeholder3">PlaceHolder 3</Tabs.Tab>
                        <Tabs.Tab value="tax_rates">Tax Rates</Tabs.Tab>
                    </Tabs.List>
                    <PanelContainer>
                        <Tabs.Panel value="placeholder1">
                            <div>Placeholder</div>
                        </Tabs.Panel>

                        <Tabs.Panel value="placeholder2">
                            <div>Placeholder</div>
                        </Tabs.Panel>

                        <Tabs.Panel value="placeholder3">
                            <div>Placeholder</div>
                        </Tabs.Panel>

                        <Tabs.Panel value="tax_rates">
                            <AdminTaxRates model={model} companyInfo={compInfo} refreshMsp={refreshMspCompany}></AdminTaxRates>
                        </Tabs.Panel>
                    </PanelContainer>
                </Tabs>
                {hasChanges && (
                    <>
                        <Divider />
                        <Group px="xl" py="md" position="left">
                            <Button color="primary" onClick={saveChanges}>
                                Save
                            </Button>
                            <Button variant="light" onClick={resetChanges}>
                                Discard Changes
                            </Button>
                        </Group>
                    </>
                )}
            </Box>
        </>
    );
};

const PanelContainer = styled.div`
    height: 100%;
    flex: 1;
    min-height: 0;
    > div {
        height: 100%;
        overflow: auto;
    }
`;
