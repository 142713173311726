import { BillingInvoice, BillingInvoiceStatus, BillingInvoiceStatusProperty } from '@apis/Invoices/model';
import { Box, Button, Grid, Group, LoadingOverlay, Modal, Select, Space, Text, TextInput } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useDi, useDiComponent } from '@root/Services/DI';
import { FormatService } from '@root/Services/FormatService';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { DataColumnConfig } from '../DataGrid/Models';
import { DataGrid } from '../DataGrid';
import { getBillingInvoiceGetBillingInvoices, postBillingInvoiceUpdateBillingInvoiceStatus } from '@apis/Invoices';

export const BillingInvoicesGrid = () => {
    const [loading, setLoading] = useState(true);
    const [invoices, setInvoices] = useState<BillingInvoice[]>([]);
    const [selectedInvoice, setSelectedInvoice] = useState<BillingInvoice | null>(null);
    const [EditModalOpened, { open: openEdit, close: closeEdit }] = useDisclosure(false);
    const fmtSvc = useDi(FormatService);
    const DiContainer = useDiComponent();

    useEffect(() => {
        loadBillingInvoices();
    }, []);

    useEffect(() => {
        if (selectedInvoice !== null) {
            openEdit();
        }
    }, [selectedInvoice]);

    const loadBillingInvoices = async () => {
        setLoading(true);
        getBillingInvoiceGetBillingInvoices().then((response) => {
            setInvoices(response);
        });
        setLoading(false);
    };

    const onEditModalClose = useCallback(
        (didEdit: boolean) => {
            if (didEdit) {
                loadBillingInvoices();
            }
            closeEdit();
        },
        [closeEdit]
    );

    const columns = useMemo(() => {
        return [
            {
                id: 'Year',
                header: 'Year',
                accessor: 'Year',
                defaultWidth: 250,
                sortField: 'Year',
                type: 'number',
            },
            {
                id: 'Month',
                header: 'Month',
                accessor: 'Month',
                defaultWidth: 250,
                sortField: 'Month',
                type: 'number',
            },
            {
                id: 'Billingterm',
                header: 'Billing Term',
                accessor: 'Billingterm',
                defaultWidth: 250,
                sortField: 'Billingterm',
                type: 'string',
            },
            {
                id: 'Total',
                header: 'Total',
                accessor: 'Total',
                defaultWidth: 250,
                sortField: 'Total',
                type: 'number',
            },
            {
                id: 'CreatedAt',
                header: 'CreatedAt',
                accessor: 'CreatedAt',
                defaultWidth: 250,
                sortField: 'CreatedAt',
                type: 'string',
                cellRenderer: (row) => {
                    return row.CreatedAt ? fmtSvc.formatDate(fmtSvc.toLocalDate(row.CreatedAt)) : '';
                },
            },
            {
                id: 'Status',
                header: 'Status',
                accessor: 'Status',
                defaultWidth: 250,
                sortField: 'Status',
                type: 'number',
            },
        ] as DataColumnConfig<BillingInvoice>[];
    }, [invoices]);

    return (
        <>
            {loading && <LoadingOverlay visible={true} />}
            <Modal opened={EditModalOpened} closeOnClickOutside onClose={closeEdit} title="Edit Billing Invoice">
                <DiContainer>
                    <BillingInvoiceStatusForm onClose={onEditModalClose} billingInvoice={selectedInvoice!}></BillingInvoiceStatusForm>
                </DiContainer>
            </Modal>

            <Box>
                {invoices.length > 0 ? (
                    <div style={{ height: '525px', overflow: 'auto' }}>
                        <DataGrid
                            columns={columns}
                            dataSource={invoices!}
                            onRowClick={(row) => {
                                setSelectedInvoice(row);
                            }}
                        ></DataGrid>
                    </div>
                ) : (
                    <Text>No Invoices found</Text>
                )}
            </Box>
        </>
    );
};

export function BillingInvoiceStatusForm(props: { onClose: (didEdit: boolean) => void; billingInvoice: BillingInvoice }) {
    const [loading, setLoading] = useState(false);
    const [statusUpdated, setStatusUpdated] = useState(false);
    const [status, setStatus] = useState(props.billingInvoice.Status);
    const [comments, setComments] = useState('');
    const statusOptions = ['Draft', 'Finalized', 'Sent', 'Paid', 'Voided'];
    const handleSave = async (e: any) => {
        if (e) {
            e.preventDefault();
        }
        if (statusUpdated) {
            try {
                setLoading(true);
                const invoiceStatus: BillingInvoiceStatus = {
                    Comments: comments,
                    Status: status,
                    BillingInvoiceId: props.billingInvoice.Id,
                    Snapshot: props.billingInvoice.FinalizedSnapshot,
                };
                await postBillingInvoiceUpdateBillingInvoiceStatus(invoiceStatus);
                props.onClose(true);
            } finally {
                setLoading(false);
            }
        }
    };

    const handleStatusChange = (value: string) => {
        setStatus(value as BillingInvoiceStatusProperty);
        setStatusUpdated(true);
    };

    const handleCommentsChange = (e: any) => {
        setComments(e.target.value);
    };
    return (
        <>
            {loading && <LoadingOverlay visible={true} />}
            <div>
                <Grid>
                    <Grid.Col span={4}>
                        <Text>Month & Year</Text>
                    </Grid.Col>
                    <Grid.Col span={8}>
                        <Text>
                            {props.billingInvoice.Month} {props.billingInvoice.Year}
                        </Text>
                    </Grid.Col>
                    <Grid.Col span={4}>
                        <Text>Status</Text>
                    </Grid.Col>
                    <Grid.Col span={8}>
                        <Select
                            data={statusOptions.map((i) => {
                                return { value: i, label: i };
                            })}
                            value={status}
                            onChange={handleStatusChange}
                        ></Select>
                    </Grid.Col>
                    <Grid.Col span={4}>
                        <Text>Comments</Text>
                    </Grid.Col>
                    <Grid.Col span={8}>
                        <TextInput value={comments} onChange={handleCommentsChange}></TextInput>
                    </Grid.Col>
                </Grid>
                <Space h="md" />
                <Group position="right">
                    <Button onClick={() => props.onClose(false)} variant="outline">
                        Cancel
                    </Button>
                    <Button onClick={handleSave} disabled={!statusUpdated}>
                        Save
                    </Button>
                </Group>
                <Space h="md" />
            </div>
        </>
    );
}
