import { Box, Button, Grid, Group, LoadingOverlay, MultiSelect, NumberInput, Select, Space, Text, TextInput } from '@mantine/core';
import { useEffect, useState } from 'react';
import { DateRangePicker } from '../Picker/DateRangePicker';
import { DatePicker } from '@mantine/dates';
import { CompanyCloudProvider } from '@apis/Customers/model';
import { getCompanyGetCompanyCloudProviders } from '@apis/Customers';
import {
    getBillingInvoiceGetCompanyBillingTaxCode,
    postBillingInvoiceSaveBillingTaxCode,
    postBillingInvoiceSaveCompanyBillingTaxCode,
} from '@apis/Invoices';
import { BillingTaxCode, CompanyBillingTaxCode } from '@apis/Invoices/model';

export function CompanyTaxCodeForm(props: { onClose: (didAdd: boolean) => void; customerTaxCodeId?: number }) {
    const [loading, setLoading] = useState(false);
    const [formIsValid, setFormIsValid] = useState<boolean>(false);
    const [jurisdiction, setJurisdiction] = useState<string>();
    const [rate, setRate] = useState<number>();
    const [code, setCode] = useState<string>();
    const [effectiveFrom, setEffectiveFrom] = useState<Date>();
    const [effectiveTo, setEffectiveTo] = useState<Date>();
    const [cloudProviderId, setCloudProviderId] = useState<number>();
    const [accounts, setAccounts] = useState([] as { value: number; label: string }[]);
    const [hasChanges, setHasChanges] = useState<boolean>(false);
    const [billingTaxCodeId, setBillingTaxCodeId] = useState<number>();
    const [customerTaxCodeId, setCustomerTaxCodeId] = useState<number>();
    const [companyId, setCompanyId] = useState<number>();

    useEffect(() => {
        setLoading(true);
        (async () => {
            const accounts = await getCompanyGetCompanyCloudProviders();
            setAccounts(accounts.map((i) => ({ value: i!.Id!, label: i!.Name! })));
            if (props.customerTaxCodeId) {
                const taxCode = await getBillingInvoiceGetCompanyBillingTaxCode({ id: props.customerTaxCodeId });
                setJurisdiction(taxCode.TaxCode?.Jurisdiction!);
                setRate(taxCode.TaxCode?.Rate!);
                setCode(taxCode.TaxCode?.Code!);
                setEffectiveFrom(new Date(taxCode.TaxCode?.EffectiveFrom!));
                setEffectiveTo(new Date(taxCode.TaxCode?.EffectiveTo!));
                setCloudProviderId(taxCode.CompanyCloudProviderId!);
                setBillingTaxCodeId(taxCode.TaxCode?.Id!);
                setCustomerTaxCodeId(taxCode.Id!);
                setCompanyId(taxCode.CompanyId!);
            }
        })();
        setLoading(false);
    }, []);
    const handleSave = async (e: any) => {
        if (e) {
            e.preventDefault();
        }

        if (hasChanges) {
            try {
                setLoading(true);
                const taxCode: BillingTaxCode = {
                    Jurisdiction: jurisdiction!,
                    Rate: rate!,
                    Code: code!,
                    EffectiveFrom: effectiveFrom?.toISOString(),
                    EffectiveTo: effectiveTo?.toISOString(),
                    Id: billingTaxCodeId ?? undefined,
                };
                const billingTaxCodeResponse = await postBillingInvoiceSaveBillingTaxCode(taxCode);
                const companyTaxCode: CompanyBillingTaxCode = {
                    CompanyCloudProviderId: cloudProviderId!,
                    TaxCodeId: billingTaxCodeResponse.Id!,
                    TaxCode: billingTaxCodeResponse,
                    CompanyId: companyId ?? undefined,
                    Id: customerTaxCodeId ?? undefined,
                };
                await postBillingInvoiceSaveCompanyBillingTaxCode(companyTaxCode);
                props.onClose(true);
            } finally {
                setLoading(false);
            }
        }
    };

    useEffect(() => {
        setFormIsValid(
            hasChanges &&
                cloudProviderId !== undefined &&
                jurisdiction !== undefined &&
                rate !== undefined &&
                code !== undefined &&
                effectiveFrom !== undefined &&
                effectiveTo !== undefined
        );
    }, [cloudProviderId, jurisdiction, rate, effectiveFrom, effectiveTo, code]);

    const handleAccountName = (e: any) => {
        if (cloudProviderId === undefined || e.currentTarget.value !== cloudProviderId?.toString()) {
            setCloudProviderId(parseInt(e, 10));
            setHasChanges(true);
        }
    };

    const handleJurisdictionChange = (e: any) => {
        if (e.currentTarget.value !== jurisdiction) {
            setJurisdiction(e.currentTarget.value);
            setHasChanges(true);
        }
    };

    const handleRateChange = (e: any) => {
        if (parseInt(e.currentTarget.value, 10) !== rate) {
            setRate(parseInt(e.currentTarget.value, 10));
            setHasChanges(true);
        }
    };

    const handleEffectiveFromDateChange = (value: Date) => {
        if ((effectiveTo === undefined || value < effectiveTo) && value !== effectiveFrom) {
            setEffectiveFrom(value);
        }
    };

    const handleEffectiveToDateChange = (value: Date) => {
        if ((effectiveFrom === undefined || value > effectiveFrom) && value !== effectiveTo) {
            setEffectiveTo(value);
        }
    };

    const handleCodeChange = (e: any) => {
        if (e.currentTarget.value !== code) {
            setCode(e.currentTarget.value);
            setHasChanges(true);
        }
    };
    return (
        <>
            {loading && <LoadingOverlay visible={true} />}
            <div>
                <Space h="md" />
                <Grid>
                    <Grid.Col span={4}>
                        <Text>Account Name</Text>
                    </Grid.Col>
                    <Grid.Col span={8}>
                        <Select
                            data={accounts.map((i) => {
                                return { value: i.value.toString(), label: i.label };
                            })}
                            value={cloudProviderId?.toString()}
                            onChange={handleAccountName}
                        ></Select>
                    </Grid.Col>
                    <Grid.Col span={4}>
                        <Text>Jurisdiction</Text>
                    </Grid.Col>
                    <Grid.Col span={8}>
                        <TextInput value={jurisdiction} onChange={handleJurisdictionChange}></TextInput>
                    </Grid.Col>
                    <Grid.Col span={4}>
                        <Text>Rate</Text>
                    </Grid.Col>
                    <Grid.Col span={8}>
                        <TextInput value={rate?.toString()} onChange={handleRateChange}></TextInput>
                    </Grid.Col>
                    <Grid.Col span={4}>
                        <Text>Code</Text>
                    </Grid.Col>
                    <Grid.Col span={8}>
                        <TextInput value={code} onChange={handleCodeChange}></TextInput>
                    </Grid.Col>
                    <Grid.Col span={4}>
                        <Text>Effective From</Text>
                    </Grid.Col>
                    <Grid.Col span={8}>
                        <DatePicker value={effectiveFrom} onChange={handleEffectiveFromDateChange}></DatePicker>
                    </Grid.Col>
                    <Grid.Col span={4}>
                        <Text>Effective To</Text>
                    </Grid.Col>
                    <Grid.Col span={8}>
                        <DatePicker value={effectiveTo} onChange={handleEffectiveToDateChange}></DatePicker>
                    </Grid.Col>
                </Grid>
                <Space h="md" />
                <Group position="right">
                    <Button onClick={() => props.onClose(false)} variant="outline">
                        Cancel
                    </Button>
                    <Button onClick={handleSave} disabled={!formIsValid}>
                        Save
                    </Button>
                </Group>
                <Space h="md" />
            </div>
        </>
    );
}
