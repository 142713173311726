import { Box, Card, Divider, Group, MantineColor, Stack, Text, useMantineTheme } from '@mantine/core';
import { BarCustomLayerProps, BarLayer, BarLegendProps, ComputedBarDatum, ComputedDatum, ResponsiveBar } from '@nivo/bar';
import { ScaleSpec } from '@nivo/scales';
import { DatumValue, getValueFormatter } from '@nivo/core';
import { OrdinalColorScaleConfigCustomFunction } from '@nivo/colors';
import { MouseEventHandler, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
    axisLabelLayer,
    chartColors,
    ChartMargin,
    createEdgeLineLayer,
    EdgeLineConfig,
    getMetricAxisRange,
    getPivotedPlotRowMetricRange,
    IChartReaggConfig,
    IPivotedPlotData,
    IPivotedPlotDataItem,
    IPlotFieldDescriptor,
    IPlotStats,
    transformBarToPlotData,
    transformBarToVisualPlotData,
    withContainerDimensions,
} from './Common';
import { StandardChartProps } from './Models';
import { line, curveMonotoneX } from 'd3-shape';
import { AnyScale } from '@nivo/scales';
import { EventEmitter, useEventValue } from '@root/Services/EventEmitter';
import { ChartWrapper } from './Design';
import { useDi } from '@root/Services/DI';
import { FormatService, INamedFormatter, NamedFormats, useFmtSvc } from '@root/Services/FormatService';
import { useId } from '@root/Services/IdGen';
import { FlyoverHost, IFlyoverRequest, useFlyoverModel } from '../Picker/Flyover';
import { useEllipsisTextClass } from '@root/Design/Text';

export type BarChartProps<T> = CustomBarChartProps<T> | (UserConfiguredBarChartProps<T> & { mode: 'user-configured' });
export function BarChart<T extends Record<string, string | number>>(props: BarChartProps<T>) {
    if (!('mode' in props) || props.mode !== 'user-configured') {
        return <CustomBarChart {...(props as CustomBarChartProps<T>)} />;
    } else {
        return <UserConfiguredBarChart {...(props as UserConfiguredBarChartProps<T>)} />;
    }
}

// #region Custom Bar Chart
export function CustomBarChart<T extends Record<string, string | number>>(props: CustomBarChartProps<T>) {
    const bindData = useMemo(() => transformBarData(props), [props.data, props.groups, props.values]);
    const orientation = props.settings?.orientation;
    const isHorz = orientation === 'Horizontal';
    const format = props.settings?.format;
    const formatter = getValueFormatter(
        format === 'money'
            ? '>-$,.2f'
            : format === 'money-whole'
            ? '>-$,.0f'
            : format === 'percent'
            ? '>-.0%'
            : format === 'integer'
            ? '>-.0f'
            : '>-,'
    );
    const fmtSvc = useDi(FormatService);
    const xFormat = props.settings?.xFormat;
    const xFormatter =
        xFormat === 'date'
            ? (value: string | number) =>
                  fmtSvc.formatDate(fmtSvc.toLocalDate(typeof value === 'number' ? value : /^\d+$/.test(value ?? '') ? parseInt(value) : value))
            : (value: string | number) => value;
    const stacked = props.groups.length > 1 || props.settings?.stacked === true;
    const displayEvent = useMemo(() => new EventEmitter<ComputedDatum<DataType> | undefined>(undefined), []);

    const layers: BarLayer<DataType>[] = ['grid', 'axes', ZeroLine];
    if (props.settings?.showTrend) {
        layers.push(TrendLayer);
    }
    layers.push('bars', 'markers', 'legends', 'annotations');
    const labelModel = props.settings?.enableLabel === undefined ? true : props.settings?.enableLabel;
    const hoverLabel = props.settings?.enableLabel === 'on-hover';
    if (labelModel === true) {
        layers.push(({ bars }: { bars: ComputedBarDatum<DataType>[] }) => {
            return (
                <g>
                    {bars.map(({ width, height, y, x, data, index, ...others }) => {
                        const textW = data.formattedValue.length * 5;
                        if ((isHorz && (width < 20 || textW > height)) || (!isHorz && (height < 15 || textW > width))) return null;
                        const translate =
                            stacked && isHorz
                                ? `translate(${x + width / 2}, ${y + height / 2})`
                                : stacked && !isHorz
                                ? `translate(${x + width / 2}, ${y + height / 2})`
                                : isHorz
                                ? `translate(${width + 4}, ${y + height / 2})`
                                : `translate(${width / 2 + x}, ${y - 8})`;
                        const textAnchor = isHorz && !stacked ? 'start' : 'middle';
                        return (
                            <text
                                style={{ pointerEvents: 'none' }}
                                key={index}
                                fontWeight="bolder"
                                textAnchor={textAnchor}
                                fontSize=".8em"
                                dominantBaseline="central"
                                transform={translate}
                            >
                                {formatter(data.value)}
                            </text>
                        );
                    })}
                </g>
            );
        });
    }
    if (hoverLabel) {
        layers.push(createDynamicLabelLayer(displayEvent, formatter));
    }
    const onMouseEnter = useCallback(
        (datum: ComputedDatum<DataType>, evt: React.MouseEvent<SVGRectElement, MouseEvent>) => {
            displayEvent.emit(datum);
            if (props.settings?.onBarClick) {
                evt.currentTarget.style.cursor = 'pointer';
                evt.currentTarget.style.filter = 'brightness(1.2)';
            }
        },
        [props.settings?.onBarClick]
    );
    const onMouseLeave = useCallback(
        (_: ComputedDatum<DataType>, evt: React.MouseEvent<SVGRectElement, MouseEvent>) => {
            displayEvent.emit(undefined);
            if (props.settings?.onBarClick) {
                evt.currentTarget.style.filter = '';
            }
        },
        [props.settings?.onBarClick]
    );
    const onClick = useCallback(
        (datum: ComputedDatum<DataType>) => {
            props.settings?.onBarClick?.(datum.data.__orig);
        },
        [props.settings?.onBarClick]
    );

    const responsiveBar = (
        <ResponsiveBar
            data={bindData.data}
            defs={[{ id: 'label-bg' }]}
            indexBy="id"
            keys={bindData.keys}
            colors={props.settings?.chartColors ?? chartColors}
            layout={props.settings?.orientation === 'Horizontal' ? 'horizontal' : 'vertical'}
            margin={{ left: 60, bottom: 60, ...props.settings?.margin }}
            axisBottom={{
                format: (value) => (props.settings?.orientation === 'Horizontal' ? formatter(value) : xFormatter(value)),
                tickRotation: props.settings?.labelAngle || -50,
                tickSize: props.settings?.gridLines ? 4 : 0,
            }}
            onClick={onClick}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            enableLabel={false}
            enableGridY={props.settings?.gridLines ?? false}
            axisLeft={{
                format: (value) =>
                    props.settings?.orientation === 'Horizontal'
                        ? xFormatter(value)
                        : format === 'integer'
                        ? value === Math.floor(value) && value
                        : formatter(value),
                tickSize: !props.settings?.gridLines ? 0 : 4,
            }}
            tooltip={hoverLabel ? () => <></> : undefined}
            tooltipLabel={(d) => (props.groups.length > 1 ? d.id?.toString() : xFormatter(d.data.id?.toString())) as string}
            padding={props.settings?.barPadding}
            borderRadius={4}
            valueFormat={formatter}
            legends={props.settings?.legend}
            layers={layers}
        />
    );

    if (props.settings?.noWrapper) {
        return responsiveBar;
    } else {
        return <ChartWrapper className="chartWrapper">{responsiveBar}</ChartWrapper>;
    }
}

export interface CustomBarChartSettings {
    orientation?: 'Vertical' | 'Horizontal';
    margin?: ChartMargin;
    hideYAxis?: boolean;
    hideXAxis?: boolean;
    labelAngle?: number;
    gridLines?: boolean;
    format?: 'money' | 'money-whole' | 'percent' | 'integer';
    xFormat?: 'date';
    topN?: number;
    sort?: 'ascending' | 'descending' | 'none';
    sortBy?: 'value' | 'group';
    /**
     * Number between zero and 1 representing the distance between each bar, zero means bars will touch, 1 means bars will have no width
     */
    barPadding?: number;
    showTrend?: boolean;
    fractions?: boolean;
    enableLabel?: boolean | 'on-hover';
    chartColors?: string[] | undefined;
    legend?: BarLegendProps[] | undefined;
    stacked?: boolean;
    onBarClick?: (data: DataType) => void;
    noWrapper?: boolean;
}
interface CustomBarChartProps<T> extends StandardChartProps<string | number, T> {
    settings?: CustomBarChartSettings;
}
type DataType = { __total: number; __orig: any; id: string | number } & Omit<Record<string, string | number>, '__total' | '__orig'>;
function transformBarData<T extends Record<string, string | number>>(props: CustomBarChartProps<T>) {
    const dataLookup = new Map<string | number, DataType>();
    const data: DataType[] = [];
    const index = props.groups[0];
    const extraKeys = props.groups.length > 1 ? props.groups.slice(1) : undefined;
    const keys = new Set<string>();
    if (index) {
        let totalValue = 0;
        if (props.settings?.format === 'percent') {
            props.data.forEach((item) => {
                totalValue += item[props.values[0]] as number;
            });
        }

        for (const item of props.data) {
            const indexKey = item[index];
            let resultItem = dataLookup.get(indexKey);

            if (!resultItem) {
                dataLookup.set(indexKey, (resultItem = { id: indexKey, __total: 0, __orig: item as any }));
                data.push(resultItem);
            }

            const value = item[props.values[0]];

            if (extraKeys) {
                for (const key of extraKeys) {
                    const group = `${item[key]}`;
                    keys.add(group);
                    resultItem[group] = value;
                    resultItem.__total += value as number;
                }
            } else {
                resultItem.value = props.settings?.format === 'percent' ? (value as number) / totalValue : value;
                resultItem.__total += value as number;
            }
        }
    }

    if (props.settings?.sort !== 'none') {
        const modifier = props.settings?.sort === 'ascending' ? -1 : 1;
        if (props.settings?.sortBy === 'group') {
            data.sort((a, b) => {
                return typeof b.id === 'string'
                    ? (b.id as string).localeCompare(a.id as string, undefined, { sensitivity: 'base' }) * modifier
                    : (b.id as number) - (a.id as number) * modifier;
            });
        } else {
            data.sort((a, b) => {
                return (b.__total - a.__total) * modifier;
            });
        }
    }

    if (props.settings?.topN) {
        data.splice(props.settings.topN);
    }

    if (props.settings?.orientation === 'Horizontal') {
        data.reverse();
    }

    return { data, keys: keys.size > 0 ? [...keys] : undefined };
}

function ZeroLine({
    bars,
    yScale,
    xScale,
    innerWidth,
}: {
    bars: ComputedBarDatum<DataType>[];
    yScale: AnyScale;
    xScale: AnyScale;
    innerWidth: number;
}) {
    const theme = useMantineTheme();
    const { hasNeg, hasPos } = bars.reduce(
        (res, b) => {
            const hasNeg = res.hasNeg || (b.data.value ?? 0) < 0;
            const hasPos = res.hasPos || (b.data.value ?? 0) > 0;
            return { hasNeg, hasPos };
        },
        { hasNeg: false, hasPos: false }
    );
    const showZero = hasNeg && hasPos;
    const points = showZero
        ? [
              { x: 0, y: yScale(0) },
              { x: innerWidth, y: yScale(0) },
          ]
        : [];
    const lineGenerator = line<{ x: number; y: number }>()
        .x((b) => b.x)
        .y((b) => b.y);

    return !showZero ? null : <path d={lineGenerator(points) ?? ''} fill="none" stroke={theme.colors.gray[4]} strokeWidth={1}></path>;
}

function createDynamicLabelLayer(displayEvent: EventEmitter<ComputedDatum<DataType> | undefined>, formatter: (value: unknown) => string) {
    return function DynamicLabelLayer({ bars }: { bars: ComputedBarDatum<DataType>[] }) {
        const theme = useMantineTheme();
        const datum = useEventValue(displayEvent);
        const bar = bars.find((b) => b.data === datum);
        if (!bar) return null;
        const { x, y, width, height, data } = bar;
        const translate = `translate(${x + width / 2}, ${y - 10})`;

        return (
            <g>
                <text transform={translate} fontWeight="bolder" textAnchor="middle" dominantBaseline="central" fill={theme.colors.gray[9]}>
                    {formatter(data.value)}
                </text>
            </g>
        );
    };
}

function TrendLayer({ bars }: { bars: ComputedBarDatum<DataType>[] }) {
    try {
        const theme = useMantineTheme();
        const uniqueBars = [
            ...bars.reduce((res, b) => {
                let item = res.get(b.x);
                if (!item) {
                    res.set(b.x, (item = b));
                }
                item.y = Math.min(item.y, b.y);
                item.height = Math.max(item.height, b.height);
                item.data.value = item === b ? item.data.value ?? 0 : (item.data.value ?? 0) + (b.data.value ?? 0);
                return res;
            }, new Map<number, ComputedBarDatum<DataType>>()),
        ].map(([, b]) => b as ComputedBarDatum<DataType>);
        const bounds = uniqueBars.reduce(
            (res, b) => {
                return {
                    minX: Math.min(res.minX, b.x + b.width / 2),
                    maxX: Math.max(res.maxX, b.x + b.width / 2),
                    minY: Math.min(res.minY, b.y),
                    maxY: Math.max(res.maxY, b.y + b.height),
                };
            },
            { minX: Infinity, maxX: -Infinity, minY: 0, maxY: 0 }
        );
        const width = bounds.maxX - bounds.minX;
        const dist = width / (uniqueBars.length - 1);
        const yValues = uniqueBars.map((b) => ((b.data.value ?? 0) < 0 ? b.y + b.height : b.y));
        const movingAverage: number[] = [];
        for (let i = 1; i < yValues.length - 1; i++) {
            movingAverage.push(yValues.slice(i - 1, i + 2).reduce((r, n) => r + n, 0) / 3);
        }
        const adjustedMovingAvg = [yValues[0], ...movingAverage, yValues[yValues.length - 1]];
        const scaledPoints = adjustedMovingAvg.map((y, i) => {
            const x = i * dist;
            return { x: bounds.minX + x, y: y + bounds.minY };
        });
        const lineGenerator = line<{ x: number; y: number }>()
            .curve(curveMonotoneX)
            .x((b) => b.x)
            .y((b) => b.y);
        return (
            <path
                d={lineGenerator(scaledPoints) ?? ''}
                fill="none"
                stroke={theme.fn.rgba(theme.colors.warning[4], 0.5)}
                strokeWidth={3}
                strokeDasharray="0 4 0"
            ></path>
        );
    } catch {
        return null;
    }
}
// #endregion

// #region User Configured Bar Chart
export interface UserConfiguredBarChartSettings {
    orientation?: 'Vertical' | 'Horizontal';
    margin?: ChartMargin;
    labelAngle?: number;
    gridLines?: boolean;
    format?: NamedFormats;
    metricLabel?: string;
    xFormat?: NamedFormats;
    reaggOptions?: IChartReaggConfig;
    maxStackItems?: number;
    onBarClick?: (data: DataType) => void;
    descriptors?: IPlotFieldDescriptor[];
}
interface UserConfiguredBarChartProps<T> extends StandardChartProps<string | number, T> {
    settings?: UserConfiguredBarChartSettings;
}

export const UserConfiguredBarChart = withContainerDimensions(function UserConfiguredBarChart<T extends Record<string, string | number>>(
    props: UserConfiguredBarChartProps<T> & { width: number; height: number }
) {
    const { settings, groups, values, data, width, height } = props;
    const defaultSettings = getUserConfiguredBarSettingsDefaults(settings ?? {});
    const { descriptors, format, gridLines, labelAngle, metricLabel, orientation, reaggOptions, xFormat, maxStackItems } = defaultSettings;
    const fmtSvc = useDi(FormatService);
    const stacked = groups.length > 1;

    const { plotData: fullPlotData, dataStats } = useMemo(() => {
        const barField = groups[0] as string;
        const stackFields = groups.slice(1) as string[];
        const metricField = values[0] as string;
        return transformBarToPlotData({ data, descriptors, barField, stackFields, metricField });
    }, [props.data, JSON.stringify([groups, values])]);

    const isHorz = orientation === 'Horizontal';
    const yFmt = fmtSvc.getFormatter(format, 'number');
    const formatter = yFmt.format;
    const xFmt = fmtSvc.getFormatter(xFormat, 'string');
    const xFormatter = <T extends DatumValue>(value: T) => (value === '\0' ? reaggOptions?.otherLabel ?? 'Other' : xFmt.format(value));

    const { margin: reqMargin } = defaultSettings;
    const baseVisualDataDeps = [xFmt, yFmt, dataStats, width, height, orientation, maxStackItems, labelAngle, metricLabel];
    const { margin, plotData } = useMemo(() => {
        const baseSettings = { margin: reqMargin, width, height, fullPlotData, fullDataStats: dataStats, orientation };
        const extendedSettings = { maxStackItems, xFmt, yFmt, labelAngleDeg: labelAngle, metricLabel, reaggOptions };
        return transformBarToVisualPlotData({ ...baseSettings, ...extendedSettings });
    }, [...baseVisualDataDeps, JSON.stringify([reaggOptions, reqMargin])]);

    const colorByBar = useCallback((item: ComputedDatum<IPivotedPlotDataItem>) => item.data.color, []);
    const colorByStack = useCallback(
        (item: ComputedDatum<IPivotedPlotDataItem>) => plotData.columnInfo[item.id as keyof typeof plotData.columnInfo].color,
        [plotData]
    );
    const color = (stacked ? colorByStack : colorByBar) as unknown as OrdinalColorScaleConfigCustomFunction<
        ComputedDatum<Record<string, string | number>>
    >;
    const barData = useMemo(
        () => (isHorz ? plotData.data.slice().reverse() : plotData.data) as unknown as Record<string, number | string>[],
        [plotData.data, isHorz]
    );
    const barKeys = stacked ? plotData.fields : (['total'] as string[]);

    const paddingPct = 0.1;
    const barHoverLayers = useBarTooltip({ dataStats, plotData, xFmt, yFmt, isHorz, paddingPct });
    const { barTooltipHostLayer, barHighlightLayer, barHoverBgLayer, barHoverInteractionLayer } = barHoverLayers;

    const metricLabelLayer = axisLabelLayer(metricLabel ?? '', isHorz ? 'x' : 'y', isHorz ? 'end' : 'start');

    const { min, max } = getPivotedPlotRowMetricRange(plotData);
    const yScale: ScaleSpec = { ...getMetricAxisRange(min, max), type: 'linear', clamp: true };
    const extraLines = useLinesLayer(plotData, dataStats, isHorz);
    const layers = [
        barTooltipHostLayer,
        barHoverBgLayer,
        'grid',
        extraLines,
        'axes',
        metricLabelLayer,
        'bars',
        barHoverInteractionLayer,
        barHighlightLayer,
    ] as BarLayer<any>[];

    return (
        <>
            <ResponsiveBar
                data={barData}
                indexBy="pivot"
                keys={barKeys}
                layers={layers}
                /*axisTop percents? */
                groupMode="stacked"
                colors={color}
                layout={isHorz ? 'horizontal' : 'vertical'}
                margin={margin}
                axisBottom={{
                    format: isHorz ? formatter : xFormatter,
                    tickRotation: labelAngle || -50,
                }}
                valueScale={yScale}
                enableGridX={isHorz ? gridLines : false}
                enableGridY={isHorz ? false : gridLines}
                axisLeft={{
                    format: isHorz ? xFormatter : formatter,
                }}
                tooltip={() => <></>}
                padding={paddingPct}
                borderRadius={0}
                valueFormat={formatter}
                enableLabel={false}
            />
        </>
    );
});

const getUserConfiguredBarSettingsDefaults = (settings: UserConfiguredBarChartSettings) => {
    return {
        gridLines: true,
        labelAngle: -50,
        descriptors: [],
        format: 'number',
        margin: { left: 60, bottom: 60, right: 0, top: 10 },
        maxStackItems: 15,
        orientation: 'Vertical' as const,
        ...settings,
        reaggOptions: { otherLabel: 'Other', ...settings.reaggOptions },
    };
};

function useLinesLayer(plotData: IPivotedPlotData, dataStats: IPlotStats, isHorz: boolean) {
    const { colors } = useMantineTheme();
    return useMemo(() => {
        const lineCfg: EdgeLineConfig[] = [];

        const [neg, pos] = plotData.data.reduce(([neg, pos], d) => [neg || d.total < 0, pos || d.total > 0], [false, false]);
        const hasMultiSign = neg && pos;
        if (hasMultiSign) {
            lineCfg.push({ type: isHorz ? 'zero-y-horz' : 'zero-y-vert', color: colors.warning[4] }, { type: 'bottom' }, { type: 'left' });
        }

        return createEdgeLineLayer(...lineCfg);
    }, [plotData, dataStats, isHorz]);
}

// #region Tooltips
type BarHoverEventData = { barIdx: number; stackId?: string } | undefined;
type BarHoverEvent = EventEmitter<BarHoverEventData>;
function useBarTooltip(props: Omit<IBarTooltipProps, 'bar' | 'stackId'>) {
    const { plotData, isHorz, paddingPct } = props;
    const hoverEvt = useMemo(() => new EventEmitter<BarHoverEventData>(undefined), [plotData, isHorz, paddingPct]);
    return useMemo(() => createBarHoverLayers(hoverEvt, props), [hoverEvt]);
}

function createBarHoverLayers(elementHovered: BarHoverEvent, tooltipProps: Omit<IBarTooltipProps, 'bar' | 'stackId'>) {
    const { plotData, paddingPct, isHorz } = tooltipProps;
    return { barTooltipHostLayer, barHoverInteractionLayer, barHighlightLayer, barHoverBgLayer };

    type LayerProps = BarCustomLayerProps<ComputedBarDatum<Record<string, string | number>>>;
    function createHoverable(dims: { x: number; y: number; width: number; height: number }, barIdx: number, stackId?: string) {
        return {
            containsPt: (pt: { x: number; y: number }) => {
                const { x, y, width, height } = dims;
                return pt.x >= x && pt.x <= x + width && pt.y >= y && pt.y <= y + height;
            },
            dims,
            barIdx,
            stackId,
        };
    }

    function useHoveredBar() {
        const [evt, setEvt] = useState<BarHoverEventData>();
        useEffect(() => {
            let lastEvt = elementHovered.value;
            const { dispose } = elementHovered.listen((evt: BarHoverEventData) => {
                if (evt !== lastEvt || evt?.barIdx !== lastEvt?.barIdx || evt?.stackId !== lastEvt?.stackId) {
                    setEvt(evt);
                }
                lastEvt = evt;
            });
            return dispose;
        }, [elementHovered]);
        return evt;
    }

    function useBarDimensions(layerProps: LayerProps) {
        const { innerHeight, innerWidth, bars } = layerProps;
        const hoverableStacks =
            plotData.fields.length < 1
                ? []
                : bars.map(({ data, x, y, width, height }) => createHoverable({ x, y, width, height }, data.data.index ?? 0, data.id?.toString()));
        const dimensionInfo = useMemo(() => {
            const barPts = bars.map((b) => (isHorz ? b.y : b.x));
            const dimPts = [...new Set(barPts)].sort((a, b) => a - b);
            const size = bars.reduce((sum, b) => sum + (isHorz ? b.height : b.width), 0) / bars.length;
            const length = isHorz ? innerWidth : innerHeight;
            const height = isHorz ? size : length;
            const width = isHorz ? length : size;
            const hoverableBars = dimPts
                .map((pt) => ({ x: isHorz ? 0 : pt, y: isHorz ? pt : 0, width, height }))
                .map((dims, i) => createHoverable(dims, i, undefined));
            const hoverables = [...hoverableStacks, ...hoverableBars];
            const padding = size * paddingPct;

            return { size, length, width, height, dimPts, padding, hoverables };
        }, [innerHeight, innerWidth, isHorz, paddingPct, plotData, JSON.stringify(hoverableStacks)]);

        return dimensionInfo;
    }

    function useBarHoverDimensions(layerProps: LayerProps) {
        const { size, length, padding, dimPts } = useBarDimensions(layerProps);

        const getBarDimensions = useCallback(
            (barIdx: number | undefined) => {
                if (typeof barIdx === 'number') {
                    const dimPt = dimPts[barIdx];
                    const paddedSize = size + padding * 2;
                    const width = isHorz ? length : paddedSize;
                    const height = isHorz ? paddedSize : length;
                    const x = isHorz ? 0 : dimPt - padding;
                    const y = isHorz ? dimPt - padding : 0;
                    return { x, y, width, height };
                } else {
                    return null;
                }
            },
            [size, length, padding, JSON.stringify(dimPts)]
        );

        return { getBarDimensions };
    }

    function barHoverInteractionLayer(props: LayerProps) {
        const { height, width, margin } = props;
        const { left: ml, top: mt } = margin;
        const { hoverables } = useBarDimensions(props);

        const handleMouseMove: MouseEventHandler<SVGRectElement> = useCallback(
            (evt) => {
                const { x: elX, y: elY } = evt.currentTarget.getBoundingClientRect();
                const { clientX: mseX, clientY: mseY } = evt;
                const pt = { x: mseX - elX - ml, y: mseY - elY - mt };
                const hoveredItem = hoverables.find(({ containsPt }) => containsPt(pt));
                elementHovered.emit(hoveredItem);
            },
            [plotData, elementHovered, hoverables]
        );

        const handleMouseLeave: MouseEventHandler<SVGRectElement> = useCallback(() => {
            const showingValue = elementHovered.value;
            setTimeout(() => {
                if (elementHovered.value === showingValue) {
                    elementHovered.emit(undefined);
                }
            }, 100);
        }, [elementHovered]);

        return (
            <g data-layer-type="barHoverInteractive">
                <rect
                    x={-ml}
                    y={-mt}
                    width={width}
                    height={height}
                    fill="transparent"
                    onMouseLeave={handleMouseLeave}
                    onMouseMove={handleMouseMove}
                ></rect>
            </g>
        );
    }
    function barHighlightLayer(props: LayerProps) {
        const { colors } = useMantineTheme();
        const { getBarDimensions } = useBarHoverDimensions(props);
        const bar = useHoveredBar();
        const { x, y, width, height } = getBarDimensions(bar?.barIdx) ?? {};
        const visible = typeof x === 'number';

        return (
            <g data-layer-type="barHoverHighlight">
                {!visible ? <></> : <rect {...{ x, y, width, height }} fill={`${colors.gray[0]}22`} style={{ pointerEvents: 'none' }}></rect>}
            </g>
        );
    }
    function barHoverBgLayer(props: LayerProps) {
        const { colors } = useMantineTheme();
        const { getBarDimensions } = useBarHoverDimensions(props);
        const bar = useHoveredBar();
        const { x, y, width, height } = getBarDimensions(bar?.barIdx) ?? {};
        const visible = typeof x === 'number';
        return (
            <g data-layer-type="barHoverBg">
                {!visible ? <></> : <rect {...{ x, y, width, height }} fill={`${colors.primary[2]}`} style={{ pointerEvents: 'none' }}></rect>}
            </g>
        );
    }
    function barTooltipHostLayer(props: LayerProps) {
        const { height, width, margin } = props;
        const { left: ml, top: mt, bottom: mb } = margin;
        const { getBarDimensions } = useBarHoverDimensions(props);
        const ref = useRef<SVGForeignObjectElement>(null);
        const tooltipId = useId(elementHovered)?.toString();
        const model = useFlyoverModel(tooltipId);
        const reqDefaults = useMemo(
            () =>
                ({
                    nonInteractive: true,
                    anchor: isHorz ? ['center-left', 'center-right'] : ['bottom-left', 'bottom-right'],
                } as IFlyoverRequest),
            [isHorz]
        );

        const { show, hide } = useMemo(() => {
            let shownEvtData: BarHoverEventData | null = null;
            return {
                show: (dimensions: Exclude<ReturnType<typeof getBarDimensions>, null>, evt: Exclude<BarHoverEventData, undefined>) => {
                    const { barIdx, stackId } = evt;
                    if (shownEvtData?.barIdx !== barIdx || shownEvtData?.stackId !== stackId) {
                        const { x: barX, y: barY, width: barW, height: barH } = dimensions;
                        const bar = plotData.data[barIdx];
                        const { x: hostX, y: hostY } = ref.current!.getBoundingClientRect();
                        const barSize = (isHorz ? barH! : barW!) / 2;
                        const hostHw = width / 2;
                        const hostHh = height / 2;
                        const x = hostX + hostHw;
                        const y = hostY + (isHorz ? hostHh : height - mb);
                        const offsetX = hostHw - 10;
                        const offsetY = isHorz ? -barY! + hostHh - barSize : 0;
                        const rendererProps = { bar, stackId, ...tooltipProps };

                        shownEvtData = evt;
                        model.provideFlyover({ ...reqDefaults, x, y, offsetX, offsetY, renderer: () => <BarTooltip {...rendererProps} /> });
                    }
                },
                hide: () => {
                    shownEvtData = null;
                    model.invalidateFlyover();
                },
            };
        }, [model, plotData]);

        const bar = useHoveredBar();
        useEffect(() => {
            const barDimensions = getBarDimensions(bar?.barIdx);
            if (!bar || !ref.current || !barDimensions) {
                hide();
            } else {
                show(barDimensions, bar);
            }
        }, [bar, getBarDimensions]);

        return (
            <g data-layer-type="barTooltipHost">
                <foreignObject ref={ref} x={-ml} y={-mt} width={width} height={height}>
                    <FlyoverHost routeBound flyoverKey={tooltipId} />
                </foreignObject>
            </g>
        );
    }
}

interface IBarTooltipProps {
    bar: IPivotedPlotDataItem;
    stackId?: string;
    dataStats: IPlotStats;
    plotData: IPivotedPlotData;
    paddingPct: number;
    isHorz?: boolean;
    xFmt: INamedFormatter;
    yFmt: INamedFormatter;
}
function BarTooltip({ bar, stackId, plotData, dataStats, xFmt, yFmt }: IBarTooltipProps) {
    const fmtSvc = useFmtSvc();
    const { colors } = useMantineTheme();

    const { label: barType, value: barName } = bar.pivotDescriptors[0] ?? { label: '', value: bar.pivot };
    const barPct = fmtSvc.formatPercent(bar.total / dataStats.totalY);
    const barAmt = bar.total;

    const stackInfo = !stackId ? null : plotData.columnInfo[stackId as keyof typeof plotData.columnInfo];
    const stackType = stackInfo?.descriptors[0]?.label;
    const stackName = stackInfo?.label;
    const stackAmt = !stackId ? null : (bar[stackId as keyof typeof bar] as number);
    const stackPct = typeof stackAmt !== 'number' || typeof barAmt !== 'number' ? null : fmtSvc.formatPercent(stackAmt / barAmt);
    const stackColor = stackInfo?.color;

    const ellipsisTxt = useEllipsisTextClass();
    const stackTextProps = { italic: !!stackInfo?.isOther, color: (stackInfo?.isOther ? colors.gray[6] : stackColor) as MantineColor };
    return (
        <Card shadow="md" p={0} withBorder sx={{ width: 275, opacity: 0.95 }}>
            <Stack spacing={0}>
                {!stackId ? null : (
                    <>
                        <Box p="md">
                            <Box>
                                <Text size="xs" color="dimmed">
                                    {stackType}
                                </Text>
                                <Text {...stackTextProps} className={ellipsisTxt} sx={{ maxWidth: '100%' }} size="sm">
                                    {stackName}
                                </Text>
                                <Divider size={2} color={stackColor as MantineColor} />
                                <Group position="apart" noWrap>
                                    {!stackPct ? null : (
                                        <>
                                            <Text color="dimmed" size="sm">
                                                {stackPct}
                                                <Text ml={4} component="span" color="dimmed" size="xs">
                                                    of bar
                                                </Text>
                                            </Text>
                                            <Text size="sm">{yFmt.format(stackAmt)}</Text>
                                        </>
                                    )}
                                </Group>
                            </Box>
                        </Box>
                        <Divider />
                    </>
                )}
                <Box p="md" sx={{ background: stackId ? colors.gray[2] : '' }}>
                    <Box>
                        {!barType ? null : (
                            <Text size="xs" color="dimmed">
                                {barType}
                            </Text>
                        )}
                        <Text className={ellipsisTxt} sx={{ maxWidth: '100%' }} size="sm">
                            {xFmt.format(barName)}
                        </Text>
                    </Box>
                    <Group position="apart" noWrap>
                        <Text color="dimmed" size="sm">
                            {barPct}
                            <Text ml={4} component="span" color="dimmed" size="xs">
                                of total
                            </Text>
                        </Text>
                        <Text size="sm">{yFmt.format(barAmt)}</Text>
                    </Group>
                </Box>
            </Stack>
        </Card>
    );
}
// #endregion

// #endregion
