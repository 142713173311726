import { UserDefinedNotificationThreshold } from '@apis/Notification/model';
import { BellRinging } from 'tabler-icons-react';

export function describeAlert(alert: UserDefinedNotificationThreshold) {
    return {
        icon: BellRinging,
        brief: '',
        description: '',
        type: 'Alert',
    };
}
