import { Query } from '@apis/Invoices/model';
import { QueryExpr, QueryResult } from '@apis/Resources';
import { dataFilterFacetFactory } from '@root/Components/Filter/FacetPickerInput';
import { DataFilterModel, DataFilterValueRenderer, FilterExpr, UtcDataFilterSingleDate } from '@root/Components/Filter/Filters';
import { useDi } from '@root/Services/DI';
import { FormatService } from '@root/Services/FormatService';
import { IInvoiceRollup } from '@root/Services/Invoices/InvoiceSchemaService';
import { queryBuilder } from '@root/Services/QueryExpr';
import { useCallback, useMemo } from 'react';

interface IInvoiceFacetItem {
    value: string;
    count?: number;
    total?: number;
}
export function useFilterValueProviders(datasource: <T>(query: Query) => Promise<QueryResult<T>>) {
    const fmtSvc = useDi(FormatService);
    const createFacetFilter = useCallback(() => {
        const itemLookup = new Map<string, IInvoiceFacetItem>();
        return dataFilterFacetFactory<IInvoiceFacetItem>({
            columns: [
                {
                    accessor: (v) => v.value,
                    type: 'string',
                    fill: true,
                    header: 'Options',
                },
                {
                    accessor: (v) => v.total,
                    type: 'number',
                    formatter: (v) => (typeof v === 'number' ? fmtSvc.formatMoneyNonZeroTwoDecimals(v) : <>&mdash;</>),
                    header: 'Cost',
                },
            ],
            valuesProvider: async (facet: QueryExpr) => {
                const results = await queryBuilder<IInvoiceRollup>()
                    .take(10000)
                    .select((b) => ({
                        value: b.fromExpr<string>(facet),
                        count: b.count(),
                        total: b.sum(b.model['lineItem/UnblendedCost']),
                    }))
                    .execute(datasource);

                const items = results?.Results ?? [];
                const values = items.map((item) => {
                    itemLookup.set(item.value, item);
                    return item.value;
                }, itemLookup);
                return values;
            },
            itemLookup: (value) => itemLookup.get(value) ?? { value },
        });
    }, []);

    const getOrAdd = useMemo(() => {
        const results = new Map<string, DataFilterValueRenderer>();
        return (field: string, valueRendererFactory: () => DataFilterValueRenderer) =>
            results.get(field) ?? results.set(field, valueRendererFactory()).get(field)!;
    }, []);

    return useCallback(
        (filterModel: FilterExpr, model: DataFilterModel) => {
            const field = filterModel.field;
            const operation = filterModel.operation;
            const type = filterModel.getFilterType();
            return type === 'string' && (operation === 'eq' || operation === 'ne')
                ? getOrAdd(field, createFacetFilter)
                : type === 'date'
                ? UtcDataFilterSingleDate
                : undefined;
        },
        [datasource]
    );
}
