import { UserDefinedNotificationTrigger } from '@apis/Notification/model';
import { Icon } from 'tabler-icons-react';
import { describeAlert } from './Alert';
import { describeSchedule } from './Schedule';

interface TriggerDescription {
    icon: Icon;
    brief: string;
    description: string;
    type: string;
}
export function describeTrigger(trigger: UserDefinedNotificationTrigger): TriggerDescription | null {
    switch (trigger.TriggerType) {
        case 'Schedule':
            return !trigger.Schedule ? null : describeSchedule(trigger.Schedule);
        case 'Threshold':
            return !trigger.Threshold ? null : describeAlert(trigger.Threshold);
        default:
            return null;
    }
}
