import { deleteUserDeleteUser, getUserGetCompanyUsers, postRbacSaveUserRoles, postUserSaveUserStatus } from '@apis/Customers';
import { BulkUserRoleChange, Company, CompanyInfo, User, UserListItem } from '@apis/Customers/model';
import styled from '@emotion/styled';
import { Button, Group, Text, Switch, MultiSelect, Divider, Space, Modal } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { openConfirmModal } from '@mantine/modals';
import { DataGrid } from '@root/Components/DataGrid';
import { DataColumnConfig } from '@root/Components/DataGrid/Models';
import { useCompany } from '@root/Components/Router/CompanyContent';
import { UserImage } from '@root/Components/Users/UserImage';
import { PanelContent, PanelBody, PanelToolbar } from '@root/Design/Layout';
import { AnchorButton, Clearfix } from '@root/Design/Primitives';
import { useDi, useDiComponent, useDiContainer } from '@root/Services/DI';
import { NotificationService } from '@root/Services/Notification/NotificationService';
import { ResourceService } from '@root/Services/Resources/ResourceService';
import { makeAutoObservable, toJS } from 'mobx';
import { observer } from 'mobx-react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { MailForward, Trash } from 'tabler-icons-react';
import { inject, injectable } from 'tsyringe';
import { AdminPageTitle, AdminPanel } from './Design';
import { CompanyAdministrationPanelModel } from './CompanyAdministrationPanelModel';
import { postNotificationSendNotification } from '@apis/Notification';
import { MspService } from '@root/Services/MspService';
import { useEvent } from '@root/Services/EventEmitter';
import { UserInviteModal } from '@root/Site/Settings/UserInviteModal';
import { CompanyRoleService } from '@root/Site/Settings/Users/CompanyRoleService';
import { CompanyUserService } from '@root/Services/Customers/CompanyUserService';

type UserStatus = (typeof UserStatus)[keyof typeof UserStatus];
const UserStatus = {
    Active: 'Active',
    Deactivated: 'Deactivated',
    Invited: 'Invited',
} as const;

interface AdminConnectionsFormProps {
    companyInfo: CompanyInfo;
    model: CompanyAdministrationPanelModel;
    isSupport: boolean;
}

interface RoleData {
    value: string;
    label: string;
}

export const AdminUsers = (props: AdminConnectionsFormProps) => {
    const [users, setUsers] = useState<UserListItem[]>();
    const [selectedUser, setSelectedUser] = useState<UserListItem>();
    const [loading, setLoading] = useState(true);
    const [inviteOpened, { open: openInvite, close: closeInvite }] = useDisclosure(false);
    const [editOpened, { open: openEdit, close: closeEdit }] = useDisclosure(false);
    const DiContainer = useDiComponent();
    const mspSvc = useDi(MspService);

    useEvent(mspSvc.companyListChanged, () => {
        loadUsers();
    });

    const columns = useMemo(
        () =>
            [
                {
                    id: 'EMail',
                    header: 'EMail',
                    accessor: 'EMail',
                    defaultWidth: 225,
                    sortField: 'EMail',
                    type: 'string',
                },
                {
                    id: 'Name',
                    header: 'Name',
                    accessor: (u) => u.FirstName + ' ' + u.LastName + ' ' + u.EMail,
                    defaultWidth: 150,
                    sortField: 'Name',
                    noSort: false,
                    cellRenderer: (u) => {
                        return (
                            <div>
                                {u.FirstName && u.LastName && u.FirstName.trim().length > 0 && u.LastName.trim().length > 0 ? (
                                    <Text style={{ paddingTop: '5px' }}>{u.FirstName + ' ' + u.LastName}</Text>
                                ) : (
                                    <Text weight="dim" italic style={{ paddingTop: '5px' }}>
                                        No Name
                                    </Text>
                                )}
                            </div>
                        );
                    },
                },
                {
                    id: 'Roles',
                    header: 'Roles',
                    cellRenderer: (u) => {
                        if (u.Roles?.toString() != '') {
                            if (u.Roles && u.Roles.length > 0) {
                                if (u.Roles.length == 1) {
                                    return <div>{u.Roles[0].Name}</div>;
                                } else {
                                    return <div>{u.Roles[0].Name + ' + ' + (u.Roles.length - 1) + ' others'}</div>;
                                }
                            } else {
                                return <div>None</div>;
                            }
                        } else {
                            return <div>None</div>;
                        }
                    },
                    type: 'string',
                    noSort: true,
                    accessor: (item) => item.Roles?.map((r) => r.Name),
                    exportOptions: {
                        renderer: (item) => item.Roles?.map((r) => r.Name).join(', '),
                    },
                    defaultWidth: 325,
                },
                {
                    id: 'Status',
                    header: 'Status',
                    accessor: 'Status',
                    cellRenderer: (u) => {
                        return <div>{u.Status}</div>;
                    },
                    defaultWidth: 175,
                },
            ] as DataColumnConfig<UserListItem>[],
        [users]
    );

    const loadUsers = () => {
        setLoading(true);
        getUserGetCompanyUsers().then((users) => {
            setLoading(false);
            setUsers(() => users);
        });
    };
    const onSave = () => {
        setSelectedUser(() => {
            closeEdit();
            loadUsers();
            return undefined;
        });
    };
    useEffect(loadUsers, []);

    useEffect(() => {
        if (selectedUser != undefined && !loading) {
            openEdit();
        }
    }, [selectedUser]);

    const onInviteClose = useCallback(
        (didInvite: boolean) => {
            if (didInvite) {
                loadUsers();
            }
            closeInvite();
        },
        [closeInvite]
    );

    return loading ? null : (
        <AdminPanel>
            <Modal opened={inviteOpened} closeOnClickOutside onClose={closeInvite} title="Add Users and Grant Permissions">
                <DiContainer>
                    <UserInviteModal onClose={onInviteClose}></UserInviteModal>
                </DiContainer>
            </Modal>
            <Modal opened={editOpened} onClose={closeEdit} title="Edit User Details">
                {selectedUser && <UserDetails key={selectedUser.Id} onSave={onSave} onDelete={loadUsers} user={selectedUser} />}
            </Modal>
            <Group position="apart">
                <AdminPageTitle data-atid="UsersMainHeader">Users</AdminPageTitle>
                <Button data-atid="InviteUserButton" onClick={openInvite}>
                    Invite Users
                </Button>
            </Group>
            <Space h="sm" />
            {users?.length ? (
                <div style={{ height: '525px', overflow: 'auto' }}>
                    <DataGrid onRowClick={setSelectedUser} selection={selectedUser} dataSource={users!} columns={columns} exportName="Users" />
                </div>
            ) : null}
        </AdminPanel>
    );
};

@injectable()
class UserDetailsModel {
    public isLoading = true;
    public user: User = {};
    public company: Company = {};
    public status: UserStatus = 'Active';
    public existingRoles: string[] = [];
    public addedRoles: string[] = [];
    public removedRoles: string[] = [];
    public possibleRoles: RoleData[] = [];
    public isReadonly = true;
    public isDirty = false;
    public isActivated = false;
    public notificationService = useDi(NotificationService);
    public mspSvc = useDi(MspService);

    public constructor(
        @inject(ResourceService) private readonly resourceSvc: ResourceService,
        @inject(CompanyRoleService) private readonly companyRoleSvc: CompanyRoleService,
        @inject(CompanyUserService) private readonly companyUserSvc: CompanyUserService
    ) {
        makeAutoObservable(this);
    }

    public async init(company: Company, user: UserListItem) {
        this.isLoading = true;
        try {
            this.loadCompany(company);
            await this.loadRoles();
            this.loadUser(user);
        } finally {
            this.isLoading = false;
        }
    }
    public async loadUser(nextUser: UserListItem) {
        const user = makeAutoObservable({
            Id: 0,
            FirstName: '',
            LastName: '',
            Email: '',
            Picture: '',
            ExternalId: '',
            ...nextUser,
        });
        this.status = nextUser.Status ?? 'Active';
        this.user = user;
        this.isActivated = user.Status == UserStatus.Active ? true : false;
        this.existingRoles.splice(0, Infinity);
        const roles = nextUser.Roles ? nextUser.Roles : [];
        this.existingRoles = roles.map((x) => (x?.Id?.toString() ? x.Id.toString() : ''));
        this.isReadonly = false;
    }

    public save = async () => {
        const rolesPayload: BulkUserRoleChange = {};
        rolesPayload.CompanyId = this.company.Id;
        rolesPayload.Add = [];
        rolesPayload.Delete = [];
        this.addedRoles.forEach((roleToAdd) => {
            rolesPayload.Add?.push({ UserId: this.user.Id, RoleId: parseInt(roleToAdd) });
        });

        this.removedRoles.forEach((roleToDelete) => {
            rolesPayload.Delete?.push({ UserId: this.user.Id, RoleId: parseInt(roleToDelete) });
        });

        if (this.status !== 'Invited') {
            await postUserSaveUserStatus({ userId: this.user.Id, userStatus: this.isActivated ? UserStatus.Active : UserStatus.Deactivated });
        }

        const result = await postRbacSaveUserRoles(toJS(rolesPayload));

        this.companyUserSvc.invalidate();

        return result;
    };

    public resendInvite = async () => {
        var companyId = this.company.Id;
        var userId = this.user.Id?.toString();
        var userEmail = this.user.EMail;

        await postNotificationSendNotification(
            { companyId, userId, WelcomeInviteData: [{ emails: userEmail, roles: [] }] },
            { notificationType: 'Welcome' }
        );

        this.companyUserSvc.invalidate();

        this.notificationService.notify(
            'Invitation Resent',
            "The user's invitation has been resent",
            'success',
            <i className="ti ti-mail-forward" />
        );
    };

    public delete = async () => {
        await deleteUserDeleteUser({ userId: this.user.Id });
    };

    public changeStatus = async (status: boolean) => {
        this.isActivated = status;
    };

    public handleOnChange = async (values: string[]) => {
        this.addedRoles = values.filter((val) => !this.existingRoles.includes(val));
        this.removedRoles = this.existingRoles.filter((val) => !values.includes(val));
    };

    public async loadCompany(company?: Company) {
        if (company?.Id !== this.company.Id) {
            this.company = company ? company : {};
        }
    }

    public async loadRoles() {
        this.addedRoles.splice(0, Infinity);
        this.removedRoles.splice(0, Infinity);
        this.possibleRoles.splice(0, Infinity);
        const roles = await this.companyRoleSvc.getRoles();

        roles.forEach((x) => {
            const possibleRole: RoleData = { value: '', label: '' };
            possibleRole.value = x.Id?.toString() ? x.Id.toString() : '';
            possibleRole.label = x.Name ? x.Name : '';
            this.possibleRoles.push(possibleRole);
        });
    }
}

const UserDetails = observer(function UserDetails({
    user,
    onSave,
    onDelete,
    onResend,
}: {
    user: UserListItem;
    onSave?: () => void;
    onDelete?: () => void;
    onResend?: () => void;
}) {
    const container = useDiContainer();
    const company = useCompany();
    const notificationSvc = useDi(NotificationService);
    const model = useMemo(() => {
        const result = container.resolve(UserDetailsModel);
        result.init(company!, user);
        return result;
    }, []);

    const save = useCallback(() => {
        model
            .save()
            .then(() => {
                onSave?.();
                notificationSvc.notify('User saved', '', 'success', null);
            })
            .catch(() => {
                notificationSvc.notify('Save failed', '', 'error', null);
            });
    }, [model, onSave]);

    const deleteInvitee = useCallback(() => {
        model.delete().then(onDelete);
    }, [model, onDelete]);

    const resendInvite = useCallback(() => {
        model.resendInvite();
    }, [model, onResend]);

    const confirmDelete = useCallback(() => {
        openConfirmModal({
            title: 'Delete Invite?',
            children: (
                <p>
                    Are you sure you want to delete the invitation to <strong>{model.user.EMail}</strong>?
                </p>
            ),
            centered: true,
            labels: { confirm: 'Yes, delete it', cancel: 'Cancel' },
            onConfirm: deleteInvitee,
            confirmProps: { color: 'error' },
            zIndex: 10000,
        });
    }, [model.user]);

    const confirmResend = useCallback(() => {
        openConfirmModal({
            title: 'Resend Invite?',
            children: (
                <p>
                    Are you sure you want to resend the invitation to <strong>{model.user.EMail}</strong>?
                </p>
            ),
            centered: true,
            labels: { confirm: 'Yes, resend it', cancel: 'Cancel' },
            onConfirm: resendInvite,
            confirmProps: { color: 'primary.6' },
            zIndex: 10000,
        });
    }, [model.user]);
    return model.isLoading ? null : (
        <PanelContent>
            <PanelBody>
                <DetailsInfoWrapper>
                    <Group spacing={0}>
                        <UserImage
                            firstName={model.user?.FirstName ?? ''}
                            lastName={model.user?.LastName ?? ''}
                            email={model.user?.EMail ?? ''}
                            picture={model.user?.Picture ?? ''}
                            id={model.user?.Id ?? 0}
                            diameter={48}
                        />
                        <div>
                            {(model.user.FirstName &&
                                model.user.LastName &&
                                model.user.FirstName.trim().length > 0 &&
                                model.user.LastName.trim().length > 0) ||
                            model.user.EMail ? (
                                <Text weight="bold">{model.user.FirstName + ' ' + model.user.LastName}</Text>
                            ) : (
                                <Text weight="dim" italic>
                                    No Name
                                </Text>
                            )}
                            <Text>{model.user.EMail}</Text>
                        </div>
                    </Group>
                </DetailsInfoWrapper>
                <Space h={68} />
                <StatusButtonWrapper>
                    {model.status === 'Invited' ? (
                        <Group spacing={32}>
                            <AnchorButton icon={<MailForward />} text="Resend" onClick={confirmResend} atid="ResendInviteButton" />
                            <AnchorButton color="error.6" icon={<Trash />} text="Delete" onClick={confirmDelete} atid="DeleteInviteButton" />
                        </Group>
                    ) : (
                        <Switch
                            checked={model.isActivated}
                            onChange={(event) => model.changeStatus(event.currentTarget.checked)}
                            label={model.isActivated ? 'Active' : 'Deactivated'}
                        />
                    )}
                </StatusButtonWrapper>
                <Clearfix />
                <Space h="md" />
                <RolesWrapper>
                    <MultiSelect
                        data={model.possibleRoles}
                        label="Roles"
                        defaultValue={model.existingRoles}
                        onChange={(values) => {
                            model.handleOnChange(values);
                        }}
                    />
                </RolesWrapper>
            </PanelBody>
            <Divider />
            <PanelToolbar>
                <Button data-atid="SaveButton" onClick={save}>
                    Save
                </Button>
            </PanelToolbar>
        </PanelContent>
    );
});

const DetailsInfoWrapper = styled.div`
    float: left;
    img {
        width: 100px;
        border-radius: 100%;
        margin: ${(p) => p.theme.spacing.xs}px;
    }
`;

const StatusButtonWrapper = styled.div`
    float: left;
    margin-top: 16px;

    .mantine-Button-root {
        margin: 10px;
    }

    .mantine-Switch-input {
        background-color: #d6d6d6;
    }

    .mantine-Switch-input:checked {
        background-color: #0075c1;
    }
`;

const RolesWrapper = styled.div``;

const GridImageWrapper = styled.div`
    img {
        width: 50px;
        border-radius: 100%;
        margin: 0px 15px 0px 0px;
    }
`;
