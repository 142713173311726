import { EventEmitter } from '@root/Services/EventEmitter';
import { injectable } from 'tsyringe';

@injectable()
export class BillingInvoicesAdminPanelModel {
    public saveChangesRequested = EventEmitter.empty();
    public discardChangesRequested = EventEmitter.empty();
    public isModifiedChanged = new EventEmitter<boolean>(false);
    public tabChanged = new EventEmitter<string>('company');
}
