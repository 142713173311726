import { Company } from '@apis/Customers/model';
import { getInvoiceIngestionGetSchemaInfo, postDailyRollupQuery, postMonthlyRollupQuery } from '@apis/Invoices';
import {
    InvoiceSchemaInfo,
    InvoiceSchemaFeatureInfo,
    SchemaField,
    InvoiceSchemaFeatureInfoCsp,
    FocusFieldInfo,
    FocusFieldInfoUnavailableRollupsItem,
} from '@apis/Invoices/model';
import { SchemaType } from '@apis/Jobs/model';
import { QueryResult } from '@apis/Resources';
import { CompanyTenantPrereqService } from '@root/Components/Router/CompanyContent';
import { injectable, inject, singleton } from 'tsyringe';
import { BaseCacheByTenant } from '../Customers/BaseCacheByTenant';
import { ICompanyContextToken } from '../Customers/CompanyContext';
import { FormatService, NamedFormats } from '../FormatService';
import { IntegrationSchemaService } from '../Integrations/IntegrationSchemaService';
import { Platform } from '../PlatformService';
import { FieldInfo, ISupplementalFieldInfo } from '../QueryExpr';
import { FocusMetadataInfo, FocusMetadataService } from './FocusMetadataService';

export interface IBaseInvoiceRecord {
    [key: `resourceTags/user:${string}`]: string;
    BillingAccountId: string;
    /** Standard payer account field, replacement for AccountName */
    BillingAccountName?: string;
    /** Standard cash-cost field, replacement for UnblendedCost */
    BilledCost: number;
    /** Alias for UsageStartDate, BilledDate */
    ChargePeriodStart: string;
    CloudPlatform?: string;
    /** Standard, customer-specific pricing rate field, replacement for UnblendedRate */
    ContractedUnitPrice?: number;
    /** Standard usage amount field, replacement for lineItem/UsageAmount */
    ConsumedQuantity?: number;
    /** Standard amortized-cost field, used for querying when AdjustedAmortizedCost is not available */
    EffectiveCost: number;
    InvoiceIssuerName?: string;
    /** Standard region field, replacement for product/region */
    RegionId?: string;
    ResourceId?: string;
    /** Category for the resource/usage, e.g., EC2, lambda, az func = Compute; RDS, DocumentDB = Database; S3, EFS, BlobStorage = Storage */
    ServiceCategory?: string;
    /** Standard for the usage account name, replacement for lineItem/UsageAccountName */
    SubAccountName?: string;
    /** Invoice month, formatted as yyyy-MM, indexed as the first day of the month, midnight UTC */
    UsageMonth: string;
    /** Unique identifier for the billing rate */
    VarianceKey: string;
}

interface IFocusInvoiceRecord extends IBaseInvoiceRecord {
    ChargeCategory?: string;
}

interface ICurInvoiceRecord extends IBaseInvoiceRecord {
    ['lineItem/LineItemType']?: string;
}

export type IMixedInvoiceRecord = IFocusInvoiceRecord & ICurInvoiceRecord;

export interface IReallocatedInvoiceRecord {
    /**
     * Adjusted amortized-cost field, used for querying when EffectiveCost is not available
     */
    AdjustedAmortizedCost: number;
    /**
     * Adjusted cash-cost field, used for querying when BilledCost is not available
     */
    AdjustedCashCost: number;
    /**
     * External cost adjustment field, used for querying when ExternalCostAdjustment is not available
     */
    ExternalCostAdjustment: number;
}

export interface IInvoiceRollup {
    [key: `resourceTags/user:${string}`]: string;
    UsageMonth: string;
    VarianceKey: string;
    ['lineItem/AmortizedCost']?: number;
    ['lineItem/UsageAccountId']?: string;
    ['bill/PayerAccountId']?: string;
    ['bill/BillingEntity']?: string;
    ['bill/InvoiceId']?: string;
    ['product/ProductName']?: string;
    ['product/servicecode']?: string;
    ['product/instanceType']?: string;
    ['product/region']?: string;
    ['lineItem/UsageAmount']?: number;
    ['lineItem/UnblendedRate']?: string;
    ['lineItem/LineItemType']?: string;
    ['lineItem/UnblendedCost']?: number;
    ['lineItem/Operation']?: string;
    AdjustedAmortizedCost?: number;
    AdjustedCashCost?: number;
    ExternalCostAdjustment?: number;
    ['reservation/OnDemandCost']?: number;
    ['reservation/UnusedAmortizedUpfrontFeeForBillingPeriod']?: number;
    ['savingsPlan/AmortizedUpfrontCommitmentForBillingPeriod']?: number;
    ['savingsPlan/OfferingType']?: string;
    ['product/productFamily']?: string;
    ['ExternalCostAdjustment']?: number;
}

export interface IDailyRollup extends IInvoiceRollup {
    ['product/servicecode']?: string;
    BilledDate: Date;
    UsageStartDate: Date;
    UsageEndDate: Date;
    resourceType?: string;
    ['lineItem/ResourceId']?: string;
    ['reservation/ReservationARN']?: string;
    ['savingsPlan/SavingsPlanARN']?: string;
}

export interface IMonthlyRollup extends IInvoiceRollup {
    ['lineItem/LineItemDescription']?: string;
}

export interface IForecast {
    Model: string;
    JobId: string;
    UserId: number;
    CompanyId: number;
    Date: Date;
    P10: number;
    P50: number;
    P90: number;
    IsWholeMonth?: boolean;
    PerformanceMetricSmape: number;
}

type RollupTypes = 'Daily' | 'Monthly' | 'Unknown';
export interface ITranformationDetail {
    getIndexedPath: (queryExprField: string) => string;
    usesPathAliases: boolean;
    types: SchemaType[];
    schemaInfo: InvoiceSchemaInfo;
    integrationSchemas: SchemaType[];
    hiddenTypes: SchemaType[];
    rollupType: RollupTypes;
    months: Date[];
}

@singleton()
class DailyInvoiceSchemaCache extends BaseCacheByTenant<ITranformationDetail> {
    public constructor(@inject(CompanyTenantPrereqService) tenantPrereqSvc: CompanyTenantPrereqService) {
        super(tenantPrereqSvc);
    }

    public getSync(tenantId: number) {
        return this.resolvedResults.get(tenantId);
    }
}

@singleton()
class MonthlyInvoiceSchemaCache extends BaseCacheByTenant<ITranformationDetail> {
    public constructor(@inject(CompanyTenantPrereqService) tenantPrereqSvc: CompanyTenantPrereqService) {
        super(tenantPrereqSvc);
    }

    public getSync(tenantId: number) {
        return this.resolvedResults.get(tenantId);
    }
}

@singleton()
class InvoiceSchemaInfoCache extends BaseCacheByTenant<InvoiceSchemaInfo> {
    public constructor(@inject(CompanyTenantPrereqService) tenantPrereqSvc: CompanyTenantPrereqService) {
        super(tenantPrereqSvc);
    }
}

@injectable()
export class InvoiceSchemaService {
    public constructor(
        @inject(FormatService) private readonly formatSvc: FormatService,
        @inject(DailyInvoiceSchemaCache) private readonly dailyInvoiceSchemaCache: DailyInvoiceSchemaCache,
        @inject(FocusMetadataService) private readonly focusSvc: FocusMetadataService,
        @inject(MonthlyInvoiceSchemaCache) private readonly monthlyInvoiceSchemaCache: MonthlyInvoiceSchemaCache,
        @inject(InvoiceSchemaInfoCache) private readonly invoiceSchemaInfoCache: InvoiceSchemaInfoCache,
        @inject(ICompanyContextToken) private readonly company: Company,
        @inject(IntegrationSchemaService) private readonly integrationSchemaSvc: IntegrationSchemaService
    ) {}

    public getDailySchema() {
        return this.getDailySchemaTransformationDetail().then((t) => t.types);
    }
    public getPrecachedSchema() {
        return this.dailyInvoiceSchemaCache.getSync(this.company?.Id ?? 0)?.types;
    }

    public getMonthlySchema() {
        return this.getMonthlySchemaTransformationDetail().then((t) => t.types);
    }
    public getMonthlySchemaTransformationDetail() {
        return this.monthlyInvoiceSchemaCache.get(this.company?.Id ?? 0, () =>
            this.getTypes(() => postMonthlyRollupQuery({ IncludeSchema: true, Take: 0 }, {}), 'Monthly')
        );
    }
    public getDailySchemaTransformationDetail() {
        return this.dailyInvoiceSchemaCache.get(this.company?.Id ?? 0, () =>
            this.getTypes(() => postDailyRollupQuery({ IncludeSchema: true, Take: 0 }, {}), 'Daily')
        );
    }
    public getInvoiceSchemaInfo() {
        return this.invoiceSchemaInfoCache.get(this.company?.Id ?? 0, () => getInvoiceIngestionGetSchemaInfo());
    }

    private async getTypes(source: () => Promise<QueryResult<unknown>>, rollupType: RollupTypes) {
        const [focusMeta, schemaInfo, integrationSchema, queryResult] = await Promise.all([
            this.focusSvc.getFocusMetadata(),
            this.getInvoiceSchemaInfo(),
            this.integrationSchemaSvc.getIntegrationSchema(),
            source(),
        ]);
        const result = await InvoiceSchemaTransformer.transform(this.formatSvc, focusMeta, schemaInfo, integrationSchema, queryResult, rollupType);

        return result;
    }
}

@injectable()
export class DailyInvoiceSchemaProvider {
    public constructor(@inject(InvoiceSchemaService) private readonly invoiceSchemaSvc: InvoiceSchemaService) {}

    public getSchema() {
        return this.invoiceSchemaSvc.getDailySchema();
    }
}
class FieldPathConvention {
    private pathLookup = new Map<string, string>();
    public constructor(private readonly shouldUseAliases: boolean) {}

    public getSchemaPath(schema: 'FOCUS' | 'AWS' | 'Azure', indexedPath: string) {
        let result = indexedPath;
        if (this.shouldUseAliases) {
            result = `${schema}.${indexedPath}`;
            this.pathLookup.set(result, indexedPath);
        }
        return result;
    }
    public getIndexedPath = (schemaPath: string) => (!this.shouldUseAliases ? schemaPath : this.pathLookup.get(schemaPath) ?? schemaPath);
}
type FieldDisplayInfo = { format?: NamedFormats; description?: string; ufName?: string };
class InvoiceSchemaTransformer {
    private readonly indexInfoLookup = new Map<string, InvoiceSchemaFeatureInfo>();
    private readonly hasFocusIngestion: boolean = false;
    private readonly csps = new Set<InvoiceSchemaFeatureInfoCsp>();
    private readonly integrationFieldLookup = new Map<string, { integrationName: string; field: SchemaField }>();
    private readonly pathConvention: FieldPathConvention;

    private readonly fieldDisplayInfoByPath = new Map([
        /* Proprietary */
        ['BilledDate', { format: 'short-date' }],
        ['UsageMonth', { format: 'short-month' }],
        [
            'AdjustedCashCost',
            {
                format: 'money-with-two-decimals',
                ufName: 'Showback Cost',
                description: 'Adjusted Cash Cost represents the cash-basis billed costs adjusted by showback allocation rules. ',
            },
        ],
        [
            'AdjustedAmortizedCost',
            {
                format: 'money-with-two-decimals',
                ufName: 'Showback Cost (Amortized)',
                description: 'Adjusted Amortized Cost represents the accrual-basis billed costs adjusted by showback allocation rules. ',
            },
        ],
        [
            'ExternalCostAdjustment',
            {
                format: 'money-with-two-decimals',
                description:
                    'External Cost Adjustment represents costs introduced through showback allocation rules, not represented in the CSP invoice. ',
            },
        ],
        /* Aws */
        [
            'lineItem/AmortizedCost',
            {
                format: 'money-with-two-decimals',
                description:
                    'Amortized Costs, as prescribed by AWS, represents your usage costs on an accrual basic, without any showback allocation rules applied. ',
            },
        ],
        [
            'lineItem/UnblendedCost',
            {
                format: 'money-with-two-decimals',
                description:
                    'Unblended Cost represents your usage costs on the day they are charged to you. In finance terms, they represent your costs on a cash basis of accounting. ',
            },
        ],
        ['lineItem/UnblendedRate', { format: 'money-with-four-decimals' }],
        [
            'lineItem/NetUnblendedCost',
            {
                format: 'money-with-two-decimals',
                description:
                    'Net Unblended Cost represents your usage costs on the day they are charged to you minus discounts from AWS discount programs. In finance terms, they represent your costs on a cash basis of accounting. ',
            },
        ],
        [
            'lineItem/BlendedCost',
            {
                format: 'money-with-two-decimals',
                description:
                    "AWS: Blended Cost is calculated by multiplying each account's service usage by a blended rate. A blended rate is the average rate of on-demand usage, as well as Savings Plans and reservation-related usage. ",
            },
        ],
        [
            'savingsPlan/AmortizedUpfrontCommitmentForBillingPeriod',
            {
                format: 'money-with-two-decimals',
                description:
                    'AWS: Amortized Upfront Commitment For Billing Period represents the daily amortized costs of AWS Savings Plan upfront commitment. ',
            },
        ],
        [
            'reservation/OnDemandCost',
            {
                format: 'money-with-two-decimals',
                description: 'Reservation On Demand Cost represents the cost of AWS reserved instance usage at the standard, on-demand rate. ',
            },
        ],
        ['lineItem/UsageAmount', { format: 'float' }],
        ['UsageStartDate', { format: 'short-date' }],
        ['UsageEndDate', { format: 'short-date' }],
        ['product/vcpu', { ufName: 'vCPUs', description: 'The number of virtual CPUs (vCPUs) used by the resource.' }],
        ['product/vcpuHours', { ufName: 'vCPU Hours' }],
        ['product/processorFamily', { ufName: 'Processor Family' }],
        ['product/physicalProcessor', { ufName: 'Physical Processor' }],
        ['product/instanceType', { ufName: 'Instance Type' }],
        ['CspSchemaVersion', { ufName: 'Source Schema', description: `The schema of the source data exported from the Cloud Service Provider.` }],
        /* Azure */
        ['Date', { format: 'short-date' }],
        [
            'PayGCostInBillingCurrency',
            {
                ufName: 'PayG Cost',
                description: `The "pay-as-you-go" cost (in the billing currency) represents the cost at market price.`,
                format: 'money-with-two-decimals',
            },
        ],
        [
            'PayGPrice',
            { ufName: 'PayG Price', description: `The "pay-as-you-go" price represents per-unit market price.`, format: 'money-with-four-decimals' },
        ],
        ['Quantity', { format: 'float' }],
        /* Focus */
        ['BillingPeriodStart', { format: 'short-date' }],
        ['BillingPeriodEnd', { format: 'short-date' }],
        ['ChargePeriodStart', { format: 'short-date' }],
        ['ChargePeriodEnd', { format: 'short-date' }],
        ['ConsumedQuantity', { format: 'float' }],
        ['ContractedUnitPrice', { format: 'money-with-four-decimals' }],
        ['ListUnitPrice', { format: 'money-with-four-decimals' }],
        ['PricingQuantity', { format: 'float' }],
        ...[
            /* AWS */
            'pricing/publicOnDemandCost',
            'reservation/AmortizedUpfrontCostForUsage',
            'reservation/AmortizedUpfrontFeeForBillingPeriod',
            'reservation/EffectiveCost',
            'reservation/NetAmortizedUpfrontCostForUsage',
            'reservation/NetAmortizedUpfrontFeeForBillingPeriod',
            'reservation/NetEffectiveCost',
            'reservation/NetRecurringFeeForUsage',
            'reservation/NetUnusedAmortizedUpfrontFeeForBillingPeriod',
            'reservation/NetUnusedRecurringFee',
            'reservation/RecurringFeeForUsage',
            'reservation/UnusedAmortizedUpfrontFeeForBillingPeriod',
            'reservation/UnusedRecurringFee',
            'savingsPlan/AmortizedUpfrontCommitmentForBillingPeriod',
            'savingsPlan/NetAmortizedUpfrontCommitmentForBillingPeriod',
            'savingsPlan/NetRecurringCommitmentForBillingPeriod',
            'savingsPlan/NetSavingsPlanEffectiveCost',
            'savingsPlan/RecurringCommitmentForBillingPeriod',
            'savingsPlan/SavingsPlanEffectiveCost',
            'savingsPlan/TotalCommitmentToDate',
            'savingsPlan/UsedCommitment',
            /* Azure */
            'CostInBillingCurrency',
            /* Focus */
            'BilledCost',
            'ContractedCost',
            'EffectiveCost',
            'ListCost',
        ].map((f) => [f, { format: 'money-with-two-decimals' }] as [string, FieldDisplayInfo]),
    ] as [string, FieldDisplayInfo][]);

    public static transform(
        formatSvc: FormatService,
        focusMeta: FocusMetadataInfo,
        schemaInfo: InvoiceSchemaInfo,
        integrationSchemas: SchemaType[],
        queryResult: QueryResult<unknown>,
        rollupType: RollupTypes = 'Unknown'
    ) {
        const transformer = new InvoiceSchemaTransformer(formatSvc, focusMeta, schemaInfo, integrationSchemas, rollupType);
        const transformedSchemaDetails = transformer.transform(queryResult.Types ?? []);
        return {
            ...transformedSchemaDetails,
            schemaInfo,
            integrationSchemas,
            rollupType,
        };
    }

    private constructor(
        private readonly formatSvc: FormatService,
        private readonly focusMeta: FocusMetadataInfo,
        private readonly schemaInfo: InvoiceSchemaInfo,
        private readonly integrationSchemas: SchemaType[],
        private readonly rollupType: RollupTypes
    ) {
        for (const index of schemaInfo.Indices ?? []) {
            this.indexInfoLookup.set(index.Index ?? '', index);
            if (index.KeyFeatures?.includes('HasFocusSchemaVersion')) {
                this.hasFocusIngestion = true;
            }
        }
        this.pathConvention = new FieldPathConvention(this.hasFocusIngestion);
        this.csps = new Set(schemaInfo.ConnectedPlatforms);
        this.integrationFieldLookup = integrationSchemas.reduce((result, type) => {
            if (type.Fields) {
                for (const field of type.Fields ?? []) {
                    const fieldPath = `${type.Name}/${field.Field}`;
                    result.set(fieldPath, {
                        integrationName: type.Name ?? '',
                        field: { ...field, Field: fieldPath, Name: field.Name },
                    });
                }
            }
            return result;
        }, new Map<string, { integrationName: string; field: SchemaField }>());
    }

    public transform(rawTypes: SchemaType[]) {
        const result: SchemaType[] = [];

        const { awsType, azureType, types, months } = this.groupTypesByCsp(rawTypes ?? []);
        this.removeInternalFields(types);
        this.addTagsType(types);
        this.addIntegrationTypes(types);
        if (this.hasFocusIngestion) {
            types.push(this.buildFocusSchema(awsType, azureType));
        }
        if (awsType && this.schemaInfo.ConnectedPlatforms?.includes('Aws')) {
            this.addAwsSchema(awsType, types);
        }
        if (azureType && this.schemaInfo.ConnectedPlatforms?.includes('Azure')) {
            this.addAzureSchema(azureType);
        }
        if (this.hasFocusIngestion) {
            this.addProprietaryType(types);
        }
        this.applyTypeOrder(types);
        this.applyFieldIndexById(types);
        result.push(...types);

        return {
            types: result,
            getIndexedPath: (queryExprField: string) => this.pathConvention.getIndexedPath(queryExprField),
            usesPathAliases: this.hasFocusIngestion,
            hiddenTypes: this.createHiddenTypes(),
            months,
        };
    }

    // #region Common
    private addTagsType(types: SchemaType[]) {
        const tagsType: SchemaType = { TypeId: 'resourceTags', Name: 'Tags (Cost Allocation)', IsRoot: true, Fields: [] };

        const addedCols = new Set<string>();
        for (const [type, field] of this.iterateFields(types)) {
            if (field.Field?.startsWith('resourceTags')) {
                this.removeTypeField(type, field);
                if (!addedCols.has(field.Field)) {
                    const name = field.Field.substring('resourceTags/'.length).replace(/^user:|^aws:/, '');
                    const tagField = this.createField({ ...field, Name: name }, [field.Field ?? ''], `resourceTags.${field.Field}`, {
                        Flags: ['tag'],
                    });
                    tagsType.Fields?.push(tagField);
                    addedCols.add(field.Field);
                }
            }
        }

        types.push(tagsType);
    }

    private addIntegrationTypes(types: SchemaType[]) {
        const integrationTypes = new Map<string, SchemaType>();

        for (const [type, field] of this.iterateFields(types)) {
            const { field: intgFld, integrationName } = this.integrationFieldLookup.get(field.Field ?? '') ?? {};
            if (intgFld) {
                let integrationType = integrationTypes.get(integrationName ?? '');
                if (!integrationType) {
                    integrationTypes.set(integrationName ?? '', (integrationType = this.createIntegrationSchema(integrationName ?? '')));
                }
                this.removeTypeField(type, field);
                integrationType.Fields!.push(FieldInfo.applySupplementalInfo(intgFld, { Flags: ['integration'] }));
            }
        }

        types.push(...integrationTypes.values());
    }

    private removeInternalFields(types: SchemaType[]) {
        const hiddenFields = new Set([
            'resourceType',
            'ResourceTags',
            'VarianceKey',
            'UniqueId',
            'CompanyId',
            'RecordCount',
            'AdjustmentLog',
            'AdjFrom',
            'AdjBy',
            'AdjId',
            'TagChangeLog',
            'TagKey',
            'TagValue',
            'PrevVal',
            'RuleId',
            'AmortDlt',
            'FocusSchemaVersion',
            'NativeUsageType',
            'NativeLineItemType',
        ]);
        for (const type of types) {
            type.Fields = type.Fields?.filter((f) => f.Field && !hiddenFields.has(f.Field));
        }
    }

    private createHiddenTypes(): SchemaType[] {
        return [
            {
                TypeId: 'Proprietary',
                Name: 'Proprietary',
                IsRoot: true,
                Fields: [
                    {
                        Name: 'Line Item Type',
                        Field: 'NativeLineItemType',
                        IsPrimitive: true,
                        TypeName: 'string',
                        Description: 'The line item type or charge type as defined by the Cloud Service Provider.',
                        HasMany: false,
                    },
                    {
                        Name: 'Usage Type',
                        Field: 'NativeUsageType',
                        IsPrimitive: true,
                        TypeName: 'string',
                        Description: 'The usage type as defined by the Cloud Service Provider.',
                        HasMany: false,
                    },
                ],
            },
        ];
    }

    private getFieldType(platform: Platform, field: string): 'csp' | 'focus' | 'aliased-csp' {
        const focusField = this.focusMeta.getField(field);
        const cspAlias = this.focusMeta.getCspAlias(platform, field);
        return !focusField || cspAlias === field ? 'csp' : !!cspAlias ? 'aliased-csp' : 'focus';
    }

    private groupTypesByCsp(types: SchemaType[]) {
        const cspSchemas: Record<InvoiceSchemaFeatureInfoCsp, undefined | { type: SchemaType; knownFields: Set<string> }> = {
            Aws: undefined,
            Azure: undefined,
        };
        const rawMonths = new Set<string>();

        for (const type of types) {
            const { Csp: csp, KeyFeatures: idxFeatures, Year, Month } = this.indexInfoLookup.get(type.TypeId ?? '') ?? {};
            if (!this.hasFocusIngestion || idxFeatures?.includes('HasFocusSchemaVersion')) {
                if (Year && Month) {
                    rawMonths.add(`${Year}-${Month.toString().padStart(2, '0')}-01`);
                }
                if (type.IsRoot && type.Fields && csp) {
                    const { type: cspType, knownFields } = (cspSchemas[csp] ??= {
                        type: this.createCspSchema(type, csp),
                        knownFields: new Set(),
                    });

                    for (const field of type.Fields) {
                        if (!knownFields.has(field.Field ?? '')) {
                            knownFields.add(field.Field ?? '');
                            cspType.Fields!.push(field);
                        }
                    }
                }
            }
        }

        return {
            awsType: cspSchemas.Aws?.type,
            azureType: cspSchemas.Azure?.type,
            types: Object.values(cspSchemas)
                .filter((v) => !!v?.type)
                .map((v) => v!.type),
            months: [...rawMonths].map((m) => this.formatSvc.parseDateNoTime(m)),
        };
    }

    private createCspSchema(seedType: SchemaType, csp: string) {
        const type: SchemaType = {
            ...seedType,
            Name: this.formatSvc.adjustCspName(csp),
            TypeId: this.normalizeIndexName(seedType),
            IsRoot: true,
            Fields: [],
        };
        return type;
    }

    private normalizeIndexName(type: SchemaType) {
        if (type.IsRoot && type.TypeId) {
            const [rollupType, company] = type.TypeId.split('-');
            return `${rollupType}-${company}-*-*-active`;
        }
    }

    private createIntegrationSchema(integrationName: string) {
        return {
            TypeId: integrationName,
            Name: integrationName,
            IsRoot: true,
            Fields: [],
        } as SchemaType;
    }

    private getCspAliasedLookup(platform: Platform) {
        const focusFields = this.focusMeta.getCspAliasedFields(platform) ?? [];
        const result = focusFields.reduce((result, item) => result.set(item.field.Field ?? '', item), new Map<string, { cspAlias: string }>());

        return result;
    }

    private applyTypeOrder(types: SchemaType[]) {
        const sortedIntegrationNames = this.integrationSchemas.map((t) => t.Name ?? '').sort();
        const typeRank = ['Common', FocusMetadataInfo.focusLabel, 'Tags (Cost Allocation)', ...sortedIntegrationNames, 'AWS', 'Azure'];
        this.sortByRank(types, typeRank, (type) => type.Name ?? '');
    }

    private applyFieldIndexById(types: SchemaType[]) {
        for (const type of types) {
            Object.assign(type, { IndexFieldsById: true });
        }
    }

    private sortByRank<T>(items: T[], rankedValues: string[], accessor: (value: T) => string) {
        const rankLookup = rankedValues.reduce((result, item, idx) => result.set(item, idx), new Map<string, number>());
        const getRank = (value: string) => rankLookup.get(value) ?? rankLookup.size;
        return items.sort((itemA, itemB) => {
            const a = accessor(itemA);
            const b = accessor(itemB);
            return getRank(a) - getRank(b) || a.localeCompare(b, undefined, { sensitivity: 'base' });
        });
    }

    private applyFieldInfo(field: SchemaField, fieldOverride?: string) {
        const info = this.fieldDisplayInfoByPath.get(fieldOverride ?? field.Field ?? '');
        if (info) {
            field.Name = info.ufName ?? field.Name;
            this.applyFieldPatch(field, { Format: info.format });
            if (info.description) {
                field.Description = info.description;
            }
        }
    }

    private applyFieldInfoToSchema(type: SchemaType) {
        for (const field of type.Fields ?? []) {
            this.applyFieldInfo(field);
        }
    }
    private applyFieldInfoToSchemas(types: SchemaType[]) {
        for (const type of types) {
            this.applyFieldInfoToSchema(type);
        }
    }

    private createField(field: SchemaField, path: string[], fieldId: string, patch?: ISupplementalFieldInfo) {
        this.applyFieldPatch(field, { Path: path, FieldId: fieldId, ...patch });
        return field;
    }
    private applyFieldPatch(field: SchemaField, patch: ISupplementalFieldInfo) {
        FieldInfo.applySupplementalInfo(field, patch);
    }

    private removeTypeField(type: SchemaType, field: SchemaField) {
        const index = type.Fields?.findIndex((f) => f.Field === field.Field) ?? -1;
        if (index >= 0) {
            type.Fields?.splice(index, 1);
        }
    }

    private removeFields(types: SchemaType[], fields: Set<string>) {
        for (const [type, field, idx] of this.iterateFields(types)) {
            if (fields.has(field.Field ?? '')) {
                type.Fields?.splice(idx, 1);
            }
        }
    }

    private applyFieldSortOrder(types: SchemaType[]) {
        for (const [_, field, idx] of this.iterateFields(types)) {
            this.applyFieldPatch(field, { SortOrderOverride: idx });
        }
    }

    private *iterateFields(types: SchemaType[]) {
        for (const type of types) {
            if (type.Fields) {
                let idx = 0;
                for (const field of type.Fields.slice()) {
                    yield [type, field, idx] as [SchemaType, SchemaField, number];
                    idx++;
                }
            }
        }
    }
    // #endregion

    // #region Focus
    private buildFocusSchema(awsTypes?: SchemaType, azureType?: SchemaType, rollupType?: string) {
        const types = [awsTypes, azureType].filter((t) => !!t) as SchemaType[];
        const focusType = this.createFocusType();
        this.addFocusSchemaFields(focusType, types, rollupType);
        this.applyFieldInfoToSchema(focusType);
        this.removeCspFocusFields(awsTypes, azureType);
        return focusType;
    }

    private addFocusSchemaFields(focusType: SchemaType, types: SchemaType[], rollupType?: string) {
        const focusFields = this.focusMeta.getFields();
        const focusFieldLookup = focusFields.reduce((result, field) => result.set(field.Field ?? '', field), new Map<string, FocusFieldInfo>());
        const foundFields = new Set<string>();
        rollupType ??= rollupType?.toLocaleLowerCase() ?? 'Unknown';

        for (const [_, field] of this.iterateFields(types)) {
            if (!foundFields.has(field.Field ?? '')) {
                foundFields.add(field.Field ?? '');
                const focusField = focusFieldLookup.get(field.Field ?? '');
                if (focusField) {
                    const unavailable = focusField.UnavailableRollups?.some((r) => r.toLocaleLowerCase() === rollupType);
                    const ignoreField = focusField.Ignore || !focusField.DisplayName || unavailable;
                    if (!ignoreField) {
                        focusType.Fields!.push(this.createFocusField(focusField, field));
                    }
                }
            }
        }
    }

    private removeCspFocusFields(awsType?: SchemaType, azureType?: SchemaType) {
        const types: [Platform, undefined | SchemaType][] = [
            ['Aws', awsType],
            ['Azure', azureType],
        ];
        for (const [platform, type] of types) {
            for (const field of (type?.Fields ?? []).slice()) {
                const fieldType = this.getFieldType(platform, field.Field ?? '');
                if (fieldType === 'focus') {
                    this.removeTypeField(type!, field);
                }
            }
        }
    }

    private createFocusType() {
        return {
            TypeId: 'focus',
            Fields: [],
            Name: FocusMetadataInfo.focusLabel,
            Description: `Fields based on the FinOps Open Cost and Usage Specification (${FocusMetadataInfo.focusLabel}), a cloud-agnostic schema standard developed by the FinOps Foundation to unify invoice data across providers.`,
            IsRoot: true,
        } as SchemaType;
    }

    private createFocusField(info: FocusFieldInfo, field: SchemaField) {
        return this.createField(
            {
                Field: info.Field,
                IsPrimitive: field.IsPrimitive,
                TypeName: field.TypeName,
                Description: info.Description,
                Name: info.DisplayName ?? info.Field,
                HasMany: field.HasMany,
            },
            [field.Field ?? ''],
            `FOCUS.${field.Field}`,
            { Path: [this.pathConvention.getSchemaPath('FOCUS', field.Field ?? '')] }
        );
    }
    // #endregion

    // #region AWS
    private addAwsSchema(awsType: SchemaType, types: SchemaType[]) {
        this.addAwsGroupTypes(awsType, types);
        this.applyFieldInfoToSchema(awsType);
    }

    private addAwsGroupTypes(awsType: SchemaType, types: SchemaType[]) {
        const groupTypes = this.createAwsGroupTypes(awsType);
        this.applyFieldInfoToSchemas(groupTypes);
        this.removeAwsRedundantCostFields(groupTypes);
        if (this.csps.size > 1) {
            types.push(...groupTypes);
            awsType.Fields = groupTypes.map((g) => this.createAwsGroupTypeField(g));
            this.sortByRank(awsType.Fields, ['Common'], (field) => field.Name ?? '');
            this.applyFieldSortOrder(groupTypes);
        } else {
            types.splice(0, Infinity, ...groupTypes, ...types.filter((t) => t !== awsType));
        }
    }

    private createAwsGroupTypes(type: SchemaType) {
        const result: SchemaType[] = [];
        const groupTypeLookup = new Map<string, SchemaType>();
        const aliasedFieldLookup = this.getCspAliasedLookup('Aws');
        const addedFields = new Set<string>();

        if (type.Fields) {
            for (const field of type.Fields) {
                if (!field.Field) continue;

                const cspField = aliasedFieldLookup.get(field.Field)?.cspAlias ?? field.Field;

                let [typeName, fieldName] = cspField.split('/');
                if (!fieldName) {
                    fieldName = typeName;
                    typeName = 'Common';
                }
                let groupType = groupTypeLookup.get(typeName);
                if (!groupType) {
                    groupTypeLookup.set(typeName, (groupType = this.createAwsGroupType(typeName)));
                    result.push(groupType);
                }

                const { fieldId, schFld } = this.createAwsField(field, cspField, fieldName, typeName);
                if (!addedFields.has(fieldId)) {
                    addedFields.add(fieldId);
                    groupType.Fields!.push(schFld);
                }
            }
        }

        return result;
    }

    private createAwsGroupType(typeName: string) {
        return {
            TypeId: typeName,
            Name: this.formatSvc.userFriendlyCamelCase(typeName),
            IsRoot: this.csps.size === 1,
            Fields: [],
        } as SchemaType;
    }

    private createAwsGroupTypeField({ Name, TypeId }: SchemaType) {
        return {
            Field: Name,
            HasMany: true,
            TypeName: TypeId,
            IsPrimitive: false,
            Name: Name,
        } as SchemaField;
    }

    private createAwsField({ Field, TypeName }: SchemaField, cspField: string, parsedField: string, typeId: string) {
        const info = this.fieldDisplayInfoByPath.get(cspField);
        const fieldId = `${typeId}.${cspField}`;
        const schFld = this.createField(
            {
                Field,
                HasMany: false,
                TypeName,
                IsPrimitive: true,
                Name: info?.ufName ?? this.formatSvc.userFriendlyCamelCase(parsedField),
            },
            [Field ?? ''],
            fieldId,
            { AdditionalIds: [`Aws.${cspField}`], Path: [this.pathConvention.getSchemaPath('AWS', cspField)] }
        );
        return { fieldId, schFld };
    }

    private removeAwsRedundantCostFields(types: SchemaType[]) {
        this.removeFields(types, new Set(['BilledCost', 'EffectiveCost']));
    }
    // #endregion

    // #region Azure
    private addAzureSchema(azureType: SchemaType) {
        this.adjustSpecificAzureFields(azureType);
        this.adjustAzureFields(azureType);
    }

    private adjustSpecificAzureFields(azureType: SchemaType) {
        for (const field of azureType.Fields ?? []) {
            switch (field.Field) {
                case 'BilledCost':
                    this.applyFieldPatch(field, {
                        FieldId: 'Azure.CostInBillingCurrency',
                        Path: [this.pathConvention.getSchemaPath('Azure', 'BilledCost')],
                    });
                    break;
                case 'EffectiveCost':
                    this.removeTypeField(azureType, field);
                    break;
            }
        }
    }

    private adjustAzureFields(azureType: SchemaType) {
        const aliasedFieldLookup = this.getCspAliasedLookup('Azure');

        for (const field of azureType.Fields ?? []) {
            const actualField = field.Field ?? '';
            const cspField = aliasedFieldLookup.get(actualField)?.cspAlias ?? actualField;
            field.Name = this.formatSvc.userFriendlyCamelCase(cspField);
            this.applyFieldInfo(field, cspField);
            this.applyFieldPatch(field, { Path: [this.pathConvention.getSchemaPath('Azure', actualField)], FieldId: `Azure.${cspField}` });
        }
    }
    // #endregion

    // #region Proprietary
    private addProprietaryType(types: SchemaType[]) {}
    // #endregion
}
