import { Stack } from '@mantine/core';
import {
    SettingsSection,
    SettingsSectionBodyDivider,
    SettingsSectionItem,
    SettingsSectionItemBody,
    SettingsSectionItemHeader,
    SettingsSectionItemHeaderLabel,
} from '@root/Design/Settings';
import { ComponentType, ReactNode } from 'react';
import { ICustomInputSpec, InputSpec, IWrappedInputSpec } from './InputSpec';
import { SettingsInput, SettingsLabeled, useRerenderOnChange } from './SettingsInputs';

type FormInput = InputSpec;
interface IFormDivider {
    type: 'divider';
}

export type IFormCustomElement<V, P> = ICustomInputSpec<V, P> | IWrappedInputSpec<V, P>;

export type FormElement = FormInput | IFormDivider | IFormCustomElement<any, any>;

export interface FormSectionItem {
    header?: { label: string; controls?: ReactNode };
    elements: FormElement[];
}

export interface CustomFormSectionItem {
    component: ComponentType<any>;
}

export interface FormSection {
    title?: string;
    elements: Array<FormElement | FormSectionItem>;
}
export function SettingsForm(props: { sections: FormSection[]; onChanged?: (element: FormElement) => void }) {
    return (
        <Stack spacing="md">
            {props.sections.map((section, i) => (
                <SettingsFormSection key={i} section={section} onChanged={props.onChanged} />
            ))}
        </Stack>
    );
}

function SettingsFormSection({ section, onChanged }: { section: FormSection; onChanged?: (element: FormElement) => void }) {
    const { title, elements } = section;
    return (
        <SettingsSection title={title}>
            {elements.map((element, i) =>
                'elements' in element ? (
                    <SettingsFormSectionItem key={i} sectionItem={element} onChanged={onChanged} />
                ) : (
                    <SettingsFormElement key={i} element={element} onChanged={onChanged} />
                )
            )}
        </SettingsSection>
    );
}

function SettingsFormSectionItem({ sectionItem, onChanged }: { sectionItem: FormSectionItem; onChanged?: (element: FormElement) => void }) {
    const { header, elements } = sectionItem;
    return (
        <SettingsSectionItem>
            {!header ? null : (
                <SettingsSectionItemHeader>
                    <SettingsSectionItemHeaderLabel>{header.label}</SettingsSectionItemHeaderLabel>
                    {header.controls}
                </SettingsSectionItemHeader>
            )}
            <SettingsSectionItemBody>
                {elements.map((element, i) => (
                    <SettingsFormElement key={i} element={element} onChanged={onChanged} />
                ))}
            </SettingsSectionItemBody>
        </SettingsSectionItem>
    );
}

function SettingsFormElement(props: { element: FormElement; onChanged?: (element: FormElement) => void }) {
    const { element, onChanged } = props;

    switch (element.type) {
        case 'divider':
            return <SettingsSectionBodyDivider />;
        case 'custom':
        case 'wrapped':
            return <CustomFormElement element={element} onChanged={onChanged} />;
        default:
            return <SettingsInput spec={element} onChanged={onChanged} />;
    }
}

function CustomFormElement<V, P>({
    element,
    onChanged,
}: {
    element: IWrappedInputSpec<V, P> | ICustomInputSpec<V, P>;
    onChanged?: (element: FormElement) => void;
}) {
    const { type, component: CustomComponent, props: elProps } = element;
    const passthruProps = typeof elProps === 'function' ? ((elProps as () => P)() as P) : elProps;
    const bindingProps = useRerenderOnChange(element as unknown as InputSpec, onChanged);
    const UntypedComponent = CustomComponent as ComponentType<any>;
    if (type === 'wrapped') {
        return (
            <SettingsLabeled spec={element}>
                <UntypedComponent spec={bindingProps} {...passthruProps} />;
            </SettingsLabeled>
        );
    } else {
        const props = { ...passthruProps, ...bindingProps };
        return <UntypedComponent {...props} />;
    }
}
