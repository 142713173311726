import { getUserGetCompanyUsers } from '@apis/Customers';
import { Company, UserListItem } from '@apis/Customers/model';
import { CompanyTenantPrereqService } from '@root/Components/Router/CompanyContent';
import { inject, injectable, singleton } from 'tsyringe';
import { BaseCacheByTenant } from './BaseCacheByTenant';
import { ICompanyContextToken } from './CompanyContext';

type RequiredUserFields = keyof UserListItem & ('FirstName' | 'LastName' | 'Id' | 'EMail' | 'Status' | 'Roles');
type FieldsNotNull<T> = Required<{ [K in keyof T]: Exclude<T[K], null | undefined> }>;
export type UserRecord = UserListItem & FieldsNotNull<Pick<UserListItem, RequiredUserFields>> & { fullName: string; formalSortableName: string };

@singleton()
class CompanyUserCache extends BaseCacheByTenant<ICompanyUserList> {
    public constructor(@inject(CompanyTenantPrereqService) tenantPrereqSvc: CompanyTenantPrereqService) {
        super(tenantPrereqSvc);
    }

    public async get(tenantId: number, getUserList: () => Promise<ICompanyUserList>) {
        return super.get(tenantId, getUserList);
    }
}

export interface ICompanyUserList {
    getById(id: number): UserRecord | undefined;
    getList(): UserRecord[];
}

class CompanyUserList implements ICompanyUserList {
    private readonly users: UserRecord[];
    private readonly userLookup: Map<number, UserRecord>;

    public constructor(users: UserListItem[]) {
        this.users = users
            .map((u) => ({
                ...u,
                Id: u.Id ?? 0,
                FirstName: u.FirstName ?? '',
                LastName: u.LastName ?? '',
                EMail: u.EMail ?? '',
                Status: u.Status ?? 'Deactivated',
                Roles: u.Roles ?? [],
                fullName: `${u.FirstName} ${u.LastName}`,
                formalSortableName: `${u.LastName}, ${u.FirstName}`,
            }))
            .sort((a, b) => a.formalSortableName.localeCompare(b.formalSortableName, undefined, { sensitivity: 'base' }));

        this.userLookup = new Map(this.users.map((u) => [u.Id ?? 0, u]));
    }

    public getById(id: number) {
        return this.userLookup.get(id);
    }
    public getList() {
        return this.users;
    }
}

@injectable()
export class CompanyUserService {
    public constructor(
        @inject(CompanyUserCache) private readonly cache: CompanyUserCache,
        @inject(ICompanyContextToken) private readonly company: Company
    ) {}

    public invalidate() {
        this.cache.invalidate(this.company.Id ?? 0);
    }

    public getUserList() {
        return this.cache.get(this.company.Id ?? 0, async () => {
            try {
                const users = await getUserGetCompanyUsers();
                return new CompanyUserList(users);
            } catch (e) {
                return new CompanyUserList([]);
            }
        });
    }
}
