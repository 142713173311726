import { createContext, DependencyList, ReactNode, useContext, useMemo, useRef } from 'react';
import { container, InjectionToken } from 'tsyringe';

export const DiContext = createContext(container);

export function useDiContainer() {
    const ctx = useContext(DiContext);
    return ctx;
}

export function useDi<T>(token: InjectionToken<T>) {
    const ctx = useContext(DiContext);
    return ctx.resolve<T>(token);
}

export function useDiMemo<T>(token: InjectionToken<T>, deps?: DependencyList, initializer?: (item: T) => unknown) {
    const ctx = useContext(DiContext);
    return useMemo(() => {
        const result = ctx.resolve<T>(token);
        initializer?.(result);
        return result;
    }, deps ?? []);
}

export function useDiComponent() {
    const container = useDiContainer();
    const DiSubComponent = useMemo(() => {
        return function DiSubContext({ children }: { children: ReactNode }) {
            return <DiContext.Provider value={container}>{children}</DiContext.Provider>;
        };
    }, [container]);

    return DiSubComponent;
}
