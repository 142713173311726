import { IQueryExpr } from '@apis/Jobs/model';
import { QueryExpr, QueryField, QueryOperation } from '@apis/Resources';
import { ActionIcon, Box, Divider, Group, Popover, Text, Tooltip } from '@mantine/core';
import { EventEmitter, useEvent, useEventValue } from '@root/Services/EventEmitter';
import { FieldInfo, PrimitiveType, SchemaService, SchemaValueProvider, TypeInfo } from '@root/Services/QueryExpr';
import { makeAutoObservable } from 'mobx';
import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { AddFilterButton, DataFilterModel, DataFilters, FilterExpr } from '../Filter/Filters';
import { IExprTypeProvider, IFieldNameProvider, IQueryToken, SchemaFieldNameProvider } from '../Filter/Services';
import { FieldPicker } from '../Picker/FieldPicker';
import { Picker } from '../Picker/Picker';
import { ColumnSelectorOption } from './ColumnSelector';
import { DataGridColumn, DataGridModel } from './DataGridModel';
import { GroupByButton } from './GroupByEditor';
import { MultiSortButton } from './MultiSortEditor';

export function DataGridFilters({
    grid,
    leftFilterPlaceHolder,
    hideFilters,
}: {
    hideFilters?: boolean;
    grid: DataGridModel;
    leftFilterPlaceHolder?: ReactNode;
}) {
    const { fieldInfo, filterInfoProvider } = useMemo(() => {
        const filterInfoProvider = new GridFieldInfoProvider(grid);
        const fieldInfo = grid.schemaSvc ? new SchemaFieldNameProvider(grid.schemaSvc) : filterInfoProvider;
        return { fieldInfo, filterInfoProvider };
    }, [grid, grid.schemaSvc]);
    const globalSearch = useMemo(() => new GridGlobalFilter(grid, filterInfoProvider), [grid, fieldInfo]);
    const filterRequested = useMemo(() => {
        return new EventEmitter<{ field: string } | undefined>(undefined);
    }, [grid]);
    const filtersChanged = useCallback(
        (filters: QueryExpr[], globalSearchText: string) => {
            const globalFilter = globalSearchText ? globalSearch.getFilter(globalSearchText) : undefined;
            if (globalFilter) {
                filters = [...filters, globalFilter];
            }
            grid.applyFilters(filters);
        },
        [grid, globalSearch]
    );
    const getValueRenderer = useCallback(
        (filterExpr: FilterExpr) => {
            const filterOptions = grid.getFilterByFilterField(filterExpr.field);
            return filterOptions?.valueRenderer;
        },
        [grid]
    );
    const tokenProvider = useCallback(
        (filter: FilterExpr, defaultValue: IQueryToken[]) => {
            const filterOptions = grid.getFilterByFilterField(filter.field);
            return filterOptions?.tokenProvider?.(filter, defaultValue) ?? defaultValue;
        },
        [grid]
    );
    useEffect(() => {
        return grid.newFilterRequested.listen((col) => {
            if (col) {
                const filterInfo = filterInfoProvider.getFilterInfo(col);
                if (filterInfo) {
                    filterRequested.emit({ field: filterInfo.field });
                }
            }
        }).dispose;
    }, [grid, filterRequested, filterInfoProvider]);
    useEffect(() => {
        filterInfoProvider.init();
    }, [grid.columns]);
    useEvent(grid.gridStateChanged);
    useEvent(grid.viewInvalidated);
    const disabled = useEventValue(grid.filterDisabled);
    const renderGroupBy = () =>
        grid.currentOptions?.allowGroupBy && (
            <GroupByButton
                fieldPicker={
                    grid.currentOptions?.groupByFieldPicker ??
                    ((select) =>
                        (
                            <GridFieldPicker
                                filter={(c) => !!c.config.allowGrouping}
                                grid={grid}
                                fieldInfo={filterInfoProvider}
                                onSelect={(_, col) => col && select(col)}
                            />
                        ) as ReactNode)
                }
                grid={grid}
            />
        );
    const renderMultiSort = () =>
        grid.currentOptions?.allowMultiSort && (
            <MultiSortButton
                grid={grid}
                fieldPicker={(select) =>
                    (
                        <GridFieldPicker
                            filter={(c) => !c.config.noSort}
                            grid={grid}
                            fieldInfo={filterInfoProvider}
                            onSelect={(_, col) => col && select({ Direction: 'Asc', Expr: { Field: col.sortField } })}
                        />
                    ) as ReactNode
                }
            />
        );
    return (
        <Box>
            {hideFilters ? (
                <Group>
                    {renderGroupBy()}
                    {renderMultiSort()}
                </Group>
            ) : (
                <DataFilters
                    leftPlaceHolder={leftFilterPlaceHolder}
                    valueProvider={grid.filterValueProvider}
                    fieldInfoProvider={fieldInfo}
                    operationInfoProvider={grid.currentOptions?.operationInfoProvider}
                    filters={globalSearch.getBasicFilters(grid.gridState.filters as QueryExpr[])}
                    onChange={filtersChanged}
                    disabled={disabled}
                    filterRequested={filterRequested}
                    renderFieldPicker={(select) => (
                        <GridFieldPicker
                            allowNonColumnFilters={grid.currentOptions?.allowNonColumnFilters}
                            onSelect={select}
                            grid={grid}
                            fieldInfo={filterInfoProvider}
                        />
                    )}
                    renderAddFilter={(model) => (
                        <>
                            {renderGroupBy()}
                            {renderMultiSort()}
                            <AddFilterButton onClick={() => model.addEmptyFilter()} />
                        </>
                    )}
                    valueRendererProvider={getValueRenderer}
                    tokenProvider={tokenProvider}
                    showGlobalSearch={!grid.currentOptions?.hideGlobalSearch}
                    globalSearchText={globalSearch.getGlobalSearchText()}
                />
            )}
        </Box>
    );
}

class GridFieldInfoProvider implements IExprTypeProvider, IFieldNameProvider {
    private fieldLookup = new Map<string, IGridFilterInfo>();
    private columnLookup = new Map<DataGridColumn, IGridFilterInfo>();
    private aggFieldInfo = new WeakMap<FieldInfo, null | (FieldInfo & { agg: string })[]>();
    private aggColumnLookup = new Map<FieldInfo, DataGridColumn | undefined>();
    private columnFieldLookup = new Map<DataGridColumn, FieldInfo>();
    public constructor(public grid: DataGridModel) {
        this.init();
    }
    public init() {
        const filterInfo = this.grid.columns.reduce((result, item) => {
            if (item.config.filter) {
                const field = item.filterField;
                if (typeof field === 'string') {
                    const name = item.header;
                    const type = item.filterType ?? 'unknown';
                    result.push({ column: item, field, name, type });
                }
            }
            return result;
        }, [] as IGridFilterInfo[]);
        this.fieldLookup = filterInfo.reduce((result, item) => {
            result.set(item.field, item);
            return result;
        }, new Map<string, IGridFilterInfo>());
        this.columnLookup = filterInfo.reduce((result, item) => {
            result.set(item.column, item);
            return result;
        }, new Map<DataGridColumn, IGridFilterInfo>());
    }
    public getName(value: QueryField) {
        return this.fieldLookup.get(value.Field ?? '')?.name ?? value.Field;
    }

    public getFilterInfo(field: string | DataGridColumn) {
        return typeof field === 'string' ? this.fieldLookup.get(field) : this.columnLookup.get(field);
    }
    public getFieldInfo(column: DataGridColumn) {
        return this.columnFieldLookup.get(column);
    }
    public getType(value: QueryExpr) {
        return (this.fieldLookup.get('Field' in value ? value.Field ?? '' : '')?.type as PrimitiveType) ?? 'unknown';
    }
    public getFlags(value: QueryField) {
        return [];
    }
    public getFormatter(value: QueryField) {
        const column = this.fieldLookup.get(value.Field ?? '')?.column;
        const fieldInfo = !column ? null : this.columnFieldLookup.get(column);

        return fieldInfo?.format;
    }

    public getColumnById(field: FieldInfo) {
        return this.aggColumnLookup.get(field) ?? this.grid.getColumnById(field.pathWithRoot);
    }

    public getAggFieldInfo(field: FieldInfo) {
        let result = this.aggFieldInfo.get(field);
        if (result !== null && !result && !('agg' in field)) {
            const column = this?.grid.getColumnById(field.pathWithRoot)?.config;
            if (column?.aggregations) {
                result = ['', ...column.aggregations].map((agg) => {
                    const item = Object.assign(Object.create(FieldInfo.prototype), field, { agg, name: `${agg} ${field.name}` });
                    const column = this?.grid.getColumnById(agg ? `${agg}(${item.pathWithRoot})` : item.pathWithRoot);
                    if (column) {
                        this.aggColumnLookup.set(item, this?.grid.getColumnById(`${agg}(${item.pathWithRoot})`));
                        this.columnFieldLookup.set(column, item);
                    }
                    return item;
                });
                this.aggFieldInfo.set(field, result);
            } else {
                this.aggFieldInfo.set(field, null);
            }
        }
        return result ?? undefined;
    }
}

interface IGridFilterInfo {
    field: string;
    name: string;
    type: string;
    column: DataGridColumn;
}

function GridFieldPicker({
    grid,
    fieldInfo,
    onSelect,
    filter,
    allowNonColumnFilters,
}: {
    grid: DataGridModel;
    fieldInfo: GridFieldInfoProvider;
    onSelect: (field: string, column?: DataGridColumn) => void;
    filter?: (column: DataGridColumn) => boolean;
    allowNonColumnFilters?: boolean;
}) {
    const [picker, setPicker] = useState({
        options: [] as ColumnSelectorOption[],
        selections: [],
    });
    const select = useCallback(
        ([option]: ColumnSelectorOption[]) => {
            if ('column' in option) {
                const column = grid.getColumnById(option.column.id);
                const filterInfo = column ? fieldInfo.getFilterInfo(column) : null;
                if (filterInfo && column) {
                    onSelect(filterInfo.field, column);
                }
            }
        },
        [onSelect]
    );
    const selectField = useCallback(
        (fields: FieldInfo[]) => {
            const { [0]: field } = fields;
            if (field) {
                const column = fieldInfo.getColumnById(field);
                const filterInfo = column ? fieldInfo.getFilterInfo(column) : null;
                const aggField = column ? fieldInfo.getFieldInfo(column) : null;
                if (filterInfo && column) {
                    onSelect(filterInfo.field, column);
                } else if (aggField && column) {
                    onSelect(aggField.path, column);
                } else if (allowNonColumnFilters) {
                    onSelect(field.path, undefined);
                }
            }
        },
        [onSelect, grid, grid.schemaSvc]
    );
    useEffect(() => {
        let { options } = grid.getColumnSelectorOptions(true);
        if (filter) {
            options = options.filter((o) => {
                if ('column' in o) {
                    return filter(grid.getColumnById(o.column.id)!);
                } else {
                    o.options = o.options.filter((c) => filter(grid.getColumnById(c.column.id)!));
                    return !!o.options.length;
                }
            });
        }
        setPicker({ options, selections: [] });
    }, []);
    const renderField = useCallback(
        (field: FieldInfo) => {
            const column = fieldInfo.getColumnById(field);
            return column?.renderFilterCell() ?? field.name;
        },
        [grid]
    );
    const showFieldFilter = useMemo(() => picker.options.length > 20 || picker.options.some((o) => 'name' in o), [picker]);
    const schemaFilter = useMemo(() => {
        return !grid.schemaSvc || !filter
            ? undefined
            : (item: FieldInfo | TypeInfo) => {
                  if ('isPrimitive' in item) {
                      const column = grid.getColumnById(item.pathWithRoot);
                      return !column ? false : filter?.(column);
                  } else {
                      return true;
                  }
              };
    }, [filter]);
    const getFieldChildren = useCallback((item: FieldInfo | TypeInfo) => {
        if (item instanceof TypeInfo) {
            return item.children;
        }
        return fieldInfo.getAggFieldInfo(item) ?? item.children;
    }, []);

    return (
        <Box>
            {grid.schemaSvc ? (
                <FieldPicker
                    mode="single"
                    onChange={selectField}
                    selections={picker.selections as FieldInfo[]}
                    renderItem={(o) => (!('field' in o) ? o.name : renderField(o))}
                    schema={grid.schemaSvc}
                    schemaFilter={schemaFilter}
                    getChildren={getFieldChildren}
                    height={350}
                    minimizeHeight
                />
            ) : (
                <Picker
                    items={picker.options}
                    selections={picker.selections}
                    onChange={select}
                    filterPlaceholder="Find a field"
                    noFilter={!showFieldFilter}
                    nameAccessor={(c) => ('name' in c ? c.name : c.column.id)}
                    childAccessor={(c) => ('options' in c ? c.options : undefined)}
                    isSelectable={(c) => 'column' in c}
                    renderItem={(c) =>
                        'column' in c
                            ? (typeof c.column.filter === 'object' && 'fieldPickerRenderer' in c.column.filter
                                  ? c.column.filter.fieldPickerRenderer?.()
                                  : null) ??
                              c.column.headerRenderer?.(c.column) ??
                              c.column.header
                            : c.name
                    }
                    width={350}
                    height={350}
                    minimizeHeight
                    mode="single"
                />
            )}
        </Box>
    );
}

class GridGlobalFilter {
    public constructor(private grid: DataGridModel, private fieldInfo: GridFieldInfoProvider) {
        makeAutoObservable(this);
    }

    public getGlobalSearchText() {
        const filter = this.grid.gridState.filters.find((f) => 'Operation' in f && f.Operation === 'or') as QueryOperation;
        let value = '';
        if (filter) {
            for (const op of filter.Operands) {
                for (const nested of op.Operands) {
                    const valueExpr = 'Operation' in nested ? nested.Operands.find((r: QueryExpr) => 'Value' in r) : undefined;
                    if (valueExpr) {
                        value = valueExpr.Value;
                        break;
                    }
                }
            }
        }
        return value;
    }

    public getBasicFilters(filters: QueryExpr[]) {
        return filters.filter((f) => 'Operation' in f && f.Operation !== 'or');
    }

    public getFilter(text: string) {
        if (text) {
            const result = {
                Operation: 'or',
                Operands: [],
            } as QueryOperation;
            for (const column of this.grid.visibleColumns) {
                const expr = this.getSearchExprByColumn(column, text);
                if (expr) {
                    result.Operands.push(expr);
                }
            }
            if (result.Operands.length) {
                return result;
            }
        }
        return undefined;
    }

    private getSearchExprByColumn(column: DataGridColumn, text: string) {
        const filterInfo = this.fieldInfo?.getFilterInfo(column);
        if (filterInfo) {
            let value: number | string | Date | boolean | undefined = text;
            const fieldExpr = { Field: filterInfo.field };
            const valueExpr = { Value: value };
            const operands = [fieldExpr, valueExpr];
            if (filterInfo.type === 'string') {
                return {
                    Operation: 'or',
                    Operands: [
                        { Operation: 'contains', Operands: operands },
                        { Operation: 'eq', Operands: operands },
                    ],
                };
            } else if (filterInfo.type === 'number') {
                value = parseFloat(text);
                if (isNaN(value)) {
                    return undefined;
                }
            } else if (filterInfo.type === 'date') {
                const dateValue = Date.parse(text);
                if (isNaN(dateValue)) {
                    return undefined;
                }
                value = new Date(dateValue);
            } else if (filterInfo.type === 'boolean') {
                const textLc = text.toLowerCase();
                value = textLc === 'true' || textLc === 'y' ? true : textLc === 'false' || textLc === 'n' ? false : undefined;
                if (value === undefined) {
                    return undefined;
                }
            }
            return { Operation: 'eq', Operands: operands };
        }
        return undefined;
    }
}
