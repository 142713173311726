import { QuerySelectExpr } from '@apis/Resources/model';
import { Accordion, ActionIcon, Box, TextInput, Tooltip } from '@mantine/core';
import { KpiChartSettings } from '@root/Components/Charts/KpiChart';
import { AnchorButton } from '@root/Design/Primitives';
import {
    SettingsInputRow,
    SettingsLabel,
    SettingsSection,
    SettingsSectionItem,
    SettingsSectionItemBody,
    SettingsSectionItemHeader,
    SettingsSectionItemHeaderLabel,
} from '@root/Design/Settings';
import { useEvent } from '@root/Services/EventEmitter';
import { observer } from 'mobx-react';
import { useCallback, useMemo } from 'react';
import { Calculator, CursorText, Plus, Trash } from 'tabler-icons-react';
import { EditorExprPicker, EditorFilterAccordion, FormatSettings } from './Components';
import { IChartEditor } from './Models';

export const KpiSettings = observer(function KpiSettings({ editor }: { editor: IChartEditor }) {
    const settings = useMemo(() => editor.getChartSettings<KpiChartSettings>(), []);
    const addItem = useCallback(() => {
        const idx = editor.settings.values.length;
        const metric = editor.getDatasource()?.getDefaultValue();
        if (metric?.Expr) {
            editor.setValue(idx, { Expr: metric?.Expr ?? { Operation: 'count' }, Alias: 'kpi' + idx });
            settings.labels.push('');
            settings.format.push(editor.getDefaultFormatter(metric));
        }
    }, [settings, editor]);
    useEvent(editor.onSettingsChanged);
    return (
        <>
            <SettingsSection title="KPIs">
                {editor.settings.values.map((v, i) => (
                    <Box key={i}>
                        <KpiSettingsItem editor={editor} index={i} settings={settings} />
                    </Box>
                ))}
                <SettingsSectionItem style={{ flex: 1 }} anchor onClick={addItem}>
                    <AnchorButton icon={<Plus size={16} />} size="sm" text="Add KPI" onClick={() => {}} />
                </SettingsSectionItem>
            </SettingsSection>
            <EditorFilterAccordion editor={editor} />
        </>
    );
});

const KpiSettingsItem = observer(function KpiSettings(props: { editor: IChartEditor; index: number; settings: KpiChartSettings }) {
    const { editor, index, settings } = props;
    const expr = editor.getValue(index);
    useEvent(editor.onSettingsChanged);
    function setItemAtIndex<T>(item: T, filler: T, index: number, items: T[]) {
        if (items.length <= index) {
            items.push(...Array(index - items.length + 1).fill(filler));
        }
        items[index] = item;
    }
    const applyLabel = useCallback(
        (label: string) => {
            setItemAtIndex(label, '', index, settings.labels);
        },
        [index, settings.labels]
    );
    const remove = useCallback(() => {
        settings.labels.splice(index, 1);
        settings.format.splice(index, 1);
        editor.removeValue(index);
    }, [index, settings]);
    const onExprChange = useCallback(
        (select: QuerySelectExpr) => {
            settings.format.splice(index, 1, editor.getNextFormatter(expr, select, settings.format[index]));
            editor.setValue(index, { Alias: `kpi${index}`, Expr: select.Expr });
        },
        [editor, index, settings]
    );
    const name = `KPI ${index + 1}`;
    return (
        <SettingsSectionItem>
            <SettingsSectionItemHeader>
                <SettingsSectionItemHeaderLabel>{name}</SettingsSectionItemHeaderLabel>
                <Tooltip label={`Remove ${name}`}>
                    <ActionIcon className="--hover-visible" onClick={remove}>
                        <Trash size={16} />
                    </ActionIcon>
                </Tooltip>
            </SettingsSectionItemHeader>
            <SettingsSectionItemBody>
                <SettingsInputRow>
                    <SettingsLabel icon={<CursorText />}>Label</SettingsLabel>
                    <TextInput
                        size="xs"
                        value={settings.labels.length > index ? settings.labels[index] : ''}
                        onChange={(e) => applyLabel(e.currentTarget.value)}
                    />
                </SettingsInputRow>
                <SettingsInputRow>
                    <SettingsLabel icon={<Calculator />}>Metric</SettingsLabel>
                    <EditorExprPicker
                        name={settings.labels[index] || `KPI ${index + 1}`}
                        types={['number']}
                        expr={expr}
                        editor={editor}
                        onChange={onExprChange}
                    />
                </SettingsInputRow>
                <FormatSettings
                    type="number"
                    onChange={(value) => (settings.format[index] = value as any)}
                    value={typeof settings.format[index] === 'string' ? (settings.format[index] as string) : undefined}
                />
            </SettingsSectionItemBody>
        </SettingsSectionItem>
    );
});
