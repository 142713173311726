import styled from '@emotion/styled';
import { Card, Center, Group, Loader, Stack, Text } from '@mantine/core';
import { Fragment, ReactNode } from 'react';
import { Icon } from 'tabler-icons-react';

export function LoadingFiller({ height }: { height?: number | string }) {
    return (
        <Center sx={{ height: height ?? '100%' }}>
            <Loader />
        </Center>
    );
}

export function NoDataFiller({ height, message }: { height?: number | string; message: string }) {
    return (
        <Center sx={{ height: height ?? '100%' }}>
            <Text color="dimmed">{message}</Text>
        </Center>
    );
}

export function FillerMessage(props: { children: JSX.Element } | { title: string | JSX.Element; message: string | JSX.Element; icon?: JSX.Element }) {
    return (
        <Center sx={{ height: '100%' }}>
            <Card withBorder shadow="xs" radius="md" sx={{ minWidth: 300, maxWidth: 600 }}>
                {'children' in props ? (
                    props.children
                ) : (
                    <Group spacing="lg" noWrap align="flex-start">
                        <Center>{props.icon}</Center>
                        <Stack spacing={8}>
                            {typeof props.title === 'string' ? <Text>{props.title}</Text> : props.title}
                            {typeof props.message === 'string' ? <Text size="sm">{props.message}</Text> : props.message}
                        </Stack>
                    </Group>
                )}
            </Card>
        </Center>
    );
}

export function FillerSwitch({
    loading,
    noData,
    children,
    height,
    noDataMessage = 'No Data',
}: {
    loading?: boolean;
    noData?: boolean;
    children: ReactNode | (() => ReactNode);
    height?: number | string;
    noDataMessage?: string;
}) {
    const content = loading ? (
        <LoadingFiller height={height} />
    ) : noData ? (
        <NoDataFiller message={noDataMessage} height={height} />
    ) : typeof children === 'function' ? (
        <>{children()}</>
    ) : (
        <></>
    );
    const Wrap = typeof children === 'function' ? Fragment : FillerSwitchContent;
    return (
        <>
            <Wrap>{content}</Wrap>
            {typeof children !== 'function' ? <>{children}</> : <></>}
        </>
    );
}

const FillerSwitchContent = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
`;
